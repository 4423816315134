import * as actions from "../actionTypes"

// initial state is no items selected
// each item is:
// {
//    id: parent id (e.g. project id or component id)
//    fileName: the file name that is selected for that parent, e.g. might select 'my_model.archimate'
// }

const initialState = {
    value: {},
}

export default function selectedModelFileName(state = initialState, action) {
    switch (action.type) {
        case actions.SET_SELECTED_MODEL_FILE_NAME: {
            const selectedFileInfo = action.payload.value

            const newState = {
                ...state.value,
                // store the selectedFileInfo in 2 forms, for convenience of consuming code
                [selectedFileInfo.parentId]: selectedFileInfo.fileName,
                fileName: selectedFileInfo.fileName,
                parentId: selectedFileInfo.parentId,
            }

            // console.log("%c[redux] new selected file name state", "color:lightgreen", {
            //     state,
            //     action,
            //     payload: action.payload.value,
            //     newState,
            // })

            return {
                value: newState,
            }
        }

        default:
            return state
    }
}
