// src/utils/MarkdownToWordConverter.js

import {
  Paragraph,
  TextRun,
  HeadingLevel,
  AlignmentType,
  LevelFormat,
  Table,
  TableRow,
  TableCell,
  WidthType,
} from "docx"

/**
 * Converts Markdown text to an array of Docx elements (Paragraphs, Tables, etc.)
 *
 * @param {object} params - The parameters for conversion.
 * @param {string} params.markdown - The Markdown content to convert.
 * @param {object} params.options - Required parameters.
 * @param {object} params.options.styles - Styles to apply to the Word document.
 * @param {object} params.options.numbering - Numbering configurations.
 * @returns {Array} - An array of Docx elements.
 * @throws {Error} Throws an error if styles or numbering are not provided.
 */
export function convertMarkdownToDocxElements({ markdown, options }) {
  const { styles, numbering } = options

  // Validate that styles and numbering are provided
  if (!styles || !numbering) {
    throw new Error("Both 'styles' and 'numbering' options must be provided.")
  }

  const lines = markdown.split("\n")
  const elements = []
  let inCodeBlock = false
  let codeBlockLanguage = ""
  let codeBlockContent = ""
  let inTable = false
  let tableHeaders = []
  let tableAlignments = []
  let tableRows = []

  for (let i = 0; i < lines.length; i++) {
    let line = lines[i]

    // Handle code blocks using regex to account for leading spaces and language specifiers
    const codeBlockDelimiterMatch = line.match(/^\s*```(\w+)?\s*$/)
    if (codeBlockDelimiterMatch) {
      if (inCodeBlock) {
        // End of code block
        if (codeBlockLanguage.toLowerCase() === "json") {
          // Insert JSON code block as raw text to preserve comments
          const jsonParagraph = createRawCodeParagraph(
            codeBlockContent,
            codeBlockLanguage
          )
          elements.push(jsonParagraph)
        } else {
          // For other languages, attempt to parse and format
          const formattedParagraph = formatCodeBlock(
            codeBlockContent,
            codeBlockLanguage
          )
          elements.push(formattedParagraph)
        }

        inCodeBlock = false
        codeBlockLanguage = ""
        codeBlockContent = ""
      } else {
        // Start of code block
        inCodeBlock = true
        codeBlockLanguage = codeBlockDelimiterMatch[1] || ""
        codeBlockContent = ""
      }
      continue
    }

    if (inCodeBlock) {
      codeBlockContent += line + "\n"
      continue
    }

    // Handle horizontal rules
    if (/^---+$/.test(line.trim())) {
      elements.push(
        new Paragraph({
          children: [],
          border: {
            bottom: {
              color: "auto",
              space: 1,
              value: "single",
              size: 6,
            },
          },
        })
      )
      continue
    }

    // Handle table rows
    const tableMatch = line.match(/^\|.*\|$/)
    if (tableMatch) {
      if (!inTable) {
        // Potential start of a table: previous line is header, current is delimiter
        const prevLine = lines[i - 1] || ""
        const delimiterMatch = line.match(/^\|\s*:?-+:?\s*\|$/)
        if (delimiterMatch && i > 0 && lines[i - 1].match(/^\|.*\|$/)) {
          // Start table
          inTable = true
          // Parse headers
          tableHeaders = parseTableRow(lines[i - 1])
          // Parse alignments
          tableAlignments = parseTableAlignment(line)
          tableRows = []
          continue
        }
      } else {
        // Inside a table, parse rows
        const row = parseTableRow(line)
        if (row.length === 0) {
          // Empty row indicates end of table
          inTable = false
          // Create Table element
          elements.push(
            createDocxTable(tableHeaders, tableAlignments, tableRows)
          )
          tableHeaders = []
          tableAlignments = []
          tableRows = []
          continue
        } else {
          tableRows.push(row)
          continue
        }
      }
    } else {
      if (inTable) {
        // End of table
        inTable = false
        elements.push(createDocxTable(tableHeaders, tableAlignments, tableRows))
        tableHeaders = []
        tableAlignments = []
        tableRows = []
      }
    }

    // Handle headings with # syntax
    const headingMatch = line.match(/^(#{1,6})\s+(.*)/)
    if (headingMatch) {
      const level = headingMatch[1].length
      const text = headingMatch[2].trim()

      elements.push(
        new Paragraph({
          children: parseInlineStyles(text),
          heading: getHeadingLevel(level),
          // The spacing.before and spacing.after are handled by the style definitions in styles.js
        })
      )
      continue
    }

    // Handle entirely bolded lines as normal paragraphs with top and bottom margins
    const boldParagraphMatch = line.match(/^(\*\*|__)(.+)\1$/)
    if (boldParagraphMatch) {
      const text = boldParagraphMatch[2].trim()
      elements.push(
        new Paragraph({
          children: [new TextRun({ text: text, bold: true })],
          style: "Normal", // Keep it as "Normal" style
          spacing: {
            before: 200, // Adjust this value as needed (200 twips ≈ 10pt)
            after: 200, // Adjust this value as needed (200 twips ≈ 10pt)
          },
        })
      )
      continue
    }

    // Handle unordered lists
    const ulMatch = line.match(/^(\s*)[-*+]\s+(.*)/)
    if (ulMatch) {
      const indent = getIndentLevel(ulMatch[1])
      const text = ulMatch[2].trim()

      handleList("bulletList", indent, text, elements, numbering)
      continue
    }

    // Handle ordered lists
    const olMatch = line.match(/^(\s*)(\d+)\.\s+(.*)/)
    if (olMatch) {
      const indent = getIndentLevel(olMatch[1])
      const text = olMatch[3].trim()

      handleList("orderedList", indent, text, elements, numbering)
      continue
    }

    // Handle blockquotes (simple implementation)
    const blockquoteMatch = line.match(/^>\s+(.*)/)
    if (blockquoteMatch) {
      const text = blockquoteMatch[1].trim()
      elements.push(
        new Paragraph({
          children: parseInlineStyles(text),
          style: "BlockQuote", // Ensure this style is defined in styles.js
        })
      )
      continue
    }

    // Handle paragraphs (including inline styles)
    if (line.trim() !== "") {
      elements.push(
        new Paragraph({
          children: parseInlineStyles(line.trim()),
          spacing: {
            before: 200, // Adjust this value as needed (200 twips ≈ 10pt)
            after: 200, // Adjust this value as needed (200 twips ≈ 10pt)
          },
          style: "Normal",
        })
      )
    }
  }

  // After processing all lines, if still inside a table, close it
  if (inTable) {
    inTable = false
    elements.push(createDocxTable(tableHeaders, tableAlignments, tableRows))
    tableHeaders = []
    tableAlignments = []
    tableRows = []
  }

  return elements
}

/**
 * Creates a Paragraph with raw code, preserving comments and formatting.
 *
 * @param {string} code - The raw code string.
 * @param {string} language - The programming language of the code.
 * @returns {Paragraph} - The constructed Paragraph element.
 */
function createRawCodeParagraph(code, language) {
  const lines = code.split("\n")
  const children = []

  lines.forEach((line, index) => {
    // Preserve indentation via spaces and use monospaced font
    children.push(new TextRun(line))
    if (index < lines.length - 1) {
      children.push(new TextRun({ break: 1 }))
    }
  })

  return new Paragraph({
    children: children,
    style: "Code",
  })
}

/**
 * Attempts to format a code block by parsing and re-stringifying (for non-JSON).
 *
 * @param {string} code - The code block content.
 * @param {string} language - The programming language of the code.
 * @returns {Paragraph} - The formatted Paragraph element.
 */
function formatCodeBlock(code, language) {
  // Implement parsing and formatting for other languages if needed
  // For simplicity, we'll treat them as raw code similar to JSON
  return createRawCodeParagraph(code, language)
}

/**
 * Parses a table row from Markdown into an array of cell texts.
 *
 * @param {string} row - The Markdown table row line.
 * @returns {Array} - An array of cell texts.
 */
function parseTableRow(row) {
  // Remove leading and trailing pipes and split by pipe
  return row
    .replace(/^\|/, "")
    .replace(/\|$/, "")
    .split("|")
    .map((cell) => cell.trim())
}

/**
 * Parses the alignment row in a Markdown table.
 *
 * @param {string} alignmentRow - The Markdown table alignment row.
 * @returns {Array} - An array of alignment types for each column.
 */
function parseTableAlignment(alignmentRow) {
  return alignmentRow
    .replace(/^\|/, "")
    .replace(/\|$/, "")
    .split("|")
    .map((cell) => {
      cell = cell.trim()
      if (cell.startsWith(":") && cell.endsWith(":")) {
        return "center"
      } else if (cell.startsWith(":")) {
        return "left"
      } else if (cell.endsWith(":")) {
        return "right"
      } else {
        return "left" // Default alignment
      }
    })
}

/**
 * Creates a docx Table element from headers, alignments, and rows.
 *
 * @param {Array} headers - The header texts.
 * @param {Array} alignments - The alignment types for each column.
 * @param {Array} rows - The body rows, each as an array of cell texts.
 * @returns {Table} - The constructed docx Table element.
 */
function createDocxTable(headers, alignments, rows) {
  // Create header row
  const headerRow = new TableRow({
    children: headers.map(
      (header, index) =>
        new TableCell({
          children: [
            new Paragraph({
              children: parseInlineStyles(header),
              alignment: mapAlignment(alignments[index]),
              bold: true, // Make header text bold
            }),
          ],
          shading: {
            fill: "D3D3D3", // Light gray background for headers
          },
          width: {
            size: 100 / headers.length,
            type: WidthType.PERCENTAGE,
          },
        })
    ),
  })

  // Create body rows
  const bodyRows = rows.map(
    (row) =>
      new TableRow({
        children: row.map(
          (cell, index) =>
            new TableCell({
              children: [
                new Paragraph({
                  children: parseInlineStyles(cell),
                  alignment: mapAlignment(alignments[index]),
                }),
              ],
              width: {
                size: 100 / headers.length,
                type: WidthType.PERCENTAGE,
              },
            })
        ),
      })
  )

  return new Table({
    rows: [headerRow, ...bodyRows],
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    borders: {
      top: { style: "single", size: 6, color: "000000" },
      bottom: { style: "single", size: 6, color: "000000" },
      left: { style: "single", size: 6, color: "000000" },
      right: { style: "single", size: 6, color: "000000" },
      insideHorizontal: { style: "single", size: 6, color: "000000" },
      insideVertical: { style: "single", size: 6, color: "000000" },
    },
  })
}

/**
 * Maps alignment string to docx AlignmentType.
 *
 * @param {string} alignment - 'left', 'center', 'right'.
 * @returns {AlignmentType} - Corresponding docx AlignmentType.
 */
function mapAlignment(alignment) {
  switch (alignment) {
    case "center":
      return AlignmentType.CENTER
    case "right":
      return AlignmentType.RIGHT
    case "left":
    default:
      return AlignmentType.LEFT
  }
}

/**
 * Calculates the indentation level based on leading spaces or tabs.
 *
 * @param {string} whitespace - The leading whitespace of a line.
 * @returns {number} - The calculated indentation level.
 */
function getIndentLevel(whitespace) {
  // Count tabs as equivalent to 4 spaces
  const spaceCount = whitespace.replace(/\t/g, "    ").length
  // Assuming 2 spaces per indent level
  return Math.floor(spaceCount / 2)
}

/**
 * Maps heading levels to docx HeadingLevel enums.
 *
 * @param {number} level - The heading level (1-6).
 * @returns {HeadingLevel} - The corresponding docx HeadingLevel.
 */
function getHeadingLevel(level) {
  switch (level) {
    case 1:
      return HeadingLevel.HEADING_1
    case 2:
      return HeadingLevel.HEADING_2
    case 3:
      return HeadingLevel.HEADING_3
    case 4:
      return HeadingLevel.HEADING_4
    case 5:
      return HeadingLevel.HEADING_5
    case 6:
      return HeadingLevel.HEADING_6
    default:
      return HeadingLevel.HEADING_1
  }
}

/**
 * Handles list items and manages nested lists.
 *
 * @param {string} type - 'bulletList' or 'orderedList'.
 * @param {number} indent - The indentation level.
 * @param {string} text - The list item text.
 * @param {Array} elements - The array to push Docx elements into.
 * @param {object} numbering - Numbering configurations.
 */
function handleList(type, indent, text, elements, numbering) {
  // Determine numbering reference based on list type
  const reference = type === "orderedList" ? "numbered" : "my-bullet-points"

  // Create a new Paragraph with numbering and parse inline styles
  elements.push(
    new Paragraph({
      children: parseInlineStyles(text),
      numbering: {
        reference: reference,
        level: indent,
      },
      // Indentation is handled by the numbering configuration defined in styles.js
    })
  )
}

/**
 * Parses inline Markdown styles within a line of text.
 *
 * @param {string} text - The line of text to parse.
 * @returns {Array} - An array of Docx TextRun objects with applied styles.
 */
function parseInlineStyles(text) {
  const textRuns = []
  let lastIndex = 0

  // Regex to match bold, italic, inline code, and hyperlinks
  const regex =
    /(\*\*|__)(.*?)\1|(\*|_)(.*?)\3|`([^`]+)`|\[([^\]]+)\]\(([^)]+)\)/g
  let match

  while ((match = regex.exec(text)) !== null) {
    const index = match.index

    // Add text before the match
    if (index > lastIndex) {
      textRuns.push(new TextRun(text.slice(lastIndex, index)))
    }

    if (match[1] && match[2]) {
      // Bold text
      textRuns.push(
        new TextRun({
          text: match[2],
          bold: true,
        })
      )
    } else if (match[3] && match[4]) {
      // Italic text
      textRuns.push(
        new TextRun({
          text: match[4],
          italics: true,
        })
      )
    } else if (match[5]) {
      // Inline code
      textRuns.push(
        new TextRun({
          text: match[5],
          font: { name: "Courier New" },
          color: "808080",
        })
      )
    } else if (match[6] && match[7]) {
      // Link
      textRuns.push(
        new TextRun({
          text: match[6],
          style: "Hyperlink",
          link: match[7],
        })
      )
    }

    lastIndex = regex.lastIndex
  }

  // Add any remaining text after the last match
  if (lastIndex < text.length) {
    textRuns.push(new TextRun(text.slice(lastIndex)))
  }

  return textRuns
}
