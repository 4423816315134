import React from "react"
import Header from "../components/Header"
import { Paper } from "@mui/material"
import InviteList from "../components/InviteList"
import { spacing } from "../pages/services/styleServices"

const styles = {
    pageContent: {
        margin: spacing(2),
        padding: spacing(2),
    },
}

const Invites = (props) => {
    return (
        <Header title="Invites">
            <Paper sx={styles.pageContent}>
                <InviteList {...props} />
            </Paper>
        </Header>
    )
}

export default Invites
