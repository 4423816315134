import React from "react"
import { Grid, Paper, Typography } from "@mui/material"
import { Form } from "./useForm"
import Controls from "./controls/Controls"
import { secsToDateStr } from "../pages/services/dateServices"
import { spacing } from "../pages/services/styleServices"

const styles = {
    pageContent: {
        marginTop: spacing(1),
        marginLeft: spacing(1),
        marginRight: spacing(2),
        padding: spacing(1),
        maxWidth: "320px",
    },
}

const StripeCustomer = (props) => {
    const { customer } = props

    return (
        <Paper sx={styles.pageContent}>
            <Form>
                <Grid container direction="column">
                    <Grid item>
                        <Typography
                            variant="h6"
                            component={"span"}
                            gutterBottom={true}
                            paragraph={true}
                        >
                            Billing Details
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Controls.Readonly name="name" label="Billing Name" value={customer.name || ""} />
                    </Grid>

                    <Grid item>
                        <Controls.Readonly name="email" label="Billing Email" value={customer.email || ""} />
                    </Grid>

                    <Grid item>
                        <Controls.Readonly name="phone" label="Phone" value={customer.phone || ""} />
                    </Grid>

                    <Grid item>
                        <Controls.Readonly
                            name="created"
                            label="Created"
                            value={secsToDateStr(customer.created)}
                        />
                    </Grid>
                </Grid>
            </Form>
        </Paper>
    )
}

export default StripeCustomer
