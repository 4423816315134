import { styled } from "@mui/system"
import * as colors from "@mui/material/colors"
import { Link } from "react-router-dom"

const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: "none",
    color: theme.palette.text.primary,
    ":hover": {
        textDecoration: "underline",
        color: colors.blue[800],
    },
}))

export default StyledLink
