import React, { useEffect, useState } from "react"
import {
    Box,
    Card,
    Chip,
    CardContent,
    CardHeader,
    Stack,
    CardActionArea,
    Grid,
    Typography,
    Tooltip,
} from "@mui/material"
import { Link } from "react-router-dom"
import * as palette from "./symbols/palette"
import * as rules from "../pages/services/rules"
import * as colors from "@mui/material/colors"
import RuleTags from "./RuleTags"
import { spacing } from "../pages/services/styleServices"

const styles = {
    root: {
        flexGrow: 1,
        minWidth: 320,
        maxWidth: 320,
    },
    paper: {
        padding: spacing(1),
        textAlign: "center",
        color: "theme.palette.text.secondary",
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
        gap: spacing(1),
    },
    cardHeader: {
        paddingBottom: spacing(0),
    },
    ruleOverview: {
        marginTop: spacing(1),
    },
    subHeading: {
        marginTop: spacing(2),
        marginBottom: spacing(0.5),
    },
}

const RuleCard = (props) => {
    const { rule, showRuleDetail } = props

    const url = "/RuleEdit/" + rule.id

    const [ruleRequiredProps, setRuleRequiredProps] = useState({})

    const getNoneDisplay = (items) => {
        return (
            items.length === 0 && (
                <Typography variant="caption" component={"span"}>
                    None
                </Typography>
            )
        )
    }

    useEffect(() => {
        if (rule && rule.rule_data) {
            const required = (rule.rule_data.required || "Y") === "Y"

            if (required) {
                setRuleRequiredProps({ label: "Match", sx: { backgroundColor: colors.green[200] } })
            } else {
                setRuleRequiredProps({
                    label: "Avoid Match",
                    sx: { backgroundColor: colors.red[200] },
                })
            }
        }
    }, [rule])

    const showMessage = (message) => {
        return message ? (
            <Typography variant="caption" sx={{ fontStyle: "italic" }} component={"span"}>
                "{message}"
            </Typography>
        ) : (
            <Typography variant="caption" component={"span"}>
                A default message is generated
            </Typography>
        )
    }

    // Check if ruleData specifies only 1 of sources or targets
    const isModelSpecific = (ruleData) => {
        return (
            rules.getRuleScope(ruleData.sources, ruleData.targets, ruleData.connectors) === "model"
        )
    }

    return (
        <Grid item xs={2} sm={3} sx={styles.root} key={rule.id}>
            <Box padding={0.1}>
                <Card>
                    <CardActionArea component={Link} to={url}>
                        <CardHeader
                            title={
                                <Typography variant="body1" component={"span"}>
                                    {rule.name}
                                </Typography>
                            }
                            subheader={
                                <Box>
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                        component={"span"}
                                    >
                                        {rule.description}
                                    </Typography>
                                </Box>
                            }
                            sx={styles.cardHeader}
                        />
                        {!showRuleDetail && (
                            <CardContent
                                sx={{ paddingBottom: "5px", paddingTop: "5px", margin: 0 }}
                            ></CardContent>
                        )}
                        {showRuleDetail && (
                            <CardContent>
                                <SubHeading>Checks Performed</SubHeading>

                                {rule.rule_data.source_to_target && (
                                    <Stack sx={styles.ruleOverview}>
                                        <Typography variant="caption" component={"span"}>
                                            Source(s) must have target(s) defined
                                        </Typography>

                                        {showMessage(rule.rule_data.source_to_target_msg)}
                                    </Stack>
                                )}

                                {rule.rule_data.target_to_source && (
                                    <Stack sx={styles.ruleOverview}>
                                        <Typography variant="caption" component={"span"}>
                                            Target(s) must have source(s) defined
                                        </Typography>

                                        {showMessage(rule.rule_data.target_to_source_msg)}
                                    </Stack>
                                )}

                                {rule.rule_type === "parent_child" && (
                                    <Box>
                                        <SubHeading>Sources</SubHeading>
                                        <Box sx={styles.chips}>
                                            {rule.rule_data.sources.map((source) => (
                                                <Chip
                                                    size="small"
                                                    key={source}
                                                    label={source}
                                                    style={{
                                                        backgroundColor:
                                                            palette.getElementLayerColor(source),
                                                    }}
                                                />
                                            ))}
                                            {getNoneDisplay(rule.rule_data.sources)}
                                        </Box>
                                        <SubHeading>Connectors</SubHeading>
                                        <Box sx={styles.chips}>
                                            {rule.rule_data.connectors.map((connector) => (
                                                <Chip
                                                    size="small"
                                                    key={connector}
                                                    label={connector}
                                                />
                                            ))}
                                            {getNoneDisplay(rule.rule_data.connectors)}
                                        </Box>
                                        <SubHeading>Targets</SubHeading>

                                        <Box sx={styles.chips}>
                                            {rule.rule_data.targets.map((target) => (
                                                <Chip
                                                    size="small"
                                                    key={target}
                                                    label={target}
                                                    style={{
                                                        backgroundColor:
                                                            palette.getElementLayerColor(target),
                                                    }}
                                                />
                                            ))}
                                            {getNoneDisplay(rule.rule_data.targets)}
                                        </Box>
                                    </Box>
                                )}

                                <SubHeading>Rule Type</SubHeading>

                                <Box>
                                    <Tooltip title="The level at which the rule is applied">
                                        <Typography variant="caption" component={"span"}>
                                            {isModelSpecific(rule.rule_data)
                                                ? "Per Model"
                                                : "Per View"}
                                        </Typography>
                                    </Tooltip>
                                </Box>

                                <SubHeading>Model must match or not match</SubHeading>

                                <Box>
                                    {rule.rule_data && <Chip size="small" {...ruleRequiredProps} />}
                                </Box>

                                <SubHeading>Tags</SubHeading>
                                <Box>
                                    <RuleTags tags={rule.tags} />
                                    {(rule.tags || []).length === 0 && (
                                        <Typography variant="caption">No tags defined</Typography>
                                    )}
                                </Box>
                            </CardContent>
                        )}
                    </CardActionArea>
                </Card>
            </Box>
        </Grid>
    )
}

const SubHeading = (props) => {
    return (
        <Box sx={styles.subHeading}>
            <Typography variant="caption" color="textSecondary" component={"span"}>
                {props.children}
            </Typography>
        </Box>
    )
}

export default RuleCard
