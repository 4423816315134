import React, { useState } from "react"
import * as common from "./common"
import { deepPurple } from "@mui/material/colors"
import BaseShapeOctagon from "./BaseShapeOctagon"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const Goal = (props) => {
    const { x, y, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = deepPurple[200]
    const defaultColor = fillColor || deepPurple[100]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <BaseShapeOctagon {...props} fill={fill} setFill={handleFill} />

            <svg x={width - 22} y={7}>
                {common.get3CirclesIcon(x, y)}
            </svg>
            <ElementText {...props} label={label} x={props.x + 3} />
        </Svg>
    )
}

export default Goal
