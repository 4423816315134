import React, { useState } from "react"
import { Box } from "@mui/material"
import PrintForm from "../components/PrintForm"
import { spacing } from "../pages/services/styleServices"

const styles = {
    pageContent: {
        margin: spacing(1),
        padding: spacing(1),
    },
    menu: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: spacing(1),
    },
}

const Print = (props) => {
    const [title, setTitle] = useState("Print")

    return (
        <Box sx={styles.pageContent}>
            <PrintForm {...props} setTitle={setTitle} />
        </Box>
    )
}

export default Print
