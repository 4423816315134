import * as actions from '../actionTypes'

// 
const initialState = { 
    value: false
}

export default function configMenuOpen(state = initialState, action) {

    switch(action.type) {

        case actions.SET_CONFIG_MENU_OPEN: {

            console.log('[redux] Setting config menu status', state)
            return {
                ...state,
                value: action.payload.value
            }
        }

        default:
            return state
    }
}