import React, { useState } from "react"
import * as common from "./common"
import { grey } from "@mui/material/colors"
import ElementText from "./ElementText"
import Svg from "./Svg"

const Note = (props) => {
    const { x, y, height, width, label, fillColor } = props

    const hoverColor = grey[50]
    const defaultColor = fillColor || "#fff"

    const [fill, setFill] = useState(defaultColor)

    // corner length
    const c = 15

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={setFill}>
            <path
                d={`M${x},${y + 1} h${width} v ${height - c} l ${-c} ${c} h${-(
                    width - c
                )} v${-height}  z`}
                stroke={common.outlineColor}
                strokeWidth={0.5}
                fill={fill}
            />

            <ElementText {...props} label={label} />
        </Svg>
    )
}

export default Note
