import { useState, useEffect } from "react"
import _ from "lodash"
import { selectMenuStatus } from "../../redux/selectors"
import { useSelector } from "react-redux"

function getWindowDimensions(window, menuOpen) {
    const usableWidth = window.innerWidth - (menuOpen ? 240 : 0)
    return { width: usableWidth, height: window.innerHeight }
}

const useWindowDimensions = () => {
    const menuStatus = useSelector(selectMenuStatus)

    const [menuOpen, setMenuOpen] = useState(menuStatus)

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions(window, menuOpen))

    const updateWindowDimensions = (dimensions) => {
        if (!_.isEqual(dimensions, windowDimensions)) {
            // Subtract 240px from width if menuOpen is true
            if (menuOpen) {
                dimensions.width = dimensions.width - 240
            }
            setWindowDimensions(dimensions, menuOpen)
        }
    }

    useEffect(() => {
        if (menuStatus !== menuOpen) {
            setMenuOpen(menuStatus)
        }
    }, [menuStatus])

    useEffect(() => {
        function handleResize() {
            const dimensions = getWindowDimensions(window)
            //console.log("handle resize", dimensions)
            updateWindowDimensions(dimensions)
        }

        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuOpen])

    useEffect(() => {
        function handleOrientationChange() {
            const dimensions = getWindowDimensions(window, menuOpen)
            //console.log("handle orientation change", dimensions)
            updateWindowDimensions(dimensions)
        }

        window.addEventListener("orientationchange", handleOrientationChange)
        return () => window.removeEventListener("orientationchange", handleOrientationChange)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return windowDimensions
}

export { useWindowDimensions }
