import * as actions from "../actionTypes"

// show documentation indicator for diagrams - global setting
const initialState = {
    value: true,
}

export default function showDocumentationIndicator(state = initialState, action) {
    switch (action.type) {
        case actions.SET_SHOW_DOCUMENTATION_INDICATOR: {
            // console.log(
            //     "%c[redux] Setting show documentation indicator for diagrams",
            //     "color:lightGreen",
            //     action.payload.value
            // )
            return {
                ...state,
                value: action.payload.value,
            }
        }

        default:
            return state
    }
}
