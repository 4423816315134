import React, { Fragment, useMemo, useState } from "react"
import Grid from "@mui/material/Grid"
import { withSnackbar } from "notistack"
import { withRouter } from "react-router-dom"
import {
    Avatar,
    Box,
    Chip,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from "@mui/material"
import * as colors from "@mui/material/colors"
import { styled } from "@mui/material/styles"
import { features } from "./jobsToBeDoneData"

const styles = {
    selectedChip: {
        backgroundColor: colors.teal[300],
    },
}

const ChipsBox = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(0.5),
}))

const StyledAvatar = styled(Avatar)(({ status, theme }) => {
    return status === "Idea"
        ? {
              backgroundColor: colors.deepPurple[500],
              color: theme.palette.getContrastText(colors.deepPurple[500]),
          }
        : status === "WIP"
        ? {
              backgroundColor: colors.blue[500],
              color: theme.palette.getContrastText(colors.blue[500]),
          }
        : status === "Done"
        ? {
              backgroundColor: colors.green[600],
              color: theme.palette.getContrastText(colors.green[600]),
          }
        : {}
})

const JobsToBeDoneForm = (props) => {
    const [selectedCategories, setSelectedCategories] = useState([])

    const [selectedStatus, setSelectedStatus] = useState([])

    const categories = useMemo(
        () =>
            Array.from(new Set(features.map((feature) => feature.category)))
                .filter((item) => item !== "" && item !== undefined)
                .sort(),
        [features]
    )

    const filteredFeatures = useMemo(() => {
        return features.filter((feature) => {
            const categoryMatch =
                selectedCategories.length === 0
                    ? true
                    : selectedCategories.includes(feature.category)

            const statusMatch =
                selectedStatus.length === 0 ? true : selectedStatus.includes(feature.status)

            return categoryMatch && statusMatch
        })
    }, [selectedCategories, selectedStatus, features])

    const handleToggleCategory = (category) => {
        if (selectedCategories.includes(category)) {
            setSelectedCategories(selectedCategories.filter((item) => item !== category))
        } else {
            setSelectedCategories([...selectedCategories, category])
        }
    }

    const handleToggleStatus = (status) => {
        if (selectedStatus.includes(status)) {
            setSelectedStatus(selectedStatus.filter((item) => item !== status))
        } else {
            setSelectedStatus([...selectedStatus, status])
        }
    }

    return (
        <Fragment>
            <ChipsBox>
                {categories &&
                    categories.map((category) => {
                        const colorProps = selectedCategories.includes(category)
                            ? { style: styles.selectedChip }
                            : {}
                        return (
                            <Chip
                                size="small"
                                key={category}
                                label={category}
                                onClick={() => handleToggleCategory(category)}
                                {...colorProps}
                            />
                        )
                    })}
            </ChipsBox>
            <ChipsBox>
                {["Done", "Idea", "WIP"].map((status) => {
                    const colorProps = selectedStatus.includes(status)
                        ? { styles: styles.selectedChip }
                        : {}
                    return (
                        <Chip
                            size="small"
                            key={status}
                            label={status}
                            onClick={() => handleToggleStatus(status)}
                            {...colorProps}
                        />
                    )
                })}
            </ChipsBox>
            <Grid container direction="column">
                <List>
                    {filteredFeatures &&
                        filteredFeatures
                            .sort((a, b) => {
                                let sort =
                                    (styles[a.status]?.sort || 0) - (styles[b.status]?.sort || 0)
                                if (sort === 0) {
                                    sort = a.name.localeCompare(b.name)
                                }
                                return sort
                            })
                            .map((feature) => (
                                <ListItem key={feature.name}>
                                    <ListItemAvatar>
                                        <StyledAvatar status={feature.status}>
                                            <Typography
                                                variant="caption"
                                                className={styles[feature.status]?.text}
                                                component={"span"}
                                            >
                                                {feature.status}
                                            </Typography>
                                        </StyledAvatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Typography component={"span"}>
                                                <b>{feature.name}</b> | {feature.category}{" "}
                                                {feature.completed && ` | ${feature.completed}`}
                                            </Typography>
                                        }
                                        secondary={
                                            <>
                                                <Typography
                                                    sx={{ marginTop: "10px" }}
                                                    variant="body2"
                                                    color="textPrimary"
                                                    component={"span"}
                                                >
                                                    {feature.description}
                                                </Typography>
                                                <Typography
                                                    sx={{ marginTop: "10px" }}
                                                    variant="body2"
                                                    color="textSecondary"
                                                    component={"span"}
                                                >
                                                    {feature.notes && ". "}
                                                    {feature.notes}
                                                </Typography>
                                            </>
                                        }
                                    />
                                </ListItem>
                            ))}
                </List>
            </Grid>
        </Fragment>
    )
}

export default withSnackbar(withRouter(JobsToBeDoneForm))
