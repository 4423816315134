import * as palette from "../../components/symbols/palette"
import * as modelServices from "./modelServices"

const createChatPrompt = ({
  promptData,
  promptLayers,
  includeProperties,
  includeDoco,
  includeIds = true,
}) => {
  console.log("Create Chat Prompt", promptData)

  const prompt = []

  const shortIds = {}

  let shortIdCount = 1

  const RELATIONSHIP_DESCRIPTORS = {
    [palette.TRIGGERING_RELATIONSHIP]: "triggers",
    [palette.ASSOCIATION_RELATIONSHIP]: "is associated with",
    [palette.COMPOSITION_RELATIONSHIP]: "composes",
    [palette.AGGREGATION_RELATIONSHIP]: "aggregates",
    [palette.ACCESS_RELATIONSHIP]: "accesses",
    [palette.REALIZATION_RELATIONSHIP]: "realizes",
    [palette.FLOW_RELATIONSHIP]: "flows to",
    [palette.ASSIGNMENT_RELATIONSHIP]: "assigns",
    [palette.SERVING_RELATIONSHIP]: "serves",
    [palette.INFLUENCE_RELATIONSHIP]: "influences",
  }

  // Return a short id for an element. Short ids start at 1, 2, 3, .... Check if a long id exists first before creating a new short id
  const getShortId = (longId) => {
    if (shortIds[longId]) {
      return shortIds[longId]
    }

    const shortId = `ID:${shortIdCount++}`
    shortIds[longId] = shortId
    return shortId
  }

  prompt.push(
    `The following information is for an Architecture view called '${promptData.view.name}'.`
  )

  const getLabel = (el, includeLabel = true) => {
    if (includeIds) {
      const label = includeLabel ? `(${palette.formatLabel(el.type)})` : ""
      return `[${getShortId(el.id)}] '${el.name}' ${label}`.trim()
    }
    return el.name
  }

  const { elements } = promptData

  console.log("Elements", elements)

  const getLabelForElementRef = (elementRef, includeLabel) => {
    const el = elements.find(
      (el) => el.name === elementRef.name && el.type === elementRef.type
    )
    if (el) {
      return getLabel(el, includeLabel)
    }
    return `${elementRef.type} ${elementRef.name} - NOT FOUND`
  }

  elements.map((el) => {
    prompt.push(
      `**${getLabel(el)}**:${
        includeDoco && el.documentation.length > 0 ? ` ${el.documentation}` : ""
      }`
    )

    if (includeProperties && el.props.length > 0) {
      prompt.push(
        ` *Properties*: ${el.props.map(
          (prop) => ` ${prop.name}=${prop.value}`
        )}`
      )
    }

    const groupedConnections = el.connections.reduce((acc, curr) => {
      if (!acc[curr.type]) {
        acc[curr.type] = []
      }
      acc[curr.type].push(curr.target)
      return acc
    }, {})

    const relTypes = Object.keys(groupedConnections)

    relTypes.forEach((relType) => {
      const targetElements = groupedConnections[relType]
      prompt.push(`  **${RELATIONSHIP_DESCRIPTORS[relType]}**:`)
      for (const t in targetElements) {
        prompt.push(`  - ${getLabelForElementRef(targetElements[t], false)}`)
      }
    })
  })

  return prompt
}

export { createChatPrompt }
