import React, { useEffect, useState } from "react"
import {
  Box,
  Chip,
  Dialog,
  Switch,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material"
import Controls from "./controls/Controls"
import db from "../Firestore"
import firebase from "firebase/compat/app"
import _ from "lodash"
import { selectSelectedRules, selectMlModels } from "../redux/selectors"
import { setSelectedRules } from "../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import SmartToyIcon from "@mui/icons-material/SmartToy"
import * as colors from "@mui/material/colors"
import * as tf from "@tensorflow/tfjs"
import { spacing } from "../pages/services/styleServices"
import DraggablePaper from "../components/DraggablePaper"

const styles = {
  tagTypes: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    gap: spacing(1),
    marginBottom: spacing(1.5),
  },
  typeLabel: {
    minWidth: "3em",
  },
  ruleTag: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    gap: spacing(1),
    marginBottom: spacing(1),
  },
  ruleName: {
    marginTop: spacing(2),
    marginBottom: spacing(0.5),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  ruleTags: {
    marginLeft: spacing(6),
  },
  tagTypeChips: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: spacing(1),
  },
  mlRules: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: spacing(1),
  },
}

const RunRulesDialog = (props) => {
  const { open, setOpen, handleRunRules } = props

  const dispatch = useDispatch()

  const [rules, setRules] = useState()

  const [tags, setTags] = useState()

  const mlModels = useSelector(selectMlModels)

  const selectedRules = useSelector(selectSelectedRules)

  const getUniqueObjects = (tags) => {
    return tags.filter(
      (tag, index, arr) => arr.findIndex((t) => _.isEqual(t, tag)) === index
    )
  }

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      if (user !== null) {
        user.getIdTokenResult(false).then((token) => {
          db.collection("rules")
            .where("account_id", "==", token.claims.account_id)
            .get()
            .then((querySnapshot) => {
              const ruleData = querySnapshot.docs
                .map((doc) => {
                  return {
                    id: doc.id,
                    ...doc.data(),
                  }
                })
                .sort((a, b) => a.name.localeCompare(b.name))

              setRules(ruleData)

              const tagData = _.flatten(
                ruleData.filter((rule) => rule.tags).map((rule) => rule.tags)
              )

              setTags(getUniqueObjects(tagData))
            })
        })
      }
    })

    return unsub
  }, [])

  const handleClose = () => {
    setOpen(false)
  }

  const isTagMatch = (tag1, tag2) => {
    return tag1.type === tag2.type && tag1.label === tag2.label
  }

  const handleSelectTag = (tag) => {
    const isTagSelected = selectedRules.selectedTags.find((selectedTag) =>
      isTagMatch(tag, selectedTag)
    )

    const newSelectedTags = isTagSelected
      ? selectedRules.selectedTags.filter(
          (selectedTag) => !isTagMatch(tag, selectedTag)
        )
      : [...selectedRules.selectedTags, tag]

    // Select rules that match the selected tag

    const matchingRules = rules.filter((rule) =>
      newSelectedTags.find(
        (t) => rule.tags && rule.tags.find((tag) => isTagMatch(t, tag))
      )
    )

    const newSelectedRulesIds = matchingRules.map((rule) => rule.id)

    const newRuleState = {
      ...selectedRules,
      selectedRuleIds: newSelectedRulesIds,
      selectedTags: newSelectedTags,
    }
    dispatch(setSelectedRules(newRuleState))
  }

  const handleToggleMlRules = () => {
    const newRuleState = {
      ...selectedRules,
      mlRules: !selectedRules.mlRules,
    }
    // console.log("%ctoggle mlRules", "color:lightgreen", {
    //     new: newRuleState,
    //     old: selectedRules,
    // })
    dispatch(setSelectedRules(newRuleState))
  }

  const handleRuleSelectionChange = ({ rule, event }) => {
    console.log("event", { rule, event })

    const newSelectedRuleIds = event.target.checked
      ? [...selectedRules.selectedRuleIds, rule.id]
      : selectedRules.selectedRuleIds.filter((r) => r !== rule.id)

    const newSelectedRuleState = {
      ...selectedRules,
      selectedRuleIds: newSelectedRuleIds,
      selectedTags: selectedRules.selectedTags,
    }
    dispatch(setSelectedRules(newSelectedRuleState))
  }

  const isTagSelected = (tag) => {
    return selectedRules.selectedTags.find((t) => isTagMatch(t, tag))
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperComponent={DraggablePaper}
    >
      <DialogTitle sx={{ cursor: "move" }} id="draggable-dialog-title">
        Run Rules
      </DialogTitle>
      <DialogContent>
        {tags &&
          _.uniq(tags.map((tag) => tag.type))
            .sort()
            .map((type) => (
              <Box key={type}>
                <Box sx={styles.tagTypes}>
                  <Box sx={styles.typeLabel}>
                    <Typography variant="caption">{type}</Typography>
                  </Box>
                  <Box sx={styles.tagTypeChips}>
                    {tags
                      .filter((tag) => tag.type === type)
                      .map((tag) => (
                        <Chip
                          key={tag.label}
                          size="small"
                          label={tag.label}
                          onClick={() => handleSelectTag(tag)}
                          variant={isTagSelected(tag) ? "standard" : "outlined"}
                        />
                      ))}
                  </Box>
                </Box>
              </Box>
            ))}
        <Box sx={styles.mlRules}>
          <Switch
            checked={selectedRules.mlRules || false}
            onChange={handleToggleMlRules}
          />{" "}
          <SmartToyIcon
            sx={{
              color: selectedRules.mlRules
                ? colors.green[500]
                : colors.grey[400],
            }}
          />{" "}
          <Typography variant="caption">ML Rules</Typography>
        </Box>
        {rules &&
          rules.map((rule) => (
            <RuleSelection
              key={rule.id}
              rule={rule}
              selected={selectedRules.selectedRuleIds.includes(rule.id)}
              handleChange={handleRuleSelectionChange}
            />
          ))}
      </DialogContent>
      <DialogActions>
        <Controls.Button
          text="Close"
          variant="outlined"
          onClick={handleClose}
        />
        <Controls.Button
          text="Run Rules"
          variant="contained"
          onClick={async () => {
            console.log("%cselected rules", "color:lightgreen", {
              selectedRules,
            })
            setOpen(false)
            handleRunRules({
              rules: rules.filter((rule) =>
                selectedRules.selectedRuleIds.includes(rule.id)
              ),
              mlRules: selectedRules.mlRules,
              mlModels: mlModels,
            })
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

const RuleSelection = (props) => {
  const { rule, selected, handleChange } = props

  return (
    <Box key={rule.id}>
      <Box sx={styles.ruleName}>
        <Switch
          label={rule.name}
          checked={selected}
          onChange={(event) => handleChange({ rule, event })}
        />
        <Box>
          <Typography variant="body2">{rule.name}</Typography>
          <Typography variant="caption" color="text.secondary">
            {rule.description}
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.ruleTags}>
        {rule.tags &&
          _.uniq(rule.tags.map((tag) => tag.type)).map((tagType) => (
            <Box key={`${rule.id}-${tagType}`} sx={styles.ruleTag}>
              <Box sx={styles.typeLabel}>
                <Typography variant="caption">{tagType}</Typography>
              </Box>
              {rule.tags &&
                rule.tags
                  .filter((tag) => tag.type === tagType)
                  .map((tag) => (
                    <Chip key={tag.label} size="small" label={tag.label} />
                  ))}
            </Box>
          ))}
      </Box>
    </Box>
  )
}

export default RunRulesDialog
