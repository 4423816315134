import React, { useState } from "react"
import Header from "../components/Header"
import { Paper } from "@mui/material"
import StoryEditForm from "../components/StoryEditForm"
import { spacing } from "./services/styleServices"

const styles = {
    pageContent: {
        margin: spacing(1),
        padding: spacing(1),
    },
    tabLabel: {
        textTransform: "none",
    },
}

const StoryEdit = (props) => {
    const [title, setTitle] = useState("Create Story")

    return (
        <Header title={title}>
            <Paper sx={styles.pageContent}>
                <StoryEditForm {...props} setTitle={setTitle} />
            </Paper>
        </Header>
    )
}

export default StoryEdit
