import React, { useState } from "react"
import Svg from "./Svg"
import { useFill } from "./useFill"
import * as common from "./common"

const Flow = (props) => {
    const { x1, y1, x2, y2, showEndMarker = true, highlight, lineColor } = props

    const markerEnd = showEndMarker ? "url(#flow-end)" : ""

    const hoverColor = common.defaultLineHighlightColor
    const defaultColor = lineColor || common.defaultLineColor

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, setFill })

    const lineStyle = {
        stroke: fill,
        strokeWidth: 2,
        strokeDasharray: "4 2",
        strokeDashoffset: 6, // start with a value equal to sum of stroke-dasharray values
        animation: "flow 0.7s linear infinite", // use infinite to make the animation loop forever
    }

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <defs>
                <marker
                    id="flow-end"
                    markerWidth="7"
                    markerHeight="7"
                    refX="5"
                    refY="2.5"
                    orient="auto"
                >
                    <polygon points="0 0, 5 2.5, 0 5" />
                </marker>
            </defs>
            <line
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                stroke={fill}
                strokeWidth="2"
                strokeDasharray="4 2"
                markerEnd={markerEnd}
                style={lineStyle}
            />
        </Svg>
    )
}

export default Flow
