import React, { useMemo } from "react"
import { Box, Stack, TextField, Typography } from "@mui/material"
import { passwordStrength } from "check-password-strength"

const styles = {
  fields: {
    display: "flex",
    flexDirection: "column",
  },
}

const PasswordFields = (props) => {
  const {
    showOldPassword = false,
    handleInputChange,
    oldPassword,
    newPassword1,
    newPassword2,
    isPasswordDefined = false,
  } = props

  const pwdStrength = useMemo(() => {
    return newPassword1.trim() === ""
      ? ""
      : passwordStrength(newPassword1).value
  }, [newPassword1])

  const passwordsMatch = useMemo(() => {
    return newPassword1 === newPassword2 && newPassword1.trim() !== ""
  }, [newPassword1, newPassword2])

  const password1Label = useMemo(() => {
    return isPasswordDefined ? "Password" : "New Password"
  }, [isPasswordDefined])

  return (
    <Stack sx={styles.fields} gap={2}>
      {showOldPassword && (
        <TextField
          id="old-password"
          onChange={handleInputChange}
          name="old_password"
          label="Old Password"
          variant="outlined"
          value={oldPassword}
          type="password"
          size="small"
        />
      )}

      <TextField
        id="new-password-1"
        onChange={handleInputChange}
        name="new_password_1"
        label={password1Label}
        variant="outlined"
        value={newPassword1}
        type="password"
        size="small"
      />

      {!isPasswordDefined && (
        <TextField
          id="new-password-2"
          onChange={handleInputChange}
          name="new_password_2"
          label="New Password (Confirm)"
          variant="outlined"
          value={newPassword2}
          type="password"
          size="small"
        />
      )}

      <Box>
        {pwdStrength !== "" && (
          <Typography variant="caption">
            Password strength: {pwdStrength}
          </Typography>
        )}
      </Box>
      <Box>
        <Typography variant="caption">
          {passwordsMatch
            ? "Passwords match"
            : newPassword1 === ""
            ? ""
            : "Passwords do not match"}
        </Typography>
      </Box>
    </Stack>
  )
}

export default PasswordFields
