import React, { useState } from "react"
import Header from "../components/Header"
import PromptEditForm from "../components/PromptEditForm"

const PromptEdit = (props) => {
    const [title, setTitle] = useState("Create Prompt")

    return (
        <Header title={title}>
            <PromptEditForm {...props} setTitle={setTitle} />
        </Header>
    )
}

export default PromptEdit
