// React functional component Dialog window to enter the name for a new design

import React, { useState } from "react"
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { checkIfScopeSpecifiesOverallOutcome } from "../pages/services/chatGenerationServices"

const ViewSetDialog = ({ open, onClose, viewSet }) => {
  const [values, setValues] = useState({
    name: "",
    description: "",
    scope: "",
    overview: "",
    purpose: "",
    ...viewSet,
  })

  const [analysis, setAnalysis] = useState()

  const [isAnalysing, setAnalysing] = useState(false)

  const handleSubmit = () => {
    console.log("submitting", values)
    onClose({ action: "update", values: values })
  }

  // const handleValidateScopeAndPurpose = async () => {
  //   setAnalysis(undefined)
  //   setAnalysing(true)

  //   checkIfScopeSpecifiesOverallOutcome({
  //     overview: values.overview,
  //     purpose: values.purpose,
  //   }).then((scopeAndPurposeResult) => {
  //     setAnalysing(false)

  //     console.log("scopeAndPurposeResult", scopeAndPurposeResult)

  //     setAnalysis(scopeAndPurposeResult)
  //   })
  // }

  return (
    <Dialog open={open} onClose={(e) => onClose(undefined)}>
      <DialogTitle>Set Objective and Context</DialogTitle>
      <DialogContent>
        <Stack
          direction="column"
          spacing={2}
          sx={{ marginTop: "15px", marginBottom: "20px" }}
        >
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            value={values.name}
            onChange={(e) =>
              setValues((curr) => ({ ...curr, name: e.target.value }))
            }
            fullWidth
            variant="outlined"
            size="small"
          />
          <TextField
            label="Description"
            value={values.description}
            multiline
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) =>
              setValues((curr) => ({ ...curr, description: e.target.value }))
            }
          />
        </Stack>

        <Typography
          variant="body2"
          sx={{ fontWeight: "bold", marginBottom: "20px" }}
        >
          Context information
        </Typography>

        <Alert severity="info">
          <Typography variant="caption">
            The following <b>context</b> information is passed into OpenAI when
            generating content. This influences the relevance and quality of
            generated content. The modelling <b>objective</b> helps AIM and GPT
            analyse your current views, and suggest the next best view.
          </Typography>
        </Alert>

        <Stack direction="column" gap={3} sx={{ marginTop: "20px" }}>
          <TextField
            label="Objective"
            value={values.purpose}
            multiline
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) =>
              setValues((curr) => ({ ...curr, purpose: e.target.value }))
            }
            helperText={
              <Typography variant="caption" component={"span"}>
                What is the end goal you are seeking to achieve? e.g. Create a
                strategic roadmap to improve profitability, Find ways to improve
                the customer experience, minimise CyberRisk, scope a project?
              </Typography>
            }
          />

          {analysis?.defines_purpose && (
            <Alert severity="success">Objective is clear</Alert>
          )}

          {analysis?.context_purpose_feedback && (
            <Typography
              variant="caption"
              color="text.secondary"
              component={"span"}
              sx={{ marginLeft: "5px" }}
            >
              Alignment of Context and Objective:{" "}
              {analysis?.context_purpose_feedback}
            </Typography>
          )}

          <TextField
            label="Context - brief"
            value={values.scope}
            variant="outlined"
            size="small"
            multiline
            fullWidth
            onChange={(e) =>
              setValues((curr) => ({ ...curr, scope: e.target.value }))
            }
            helperText={
              <Typography variant="caption" component={"span"}>
                A brief 1-line summary of your context. For example: 'Commercial
                Aviation in Europe'
              </Typography>
            }
          />

          <TextField
            label="Context - full"
            value={values.overview}
            multiline
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) =>
              setValues((curr) => ({ ...curr, overview: e.target.value }))
            }
            helperText={
              <Typography variant="caption" component={"span"}>
                A more elaborate context description, For example: 'Commercial
                aviation in Europe is a highly competitive market. The market is
                dominated by a few large airlines, such as Lufthansa, Air
                France, British Airways, and KLM. These airlines have a strong
                presence in their home countries and are able to offer
                competitive prices due to their large fleets and economies of
                scale. Our airline wishes to be a major player in this market
                and is looking for ways to increase its market share.'
              </Typography>
            }
          />

          {analysis?.context_matches_purpose && (
            <Alert severity="success">Context and Objective are aligned</Alert>
          )}
        </Stack>
        {isAnalysing && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <CircularProgress size={20} />
            <Typography
              variant="caption"
              component={"span"}
              sx={{ marginLeft: "10px" }}
            >
              Analysing
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => onClose(undefined)}
          sx={{ textTransform: "none" }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={() => onClose({ action: "delete" })}
          sx={{ textTransform: "none" }}
          variant="outlined"
        >
          Delete
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{ textTransform: "none" }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ViewSetDialog
