import React, { useEffect, useState } from "react"
import Header from "../components/Header"
import { Link, Typography } from "@mui/material"
import { useHistory } from "react-router-dom"
import firebase from "firebase/compat/app"
import { Alert, AlertTitle } from "@mui/material"
import { spacing } from "../pages/services/styleServices"
//import { setMenuStatus } from "../redux/actions"
//import { useDispatch } from "react-redux"

const styles = {
  pageContent: {
    margin: spacing(2),
    padding: spacing(2),
  },
  leftPadded: {
    marginLeft: spacing(2),
  },
  link: {
    cursor: "pointer",
  },
}

function SignOut() {
  const history = useHistory()

  //const dispatch = useDispatch()

  const handleSignIn = () => {
    history.push("/SignIn")
  }

  const [signedIn, setSignedIn] = useState(false)

  useEffect(() => {
    //dispatch(setMenuStatus(false))
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      console.log("user", user)

      setSignedIn(user !== undefined && user !== null)
    })

    return unsub
  }, [])

  firebase.auth().signOut()

  return (
    <>
      <Header title="Sign Out" />

      {signedIn && (
        <Alert severity="info" sx={styles.pageContent}>
          <AlertTitle>Sign Out Status</AlertTitle>
          You are still signed in
        </Alert>
      )}

      {!signedIn && (
        <Alert severity="success" sx={styles.pageContent}>
          <AlertTitle>Sign Out Status</AlertTitle>
          You are signed out
        </Alert>
      )}

      <Typography variant="caption" sx={styles.leftPadded} component={"span"}>
        <Link color="primary" sx={styles.link} onClick={handleSignIn}>
          Sign In again...
        </Link>
      </Typography>
    </>
  )
}

export default SignOut
