// src/utils/styles.js

import { LevelFormat, AlignmentType, convertInchesToTwip } from "docx"
import { colors } from "@mui/material"

/**
 * Custom styles and numbering configurations for the Word document.
 */
const customStyles = {
  numbering: {
    config: [
      {
        // Unordered Lists Configuration
        reference: "my-bullet-points",
        levels: [
          {
            level: 0,
            format: LevelFormat.BULLET,
            text: "•",
            alignment: AlignmentType.LEFT,
            style: {
              paragraph: {
                indent: {
                  left: convertInchesToTwip(0.25), // 0.25 inch
                  hanging: convertInchesToTwip(0.25), // Hanging indent of 0.25 inch
                },
              },
            },
          },
          {
            level: 1,
            format: LevelFormat.BULLET,
            text: "◦",
            alignment: AlignmentType.LEFT,
            style: {
              paragraph: {
                indent: {
                  left: convertInchesToTwip(0.5), // 0.5 inch
                  hanging: convertInchesToTwip(0.25),
                },
              },
            },
          },
          {
            level: 2,
            format: LevelFormat.BULLET,
            text: "▪",
            alignment: AlignmentType.LEFT,
            style: {
              paragraph: {
                indent: {
                  left: convertInchesToTwip(0.75), // 0.75 inch
                  hanging: convertInchesToTwip(0.25),
                },
              },
            },
          },
          // Add more levels if needed
        ],
      },
      {
        // Ordered Lists Configuration
        reference: "numbered",
        levels: [
          {
            level: 0,
            format: LevelFormat.DECIMAL,
            text: "%1.",
            alignment: AlignmentType.LEFT,
            style: {
              paragraph: {
                indent: {
                  left: convertInchesToTwip(0.25),
                  hanging: convertInchesToTwip(0.25),
                },
              },
            },
          },
          {
            level: 1,
            format: LevelFormat.LOWER_LETTER,
            text: "%2.",
            alignment: AlignmentType.LEFT,
            style: {
              paragraph: {
                indent: {
                  left: convertInchesToTwip(0.5),
                  hanging: convertInchesToTwip(0.25),
                },
              },
            },
          },
          {
            level: 2,
            format: LevelFormat.LOWER_ROMAN,
            text: "%3.",
            alignment: AlignmentType.LEFT,
            style: {
              paragraph: {
                indent: {
                  left: convertInchesToTwip(0.75),
                  hanging: convertInchesToTwip(0.25),
                },
              },
            },
          },
          // Add more levels if needed
        ],
      },
    ],
  },
  styles: {
    paragraphStyles: [
      // Title Style
      {
        id: "Title",
        name: "Title",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          size: "30pt",
          bold: true,
          color: colors.blueGrey[300],
          font: "Aptos",
        },
        paragraph: {
          spacing: {
            after: 120, // Space after Title
            before: 120, // Space before Title (Top Margin)
          },
        },
      },
      // Heading 1 Style
      {
        id: "Heading1",
        name: "Heading 1",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          size: "28pt",
          bold: true,
          color: colors.blueGrey[800],
          font: "Aptos",
        },
        paragraph: {
          spacing: {
            after: 120, // Space after Heading 1
            before: 120, // Space before Heading 1 (Top Margin)
          },
        },
      },
      // Heading 2 Style
      {
        id: "Heading2",
        name: "Heading 2",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          size: "18pt",
          bold: true,
          color: colors.blueGrey[700],
          font: "Aptos",
        },
        paragraph: {
          spacing: {
            after: 120, // Space after Heading 2
            before: 120, // Space before Heading 2 (Top Margin)
          },
        },
      },
      // Heading 3 Style
      {
        id: "Heading3",
        name: "Heading 3",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          size: "14pt",
          bold: true,
          color: colors.grey[700],
          font: "Aptos",
        },
        paragraph: {
          spacing: {
            before: 120, // Space before Heading 3 (Top Margin)
            after: 80,    // Space after Heading 3
          },
        },
      },
      // Heading 4 Style
      {
        id: "Heading4",
        name: "Heading 4",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          size: "12pt",
          bold: true,
          color: colors.grey[900],
          font: "Aptos",
        },
        paragraph: {
          spacing: {
            before: 20, // Space before Heading 4 (Top Margin)
            after: 20,  // Space after Heading 4
          },
        },
      },
      // Normal Paragraph Style
      {
        id: "Normal",
        name: "Normal",
        basedOn: "Normal",
        next: "Normal",
        run: {
          size: 24, // 12pt (docx uses half-points)
          font: "Aptos",
        },
        paragraph: {
          spacing: {
            after: 200, // Space after Normal paragraph (10pt)
            before: 200, // Space before Normal paragraph (10pt)
          },
        },
      },
      // Props Paragraph Style
      {
        id: "Props",
        name: "Props",
        basedOn: "Normal",
        next: "Normal",
        run: {
          size: 22, // 11pt
          font: "Aptos",
        },
        paragraph: {
          spacing: {
            after: 30, // Space after Props paragraph
            before: 0,  // Space before Props paragraph
          },
        },
      },
      // Code Block Style
      {
        id: "Code",
        name: "Code",
        basedOn: "Normal",
        next: "Normal",
        run: {
          font: { name: "Courier New" },
          color: "808080",
        },
        paragraph: {
          spacing: {
            before: 100, // Space before Code block (5pt)
            after: 100,  // Space after Code block (5pt)
          },
        },
      },
      // BlockQuote Style
      {
        id: "BlockQuote",
        name: "BlockQuote",
        basedOn: "Normal",
        next: "Normal",
        quickFormat: true,
        run: {
          italics: true,
        },
        paragraph: {
          indent: {
            left: convertInchesToTwip(0.5), // Indent BlockQuote by 0.5 inch
          },
          spacing: {
            before: 100, // Space before BlockQuote (5pt)
            after: 100,  // Space after BlockQuote (5pt)
          },
        },
      },
      // Hyperlink Style
      {
        id: "Hyperlink",
        name: "Hyperlink",
        basedOn: "Normal",
        run: {
          color: "0000FF", // Blue color
          underline: {
            type: "single",
          },
        },
      },
      // Additional Styles (if needed)
      // Example: List Item Style
      // {
      //   id: "ListItem",
      //   name: "List Item",
      //   basedOn: "Normal",
      //   next: "Normal",
      //   run: {
      //     font: "Aptos",
      //   },
      //   paragraph: {
      //     spacing: {
      //       before: 0,
      //       after: 0,
      //     },
      //   },
      // },
    ],
  },
}

export { customStyles }
