import {
  Box,
  Button,
  Checkbox,
  TextField,
  Dialog,
  DialogActions,
  FormControl,
  Alert,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
} from "@mui/material"
import * as palette from "./symbols/palette"
import React, { useState, useEffect, createRef, useMemo } from "react"
import * as modelServices from "../pages/services/modelServices"
import _ from "lodash"
import xml2js from "xml2js"
import { setModelState } from "../redux/actions"
import ArchitectureIcon from "@mui/icons-material/Architecture"
import { useDispatch } from "react-redux"
import { useSnackbar } from "notistack"
import Diagram from "./Diagram"
import * as colors from "@mui/material/colors"
import * as icons from "../icons"
import { useWindowDimensions } from "../pages/services/useWindowDimensions"
import { spacing } from "../pages/services/styleServices"

const styles = {
  viewHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: spacing(1),
  },
  fileHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *": {
      fontWeight: 600,
    },
    gap: spacing(2),
    backgroundColor: colors.grey[200],
    padding: spacing(1),
    paddingLeft: spacing(2),
  },
  parentHeader: {
    backgroundColor: colors.blue[100],
    paddingTop: spacing(0.5),
    paddingBottom: spacing(0.5),
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    marginTop: spacing(2),
  },
  parentName: {
    display: "flex",
    flexDirection: "row",
    gap: spacing(2),
    alignItems: "center",
    marginBottom: spacing(1),
  },
  diagram: {
    paddingLeft: spacing(3),
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
  },
  documentation: {
    paddingLeft: spacing(4),
  },
  onlyShowSelected: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: spacing(2),
  },
  newFileName: {
    marginLeft: spacing(1),
    paddingBottom: spacing(2),
  },
}

// OpenExchange names for the different junction types
const AND_JUNCTION = "AndJunction"
const OR_JUNCTION = "OrJunction"

const CreateNewModelDialog = (props) => {
  const {
    open,
    setOpen,
    values,
    projectId,
    components,
    modelCache,
    handleFileGenerated,
  } = props

  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const { height, width = 500 } = useWindowDimensions()

  const newFileNameRef = createRef()

  // values for this form, whereas the 'values' prop is from the project edit page
  const [createValues, setCreateValues] = useState({
    selectedViews: {},
    onlyShowSelectedViews: false,
    newFileName: "",
  })

  useEffect(() => {
    if (components && values && projectId) {
      const filesToLoad = values.files.map((file) => ({
        id: projectId,
        name: values.name,
        type: "project",
        file: file,
      }))

      const componentFilesToLoad = _.flatten(
        components.map((component) =>
          component.files.map((file) => ({
            id: component.id,
            name: component.name,
            type: "component",
            file: file,
          }))
        )
      )

      const allFiles = [...filesToLoad, ...componentFilesToLoad]

      allFiles.forEach((fileInfo) => {
        loadFile(fileInfo.id, fileInfo.name, fileInfo.type, fileInfo.file)
      })

      //setFileInfo(allFiles)
    }
  }, [components, values, projectId])

  const handleClose = () => {
    setOpen(false)
  }

  const handleOK = () => {
    const isValidFileNamePattern = /^[a-z 0-9_.@()-]+\.xml$/i.test(
      createValues.newFileName
    )

    const fileNameValid =
      createValues.newFileName &&
      createValues.newFileName.trim().length <= 50 &&
      isValidFileNamePattern

    console.log(
      "fileNameValid",
      createValues.newFileName,
      fileNameValid,
      isValidFileNamePattern
    )

    if (!fileNameValid) {
      enqueueSnackbar(
        "Enter a file name ending with .xml, less than 50 characters",
        {
          variant: "info",
        }
      )
      newFileNameRef.current.scrollIntoView({ behavior: "smooth" })
    } else {
      // Create new OpenExchange file

      const xml = createOpenExchangeModel(modelCache, createValues)

      handleFileGenerated(createValues.newFileName, xml)
    }
  }

  const getModelCacheKey = (id, fileName, type) => {
    return modelServices.createModelCacheKey(fileName, id, type)
  }

  const loadFile = (id, name, type, fileName) => {
    const key = getModelCacheKey(id, fileName, type)

    const folder = { project: "projects", component: "components" }[type]

    // const cachedModel = Object.values(modelCache).find(
    //     (cacheEntry) =>
    //         cacheEntry.parent_id === key.parentId &&
    //         cacheEntry.model.file === key.fileName &&
    //         cacheEntry.type === key.type
    // )

    const cachedModel = modelServices.searchModelCache({
      modelCacheKey: key,
      modelCache: modelCache,
    })

    if (cachedModel === undefined) {
      const filePath = `accounts/${values.account_id}/${folder}/${id}/`

      modelServices.loadFile(filePath, fileName, loadModelIntoCache, {
        id,
        name,
        type,
      })
    } else {
      console.log("%c[cache load] file already loaded", "color:lightgreen", {
        key,
      })
    }

    return cachedModel
  }

  const loadModelIntoCache = (model, fileName, rawText, callbackProps) => {
    const modelState = modelServices.createModelCacheItem(
      model,
      fileName,
      callbackProps.name,
      callbackProps.id,
      callbackProps.type
    )

    console.log("%cupdate cache", "color:orange", { modelState, callbackProps })

    dispatch(setModelState(modelState))

    // Don't need to create an index -- The component should already be indexed
  }

  const handleSelectView = (event, viewKey, viewId) => {
    const newCreateValues = { ...createValues }
    if (event.target.checked) {
      newCreateValues.selectedViews[viewKey] = true
    } else {
      delete newCreateValues.selectedViews[viewKey]
    }

    setCreateValues(newCreateValues)
  }

  const handleCreateValuesChange = (event) => {
    const newCreateValues = {
      ...createValues,
      [event.target.name]: event.target.value,
    }
    setCreateValues(newCreateValues)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={width}
    >
      <DialogTitle id="form-dialog-title">Create New Model</DialogTitle>
      <DialogContent>
        <Alert severity="warning" style={{ marginBottom: "10px" }}>
          This page is in development. Any feedback welcome on how to select
          content to go into a new composite model
        </Alert>
        <Box>
          <Alert severity="info">
            Select 1 or more views, then click OK to create a new OpenExchange
            .xml model file.
          </Alert>
        </Box>
        <Box sx={styles.onlyShowSelected}>
          <Checkbox
            checked={createValues.onlyShowSelectedViews}
            name="onlyShowSelectedViews"
            onChange={(event) =>
              handleCreateValuesChange({
                target: {
                  name: event.target.name,
                  value: event.target.checked,
                },
              })
            }
          />
          <Typography variant="body2" component={"span"}>
            Only show selected views
          </Typography>
        </Box>
        <Box sx={styles.newFileName}>
          <FormControl>
            <Tooltip title="Name of OpenExchange .xml model file to be created">
              <TextField
                id="new-file-name"
                label="New Model File Name"
                ref={newFileNameRef}
                name="newFileName"
                variant="outlined"
                size="small"
                value={createValues.newFileName}
                onChange={handleCreateValuesChange}
                helperText="Enter an OpenExchange .xml file name"
              />
            </Tooltip>
          </FormControl>
        </Box>

        <Box
          sx={styles.parentHeader}
          style={{ backgroundColor: colors.pink[100] }}
        >
          <Box sx={styles.parentName}>
            <icons.ProjectIcon fontSize="small" />
            <Typography variant="h6" component={"span"}>
              {values.name}
            </Typography>
          </Box>
          <Typography
            variant="caption"
            color="textSecondary"
            component={"span"}
          >
            {values.description}
          </Typography>
        </Box>
        {values &&
          values.files.map((file) => (
            <Box key={`${projectId}-${file}`}>
              <Box sx={styles.fileHeader}>
                <ArchitectureIcon />
                <Typography component={"span"}>{file}</Typography>
              </Box>
              <ParentViews
                modelCache={modelCache}
                width={width}
                modelCacheKey={getModelCacheKey(projectId, file, "project")}
                createValues={createValues}
                handleSelectView={handleSelectView}
              />
            </Box>
          ))}
        {components &&
          components.map((component) => (
            <Box>
              <Box sx={styles.parentHeader}>
                <Box styles={styles.parentName}>
                  <icons.ComponentIcon fontSize="small" />
                  <Typography variant="h6" component={"span"}>
                    {component.name}
                  </Typography>
                </Box>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  component={"span"}
                >
                  {component.description}
                </Typography>
              </Box>
              <Box>
                {component.files &&
                  component.files.map((file) => (
                    <Box key={`${component.id}-${file}`}>
                      <Box sx={styles.fileHeader}>
                        <ArchitectureIcon />
                        <Typography component={"span"}>{file}</Typography>
                      </Box>
                      <ParentViews
                        modelCache={modelCache}
                        modelCacheKey={getModelCacheKey(
                          component.id,
                          file,
                          "component"
                        )}
                        createValues={createValues}
                        handleSelectView={handleSelectView}
                      />
                    </Box>
                  ))}
              </Box>
            </Box>
          ))}
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ textTransform: "none" }}
          onClick={handleClose}
          color="primary"
        >
          Cancel
        </Button>

        <CreateButton createValues={createValues} handleOK={handleOK} />
      </DialogActions>
    </Dialog>
  )
}

const CreateButton = (props) => {
  const { createValues, handleOK } = props

  const isButtonDisabled = useMemo(
    () => Object.keys(createValues.selectedViews).length === 0,
    [createValues]
  )

  return (
    <Button onClick={handleOK} color="primary" sx={{ textTransform: "none" }} disabled={isButtonDisabled}>
      Create New Model
    </Button>
  )
}

const ParentViews = (props) => {
  const { modelCache, modelCacheKey, width, createValues, handleSelectView } =
    props

  const [model, setModel] = useState()

  const [views, setViews] = useState([])

  const findModel = (modelCache, modelCacheKey) => {
    //TODO: replace this with a call to searchModelCache(...)
    const result = Object.values(modelCache).find(
      (entry) =>
        entry.parent_id === modelCacheKey.parentId &&
        entry.model.file === modelCacheKey.fileName &&
        entry.type === modelCacheKey.type
    )
    return result
  }

  const getViews = (modelCacheKey) => {
    const cacheItem = findModel(modelCache, modelCacheKey)
    return (cacheItem && cacheItem.model.views) || []
  }

  const [lastModelCacheKey, setLastModelCacheKey] = useState({})

  useEffect(() => {
    if (modelCache && modelCacheKey) {
      const isKeyChanged = !_.isEqual(lastModelCacheKey, modelCacheKey)

      if (isKeyChanged || views.length === 0) {
        setModel(findModel(modelCache, modelCacheKey))
        setViews(getViews(modelCacheKey))
      }

      setLastModelCacheKey(modelCacheKey)
    }
  }, [modelCache, modelCacheKey])

  return views.map((view) => {
    const viewKey = `${view.id}-${modelCacheKey.parentId}-${modelCacheKey.fileName}-${modelCacheKey.type}`

    if (
      !createValues.onlyShowSelectedViews ||
      (createValues.onlyShowSelectedViews &&
        createValues.selectedViews[viewKey])
    ) {
      return (
        <ShowView
          viewKey={viewKey}
          view={view}
          width={width}
          modelCacheKey={modelCacheKey}
          model={model}
          createValues={createValues}
          handleSelectView={handleSelectView}
        />
      )
    } else {
      return <></>
    }
  })
}

const ShowView = (props) => {
  const {
    viewKey,
    view,
    width,
    modelCacheKey,
    model,
    createValues,
    handleSelectView,
  } = props

  const [diagramProps, setDiagramProps] = useState()

  const [diagramBackgroundColor, setDiagramBackgroundColor] = useState("#fff")

  const [diagramBorderColor, setDiagramBorderColor] = useState("#fff")

  useEffect(() => {
    if (viewKey && createValues) {
      if (createValues.selectedViews[viewKey]) {
        const fill = colors.green[200]
        setDiagramProps({ backgroundColor: fill })
        setDiagramBackgroundColor(fill)
        setDiagramBorderColor(fill)
      } else {
        setDiagramProps({})
        setDiagramBackgroundColor("#fff")
        setDiagramBorderColor("#fff")
      }
    }
  }, [viewKey, createValues])

  return (
    <Box>
      <Box sx={styles.viewHeader}>
        <Checkbox
          checked={createValues.selectedViews[viewKey] || false}
          name={viewKey}
          onChange={(event) => handleSelectView(event, viewKey, view.id)}
        />
        <Box>
          <Typography variant="body2">{view.name}</Typography>
        </Box>
      </Box>

      <Box sx={styles.documentation}>
        <Typography variant="caption" color="textSecondary" component={"span"}>
          {view.documentation}
        </Typography>
      </Box>
      <Box sx={styles.diagram} style={diagramProps}>
        <ShowDiagram
          model={model}
          view={view}
          width={width}
          modelCacheKey={modelCacheKey}
          diagramBackgroundColor={diagramBackgroundColor}
          diagramBorderColor={diagramBorderColor}
        />
      </Box>
    </Box>
  )
}

const ShowDiagram = (props) => {
  const {
    model,
    view,
    width,
    modelCacheKey,
    diagramBackgroundColor,
    diagramBorderColor,
  } = props

  const [params, setParams] = useState({})

  useEffect(() => {
    const newParams = {
      modelId: modelCacheKey.parentId,
      viewId: view.id,
      fileName: modelCacheKey.fileName,
    }

    if (!_.isEqual(newParams, params)) {
      setParams(newParams)
    }
  }, [model])

  return (
    <Diagram
      params={params}
      maxWidth={width - 180}
      //maxScale={0.5}
      showRules={false}
      showLabels={true}
      model={model}
      diagramBackgroundColor={diagramBackgroundColor}
      diagramBorderColor={diagramBorderColor}
      showCreateStoryButton={false}
    />
  )
}

// Get all of the views for the given file name, so that we can populate
// them in the Views folder within the overall <organization> folder, which
// holds both views and elements

const getModelViews = (viewInfo, fileName, fileIndexes) => {
  const viewItems = viewInfo.filter(
    (viewItem) => viewItem.model.model.file === fileName
  )

  return viewItems.map((viewItem) => ({
    $: {
      identifierRef: `${viewItem.view.id}-${fileIndexes.indexOf(fileName) + 1}`,
    },
  }))
}

const getIdentifierRef = (element) => {
  return { $: { identifierRef: element.$.identifier } }
}

const boundsToOpenExchange = (bounds) => {
  return {
    h: bounds.height,
    w: bounds.width,
    x: bounds.x,
    y: bounds.y,
  }
}

const getNestedNodes = (nesting, fileIndex, viewElements) => {
  // Find the ids of those parent view elements, that are not nested within another element
  // Note this is the view id, not ArchiMate/OpenExchange element id
  // Each element on a view has a separate id specific to the view

  const parentNodes = nesting.filter(
    (element) => !nesting.find((item) => element.parent.id === item.child.id)
  )

  const parentNodes2 = _.uniqBy(
    nesting
      .filter(
        (element) =>
          !nesting.find((item) => element.parent.id === item.child.id)
      )
      .map((n) => n.parent),
    "id"
  )

  // Now recurse through the nesting, starting with the parents to generate
  // the nested OpenExchange 'node' hierarchy

  const nodeHierarchy = parentNodes2.map((parent) => {
    const viewElement = viewElements.find(
      (element) => element.diagramObject.id === parent.id
    )

    const bounds = viewElement.bounds

    const result = {
      $: {
        identifier: `${parent.id}-${fileIndex}`,
        elementRef: `${parent.archimateElement}-${fileIndex}`,
        "xsi:type": "Element",
        ...boundsToOpenExchange(bounds),
      },
    }

    if (viewElement.diagramObject.fillColor) {
      result.style = {
        fillColor: hexToRgb(viewElement.diagramObject.fillColor),
      }
    }

    const children = nesting.filter((n) => n.parent.id === parent.id)

    if (children) {
      result.node = _.flatten(
        children.map((c) =>
          getChildNodes(nesting, c.child.id, fileIndex, viewElements)
        )
      )
    }

    return result
  })

  return nodeHierarchy
}

const getChildNodes = (nesting, nodeChildId, fileIndex, viewElements) => {
  const childNodes = nesting
    .filter((node) => node.parent.id === nodeChildId)
    .map((node) => {
      const viewElement = viewElements.find(
        (element) => element.diagramObject.id === node.parent.id
      )

      const bounds = viewElement.bounds

      const result = {
        $: {
          identifier: `${node.parent.id}-${fileIndex}`,
          elementRef: `${node.parent.archimateElement}-${fileIndex}`,
          "xsi:type": "Element",
          ...boundsToOpenExchange(bounds),
        },
        node: getChildNodes(nesting, node.child.id, fileIndex, viewElements),
      }

      if (viewElement.diagramObject.fillColor) {
        result.style = {
          fillColor: hexToRgb(viewElement.diagramObject.fillColor),
        }
      }

      return result
    })

  if (childNodes.length === 0) {
    // We've reached the bottom of the hierachy so add the current child as a leaf

    const archimateElement = nesting.find(
      (node) => node.child.id === nodeChildId
    ).child.archimateElement

    const viewElement = viewElements.find(
      (element) => element.diagramObject.archimateElement === archimateElement
    )

    const bounds = viewElement.bounds

    const result = {
      $: {
        identifier: `${nodeChildId}-${fileIndex}`,
        elementRef: `${archimateElement}-${fileIndex}`,
        "xsi:type": "Element",
        ...boundsToOpenExchange(bounds),
      },
    }

    if (viewElement.diagramObject.fillColor) {
      result.style = {
        fillColor: hexToRgb(viewElement.diagramObject.fillColor),
      }
    }

    return result
  }
  return childNodes
}

// Get all the elements of the specified element type.
// elementsGroupedByLayer is already all of the elements for 1 model file grouped into ArchiMate layers
const getFolderTypeWithIdentifiers = (elementType, elementsGroupedByLayer) => {
  const elementsByType = elementsGroupedByLayer[elementType]?.map(
    (item) => item.element
  )

  // Checking if 'elementsByType' is not undefined means that we only create <organization> folders
  // in the xml file if there are elements of that type.
  if (elementsByType) {
    return [
      {
        label: { $: { "xml:lang": "en" }, _: elementType },
        item: elementsByType.map((item) => getIdentifierRef(item)),
      },
    ]
  }
  return []
}

const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        $: {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          a: 100,
        },
      }
    : null
}

// Get unique keys for all elements used in selected views
const getElementPropKeys = (viewInfo) => {
  const elementPropKeys = _.uniq(
    _.flatten(
      _.flatten(viewInfo.map((item) => item.model.model.elements)).map(
        (element) => element.properties
      )
    ).map((p) => p.key)
  )

  return elementPropKeys
}

// Get unique property keys for all non-elements used on views, e.g. Group, Note.
// These types of view elements only exist per-view, and so there is no underlying
// property definition as there is for proper ArchiMate elements.
const getViewPropKeys = (viewInfo) => {
  const viewPropKeys = _.flatten(
    _.flatten(
      viewInfo.map((item) =>
        item.view.elements.map((element) =>
          element.viewProperties.map((p) => p.key)
        )
      )
    )
  )

  return viewPropKeys
}

const getRelationshipPropKeys = (viewInfo) => {
  const relationshipPropKeys = _.flatten(
    _.flatten(
      viewInfo.map((viewItem) =>
        viewItem.model.model.elements.filter((item) => item.source)
      )
    ).map((rel) => rel.properties.map((p) => p.key))
  )

  return relationshipPropKeys
}

// Get all relationships for all elements in selected views, since OpenExchange (.xml) files
// do NOT include the relationship in the diagram/view if it is not displayed, e.g. for nested elements,
// whereas .archimate files DO include the relationship even if it is not displayed.
const getAllRelsByViewAndFile = (allViewElementsByFile) => {
  const allRelsByFileAndView = _.flatten(
    Object.values(allViewElementsByFile).map((entry) => {
      // Get all elements in this view, and get all relationships that reference them

      const requiredViewRels = entry.map((view) => {
        const viewElementIds = view.elements.map(
          (element) => element.$["identifier"]
        )

        const allModelRels = view.cacheEntry.model.elements.filter(
          (item) => item.source
        )

        const requiredRels = allModelRels.filter((rel) => {
          const sourceInView = viewElementIds.includes(rel.source)
          const targetInView = viewElementIds.includes(rel.target)

          return sourceInView && targetInView
        })

        return { relations: requiredRels, view: view.view, file: view.file }
      })

      return requiredViewRels
    })
  )
  return allRelsByFileAndView
}

const getAllElementsByView = ({ viewInfo, propertyDefs }) => {
  const allElementsByView = viewInfo.map((viewItem) => {
    const elements = viewItem.view.elements
      .filter((viewElement) => {
        return !palette.viewOnlySymbols.includes(
          viewElement.diagramObject["xsi:type"]
        )
      })
      .map((viewElement) => {
        const element = viewItem.model.model.elements.find(
          (element) => element.id === viewElement.diagramObject.archimateElement
        )

        const elementType =
          element.type === "Junction"
            ? element.junctionType === "and"
              ? AND_JUNCTION
              : OR_JUNCTION
            : element.type

        const result = {
          $: {
            identifier: viewElement.diagramObject.archimateElement,
            ["xsi:type"]: elementType,
          },
          name: { $: { "xml:lang": "en" }, _: element.name },

          //TODO: put in properties, but need to have discovered all properties first to create the key/index map
        }

        if (element.documentation.length > 0) {
          result.documentation = {
            $: { "xml:lang": "en" },
            _: element.documentation.join("\r\n"),
          }
        }

        if (element.properties.length > 0) {
          const defs = element.properties.map((property) => ({
            $: {
              propertyDefinitionRef: `prop-${
                propertyDefs.find((p) => p.identifier === property.key).index
              }`,
            },
            value: { $: { "xml:lang": "en" }, _: property.value },
          }))

          result.properties = {
            property: defs,
          }
        }

        return result
      })

    return {
      elements,
      view: viewItem.view.name,
      file: viewItem.model.model.file,
      cacheEntry: viewItem.model,
    }
  })

  return allElementsByView
}

// Get all properties across elements and relations, so that we can create the
// property definition refs
const getAllProperties = ({ viewInfo }) => {
  const relationshipKeys = getRelationshipPropKeys(viewInfo)
  const elementKeys = getElementPropKeys(viewInfo)
  const viewKeys = getViewPropKeys(viewInfo)

  const allKeys = _.uniq([...elementKeys, ...relationshipKeys, ...viewKeys])

  return allKeys
}

const getRelationships = ({
  allRelsByFileAndView,
  fileIndexes,
  propertyDefs,
}) => {
  const relationships = _.flatten(
    allRelsByFileAndView.map((entry) =>
      entry.relations.map((rel) => {
        const fileIndex = fileIndexes.indexOf(entry.file) + 1

        const result = {
          $: {
            identifier: `${rel.id}-${fileIndex}`,
            source: `${rel.source}-${fileIndex}`,
            target: `${rel.target}-${fileIndex}`,
            // Stripe 'Relationship' from the name since .xml / OpenExchange only stores the base relationship name
            ["xsi:type"]: rel.type.replace("Relationship", ""),
          },
        }

        if (rel.name) {
          result.name = {
            $: { "xml:lang": "en" },
            _: rel.name,
          }
        }

        if (rel.documentation.length > 0) {
          result.documentation = {
            $: { "xml:lang": "en" },
            _: rel.documentation.join("\r\n"),
          }
        }

        if (rel.properties.length > 0) {
          result.properties = {
            property: rel.properties.map((property) => ({
              $: {
                propertyDefinitionRef: `prop-${
                  propertyDefs.find((p) => p.identifier === property.key).index
                }`,
              },
              value: { $: { "xml:lang": "en" }, _: property.value },
            })),
          }
        }

        return result
      })
    )
  )

  return relationships
}

const getElementsGroupedByFile = ({ allElementsByFile, fileIndexes }) => {
  const elementsGroupedByFile = allElementsByFile.map((item) => {
    const index = fileIndexes.indexOf(item.file) + 1

    return {
      file: item.file,
      elements: item.elements.map((element) => {
        const result = {
          name: element.name,
          $: {
            ...element.$,
            identifier: `${element.$["identifier"]}-${index}`,
          },
        }

        if (element.documentation) {
          // Already in the correct output form, as per .xml / OpenExchange format
          result.documentation = element.documentation
        }

        if (element.properties) {
          // Already in the correct output form, as per .xml / OpenExchange format
          result.properties = element.properties
        }

        return result
      }),
    }
  })

  return elementsGroupedByFile
}

const getRelationshipsGroupedByFile = ({ allRelsByFile, fileIndexes }) => {
  const relationshipsGroupedByFile = allRelsByFile.map((item) => {
    const index = fileIndexes.indexOf(item.file) + 1
    return {
      file: item.file,
      relationships: item.relationships.map((rel) => {
        const result = {
          $: {
            ...rel,
            id: `${rel.id}-${index}`,
            source: `${rel.source}-${index}`,
            target: `${rel.target}-${index}`,
          },
        }

        return result
      }),
    }
  })

  return relationshipsGroupedByFile
}

const createOpenExchangeModel = (modelCache, createValues) => {
  //const header = '<?xml version="1.0" encoding="UTF-8"?>'

  console.log("%ccreateOpenExchangeModel", "color:orange", {
    modelCache,
    createValues,
  })

  const viewInfo = []

  Object.values(modelCache).forEach((cacheEntry) => {
    cacheEntry.model.views.forEach((view) => {
      const viewKey = `${view.id}-${cacheEntry.parent_id}-${cacheEntry.model.file}-${cacheEntry.type}`
      if (Object.keys(createValues.selectedViews).includes(viewKey)) {
        viewInfo.push({ model: cacheEntry, view: view })
      }
    })
  })

  // Get all properties across elements and relations, so that we can create the
  // property definition refs

  const allPropertyKeys = getAllProperties({ viewInfo })

  const propertyDefs = allPropertyKeys.map((key, index) => ({
    identifier: key,
    index,
  }))

  // These element types can appear on a view, but aren't ArchiMate elements

  const allElementsByView = getAllElementsByView({ viewInfo, propertyDefs })

  const allViewElementsByFile = _.groupBy(allElementsByView, "file")

  const allElementsByFile = Object.values(allViewElementsByFile).map(
    (elementsByFile) => {
      const file = elementsByFile[0].file

      const mergedElementsForFile = _.uniqBy(
        _.flatten(elementsByFile.map((item) => item.elements)),
        (element) => element.$["identifier"]
      )
      return { file: file, elements: mergedElementsForFile }
    }
  )

  const fileIndexes = _.uniq([...allElementsByFile.map((item) => item.file)])

  const allRelsByFileAndView = getAllRelsByViewAndFile(allViewElementsByFile)

  const relationships = getRelationships({
    allRelsByFileAndView,
    fileIndexes,
    propertyDefs,
  })

  const allRelsByFile = Object.values(
    _.groupBy(allRelsByFileAndView, "file")
  ).map((fileRels) => {
    return {
      file: fileRels[0].file,
      relationships: _.flatten(fileRels.map((item) => item.relations)),
    }
  })

  const elementsGroupedByFile = getElementsGroupedByFile({
    allElementsByFile,
    fileIndexes,
  })

  const relationshipsGroupedByFile = getRelationshipsGroupedByFile({
    allRelsByFile,
    fileIndexes,
  })

  const docElements = _.flatten(
    elementsGroupedByFile.map((item) => item.elements)
  )

  const elementsGroupedByViewAndLayer = elementsGroupedByFile.map((item) => {
    const elementsByLayer = item.elements.map((element) => {
      // At this stage we've already converted from the internal Archi representation of type, which means
      // that 'Junction' has been converted to either 'AndJunction' or 'OrJunction' (for OpenExchange)
      // so we need to convert it back for the purpose of doing a layer lookup.
      const archiElementType = [AND_JUNCTION, OR_JUNCTION].includes(
        element.$["xsi:type"]
      )
        ? "Junction"
        : element.$["xsi:type"]
      const elType = palette.getElementType(archiElementType)

      return {
        element,
        layer: elType.layer.name,
      }
    })

    return {
      file: item.file,
      elementsByLayer: _.groupBy(elementsByLayer, "layer"),
    }
  })

  const folderInfo = elementsGroupedByViewAndLayer.map((item) => {
    const layers = Object.keys(item.elementsByLayer).map((layer) => {
      const elements = item.elementsByLayer[layer].map(
        (element) => element.element
      )
      return {
        name: layer,
        elements,
      }
    })

    return { file: item.file, layers }
  })

  const folders = folderInfo.map((info) => {
    return {
      label: {
        $: { "xml:lang": "en" },
        _: info.file,
      },
      item: [
        {
          label: { $: { "xml:lang": "en" }, _: "Views" },

          // Get views for this file
          item: getModelViews(viewInfo, info.file, fileIndexes),
        },
        ...getFolderTypeWithIdentifiers(
          palette.LAYER_NAME_BUSINESS,
          elementsGroupedByViewAndLayer.find((item) => item.file === info.file)
            .elementsByLayer
        ),
        ...getFolderTypeWithIdentifiers(
          palette.LAYER_NAME_APPLICATION,
          elementsGroupedByViewAndLayer.find((item) => item.file === info.file)
            .elementsByLayer
        ),
        ...getFolderTypeWithIdentifiers(
          palette.LAYER_NAME_TECHNOLOGY,
          elementsGroupedByViewAndLayer.find((item) => item.file === info.file)
            .elementsByLayer
        ),
        ...getFolderTypeWithIdentifiers(
          palette.LAYER_NAME_IMPLEMENTATION,
          elementsGroupedByViewAndLayer.find((item) => item.file === info.file)
            .elementsByLayer
        ),
        ...getFolderTypeWithIdentifiers(
          palette.LAYER_NAME_MOTIVATION,
          elementsGroupedByViewAndLayer.find((item) => item.file === info.file)
            .elementsByLayer
        ),
        ...getFolderTypeWithIdentifiers(
          palette.LAYER_NAME_STRATEGY,
          elementsGroupedByViewAndLayer.find((item) => item.file === info.file)
            .elementsByLayer
        ),
        {
          label: {
            $: { "xml:lang": "en" },
            _: "Relations",
          },
          item: relationshipsGroupedByFile
            .find((item) => item.file === info.file)
            .relationships.map((rel) => ({ $: { identifierRef: rel.$.id } })),
        },
      ],
    }
  })

  const propDefs = propertyDefs.map((def) => ({
    $: {
      identifier: `prop-${def.index}`,
      type: "string",
    },
    name: { _: def.identifier },
  }))

  const model = {
    $: {
      xmlns: "http://www.opengroup.org/xsd/archimate/3.0/",
      ["xmlns:xsi"]: "http://www.w3.org/2001/XMLSchema-instance",
      ["xsi:schemaLocation"]:
        "http://www.opengroup.org/xsd/archimate/3.0/ http://www.opengroup.org/xsd/archimate/3.1/archimate3_Diagram.xsd",
      identifier: "id-b6ca4ed166644179aebbce873acb779f",
    },
    name: { $: { "xml:lang": "en" }, _: createValues.newFileName },
    elements: {
      element: docElements,
    },
    relationships: {
      relationship: relationships,
    },
    organizations: {
      item: folders,
    },
    propertyDefinitions: {
      propertyDefinition: propDefs,
    },
    views: {
      diagrams: {
        view: viewInfo.map((item) => {
          const fileIndex = fileIndexes.indexOf(item.model.model.file) + 1

          // Get all ids that appear within the nesting. Note that the nesting only
          // includes nodes that have a nesting relationship, not top level / unnested nodes
          const nestingIds = _.flatten(
            _.uniq(
              item.view.nesting.map((item) => [item.parent.id, item.child.id])
            )
          )

          const unNestedNodes = item.view.elements
            .filter((element) => !nestingIds.includes(element.diagramObject.id))
            .map((element) => {
              const type = element.diagramObject["xsi:type"]

              const props = {}

              let label = ""
              let viewRef

              let excludeElement = false

              if (type === "archimate:DiagramObject") {
                props["xsi:type"] = "Element"
                props.elementRef = `${element.diagramObject.archimateElement}-${fileIndex}`
              } else if (type === "archimate:Note") {
                props["xsi:type"] = "Label"
                label = element.noteContent.join("\r\n")
              } else if (type === "archimate:Group") {
                props["xsi:type"] = "Container"
                label = element.diagramObject.name
              } else if (type === "archimate:DiagramModelReference") {
                // OpenExchange represents a DiagramModelReference (link from one view to another) as a "Label" with a
                // viewRef element following the style element, e.g. <viewRef ref="id-541cda6211b044719600cdbd1a90ec08" />
                // The 'model' attribute is the id of the target view
                props["xsi:type"] = "Label"
                label = "Target view" // todo: get target view name
                viewRef = {
                  $: { ref: `${element.diagramObject.model}-${fileIndex}` },
                }

                // Check if the user has even included the target view in the list of selected views.
                // If they have not, then there is nowhere to link to, and so we should exclude this element.

                const isTargetViewIncluded =
                  viewInfo.find(
                    (item) => item.view.id === element.diagramObject.model
                  ) !== undefined

                excludeElement = !isTargetViewIncluded
              } else {
                console.log("%cunknown type", "color: red", element)
              }

              // Filter out this element
              if (excludeElement) {
                return undefined
              }

              const result = {
                $: {
                  identifier: `${element.diagramObject.id}-${fileIndex}`,
                  ...props,
                  ...boundsToOpenExchange(element.bounds),
                },
                label: { _: label, $: { "xml:lang": "en" } },
              }

              if (element.diagramObject.fillColor) {
                result.style = {
                  fillColor: hexToRgb(element.diagramObject.fillColor),
                }
              }

              if (viewRef) {
                result.viewRef = viewRef
              }

              if (label === "") {
                delete result.label
              }

              return result
            })
            .filter((item) => item !== undefined)

          const nestedNodes = getNestedNodes(
            item.view.nesting,
            fileIndex,
            item.view.elements
          )

          console.log("nestedNodes", nestedNodes)

          const connections = _.flatten(
            item.view.elements
              .map((element) => {
                // Merge in bounds so it gets passed down to the next level,
                // where we need to know the bounds to calculate and bendpoints
                return element.sourceConnections.map((sc) => ({
                  ...sc,
                  element_bounds: element.bounds,
                }))
              })
              .map((cnxs) =>
                cnxs.map((sc) => {
                  const props = {}

                  if (sc.connection.archimateRelationship) {
                    props.relationshipRef = `${sc.connection.archimateRelationship}-${fileIndex}`
                  }

                  let bendpoints

                  if (sc.bendpoints) {
                    // To calculate OpenExchange bendpoints from ArchiMate we
                    // find the mid point of the ArchiMate element and add the offset

                    bendpoints = sc.bendpoints?.map((bp) => ({
                      $: {
                        x:
                          bp.startX +
                          (sc.element_bounds.x + sc.element_bounds.width / 2),
                        y:
                          bp.startY +
                          (sc.element_bounds.y + sc.element_bounds.height / 2),
                      },
                    }))
                  }

                  const result = {
                    $: {
                      "xsi:type": props.relationshipRef
                        ? "Relationship"
                        : "Line",
                      identifier: `${sc.connection.id}-${fileIndex}`,
                      source: `${sc.connection.source}-${fileIndex}`,
                      target: `${sc.connection.target}-${fileIndex}`,
                      ...props,
                    },
                  }

                  if (sc.connection.lineColor) {
                    result.style = {
                      lineColor: hexToRgb(sc.connection.lineColor),
                    }
                  }

                  if (bendpoints) {
                    result.bendpoint = bendpoints
                  }

                  return result
                })
              )
          )

          // Combine both those nodes that are either a parent or child, i.e. nested
          // as well as any node that is unnested
          const allNodes = [...nestedNodes, ...unNestedNodes]

          const result = {
            name: { $: { "xml:lang": "en" }, _: item.view.name },
            $: {
              identifier: `${item.view.id}-${fileIndex}`,
              "xsi:type": "Diagram",
            },
            node: allNodes,
            connection: connections,
          }

          return result
        }),
      },
    },
  }

  if (propDefs.length === 0) {
    // OpenExchange format doesn't like an empty property definitions section
    delete model.propertyDefinitions
  }

  if (relationships.length === 0) {
    // OpenExchange format doesn't like an empty relationships section
    delete model.relationships
  }

  const doc = { model }

  console.log("%cdoc", "color:lightgreen", { doc })

  var builder = new xml2js.Builder()
  var xml = builder.buildObject(doc)

  console.log("%cxml", "color:lightgreen", xml)

  return xml
}

export default CreateNewModelDialog
