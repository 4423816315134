import React, { useState } from "react"
import * as common from "./common"
import { deepPurple } from "@mui/material/colors"
import BaseShapeOctagon from "./BaseShapeOctagon"
import BaseShapeRoundedRect from "./BaseShapeRoundedRect"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const styles = {
    exclamation: {
        fontWeight: 700,
        fontSize: "13px",
    },
}

const Principle = (props) => {
    const { x, y, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = deepPurple[200]
    const defaultColor = fillColor || deepPurple[100]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <BaseShapeOctagon {...props} fill={fill} setFill={handleFill} />

            <svg x={x + width - 23} y={y + 2} width={20} height={20}>
                <BaseShapeRoundedRect
                    x={3}
                    y={3}
                    r={2}
                    height={15}
                    width={10}
                    fill="none"
                    stroke={common.outlineColor}
                />

                <text x={7.5} y={15.5} style={styles.exclamation}>
                    !
                </text>
            </svg>

            <ElementText {...props} label={label} x={props.x + 3} />
        </Svg>
    )
}

export default Principle
