// src/components/TiptapEditor.jsx

import React, { useEffect } from "react"
import { useEditor, EditorContent } from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import Link from "@tiptap/extension-link" // Import the Link extension
// Removed explicit CodeBlock import
import { Button } from "@mui/material"
import UnknownNode from "./UnknownNode"

const TiptapEditor = ({ content, onUpdate }) => {
  // Initialize the editor with necessary extensions
  const editor = useEditor({
    extensions: [
      StarterKit, // StarterKit already includes CodeBlock
      Link.configure({
        openOnClick: false, // Prevent links from navigating within the editor
        HTMLAttributes: {
          target: "_blank", // Ensure links open in a new tab when rendered externally
          rel: "noopener noreferrer", // Security attributes
          style: "cursor: pointer; color: blue; text-decoration: underline;", // Inline styles for links
        },
      }),
      UnknownNode,
    ],
    content: content, // Initial content as an HTML string
    // Removed onUpdate from here to handle it manually
  })

  // Handle updates to the content prop
  useEffect(() => {
    if (editor && content) {
      editor.commands.setContent(content, false) // The second parameter prevents selection loss
    }
  }, [content, editor])

  // Add event listeners for link clicks and blur event
  useEffect(() => {
    if (editor) {
      const proseMirror = editor.view.dom
      // Remove the outline on focus
      proseMirror.style.outline = "none"

      // Function to handle link clicks
      const handleLinkClick = (event) => {
        const target = event.target.closest("a")
        if (target && target.href) {
          event.preventDefault() // Prevent the editor from navigating
          window.open(target.href, "_blank", "noopener,noreferrer") // Open the link in a new tab/window
        }
      }

      // Function to handle blur event to trigger onUpdate
      const handleBlur = () => {
        if (onUpdate && typeof onUpdate === "function") {
          const html = editor.getHTML()
          onUpdate(html)
        }
      }

      // Add event listeners for clicks and blur
      proseMirror.addEventListener("click", handleLinkClick)
      proseMirror.addEventListener("blur", handleBlur)

      // Ensure the outline stays removed when focused or clicked
      const handleFocus = () => {
        proseMirror.style.outline = "none"
      }

      proseMirror.addEventListener("focus", handleFocus)
      proseMirror.addEventListener("mousedown", handleFocus)

      // Clean up event listeners when the component unmounts
      return () => {
        proseMirror.removeEventListener("click", handleLinkClick)
        proseMirror.removeEventListener("blur", handleBlur)
        proseMirror.removeEventListener("focus", handleFocus)
        proseMirror.removeEventListener("mousedown", handleFocus)
      }
    }
  }, [editor, onUpdate])

  if (!editor) {
    return null
  }

  // Inline styles for simplicity; consider using styled-components or other CSS-in-JS solutions in production
  const styles = {
    editorContainer: {
      border: "1px solid #d3d3d3",
      borderRadius: "4px",
      padding: "8px",
      margin: "20px auto",
      backgroundColor: "#fff",
    },
    toolbar: {
      display: "flex",
      borderBottom: "1px solid #d3d3d3",
      paddingBottom: "4px",
      marginBottom: "8px",
    },
    editor: {
      minHeight: "200px",
      outline: "none", // Remove default outline
      border: "none",
      boxShadow: "none",
      padding: "0",
      fontSize: "16px",
      lineHeight: "1.5",
      color: "#000",
    },
  }

  // Reusable ToolbarButton component
  const ToolbarButton = ({ onClick, isActive, ariaLabel, children }) => (
    <Button
      onMouseDown={(event) => {
        event.preventDefault() // Prevents the button from taking focus
        onClick() // Executes the formatting command
      }}
      aria-label={ariaLabel}
      size="small"
      sx={{
        backgroundColor: isActive ? "#e0e0e0" : "transparent",
        "&:hover": {
          backgroundColor: "#f0f0f0",
        },
        width: "30px",
        border: "none",
        padding: "8px",
        cursor: "pointer",
        fontSize: "16px",
        marginRight: "4px",
        borderRadius: "4px",
        transition: "background-color 0.3s",
      }}
    >
      {children}
    </Button>
  )

  return (
    <div style={styles.editorContainer}>
      {/* Toolbar */}
      <div style={styles.toolbar}>
        <ToolbarButton
          onClick={() => editor.chain().focus().toggleBold().run()}
          isActive={editor.isActive("bold")}
          ariaLabel="Toggle Bold"
        >
          <strong>B</strong>
        </ToolbarButton>
        <ToolbarButton
          onClick={() => editor.chain().focus().toggleItalic().run()}
          isActive={editor.isActive("italic")}
          ariaLabel="Toggle Italic"
        >
          <em>I</em>
        </ToolbarButton>
        {/* Code Block Toolbar Button */}
        <ToolbarButton
          onClick={() => editor.chain().focus().toggleCodeBlock().run()}
          isActive={editor.isActive("codeBlock")}
          ariaLabel="Toggle Code Block"
        >
          {"</>"}
        </ToolbarButton>
        {/* Existing Line Break Button */}
        <ToolbarButton
          onClick={() => editor.chain().focus().setHardBreak().run()}
          isActive={false} // hardBreak doesn't have an active state
          ariaLabel="Insert Line Break"
        >
          <span aria-hidden="true">&#8629;</span> {/* Unicode for line break symbol */}
        </ToolbarButton>
      </div>

      {/* Editor */}
      <EditorContent editor={editor} style={styles.editor} />
    </div>
  )
}

export default TiptapEditor
