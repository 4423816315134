import React, { useState, useEffect } from "react"
import db from "../Firestore"
import UserCard from "./UserCard"
import { Grid, Box, Fab } from "@mui/material"
import * as dataServices from "../pages/services/dataServices"
import firebase from "firebase/compat/app"
import AddIcon from "@mui/icons-material/Add"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import useAccountStatus from "./useAccountStatus"

const styles = {
    gridContainer: {
        paddingLeft: "5px",
        paddingRight: "5px",
    },
    fab: {
        position: "fixed",
        bottom: 16,
        right: 16,
        top: "auto",
        left: "auto",
    },
}

function UserCards(props) {
    const [users, setUsers] = useState([])

    const [maxModified, setMaxModified] = useState()

    const COLLECTION_NAME = "users"

    const [user, setUser] = useState()

    const [accountId, setAccountId] = useState()

    const { isActive } = useAccountStatus()

    const history = useHistory()

    useEffect(() => {
        const unsub = firebase.auth().onAuthStateChanged((user) => {
            console.log("### user changed", user)
            setUser(user)
            user.getIdTokenResult(false).then((token) => {
                console.log("### setting account id", token.claims.account_id)
                setAccountId(token.claims.account_id)
            })
        })

        return unsub
    }, [])

    // Listen for changes

    useEffect(() => {
        if (user === undefined || accountId === undefined) {
            return
        }

        //console.log('listen for changes', 'userDetails', userDetails)

        const unsubscribe = db
            .collection(COLLECTION_NAME)
            .where("modified", ">=", dataServices.localTimestamp())
            .where("account_id", "==", accountId)
            .onSnapshot((querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    //console.log("=== job change ===", change.type, change.doc.data())

                    // trigger refresh
                    setMaxModified(change.doc.data().modified)
                })
            })

        return unsubscribe // gets called on unmount
    }, [user, accountId])

    // Get most recent modified record

    useEffect(() => {
        if (user === undefined || accountId === undefined) {
            return
        }

        const unsubscribe = db
            .collection(COLLECTION_NAME)
            .where("account_id", "==", accountId)
            .orderBy("modified", "desc")
            .limit(20)
            .onSnapshot((querySnapshot) => {
                let newMaxModified = null
                querySnapshot.docChanges().forEach((change) => {
                    if (
                        newMaxModified === null ||
                        change.doc.data().modified.seconds > newMaxModified.seconds
                    ) {
                        newMaxModified = change.doc.data().modified
                    }
                })
                if (newMaxModified !== null && newMaxModified !== maxModified) {
                    console.log("setMaxModified", newMaxModified, maxModified)
                    setMaxModified(newMaxModified)
                }
            })

        return unsubscribe
    }, [user, accountId])

    const [lastRetrieved, setLastRetrieved] = useState()

    // Load users

    const [reloadState, setReloadState] = useState({})

    useEffect(() => {
        if (user === undefined || accountId === undefined) {
            return
        }

        const newReloadState = { user, accountId, maxModified }

        if (_.isEqual(reloadState, newReloadState)) {
            return
        }

        setReloadState(newReloadState)

        console.log("update last retrieved", { maxModified, lastRetrieved, newReloadState })
        setLastRetrieved(maxModified)

        let query = db.collection(COLLECTION_NAME).where("account_id", "==", accountId)

        console.log("%cloading users", "color: lightblue", { user, accountId, maxModified })
        dataServices.loadData("(Load users)", query).then((users) => setUsers(users))
    }, [user, accountId, maxModified])

    const handleAddUserInvite = () => {
        history.push("/invites")
    }

    return (
        <Box>
            <Grid container direction="row" spacing={2} flex="wrap" sx={styles.gridContainer}>
                {users.map((user) => (
                    <UserCard key={user.id} user={user} />
                ))}
            </Grid>
            <Fab
                color="primary"
                aria-label="add"
                sx={styles.fab}
                onClick={handleAddUserInvite}
                disabled={!isActive()}
            >
                <AddIcon />
            </Fab>
        </Box>
    )
}

export default UserCards
