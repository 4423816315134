import db from "../../Firestore"
import firebase from "firebase/compat/app"
import _ from "lodash"

const FROM_CACHE = { source: "cache" }

// call this when you are sure the user exists
const getUserByUid = async (uid) => {
    //TODO: try getting from cache first
    const userDoc = await db.collection("users").doc(uid).get()
    //console.log("user exists?", uid, userDoc.exists)
    if (userDoc.exists) {
        return userDoc.data()
    }
    return null
}

const getInviteForUser = (email) => {
    return new Promise((resolve, reject) => {
        const query = db.collection("invites").where("email", "==", email).limit(1)

        query.get().then((querySnapshot) => {
            let data = querySnapshot.docs.map(function (doc) {
                return {
                    id: doc.id,
                    ...doc.data(),
                }
            })

            if (data.length === 1) {
                resolve(data[0])
            }
        })
    })
}

const getAccountById = async (accountId) => {
    //console.log("getting account " + accountId)

    return await db
        .collection("accounts")
        .doc(accountId)
        .get()
        .then((accountDoc) => {
            if (accountDoc.exists) {
                return accountDoc.data()
            }
            return { error: "Account not found" }
        })
        .catch((error) => {
            console.log("%cError getting account:", "color:red", error)
            throw error
        })
}

const deleteParentModelIndexes = async (parentId, accountId) => {
    const modelIndexes = await db
        .collection("model_index")
        .where("account_id", "==", accountId)
        .where("parent_id", "==", parentId)
        .get()

    modelIndexes.docs.forEach(async (doc) => {
        await db.collection("model_index").doc(doc.id).delete()
    })
}

// parentId is either a projectId or componentId
const deleteExistingModelIndex = async (accountId, parentId, fileName) => {
    //console.log("%cfind existing model index", "color:yellow", { accountId, parentId, fileName })
    const existingDocs = await db
        .collection("model_index")
        .where("account_id", "==", accountId)
        .where("parent_id", "==", parentId)
        .where("file_name", "==", fileName)
        .get()

    // console.log("%cexisting indexes", "color:yellow", {
    //     count: existingDocs.docs.length,
    // })

    if (existingDocs.docs.length > 0) {
        existingDocs.docs.forEach(async (doc) => {
            console.log("%cdeleting model index", "color:yellow", doc.data())
            await db.collection("model_index").doc(doc.id).delete()
        })
    }
}

const getStories = async (parentId, accountId) => {
    return await db
        .collection("stories")
        .where("parent_id", "==", parentId)
        .where("account_id", "==", accountId)
        .get()
        .then((querySnapshot) => {
            let data = querySnapshot.docs.map(function (doc) {
                return {
                    id: doc.id,
                    ...doc.data(),
                }
            })
            return data
        })
}

const getStoriesById = async ({ accountId, storyIds }) => {
    if (storyIds.length > 10) {
        //console.error("WARNING: trimming story IDs to 10. max allowed by firestore")
        storyIds = storyIds.slice(0, 10)
    }

    let stories = await getStoriesByIdWithSource({ accountId, storyIds }, FROM_CACHE)

    if (stories.length !== storyIds.length) {
        stories = await getStoriesByIdWithSource({ accountId, storyIds }, {})
        //console.log("%cgetStoriessById - source: SERVER. rows=>", "color:red", stories.length)
    }

    return stories
}

const getStoriesByIdWithSource = async ({ accountId, storyIds }, source) => {
    let storyDocs = await db
        .collection("stories")
        .where("account_id", "==", accountId)
        .where(firebase.firestore.FieldPath.documentId(), "in", storyIds)
        .get(source)

    const stories = storyDocs.docs.map((storyDoc) => {
        return {
            id: storyDoc.id,
            ...storyDoc.data(),
        }
    })

    return stories
}

const getComponentsByIdWithSource = async (accountId, componentIds, source) => {
    let componentDocs = await db
        .collection("components")
        .where("account_id", "==", accountId)
        .where(firebase.firestore.FieldPath.documentId(), "in", componentIds)
        .get(source)

    const components = componentDocs.docs.map((componentDoc) => {
        return {
            id: componentDoc.id,
            ...componentDoc.data(),
        }
    })

    return components
}

const getProjectsByIdWithSource = async (projectIds, source) => {
    //console.log("getProjectsByIdWithSource", projectIds, source)
    let projectDocs = await db
        .collection("projects")
        .where(firebase.firestore.FieldPath.documentId(), "in", projectIds)
        .get(source)

    const projects = projectDocs.docs.map((projectDoc) => {
        return {
            id: projectDoc.id,
            ...projectDoc.data(),
        }
    })

    return projects
}

const getComponentsById = async (accountId, componentIds) => {
    // Trim ids list to 10 (max allowed by firestore)
    if (componentIds.length > 10) {
        //console.error("WARNING: trimming component IDs to 10. max allowed by firestore")
        componentIds = componentIds.slice(0, 10)
    }

    let components = await getComponentsByIdWithSource(accountId, componentIds, FROM_CACHE)

    if (components.length !== componentIds.length) {
        components = await getComponentsByIdWithSource(accountId, componentIds, {})
        //console.log("%cgetComponentsById - source: SERVER. rows=>", "color:red", components.length)
    }

    return components
}

const getProjectsById = async (projectIds) => {
    // Trim ids list to 10 (max allowed by firestore)
    if (projectIds.length > 10) {
        //console.error("WARNING: trimming project IDs to 10. max allowed by firestore")
        projectIds = projectIds.slice(0, 10)
    }

    let projects = await getProjectsByIdWithSource(projectIds, FROM_CACHE)

    if (projects.length !== projectIds.length) {
        projects = await getProjectsByIdWithSource(projectIds, {})
        //console.log("%cgetProjectsById - source: SERVER. rows=>", "color:red", projects.length)
    }

    return projects
}

const splitIdsIntoChunks = (ids, chunkSize) => {
    const idsCopy = [...ids] // take a copy, otherwise this function modifies the underlying 'ids' array as a side-effect
    const chunks = []
    while (idsCopy.length) {
        chunks.push(idsCopy.splice(0, chunkSize))
    }
    return chunks
}

const MAX_FIRESTORE_IN_CLAUSE_SIZE = 10

const getComponentsByIdChunks = async (accountId, componentIds) => {
    //console.log("getComponentsByIdChunks", { accountId, componentIds })
    const componentIdChunks = splitIdsIntoChunks(componentIds, MAX_FIRESTORE_IN_CLAUSE_SIZE)

    const loadComponentPromises = componentIdChunks.map((chunk) =>
        getComponentsById(accountId, chunk)
    )

    const componentChunks = await Promise.all(loadComponentPromises)

    // concat arrays
    const components = [].concat(...componentChunks)

    return components
}

const getProjectsByIdChunks = async (projectIds) => {
    const projectIdsChunks = splitIdsIntoChunks(projectIds, MAX_FIRESTORE_IN_CLAUSE_SIZE)

    const loadProjectPromises = projectIdsChunks.map((chunk) => getProjectsById(chunk))

    const projectChunks = await Promise.all(loadProjectPromises)

    // concat arrays
    const projects = [].concat(...projectChunks)

    return projects
}

const deleteStory = async ({ storyId, parentId, type }) => {
    //console.log("deleteStory", { storyId, parentId, type })
    db.collection("stories")
        .doc(storyId)
        .delete()
        .then(() => {
            // setStories(stories.filter((s) => s.id !== storyId))
            //enqueueSnackbar("Story deleted", { variant: "success" })
        })
        .then(() => {
            // Remove story from parent story summary

            let collectionName

            if (type === "project") {
                collectionName = "projects"
            } else if (type === "component") {
                collectionName = "components"
            }

            db.collection(collectionName)
                .doc(parentId)
                .get()
                .then((doc) => {
                    const existingStories = doc.data().stories || []
                    const newStoriesSummary = existingStories.filter((s) => s.id !== storyId)

                    console.log('update parent stories', { existingStories, newStoriesSummary })
                    
                    db.collection(collectionName).doc(parentId).update(
                        {
                            stories: newStoriesSummary,
                            modified: localTimestamp(),
                        },
                        { merge: true }
                    )
                })
        })
}

const createInvite = async (invite, accountId) => {
    const account = await getAccountById(accountId)

    const inviteRec = {
        ...invite,
        created: serverTimestamp(),
        account_id: accountId,
        account_name: account.name,
    }
    console.log("create invite", inviteRec)

    await db
        .collection("invites")
        .add(inviteRec)
        .then((result) => console.log("added invite"))
        .catch((err) => console.error(`error adding invite - ${err}`))
}

const deleteInvite = async (inviteId) => {
    await db.collection("invites").doc(inviteId).delete()
}

const getUsersByIdWithSource = async (userIds, source) => {
    let userDocs = await db
        .collection("users")
        .where(firebase.firestore.FieldPath.documentId(), "in", userIds)
        .get(source)

    const users = userDocs.docs.map((userDoc) => {
        return {
            id: userDoc.id,
            ...userDoc.data(),
        }
    })

    return users
}

const getUsersById = async (userIds) => {
    // Trim ids list to 10 (max allowed by firestore)
    if (userIds.length > 10) {
        console.error("WARNING: trimming user IDs to 10. max allowed by firestore")
        userIds = userIds.slice(0, 10)
    }

    let users = await getUsersByIdWithSource(userIds, FROM_CACHE)

    if (users.length !== userIds.length) {
        users = await getUsersByIdWithSource(userIds, {})
        console.log("%cgetUsersById - source: SERVER. rows=>", "color:red", users.length)
    }

    return users
}

const getUsersByIdChunks = async (userIds) => {
    const userIdChunks = splitIdsIntoChunks(userIds, MAX_FIRESTORE_IN_CLAUSE_SIZE)

    console.log("%cRetrieving users by id", "color:yellow", {
        userIds,
        userIdChunks,
    })

    const loadUserPromises = userIdChunks.map((chunk) => getUsersById(chunk))

    const userChunks = await Promise.all(loadUserPromises)

    // concat arrays
    const users = [].concat(...userChunks)

    return users
}

const getUser = (emailAddr, accountId) => {
    return new Promise((resolve, reject) => {
        db.collection("users")
            .where("email", "==", emailAddr)
            .where("account_id", "==", accountId)
            .limit(1)
            .get()
            .then((userData) => {
                const users = []

                userData.docs.forEach((doc) => {
                    users.push({
                        ...doc.data(),
                        id: doc.id,
                    })
                })

                console.log("DS getUser - server")

                if (users.length === 1) {
                    resolve(users[0])
                } else {
                    reject("User not found " + emailAddr)
                }
            })
            .catch((error) => reject(error))
    })
}

const getUserFromCache = (emailAddr, accountId) => {
    console.log("getUsersFromCache", emailAddr, accountId)

    return new Promise((resolve, reject) => {
        db.collection("users")
            .where("email", "==", emailAddr)
            .where("account_id", "==", accountId)
            .get(FROM_CACHE)
            .then((userData) => {
                const users = []

                userData.docs.forEach((doc) => {
                    users.push({
                        ...doc.data(),
                        id: doc.id,
                    })
                })

                if (users.length > 0) {
                    console.log("DS getUser - cache")

                    resolve(users[0])
                } else {
                    resolve(getUser(emailAddr, accountId))
                }
            })
            .catch((error) => {
                resolve(getUser(emailAddr, accountId))
            })
    })
}

const findInviteByEmail = async (email, accountId) => {
    const query = db
        .collection("invites")
        .where("account_id", "==", accountId)
        .where("email", "==", email)
        .limit(1)

    const invites = await find(query, FROM_CACHE)

    console.log("invites for", email, accountId, "=>", invites)

    return invites
}

const getCurrentUser = async () => {
    const uid = firebase.auth().currentUser.uid

    const userDoc = await db.collection("users").doc(uid).get()
    return userDoc.data()
}

const getStoryParent = async (storyId) => {
    const storyDoc = await db.collection("stories").doc(storyId).get()
    const story = storyDoc.data()

    const parentType = { project: "projects", component: "components" }[story.type]

    if (!parentType) {
        throw new Error(`Invalid story type ${story.type}`)
    }

    const parentDoc = await db.collection(parentType).doc(story.parent_id).get()
    return parentDoc.data()
}

const getProvidersByIdWithSource = async (providerIds, source) => {
    let providerDocs = await db
        .collection("providers")
        .where(firebase.firestore.FieldPath.documentId(), "in", providerIds)
        .get(source)

    const providers = providerDocs.docs.map((providerDoc) => {
        return {
            id: providerDoc.id,
            ...providerDoc.data(),
        }
    })

    return providers
}

const getProvidersById = async (providerIds) => {
    // Trim ids list to 10 (max allowed by firestore)
    if (providerIds.length > 10) {
        console.error("WARNING: trimming provider IDs to 10. max allowed by firestore")
        providerIds = providerIds.slice(0, 10)
    }

    let providers = await getProvidersByIdWithSource(providerIds, FROM_CACHE)
    if (providers.length !== providerIds.length) {
        providers = await getProvidersByIdWithSource(providerIds, {})
    }
    return providers
}

const getProviderFromCache = async (providerId) => {
    return new Promise((resolve, reject) => {
        db.collection("providers")
            .doc(providerId)
            .get(FROM_CACHE)
            .then((provider) => {
                console.log("DS getProvider - cache")

                resolve(provider.data())
            })
            .catch((error, code) => {
                db.collection("providers")
                    .doc(providerId)
                    .get()
                    .then((provider) => {
                        console.log("DS getProvider - server")
                        resolve(provider.data())
                    })
            })
    })
}

// Check if project or component stories attribute  matches the stories just loaded

const updateStoriesSummary = async (collection, parentId, parentStories, stories) => {
    if (!["projects", "components"].includes(collection)) {
        console.error(
            `Expecting collection name to be 'projects' or 'components' but got '${collection}`
        )
        return
    }

    const mapper = (story) => ({ id: story.id, name: story.name })
    const parentStoriesSummary = parentStories?.map(mapper) || []
    const storySummary = stories.map(mapper)

    if (!_.isEqual(parentStoriesSummary, storySummary)) {
        console.log(`%cupdate ${collection} stories`, "color:lightgreen", {
            storySummary,
            parentStoriesSummary,
        })

        await db
            .collection(collection)
            .doc(parentId)
            .update({ stories: storySummary, modified: localTimestamp() }, { merge: true })
    }

    return storySummary
}

const getProvidersByAccountId = async (accountId, cacheFirst) => {
    if (accountId === undefined) {
        return []
    }

    const query = db.collection("providers").where("account_id", "==", accountId).orderBy("name")

    let providerDocs

    if (cacheFirst) {
        providerDocs = await query.get(FROM_CACHE)
    }

    if (!cacheFirst || providerDocs.size === 0) {
        providerDocs = await query.get()
    }

    const providers = providerDocs.docs.map((providerDoc) => {
        return {
            id: providerDoc.id,
            ...providerDoc.data(),
        }
    })

    return providers
}

const createUserIfRequired = (uid, email, displayName, phone) => {
    return new Promise((resolve, reject) => {
        console.log("find user", uid, email, displayName)
        db.collection("users")
            .doc(uid)
            .get()
            .then((user) => {
                if (user.exists) {
                    reject({
                        reason: "user already exists",
                        uid: uid,
                        name: user.name,
                        email: user.email,
                    })
                } else {
                    const newUser = {
                        email: email,
                        phone: phone === undefined ? "" : phone,
                        name: displayName ? displayName : "",
                    }

                    db.collection("users")
                        .doc(uid)
                        .set(newUser)
                        .then((user) => {
                            console.log("Created user", newUser)
                            resolve(newUser)
                        })
                        .catch((err) => console.log("Unable to create user", err))
                }
            })
    })
}

const find = async (query, from) => {
    return await query.get().then((querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => {
            return {
                id: doc.id,
                ...doc.data(),
            }
        })

        return data
    })
}

const loadData = async (source, query) => {
    // let querySnapshot = await query.get(FROM_CACHE)

    // //console.log('QS size=', querySnapshot.size, 'from cache')

    // if (querySnapshot.size === 0) {
    //     querySnapshot = await query.get()

    //     console.log("%cload from server", "color:red", "QS size=", querySnapshot.size, source)
    // }

    const querySnapshot = await query.get()

    return querySnapshot.docs.map((doc) => {
        return {
            id: doc.id,
            ...doc.data(),
            doc: doc,
        }
    })
}

// for initializing the UI with a value, typically overwritten when we save
const localTimestamp = () => firebase.firestore.Timestamp.fromDate(new Date())

const localTimestampTruncTime = () => {
    const truncDate = new Date(localTimestamp().toDate().setHours(0, 0, 0))
    return firebase.firestore.Timestamp.fromDate(truncDate)
}

// use to ensure server based timetamps for created and modifed attributes
const serverTimestamp = () => firebase.firestore.FieldValue.serverTimestamp()

const timestampFromDate = (date) => firebase.firestore.Timestamp.fromDate(date)

const modifyQuery = (query, searchField, searchValue) => {
    if (searchValue !== "") {
        /*
        let startSearchValue = searchValue
        const lastChar = searchValue.charAt(searchValue.length - 1)
        const nextChar = String.fromCharCode(lastChar.charCodeAt(0) + 1)
        let endSearchValue = searchValue.slice(0,-1) + nextChar         
        query = query.where(searchField, '>=', startSearchValue).where(searchField, '<=', endSearchValue)
        */

        query = query
            .orderBy(searchField)
            .startAt(searchValue)
            .endAt(searchValue + "~")
    }
    return query
}

export {
    FROM_CACHE,
    // Accounts
    getAccountById,
    // Users
    getUser,
    getUserByUid,
    getUserFromCache,
    getCurrentUser,
    getUsersByIdChunks,
    // Model Index
    deleteExistingModelIndex,
    deleteParentModelIndexes,
    // Components
    getComponentsByIdChunks,
    // Projects
    getProjectsByIdChunks,
    // Stories
    getStoriesById,
    getStories,
    updateStoriesSummary,
    getStoryParent,
    deleteStory,
    // General
    loadData,
    localTimestamp,
    localTimestampTruncTime,
    serverTimestamp,
    timestampFromDate,
    modifyQuery,
    createUserIfRequired,
    splitIdsIntoChunks,
    // Invites
    createInvite,
    deleteInvite,
    getInviteForUser,
    findInviteByEmail,
    // Providers
    getProvidersById,
    getProviderFromCache,
    getProvidersByAccountId,
}
