import {
  Autocomplete,
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import { DeleteIcon } from "../icons"
import Controls from "./controls/Controls"
import ModelEditAppearsIn from "./ModelEditAppearsIn"
import PaletteIcon from "@mui/icons-material/Palette"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useMemo, useState } from "react"
import { format } from "date-fns"
import {
  getAvailablePropertyValuesForElement,
  addMissingIdsToProperties,
} from "../pages/services/modelEditServices"
import EventIcon from "@mui/icons-material/Event"
import TextFieldsIcon from "@mui/icons-material/TextFields"
import NumbersIcon from "@mui/icons-material/Numbers"
import _ from "lodash"
import * as colors from "@mui/material/colors"
import DraggablePaper from "../components/DraggablePaper"
import ArchiMateElementSelect from "./controls/ArchiMateElementSelect"

const ModelEditElementDialog = (props) => {
  const {
    open,
    onClose,
    currentElement,
    setSelectedItemId,
    setSelectedViewId,
    handleEditShader,
    views,
    setPropertyType,
    handleUpdateElement,
    handleDelete,
  } = props

  const [propTypeAnchorEl, setPropTypeAnchorEl] = useState(null)

  const [name, setName] = useState(currentElement.name)

  const [description, setDescription] = useState(currentElement.description)

  const [type, setType] = useState(currentElement.type || "")

  const [elementProps, setElementProps] = useState(currentElement.props)

  const [originalElement, setOriginalElement] = useState(currentElement)

  const editedElement = useMemo(
    () => ({
      description: description,
      name: name,
      id: currentElement.id,
      children: currentElement.children,
      type: type,
      props: elementProps,
    }),
    [name, description, elementProps, type, currentElement]
  )

  const propertyTypeIcons = [
    { value: "text", icon: <TextFieldsIcon /> },
    { value: "number", icon: <NumbersIcon /> },
    { value: "date", icon: <EventIcon /> },
  ]

  const handleAddProp = (name) => {
    setElementProps((curr) => {
      const maxPropId = curr.reduce((max, p) => (p.id > max ? p.id : max), 0)
      const newProp = { id: maxPropId + 1, name: name, value: "", type: "text" }
      const newProps = [...curr, newProp]
      return newProps
    })
  }

  const handleAddNewProp = (type) => {
    setElementProps((curr) => {
      const maxPropId = elementProps.reduce(
        (max, p) => (p.id > max ? p.id : max),
        0
      )
      const newProp = { id: maxPropId + 1, name: "", value: "", type: type }
      const newProps = [...curr, newProp]
      return newProps
    })
  }

  const handlePropChange = (index, name, value) => {
    console.log("prop change", { index, name, value })
    const newProps = elementProps.map((p, i) => {
      if (i === index) {
        if (name.startsWith("propname-")) {
          return { ...p, name: value }
        } else if (name.startsWith("propvalue-")) {
          return { ...p, value: value }
        } else if (name.startsWith("propreason-")) {
          return { ...p, reason: value }
        }
      } else {
        return p
      }
    })

    const newPropsWithIds = addMissingIdsToProperties(newProps)

    console.log("%cupdated props", "color: green", { newPropsWithIds })

    setElementProps(newPropsWithIds)
  }

  const propOptions = useMemo(() => {
    return getAvailablePropertyValuesForElement(editedElement, views)
  }, [editedElement, views])

  const currentElementPropertyKeys = useMemo(() => {
    if (currentElement) {
      const allViewElements = views.reduce((acc, view) => {
        return [...acc, ...view.elements]
      }, [])

      // Get 'name' attribute of 'props' object for each element

      const allViewElementKeys = _.flatten(
        allViewElements
          .filter(
            (element) => element.props.length > 0 && element.type === type
          )
          .map((element) => ({
            type: element.type,
            props: element.props.map((p) => p.name),
          }))
          .map((item) => item.props.map((p) => p))
      )

      return _.uniq(allViewElementKeys).sort((a, b) => a.localeCompare(b))
    }
    return []
  }, [views, type, currentElement])

  const missingKeysForCurrentElement = useMemo(() => {
    if (currentElementPropertyKeys && editedElement) {
      return currentElementPropertyKeys.filter((key) => {
        return key !== "" && !editedElement.props.find((p) => p.name === key)
      })
    }
    return []
  }, [editedElement, currentElementPropertyKeys])

  const handleDeleteProp = (index) => {
    setElementProps((curr) => curr.filter((p, i) => i !== index))
  }

  return (
    <Dialog open={open} onClose={onClose} PaperComponent={DraggablePaper}>
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        Edit Element
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" gap={2}>
          <Controls.TextInput
            label="Name"
            name="name"
            fullWidth={false}
            autoComplete="off"
            sx={{ width: "250px" }}
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleUpdateElement({ editedElement, originalElement })
              }
            }}
            // onBlur={() => {
            //     handleInherit(currentElement)
            // }}
          />

          {/* <ModelEditAppearsIn
            currentElement={editedElement}
            views={views}
            setSelectedItemId={setSelectedItemId}
            setSelectedViewId={setSelectedViewId}
          /> */}

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <ArchiMateElementSelect type={type} setType={setType} />
          </Box>

          <Box>
            <Controls.TextInput
              name="description"
              label="Description"
              value={description}
              fullWidth={false}
              sx={{ width: "300px" }}
              multiline
              onChange={(e) => setDescription(e.target.value)}
              onBlur={(e) => setDescription(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleUpdateElement({ editedElement, originalElement })
                }
              }}
            />
          </Box>

          <Box>
            <Typography
              sx={{
                fontWeight: "bold",
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              Properties
            </Typography>
          </Box>
        </Stack>

        <Stack direction="column" gap={2}>
          {editedElement.props.map((item, index) => (
            <Paper
              key={item.id}
              sx={{
                display: "flex",
                flexDirection: "row",
                maxWidth: "600px",
                gap: "10px",
                alignItems: "center",
                padding: "10px",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Stack direction="row" gap={2}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "150px",
                      }}
                    >
                      {item.value === "" && (
                        <PropertyNameInput
                          index={index}
                          item={item}
                          handlePropChange={handlePropChange}
                          setPropertyType={setPropertyType}
                        />
                      )}
                      {item.value !== "" && (
                        <Chip
                          size="small"
                          label={item.name}
                          sx={{
                            marginRight: "10px",
                            backgroundColor: colors.blue[100],
                          }}
                        />
                      )}
                    </Box>
                    <Box>
                      {item.type === "date" && (
                        <PropertyDateInput
                          handlePropChange={handlePropChange}
                          index={index}
                          item={item}
                        />
                      )}
                      {["number", "text"].includes(item.type) && (
                        <PropertyTextNumberInput
                          handlePropChange={handlePropChange}
                          index={index}
                          item={item}
                          propOptions={propOptions}
                        />
                      )}
                    </Box>
                  </Stack>
                </Box>

                <Stack direction="column" gap={2}>
                  <Box sx={{ width: "100%" }}>
                    <Controls.TextInput
                      name={`propreason-${index}`}
                      label="Reason"
                      fullWidth={true}
                      value={item.reason}
                      multiline
                      onChange={(e) =>
                        handlePropChange(index, e.target.name, e.target.value)
                      }
                    />
                  </Box>
                </Stack>
                <Box sx={{ display: "flex", marginLeft: "auto" }}>
                  <Tooltip title="Delete">
                    <IconButton
                      sx={{ marginTop: "10px" }}
                      onClick={() => handleDeleteProp(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Select colours used to highlight diagram for this property">
                    <IconButton
                      sx={{ marginTop: "10px" }}
                      onClick={() => handleEditShader(item)}
                    >
                      <PaletteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Paper>
          ))}
        </Stack>

        {propTypeAnchorEl !== null && (
          <Menu
            anchorEl={propTypeAnchorEl}
            open={propTypeAnchorEl !== null}
            onClose={() => setPropTypeAnchorEl(null)}
          >
            {propertyTypeIcons.map((item) => (
              <MenuItem
                key={item.value}
                value={item.value}
                onClick={() => handleAddNewProp(item.value)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={`${item.value}`} />
              </MenuItem>
            ))}
          </Menu>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Tooltip title="Add new property">
            <span>
              <Controls.Button
                text="Add Property"
                onClick={(e) => setPropTypeAnchorEl(e.currentTarget)}
              />
            </span>
          </Tooltip>

          <Box
            sx={{
              marginTop: "2px",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "2px",
            }}
          >
            {missingKeysForCurrentElement &&
              missingKeysForCurrentElement.map((name) => (
                <Tooltip title={`Add ${name} property`} key={name}>
                  <Chip
                    label={name}
                    size="small"
                    onClick={() => handleAddProp(name)}
                    sx={{
                      backgroundColor: colors.blue[100],
                      "&:hover": {
                        backgroundColor: colors.blue[200],
                      },
                    }}
                  />
                </Tooltip>
              ))}
          </Box>
          {/* <Box>
                        <pre>{JSON.stringify({ currentElement, editedElement }, null, 2)}</pre>
                    </Box> */}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          <Controls.Button
            text="Delete"
            onClick={() => {
              setPropTypeAnchorEl(null)
              handleDelete()
            }}
          />
          <Divider orientation="vertical" flexItem />
          <Controls.Button
            text="Close"
            onClick={() => {
              setPropTypeAnchorEl(null)
              onClose()
            }}
          />
          <Controls.Button
            text="Apply"
            variant="contained"
            onClick={() => {
              setPropTypeAnchorEl(null)
              handleUpdateElement({ editedElement, originalElement })
            }}
          />
        </Box>
      </DialogActions>
    </Dialog>
  )
}

const PropertyTextNumberInput = ({
  handlePropChange,
  index,
  item,
  propOptions,
}) => {
  return (
    <Box sx={{ marginBottom: "3px" }}>
      <Autocomplete
        name={`propvalue-${index}`}
        value={item.value}
        options={
          propOptions.find((optionsList) => optionsList.name === item.name)
            ?.values || []
        }
        onChange={(e, value) => {
          if (e.target.value !== item.value) {
            handlePropChange(index, `propvalue-${index}`, value || "")
            e.stopPropagation()
          }
        }}
        onInputChange={(e, value) => {}}
        onBlur={(e) => {
          if (e.target.value !== item.value) {
            handlePropChange(index, `propvalue-${index}`, e.target.value)
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handlePropChange(index, `propvalue-${index}`, e.target.value)
          }
        }}
        sx={{ width: "150px" }}
        renderInput={(params) => (
          <TextField variant="outlined" {...params} label={"Value"} />
        )}
      />
    </Box>
  )
}

const PropertyDateInput = ({ handlePropChange, index, item }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        id={item.id}
        name={`propvalue-${index}`}
        label="(d/m/y)"
        inputFormat="DD/MM/YYYY"
        value={item.value}
        onChange={(selectedDate) => {
          // Format date as a string in the format ddmmmyyyy

          try {
            const formattedDate = format(selectedDate.toDate(), "dd-MMM-yyyy")

            handlePropChange(index, `propvalue-${index}`, formattedDate)
          } catch (e) {
            // console.warn("error formatting date", {
            //     e,
            //     selectedDate,
            // })
          }
        }}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        renderInput={(params) => (
          <TextField
            variant="outlined"
            size="small"
            {...params}
            sx={{ width: "150px" }}
          />
        )}
      />
    </LocalizationProvider>
  )
}

const PropertyNameInput = (props) => {
  const { index, item, handlePropChange, setPropertyType } = props

  const [value, setValue] = useState(item.name)

  return (
    <Controls.TextInput
      label="Name"
      name={`propname-${index}`}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => {
        handlePropChange(index, e.target.name, e.target.value)
        setPropertyType(index, `propvalue-${index}`, e.target.value)
      }}
      sx={{ maxWidth: "200px" }}
    />
  )
}

export default ModelEditElementDialog
