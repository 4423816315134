import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import RightSidePanel, { useRightSidePanel } from "./RightSidePanel"
import React, { useEffect, useState } from "react"
import {
  fillHeading,
  selectBestViewsToFillSection,
  selectQuestionsToFillSection,
} from "../pages/services/templateFillServices"
import SimpleMarkdownRenderer from "./SimpleMarkdownRenderer"
import TemplateInstanceHeadingCard from "./TemplateInstanceHeadingCard"
import TextInput from "./controls/TextInput"
import ModelEditQuestion from "./ModelEditQuestion"
import { createSingleSuggestedView } from "../pages/services/chatGenerationServices"

const TemplateInstanceHeadings = ({
  templateInstance,
  viewSet,
  views,
  assistants,
  handleSaveHeadingContent,
  handleAddSuggestedView,
}) => {
  const { isOpen, openPanel, closePanel, togglePanel } = useRightSidePanel()

  const [selectedHeading, setSelectedHeading] = useState()

  const [scrollTo, setScrollTo] = useState()

  useEffect(() => {
    if (selectedHeading) {
      openPanel()
    }
  }, [selectedHeading])

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          //height: "100vh", // Full viewport height
        }}
      >
        {/* TOC Box */}
        <Box
          sx={{
            minWidth: "350px",
            display: "flex",
            flexDirection: "column",
            borderRight: "1px solid #ddd", // Optional: adds a separator
          }}
        >
          <Stack
            gap={1}
            sx={{
              flexGrow: 1, // Allows the stack to take up remaining space
              //overflowY: "auto", // Enables vertical scrolling
              padding: 2,
              px: 0,
            }}
          >
            {templateInstance.content.map((h, index) => {
              const hasContent = h.content && h.content.length > 0

              return (
                <Box
                  key={`toc-${h.level}-${index}`} // Ensure unique keys
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    console.log(`TOC clicked: ${h.level.replace(/\./g, "_")}`)
                    setScrollTo(h.level.replace(/\./g, "_"))
                  }}
                >
                  <Box sx={{ width: "45px" }}>
                    <Typography variant="caption">{h.level}</Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="caption"
                      fontWeight={hasContent ? "bold" : "normal"}
                    >
                      {h.heading}
                    </Typography>
                  </Box>
                </Box>
              )
            })}
          </Stack>
        </Box>

        {/* Content Area */}
        <Box
          sx={{
            flexGrow: 1,
            padding: 2,
            overflowY: "auto", // Optional: enables scrolling if content overflows
          }}
        >
          <InstanceHeadings
            headings={templateInstance.content}
            setSelectedHeading={setSelectedHeading}
            scrollTo={scrollTo}
          />
        </Box>
      </Box>

      <RightSidePanel
        isOpen={isOpen}
        closePanel={() => {
          setSelectedHeading(undefined)
          closePanel()
        }}
      >
        <FillPanel
          viewSet={viewSet}
          views={views}
          selectedHeading={selectedHeading}
          templateInstance={templateInstance}
          assistants={assistants}
          handleSaveHeadingContent={handleSaveHeadingContent}
          closePanel={closePanel}
          handleAddSuggestedView={handleAddSuggestedView}
        />
      </RightSidePanel>
    </Box>
  )
}

const FillPanel = ({
  viewSet,
  views,
  selectedHeading,
  templateInstance,
  assistants,
  handleSaveHeadingContent,
  closePanel,
  handleAddSuggestedView,
}) => {
  const [fileOptions, setFileOptions] = useState([])

  const [viewOptions, setViewOptions] = useState([])

  const [selectedFileIds, setSelectedFileIds] = useState([])

  const [selectedViewIds, setSelectedViewIds] = useState([])

  const [headingContent, setHeadingContent] = useState()

  const [usefulQuestions, setUsefulQuestions] = useState()

  const [contentGuidance, setContentGuidance] = useState("")

  const [isShowProgress, setIsShowProgress] = useState(false)

  const [isProcessing, setIsProcessing] = useState(false)

  useEffect(() => {
    if (viewSet && viewSet.docs) {
      const options = viewSet.docs.map((doc) => ({
        title: doc.name,
        id: doc.file_id,
      }))
      setFileOptions(options)
    }
  }, [viewSet])

  useEffect(() => {
    if (views) {
      const options = views.map((view) => ({
        title: view.name,
        subtitle: view.question || view.description,
        id: view.id,
      }))

      console.log("views", { views, viewOptions })
      setViewOptions(options)
    }
  }, [views])

  const handleFillHeading = ({
    selectedHeading,
    selectedFileIds,
    selectedViewIds,
    contentGuidance,
  }) => {
    console.log("%cusing content guidance", "color:yellow", contentGuidance)
    fillHeading({
      templateInstance,
      viewSet,
      views,
      selectedHeading,
      selectedFileIds,
      selectedViewIds,
      assistants,
      contentGuidance,
    }).then((content) => {
      setHeadingContent(content)
      setIsProcessing(false)
    })
  }

  const handleSelectQuestions = () => {
    setIsShowProgress(true)
    selectQuestionsToFillSection({
      views,
      viewSet,
      templateInstance,
      selectedHeading,
      assistants,
      contentGuidance,
    }).then((result) => {
      console.log("selectedQuestions", { result })

      const selectedQuestionIds = result.questions.map(
        (item) => item.question_id
      )

      const questions = viewSet.view_questions
        .filter((q) => selectedQuestionIds.includes(q.question_id))
        .map((q) => {
          const selectionRationale = result.questions.find(
            (item) => item.question_id === q.question_id
          )?.rationale

          return {
            ...q,
            rationale: `${q.rationale}\nIn terms of filling this section, ${selectionRationale}`,
          }
        })

      setUsefulQuestions(questions)
      setIsShowProgress(false)
    })
  }

  // Select which views might best fill this section
  const handleSelectViews = () => {
    setIsShowProgress(true)
    selectBestViewsToFillSection({
      views,
      viewSet,
      templateInstance,
      selectedHeading,
      assistants,
      contentGuidance,
    }).then((result) => {
      console.log("selectedViews", { result })

      // Replace the selected views with the best views
      setSelectedViewIds(
        result.selections.map((selection) => ({
          id: selection.id,
          title: selection.name,
          subtitle: selection.rationale,
        }))
      )
      setIsShowProgress(false)
    })
  }

  const handleCreateSuggestedView = async ({ question }) => {
    const suggestedView = await createSingleSuggestedView({
      assistants: assistants,
      overview: viewSet.overview,
      purpose: viewSet.purpose,
      question: question,
    })

    console.log("suggestedView", { suggestedView })

    const suggestedViewWithQuestion = {
      ...suggestedView,
      question: question.question,
      // So we can crossref the question with the view
      question_id: question.question_id,
    }

    handleAddSuggestedView(suggestedViewWithQuestion)
  }

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <Typography variant="h5">{viewSet.name}</Typography>
        <Box>
          <Typography fontWeight={"bold"}>
            {selectedHeading?.heading}
          </Typography>
          <Typography variant="caption">
            {selectedHeading?.rationale}
          </Typography>
        </Box>
      </Stack>
      <Box sx={{ m: "5px", mt: "10px" }}>
        <Alert severity="info">
          Select any file(s) and view(s) that you want used as input to fill
          this document section.
        </Alert>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Autocomplete
          options={fileOptions}
          multiple
          getOptionLabel={(option) => option.title}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="File(s)"
              variant="outlined"
              fullWidth
            />
          )}
          value={selectedFileIds}
          onChange={(event, newValue) => {
            setSelectedFileIds(newValue)
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={option.id}
                variant="outlined"
                label={option.title}
                {...getTagProps({ index })}
              />
            ))
          }
        />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Autocomplete
          options={viewOptions}
          multiple
          getOptionLabel={(option) => option.title}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="View(s)"
              variant="outlined"
              fullWidth
            />
          )}
          value={selectedViewIds}
          onChange={(event, newValue) => {
            setSelectedViewIds(newValue)
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={option.id}
                variant="outlined"
                label={
                  <Tooltip title={`${option.subtitle}`}>
                    <Typography variant="caption">{option.title}</Typography>
                  </Tooltip>
                }
                {...getTagProps({ index })}
              />
            ))
          }
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mt: "20px",
          gap: 1,
          alignItems: "center",
        }}
      >
        {isShowProgress && <CircularProgress size={20} sx={{ mx: "10px" }} />}
        <Tooltip title="Are there any questions I should answer first?">
          <span>
            <Button
              variant="outlined"
              onClick={handleSelectQuestions}
              disabled={!assistants || isShowProgress || isProcessing}
            >
              Questions
            </Button>
          </span>
        </Tooltip>
        <Tooltip title="What views should I use?">
          <span>
            <Button
              variant="outlined"
              onClick={handleSelectViews}
              disabled={!assistants || isShowProgress || isProcessing}
            >
              Auto Views
            </Button>
          </span>
        </Tooltip>
        <Tooltip title="Generate content based on selected files and views">
          <span>
            <Button
              variant="contained"
              onClick={() => {
                setIsProcessing(true)
                handleFillHeading({
                  selectedHeading,
                  selectedFileIds,
                  selectedViewIds,
                  contentGuidance,
                })
              }}
              disabled={!assistants || isShowProgress || isProcessing}
            >
              Go
            </Button>
          </span>
        </Tooltip>
        <Button
          variant="contained"
          onClick={() => {
            handleSaveHeadingContent({ selectedHeading, headingContent })
            closePanel()
          }}
          disabled={!headingContent}
        >
          Save
        </Button>
      </Box>
      <Box sx={{ mt: "10px" }}>
        <TextInput
          label="Provide optional extra guidance for content generation"
          value={contentGuidance}
          onChange={(e) => {
            console.log('%csetting content guidance', 'color:yellow', e.target.value)
            setContentGuidance(e.target.value)
          }}
          multiline
        />
      </Box>

      {isProcessing && (
        <Stack sx={{ mt: "20px" }}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Stack>
      )}
      {headingContent && (
        <Stack sx={{ mt: "20px" }} gap={1}>
          <SimpleMarkdownRenderer markdown={headingContent} />
        </Stack>
      )}

      {usefulQuestions && (
        <Stack sx={{ mt: "20px" }} gap={2}>
          <Typography variant="h6">
            It could help if these questions were answered
          </Typography>
          {usefulQuestions.map((q, index) => (
            <ModelEditQuestion
              key={`q-${q.question}-${index}`}
              viewSet={viewSet}
              views={views}
              question={q}
              isGenerating={isProcessing}
              handleCreateSuggestedView={() =>
                handleCreateSuggestedView({ question: q })
              }
              handleTogglePinQuestion={undefined}
              handleShowView={undefined}
              handleDeleteQuestion={undefined}
              disableCreateSuggestedView={false}
              selectedQuestionId={undefined}
              setSelectedQuestionId={undefined}
              cardProps={{
                width: "auto",
                maxWidth: "500px",
                position: "relative",
              }}
            />
          ))}
        </Stack>
      )}
    </Stack>
  )
}

const InstanceHeadings = React.memo(
  ({ headings, setSelectedHeading, scrollTo }) => {
    useEffect(() => {
      if (scrollTo) {
        const element = document.getElementById(`section_${scrollTo}`)
        if (element) {
          console.log(`Attempting to scroll to: section_${scrollTo}`)
          element.scrollIntoView({ behavior: "smooth", block: "start" })
          console.log(`Scrolled to: section_${scrollTo}`)
        } else {
          console.warn(`Element with id section_${scrollTo} not found.`)
        }
      }
    }, [scrollTo])

    return (
      <Stack gap={1}>
        {headings &&
          headings.map((h, index) => {
            const sectionId = h.level.replace(/\./g, "_")
            return (
              <TemplateInstanceHeadingCard
                key={`${h.level}-${index}`} // Ensure unique keys
                heading={h}
                setSelectedHeading={setSelectedHeading}
              />
            )
          })}
      </Stack>
    )
  }
)

export default TemplateInstanceHeadings
