import React, { useState } from "react"
import BaseShapeRoundedRect from "./BaseShapeRoundedRect"
import * as common from "./common"
import { deepOrange } from "@mui/material/colors"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const Resource = (props) => {
    const { x, y, height, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = deepOrange[300]
    const defaultColor = fillColor || deepOrange[200]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <rect
                x={x + 1}
                y={y + 1}
                fill={fill}
                stroke={common.outlineColor}
                width={width - 1}
                height={height - 1}
            />

            <svg x={x + width - 26} y={7}>
                <BaseShapeRoundedRect
                    x={2}
                    y={y}
                    r={2}
                    height={10}
                    width={16}
                    fill={fill}
                    setFill={handleFill}
                />

                <rect
                    x={18}
                    y={y + 3}
                    height={4}
                    width={2}
                    stroke={common.outlineColor}
                    fill='none'
                />

                <line x1={5} y1={y + 2} x2={5} y2={y + 8} stroke={common.outlineColor} />
                <line x1={8} y1={y + 2} x2={8} y2={y + 8} stroke={common.outlineColor} />
                <line x1={11} y1={y + 2} x2={11} y2={y + 8} stroke={common.outlineColor} />
            </svg>

            <ElementText {...props} label={label} />
        </Svg>
    )
}

export default Resource
