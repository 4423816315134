import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Typography,
  colors,
} from "@mui/material"
import Controls from "./controls/Controls"
import { useMemo, useRef, useState } from "react"
import DeleteIcon from "@mui/icons-material/Delete"
import SaveIcon from "@mui/icons-material/Save"
import { useSnackbar, withSnackbar } from "notistack"
import db from "../Firestore"
import * as dataServices from "../pages/services/dataServices"
import { useEffect } from "react"
import { useHistory, withRouter } from "react-router-dom"
import { spacing } from "../pages/services/styleServices"
import YesNo from "./YesNo"
import useClaims from "./useClaims"
import { createTemplate } from "../pages/services/templateBuilderServices"
import useAssistants from "./useAssistants"
import { aiIcon } from "../pages/services/colorServices"
import DescriptionIcon from "@mui/icons-material/Description"
import { handleChange } from "../pages/services/tocServices"
import DoneIcon from "@mui/icons-material/Done"

const styles = {
  fab: {
    position: "fixed",
    bottom: 16,
    right: 16,
    top: "auto",
    left: "auto",
  },

  buttons: {
    marginTop: spacing(3),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "5px",
    alignItems: "center",
  },
}

const TemplateEditForm = (props) => {
  const [templateId, setTemplateId] = useState(props.computedMatch.params.id)

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const [isProcessing, setProcessing] = useState(false)

  const assistants = useAssistants()

  const [newHeading, setNewHeading] = useState("")

  const [highlight, setHighlight] = useState()

  const addFileRef = useRef(null)

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Delete",
    openPrompt: false,
    description: "Delete?",

    // Callback if user clicks 'Yes' to delete a child record.
    // We set the callback and label depending on what the user is deleting
    handleConfirm: null,
  })

  const [values, setValues] = useState({
    account_id: "",
    name: "",
    purpose: "",
    docs: [],
    tags: [],
    headings: [],
  })

  const { claims, roles } = useClaims({ forceRefresh: false })

  useEffect(() => {
    if (templateId) {
      db.collection("templates")
        .doc(templateId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = { ...values, ...doc.data() }
            setValues(data)
          } else {
            enqueueSnackbar("Template not found", { variant: "error" })
          }
        })
        .catch((error) => {
          enqueueSnackbar("Error getting template", { variant: "error" })
        })
    }
  }, [templateId])

  const isNew = () => templateId === undefined || templateId === ""

  const handlePromptConfirmDelete = (event) => {
    event.preventDefault()

    setYesNoConfig({
      title: "Delete Template?",
      openPrompt: true,
      description: "This delete is permanent. Are you sure?",
      handleConfirm: () => handleDeletePromptConfirmed(),
    })
  }

  const hideDeletePrompt = () => {
    const newConfig = {
      ...yesNoConfig,
      openPrompt: false,
    }
    setYesNoConfig(newConfig)
  }

  const handleDeletePromptConfirmed = async () => {
    hideDeletePrompt()

    if (templateId) {
      // Delete template
      db.collection("templates")
        .doc(templateId)
        .delete()
        .then(history.push("/templates"))
        .then(() => {
          enqueueSnackbar("Deleted", { variant: "success" })
        })
    }
  }

  // Add a new heading with the same indent level as the last heading
  // There could be no headings yet, so we need to check for that
  const handleAddHeading = (event) => {
    event.preventDefault()

    if (newHeading.trim() === "") {
      return
    }

    const isHeadings = values.headings && values.headings.length > 0

    let newLevel
    if (isHeadings) {
      const lastHeading = values.headings[values.headings.length - 1]
      newLevel = lastHeading.level.split(".")
      newLevel[newLevel.length - 1] = (
        parseInt(newLevel[newLevel.length - 1], 10) + 1
      ).toString()
    } else {
      newLevel = ["1"] // Directly set to "1" for the first heading
    }

    const heading = {
      level: newLevel.join("."),
      heading: newHeading,
      rationale: "",
    }

    setValues((curr) => ({
      ...curr,
      headings: [...curr.headings, heading],
    }))
    setNewHeading("")
  }

  const handleCreateTemplate = (event) => {
    setProcessing(true)

    createTemplate({
      name: values.name,
      purpose: values.purpose,
      assistants,
      roles,
    }).then((template) => {
      console.log("created template", { template })
      //setTemplateJson(template)
      setValues((curr) => ({ ...curr, headings: template.headings }))
      setProcessing(false)
    })
  }

  const handleSave = async (event) => {
    event.preventDefault()

    if (values.name === "") {
      enqueueSnackbar("Enter template name", { variant: "warning" })
    } else {
      if (isNew()) {
        const newTemplate = {
          ...values,
          account_id: claims.account_id,
          created: dataServices.serverTimestamp(),
          modified: dataServices.serverTimestamp(),
        }

        console.log("saving template", { newTemplate })

        await db
          .collection("templates")
          .add(newTemplate)
          .then((docRef) => {
            setTemplateId(docRef.id)
            history.replace(`/template/${docRef.id}`)
          })
          .then(() => {
            enqueueSnackbar("Created", {
              variant: "success",
            })
          })
      } else {
        const updateRecord = {
          ...values,
          modified: dataServices.serverTimestamp(),
        }

        console.log("saving record", { templateId, updateRecord })

        db.collection("templates")
          .doc(templateId)
          .update(updateRecord)
          .then(() => {
            enqueueSnackbar("Template saved", {
              variant: "success",
              vertical: "bottom",
              horizontal: "right",
            })
          })
      }
    }
  }

  return (
    <>
      <YesNo config={yesNoConfig} />

      {/* <YesNo config={yesNoDeleteFileConfig} /> */}

      <Box>
        <Stack
          direction="column"
          gap={3}
          sx={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <Controls.TextInput
            label="Template Name"
            value={values.name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
            sx={{ width: "350px" }}
          />

          <Controls.TextInput
            label="Template Prompt"
            value={values.purpose}
            multiline={true}
            onChange={(e) => setValues({ ...values, purpose: e.target.value })}
            sx={{ width: "500px" }}
          />

          <Divider />

          <Box sx={{ ml: "50px" }}>
            <Controls.TextInput
              label="Heading"
              sx={{ width: "400px" }}
              value={newHeading}
              onChange={(e) => setNewHeading(e.target.value)}
            />

            <Box
              sx={{
                display: "flex",
                width: "400px",
                justifyContent: "flex-end",
              }}
            >
              <Controls.Button text="Add" onClick={handleAddHeading} />
            </Box>
          </Box>

          {values.headings && values.headings.length > 0 && (
            <Box>
              <TemplateHeadings
                headings={values.headings}
                setValues={setValues}
                highlight={highlight}
                setHighlight={setHighlight}
              />
            </Box>
          )}
        </Stack>

        <Box sx={styles.buttons}>
          {!isNew() && (
            <Controls.Button
              text="Delete"
              type="button"
              tooltip="Delete this template"
              endIcon={<DeleteIcon />}
              onClick={handlePromptConfirmDelete}
            />
          )}

          {!isNew() && (
            <Controls.Button
              type="button"
              text="Generate"
              variant="outlined"
              tooltip="Generate a new template"
              endIcon={<DescriptionIcon />}
              onClick={handleCreateTemplate}
              disabled={assistants.length === 0 || isProcessing}
            />
          )}

          <Controls.Button
            type="button"
            text="Save"
            variant="contained"
            tooltip="Save template"
            endIcon={<SaveIcon />}
            onClick={(event) => handleSave(event)}
          />

          {isProcessing && (
            <Box sx={{ ml: "10px" }}>
              <CircularProgress size="20" sx={aiIcon} />
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

const TemplateHeadings = ({ headings, setValues, highlight, setHighlight }) => {
  const [selectedHeading, setSelectedHeading] = useState()

  const handleChangeHeading = (newValues, h) => {
    const newHeadings = headings.map((heading) => {
      if (heading.level === h.level) {
        return { ...heading, ...newValues }
      }
      return heading
    })
    setValues((curr) => ({ ...curr, headings: newHeadings }))
    setSelectedHeading(undefined)
  }

  const handleDeleteHeading = (h) => {
    const newHeadings = headings.filter((heading) => heading.level !== h.level)
    setValues((curr) => ({ ...curr, headings: newHeadings }))
    setSelectedHeading(undefined)
  }

  return (
    <Stack gap={2}>
      {headings &&
        headings.map((h) => {
          // parse the heading level, e.g. "1", "1.1", "2.3.1", etc to determine the
          // amount of indentation to apply

          if (!h.level) {
            return <></>
          }

          const isHeadingSelected =
            selectedHeading && selectedHeading.level === h.level

          return (
            <Box
              key={`${h.level}-${h.name}`}
              sx={{ cursor: "pointer" }}
              draggable={!isHeadingSelected}
              onDragStart={(e) => {
                if (!isHeadingSelected) {
                  e.dataTransfer.setData(
                    "application/json",
                    JSON.stringify({ level: h.level, x_pos: e.clientX })
                  )
                }
              }}
              onDragOver={(e) => {
                e.preventDefault()
              }}
              onDrop={(e) => {
                e.preventDefault()
                setSelectedHeading(undefined)
                const dragged = JSON.parse(
                  e.dataTransfer.getData("application/json")
                )
                console.log("dropped", dragged, `level: ${dragged.level}`)

                handleChange({
                  source: dragged,
                  target: { level: h.level, x_pos: e.clientX },
                  headings: headings,
                  setHeadings: (newHeadings) => {
                    console.log("setHeadings", newHeadings)
                    setValues((curr) => ({ ...curr, headings: newHeadings }))
                  },
                })
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
                onClick={() => {
                  setSelectedHeading(h)
                }}
              >
                {selectedHeading && selectedHeading.level === h.level ? (
                  <HeadingEditable
                    heading={h}
                    selectedHeading={selectedHeading}
                    setSelectedHeading={setSelectedHeading}
                    handleDeleteHeading={handleDeleteHeading}
                    handleChangeHeading={(newValues) =>
                      handleChangeHeading(newValues, h)
                    }
                  />
                ) : (
                  <HeadingReadOnly
                    heading={h}
                    setSelectedHeading={setSelectedHeading}
                    handleDeleteHeading={handleDeleteHeading}
                    highlight={highlight}
                    setHighlight={setHighlight}
                  />
                )}
              </Box>
            </Box>
          )
        })}
    </Stack>
  )
}

const getHeadingProps = (heading) => {
  const indent = heading.level.split(".").length - 1

  return indent === 0
    ? {
        fontWeight: "bold",
        variant: "h5",
        color: colors.lightBlue[600],
      }
    : indent === 1
    ? { fontWeight: "bold", variant: "h6", color: colors.grey[600] }
    : {}
}

const HeadingEditable = ({
  heading,
  selectedHeading,
  setSelectedHeading,
  handleChangeHeading,
  handleDeleteHeading,
}) => {
  const headingProps = useMemo(() => {
    return getHeadingProps(heading)
  }, [heading])

  const [newHeading, setNewHeading] = useState(heading)

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
        onClick={() => {
          setSelectedHeading(heading)
        }}
      >
        <Box sx={{ width: "50px" }}>
          <Typography sx={headingProps}>{heading.level}</Typography>
        </Box>
        {selectedHeading && selectedHeading.level === heading.level && (
          <Box sx={{ width: "500px", boxSizing: "border-box" }}>
            <TextField
              size="small"
              value={newHeading.heading}
              fullWidth
              label="Heading"
              onChange={(e) =>
                setNewHeading({ ...newHeading, heading: e.target.value })
              }
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          ml: `50px`, // Margin-left based on indent
          width: "500px", // Make the Box take full available width
          boxSizing: "border-box", // Ensure padding and border are included in the width
          mt: "20px", // Margin-top
        }}
      >
        <Stack direction="column" gap={1}>
          <TextField
            variant="outlined"
            size="small"
            multiline
            label="Rationale"
            value={heading.rationale}
            onChange={(e) =>
              setNewHeading({ ...newHeading, rationale: e.target.value })
            }
            fullWidth // Makes the TextField occupy the full width of its container
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              mt: "10px",
            }}
          >
            <Button
              variant="outlined"
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                setSelectedHeading(undefined)
              }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              size="small"
              endIcon={<DeleteIcon />}
              onClick={(e) => {
                e.stopPropagation()
                handleDeleteHeading(heading)
              }}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                handleChangeHeading(newHeading)
              }}
              endIcon={<DoneIcon />}
            >
              Apply
            </Button>
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}

const HeadingReadOnly = ({
  heading,
  setSelectedHeading,
  handleDeleteHeading,
  highlight,
  setHighlight,
}) => {
  return (
    <Stack
      sx={{ minHeight: "50px" }}
      direction="row"
      onMouseOver={() => setHighlight(heading)}
      onMouseOut={() => setHighlight(undefined)}
    >
      <HeadingPanel heading={heading} setSelectedHeading={setSelectedHeading} />
      {/* {highlight && highlight.level === heading.level && (
        <Box>
          <Button onClick={() => handleDeleteHeading(heading)}>Delete</Button>
        </Box>
      )} */}
    </Stack>
  )
}

const HeadingPanel = ({ heading, setSelectedHeading }) => {
  const headingProps = useMemo(() => {
    return getHeadingProps(heading)
  }, [heading])

  const indent = heading.level.split(".").length - 1

  return (
    <Box sx={{ width: "500px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
        onClick={() => {
          setSelectedHeading(heading)
        }}
      >
        <Box sx={{ width: "50px" }}>
          <Typography sx={headingProps}>{heading.level}</Typography>
        </Box>

        <Box>
          <Box sx={{ ml: `${indent * 10}px` }}>
            <Typography sx={headingProps}>{heading.heading}</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ ml: `${50 + indent * 10}px` }}>
        <Typography variant="caption" color={colors.grey[600]}>
          {heading.rationale}
        </Typography>
      </Box>
    </Box>
  )
}

export default withSnackbar(withRouter(TemplateEditForm))
