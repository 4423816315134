import React, { useState } from "react"
import { Drawer, Button, Box, Typography, Stack } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

const RightSidePanel = ({ isOpen, closePanel, children, drawerTitle }) => {
  return (
    <Drawer anchor="right" open={isOpen} onClose={closePanel}>
      <Box
        sx={{
          width: 450,
          padding: 2,
          height: "100%",
        }}
        role="presentation"
      >
        <Stack direction="row">
          <Typography variant="h6" gutterBottom flexGrow={1}>
            {drawerTitle}
          </Typography>
          <Button onClick={closePanel}>
            <CloseIcon />
          </Button>
        </Stack>
        {children}
      </Box>
    </Drawer>
  )
}

const useRightSidePanel = () => {
  const [isOpen, setIsOpen] = useState(false)

  const openPanel = () => setIsOpen(true)
  const closePanel = () => setIsOpen(false)
  const togglePanel = () => setIsOpen((prev) => !prev)

  return { isOpen, openPanel, closePanel, togglePanel }
}

export { useRightSidePanel }

export default RightSidePanel
