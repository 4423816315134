import React from "react"
import * as common from "./common"

const Junction = (props) => {
    const { x, y, height, width, junctionType } = props

    const fill = junctionType && junctionType === "or" ? "none" : ""

    return (
        <svg style={common.elementStyle}>
            <circle
                cx={x + width / 2}
                cy={y + height / 2}
                r={6}
                fill={fill}
                stroke={common.outlineColor}
            />
        </svg>
    )
}

export default Junction
