import { Stack, Typography } from "@mui/material"
import Controls from "./controls/Controls"
import {
  GPT_4_TURBO_PREVIEW,
  GPT_4_TURBO,
  GPT_4_LATEST,
  GPT_4o_LATEST,
  GPT_4o_MINI_LATEST,
} from "../pages/services/chatGenerationServices"

const GPTModelSelect = ({ selectedGPTModel, setSelectedGPTModel }) => {
  const gptModelOptions = [
    //{ id: GPT_4_LATEST, title: "GPT 4" },
    //{ id: GPT_4_TURBO, title: "GPT 4 Turbo" },
    //{ id: GPT_4_TURBO_PREVIEW, title: "GPT 4 Turbo Preview" },
    { id: GPT_4o_LATEST, title: "GPT 4o" },
    { id: GPT_4o_MINI_LATEST, title: "GPT 4o Mini" },
  ]

  return (
    <Stack gap={2}>
      <Controls.Select
        name="view"
        addNoneOption={false}
        label={
          <Typography variant="body2" component={"span"}>
            GPT Model
          </Typography>
        }
        value={selectedGPTModel}
        options={gptModelOptions}
        onChange={(e) => setSelectedGPTModel(e.target.value)}
      />
    </Stack>
  )
}

export default GPTModelSelect
