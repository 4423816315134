import { useEffect, useState, useMemo } from "react"
import _ from "lodash"
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import Controls from "./controls/Controls"
import DraggablePaper from "../components/DraggablePaper"
import ModelEditSortValues from "./ModelEditSortValues"

const ModelEditRenameViewDialog = (props) => {
  const {
    open,
    onClose,
    currentView,
    handleUpdateViewData,
    views,
    handleDeleteView,
  } = props

  const [values, setValues] = useState(currentView)

  const [fieldColor, setFieldColor] = useState("primary")

  const [pivot, setPivot] = useState(currentView.pivot_view !== "")

  const [useTopLevelAsGroup, setUseTopLevelAsGroup] = useState(
    currentView.pivot_use_top_level
  )

  const [pivotProps, setPivotProps] = useState(values.pivot_props)

  const [enterPivotDetails, setEnterPivotDetails] = useState(false)

  const [viewName, setViewName] = useState(values.name)

  const [description, setDescription] = useState(values.description)

  const [subTitle, setSubTitle] = useState(values.direct_statement)

  const isNew = currentView.elements.length === 0

  // Array of [ name: <propname>, values: [sortedvalues] ]
  const [sortedPropValues, setSortedPropValues] = useState(
    values.sorted_prop_values || []
  )

  const viewPropKeys = useMemo(() => {
    // Get 'name' attribute of 'props' object for each element

    console.log("viewPropKeys", { views })

    if (views && values.pivot_view !== "") {
      const sourceView = views.find((v) => v.id === values.pivot_view)

      console.log("sourceView", { sourceView, views, values })

      const keys = sourceView.elements
        .filter((element) => element.props.length > 0)
        .map((element) => element.props.map((p) => p.name))
        .flat()

      console.log("keys", { keys })

      const uniqueKeys = _.uniq(keys).sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase())
      )

      console.log("keys", { keys })

      return uniqueKeys
    }
  }, [views, values])

  const handleSaveView = () => {
    const newValues = {
      ...values,
      name: viewName,
      description: description,
      direct_statement: subTitle,
      pivot_view: pivot ? values.pivot_view : "",
      pivot_use_top_level: pivot ? useTopLevelAsGroup : false,
      pivot_props: pivot ? pivotProps : [],
      sorted_prop_values: sortedPropValues,
    }
    console.log("saving", { newValues })
    handleUpdateViewData(newValues)
  }

  const handleViewNameChange = () => {
    console.log(`handleViewNameChange - %c${values.name}`, "color:orange", {
      values,
    })
    if (viewName === "" || viewName === undefined) {
      setFieldColor("warning")
    } else if (pivot && values.pivot_view === "" && pivotProps.length === 0) {
      setEnterPivotDetails(true)
    } else {
      setFieldColor("primary")
      handleSaveView()
    }
  }

  useEffect(() => {
    if (!pivot) {
      setValues((curr) => ({ ...curr, pivot_view: "" }))
    }
  }, [pivot])

  return (
    <Dialog open={open} onClose={onClose} PaperComponent={DraggablePaper}>
      <DialogTitle sx={{ cursor: "move" }} id="draggable-dialog-title">
        Configure View
      </DialogTitle>
      <DialogContent>
        {values && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: "15px",
            }}
          >
            <Controls.TextInput
              autoFocus
              color={fieldColor}
              name="viewname"
              label="View Name"
              value={viewName}
              onChange={(e) => setViewName(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleViewNameChange()
                }
              }}
              helperText={
                fieldColor === "warning" ? "View name cannot be empty" : ""
              }
            />
            <Controls.TextInput
              multiline
              color={fieldColor}
              name="description"
              label="View Title"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <Controls.TextInput
              multiline
              color={fieldColor}
              name="subtitle"
              label="View Subtitle"
              value={subTitle}
              onChange={(e) => setSubTitle(e.target.value)}
            />
            <FormControlLabel
              label="Pivot"
              control={
                <Switch
                  checked={pivot}
                  onChange={(e) => {
                    setPivot((curr) => !curr)
                  }}
                  disabled={!isNew}
                />
              }
            />

            <Typography variant="caption" sx={{ maxWidth: "300px" }}>
              Select 'Pivot' to create a pivot view. A pivot view is a view that
              renders another source view grouped by one or more properties.
            </Typography>

            {pivot && (
              <>
                <Tooltip title="Select the source view for your pivot view">
                  <span>
                    <Controls.Select
                      name="pivot_view"
                      label="Source View"
                      value={values.pivot_view}
                      onChange={(e) => {
                        setValues({ ...values, pivot_view: e.target.value })
                      }}
                      sx={{ minWidth: "300px" }}
                      options={
                        views &&
                        views
                          .filter(
                            (view) =>
                              view.pivot_view === "" &&
                              values.name !== view.name
                          )
                          .map((view) => ({
                            id: view.id,
                            title: view.name,
                          }))
                      }
                      disabled={pivot === false}
                    />
                  </span>
                </Tooltip>

                {enterPivotDetails && (
                  <Alert severity="info">
                    Choose the levels for your pivot view
                  </Alert>
                )}

                <Tooltip title="Use the top level of the source view as the top level of your pivot view">
                  <span>
                    <FormControlLabel
                      label="Use top level as group"
                      control={
                        <Switch
                          checked={useTopLevelAsGroup}
                          onChange={(e) => {
                            setUseTopLevelAsGroup((curr) => !curr)
                          }}
                          disabled={pivot === false}
                        />
                      }
                    />
                  </span>
                </Tooltip>

                {values.pivot_view !== "" &&
                  pivot &&
                  pivotProps.length === 0 && (
                    <Alert severity="warning">
                      Select one or more Properties
                    </Alert>
                  )}

                <Autocomplete
                  multiple
                  id="pivot_props"
                  options={viewPropKeys || []}
                  size="small"
                  value={pivotProps}
                  disabled={pivot === false}
                  onChange={(e, newValue) => {
                    setPivotProps(newValue)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Properties"
                      variant="outlined"
                      size="small"
                      sx={{ maxWidth: "300px" }}
                    />
                  )}
                />

                <Box>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{ marginTop: "10px" }}
                  >
                    Property value sort order
                  </Typography>

                  <ModelEditSortValues
                    propNames={pivotProps}
                    view={views.find((v) => v.id === values.pivot_view)}
                    sortedPropValues={sortedPropValues}
                    setSortedPropValues={setSortedPropValues}
                  />
                </Box>
              </>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{ textTransform: "none" }}
          variant="outlined"
        >
          Close
        </Button>
        <Button
          onClick={handleDeleteView}
          sx={{ textTransform: "none" }}
          variant="outlined"
        >
          Delete
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={(e) => {
            handleViewNameChange()
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModelEditRenameViewDialog
