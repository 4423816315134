import { Typography } from "@mui/material"

const TagLabel = (props) => {
    const { tag } = props

    return (
        <Typography variant="caption" component="span">
            {tag.type || "*"} | <b>{tag.label || "*"}</b>
        </Typography>
    )
}

export default TagLabel
