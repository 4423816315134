import React from "react"
import {
    Box,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material"
import { formatAmount } from "../pages/services/formatting"
import { secsToLocaleDateStr } from "../pages/services/dateServices"
import { green, red } from "@mui/material/colors"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import DownloadIcon from "@mui/icons-material/Download"

const StripeInvoice = ({ invoice, taxRates }) => {
    const getTaxDesc = (tax) => {
        const taxRate = taxRates.find((tr) => tr.id === tax.tax_rate)

        return `${taxRate.percentage}% ${taxRate.display_name}: ${formatAmount(tax.amount)} (${
            taxRate.jurisdiction
        })`
    }

    const handleDownload = (url, name) => {
        // Create a hidden anchor element
        const downloadLink = document.createElement("a")
        downloadLink.href = url // Replace with the actual file URL
        downloadLink.download = name // Replace with the desired file name

        // Trigger the download
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
    }

    return (
        <Paper style={{ padding: "20px", marginTop: "20px", maxWidth: "700px" }}>
            <Box>
                <Typography variant="h4">Invoice #{invoice.number}</Typography>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <IconButton
                        onClick={() => {
                            window.open(invoice.hosted_invoice_url, "_blank")
                        }}
                        disabled={!invoice.hosted_invoice_url}
                    >
                        <OpenInNewIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            handleDownload(invoice.invoice_pdf, `${invoice.number}.pdf`)
                        }}
                        disabled={!invoice.invoice_pdf}
                    >
                        <DownloadIcon />
                    </IconButton>
                </Box>
            </Box>
            <Typography color={invoice.paid ? green[500] : red[500]}>
                {invoice.paid ? "Paid" : "Unpaid"}
            </Typography>
            <Grid container spacing={2} sx={{ maxWidth: "700px" }}>
                <Grid item xs={6}>
                    <Typography variant="h6">From:</Typography>
                    <Typography>{invoice.account_name}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6">To:</Typography>
                    <Typography>{invoice.customer_name}</Typography>
                    <Typography>{invoice.customer_email}</Typography>
                </Grid>
            </Grid>
            <Box mt={3}>
                <Typography variant="h6">Invoice Date:</Typography>
                <Typography>{secsToLocaleDateStr(invoice.created)}</Typography>
            </Box>
            {/* <Box mt={3}>
                <Typography variant="h6">Description:</Typography>
                <Typography>{invoice.description}</Typography>
            </Box> */}
            <Box mt={3}>
                <Typography variant="h6">Invoice Items:</Typography>
                <TableContainer component={Paper} sx={{ maxWidth: "700px" }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Description</TableCell>
                                <TableCell align="right">Qty</TableCell>
                                <TableCell align="right">Price Ex Tax</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoice.lines.data.map((item, i) => (
                                <TableRow key={item.id}>
                                    <TableCell>{item.description}</TableCell>
                                    <TableCell align="right">{item.quantity}</TableCell>
                                    <TableCell align="right">{formatAmount(item.amount)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box mt={3}>
                <Typography variant="h6">Reason for Invoice:</Typography>
                <Typography>{invoice.billing_reason}</Typography>
            </Box>
            <Grid container spacing={2} sx={{ maxWidth: "700px" }}>
                <Grid item xs={6}>
                    {/* <Typography variant="h6">Subtotal:</Typography>
                    <Typography>{formatAmount(invoice.subtotal)}</Typography>
                    <Typography variant="h6">Discount:</Typography>
                    <Typography>{formatAmount(invoice.discount)}</Typography> */}
                    <Typography variant="h6">Taxes:</Typography>
                    <Typography>{formatAmount(invoice.tax)}</Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item>
                    {invoice.total_tax_amounts.map((tax) => (
                        <Typography key={tax.tax_rate}>{getTaxDesc(tax)}</Typography>
                    ))}
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ maxWidth: "700px" }}>
                <Grid item xs={6}>
                    <Typography variant="h6">Total:</Typography>
                    <Typography>{formatAmount(invoice.total)}</Typography>
                </Grid>
            </Grid>
            {/* <Box mt={3}>
                <Typography variant="h6">Payment Terms:</Typography>
                <Typography>{invoice.paymentTerms}</Typography>
            </Box> */}
            <Box mt={3}>
                <Typography variant="h6">Notes:</Typography>
                <Typography>{invoice.notes}</Typography>
            </Box>
        </Paper>
    )
}

export default StripeInvoice

// return (
//     <Paper sx={styles.pageContent}>
//         <Form>
//             <Grid container direction="column">
//                 <Grid item>
//                     <Typography variant="h6" component={"span"}>
//                         Upcoming Invoice {invoice.currency.toUpperCase()}{" "}
//                         {formatAmount(invoice.amount_due)}
//                     </Typography>
//                 </Grid>
//                 <Grid item>
//                     <Typography
//                         variant="caption"
//                         gutterBottom={true}
//                         paragraph={true}
//                         component={"span"}
//                     >
//                         {invoice.status}
//                     </Typography>
//                 </Grid>

//                 <Grid item>
//                     <Controls.Readonly
//                         name="subtotal"
//                         label="Subtotal"
//                         value={`${formatAmount(invoice.subtotal)}`}
//                     />
//                 </Grid>

//                 <Grid item>
//                     <Controls.Readonly
//                         name="tax"
//                         label="Tax"
//                         value={`${formatAmount(invoice.tax)}`}
//                     />
//                 </Grid>

//                 <Grid item>
//                     <Controls.Readonly
//                         name="amount_due"
//                         label="Amount Due"
//                         value={`${formatAmount(invoice.amount_due)}`}
//                     />
//                 </Grid>

//                 <Grid item>
//                     <Controls.Readonly
//                         name="invoice_period"
//                         label="Period"
//                         value={`${secsToDateStr(invoice.period_start)} - ${secsToDateStr(
//                             invoice.period_end
//                         )}`}
//                     />
//                 </Grid>

//                 <Grid item>
//                     <Controls.Readonly
//                         name="invoice_next_payment_attempt"
//                         label="Next Payment Attempt"
//                         value={secsToDateStr(invoice.next_payment_attempt)}
//                     />
//                 </Grid>
//             </Grid>
//         </Form>
//     </Paper>
//)
//}
