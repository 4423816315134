import React, { useState } from "react"
import * as common from "./common"
import { green } from "@mui/material/colors"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const Node = (props) => {
    const { x, y, height, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = green[200]
    const defaultColor = fillColor || green[100]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <rect
                x={x + 1}
                y={y + 1}
                fill={fill}
                stroke={common.outlineColor}
                width={width - 1}
                height={height - 1}
            />

            <svg x={x + width - 32} y={y + 1}>
                <rect
                    x={10}
                    y={8}
                    height={12}
                    width={12}
                    fill='none'
                    stroke={common.outlineColor}
                />

                <path
                    d={`M10,8 l 4 -4 h 12 v 12 l -4 4`}
                    fill='none'
                    stroke={common.outlineColor}
                />

                <path d={`M22,8 l 4 -4`} stroke={common.outlineColor} />
            </svg>

            <ElementText {...props} label={label} />
        </Svg>
    )
}

export default Node
