import React, { useEffect, useState, useCallback } from "react"
import PropTypes from "prop-types"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import { format } from "date-fns"
import { Box, Chip, Grid, Tooltip } from "@mui/material"
import firebase from "firebase/compat/app"
import { useDispatch, useSelector } from "react-redux"
import { selectStoryGridPagination } from "../redux/selectors"
import { setStoryGridPagination } from "../redux/actions"
import { selectStoryGridPageDocs } from "../redux/selectors"
import { setStoryGridPageDocs } from "../redux/actions"
import { selectStoryGridSelectedItems } from "../redux/selectors"
import { setStoryGridSelectedItems, setEditMode } from "../redux/actions"
import Controls from "./controls/Controls"
import * as tagServices from "../pages/services/tagServices"
import TagLabel from "./TagLabel"
import { spacing } from "../pages/services/styleServices"
import StyledLink from "./controls/StyledLink"
import PageNo from "./PageNo"
import TagChip from "./TagChip"

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Story Name",
    sortable: true,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: "Type",
    sortable: false,
  },
  {
    id: "parent_name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    sortable: false,
  },
  {
    id: "tags",
    numeric: false,
    disablePadding: true,
    label: "Story Tags",
    sortable: false,
  },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Created",
    sortable: true,
  },
]

const StoryGrid = (props) => {
  const { order, orderBy, onRequestSort } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={headCell.sortable && orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {headCell.label}
                </Typography>
              </TableSortLabel>
            ) : (
              <Typography sx={{ fontWeight: "bold" }}>
                {headCell.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

StoryGrid.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const styles = {
  root: {
    width: "100%",
    marginTop: spacing(2),
  },
  paper: {
    width: "100%",
    marginBottom: spacing(2),
  },
  table: {
    minWidth: 750,
  },
  navButtons: {
    display: "flex",
    flexDirection: "row",
    gap: 1,
  },
  gridContainer: {
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  filterGrid: {
    paddingLeft: "15px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  tagsCell: {
    display: "flex",
    flexDirection: "row",
    gap: 1,
    flexWrap: "wrap",
  },
}

export default function EnhancedTable() {
  const selectedStoryItems = useSelector(selectStoryGridSelectedItems)
  const [selected, setSelected] = React.useState(selectedStoryItems)

  // 'next', or 'prev'. Used to inform pagination logic
  const [direction, setDirection] = useState("")

  const [dense, setDense] = React.useState(true)

  const pag = useSelector(selectStoryGridPagination)
  const [pagination, setPagination] = useState(pag)

  const dispatch = useDispatch()

  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const [stories, setStories] = useState([])

  const [user, setUser] = useState()

  const [accountId, setAccountId] = useState()

  const [tagsFilter, setTagsFilter] = useState()

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      console.log("### user changed", user)
      setUser(user)

      if (user !== null) {
        user.getIdTokenResult(false).then((token) => {
          console.log("### setting account id", token.claims.account_id)
          setAccountId(token.claims.account_id)
        })
      }
    })

    return unsub
  }, [])

  const pgDocs = useSelector(selectStoryGridPageDocs)
  const [pageDocs, setPageDocs] = useState(pgDocs)

  const COLLECTION_NAME = "stories"

  const [maxModified, setMaxModified] = useState()

  // Listen for changes

  useEffect(() => {
    if (user === undefined || accountId === undefined) {
      return
    }

    const whereClauseLog = []

    let query = db
      .collection(COLLECTION_NAME)
      .where("modified", ">=", dataServices.localTimestamp())
      .orderBy("modified", "desc")

    whereClauseLog.push(
      "modified >= " +
        dataServices.localTimestamp() +
        " [" +
        dataServices.localTimestamp().toDate() +
        "]"
    )

    let unsubscribe

    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((token) => {
        switch (token.claims.account_type) {
          case "client":
            query = query.where("account_id", "==", token.claims.account_id)
            whereClauseLog.push("account_id == " + token.claims.account_id)
            break

          default:
            throw new Error("Unknown account type " + token.claims.account_type)
        }

        //console.log('whereClause for listener', whereClauseLog)

        let newMaxModified = null
        unsubscribe = query.onSnapshot((querySnapshot) => {
          querySnapshot.docChanges().forEach((change) => {
            if (
              newMaxModified === null ||
              change.doc.data().modified.seconds > newMaxModified.seconds
            ) {
              // trigger refresh
              newMaxModified = change.doc.data().modified
            }
          })
        })

        if (newMaxModified !== null) {
          setMaxModified(newMaxModified)
        }
      })
    return unsubscribe // gets called on unmount
  }, [user, accountId])

  // Get most recent modified record

  useEffect(() => {
    if (user === undefined || accountId === undefined) {
      return
    }

    console.log("=== get max modified date ===")

    let query = db.collection(COLLECTION_NAME)

    let unsubscribe

    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((token) => {
        switch (token.claims.account_type) {
          case "client":
            query = query.where("account_id", "==", token.claims.account_id)
            break

          default:
            throw new Error("Unknown account type " + token.claims.account_type)
        }

        console.log("%cload stories, pagination", "color:yellow", {
          pagination,
        })
        //query = query.where("tags_index", "array-contains-any", ["Type=Tech Debt"])

        query = query.orderBy("modified", "desc").limit(30)

        unsubscribe = query.onSnapshot((querySnapshot) => {
          let newMaxModified = null
          querySnapshot.docChanges().forEach((change) => {
            if (
              newMaxModified === null ||
              change.doc.data().modified.seconds > newMaxModified.seconds
            ) {
              console.log(
                "=== max modified ===",
                change.doc.id,
                change.doc.data().name,
                change.doc.data().modified
              )
              newMaxModified = change.doc.data().modified
            }
          })
          if (newMaxModified !== null) {
            setMaxModified(newMaxModified)
          }
        })
      })
    return unsubscribe
  }, [user, accountId, pagination])

  const updatePageDocs = useCallback(() => {
    if (stories.length > 0 && direction !== "prev") {
      const newPageDocs = [...pageDocs]

      const newPageDoc = {
        first: stories[0].doc,
        last: stories[stories.length - 1].doc,
      }

      newPageDocs[pagination.page] = newPageDoc
      setPageDocs(newPageDocs)
      dispatch(setStoryGridPageDocs(newPageDocs))
    }
  }, [stories, pagination.page, direction])

  useEffect(() => {
    updatePageDocs(stories)
  }, [stories, updatePageDocs])

  // Load stories

  useEffect(() => {
    if (user === undefined || accountId === undefined) {
      return
    }

    if (maxModified === undefined) {
      return
    }

    console.log("(1) load stories", {
      accountId,
      user,
      maxModified,
      pagination,
    })

    const queryMods = []

    let query = db.collection(COLLECTION_NAME)

    if (!firebase.auth().currentUser) {
      return
    }

    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((token) => {
        switch (token.claims.account_type) {
          case "client":
            query = query.where("account_id", "==", token.claims.account_id)
            queryMods.push("where account_id == " + token.claims.account_id)
            break

          default:
            throw new Error("Unknown account type " + token.claims.account_type)
        }

        if (pagination.tags.length > 0) {
          console.log("%cload stories, pagination", "color:yellow", {
            pagination,
          })
          const tagsExpr = tagServices.toSearchExpression(pagination.tags)
          query = query.where("tags_index", "array-contains-any", tagsExpr)
          queryMods.push(`tags_index contains any ${tagsExpr}`)
        }

        // Get current page of stories

        query = query.orderBy(pagination.orderBy, pagination.order)
        queryMods.push(
          "order by " + pagination.orderBy + " " + pagination.order
        )
        query = query.orderBy(
          firebase.firestore.FieldPath.documentId(),
          pagination.order
        )
        queryMods.push("order by doc id " + pagination.order)

        if (pagination.page > 0 && direction !== "prev") {
          // Use pageDocs if available, i.e. if we've gone forward, then back, then forward again through collection.
          // But if not found, it just means this is the first time we've clicked Next through the collection
          if (pageDocs[pagination.page - 1]) {
            const lastDoc = pageDocs[pagination.page - 1].last
            //console.log('startAfter', lastDoc.id)
            query = query.startAfter(lastDoc)
            queryMods.push(
              "start after last doc on previous page " + lastDoc.id
            )
          }
        } else if (direction === "prev") {
          if (!pageDocs[pagination.page]) {
            console.error("Cant find pagedocs for page", pagination.page)
          }
          query = query.startAt(pageDocs[pagination.page].first)
          queryMods.push(
            "start at 1st doc on page " + pageDocs[pagination.page].first.id
          )
        }

        console.log("queryMods", queryMods)
        console.groupEnd()

        query = query.limit(rowsPerPage)

        dataServices
          .loadData("(Load story grid)", query)
          .then((stories) => setStories(stories))
          .then(updatePageDocs())

        console.groupEnd()
      })
  }, [accountId, user, maxModified, pagination])

  const handleRequestSort = (event, property) => {
    const isAsc = pagination.orderBy === property && pagination.order === "asc"

    const updatedPagination = {
      tags: [],
      ...pagination,
      page: 0,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    }

    setPagination(updatedPagination)
    dispatch(setStoryGridPagination(updatedPagination))

    setPageDocs([])
    dispatch(setStoryGridPageDocs([]))

    setDirection("")
  }

  // Select all on story grid

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const allSelected = stories.map((n) => {
        return {
          id: n.id,
          label: n.name,
        }
      })

      console.log("allSelected", allSelected)
      console.log("selected", selected)

      // Find newly selected ids

      const newSelected = [...selected]

      const newAdditions = allSelected.filter(
        (item) => selected.find((s) => item.id === s.id) === undefined
      )
      console.log("new additions", newAdditions)
      newAdditions.forEach((item) => newSelected.push(item))

      setSelected(newSelected)
      dispatch(setStoryGridSelectedItems(newSelected))
    } else {
      // Remove all stories from selected
      const allDeselected = stories.map((n) => {
        return {
          id: n.id,
          label: n.name,
        }
      })
      const newSelected = selected.filter(
        (item) => allDeselected.find((s) => item.id === s.id) === undefined
      )
      setSelected(newSelected)
      dispatch(setStoryGridSelectedItems(newSelected))
    }
  }

  const deselectStory = (id) => {
    const existing = selected.find((item) => item.id === id)
    if (existing) {
      const newSelected = selected.filter((item) => item.id !== id)
      setSelected(newSelected)
      dispatch(setStoryGridSelectedItems(newSelected))
    }
  }

  const handleClick = (event, name, id) => {
    if (event.target.checked) {
      const newSelected = [...selected]
      newSelected.push({
        id: id,
        name: name,
      })
      setSelected(newSelected)
      dispatch(setStoryGridSelectedItems(newSelected))
    } else {
      deselectStory(id)
    }
  }

  const handlePageNav = (pageChange) => {
    const newPage = pagination.page + pageChange
    if (newPage >= 0) {
      setDirection(pageChange === 1 ? "next" : "prev")

      const updatedPagination = {
        ...pagination,
        page: newPage,
      }

      setPagination(updatedPagination)
      dispatch(setStoryGridPagination(updatedPagination))
    }
  }

  const handleTagDelete = (tagToDelete) => {
    const newTags = pagination.tags.filter(
      (tag) =>
        !(tag.type === tagToDelete.type && tag.label === tagToDelete.label)
    )
    const updatedPagination = {
      ...pagination,
      tags: newTags,
    }
    setPagination(updatedPagination)
    dispatch(setStoryGridPagination(updatedPagination))
  }

  const handleTagChange = (event) => {
    const value = event.target.value || ""
    setTagsFilter(value)
  }

  const handleTagKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      setPageDocs([])

      console.log("handleTagKeyPress", tagsFilter)

      const enteredTags = tagServices.parseTags(tagsFilter)
      const newTags = enteredTags.filter(
        (tag) =>
          pagination.tags.find(
            (pt) => pt.type === tag.type && pt.label === tag.label
          ) === undefined
      )

      console.log("newTags", { newTags, enteredTags })

      if (newTags.length > 0) {
        console.log("%cadding tag to filter", "color:lightGreen", newTags)
        const updatedTags = [...pagination.tags, ...newTags]

        const updatedPagination = {
          ...pagination,
          page: 0,
          tags: updatedTags,
        }

        setDirection("")
        setPagination(updatedPagination)
        dispatch(setStoryGridPagination(updatedPagination))

        console.log("%cset pagination", "color:yellow", updatedPagination)
      }
      setTagsFilter("")
    }
  }

  const isSelected = (id) =>
    selected.find((item) => item.id === id) !== undefined

  return (
    <>
      <Grid container direction="row" spacing={2} sx={styles.filterGrid}>
        <Grid item>
          <Controls.TextInput
            name="tags_filter"
            label="Filter Tags"
            helperText="Enter a tag in the form type:label, e.g. Type:Tech Debt"
            value={tagsFilter}
            onChange={handleTagChange}
            onKeyPress={handleTagKeyPress}
          />
        </Grid>
        <Grid item>
          {pagination &&
            pagination.tags.map((tag) => (
              <Chip
                key={`${tag.type}:${tag.label}`}
                size="small"
                label={<TagLabel tag={tag} />}
                onDelete={() => handleTagDelete(tag)}
              />
            ))}
        </Grid>
      </Grid>
      <Box sx={styles.root}>
        <Paper sx={styles.paper}>
          <TableContainer>
            <Table
              sx={styles.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="Projects"
            >
              <StoryGrid
                numSelected={selected.length}
                selected={selected}
                stories={stories}
                order={pagination.order}
                orderBy={pagination.orderBy}
                onRequestSort={handleRequestSort}
                rowCount={stories.length}
              />
              <TableBody>
                {stories.map((row, index) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {/* TODO: use dynamic link depending on whether it's a Project story or Component story */}
                        <StyledLink
                          to={"/Story/" + row.id}
                          onClick={() => dispatch(setEditMode(false))}
                        >
                          <Tooltip title={row.description}>
                            <Typography
                              noWrap={true}
                              variant="body2"
                              component={"span"}
                            >
                              {row.name}
                            </Typography>
                          </Tooltip>
                        </StyledLink>
                      </TableCell>

                      <TableCell component="th" padding="none">
                        <Typography
                          noWrap={true}
                          variant="body2"
                          component={"span"}
                        >
                          {row.type}
                        </Typography>
                      </TableCell>

                      <TableCell component="th" padding="none">
                        <StyledLink
                          to={
                            row.type === "project"
                              ? `/Project/${row.parent_id}`
                              : `/Component/${row.parent_id}`
                          }
                        >
                          <Typography
                            noWrap={true}
                            variant="body2"
                            component={"span"}
                            sx={{ paddingLeft: "5px" }}
                          >
                            {row.parent_name}
                          </Typography>
                        </StyledLink>
                      </TableCell>

                      <TableCell>
                        <Box sx={styles.tagsCell}>
                          {row.tags &&
                            row.tags.map((tag) => (
                              <TagChip
                                key={`${tag.type}-${tag.label}`}
                                tag={tag}
                              />
                            ))}
                        </Box>
                      </TableCell>

                      <TableCell align="left">
                        <Typography
                          variant="body2"
                          noWrap={true}
                          component={"span"}
                        >
                          {format(row.created.toDate(), "dd-M-yy")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box sx={styles.navButtons}>
          <Controls.Button
            size="small"
            disabled={pagination.page === 0}
            onClick={() => handlePageNav(-1)}
            text="Prev"
          />

          <Controls.Button
            size="small"
            disabled={stories.length < rowsPerPage}
            onClick={() => handlePageNav(1)}
            text="Next"
          />
        </Box>
        <PageNo pageNo={pagination.page + 1} />
      </Box>
    </>
  )
}
