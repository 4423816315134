import { Box } from "@mui/material"
import { useRef } from "react"
import ModelEditElement from "./ModelEditElement"
import ModelEditElementContainer from "./ModelEditElementContainer"
import * as palette from "./symbols/palette"
import * as colors from "@mui/material/colors"
import { useEffect } from "react"

const ModelEditChildElements = (props) => {
    const {
        elements,
        // A list of all of the elements ids that should be shaded if the shader is selected and hideUnshaded === false
        shadedElementIds,
        showRender,
        children,
        handleAddSibling,
        selectedItemId,
        setSelectedItemId,
        setShowEditElement,
        onMouseDown,
        onMouseUp,
        layout = { orientation: "row" },
        setCoords,
        highlightLevel,
        level,
        levels,
        highlightProp,
        currentShader,
        hideUnshaded,
        setHighlightProp,
        showProps,
        hiddenProps,
        showUsage,
        applyShaders,
        applyShadersToChips,
        shaders,
        hiddenElementTypes,
        quicksetProps,
        views,
        handleToggleProp,
        duplicateItems,
        drawRequestTime,
        currentView,
        handleSelectItem,
        //handleCreateDescription,
        //handleExpandItem,
        showDocs,
        elementsWithProjects,
        waitingElementIds,
        isShowGenerationPanel
    } = props

    const elementRef = useRef()

    const thisOrientation = layout.length > 0 ? layout[0].orientation : "row"

    const thisWrap = layout.length > 0 ? layout[0].wrap : "nowrap"

    const childLayout = layout.length > 1 ? layout.slice(1) : []

    const marginLeft = level > 0 ? { marginLeft: "10px" } : {}

    // We show elements in this component if hideUnshaded is false, or if hideUnshaded is true and 1 or more children are shaded
    const showElements =
        children.length > 0 &&
        (!hideUnshaded || !currentShader || children.some((c) => shadedElementIds.includes(c.id)))

    const borderProps =
        level === highlightLevel
            ? { border: `2px solid ${colors.pink[300]}` }
            : { border: "2px solid #fff" }

    const getChildElements = (id) => {
        const element = elements.find((item) => item.id === id)
        if (element) {
            const result = element.children.map((child) => {
                const childItem = elements.find((item) => item.id === child.id)
                //console.log("%cchildItem", "color:lightgreen", { childItem, child })
                return {
                    ...childItem,
                    symbol: palette.symbols[palette.getElementNameByIndex(childItem.type)],
                }
            })
            return result
        }
        return []
    }

    useEffect(() => {
        // Find any duplicate id values in children
        const duplicateIds = children.reduce((acc, item) => {
            if (acc[item.id]) {
                acc[item.id]++
            } else {
                acc[item.id] = 1
            }
            return acc
        }, {})

        // See if any of the duplicate ids are greater than 1
        const duplicateIdValues = Object.values(duplicateIds).filter((value) => value > 1)

        // If there are any duplicate ids, log a warning printing only the duplicate ids
        if (duplicateIdValues.length > 0) {
            console.warn(
                "%cDuplicate ids found in children",
                "color:orange",
                Object.keys(duplicateIds).filter((key) => duplicateIds[key] > 1),
                children
            )
        }
    }, [children, elements])

    return (
        <>
            {showElements && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: thisOrientation,
                        flexWrap: thisWrap,
                        border: "1px solid #e0e0e0",
                        gap: "5px",
                        ...marginLeft,
                    }}
                >
                    {children.map((element) => (
                        <ModelEditElementContainer
                            key={`container-${element.id}`}
                            borderProps={borderProps}
                        >
                            {(!hideUnshaded ||
                                !currentShader ||
                                shadedElementIds.includes(element.id)) && (
                                <ModelEditElement
                                    item={element}
                                    showRender={showRender}
                                    parentRef={elementRef}
                                    selectedItemId={selectedItemId}
                                    setSelectedItemId={setSelectedItemId}
                                    setShowEditElement={setShowEditElement}
                                    onMouseDown={onMouseDown}
                                    onMouseUp={onMouseUp}
                                    setCoords={setCoords}
                                    layout={layout}
                                    highlightProp={highlightProp}
                                    setHighlightProp={setHighlightProp}
                                    currentShader={currentShader}
                                    showProps={showProps}
                                    hiddenProps={hiddenProps}
                                    showUsage={showUsage}
                                    applyShaders={applyShaders}
                                    applyShadersToChips={applyShadersToChips}
                                    shaders={shaders}
                                    hiddenElementTypes={hiddenElementTypes}
                                    quicksetProps={quicksetProps}
                                    views={views}
                                    handleToggleProp={handleToggleProp}
                                    duplicateItems={duplicateItems}
                                    drawRequestTime={drawRequestTime}
                                    currentView={currentView}
                                    handleSelectItem={handleSelectItem}
                                    // handleCreateDescription={handleCreateDescription}
                                    // handleExpandItem={handleExpandItem} 
                                    showDocs={showDocs}
                                    elementsWithProjects={elementsWithProjects}
                                    waitingElementIds={waitingElementIds}
                                    isShowGenerationPanel={isShowGenerationPanel}
                                />
                            )}

                            <ModelEditChildElements
                                elements={elements}
                                shadedElementIds={shadedElementIds}
                                showRender={showRender}
                                children={getChildElements(element.id)}
                                handleAddSibling={handleAddSibling}
                                selectedItemId={selectedItemId}
                                setSelectedItemId={setSelectedItemId}
                                setShowEditElement={setShowEditElement}
                                onMouseDown={onMouseDown}
                                onMouseUp={onMouseUp}
                                layout={childLayout}
                                setCoords={setCoords}
                                highlightLevel={highlightLevel}
                                level={level + 1}
                                levels={levels}
                                highlightProp={highlightProp}
                                setHighlightProp={setHighlightProp}
                                currentShader={currentShader}
                                hideUnshaded={hideUnshaded}
                                showProps={showProps}
                                hiddenProps={hiddenProps}
                                showUsage={showUsage}
                                applyShaders={applyShaders}
                                applyShadersToChips={applyShadersToChips}
                                shaders={shaders}
                                hiddenElementTypes={hiddenElementTypes}
                                quicksetProps={quicksetProps}
                                views={views}
                                handleToggleProp={handleToggleProp}
                                duplicateItems={duplicateItems}
                                drawRequestTime={drawRequestTime}
                                currentView={currentView}
                                handleSelectItem={handleSelectItem}
                                // handleCreateDescription={handleCreateDescription}
                                // handleExpandItem={handleExpandItem}
                                showDocs={showDocs}
                                elementsWithProjects={elementsWithProjects}
                                waitingElementIds={waitingElementIds}
                                isShowGenerationPanel={isShowGenerationPanel}
                            />
                        </ModelEditElementContainer>
                    ))}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "auto",
                            marginBottom: "auto",
                        }}
                    ></Box>
                </Box>
            )}
        </>
    )
}

export default ModelEditChildElements
