import { Alert, Box, Button, Typography } from "@mui/material"
import { useState } from "react"
import GetAIMAIDialog from "./GetAIMAIDialog"

const UpdateAlert = ({ message }) => {
  const [showBillingDialog, setShowBillingDialog] = useState(false)

  return (
    <>
      {showBillingDialog && (
        <GetAIMAIDialog
          open={showBillingDialog}
          onClose={() => setShowBillingDialog(false)}
        />
      )}
      <Box sx={{ p: "10px" }}>
        <Alert severity="info">
          <Typography variant="caption">{message}</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{ mt: "10px" }}
              variant="contained"
              size="small"
              onClick={() => setShowBillingDialog(true)}
            >
              <Typography variant="caption">Upgrade</Typography>
            </Button>
          </Box>
        </Alert>
      </Box>
    </>
  )
}

export default UpdateAlert
