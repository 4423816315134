import React, { Fragment, useState, useEffect } from "react"
import Grid from "@mui/material/Grid"
import Controls from "./controls/Controls"
import FormLabel from "@mui/material/FormLabel"
import { useForm, Form } from "./useForm"
import * as dataServices from "../pages/services/dataServices"
import EmailIcon from "@mui/icons-material/Email"
import PhoneIcon from "@mui/icons-material/Phone"
import SaveIcon from "@mui/icons-material/Save"
import DeleteIcon from "@mui/icons-material/Delete"
import * as Roles from "../pages/services/roleServices"
import db from "../Firestore"
import { withSnackbar, useSnackbar } from "notistack"
import { useHistory, withRouter } from "react-router-dom"
import firebase from "firebase/compat/app"
import _ from "lodash"
import {
  FormControl,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Box,
} from "@mui/material"
import YesNo from "./YesNo"
import { getAnalytics, logEvent } from "firebase/analytics"
import useAccountStatus from "./useAccountStatus"
import { spacing } from "../pages/services/styleServices"
import { getUserCountWithAIMAIRole } from "../pages/services/userServices"
import * as stripeServices from "../pages/services/stripeServices"
import ProgressBackdrop from "./ProgressBackdrop"
import * as cloudFunctions from "../pages/services/cloudFunctions"

const initialValues = () => {
  return {
    name: "",
    email: "",
    phone: "",
    roles: [],
    provider_id: "",
    account_id: "",
    created: dataServices.localTimestamp(),
    modified: dataServices.localTimestamp(),
  }
}

const styles = {
  formControl: {
    margin: spacing(3),
  },
  checkboxes: {
    paddingTop: spacing(2),
  },
}

const UserEditForm = (props) => {
  const { setTitle } = props

  const { enqueueSnackbar } = useSnackbar()

  const [userId, setUserId] = useState(props.computedMatch.params.id)

  const [hasAIMAIRole, setHasAIMAIRole] = useState(false)

  const [isShowProgress, setIsShowProgress] = useState(false)

  const history = useHistory()

  const { isActive } = useAccountStatus()

  const isNew = () => userId === undefined || userId === ""

  const { values, setValues, handleInputChange } = useForm(initialValues())

  const COLLECTION_NAME = "users"

  const [accountId, setAccountId] = useState()

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Delete User?",
    description: "This delete is permanent",
    openPrompt: false,

    // this method is set when we prompt for deletion
    handleConfirm: null,
  })

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      console.log("### user changed", user)

      user.getIdTokenResult(false).then((token) => {
        console.log("### setting account id", token.claims.account_id)
        setAccountId(token.claims.account_id)
      })
    })

    return unsub
  }, [])

  // Load user record

  useEffect(() => {
    console.log("load user", userId)

    if (userId != null) {
      db.collection(COLLECTION_NAME)
        .doc(userId)
        .get()
        .then((snapshot) => {
          const userRec = _.merge({}, initialValues(), snapshot.data())
          console.log("loaded user", userRec)
          setValues(userRec)
          setHasAIMAIRole(userRec.roles.indexOf(Roles.AIM_AI) !== -1)
        })
    }
  }, [userId])

  const handleLogEvent = () => {
    const analytics = getAnalytics()

    logEvent(analytics, "user_saved", {
      account_id: accountId,
      values: values,
    })

    console.log("logged event")
  }

  const handleSelectRole = (event) => {
    console.log("selectRole")

    if (event.target.checked) {
      // Adding
      const newRoles = [...values.roles]
      newRoles.push(event.target.value)
      const newValues = {
        ...values,
        roles: newRoles,
      }
      console.log("newValues", newValues)
      setValues(newValues)
    } else {
      // Removing
      const newRoles = values.roles.filter(
        (roleId) => roleId !== event.target.value
      )
      const newValues = {
        ...values,
        roles: newRoles,
      }
      console.log("newValues", newValues)
      setValues(newValues)
    }
  }

  useEffect(() => setTitle(values.name), [values.name, setTitle])

  // 'confirmed' is true or false
  const handleDeleteConfirmed = () => {
    // Reset prompt flag, so if we'd clicked No, we can still click Delete again and get prompted
    const newPromptConfig = {
      ...yesNoConfig,
      openPrompt: false,
    }
    setYesNoConfig(newPromptConfig)

    if (userId !== undefined && userId !== "" && userId !== null) {
      db.collection(COLLECTION_NAME)
        .doc(userId)
        .delete()
        .then(history.goBack())
        .then(enqueueSnackbar("Deleted", { variant: "success" }))
    }
  }

  const handlePromptConfirmDelete = (event) => {
    event.preventDefault()

    const newPromptConfig = {
      ...yesNoConfig,
      openPrompt: true,
      handleConfirm: handleDeleteConfirmed,
    }
    setYesNoConfig(newPromptConfig)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    // See if the user has added 'aim_ai' role.
    const hasAddedAIMAIRole =
      values.roles.indexOf(Roles.AIM_AI) !== -1 && !hasAIMAIRole
    const hasRemovedAIMAIRole =
      values.roles.indexOf(Roles.AIM_AI) === -1 && hasAIMAIRole

    console.log("added AIM AI role", hasAddedAIMAIRole)

    // Check if allowed to add the AIM AI role, i.e. within licensing

    if (hasAddedAIMAIRole) {
      setIsShowProgress(true)

      // Get user count with AIM AI role
      const existingUsersWithAIMAIRole = await getUserCountWithAIMAIRole(
        accountId
      )
      console.log("count", existingUsersWithAIMAIRole)

      // Get stripe licensing for AIM AI
      const stripeCust = await stripeServices.getStripeCustomer(accountId)
      console.log("stripeCust", stripeCust)

      const productQuantities = stripeCust.customer.subscriptions.flatMap(
        (sub) => {
          return sub.items.data.map((item) => {
            return {
              product_id: item.price.product,
              quantity: item.quantity,
            }
          })
        }
      )

      const productIds = productQuantities.map((pq) => pq.product_id)
      console.log("%cfinding products", "color:lightgreen", productIds)
      const products = await stripeServices.getStripeProducts(productIds)

      console.log("%cloaded products", "color:lightgreen", products)

      // Map product name onto productQuantities

      const productQuantitiesWithNames = productQuantities.map((pq) => {
        const product = products.find((p) => p.id === pq.product_id)
        return {
          ...pq,
          product_name: product.name,
        }
      })

      console.log(
        "%cproductQuantitiesWithNames",
        "color:lightgreen",
        productQuantitiesWithNames
      )

      const canAddAIMAI = productQuantitiesWithNames.some(
        (pq) =>
          pq.product_name === stripeServices.AIM_AI_PRODUCT &&
          pq.quantity > existingUsersWithAIMAIRole
      )

      setIsShowProgress(false)

      if (!canAddAIMAI) {
        enqueueSnackbar(
          "You have reached your AIM AI license limit. Open Billing (top right menu) and increase the number of AIM AI licenses.",
          { variant: "error" }
        )

        // Remove the role
        const newRoles = values.roles.filter(
          (roleId) => roleId !== Roles.AIM_AI
        )
        const newValues = {
          ...values,
          roles: newRoles,
        }
        console.log("newValues", newValues)
        setValues(newValues)

        return
      }
    }

    if (values.name === "") {
      enqueueSnackbar("Enter name", { variant: "error" })
    } else if (values.email === "") {
      enqueueSnackbar("Enter email", { variant: "error" })
    } else {
      if (isNew()) {
        dataServices
          .getCurrentUser()
          .then((user) => {
            const newRecord = {
              ...values,
              provider_id: values.provider_role ? values.provider_id : null,
              account_id: user.account_id,
              created: dataServices.serverTimestamp(),
              modified: dataServices.serverTimestamp(),
            }

            db.collection(COLLECTION_NAME)
              .add(newRecord)
              .then((docRef) => setUserId(docRef.id))
              .then(enqueueSnackbar("Created", { variant: "success" }))

              .then(handleLogEvent())
              .then(() => history.push("/users"))
          })
          .catch(function (error) {
            console.error("Error:" + error)
            enqueueSnackbar("Error", { variant: "error " })
          })
      } else {
        const updateRecord = {
          ...values,
          provider_id: values.provider_role
            ? values.provider_id
            : firebase.firestore.FieldValue.delete(),
          modified: dataServices.serverTimestamp(),
        }

        console.log("updating user", updateRecord)

        db.collection(COLLECTION_NAME)
          .doc(userId)
          .update(updateRecord)
          .then(handleLogEvent())
          .then(enqueueSnackbar("Updated", { variant: "success" }))
          .then(history.push("/users"))
          .then(() => {
            // Is this user the current user?
            const isCurrentUser = firebase.auth().currentUser.uid === userId

            if (hasAddedAIMAIRole || hasRemovedAIMAIRole) {
              if (isCurrentUser) {
                // Refresh user token which contains their roles, incl. the AIM AI role.
                cloudFunctions.obtainCustomClaims()
              }
            }
          })
      }
    }
  }

  return (
    <>
      {yesNoConfig && <YesNo config={yesNoConfig} />}

      <ProgressBackdrop
        open={isShowProgress}
        label={"Checking subscription..."}
      />

      <Form>
        <Grid container direction="column">
          <Grid item>
            <Controls.Readonly
              name="email"
              label="Email"
              value={values.email}
              icon={<EmailIcon />}
            />
          </Grid>

          <Grid item>
            <Controls.TextInput
              name="name"
              label="Name"
              value={values.name}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item>
            <Controls.TextInput
              name="phone"
              label="Phone"
              value={values.phone}
              icon={<PhoneIcon />}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item sx={styles.checkboxes}>
            <FormControl component="fieldset" sx={styles.formControl}>
              <FormLabel component="legend">Assign user role(s)</FormLabel>
              <FormGroup>
                {Roles.getRoles().map((role) => (
                  <FormControlLabel
                    key={role.name}
                    control={
                      <Checkbox
                        color="primary"
                        checked={values.roles.indexOf(role.name) !== -1}
                        value={role.name}
                        name="role"
                        onChange={handleSelectRole}
                      />
                    }
                    label={`${role.label} - ${role.description}`}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Grid item>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
            {!isNew() ? (
              <Controls.Button
                text="Delete"
                type="button"
                endIcon={<DeleteIcon />}
                onClick={handlePromptConfirmDelete}
                disabled={!isActive()}
              />
            ) : null}
            <Controls.Button
              type="submit"
              text="Save"
              variant="contained"
              endIcon={<SaveIcon />}
              onClick={handleSubmit}
              disabled={!isActive()}
            />
          </Box>
        </Grid>
      </Form>
    </>
  )
}

export default withSnackbar(withRouter(UserEditForm))
