import React, { Fragment, useState, useEffect } from "react"
import Grid from "@mui/material/Grid"
import Controls from "./controls/Controls"
import { useForm, Form } from "./useForm"
import db from "../Firestore"
import PhoneIcon from "@mui/icons-material/Phone"
import EmailIcon from "@mui/icons-material/Email"
import * as dataServices from "../pages/services/dataServices"
import SaveIcon from "@mui/icons-material/Save"
import { withSnackbar, useSnackbar } from "notistack"
import { withRouter } from "react-router-dom"
import { spacing } from "../pages/services/styleServices"

const initialValues = () => {
  return {
    name: "",
    email: "",
    phone: "",
    created: dataServices.localTimestamp(),
    modified: dataServices.localTimestamp(),
    stripe_cust_id: "",
    billable_user_count: 0,
  }
}

const styles = {
  buttons: {
    marginTop: spacing(2),
  },
}

function AccountEditForm(props) {
  const { setTitle } = props

  const { enqueueSnackbar } = useSnackbar()

  const [accountId, setAccountId] = useState("")

  const { values, setValues, handleInputChange } = useForm(initialValues())

  // Load account

  useEffect(() => {
    console.log("props", { props, id: props.computedMatch.params.id })

    if (!accountId) {
      const { id } = props.computedMatch.params
      if (id !== undefined) {
        setAccountId(id)

        console.log("load account", id)

        // Load account
        db.collection("accounts")
          .doc(id)
          .get()
          .then((snapshot) => {
            console.log(snapshot.data())

            const newValues = {
              ...initialValues(),
              ...snapshot.data(),
            }
            setValues(newValues)

            console.log("%cgot values", "color: yellow", newValues)
          })
      }
    }
  }, [props])

  useEffect(() => {
    setTitle(values.name)
  }, [values.name, setTitle])

  const handleSubmit = (event) => {
    event.preventDefault()

    if (values.name === "") {
      enqueueSnackbar("Enter name", { variant: "error" })
    } else {
      console.log("Updating existing account", props.id)

      const updateRecord = {
        ...values,
        modified: dataServices.serverTimestamp(),
      }

      console.log("updating account", updateRecord)

      db.collection("accounts")
        .doc(accountId)
        .update(updateRecord)
        .then(enqueueSnackbar("Updated", { variant: "success" }))
      //.then(history.goBack())
    }
  }

  return (
    <Fragment>
      <Form>
        <Grid container direction="column">
          <Grid item>
            <Controls.TextInput
              name="name"
              label="Name"
              value={values.name}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item>
            <Controls.TextInput
              name="phone"
              label="Phone"
              value={values.phone}
              icon={<PhoneIcon />}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item>
            <Controls.Readonly
              name="email"
              label="Email"
              value={values.email}
              variant="outlined"
              icon={<EmailIcon />}
            />
          </Grid>

          <Grid item>
            <Controls.CreatedDate
              name="created"
              label="Created"
              value={values.created}
            />
          </Grid>
        </Grid>

        <Grid item sx={styles.buttons}>
          <Controls.Button
            type="submit"
            text="Save"
            variant="contained"
            endIcon={<SaveIcon />}
            onClick={handleSubmit}
          />
        </Grid>
      </Form>
    </Fragment>
  )
}

export default withSnackbar(withRouter(AccountEditForm))
