import React, { useState } from "react"
import Header from "../components/Header"
import { Box } from "@mui/material"
import ModelEditForm from "../components/ModelEditForm"
import { spacing } from "../pages/services/styleServices"
import useAssistants from "../components/useAssistants"

const styles = {
  pageContent: {
    margin: spacing(1),
    padding: spacing(1),
  },
}

const ModelEdit = (props) => {
  const [title, setTitle] = useState("AI Designer")

  const assistants = useAssistants()

  return (
    <Header title={title}>
      <Box sx={styles.pageContent}>
        <ModelEditForm {...props} setTitle={setTitle} assistants={assistants} />
      </Box>
    </Header>
  )
}

export default ModelEdit
