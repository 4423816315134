import * as actions from "../actionTypes"

const initialState = []

export default function elementDefinitions(state = initialState, action) {
  switch (action.type) {
    case actions.SET_ELEMENT_DEFINITIONS: {
      console.log("%c[redux] Setting element definitions", "color:lightgreen", {
        action,
        state,
      })

      // Check state for existing element definitions based on action.payload.values items having a matching 'type' property

      const newState = action.payload.value

      // Now append any existing state items that are not in the new state, based on 'type' property

      const itemsToAdd = state.filter(
        (item) => !newState.some((newItem) => newItem.type === item.type)
      )

      newState.push(...itemsToAdd)

      console.log("%c[redux] New element definitions", "color:lightgreen", {
        newState,
      })

      return newState
    }

    default:
      return state
  }
}
