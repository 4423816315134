import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
} from "@mui/material"
import Controls from "./controls/Controls"
import { useState } from "react"

const DeleteDialog = ({ open, onClose, viewSetName }) => {
    const [deleteText, setDeleteText] = useState("")
    return (
        <Dialog open={open} onClose={() => onClose({ shouldDelete: false })} sx={{ zIndex: "1000" }}>
            <DialogTitle>Delete <b>{viewSetName}</b> View Set</DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <DialogContentText>Are you sure you want to delete this entire <b>{viewSetName}</b> View Set?</DialogContentText>
                    <Alert severity="warning">This action cannot be undone, and will delete this view set including all views</Alert>
                    <Controls.TextInput
                        label="Type 'DELETE' then click Delete"
                        value={deleteText}
                        onChange={(e) => setDeleteText(e.target.value)}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose({ shouldDelete: false })}>Cancel</Button>
                <Button
                    onClick={() => onClose({ shouldDelete: true })}
                    disabled={deleteText !== "DELETE"}
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteDialog
