import React, { useEffect, useState } from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { List, ListItem, ListItemText, Typography } from "@mui/material"
import db from "../Firestore"
import firebase from "firebase/compat/app"
import * as dataServices from "../pages/services/dataServices"

const styles = {
    dialog: {
        minWidth: "260px",
    },
}

const SelectComponentDialog = (props) => {
    const { open, setOpen, setSelectedComponents } = props

    const [accountId, setAccountId] = useState()

    const [components, setComponents] = useState()

    // Array of indexes of selected components
    const [selected, setSelected] = useState([])

    useEffect(() => {
        const unsub = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                user.getIdTokenResult(false).then((token) => {
                    setAccountId(token.claims.account_id)
                })
            }
        })

        return unsub
    }, [])

    useEffect(() => {
        if (accountId && open && !components) {
            loadComponents(accountId)
        }
    }, [accountId, open, components])

    useEffect(() => {
        if (open) {
            setSelected([])
        }
    }, [open])

    const loadComponents = async (accountId) => {
        const query = db.collection("components").where("account_id", "==", accountId)
        const components = await dataServices.loadData("component dialog", query)
        setComponents(components.sort((a, b) => a.name.localeCompare(b.name)))
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleOK = () => {
        setOpen(false)
        setSelectedComponents(components.filter((c, index) => selected.includes(index)))
    }

    const handleToggleSelection = (index) => {
        if (selected.includes(index)) {
            setSelected(selected.filter((item) => item !== index))
        } else {
            setSelected([...selected, index])
        }
    }

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Project Components</DialogTitle>
                <DialogContent sx={styles.dialog}>
                    <DialogContentText>Select 1 or more component(s)</DialogContentText>

                    <List dense>
                        {components &&
                            components.map((component, index) => (
                                <ListItem
                                    key={component.id}
                                    button
                                    selected={selected.includes(index)}
                                    onClick={() => handleToggleSelection(index)}
                                >
                                    <ListItemText
                                        primary={component.name}
                                        secondary={
                                            <Typography
                                                variant="caption"
                                                color="textSecondary"
                                                component={"span"}
                                            >
                                                {component.description}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} sx={{ textTransform: "none" }} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleOK} sx={{ textTransform: "none" }} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default SelectComponentDialog
