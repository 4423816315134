import { Box, IconButton, MenuItem, Tooltip, Typography } from "@mui/material"
import { useEffect } from "react"
import { useMemo } from "react"
import { useState } from "react"
import { getUniqueProps } from "../pages/services/modelEditServices"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"

const ModelEditSortValues = (props) => {
    const { propNames, elementType, view, sortedPropValues, setSortedPropValues } = props

    const [selectedPropName, setSelectedPropName] = useState(undefined)

    const [selectedPropValue, setSelectedPropValue] = useState(undefined)

    const uniquePropValues = useMemo(() => {
        if (view && elementType) {
            const result = getUniqueProps(elementType, [view])
            console.log("uniquePropValues", { result, view, propNames, elementType })
            return result
        }
    }, [view, propNames, elementType])

    useEffect(() => {
        if (uniquePropValues) {
            const newSortedPropValues = propNames.map((propName) => {
                const existing = sortedPropValues.find((item) => item.name === propName)

                if (existing && existing.values.length > 0) {
                    return existing
                } else {
                    const result = {
                        name: propName,
                        values:
                            uniquePropValues
                                .find((item) => item.name === propName)
                                ?.values.sort((a, b) => a.localeCompare(b)) || [],
                    }
                    return result
                }
            })

            console.log('newSortedPropValues', newSortedPropValues)

            setSortedPropValues(newSortedPropValues)
        }
    }, [uniquePropValues, propNames])

    const moveUp = (propName, propVal) => {
        const newSortedPropValues = sortedPropValues.map((item) => {
            if (item.name === propName) {
                const index = item.values.indexOf(propVal)
                if (index > 0) {
                    const newValues = [...item.values]
                    newValues.splice(index, 1)
                    newValues.splice(index - 1, 0, propVal)
                    return { ...item, values: newValues }
                }
            }
            return item
        })

        setSortedPropValues(newSortedPropValues)
    }

    const moveDown = (propName, propVal) => {
        const newSortedPropValues = sortedPropValues.map((item) => {
            if (item.name === propName) {
                const index = item.values.indexOf(propVal)
                if (index < item.values.length - 1) {
                    const newValues = [...item.values]
                    newValues.splice(index, 1)
                    newValues.splice(index + 1, 0, propVal)
                    return { ...item, values: newValues }
                }
            }
            return item
        })

        setSortedPropValues(newSortedPropValues)
    }

    return (
        <>
            <Box>
                {propNames &&
                    view &&
                    sortedPropValues &&
                    propNames.map((propName) => {
                        const existingValues = sortedPropValues.find(
                            (item) => item.name === propName
                        )

                        const propValues = existingValues?.values || []
                        return (
                            <Box key={propName}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    <Tooltip title="Select a property value then use the arrow keys to change its position">
                                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                            {propName}
                                        </Typography>
                                    </Tooltip>
                                    <IconButton
                                        size="small"
                                        onClick={() => moveUp(propName, selectedPropValue)}
                                        sx={{ marginLeft: "auto" }}
                                        disabled={propName !== selectedPropName}
                                    >
                                        <ArrowUpwardIcon />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={() => moveDown(propName, selectedPropValue)}
                                        disabled={propName !== selectedPropName}
                                    >
                                        <ArrowDownwardIcon />
                                    </IconButton>
                                </Box>
                                {propValues.map((propValue) => (
                                    <Box key={propValue}>
                                        <MenuItem
                                            selected={
                                                selectedPropValue === propValue &&
                                                selectedPropName === propName
                                            }
                                            onClick={() => {
                                                setSelectedPropName(propName)
                                                setSelectedPropValue(propValue)
                                            }}
                                        >
                                            {propValue}
                                        </MenuItem>
                                    </Box>
                                ))}
                            </Box>
                        )
                    })}
            </Box>
        </>
    )
}

export default ModelEditSortValues
