import React, { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import db from "../Firestore"
import { useSnackbar } from "notistack"
import { Typography, Box } from "@mui/material"
import { CardContent } from "@mui/material"
import { Card } from "@mui/material"
import { CardActions } from "@mui/material"
import { Skeleton } from "@mui/material"
import { Button } from "@mui/material"
import CreateIcon from "@mui/icons-material/Create"
import LinkIcon from "@mui/icons-material/Link"
import LinkOffIcon from "@mui/icons-material/LinkOff"
import CreditCardIcon from "@mui/icons-material/CreditCard"
import { CardHeader } from "@mui/material"
import * as stripeServices from "../pages/services/stripeServices"
import { Alert } from "@mui/material"
import { functions } from "../Firestore"
import { httpsCallable } from "firebase/functions"
import { spacing } from "../pages/services/styleServices"

const styles = {
    action: {
        marginTop: spacing(3),
        marginLeft: spacing(0),
        marginRight: spacing(3),
        padding: spacing(2),
        maxWidth: "320px",
    },
    skeleton: {
        height: 200,
        width: 250,
        marginLeft: spacing(2),
        marginTop: spacing(2),
    },
}

const StripeActions = (props) => {
    const { accountInfo, accountId, setShowProgress } = props

    const { enqueueSnackbar } = useSnackbar()

    const [actions, setActions] = useState()

    const findStripeCustomerByEmail = async (email) => {
        const getStripeCustomerByEmail = httpsCallable(functions, "getStripeCustomerByEmail")

        const result = await getStripeCustomerByEmail({ email: email, stripe_mode: stripeServices.getStripeMode()})

        return result.data.customers
    }

    const handleCreateStripeSubscription = async (productName) => {
        const createStripeSubscription = httpsCallable(functions, "createStripeSubscription")

        const quantities = [{ name: stripeServices.AIM_PRO_PRODUCT, quantity: 1 }]
        console.log("Create subscription", { accountId, quantities })

        const result = await createStripeSubscription({ accountId, quantities, stripe_mode: stripeServices.getStripeMode()})

        if (result.data.status) {
            const status = result.data.status

            console.log(result)

            enqueueSnackbar(status.message, { variant: status.type })
        }
    }

    // Get the actions that need to be performed on this account

    const getAccountActions = async (accountInfo) => {
        if (accountInfo.name === "") {
            console.log("No account name...returning from creating actions")
            return
        }

        const newActions = []

        console.log("Get account actions", accountInfo)

        const hasStripeCustId =
            accountInfo.hasOwnProperty("stripe_cust_id") && accountInfo.stripe_cust_id !== ""

        // Check if there's a Stripe account with a matching email address, but it's not
        // linked to the Simplify account

        let linkToStripeAccount = false

        if (!hasStripeCustId) {
            console.log("Find Stripe customers with email", accountInfo.email)

            // Try finding Stripe customers by email match, since we don't have a Stripe cust id
            const customers = await findStripeCustomerByEmail(accountInfo.email)

            console.log("search results", customers)

            if (customers && customers.length === 1) {
                newActions.push({
                    title: "Link Stripe Account",
                    description: `Link to existing Stripe account with same email`,
                    name: "Link",
                    icon: <LinkIcon color="primary" />,
                    func: handleLinkToStripeCustomerByEmail,
                })

                linkToStripeAccount = true
            }
        }

        // Check if the Simplify account has a Stripe Customer defined

        if (!hasStripeCustId && !linkToStripeAccount) {
            newActions.push({
                title: "Create Stripe Account",
                description: "Create a Stripe account so that we can charge the customer",
                name: "Create",
                icon: <CreateIcon color="primary" />,
                func: handleCreateStripeCustomer,
            })
        }

        let stripeCustomerDeleted = false

        const stripeCustomerInfo = await stripeServices.getStripeCustomer(accountId)

        if (hasStripeCustId) {
            if (stripeCustomerInfo.customer && stripeCustomerInfo.customer.deleted) {
                stripeCustomerDeleted = true

                newActions.push({
                    title: "Remove Stripe Link",
                    description:
                        "Unlink from this deleted Stripe account. Once this is done, a new Stripe account can be created",
                    name: "Unlink",
                    icon: <LinkOffIcon color="primary" />,
                    func: handleRepair,
                })
            }
        }

        // Check if we need to create a subscription

        const hasSubscription =
            stripeCustomerInfo.customer &&
            stripeCustomerInfo.customer.subscriptions &&
            stripeCustomerInfo.customer.subscriptions.length > 0

        if (hasStripeCustId && !stripeCustomerDeleted) {
            console.log(stripeCustomerInfo.customer)

            if (!hasSubscription) {
                newActions.push({
                    title: "Create Subscription",
                    description: "Create a subscription in this account",
                    name: "Create",
                    icon: <CreateIcon />,
                    func: () => handleCreateStripeSubscription(stripeServices.AIM_PRO_PRODUCT),
                })
            }
        }

        // Check if any payment methods are defined

        const hasPaymentMethods =
            stripeCustomerInfo.customer &&
            stripeCustomerInfo.cards &&
            stripeCustomerInfo.cards.length > 0

        if (hasSubscription && !hasPaymentMethods) {
            newActions.push({
                title: "No payment methods defined",
                description: "Customer needs to define a payment method",
                name: null,
                icon: <CreditCardIcon />,
                func: null,
            })
        }

        console.log(`Created actions: ${newActions.map((action) => action.name).join(", ")}`)

        setActions(newActions)
    }

    useEffect(() => {
        getAccountActions(accountInfo)
    }, [accountInfo])

    const getAccount = async (accountId) => {
        const accountDoc = await db.collection("accounts").doc(accountId).get()

        const account = accountDoc.data()

        return account
    }

    const handleCreateStripeCustomer = async () => {
        const createStripeCustomer = httpsCallable(functions, "createStripeCustomer")

        const account = await getAccount(accountId)

        const result = await createStripeCustomer({
            customer_account_id: accountId,

            // TODO: work out if we need to provide a billing address
            address: {
                city: "",
                country: "",
                line1: "",
                line2: "",
                postal_code: "",
                state: "",
            },
            email: account.email,
            name: account.name,
            phone: account.phone,

            // live or test
            stripe_mode: stripeServices.getStripeMode(),
        })

        console.log("createStripeCustomer result", result)

        const status = result.data.status

        enqueueSnackbar(status.message, { variant: status.type })
    }

    const handleRepair = async () => {
        const repairStripeConfig = httpsCallable(functions, "repairStripeConfig")

        const result = await repairStripeConfig({ account_id: accountId, stripe_mode: stripeServices.getStripeMode()})

        if (result.data.status) {
            const status = result.data.status

            console.log(result)

            enqueueSnackbar(status.message, { variant: status.type })
        }
    }

    const handleLinkToStripeCustomerByEmail = async () => {
        const linkToStripeCustomerByEmail = httpsCallable(functions, "linkToStripeCustomerByEmail")

        const account = await getAccount(accountId)

        const result = await linkToStripeCustomerByEmail({
            account_id: accountId,
            email: account.email,
            stripe_mode: stripeServices.getStripeMode(),
        })

        const status = result.data.status

        enqueueSnackbar(status.message, { variant: status.type })
    }

    return (
        <Box>
            {actions === undefined && (
                <Skeleton variant="rectangular" width={200} height={200} sx={styles.skeleton} />
            )}
            <Grid container direction="row">
                {actions &&
                    actions.map((action) => (
                        <Grid item key={action.name} sx={styles.action}>
                            <Card>
                                <CardHeader avatar={action.icon} title={action.title} />
                                <CardContent>
                                    <Typography variant="body2" component={"span"}>
                                        {action.description}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    {action.func && (
                                        <Button
                                            size="small"
                                            onClick={async () => {
                                                setShowProgress(true)
                                                await action.func()
                                                setShowProgress(false)
                                            }}
                                            variant="outlined"
                                            color="primary"
                                        >
                                            {action.name}
                                        </Button>
                                    )}
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
            </Grid>
            {actions && actions.length === 0 && (
                <Alert severity="success">There are no billing actions to perform</Alert>
            )}
        </Box>
    )
}

export default StripeActions
