// UnknownNode.js
import { Node, mergeAttributes } from '@tiptap/core'

const UnknownNode = Node.create({
  name: 'unknown',
  group: 'block',
  draggable: false,
  selectable: false,
  priority: 1000,

  parseDOM: [
    {
      getAttrs: (dom, schema) => {
        const nodeName = dom.nodeName.toLowerCase()
        const isHandled = schema.nodes[nodeName]

        if (!isHandled) {
          console.warn(`Unknown element type encountered: <${nodeName}>`, dom)
          return { tag: nodeName, innerHTML: dom.innerHTML }
        }

        return false
      },
    },
  ],

  renderHTML({ node, HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(HTMLAttributes, { class: 'unknown-node' }),
      node.attrs.innerHTML,
    ]
  },

  addAttributes() {
    return {
      tag: {
        default: null,
      },
      innerHTML: {
        default: null,
      },
    }
  },
})

export default UnknownNode
