import React, { useState } from "react"
import { Stack, Typography } from "@mui/material"
import CreditCardIcon from "@mui/icons-material/CreditCard"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { Card, Box } from "@mui/material"
import { CardHeader } from "@mui/material"
import { IconButton } from "@mui/material"
import { CardContent } from "@mui/material"
import { Menu } from "@mui/material"
import { ListItemIcon } from "@mui/material"
import VisibilityIcon from "@mui/icons-material/Visibility"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { MenuItem } from "@mui/material"
import CreditCardEditDialog from "./CreditCardEditDialog"
import { httpsCallable } from "firebase/functions"
import { functions } from "../Firestore"
import { useSnackbar } from "notistack"
import { spacing } from "../pages/services/styleServices"
import * as stripeServices from "../pages/services/stripeServices"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

const styles = {
  pageContent: {
    marginTop: spacing(1),
    marginLeft: spacing(1),
    marginRight: spacing(2),
    padding: spacing(1),
    maxWidth: "400px",
  },
  defaultCardMsg: {
    marginTop: spacing(1),
  },
}

const StripeCard = (props) => {
  const { card, updateCard, reload, custId, setShowProgress } = props

  const { enqueueSnackbar } = useSnackbar()

  const [cardAnchorEl, setCardAnchorEl] = useState(null)

  const [isShowDetails, setShowDetails] = useState(false)

  const handleEditCard = () => {
    handleCardMenuClose()
    setShowCardEditDialog(true)
  }

  const handleDeleteCard = async () => {
    handleCardMenuClose()

    setShowProgress(true)

    console.log("Delete card", { card, updateCard })

    //TODO: move this into stripeServices.js
    const deleteStripePaymentMethod = httpsCallable(
      functions,
      "deleteStripePaymentMethod"
    )

    const result = await deleteStripePaymentMethod({
      paymentMethodId: card.id,
      stripe_mode: stripeServices.getStripeMode(),
    })

    console.log("Delete payment method result", result)

    await reload()

    setShowProgress(false)

    enqueueSnackbar("Card deleted", { variant: "success" })
  }

  const handleSetCardDefault = async () => {
    handleCardMenuClose()

    setShowProgress(true)

    console.log("Set card default", { card, updateCard, custId })

    const result = await stripeServices.setStripeCardAsDefault(card.id, custId)

    console.log("Set payment method default result", result)

    await reload()

    setShowProgress(false)

    enqueueSnackbar("Card set as default", { variant: "success" })
  }

  const handleCardMenuClose = () => {
    setCardAnchorEl(null)
  }

  const [showCardEditDialog, setShowCardEditDialog] = useState(false)

  return (
    <Box>
      {showCardEditDialog && (
        <CreditCardEditDialog
          open={showCardEditDialog}
          setOpen={setShowCardEditDialog}
          values={card}
          updateCard={updateCard}
        />
      )}

      <Card sx={styles.pageContent}>
        <CardHeader
          avatar={
            <CreditCardIcon color={card.is_default ? "primary" : "disabled"} />
          }
          disableTypography={true}
          action={
            <IconButton
              aria-label="settings"
              aria-controls="card-menu"
              aria-haspopup="true"
              onClick={(event) => setCardAnchorEl(event.target)}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          }
          title={
            <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
              <Typography
                variant="body1"
                component={"span"}
                fontWeight={"bold"}
              >
                Payment Method
              </Typography>
              {card.is_default && (
                <CheckCircleIcon color="success" fontSize="small" />
              )}
            </Stack>
          }
          subheader={
            card.is_default && (
              <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
                <Typography variant="caption" color="text.secondary">
                  Default payment method
                </Typography>
              </Stack>
            )
          }
        />

        <CardContent sx={{ display: "flex", flexDirection: "column" }}>
          {isShowDetails && (
            <Stack>
              <Typography component={"span"}>
                {card.brand.toUpperCase()} {card.last4}
              </Typography>
              <Typography component={"span"}>
                Expiry {card.exp_month}/{card.exp_year}
              </Typography>
              <Typography component={"span"}>
                {card.billing_details.name}
              </Typography>
              <Typography component={"span"}>
                {card.billing_details.email}
              </Typography>
              <Typography component={"span"}>
                {card.billing_details.phone}
              </Typography>
              <Typography component={"span"}>
                {card.billing_details.address.line1}
              </Typography>
              <Typography component={"span"}>
                {card.billing_details.address.line2}
              </Typography>
              <Typography component={"span"}>
                {card.billing_details.address.city}
              </Typography>
              <Typography component={"span"}>
                {card.billing_details.address.state}{" "}
                {card.billing_details.address.postal_code}{" "}
                {card.billing_details.address.country}
              </Typography>
            </Stack>
          )}
          {!isShowDetails && (
            <Stack>
              <Typography component={"span"}>XXXX</Typography>
              <Typography variant="caption">
                Click 'Show details' in menu to see limited card details
              </Typography>
            </Stack>
          )}
        </CardContent>
      </Card>

      {cardAnchorEl && (
        <Menu
          id="card-menu"
          anchorEl={cardAnchorEl}
          keepMounted
          open={cardAnchorEl !== null}
          onClose={handleCardMenuClose}
        >
          <MenuItem onClick={(event) => handleEditCard(event)}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            Edit
          </MenuItem>
          <MenuItem onClick={(event) => handleDeleteCard(event)}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            Delete
          </MenuItem>
          <MenuItem onClick={(event) => setShowDetails(!isShowDetails)}>
            <ListItemIcon>
              <VisibilityIcon fontSize="small" />
            </ListItemIcon>
            {isShowDetails ? "Hide" : "Show"} details
          </MenuItem>
          <MenuItem
            onClick={(event) => handleSetCardDefault(event)}
            disabled={card.is_default}
          >
            <ListItemIcon>
              <CreditCardIcon fontSize="small" />
            </ListItemIcon>
            Set default payment method
          </MenuItem>
        </Menu>
      )}
    </Box>
  )
}

export default StripeCard
