import React, { useState, useEffect, useMemo } from "react"
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    FormControlLabel,
    Stack,
    Switch,
    Typography,
    colors,
} from "@mui/material"
import * as palette from "./symbols/palette"
import _ from "lodash"
import * as urlServices from "../pages/services/urlServices"
import { spacing } from "../pages/services/styleServices"
import { styled } from "@mui/material/styles"
import { useHistory } from "react-router-dom"
import StyledLink from "./controls/StyledLink"
import CheckIcon from "@mui/icons-material/Check"
import Controls from "./controls/Controls"

const styles = {
    deliverable: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "no-wrap",
        marginLeft: spacing(0),
        marginBottom: spacing(1),
    },
    deliverableText: {
        marginLeft: spacing(1),
    },
    symbolIcon: {
        marginRight: spacing(1),
    },
    cardHeader: {
        paddingBottom: spacing(1),
        marginLeft: 0,
        paddingLeft: 0,
    },
}

const DARK_COLOR = 200

const WorkBreakdownStructure = (props) => {
    const { model } = props

    const [workPackages, setWorkPackages] = useState()

    const [deliverables, setDeliverables] = useState()

    const colorSet = [
        colors.amber,
        colors.blue,
        colors.pink,
        colors.deepOrange,
        colors.lime,
        colors.deepPurple,
        colors.lightGreen,
        colors.red,
        colors.indigo,
        colors.lightBlue,
        colors.teal,
        colors.orange,
        colors.green,
        colors.purple,
        colors.cyan,
        colors.yellow,
        colors.blueGrey,
        colors.brown,
        colors.grey,
    ]

    const [plateaus, setPlateaus] = useState()

    const [colorCode, setColorCode] = useState(false)

    const [showResponsibleOnly, setShowResponsibleOnly] = useState(false)

    const getResponsibleParty = (element) => {
        const matches = element.name.match(/\((.*?)\)/)
        if (matches) {
            return matches[1]
        }
        return undefined
    }

    const responsible = useMemo(() => {
        // Get the unique list of values that are in ( ) in the name

        const result = []

        if (workPackages) {
            const wpResult = _.uniq(
                workPackages
                    .map((wp) => getResponsibleParty(wp))
                    .filter((item) => item !== undefined)
                    .sort((a, b) => a.localeCompare(b))
            )

            result.push(...wpResult)
        }

        if (deliverables) {
            const dResult = _.uniq(
                deliverables
                    .map((d) => getResponsibleParty(d))
                    .filter((item) => item !== undefined)
                    .sort((a, b) => a.localeCompare(b))
            )

            result.push(...dResult)
        }

        return Array.from(new Set(result)).sort((a, b) => a.localeCompare(b))
    }, [workPackages, deliverables])

    const [responsibleFilter, setResponsibleFilter] = useState([])

    const getParentCount = (element) => {
        const parentCount = model.model.elements
            .filter((rel) => rel.source === element.id)
            .map((rel) => model.model.elements.find((el) => el.id === rel.target))
            .filter((el) => el !== undefined).length

        return parentCount
    }

    const getElementViewUrl = ({ element, model }) => {
        return urlServices.createElementViewUrl({
            parentId: model.parent_id,
            elementId: element.id,
            fileName: model.model.file,
        })
    }

    const getParentElements = ({ model, parentElement, childElementType }) => {
        return model.model.elements
            .filter((rel) => rel.target === parentElement.id)
            .map((rel) =>
                model.model.elements.find(
                    (el) => el.id === rel.source && el.type === childElementType
                )
            )
            .filter((el) => el !== undefined)
            .sort((a, b) => a.name.localeCompare(b.name))
    }

    const getWorkPackagePlateaus = ({ parentElement, model }) =>
        model.model.elements
            .filter((rel) => rel.source === parentElement.id)
            .map((rel) =>
                model.model.elements.find(
                    (el) => el.id === rel.target && el.type === palette.PLATEAU
                )
            )
            .filter((el) => el !== undefined)
            .map((p) => ({
                ...p,
                elementViewUrl: getElementViewUrl({ model, element: p }),
            }))

    const getChildDeliverables = ({ parentElement, model }) =>
        model.model.elements
            .filter((el) => el.source === parentElement.id)
            .map((rel) =>
                model.model.elements.find(
                    (el) => el.id === rel.target && el.type === palette.DELIVERABLE
                )
            )
            .filter((el) => el !== undefined)
            .sort((a, b) => a.name.localeCompare(b.name))

    const getPlateaus = ({ model, showResponsibleOnly, responsibleFilter }) => {
        const leafPlateaus = model.model.elements
            .filter((el) => el.type === palette.PLATEAU)
            .filter((el) => model.model.elements.find((rel) => rel.target === el.id))
            .filter((el) => model.model.elements.find((rel) => rel.source === el.id) === undefined)

        //console.log("%cleaf plateaus", "color:yellow", leafPlateaus)

        const getParentPlateaus = (currentPlateaus) => {
            //console.log("%cget parent plateaus start", "color:yellow", currentPlateaus)

            const parentPlateaus = currentPlateaus
                .map((el) =>
                    model.model.elements.find(
                        (rel) =>
                            rel.type === palette.TRIGGERING_RELATIONSHIP && rel.target === el.id
                    )
                )
                .filter((item) => item !== undefined)
                .map((rel) =>
                    model.model.elements.find(
                        (el) => el.id === rel.source && el.type === palette.PLATEAU
                    )
                )

            return parentPlateaus
        }

        // Avoid infinite loop
        let sanity = 20

        let result = [...leafPlateaus]
        let inputPlateaus = [...leafPlateaus]

        while (true) {
            if (--sanity < 0) {
                break
            }
            const parentPlateaus = getParentPlateaus([...inputPlateaus])

            result = [...parentPlateaus, ...result]
            inputPlateaus = [...parentPlateaus]

            if (parentPlateaus.length === 0) {
                break
            }
        }

        const plateausWithWorkPackagesAndDeliverables = result.map((p) => {
            const plateauWorkPackages = getParentElements({
                model,
                parentElement: p,
                childElementType: palette.WORK_PACKAGE,
            }).filter((wp) =>
                showResponsibleOnly ? responsibleFilter.includes(getResponsibleParty(wp)) : true
            )

            const plateauDeliverables = getParentElements({
                model,
                parentElement: p,
                childElementType: palette.DELIVERABLE,
            })

            const expandedPlateauWorkPackages = expandWorkPackages({
                workPackages: plateauWorkPackages,
                model,
            })

            return {
                ...p,
                elementViewUrl: getElementViewUrl({ model, element: p }),
                workPackages: expandedPlateauWorkPackages,
                deliverables: expandDeliverables({
                    deliverables: plateauDeliverables,
                    model,
                }).filter((wp) =>
                    showResponsibleOnly ? responsibleFilter.includes(getResponsibleParty(wp)) : true
                ),
            }
        })

        return _.uniq(plateausWithWorkPackagesAndDeliverables)
    }

    const expandDeliverables = ({ deliverables, model }) => {
        return deliverables.map((d) => {
            return {
                ...d,
                elementViewUrl: getElementViewUrl({ element: d, model }),
                parentCount: getParentCount(d),
                responsible: getResponsibleParty(d),
            }
        })
    }

    const expandWorkPackages = ({ workPackages, model }) => {
        return workPackages.map((wp) => {
            return {
                ...wp,
                elementViewUrl: getElementViewUrl({ element: wp, model }),
                parentCount: getParentCount(wp),
                responsible: getResponsibleParty(wp),
                deliverables: expandDeliverables({
                    deliverables: getChildDeliverables({ parentElement: wp, model }),
                    model,
                }),
                plateaus: getWorkPackagePlateaus({ parentElement: wp, model }),
            }
        })
    }

    const getBaseWorkPackages = (model) => {
        const baseWorkPackages = model.model.elements
            .filter((el) => el.type === palette.WORK_PACKAGE)
            .sort((a, b) => a.name.localeCompare(b.name))

        return baseWorkPackages
        //return expandWorkPackages({ workPackages: baseWorkPackages, model: model })
    }

    const getDeliverables = (model) => {
        const baseDeliverables = model.model.elements
            .filter((el) => el.type === palette.DELIVERABLE)
            .sort((a, b) => a.name.localeCompare(b.name))

        return expandDeliverables({ deliverables: baseDeliverables, model: model })
    }

    const getWorkPackages = (model) => {
        const wps = getBaseWorkPackages(model)

        const expandedWps = expandWorkPackages({ workPackages: wps, model })

        return expandedWps
    }

    const workPackagesWithNoParent = useMemo(() => {
        //return workPackages && workPackages.filter((wp) => wp.plateaus.length === 0)
        return workPackages && workPackages.filter((wp) => wp.parentCount === 0)
    }, [workPackages])

    useEffect(() => {
        if (model) {
            // Get work packages (parent) and deliverables (child) from model

            const plateaus = getPlateaus({ model, showResponsibleOnly, responsibleFilter })
            setPlateaus(plateaus)

            setWorkPackages(getWorkPackages(model))
            setDeliverables(getDeliverables(model))
        }
    }, [model, showResponsibleOnly, responsibleFilter])

    const StyledChip = styled(Chip)(({ index, label, theme }) => {
        const baseColor = colorSet[index % colorSet.length]

        if (baseColor) {
            const isSelected = responsibleFilter.includes(label)

            return {
                backgroundColor: isSelected ? baseColor[DARK_COLOR] : baseColor[50],
                border: `1px solid ${baseColor[100]}`,
                color: isSelected
                    ? theme.palette.getContrastText(baseColor[DARK_COLOR])
                    : theme.palette.getContrastText(baseColor[50]),
            }
        }
    })

    return (
        <Stack>
            <Typography variant="h6">Responsible</Typography>
            <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
                <Stack direction="row" gap={2}>
                    {responsible &&
                        responsible.map((r, index) => (
                            <StyledChip
                                key={r}
                                index={index}
                                label={r}
                                variant="filled"
                                icon={
                                    responsibleFilter.includes(r) ? (
                                        <CheckIcon style={{ color: "inherit" }} />
                                    ) : undefined
                                }
                                onClick={(e) =>
                                    setResponsibleFilter((curr) =>
                                        curr.includes(r)
                                            ? curr.filter((item) => item !== r)
                                            : [...curr, r]
                                    )
                                }
                            />
                        ))}
                </Stack>
            </Box>
            <Stack sx={{ marginBottom: "20px", alignItems: "center" }} direction="row" gap={2}>
                <Controls.Button text="Clear" onClick={() => setResponsibleFilter([])} />
                <FormControlLabel
                    control={
                        <Switch
                            checked={colorCode}
                            onChange={(e) => setColorCode(e.target.checked)}
                        />
                    }
                    label={<Typography variant="caption">Color code</Typography>}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={showResponsibleOnly}
                            onChange={(e) => setShowResponsibleOnly(e.target.checked)}
                        />
                    }
                    label={<Typography variant="caption">Filter Responsible</Typography>}
                />
            </Stack>
            <Typography variant="h6">Roadmap</Typography>
            <Box>
                {plateaus && (
                    <PlateauList
                        plateaus={plateaus}
                        responsible={responsible}
                        responsibleFilter={responsibleFilter}
                        colorSet={colorSet}
                        colorCode={colorCode}
                    />
                )}

                <Divider />

                {workPackagesWithNoParent && workPackagesWithNoParent.length > 0 && (
                    <WorkPackageList
                        workPackages={workPackagesWithNoParent}
                        responsible={responsible}
                        responsibleFilter={responsibleFilter}
                        colorSet={colorSet}
                        colorCode={colorCode}
                        indentLevel={0}
                    />
                )}
            </Box>
            <Box>
                <Typography component={"span"}>{/* view goes here */}</Typography>
            </Box>
        </Stack>
    )
}

const PlateauList = ({ plateaus, responsible, responsibleFilter, colorSet, colorCode }) => {
    const history = useHistory()

    return (
        <Box>
            {plateaus &&
                plateaus.map((p) => (
                    <Card key={p.id} elevation={0}>
                        <CardHeader
                            title={<StyledLink to={p.elementViewUrl}>{p.name}</StyledLink>}
                            subheader={p.documentation}
                            avatar={<PlateauIcon />}
                            sx={styles.cardHeader}
                            onClick={(e) => {
                                history.push(p.elementViewUrl)
                            }}
                        />
                        <CardContent>
                            {p.workPackages.length > 0 && (
                                <WorkPackageList
                                    workPackages={p.workPackages}
                                    responsible={responsible}
                                    responsibleFilter={responsibleFilter}
                                    colorSet={colorSet}
                                    colorCode={colorCode}
                                    indentLevel={1}
                                />
                            )}
                            {p.deliverables.length > 0 && (
                                <DeliverableList
                                    deliverables={p.deliverables}
                                    responsible={responsible}
                                    responsibleFilter={responsibleFilter}
                                    colorSet={colorSet}
                                    colorCode={colorCode}
                                    indentLevel={1}
                                />
                            )}
                        </CardContent>
                    </Card>
                ))}
        </Box>
    )
}

const getElementColors = ({ colorCode, element, responsible, responsibleFilter, colorSet }) => {
    let baseColor

    //console.log(`element%c ${element.name}`, "color:pink")

    if (colorCode && element && responsibleFilter && colorSet) {
        if (responsible) {
            const index = responsible.findIndex((item) => item === element.responsible)
            baseColor = colorSet[index % colorSet.length]
        } else {
            baseColor = colors.grey[100]
        }
    }

    let fillColor

    if (colorCode && element && responsibleFilter) {
        if (responsible && baseColor) {
            const index = responsible.findIndex((item) => item === element.responsible)

            //console.log("index", index)

            if (index !== -1) {
                if (responsibleFilter?.includes(element.responsible)) {
                    fillColor = baseColor[DARK_COLOR]
                } else {
                    fillColor = baseColor[50]
                }
            }
        } else {
            fillColor = colors.grey[200]
        }
    }

    let borderColor

    if (colorCode && element && responsibleFilter) {
        if (baseColor && responsible) {
            const index = responsible.findIndex((item) => item === element.responsible)

            if (index !== -1) {
                borderColor = baseColor[200]
            }
        } else {
            borderColor = colors.grey[200]
        }
    }

    // console.log("element colors", element.name, {
    //     colorCode,
    //     responsible,
    //     responsibleFilter,
    //     fillColor,
    //     borderColor,
    //     colorSet,
    // })

    return {
        fillColor,
        borderColor,
    }
}

const WorkPackageCard = ({
    workPackage,
    responsible,
    responsibleFilter,
    colorSet,
    colorCode,
    indentLevel,
}) => {
    const history = useHistory()

    const { fillColor, borderColor } = getElementColors({
        colorCode,
        element: workPackage,
        responsible,
        responsibleFilter,
        colorSet,
    })

    return (
        <Card elevation={0}>
            <CardHeader
                title={<StyledLink to={workPackage.elementViewUrl}>{workPackage.name}</StyledLink>}
                subheader={
                    <Typography variant="caption" color="text.secondary">
                        {workPackage.documentation}
                    </Typography>
                }
                avatar={
                    <WorkPackageIcon
                        fillColor={fillColor}
                        borderColor={borderColor}
                        label={workPackage.responsible}
                    />
                }
                sx={styles.cardHeader}
                onClick={(e) => {
                    history.push(workPackage.elementViewUrl)
                }}
            />
            {workPackage.deliverables && workPackage.deliverables.length > 0 && (
                <DeliverableList
                    deliverables={workPackage.deliverables}
                    responsible={responsible}
                    responsibleFilter={responsibleFilter}
                    colorSet={colorSet}
                    colorCode={colorCode}
                    indentLevel={indentLevel + 1}
                />
            )}
        </Card>
    )
}

const DeliverableList = ({
    deliverables,
    responsible,
    responsibleFilter,
    colorSet,
    colorCode,
    indentLevel,
}) => {
    return (
        <Box sx={{ marginLeft: `${indentLevel * 15}px` }}>
            {deliverables &&
                deliverables.map((d) => (
                    <DeliverableCard
                        key={d.id}
                        deliverable={d}
                        responsible={responsible}
                        responsibleFilter={responsibleFilter}
                        colorSet={colorSet}
                        colorCode={colorCode}
                        indentLevel={indentLevel}
                    />
                ))}
        </Box>
    )
}

const DeliverableCard = ({
    deliverable,
    responsible,
    responsibleFilter,
    colorSet,
    colorCode,
    indentLevel,
}) => {
    const history = useHistory()

    const { fillColor, borderColor } = getElementColors({
        colorCode,
        element: deliverable,
        responsible,
        responsibleFilter,
        colorSet,
    })

    return (
        <Card elevation={0}>
            <CardHeader
                title={<StyledLink to={deliverable.elementViewUrl}>{deliverable.name}</StyledLink>}
                subheader={
                    <Typography variant="caption" color="text.secondary">
                        {deliverable.documentation}
                    </Typography>
                }
                avatar={
                    <DeliverableIcon
                        label={deliverable.responsible}
                        fillColor={fillColor}
                        borderColor={borderColor}
                    />
                }
                sx={styles.cardHeader}
                onClick={(e) => {
                    history.push(deliverable.elementViewUrl)
                }}
            />
        </Card>
    )
}

const WorkPackageList = ({
    workPackages,
    responsible,
    responsibleFilter,
    colorSet,
    colorCode,
    indentLevel,
}) => {
    return (
        <Box sx={{ marginLeft: `${indentLevel * 15}px` }}>
            {workPackages &&
                workPackages.map((wp) => (
                    <WorkPackageCard
                        key={wp.id}
                        workPackage={wp}
                        responsible={responsible}
                        responsibleFilter={responsibleFilter}
                        colorSet={colorSet}
                        colorCode={colorCode}
                        indentLevel={indentLevel}
                    />
                ))}
        </Box>
    )
}

const height = 60
const width = 140

const defaultElementProps = {
    x: 0,
    y: 0,
    height: height,
    width: width,
}

const scale = 0.8

const MiniIcon = (props) => {
    return (
        <svg height={height * scale} width={width * scale} viewBox={"0 0 150 70"}>
            {props.children}
        </svg>
    )
}

const DeliverableIcon = ({ fillColor, borderColor, label }) => {
    return (
        <MiniIcon>
            <palette.Deliverable
                {...defaultElementProps}
                fillColor={fillColor}
                borderColor={borderColor}
                label={label}
                showLabel={true}
                // Scale up font-size since we've scaled down the element size. This makes the label readable
                fontSize={16}
            />
        </MiniIcon>
    )
}
const WorkPackageIcon = ({ fillColor, borderColor, label }) => {
    return (
        <MiniIcon>
            <palette.WorkPackage
                {...defaultElementProps}
                fillColor={fillColor}
                borderColor={borderColor}
                label={label}
                showLabel={true}
                // Scale up font-size since we've scaled down the element size. This makes the label readable
                fontSize={16}
            />
        </MiniIcon>
    )
}

const PlateauIcon = () => {
    return (
        <MiniIcon>
            <palette.Plateau {...defaultElementProps} />
        </MiniIcon>
    )
}

export default WorkBreakdownStructure
