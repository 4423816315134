import _ from "lodash"
import * as palette from "../../components/symbols/palette"
class Rule {
    constructor(_id, _name, _category) {
        this.id = _id
        this.name = _name
        this.category = _category
    }

    getId() {
        return this.id
    }
}

const getRuleScope = (sourceTypes, targetTypes, connectorTypes) => {
    const sourceCount = sourceTypes.length
    const targetCount = targetTypes.length
    const connectorCount = connectorTypes.length
    return ((sourceCount === 0 && targetCount > 0) || (sourceCount > 0 && targetCount === 0)) &&
        connectorCount === 0
        ? "model"
        : "view"
}

class TargetSourceRule2 extends Rule {
    constructor(
        id,
        name,
        category,
        targetTypes,
        sourceTypes,
        relationshipTypes,
        overrideMessage,
        required
    ) {
        super(id, name, category)
        if (id === undefined) {
            console.error("Expecting rule id", { name, category })
        }

        this.targetTypes = targetTypes
        this.sourceTypes = sourceTypes
        this.relationshipTypes = relationshipTypes
        this.overrideMessage = overrideMessage
        this.required = required // Y or N

        //console.log('override message', overrideMessage)
    }

    createMessages(failedElements, isMissing) {
        const msgMissing = isMissing ? "missing" : "should not have"

        return failedElements.map((element) => {
            const msgText = this.overrideMessage
                ? `[${element.name}] - ${this.overrideMessage}`
                : `[${element.name}] - ${msgMissing} ${getRelationshipNames(
                      this.relationshipTypes
                  )} to ${this.sourceTypes.join(", ")}`

            return {
                id: `target-to-source-${element.id}-${this.sourceTypes.join(
                    "-"
                )}-${this.targetTypes.join("-")}-${this.relationshipType}`,
                ruleId: this.getId(),
                element,
                rule: this.name,
                msg: msgText,
            }
        })
    }

    execute(modelState) {
        // Find parent type and make sure it's realized by a child type

        if (this.relationshipTypes.includes(palette.ASSOCIATION_RELATIONSHIP)) {
            console.log("%cHas association relationship", "color:lightgreen")
        }

        // Find reverse association relationships, and add these into the relations below -- so that we treat Associations as bi-directional
        const reverseAssociations = modelState.model.elements
            .filter((element) => !element.source)
            .map((element) => {
                const rels = modelState.model.elements.filter(
                    (rel) =>
                        rel.source === element.id && rel.type === palette.ASSOCIATION_RELATIONSHIP
                )

                if (rels.length > 0) {
                    return {
                        source: { id: element.id, type: element.type, name: element.name },
                        connector: rels.map((rel) => {
                            const source = modelState.model.elements.find(
                                (el) => el.id === rel.source
                            )

                            return {
                                type: rel.type,
                                target: {
                                    id: rel.target,
                                    name: source.name,
                                    type: source.type,
                                },
                            }
                        }),
                    }
                }
                return undefined
            })
            .filter((item) => item)

        console.log("reverse associations", reverseAssociations)

        const elements = _.flatten(
            modelState.model.elements
                .filter((element) => !element.source)
                .map((element) => {
                    const rels = modelState.model.elements.filter(
                        (rel) => rel.target === element.id
                    )

                    return {
                        target: { id: element.id, type: element.type, name: element.name },
                        connector: rels.map((rel) => {
                            const source = modelState.model.elements.find(
                                (el) => el.id === rel.source
                            )

                            return {
                                type: rel.type,
                                source: {
                                    id: rel.source,
                                    name: source.name,
                                    type: source.type,
                                },
                            }
                        }),
                    }
                })
        )

        console.log("targetHasSourceElements", elements)

        let messages = []

        const ruleScope = getRuleScope(this.sourceTypes, this.targetTypes, this.relationshipTypes)

        console.log("%crule scope", "color:lightgreen", { ruleScope, required: this.required })

        const targetElements = modelState.model.elements.filter((element) =>
            this.targetTypes.includes(element.type)
        )

        const rels = modelState.model.elements
            .filter((rel) => rel.source && this.relationshipTypes.includes(rel.type))
            .filter((rel) => {
                return modelState.model.elements.find(
                    (element) =>
                        element.id === rel.target && this.targetTypes.includes(element.type)
                )
            })

        const relTargets = rels.map((rel) => rel.target)

        let failed

        switch (ruleScope) {
            case "view":
                if (this.required === "Y") {
                    failed = targetElements.filter((element) => !relTargets.includes(element.id))
                } else {
                    failed = targetElements.filter((element) => relTargets.includes(element.id))
                }

                messages = failed.map((element) => {
                    const msgText = this.overrideMessage
                        ? `[${element.name}] - ${this.overrideMessage}`
                        : `[${element.name}] - missing ${getRelationshipNames(
                              this.relationshipTypes
                          )} to ${this.sourceTypes.join(", ")}`

                    return {
                        id: `target-to-source-${element.id}-${this.sourceTypes.join(
                            "-"
                        )}-${this.targetTypes.join("-")}-${this.relationshipType}`,
                        ruleId: this.getId(),
                        element,
                        rule: this.name,
                        msg: msgText,
                    }
                })

                break

            case "model":
                failed = targetElements.length === 0

                const msgText = this.overrideMessage || `Expecting 1 or more: ${this.targetTypes}`

                messages = [
                    {
                        id: `${this.name}-${this.sourceTypes || ""}-${this.targetTypes || ""}-${
                            this.relationshipType || ""
                        }`,
                        ruleId: this.getId(),
                        element: undefined,
                        rule: this.name,
                        msg: msgText,
                    },
                ]

                break

            default:
                console.error(`Running rules. Expected 'view' or 'model' type`)
        }

        return messages
    }
}

const getRelationshipNames = (relTypes) => {
    return relTypes.map((name) => name.replace("Relationship", "")).join(", ")
}

class SourceTargetRule2 extends Rule {
    constructor(
        id,
        name,
        category,
        sourceTypes,
        targetTypes,
        relationshipTypes,
        overrideMessage,
        required
    ) {
        super(id, name, category)
        if (id === undefined) {
            console.error("Expecting rule id", { name, category })
        }
        this.sourceTypes = sourceTypes
        this.targetTypes = targetTypes
        this.relationshipTypes = relationshipTypes
        this.overrideMessage = overrideMessage
        this.required = required // Y or N

        //console.log('override message', overrideMessage)
    }

    createMessages(failedElements, isMissing) {
        const msgMissing = isMissing ? "missing" : "should not have"

        return failedElements.map((element) => {
            const msgText = this.overrideMessage
                ? `[${element.source.name}] - ${this.overrideMessage}`
                : `[${element.source.name}] - ${msgMissing} ${getRelationshipNames(
                      this.relationshipTypes
                  )} to ${this.targetTypes.join(", ")}`

            console.log("id fields", {
                id: element.source.id,
                targetTypes: this.targetTypes,
                sourceTypes: this.sourceTypes,
                relType: this.relationshipTypes,
            })
            return {
                id: `source-to-target-${element.source.id}-${this.targetTypes.join(
                    "-"
                )}-${this.sourceTypes.join("-")}-${this.relationshipTypes.join("-")}`,
                ruleId: this.getId(),
                element: element.source,
                rule: this.name,
                msg: msgText,
                scope: "view",
            }
        })
    }

    execute(modelState) {
        console.group(`%cRun rule: %c${this.name}`, "color:yellow", "color:lightgreen")

        if (this.relationshipTypes.includes(palette.ASSOCIATION_RELATIONSHIP)) {
            console.log("%cHas association relationship", "color:lightgreen")
        }

        // Find reverse association relationships, and add these into the relations below -- so that we treat Associations as bi-directional
        const reverseAssociations = modelState.model.elements
            .filter((element) => !element.source)
            .map((element) => {
                const rels = modelState.model.elements.filter(
                    (rel) =>
                        rel.target === element.id && rel.type === palette.ASSOCIATION_RELATIONSHIP
                )

                if (rels.length > 0) {
                    return {
                        source: { id: element.id, type: element.type, name: element.name },
                        connector: rels.map((rel) => {
                            const source = modelState.model.elements.find(
                                (el) => el.id === rel.source
                            )

                            return {
                                type: rel.type,
                                target: {
                                    id: rel.target,
                                    name: source.name,
                                    type: source.type,
                                },
                            }
                        }),
                    }
                }
                return undefined
            })
            .filter((item) => item)

        console.log("reverse associations", reverseAssociations)

        const elements = _.flatten(
            modelState.model.elements
                .filter((element) => !element.source)
                .map((element) => {
                    const rels = modelState.model.elements.filter(
                        (rel) => rel.source === element.id
                    )

                    return {
                        source: { id: element.id, type: element.type, name: element.name },
                        connector: rels.map((rel) => {
                            const target = modelState.model.elements.find(
                                (el) => el.id === rel.target
                            )

                            return {
                                type: rel.type,
                                target: {
                                    id: rel.target,
                                    name: target.name,
                                    type: target.type,
                                },
                            }
                        }),
                    }
                })
        )

        const allElements = [...reverseAssociations, ...elements]

        let messages = []

        const ruleScope = getRuleScope(this.sourceTypes, this.targetTypes, this.relationshipTypes)

        console.log("%crule scope", "color:lightgreen", { ruleScope, required: this.required })

        if (ruleScope === "view") {
            const elementsToCheck = allElements.filter((element) =>
                this.sourceTypes.includes(element.source.type)
            )

            if (this.required === "Y") {
                if (this.targetTypes.length > 0) {
                    const failed = elementsToCheck.filter((element) => {
                        const hasConnectorAndTarget = element.connector.find((connector) => {
                            const connectorFound = this.relationshipTypes.includes(connector.type)
                            if (connectorFound) {
                                return this.targetTypes.includes(connector.target.type)
                            }

                            return false
                        })

                        return !hasConnectorAndTarget
                    })

                    console.log("%cfailed", "color:pink", failed)

                    messages = this.createMessages(failed, true)
                } else {
                    console.log("do not check target types")

                    // Failures are where the expected connector type does not exist

                    const failed = elementsToCheck.filter((element) => {
                        const rels = element.connector.filter((rel) =>
                            this.relationshipTypes.includes(rel.type)
                        )
                        return rels.length === 0
                    })

                    console.log("failures", failed)

                    messages = this.createMessages(failed, true)
                }
            } else if (this.required === "N") {
                if (this.targetTypes.length > 0) {
                    const failed = elementsToCheck.filter((element) => {
                        const hasConnectorAndTarget = element.connector.find((connector) => {
                            const connectorFound = this.relationshipTypes.includes(connector.type)
                            if (connectorFound) {
                                return this.targetTypes.includes(connector.target.type)
                            }

                            return false
                        })

                        return hasConnectorAndTarget
                    })

                    console.log("%cfailed", "color:pink", failed)

                    messages = this.createMessages(failed, false)
                } else {
                    console.log("do not check target types")

                    // Failures are where the expected connector type does not exist

                    const failed = elementsToCheck.filter((element) => {
                        const rels = element.connector.filter((rel) =>
                            this.relationshipTypes.includes(rel.type)
                        )
                        return rels.length > 0
                    })

                    console.log("failures", failed)

                    messages = this.createMessages(failed, false)
                }
            }
        } else if (ruleScope === "model") {
            const sourceElements = modelState.model.elements.filter((element) =>
                this.sourceTypes.includes(element.type)
            )

            const failed = sourceElements.length === 0

            const msgText = this.overrideMessage || `Expecting 1 or more: ${this.sourceTypes}`

            if (failed) {
                messages = [
                    {
                        id: `${this.name}-${this.targetTypes.join("-")}-${this.sourceTypes.join(
                            "-"
                        )}-${this.relationshipTypes.join("-")}`,
                        ruleId: this.getId(),
                        element: "no-element",
                        rule: this.name,
                        msg: msgText,
                        scope: "model",
                    },
                ]
            }
        }

        // console.log("rule scope", ruleScope)

        // const sourceElements = modelState.model.elements.filter((element) =>
        //     this.sourceTypes.includes(element.type)
        // )

        // // Now check if relationships exist from source types to target type

        // const sourceRels = modelState.model.elements
        //     .filter((rel) => this.relationshipTypes.includes(rel.type))
        //     .filter((rel) =>
        //         modelState.model.elements.find(
        //             (element) =>
        //                 element.id === rel.source && this.sourceTypes.includes(element.type)
        //         )
        //     )

        // console.log("sourceRels", sourceRels)

        // const relSources = sourceRels.map((rel) => rel.source)

        // // Determine if the rule passed or failed.

        // let failed

        // switch (ruleScope) {
        //     case "view":
        //         console.log("required", { required: this.required, relSources })
        //         if (this.required === "Y") {
        //             failed = sourceElements.filter((element) => !relSources.includes(element.id))
        //             console.log("failed 1", { failed })
        //         } else {
        //             failed = sourceElements.filter((element) => relSources.includes(element.id))
        //             console.log("failed 2", { failed })
        //         }

        //         break

        //     case "model":
        //         failed = sourceElements.length === 0

        //         const msgText = this.overrideMessage || `Expecting 1 or more: ${this.sourceTypes}`

        //         if (failed) {
        //             messages = [
        //                 {
        //                     id: `${this.name}-${this.targetTypes.join("-")}-${this.sourceTypes.join(
        //                         "-"
        //                     )}-${this.relationshipTypes.join("-")}`,
        //                     ruleId: this.getId(),
        //                     element: undefined,
        //                     rule: this.name,
        //                     msg: msgText,
        //                     scope: "model",
        //                 },
        //             ]
        //         }

        //         break

        //     default:
        //         console.error(`Running rules. Expected 'view' or 'model' type`)
        // }

        console.groupEnd()

        console.log("returning messages", { messages })

        return messages
    }
}

export { SourceTargetRule2, TargetSourceRule2, getRuleScope }
