import * as actions from '../actionTypes'

const initialState = { 
    value: false
}

export default function showMoreDashboard(state = initialState, action) {

    switch(action.type) {

        case actions.SET_SHOW_MORE_DASHBOARD: {

            return {
                ...state,
                value: action.payload.value
            }
        }

        default:
            return state
    }
}