import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import Controls from "./controls/Controls"
import { useState } from "react"

const DiagramPromptDialog = ({
  open,
  onClose,
  diagramPromptMessages,
  diagramDescription,
  handleCreateStory,
}) => {
  const [promptText, setPromptText] = useState(
    diagramPromptMessages
      .filter((line) => !line.formatSpec)
      .map((line) => line.content)
      .join("\n\n")
  )

  const mergeDiagramDescription = () => {
    const vars = { diagram: diagramDescription.join("\n") }
    const messages = diagramPromptMessages
      .filter((line) => !line.formatSpec)
      .map((message) => {
        return {
          ...message,
          content: message.content.replace(
            /\$\{([^\}]+)\}/g,
            (_, match) => vars[match]
          ),
        }
      })
    setPromptText(messages.map((line) => line.content).join("\n\n"))
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Diagram Prompt</DialogTitle>
      <DialogContent sx={{ width: "400px" }}>
        <Controls.TextInput
          label="Prompt"
          value={promptText}
          onChange={(e) => setPromptText(e.target.value)}
          multiline={true}
        />
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={mergeDiagramDescription}>Merge Diagram Description</Button> */}
        <Button sx={{ textTransform: "none" }} onClick={onClose}>
          Cancel
        </Button>
        <Button
          sx={{ textTransform: "none" }}
          onClick={() => handleCreateStory(promptText)}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DiagramPromptDialog
