import * as actions from '../actionTypes'

// 
const initialState = { 
    value: 0        // 1st tab is 0
}

export default function selectedTabIndex(state = initialState, action) {

    switch(action.type) {

        case actions.SET_SELECTED_TAB_INDEX: {

            console.log('[redux] Setting tab index', { action, state} )
            return {
                ...state,
                value: action.payload.value
            }
        }

        default:
            return state
    }
}