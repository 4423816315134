import React from "react"
import FormControl from "@mui/material/FormControl"
import * as moment from "moment"
import { TextField, Typography } from "@mui/material"

export default function CreatedDate(props) {
  const { name, label, value, onChange, helperText } = props

  const helperTextId = name + "_helper_text"

  return (
    <FormControl>
      <TextField
        label={label}
        name={name}
        id={name}
        variant="outlined"
        size="small"
        value={moment(value.toDate()).format("D-MMM-yyyy hh:mm a")}
        disabled={true}
        onChange={onChange}
        aria-describedby={helperTextId}
        helperText={
          <Typography variant="caption" component={"span"}>
            {helperText}
          </Typography>
        }
      />
    </FormControl>
  )
}
