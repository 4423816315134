import { Box, List, Stack } from "@mui/material"
import Controls from "./controls/Controls"
import { useRef, useState } from "react"
import DeleteIcon from "@mui/icons-material/Delete"
import SaveIcon from "@mui/icons-material/Save"
import { useSnackbar, withSnackbar } from "notistack"
import db from "../Firestore"
import * as dataServices from "../pages/services/dataServices"
import { useEffect } from "react"
import { useHistory, withRouter } from "react-router-dom"
import { spacing } from "../pages/services/styleServices"
import YesNo from "./YesNo"
import useClaims from "./useClaims"
import useAssistants from "./useAssistants"
import {
  createOpenAIVectorStore,
  deleteOpenAIVectorStore,
  createOpenAIVectorStoreFile,
  createOpenAIFile,
  deleteOpenAIFile,
  listVectorStoreFiles,
  deleteOpenAIVectorStoreFile,
} from "../pages/services/cloudFunctions"
import { uploadFiles, deleteFile, getUrl } from "../pages/services/fileServices"
import Heading from "./controls/Heading"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import ClearIcon from "@mui/icons-material/Clear"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import DatasetIcon from "@mui/icons-material/Dataset"
import RemoveIcon from "@mui/icons-material/Remove"
import AddIcon from "@mui/icons-material/Add"
import FileItem from "./FileItem"
import { saveAs } from "file-saver"
import { merge } from "lodash"

const styles = {
  fab: {
    position: "fixed",
    bottom: 16,
    right: 16,
    top: "auto",
    left: "auto",
  },

  buttons: {
    marginTop: spacing(3),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "5px",
  },
}

const VectorStoreEditForm = (props) => {
  const [vectorStoreId, setVectorStoreId] = useState(
    props.computedMatch.params.id
  )

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const [isProcessing, setIsProcessing] = useState(false)

  const assistants = useAssistants()

  const addFileRef = useRef(null)

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Delete",
    openPrompt: false,
    description: "Delete?",

    // Callback if user clicks 'Yes' to delete a child record.
    // We set the callback and label depending on what the user is deleting
    handleConfirm: null,
  })

  const [yesNoDeleteFileConfig, setYesNoDeleteFileConfig] = useState({
    title: "Delete File?",
    description: "This delete is permanent",
    openPrompt: false,

    // this method is set when we prompt for deletion
    handleConfirm: null,
  })

  const [values, setValues] = useState({
    account_id: "",
    name: "",
    purpose: "",
    docs: [],
    tags: [],
  })

  const { claims, accountId, roles } = useClaims({ forceRefresh: false })

  useEffect(() => {
    if (vectorStoreId) {
      db.collection("vector_stores")
        .doc(vectorStoreId)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            const data = { ...values, ...doc.data() }

            if (data.vs_id) {
              const vsFiles = await listVectorStoreFiles({
                vsId: data.vs_id,
              })
              console.log("%clistVectorStoreFiles", "color:yellow", { vsFiles })

              const filesInVectorStore = vsFiles.data.response.body.data.map(
                (fileItem) => fileItem.id
              )

              // Update docs to have a 'in_vector_store' flag to indicate if the file is in the vector store

              const newDocs = data.docs.map((doc) => {
                return {
                  ...doc,
                  in_vector_store: filesInVectorStore.includes(doc.file_id),
                }
              })

              const newValues = { ...data, docs: newDocs }

              console.log("%cnewValues", "color:lightgreen", { newValues })
              setValues(newValues)
            } else {
              setValues(data)
            }
          } else {
            enqueueSnackbar("Vector Store not found", { variant: "error" })
          }
        })
        .catch((error) => {
          enqueueSnackbar("Error getting vector store", { variant: "error" })
        })
    }
  }, [vectorStoreId])

  const isNew = () => !vectorStoreId

  const handlePromptDeleteFile = async (index) => {
    const newYesNoDeleteFileConfig = {
      ...yesNoDeleteFileConfig,
      title: "Delete File?",
      description: `Delete file ${values.docs[index].name}?`,
      openPrompt: true,
      handleConfirm: () => handleDeleteFile(values.docs[index].name),
    }

    setYesNoDeleteFileConfig(newYesNoDeleteFileConfig)
  }

  const handleDownload = async (index) => {
    const doc = values.docs[index]
    const folderPath = `accounts/${accountId}/vector_stores/${vectorStoreId}/`
    const url = await getUrl(folderPath, doc.name)
    saveAs(url, doc.name)
  }

  const handleDeleteFile = async (name) => {
    console.log("handleDeleteFile", { name })
    const newDocs = values.docs.filter((doc) => doc.name !== name)

    setValues({ ...values, docs: newDocs })

    const folderPath = `accounts/${accountId}/vector_stores/${vectorStoreId}/`
    const fullPath = `${folderPath}/${name}`
    await deleteFile(fullPath)

    if (vectorStoreId) {
      db.collection("vector_stores")
        .doc(vectorStoreId)
        .update({ docs: newDocs, modified: dataServices.serverTimestamp() })
        .then(() => {
          enqueueSnackbar("Deleted", { variant: "success" })
        })
    }
  }


  const handleRemoveFileFromVectorStore = async ({ vsId, fileId }) => {
    deleteOpenAIVectorStoreFile({ vsId: vsId, fileId: fileId })
      .then((result) => {
        console.log("deleteVectorStoreFile", { result })

        // remove 'in_vector_store' flag in docs
        const newDocs = values.docs.map((doc) => {
          if (doc.file_id === fileId) {
            return { ...doc, in_vector_store: false }
          } else {
            return doc
          }
        })

        const newValues = { ...values, docs: newDocs }
        setValues(newValues)

        console.log("%cupdate docs", "color:lightgreen", {
          newDocs,
          vectorStoreId,
        })

        if (vectorStoreId) {
          db.collection("vector_stores")
            .doc(vectorStoreId)
            .update({ ...newValues, modified: dataServices.serverTimestamp() })
            .then(() => {
              enqueueSnackbar("File removed from vector store", {
                variant: "success",
              })
            })
        }
      })
      .then(() => {
        enqueueSnackbar("File added to vector store", { variant: "success" })
      })
  }

  const handleAddFileToVectorStore = async ({ vsId, fileId }) => {
    createOpenAIVectorStoreFile({ vsId: vsId, fileId: fileId })
      .then((result) => {
        console.log("createVectorStoreFile", { result })

        // 'in_vector_store' flag in docs
        const newDocs = values.docs.map((doc) => {
          if (doc.file_id === fileId) {
            return { ...doc, in_vector_store: true }
          } else {
            return doc
          }
        })

        const newValues = { ...values, docs: newDocs }
        setValues(newValues)

        console.log("%cupdate docs", "color:lightgreen", {
          newDocs,
          vectorStoreId,
        })

        if (vectorStoreId) {
          db.collection("vector_stores")
            .doc(vectorStoreId)
            .update({ ...newValues, modified: dataServices.serverTimestamp() })
            .then(() => {
              enqueueSnackbar("File added to vector store", {
                variant: "success",
              })
            })
        }
      })
      .then(() => {
        enqueueSnackbar("File added to vector store", { variant: "success" })
      })
  }

  const handleCreateVectorStore = async (event) => {
    event.preventDefault()
    createOpenAIVectorStore({ name: values.name, fileIds: [] })
      .then((result) => {
        console.log("createVectorStore", { result })
        setValues({ ...values, vs_id: result.data.response.id })

        // save the vector store id to the DB
        if (vectorStoreId) {
          db.collection("vector_stores")
            .doc(vectorStoreId)
            .update({ vs_id: result.data.response.id })
            .then(() => {
              enqueueSnackbar("Vector Store created", { variant: "success" })
            })
        }
      })
      .then(() => {
        enqueueSnackbar("Vector Store created", { variant: "success" })
      })
  }

  const handleUpload = (event) => {
    event.preventDefault()
    addFileRef.current.click()
  }

  const handleAddFile = async (event) => {
    const filePath = `accounts/${accountId}/vector_stores/${vectorStoreId}`
    const files = []
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i]
      files.push(file)
    }

    console.log("%cuploading files", "color:lightgreen", { files, filePath })
    await uploadFiles(files, filePath)

    // Merge files into the values.docs array, with each element having a name, and date uploaded. Deduplicate by name.

    const newDocs = values.docs || []

    files.forEach((file) => {
      const doc = {
        name: file.name,
      }

      newDocs.push(doc)
    })

    // Update docs in state, and also in DB

    setValues({ ...values, docs: newDocs })

    if (vectorStoreId) {
      db.collection("vector_stores")
        .doc(vectorStoreId)
        .update({ docs: newDocs, modified: dataServices.serverTimestamp() })
        .then(() => {
          enqueueSnackbar("Uploaded", { variant: "success" })
        })
    }

    addFileRef.current.value = null
  }

  const handlePromptConfirmDelete = (event) => {
    event.preventDefault()

    setYesNoConfig({
      title: "Delete Vector Store?",
      openPrompt: true,
      description: "This delete is permanent. Are you sure?",
      handleConfirm: () => handleDeletePromptConfirmed(),
    })
  }

  const hideDeletePrompt = () => {
    const newConfig = {
      ...yesNoConfig,
      openPrompt: false,
    }
    setYesNoConfig(newConfig)
  }

  const handleDeletePromptConfirmed = async () => {
    hideDeletePrompt()

    if (vectorStoreId) {
      // Delete any OpenAI vector store and files

      for (const doc of values.docs) {
        if (doc.file_id) {
          const deleteFileResult = await deleteOpenAIFile({
            fileId: doc.file_id,
          })
          console.log("%c[delete] OpenAI file", "color:yellow", {
            deleteFileResult,
          })
        }
      }

      if (values.vs_id) {
        const deleteVectorStoreResult = await deleteOpenAIVectorStore({
          vsId: values.vs_id,
        })
        console.log("%c[delete] OpenAI vector store", "color:yellow", {
          deleteVectorStoreResult,
        })
      }

      // Delete vector store
      db.collection("vector_stores")
        .doc(vectorStoreId)
        .delete()
        .then(history.push("/vectorstores"))
        .then(() => {
          enqueueSnackbar("Deleted", { variant: "success" })
        })
    }
  }

  const removeFileFromOpenAI = async (doc) => {
    if (doc.file_id) {
      deleteOpenAIFile({ fileId: doc.file_id }).then((result) => {
        console.log("delete file result", { result })

        // Update file id into docs array
        const newDocs = values.docs.map((d) => {
          if (d.name === doc.name) {
            // remove file_id attribute
            const { file_id, ...rest } = d
            return rest
          } else {
            return d
          }
        })

        setValues({ ...values, docs: newDocs })

        if (vectorStoreId) {
          db.collection("vector_stores")
            .doc(vectorStoreId)
            .update({ docs: newDocs, modified: dataServices.serverTimestamp() })
            .then(() => {
              enqueueSnackbar("Removed from OpenAI", { variant: "success" })
            })
        }
      })
    }
  }

  const uploadFileToOpenAI = async (doc) => {
    const folderPath = `accounts/${accountId}/vector_stores/${vectorStoreId}`
    const fullPath = `${folderPath}/${doc.name}`
    createOpenAIFile({ fileStoragePath: fullPath }).then((result) => {
      console.log("create file result", { result })

      const fileId = result.data.response.id

      console.log("fileId", { fileId })

      // Update file id into docs array
      const newDocs = values.docs.map((d) => {
        if (d.name === doc.name) {
          return { ...d, file_id: fileId }
        } else {
          return d
        }
      })

      setValues({ ...values, docs: newDocs })

      if (vectorStoreId) {
        db.collection("vector_stores")
          .doc(vectorStoreId)
          .update({ docs: newDocs, modified: dataServices.serverTimestamp() })
          .then(() => {
            enqueueSnackbar("Uploaded to OpenAI", { variant: "success" })
          })
      }
    })
  }

  const handleSave = async (event) => {
    event.preventDefault()

    if (values.name === "") {
      enqueueSnackbar("Enter vector store name", { variant: "warning" })
    } else {
      if (isNew()) {
        const newVectorStore = {
          ...values,
          account_id: claims.account_id,
          created: dataServices.serverTimestamp(),
          modified: dataServices.serverTimestamp(),
        }

        console.log("saving vector store", { newVectorStore })

        await db
          .collection("vector_stores")
          .add(newVectorStore)
          .then((docRef) => {
            setVectorStoreId(docRef.id)
            history.replace(`/vectorstore/${docRef.id}`)
          })
          .then(() => {
            enqueueSnackbar("Created", {
              variant: "success",
            })
          })
      } else {
        const updateRecord = {
          ...values,
          modified: dataServices.serverTimestamp(),
        }

        console.log("saving record", { vectorStoreId, updateRecord })

        db.collection("vector_stores")
          .doc(vectorStoreId)
          .update(updateRecord)
          .then(() => {
            enqueueSnackbar("Vector Store saved", {
              variant: "success",
              vertical: "bottom",
              horizontal: "right",
            })
          })
      }
    }
  }

  const getFileItemLabel = (doc) => {
    if (doc.file_id && doc.in_vector_store) {
      return "Added to Vector Store in OpenAI"
    } else if (doc.file_id) {
      return "Stored in AIM. Uploaded to OpenAI"
    } else {
      return "Stored in AIM. Not uploaded to OpenAI"
    }
  }

  return (
    <>
      <YesNo config={yesNoConfig} />

      <YesNo config={yesNoDeleteFileConfig} />

      <Box>
        <Stack
          direction="column"
          spacing={1}
          sx={{ marginTop: "20px", marginBottom: "20px", gap: 1 }}
        >
          <Controls.TextInput
            label="Vector Store Name"
            value={values.name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
            sx={{ width: "350px" }}
          />

          <Controls.TextInput
            label="Purpose"
            value={values.purpose}
            multiline={true}
            onChange={(e) => setValues({ ...values, purpose: e.target.value })}
            sx={{ maxWidth: "500px" }}
          />

          <Controls.Readonly
            label="Vector Store"
            value={values.vs_id ? "Yes" : "No"}
            sx={{ maxWidth: "100px" }}
          />

          <Stack gap={0}>
            <Heading>Docs</Heading>
            <List dense>
              {values.docs &&
                values.docs.map((doc, index) => (
                  <FileItem
                    key={doc.name}
                    fileName={doc.name}
                    fileIndex={index}
                    handlePromptDeleteFile={handlePromptDeleteFile}
                    handleDownload={handleDownload}
                    showSecondaryText={() => {
                      return getFileItemLabel(doc)
                    }}
                    extraMenuItems={[
                      {
                        icon: <UploadFileIcon />,
                        label: "Upload to OpenAI",
                        disabled: Boolean(doc.file_id),
                        onClick: () => {
                          console.log("uploading to OpenAI", { doc })
                          uploadFileToOpenAI(doc)
                        },
                      },
                      {
                        icon: <ClearIcon />,
                        label: "Remove from OpenAI",
                        disabled: Boolean(!doc.file_id),
                        onClick: () => {
                          console.log("Remove file from OpenAI", { doc })
                          removeFileFromOpenAI(doc)
                        },
                      },
                      {
                        icon: <AddIcon />,
                        label: "Add to OpenAI vector store",
                        disabled: Boolean(!values.vs_id || !doc.file_id || doc.in_vector_store),
                        onClick: () => {
                          console.log("Add to OpenAI vector store", { doc })
                          handleAddFileToVectorStore({
                            vsId: values.vs_id,
                            fileId: doc.file_id,
                          })
                        },
                      },
                      {
                        icon: <RemoveIcon />,
                        label: "Remove from OpenAI vector store",
                        disabled: Boolean(!values.vs_id || !doc.file_id || !doc.in_vector_store),
                        onClick: () => {
                          console.log("Remove from  OpenAI vector store", { doc })
                          handleRemoveFileFromVectorStore({
                            vsId: values.vs_id,
                            fileId: doc.file_id,
                          })
                        },
                      },
                    ]}
                  />
                ))}
            </List>
          </Stack>
        </Stack>

        <Box sx={styles.buttons}>
          {!isNew() && (
            <Controls.Button
              text="Delete"
              type="button"
              variant="outlined"
              tooltip="Delete this vector store"
              endIcon={<DeleteIcon />}
              onClick={handlePromptConfirmDelete}
            />
          )}

          <input
            hidden
            accept="*/*"
            sx={styles.input}
            id="icon-button-file"
            type="file"
            capture="environment"
            ref={addFileRef}
            onChange={(e) => handleAddFile(e)}
          />

          <Controls.Button
            type="button"
            text="Upload"
            variant="outlined"
            tooltip="Upload a file"
            endIcon={<CloudUploadIcon />}
            onClick={handleUpload}
            disabled={Boolean(!vectorStoreId)}
          />

          <Controls.Button
            type="button"
            text="Create Vector Store"
            variant="outlined"
            tooltip="Create vector store"
            endIcon={<DatasetIcon />}
            onClick={handleCreateVectorStore}
            disabled={Boolean(values.vs_id || isNew())}
          />

          <Controls.Button
            type="button"
            text="Save"
            variant="contained"
            tooltip="Save vector store"
            endIcon={<SaveIcon />}
            onClick={(event) => handleSave(event)}
          />
        </Box>
      </Box>
    </>
  )
}

export default withSnackbar(withRouter(VectorStoreEditForm))
