const formatAmount = (cents) => {
    return "$" + (cents / 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

const splitDocumentationIntoLines = (documentation) => {
    if (documentation && documentation.length > 0) {
        const lines = documentation[0].split(/\r?\n/) // .map(line => line.replace(/^\s*/, " "))

        return lines
    }
    return []
}

export { formatAmount, splitDocumentationIntoLines }
