import React, { useEffect, useMemo, useState } from "react"
import {
  getEmptyStripeAccountInfo,
  getStripeCustomer,
  createStripeCustomer,
  changeStripeSubscriptionCancellationStatus,
} from "../pages/services/stripeServices"
import ProgressBackdrop from "./ProgressBackdrop"
import StripeInvoice from "./StripeInvoice"
import StripeCard from "./StripeCard"
import StripeCustomer from "./StripeCustomer"
import * as dataServices from "../pages/services/dataServices"
import { useSnackbar } from "notistack"
import {
  Box,
  Paper,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material"
import StripeActions from "./StripeActions"
import Controls from "./controls/Controls"
import { Grid, Alert } from "@mui/material"
import { Form } from "./useForm"
import { useHistory } from "react-router-dom"
import db from "../Firestore"
import firebase from "firebase/compat/app"
import StripeAddCard from "./StripeAddCard"
import * as stripeServices from "../pages/services/stripeServices"
import { Elements } from "@stripe/react-stripe-js"
import TabPanel, { a11yProps } from "./TabPanel"
import _, { update } from "lodash"
import * as cloudFunctions from "../pages/services/cloudFunctions"
import { spacing } from "../pages/services/styleServices"
import StripeNextInvoice from "./StripeNextInvoice"
import StripeSubscriptionQuantities from "./StripeSubscriptionQuantities"
import * as Roles from "../pages/services/roleServices"
import {
  getUserCountTotal,
  getUserCountWithAIMAIRole,
} from "../pages/services/userServices"

const styles = {
  headerFields: {
    margin: spacing(1),
  },
  pageContent: {
    marginTop: spacing(1),
    marginLeft: spacing(1),
    marginRight: spacing(2),
    padding: spacing(1),
    maxWidth: "320px",
  },
  accountTab: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    "& > *": {
      minWidth: 330,
    },
  },
  tabLabel: {
    textTransform: "none",
  },
  productName: {
    fontWeight: 700,
    marginBottom: spacing(1),
  },
}

const BillingForm = (props) => {
  const { setTitle } = props

  const [isShowProgress, setShowProgress] = useState(true)

  const [stripeAccountInfo, setStripeAccountInfo] = useState(
    getEmptyStripeAccountInfo()
  )

  // How many users have the AIM Pro role?
  const [userCountWithAimAIRole, setUserCountWithAimAIRole] = useState(0)

  // How many AIM AI seats are saved into the Stripe subscription?
  const [aimAiRoleSeatsInStripe, setAimAiRoleSeatsInStripe] = useState(0)

  const [userCountTotal, setUserCountTotal] = useState(0)

  //const [retrievedBilling, setRetrievedBilling] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const [maxModified, setMaxModified] = useState()

  const [accountId, setAccountId] = useState(props.accountId)

  const [userId, setUserId] = useState()

  const [tabIndex, setTabIndex] = useState(0)

  const isSubscriptionCreatedAndActive = useMemo(() => {
    const sub = stripeAccountInfo?.customer?.subscriptions?.find(
      (sub) => sub.status === "active"
    )

    return sub || undefined
  }, [stripeAccountInfo])

  const isCardDefined = useMemo(() => {
    return stripeAccountInfo?.cards?.length > 0 || undefined
  }, [stripeAccountInfo])

  const isDefaultCardDefined = useMemo(() => {
    return (
      stripeAccountInfo?.customer?.invoice_settings?.default_payment_method !==
      null
    )
  }, [stripeAccountInfo])

  const BILLING_SETUP_COMPLETE_MSG = {
    msg: "Billing setup complete - Subscription and Payment Method setup complete",
    severity: "success",
  }

  const cardsMessage = useMemo(() => {
    if (isSubscriptionCreatedAndActive && isDefaultCardDefined) {
      return BILLING_SETUP_COMPLETE_MSG
    }

    if (!isDefaultCardDefined) {
      return {
        msg: "Make sure you have a default Payment Method set.",
        severity: "info",
      }
    }

    // if (!isSubscriptionCreated) {
    //     return { msg: "Create a subscription", severity: "info" }
    // }
  }, [isSubscriptionCreatedAndActive, isDefaultCardDefined])

  const isBillingSetup = useMemo(() => {
    if (
      isSubscriptionCreatedAndActive !== undefined &&
      isCardDefined !== undefined
    ) {
      return isSubscriptionCreatedAndActive && isCardDefined
    }
    return undefined
  }, [isSubscriptionCreatedAndActive, isCardDefined])

  const isUpcomingInvoiceForTrial = useMemo(() => {
    if (!stripeAccountInfo) {
      return false
    }

    if (!stripeAccountInfo.invoice) {
      return false
    }

    const { invoice } = stripeAccountInfo

    const found = stripeAccountInfo.customer.subscriptions.find(
      (sub) =>
        sub.current_period_start === invoice.period_start &&
        sub.current_period_end === invoice.period_end
    )

    console.log(
      "found trial subscription with same period as upcoming invoice",
      { found }
    )

    return found !== undefined
  }, [stripeAccountInfo])

  const stripePromise = stripeServices.getStripePromise()

  const [stripeProducts, setStripeProducts] = useState()

  const [stripeInvoices, setStripeInvoices] = useState()

  const [stripeTaxRates, setStripeTaxRates] = useState()

  const [stripePrices, setStripePrices] = useState()

  const [plan, setPlan] = useState("")

  // This gets populated securely from the Google JWT, and also protected access server-side
  const [isSystemRole, setSystemRole] = useState(undefined)

  const [values, setValues] = useState({
    name: "",
    email: "",
    stripe_cust_id: "",
    country: "",
    has_subscription: false,
    stripe_status: "",
  })

  // Set how many AIM AI seats are saved into the Stripe subscription, i.e. separate to any unsaved editing of subscription quantities
  useEffect(() => {
    if (
      stripeAccountInfo?.customer?.subscriptions?.length > 0 &&
      stripeProducts
    ) {
      // Get 'AIM AI' product
      const aimAiProduct = stripeProducts.find(
        (product) => product.name === "AIM AI"
      )
      console.log("aimAiProduct", aimAiProduct)

      // Get subscription items for 'AIM AI' product. Should only be 1.
      const aimAiSubscriptionItems =
        stripeAccountInfo.customer.subscriptions[0].items.data.filter(
          (item) => item.price.product === aimAiProduct.id
        )

      const aimAiQty = aimAiSubscriptionItems.reduce(
        (acc, item) => acc + item.quantity,
        0
      )

      setAimAiRoleSeatsInStripe(aimAiQty)
    }
  }, [stripeAccountInfo, stripeProducts])

  const updateUserCountInfo = async (accountId) => {
    getUserCountWithAIMAIRole(accountId).then((result) => {
      setUserCountWithAimAIRole(result)
    })

    getUserCountTotal(accountId).then((result) => {
      setUserCountTotal(result)
    })
  }

  useEffect(() => {
    if (accountId) {
      updateUserCountInfo(accountId)
    }
  }, [accountId])

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult(true).then((token) => {
          console.log("token", {
            accountId: token.claims.account_id,
            system: token.claims.system_role,
            props_account_id: props.accountId,
          })
          if (token.claims.system_role) {
            // account id passed through for system users, so you can open this page to view
            // billing info for any account, but otherwise use the account id from the token
            // which is useful for testing
            setAccountId(props.accountId || token.claims.account_id)
            setUserId(token.claims.user_id)
          } else {
            setSystemRole(token.claims.system_role)
            setPlan(token.claims.plan)
            setAccountId(token.claims.account_id)
            setUserId(token.claims.user_id)
            console.log("set account id", {
              accountId: token.claims.account_id,
            })
          }
        })
      }
    })

    return unsub
  }, [])

  useEffect(() => {
    console.log("%cIs Billing Set Up?", "color:orange", {
      isBillingSetup,
      accountId,
    })

    if (accountId && isBillingSetup) {
      console.log("Update account", { accountId, isBillingSetup })
      db.collection("accounts")
        .doc(accountId)
        .update({ billing_setup: isBillingSetup }, { merge: true })

      console.log("%cUpdate billing setup", "color:orange", { isBillingSetup })
      // Now refresh the user claims, which will unlock the ability to create new projects, components, etc.

      cloudFunctions.obtainCustomClaims().then((claimsResult) => {
        console.log("%crefreshed claims", "color:pink", claimsResult)

        // Refresh the token now (using the 'true' param), so that in useAccountStatus hook we don't have to refresh the token
        firebase
          .auth()
          ?.currentUser?.getIdTokenResult(true)
          .then((token) => {
            console.log("%crefreshed claims, got token", "color:pink", token)
          })
      })
    }
  }, [isBillingSetup, accountId])

  useEffect(() => {
    stripeServices.listStripeProducts().then((result) => {
      console.log("%cStripe products", "color:lightgreen", result)

      if (cloudFunctions.isError(result)) {
        enqueueSnackbar(cloudFunctions.getMessage(result), { variant: "error" })
        setShowProgress(false)
      } else {
        const products = result.data.filter((product) =>
          stripeServices.AIM_PRODUCT_LIST.includes(product.name)
        )

        setStripeProducts(products)
        loadPrices(products).then(() => {
          setShowProgress(false)
        })
      }
    })
  }, [])

  const loadPrices = async (products) => {
    const prices = products.map(async (product) => {
      console.log("%cget price for product", "color: lightgreen", {
        product: product.id,
      })

      const result = await stripeServices.getStripeProductPrices(product.id)

      if (cloudFunctions.isError(result)) {
        enqueueSnackbar(cloudFunctions.getMessage(result), { variant: "error" })
      }
      return result
    })
    Promise.all(prices).then((allPrices) => {
      console.log("prices", allPrices)
      setStripePrices(_.flatten(allPrices.map((item) => item.data)))
    })
  }

  const addAIMAIRoleToCurrentUser = async ({ accountId }) => {
    // Now check if there is a spare AIM AI seat, and if so, assign it to the current user to simplify their setup process

    console.log("Adding AIM AI role to user", { accountId, userId })

    await db
      .collection("users")
      .doc(userId)
      .update({
        roles: firebase.firestore.FieldValue.arrayUnion(Roles.AIM_AI),
      })

    // Refresh claims to update roles in the claims

    cloudFunctions.obtainCustomClaims().then((claimsResult) => {
      console.log("%crefreshed claims", "color:pink", claimsResult)

      // Get current firebase user

      const user = firebase.auth().currentUser

      if (user) {
        user.getIdTokenResult(true).then((token) => {
          console.log("token", token)

          enqueueSnackbar("Added AIM AI. You can start using Generative AI!", {
            variant: "success",
          })
        })
      }
    })

    console.log("Added AIM AI role to user")
  }

  const handleCreateSubscription = async ({ accountId, quantities }) => {
    // Check if there is a corresponding stripe customer

    console.log("creating stripe subscription")

    setShowProgress(true)

    // Check if Stripe customer needs to be created.

    console.log("check if customer has stripe account", { accountId })

    const accountDoc = await db.collection("accounts").doc(accountId).get()
    const account = accountDoc.data()
    if (account.stripe_cust_id === undefined) {
      console.log("creating stripe customer")
      const createResult = await stripeServices.createStripeCustomer(accountId)
      console.log("created stripe customer", createResult)
    }

    console.log("getting stripe customer")

    const stripeCustResult = await getStripeCustomer(accountId)

    console.log("stripeCust", {
      stripeCustResult,
      customer: stripeCustResult.customer,
    })

    if (!stripeCustResult.customer) {
      console.log("No Stripe Customer")
      // Create stripe customer

      const createResult = await stripeServices.createStripeCustomer(accountId)
      console.log("created stripe customer", createResult)
    }

    console.log("%ccreate subscription", "color: lightblue", {
      quantities,
      accountId,
    })

    const result = await stripeServices.createStripeSubscription(
      quantities,
      accountId
    )

    console.log("%ccreate subscription result", "color: lightblue", result)

    await addAIMAIRoleToCurrentUser({ accountId })

    updateUserCountInfo(accountId)

    // Refresh subscription data
    await loadAccountData(accountId)

    enqueueSnackbar(result.data.status.message, {
      variant: result.data.status.type,
    })

    setShowProgress(false)
  }

  const loadInvoices = async (stripeCustId) => {
    stripeServices.getStripeTaxRates().then((result) => {
      setStripeTaxRates(result)
    })

    stripeServices.getStripeInvoices(stripeCustId).then((result) => {
      setStripeInvoices(result.data.data)
    })
  }

  useEffect(() => {
    if (values.stripe_cust_id) {
      loadInvoices(values.stripe_cust_id)
    }
  }, [values])

  const adjustAIMAIUsers = async ({ accountId, stripeAccountInfo }) => {
    const userCountWithAimAIRole = await getUserCountWithAIMAIRole(accountId)
    console.log(
      "%cuserCountWithAimAIRole",
      "color:pink",
      userCountWithAimAIRole
    )

    const hasDefaultCard =
      stripeAccountInfo?.cards?.find((card) => card.is_default) !== undefined

    const hasSubscription =
      stripeAccountInfo?.customer?.subscriptions?.length > 0

    console.log("%cAdjusting AIM AI users", "color:yellow", {
      userCountWithAimAIRole,
      hasDefaultCard,
      hasSubscription,
    })

    if (!hasDefaultCard || !hasSubscription) {
      // Remove AIM AI role from users
      console.log("Removing AIM AI role from users")
      db.collection("users")
        .where("account_id", "==", accountId)
        .where("roles", "array-contains", Roles.AIM_AI)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            console.log("Removing AIM AI role from all users", doc.id)
            db.collection("users")
              .doc(doc.id)
              .update({
                roles: firebase.firestore.FieldValue.arrayRemove(Roles.AIM_AI),
              })
          })
        })
    }
  }

  const loadAccountData = async (accountId) => {
    console.log("%cloading account data...", "color:yellow", { accountId })
    let newValues = {
      ...values,
      has_subscription: undefined,

      // Do account and Stripe customer emails match?
      emails_match: undefined,
    }

    // Clear out the stripe_cust_id. If one exists, it will get re-loaded
    // in the following lines. Clearing the stripe_cust_id attribute
    // is needed when we use the 'Repair' action, to unlink a Simplify
    // account from a now-deleted Stripe customer.

    if (newValues.hasOwnProperty("stripe_cust_id")) {
      newValues.stripe_cust_id = ""
    }

    console.log("Retrieving account", accountId)
    const account = await dataServices.getAccountById(accountId)

    console.log("loaded account", { account, newValues })

    newValues = {
      ...newValues,
      ...account,
    }

    setTitle("Account: " + account.name)

    const stripeInfo = await getStripeCustomer(accountId)
    console.log("%cRetrieved stripe customer", "color:yellow", stripeInfo)

    // DEBUG CODE - for if we need to recreate a customer record that was deleted, perhaps during testing in Stripe test mode
    // if (stripeInfo.customer.deleted) {
    //   const result = await createStripeCustomer(accountId)
    //   console.log("%ccreated stripe customer", "color:orange", result)
    // }

    // if (stripeInfo.status) {
    //   enqueueSnackbar(`${stripeInfo.status.message}!!`, {
    //     variant: stripeInfo.status.type,
    //   })
    // }
    console.log("%cstripe info result", "color:lightGreen", { stripeInfo })

    adjustAIMAIUsers({ accountId, stripeAccountInfo: stripeInfo })

    const isStripeCustomerDeleted =
      stripeInfo.customer &&
      stripeInfo.customer.hasOwnProperty("deleted") &&
      stripeInfo.customer.deleted === true

    // if (result.customer.invoice_settings.default_payment_method === null) {
    //     console.log("Customer does not have a default payment method set up.")
    // } else {
    //     console.log("Customer has a default payment method set up.")
    // }
    console.log("%ccustomer stripe info", "color:yellow", stripeInfo.customer)

    const stripeStatus = isStripeCustomerDeleted
      ? "Deleted"
      : stripeInfo.customer
      ? "Customer"
      : "No billing account"

    newValues.stripe_status = stripeStatus

    if (stripeInfo.customer && !isStripeCustomerDeleted) {
      console.log(
        "%cloaded stripe account info",
        "color:lightgreen",
        stripeInfo
      )
      setStripeAccountInfo(stripeInfo)

      newValues = {
        ...newValues,
        has_subscription: stripeInfo.customer.subscriptions.length > 0,
        emails_match: account.email === stripeInfo.customer.email,
      }
    } else {
      newValues = {
        ...newValues,
        has_subscription: false,
        emails_match: false,
      }
    }

    // if (stripeInfo.status) {
    //   enqueueSnackbar(stripeInfo.status.message, {
    //     variant: stripeInfo.status.type,
    //   })
    // }

    console.log("account values", newValues)
    setValues(newValues)
  }

  const handleUpdateCard = (updatedCard) => {
    const newCards = stripeAccountInfo.cards.map((card) =>
      card.id === updatedCard.id ? updatedCard : card
    )

    const newStripeAccountInfo = {
      ...stripeAccountInfo,
      cards: newCards,
    }
    setStripeAccountInfo(newStripeAccountInfo)
  }

  useEffect(() => {
    console.log("useEffect:loadAccountData", { accountId, maxModified })
    if (accountId && maxModified) {
      setShowProgress(true)
      console.log("loading account data", { accountId, maxModified })
      loadAccountData(accountId).then(() => {
        setShowProgress(false)
      })
    }
  }, [accountId, maxModified])

  // Listen for changes, so we can reload

  useEffect(() => {
    if (accountId) {
      const query = db
        .collection("accounts")
        .where(firebase.firestore.FieldPath.documentId(), "==", accountId)

      const unsub = query.onSnapshot(
        (querySnapshot) => {
          let newMaxModified = null
          querySnapshot.docChanges().forEach((change) => {
            console.log("changed", change)

            if (
              newMaxModified === null ||
              change.doc.data().modified.seconds > newMaxModified.seconds
            ) {
              // trigger refresh
              newMaxModified = change.doc.data().modified
            }
          })
          if (newMaxModified !== null) {
            setMaxModified(newMaxModified)
          }
        },
        (error) => {
          console.log("error", error)
          enqueueSnackbar(error.message, { variant: "error" })
          setShowProgress(false)
        }
      )
      return unsub
    }
  }, [accountId])

  const tabLabel = (label) => {
    return <Typography sx={styles.tabLabel}>{label}</Typography>
  }

  const subscriptionCount = useMemo(() => {
    console.log("subscription count", { stripeAccountInfo })
    return stripeAccountInfo?.customer?.subscriptions?.length || 0
  }, [stripeAccountInfo])

  const ACCOUNT_TAB = 0
  const SUBSCRIPTION_TAB = 1
  const CARD_TAB = 2
  const INVOICES_TAB = 3
  const ACTION_TAB = 4

  const handleCancelSubscription = async (subscriptionId) => {
    console.log("%ccancelling subscription", "color:yellow", { subscriptionId })

    setShowProgress(true)

    const result = await changeStripeSubscriptionCancellationStatus(
      subscriptionId,
      true
    )

    console.log("%ccancelled subscription", "color:yellow", { result })

    // Refresh subscription data
    loadAccountData(accountId)

    setShowProgress(false)
  }

  const handleRestartSubscription = async (subscriptionId) => {
    console.log("%crestarting subscription", "color:yellow", { subscriptionId })

    if (!isDefaultCardDefined) {
      enqueueSnackbar("Add a default Payment Method", { variant: "info" })
      return
    }

    setShowProgress(true)

    const result = await changeStripeSubscriptionCancellationStatus(
      subscriptionId,
      false
    )

    console.log("%crestarted subscription", "color:yellow", { result })

    // Refresh subscription data
    loadAccountData(accountId)

    setShowProgress(false)
  }

  const handleUpdateSubscription = async (subscriptionId, quantities) => {
    console.log("%cupdating subscription", "color:yellow", {
      subscriptionId,
      quantities,
    })

    setShowProgress(true)

    const result = await stripeServices.updateStripeSubscriptions(
      subscriptionId,
      quantities
    )

    console.log("%cupdated subscription", "color:yellow", {
      result,
      quantities,
    })

    // Refresh subscription data
    loadAccountData(accountId)

    setShowProgress(false)
  }

  return (
    <>
      <ProgressBackdrop
        open={isShowProgress}
        label={"Accessing Subscription..."}
      />

      {/* {showPlanChangeDialog && (
                <ChangePlanDialog
                    open={showPlanChangeDialog}
                    setOpen={setShowPlanChangeDialog}
                    numCards={stripeAccountInfo?.cards?.length || 0}
                    handleUpgradePlan={handleUpgradePlan}
                />
            )} */}

      <Tabs
        value={tabIndex}
        onChange={(event, newIndex) => setTabIndex(newIndex)}
        aria-label="Account management"
        sx={styles.tabs}
      >
        <Tab label={tabLabel("Account")} {...a11yProps(ACCOUNT_TAB)} />

        {/* <Tab label={tabLabel("Products")} {...a11yProps(PRODUCTS_TAB)} /> */}

        <Tab
          label={tabLabel(`Subscription (${subscriptionCount})`)}
          {...a11yProps(SUBSCRIPTION_TAB)}
        />

        <Tab
          label={tabLabel(
            `Payment Methods (${stripeAccountInfo?.cards?.length || 0})`
          )}
          {...a11yProps(CARD_TAB)}
        />

        <Tab label={tabLabel(`Invoices`)} {...a11yProps(INVOICES_TAB)} />
        {isSystemRole && (
          <Tab label={tabLabel("Actions")} {...a11yProps(ACTION_TAB)} />
        )}
      </Tabs>

      <TabPanel value={tabIndex} index={ACCOUNT_TAB}>
        {!isSubscriptionCreatedAndActive &&
          isSubscriptionCreatedAndActive !== undefined && (
            <Alert severity="info">
              Select the Subscriptions tab and create a subscription
            </Alert>
          )}
        {!isCardDefined && isCardDefined !== undefined && (
          <Alert severity="warning">
            No cards found. Enter a payment card on the Cards tab
          </Alert>
        )}
        {isSubscriptionCreatedAndActive && isCardDefined && (
          <Alert severity="success">{BILLING_SETUP_COMPLETE_MSG.msg}</Alert>
        )}
        <Box sx={styles.accountTab}>
          <Paper sx={styles.pageContent}>
            <Form>
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    variant="h6"
                    component={"span"}
                    gutterBottom={true}
                    paragraph={true}
                  >
                    Account Details
                  </Typography>
                </Grid>
                <Grid container direction="column">
                  <Grid item>
                    <Controls.Readonly
                      name="name"
                      label="Account Name"
                      value={values.name}
                    />
                  </Grid>
                  <Grid item>
                    <Controls.Readonly
                      name="email"
                      label="Account Email"
                      value={values.email}
                    />
                  </Grid>

                  <Grid item>
                    <Controls.Readonly
                      name="email"
                      label="Account Country"
                      value={values.country}
                    />
                  </Grid>

                  <Grid item>
                    <Controls.Readonly
                      name="has_stripe_customer"
                      label="Subscription Status"
                      value={values.stripe_status}
                    />
                  </Grid>

                  {isSystemRole && values.stripe_cust_id && (
                    <Grid item>
                      <Controls.Readonly
                        name="stripe_cust_id"
                        label="Subscription Customer Id"
                        value={values.stripe_cust_id}
                      />
                    </Grid>
                  )}

                  <Grid item>
                    <Controls.Readonly
                      name="has_stripe_subscription"
                      label="Subscription Active?"
                      value={
                        values.has_subscription === undefined
                          ? "..."
                          : values.has_subscription
                          ? "Yes"
                          : "No"
                      }
                    />
                  </Grid>

                  {isSystemRole && values.stripe_cust_id && (
                    <Grid item>
                      <Controls.Readonly
                        name="emails_match"
                        label="Emails match?"
                        value={
                          values.stripe_status === "Deleted"
                            ? "N/A"
                            : values.emails_match === undefined
                            ? "..."
                            : values.emails_match
                            ? "Yes"
                            : "Different emails across User and Billing Accounts"
                        }
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Form>
          </Paper>

          {stripeAccountInfo && (
            <StripeCustomer customer={stripeAccountInfo.customer} />
          )}
        </Box>
      </TabPanel>

      <TabPanel value={tabIndex} index={INVOICES_TAB}>
        {tabIndex === INVOICES_TAB && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            {stripeInvoices &&
              stripeTaxRates &&
              stripeInvoices.map((invoice) => (
                <Box key={invoice.id}>
                  <StripeInvoice invoice={invoice} taxRates={stripeTaxRates} />
                  {/* <pre>{JSON.stringify(invoice, null, 2)}</pre>
                                    <pre>tax rates: {JSON.stringify(stripeTaxRates, null, 2)}</pre> */}
                </Box>
              ))}
          </Box>
        )}
      </TabPanel>

      {/* <TabPanel value={tabIndex} index={PRODUCTS_TAB}>
                {tabIndex === PRODUCTS_TAB && (
                    <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: 1 }}>
                        {stripeProducts.map((product) => (
                            <Card
                                key={product.id}
                                sx={{ margin: "2px", padding: "2px", maxWidth: "300px" }}
                            >
                                <CardHeader
                                    sx={{ padding: "2px", margin: "5px" }}
                                    title={
                                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                            Product: {product.name}
                                        </Typography>
                                    }
                                />
                                <CardContent sx={{ margin: "5px", padding: 0 }}>
                                    {product.description}
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                )}
            </TabPanel> */}

      <TabPanel value={tabIndex} index={SUBSCRIPTION_TAB}>
        {isSubscriptionCreatedAndActive && isCardDefined && (
          <Alert severity="success">{BILLING_SETUP_COMPLETE_MSG.msg}</Alert>
        )}

        <SubscriptionTab
          accountId={accountId}
          userCountTotal={userCountTotal}
          userCountWithAimAIRole={userCountWithAimAIRole}
          aimAiRoleSeatsInStripe={aimAiRoleSeatsInStripe}
          setShowProgress={setShowProgress}
          stripeProducts={stripeProducts}
          stripePrices={stripePrices}
          stripeAccountInfo={stripeAccountInfo}
          handleCreateSubscription={handleCreateSubscription}
          handleCancelSubscription={handleCancelSubscription}
          handleRestartSubscription={handleRestartSubscription}
          handleUpdateSubscription={handleUpdateSubscription}
          isUpcomingInvoiceForTrial={isUpcomingInvoiceForTrial}
          stripeTaxRates={stripeTaxRates}
          isCardDefined={isCardDefined}
        />
      </TabPanel>

      <TabPanel value={tabIndex} index={CARD_TAB}>
        {cardsMessage && (
          <Alert severity={cardsMessage.severity}>{cardsMessage.msg}</Alert>
        )}
        {stripeAccountInfo && (
          <Box>
            {stripeAccountInfo.cards.map((card) => (
              <StripeCard
                card={card}
                custId={values.stripe_cust_id}
                key={card.id}
                updateCard={handleUpdateCard}
                reload={async () => await loadAccountData(accountId)}
                setShowProgress={setShowProgress}
              />
            ))}
          </Box>
        )}
        <Elements stripe={stripePromise}>
          <StripeAddCard
            stripeCustId={values.stripe_cust_id}
            accountId={accountId}
            setShowProgress={setShowProgress}
            reload={() => loadAccountData(accountId)}
          />
        </Elements>
        <Box sx={{ marginTop: "30px", marginLeft: "20px", width: "400px" }}>
          <Stack gap={1}>
            <Typography sx={{ fontWeight: "bold" }}>
              We value the protection and privacy of your personal and credit
              card details
            </Typography>
            <Typography variant="caption">
              All credit card handling is performed by Stripe. This includes
              user-interface capture of credit card information, and secure
              transmission and storage of credit card details in the Stripe
              platform. Stripe processes monthly AIM subscriptions.
            </Typography>
            <Typography variant="caption">
              Please contact support@architectureinmotion.com.au for any queries
              or assistance for any subscription related activities.
            </Typography>
            <Box sx={{ marginTop: "10px" }}>
              <img src={`/stripe-logo.svg`} alt="stripe logo" width="150px" />
            </Box>
          </Stack>
        </Box>
      </TabPanel>

      <TabPanel value={tabIndex} index={ACTION_TAB}>
        {isSystemRole && (
          <StripeActions
            accountId={accountId}
            accountInfo={values}
            setShowProgress={setShowProgress}
          />
        )}
      </TabPanel>
    </>
  )
}

const userCountStyles = {
  label: {
    width: "200px",
  },
}
const UserCountSummary = ({
  userCountWithAimAIRole,
  aimAiRoleSeatsInStripe,
}) => {
  const history = useHistory()

  return (
    <Paper
      sx={{
        marginTop: spacing(1),
        marginLeft: spacing(0),
        marginRight: spacing(1),
        padding: spacing(1),
      }}
    >
      <Stack direction="column" gap={2}>
        <Stack direction="column" gap={1}>
          <Typography
            variant="h6"
            component={"span"}
            gutterBottom={false}
            paragraph={false}
          >
            Users
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            <Box sx={userCountStyles.label}>Users with AIM AI role</Box>
            <Box>{userCountWithAimAIRole}</Box>
            <Tooltip title="Users with the 'AIM AI' role can access AIM AI features">
              <span>
                <Controls.Button
                  text="Open Users"
                  onClick={() => history.push("/users")}
                />
              </span>
            </Tooltip>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
            <Box sx={userCountStyles.label}>AIM AI subscription seats</Box>
            <Box>{aimAiRoleSeatsInStripe}</Box>
            {aimAiRoleSeatsInStripe > userCountWithAimAIRole && (
              <Alert severity="info" sx={{ maxWidth: "500px" }}>
                You can go into the Users in AIM (under Config on left) to
                allocate AIM AI seats to your users. You can allocate{" "}
                {aimAiRoleSeatsInStripe - userCountWithAimAIRole} more seats.
              </Alert>
            )}
          </Box>
        </Stack>
        <Typography variant="caption">
          All users require a license, which can either be an AIM Pro license or
          AIM AI license. The 'AIM AI' role is assigned to users in the Users
          menu. A user without the 'AIM AI' role is considered an 'AIM Pro'
          user. New users can be sent an invite to join via the Invites menu,
          and then need to login and accept your invite to join this account.
        </Typography>
      </Stack>
    </Paper>
  )
}

const SubscriptionTab = ({
  accountId,
  userCountTotal,
  userCountWithAimAIRole,
  aimAiRoleSeatsInStripe,
  setShowProgress,
  stripeProducts,
  stripePrices,
  stripeAccountInfo,
  handleCreateSubscription,
  handleCancelSubscription,
  handleRestartSubscription,
  handleUpdateSubscription,
  isUpcomingInvoiceForTrial,
  stripeTaxRates,
  isCardDefined,
}) => {
  return (
    <Stack gap={2}>
      <StripeSubscriptionQuantities
        accountId={accountId}
        userCountTotal={userCountTotal}
        userCountWithAimAIRole={userCountWithAimAIRole}
        aimAiRoleSeatsInStripe={aimAiRoleSeatsInStripe}
        products={stripeProducts}
        prices={stripePrices}
        stripeAccountInfo={stripeAccountInfo}
        handleCreateSubscription={handleCreateSubscription}
        handleCancelSubscription={handleCancelSubscription}
        handleRestartSubscription={handleRestartSubscription}
        handleUpdateSubscription={handleUpdateSubscription}
        isCardDefined={isCardDefined}
      />
      {stripeAccountInfo?.customer?.subscriptions?.length > 0 && (
        <UserCountSummary
          userCountWithAimAIRole={userCountWithAimAIRole}
          aimAiRoleSeatsInStripe={aimAiRoleSeatsInStripe}
        />
      )}

      <Box style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {stripeAccountInfo?.customer?.subscriptions?.map((sub) => (
          <Stack direction="column" gap={2} key={sub.id}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              {stripeAccountInfo && stripeAccountInfo?.invoice && (
                <StripeNextInvoice
                  invoice={stripeAccountInfo?.invoice}
                  taxRates={stripeTaxRates}
                />
              )}
            </Box>
          </Stack>
        ))}

        {/* {stripeAccountInfo?.invoice && !isUpcomingInvoiceForTrial && (
                    <StripeInvoice invoice={stripeAccountInfo?.invoice} />
                )} */}
      </Box>
    </Stack>
  )
}

export default BillingForm
