import * as actions from "../actionTypes"

const initialState = {
    value: {
        selectedTags: [], // tags, { type, label }
        selectedRuleIds: [], // rule ids
        mlRules: false, // show ml rules
    },
}

export default function selectedRules(state = initialState, action) {
    switch (action.type) {
        case actions.SET_SELECTED_RULES: {
            //console.log("[redux] Setting selected rules", action.payload.value)
            return {
                ...state,
                value: action.payload.value,
            }
        }

        default:
            return state
    }
}
