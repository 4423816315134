import React, { useState } from "react"
import * as common from "./common"
import { green } from "@mui/material/colors"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const CommunicationNetwork = (props) => {
    const { x, y, height, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = green[200]
    const defaultColor = fillColor || green[100]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <rect
                x={x + 1}
                y={y + 1}
                fill={fill}
                stroke={common.outlineColor}
                width={width - 1}
                height={height - 1}
            />

            <svg x={x + width - 24} y={y}>
                <line x1={8} y1={7} x2={16} y2={7} stroke={common.outlineColor} />
                <line x1={6} y1={15} x2={14} y2={15} stroke={common.outlineColor} />

                <line x1={8} y1={7} x2={6} y2={15} stroke={common.outlineColor} />
                <line x1={16} y1={7} x2={14} y2={15} stroke={common.outlineColor} />

                <circle cx={8} cy={7} r={2} fill={fill} stroke={common.outlineColor} />
                <circle cx={6} cy={15} r={2} fill={fill} stroke={common.outlineColor} />
                <circle cx={16} cy={7} r={2} fill={fill} stroke={common.outlineColor} />
                <circle cx={14} cy={15} r={2} fill={fill} stroke={common.outlineColor} />
            </svg>

            <ElementText {...props} label={label} />
        </Svg>
    )
}

export default CommunicationNetwork
