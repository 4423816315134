import * as actions from "../actionTypes"

const initialState = {
    items: [],
}

export default function componentModelIndex(state = initialState, action) {
    switch (action.type) {
        case actions.SET_COMPONENT_MODEL_INDEX: {
            const newState = action.payload.value
            
            //console.log("%c[redux] Setting modelIndex", "color:lightgreen", { action, newState })

            return newState
        }

        default:
            return state
    }
}
