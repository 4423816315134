import {
  GPT_4o_LATEST,
  addMessages,
  convertToMessages,
  runAssistant,
  retrieveRunForStatus,
  GPT_4o_MINI_LATEST,
  GPT_o1_MINI_LATEST,
  CAN_USE_O1,
  getAIUsageParam,
  FUNCTION_CREATE_TEMPLATE,
  getModel,
} from "./chatGenerationServices"
import {
  createChatCompletion,
  createThread,
  deleteThread,
  listMessages,
} from "./cloudFunctions"

const createTemplate = async ({ name, purpose, assistants, roles }) => {
  const assistant = assistants.find((a) => a.name === "AIM Template Builder")

  const createTemplateFunction = assistant.tools.find(
    (t) => t.type === "function" && t.function.name === "create_template_flat"
  )

  const gptModel = getModel({
    roles: roles,
    funcName: FUNCTION_CREATE_TEMPLATE,
  })

  // Model for doing initial research for template creation
  const prepModel = getAIUsageParam({ roles, paramName: CAN_USE_O1 })
    ? GPT_o1_MINI_LATEST
    : GPT_4o_MINI_LATEST

  const templateBasicsResult = await createChatCompletion({
    messages: [
      {
        role: "user",
        content: `Create a ${name} multi level table of contents for ${purpose}.`,
      },
      {
        role: "user",
        content:
          "You are a helpful AI assistant that is an expert in creating templates for the purpose of: " +
          purpose,
      },
      {
        role: "user",
        content:
          "Think about the templates that people normally use when they are creating documents for this purpose. What are the key sections that are normally included?",
      },
      { role: "user", content: "Think about the purpose of each section?" },
      { role: "user", content: "Think about the best ordering of sections?" },
      {
        role: "user",
        content:
          "Think about how an expert would go about achieving the overall purpose, and then what type of document and what table of contents (TOC) the document would have to hold all the information they would need.",
      },
      {
        role: "user",
        content:
          "What are the key points that are normally included in each section?",
      },
      {
        role: "user",
        content:
          "You do NOT need to include any sections like title, subtitle, authors, date created, target audience, etc. These are standard sections that are normally included in any document.",
      },
      {
        role: "user",
        content: "Just provide the TOC only, and no other content",
      },
    ],
    model: prepModel,
  })

  console.log("%ctemplate basics result", "color:lightgreen", {
    templateBasicsResult,
  })

  const threadResult = await createThread({
    messages: [],
  })

  const threadId = threadResult.data?.response?.id

  // const initialMessages = [
  //   `You are a helpful AI assistant that is an expert in creating '${name}' templates for the purpose of: ${purpose}`,
  //   `Think about the templates that people normally use when they are creating documents for this purpose. What are the key sections that are normally included?`,
  //   `What is the purpose of each section?`,
  //   `What is the order of the sections?`,
  //   `What are the key points that are normally included in each section?`,
  //   `You do NOT need to include any sections like title, subtitle, authors, date created, target audience, etc. These are standard sections that are normally included in any document.`,
  //   `Do not add numbering to any headings. Numbering will be added automatically when the template is used.`,
  //   `For each heading value you must also provided an associated rationale attribute.`,
  // ]

  const initialMessages = [
    `Use the following TOC to create a multilevel template`,
    templateBasicsResult.data.response.choices[0].message.content,
  ]

  await addMessages({ threadId, content: convertToMessages(initialMessages) })

  const checkBestPracticeRunResult = await runAssistant({
    threadId,
    assistantId: assistant.id,
    usage: "think about the reader and preferred content",
    expectedStatus: "completed",
    modelName: gptModel,
  })

  const bestPracticeRunId = checkBestPracticeRunResult.result.data.response.id

  console.log("check best practice run result", { checkBestPracticeRunResult })

  const msgs = await listMessages({ threadId, runId: bestPracticeRunId })

  const approach = msgs.data.response.body.data[0].content[0].text.value
  console.log("%capproach", "color:lightgreen", { approach })

  await addMessages({
    threadId,
    content: convertToMessages([
      `Now create a template that follows the structured that you detailed in the previous step and aligns to the template purpose I have provided. Make sure all researched information is included in the template.`,
      //`Use the create_template_guidance.txt file to help achieve a good mapping of question subjects to ArchiMate element types.`,
    ]),
  })

  const createRunResult = await runAssistant({
    threadId,
    assistantId: assistant.id,
    usage: "create template",
    expectedStatus: "requires_action",
    functionToUse: createTemplateFunction,
    modelName: gptModel,
  })

  const runId = createRunResult.result.data.response.id

  console.log("%ccreated run", "color:lightgreen", { createRunResult, runId })

  const result = await retrieveRunForStatus({
    threadId: threadId,
    runId: runId,
    expectedStatus: "requires_action",
  })

  console.log("create template result", { result })

  let createTemplateResult

  if (result.success) {
    const rawJson =
      result.result.data.response.required_action.submit_tool_outputs
        .tool_calls[0].function.arguments

    console.log("raw json", { rawJson })
    const parsedJSON = JSON.parse(rawJson)

    createTemplateResult = parsedJSON
  }

  console.log("create template result", { createTemplateResult })

  const deleteThreadResult = await deleteThread({ threadId: threadId })
  console.log("delete thread result", { deleteThreadResult })

  return createTemplateResult
}

export { createTemplate }
