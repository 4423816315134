import React, { useState, useEffect } from "react"
import { Box, Card, CardContent, CardActionArea, Grid, Typography, CardHeader } from "@mui/material"
import { useHistory } from "react-router-dom"
import Diagram from "./Diagram"
import _ from "lodash"
import * as tooltipServices from "../pages/services/tooltipServices"
import * as urlServices from "../pages/services/urlServices"
import { useSelector } from "react-redux"
import { selectAutofitDiagram, selectDiagramScale, selectModelState } from "../redux/selectors"
import TagSummary from "./TagSummary"
import { spacing } from "../pages/services/styleServices"
import * as modelServices from "../pages/services/modelServices"
import * as chatPromptServices from "../pages/services/chatPromptServices"
import CreatePromptDialog from "./CreatePromptDialog"

const styles = {
    root: {
        flexGrow: 0,
        minWidth: 180,
    },
    card: {
        marginRight: spacing(0),
        marginTop: spacing(1),
    },
}

function ViewCard(props) {
    const DEFAULT_MAX_WIDTH = 280

    const DEFAULT_CARD_WIDTH = 400

    const {
        view,
        modelCacheKey,
        showDiagram = true,
        showRules = false,
        showLabels = true,
        highlightElementIds = [],
        maxWidth = DEFAULT_MAX_WIDTH,
        constrainRuleWidth = false,
        showDocumentation,
        showTooltips,
        showCopyButton,
        showCreateStoryButton,

        // view tags (optional)
        tags,
    } = props

    const history = useHistory()

    const [params, setParams] = useState()

    const [showChatPrompt, setShowChatPrompt] = useState(false)

    const [chatPromptData, setChatPromptData] = useState(null)

    const autoFitToWindow = useSelector(selectAutofitDiagram)

    const diagramScale = useSelector(selectDiagramScale)

    const modelCache = useSelector(selectModelState)

    const [cardStyleProps, setCardStyleProps] = useState({
        style: { maxWidth: DEFAULT_CARD_WIDTH },
    })

    useEffect(() => {
        if (autoFitToWindow) {
            setCardStyleProps({
                style: { maxWidth: DEFAULT_CARD_WIDTH },
            })
        } else {
            setCardStyleProps({
                style: { width: maxWidth },
            })
        }
    }, [autoFitToWindow, maxWidth])

    useEffect(() => {
        // TODO: fix inconsistent naming of parentId, sometimes called either parentId or modelId
        const newParams = {
            modelId: modelCacheKey.parentId,
            parentId: modelCacheKey.parentId,
            viewId: view.id,
            fileName: modelCacheKey.fileName,
        }

        if (!_.isEqual(newParams, params)) {
            setParams(newParams)
        }
    }, [modelCacheKey.fileName, modelCacheKey.parentId, view.id])

    const handleCreateChatPrompt = () => {
        console.log("create prompt", modelCache, modelCacheKey)

        const modelCacheItem = modelServices.getModelFromCache({
            modelCache: modelCache,
            parentId: modelCacheKey.parentId,
            fileName: modelCacheKey.fileName,
        })

        console.log("get model cache item", { modelCache, modelCacheItem, modelCacheKey })

        const promptData = chatPromptServices.createPromptDataFromModelCache(modelCacheItem, view)

        setChatPromptData(promptData)
        setShowChatPrompt(true)
    }

    const handleClickView = () => {
        history.push({
            pathname: urlServices.createViewEditUrl({
                parentId: modelCacheKey.parentId,
                viewId: view.id,
                fileName: modelCacheKey.fileName,
            }),
            state: { modelCacheKey },
        })
        //console.log("click view")
    }

    return (
        <>
            {showChatPrompt && (
                <CreatePromptDialog
                    open={showChatPrompt}
                    onClose={() => setShowChatPrompt(false)}
                    promptData={chatPromptData}
                />
            )}

            <Grid item sx={styles.root}>
                <Box sx={{ padding: 0.1 }}>
                    <Card sx={styles.card} style={cardStyleProps} variant="outlined">
                        <CardActionArea
                            onMouseMove={(event) =>
                                showTooltips &&
                                view &&
                                tooltipServices.showTooltip(event, view.name, view.documentation)
                            }
                            onMouseOut={() => view && tooltipServices.hideTooltip()}
                        >
                            <CardHeader
                                onClick={handleClickView}
                                title={
                                    <Typography
                                        variant="body2"
                                        component={"span"}
                                        sx={{ fontWeight: "bold" }}
                                    >
                                        {view.name}
                                    </Typography>
                                }
                                sx={{ padding: "5px" }}
                            />
                        </CardActionArea>

                        <CardContent sx={{ padding: "5px" }}>
                            {!params && showDiagram && (
                                <Typography component={"span"}>Loading...</Typography>
                            )}

                            {params && showDiagram && view && view.elements.length > 0 && (
                                <Box>
                                    <Diagram
                                        params={params}
                                        maxWidth={maxWidth}
                                        maxScale={diagramScale / 100}
                                        showRules={showRules}
                                        showLabels={showLabels}
                                        highlightElementIds={highlightElementIds}
                                        constrainRuleWidth={constrainRuleWidth}
                                        showDocumentation={showDocumentation}
                                        showCreateRefButton={true}
                                        showTooltips={showTooltips}
                                        showCopyButton={showCopyButton}
                                        showCreateStoryButton={showCreateStoryButton}
                                        handleCreateChatPrompt={handleCreateChatPrompt}
                                    />
                                </Box>
                            )}
                            <TagSummary tags={tags} isEditing={false} />
                        </CardContent>
                    </Card>
                </Box>
            </Grid>
        </>
    )
}

export default ViewCard
