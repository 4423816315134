import React, { useEffect, useState } from "react"
import { Fab, Box, Paper, Typography, IconButton } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import Controls from "./controls/Controls"
import firebase from "firebase/compat/app"
import db from "../Firestore"
import { useSelector } from "react-redux"
import * as palette from "./symbols/palette"
import DeleteIcon from "@mui/icons-material/Delete"
import { selectModelState } from "../redux/selectors"
import * as modelServices from "../pages/services/modelServices"
import _ from "lodash"
import { ElementTypeSelector } from "./controls/ElementTypeSelector"
import YesNo from "./YesNo"
import { spacing } from "../pages/services/styleServices"

const styles = {
    fab: {
        position: "fixed",
        bottom: 16,
        right: 16,
        top: "auto",
        left: "auto",
    },
    moreVertAndItems: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        gap: spacing(1),
    },
    elementTypeList: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: spacing(1),
        flexWrap: "wrap",
        marginTop: spacing(1.2),
    },
    elements: {
        paddingTop: spacing(1),
        paddingLeft: spacing(3),
        paddingBottom: spacing(2),
    },
    subElements: {
        paddingTop: spacing(1),
        paddingLeft: spacing(5),
        paddingBottom: spacing(2),
    },
}

const PrintForm = (props) => {
    const { parentId, type } = props?.computedMatch?.params

    const [printItems, setPrintItems] = useState([])

    const [id, setId] = useState(0)

    const [options, setOptions] = useState()

    const [accountId, setAccountId] = useState()

    const [parent, setParent] = useState()

    const [fileOptions, setFileOptions] = useState()

    const modelCache = useSelector(selectModelState)

    const [modelCacheItems, setModelCacheItems] = useState()

    const [isAdding, setAdding] = useState(false)

    useEffect(() => {
        const newOptions = [
            { id: "2D", title: "Element List" },
            { id: "V", title: "View" },
        ]

        setOptions(newOptions)
    }, [])

    useEffect(() => {
        console.log("%cuseEffect::print items", "color:lightgreen", printItems)
    }, [printItems])

    useEffect(() => {
        const unsub = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                user.getIdTokenResult(false).then((token) => {
                    setAccountId(token.claims.account_id)
                })
            }
        })

        return unsub
    }, [])

    useEffect(() => {
        if (modelCache && parent) {
            const modelCacheItems = parent.files
                .map((fileName) => {
                    //console.log("get model cache item", { modelCache, parent, file: fileName })
                    const modelCacheItem = modelServices.getModelFromCache({
                        modelCache,
                        parentId: parent.id,
                        fileName: fileName,
                    })
                    return modelCacheItem
                })
                .filter((item) => item)

            //console.log("modelCacheItems", modelCacheItems)

            setModelCacheItems(modelCacheItems)
        }
    }, [modelCache, parent])

    useEffect(() => {
        if (parentId && type) {
            const collection = type === "project" ? "projects" : "components"

            db.collection(collection)
                .doc(parentId)
                .get()
                .then((docSnapshot) => {
                    setParent({ ...docSnapshot.data(), id: docSnapshot.id })

                    const fileOptions = docSnapshot.data().files.map((file) => ({
                        id: file,
                        title: file,
                    }))

                    setFileOptions(fileOptions)
                })
        }
    }, [parentId, type])

    const handleAddPrintItem = () => {
        const newProps = {}

        if (printItems.length > 0) {
            const lastItem = printItems[printItems.length - 1]
            newProps.file = lastItem.file
            newProps.type = lastItem.type
        }

        const newId = id + 1
        setId(newId)

        const newPrintItems = [...printItems, { id: newId, type: "", ...newProps }]
        console.log("%chandleAddPrintItem", "color:lightgreen", { newPrintItems })

        setPrintItems(newPrintItems)
    }

    const handleDelete = (id) => {
        const newPrintItems = printItems.filter((item) => item.id !== id)
        setPrintItems(newPrintItems)
    }

    const handleAddAll = () => {
        setAdding(true)
    }

    useEffect(() => {
        console.log("%cuseEffect::adding", "color:lightgreen", isAdding)
        if (isAdding) {
            addAllPrintItems()
        }
    }, [isAdding])

    const addAllPrintItems = () => {
        const elementTypesByFile = modelCacheItems.map((model) => {
            const elementTypes = _.uniq(
                model.model.elements
                    .filter((element) => !element.source)
                    .map((element) => element.type)
            )

            const elementTypesGrouped = _.groupBy(
                elementTypes.map((elementType) => ({
                    elementType,
                    layer: palette.getElementType(elementType).layer.id,
                })),
                "layer"
            )

            const result = {
                file: model.model.file,
                element_types_by_layer: elementTypesGrouped,
            }

            return result
        })

        let newId = 0
        const newPrintItems = _.flatten(
            elementTypesByFile.map((fileItem, index) => {
                return _.flatten(
                    Object.values(fileItem.element_types_by_layer).map((layer) => {
                        return _.flatten(
                            layer.map((item) => {
                                console.log("%citem", "color:yellow", {
                                    item,
                                    type: item.elementType,
                                })

                                const types = [item.elementType]

                                console.log("%ctypes", "color:yellow", types)

                                newId = newId + 1

                                const result = {
                                    id: newId,
                                    type: "2D",
                                    element_types: types,
                                    sub_element_types: [],
                                    file: fileItem.file,
                                }

                                console.log("%cresult", "color:yellow", result)

                                return result
                            })
                        )
                    })
                )
            })
        )

        console.log("%cnewPrintItems", "color:lightgreen", newPrintItems)

        setPrintItems(newPrintItems)
        setAdding(false)
    }

    const handleChange = (event, id) => {
        console.log("handleChange", event.target.name, event.target.value, id)
        const newPrintItems = printItems.map((item) => {
            if (item.id === id) {
                if (event.target.name === "element_types" && event.target.value.length === 0) {
                    return item
                }

                item[event.target.name] = event.target.value
            }

            return item
        })

        console.log("%chandleChange", "color:lightgreen", { newPrintItems })

        setPrintItems(newPrintItems)
    }

    return (
        <>
            <Box>
                parent: {parentId}, {type}, {accountId}
            </Box>
            <Box>
                <Controls.Button type="button" text="All" onClick={handleAddAll} />
            </Box>
            <Box>
                {printItems &&
                    printItems.map((printItem) => (
                        <PrintItem
                            key={printItem.id}
                            printItem={printItem}
                            printItems={printItems}
                            id={printItem.id}
                            options={options}
                            fileOptions={fileOptions}
                            handleChange={handleChange}
                            handleDelete={handleDelete}
                            modelCacheItems={modelCacheItems}
                            isAdding={isAdding}
                        />
                    ))}
            </Box>
            <Fab color="primary" aria-label="add" sx={styles.fab} onClick={handleAddPrintItem}>
                <AddIcon />
            </Fab>
        </>
    )
}

const printStyles = {
    root: {
        margin: spacing(1),
        maxWidth: 500,
    },
    selectTypeAndFile: {
        display: "flex",
        flexDirection: "row",
        gap: spacing(3),
        marginBottom: spacing(2),
        paddingLeft: spacing(2),
        paddingTop: spacing(1),
    },
    itemIcons: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
    },
}

const PrintItem = (props) => {
    const {
        printItem,
        printItems,
        id,
        options,
        handleChange,
        handleDelete,
        fileOptions,
        modelCacheItems,
        isAdding,
    } = props

    const [modelCacheItem, setModelCacheItem] = useState()

    const [yesNoConfig, setYesNoConfig] = useState({
        title: "Delete?",
        openPrompt: false,

        // this method is set when we prompt for deletion
        handleConfirm: null,
    })

    useEffect(() => {
        if (printItems && id) {
            const pi = printItems.find((item) => item.id === id)
            const mci = modelCacheItems.find((item) => item.model.file === pi.file)
            setModelCacheItem(mci)
        }
    }, [printItems, id])

    const handleDeleteItemConfirm = () => {
        handleDelete(id)
    }

    const handleDeleteItem = () => {
        setYesNoConfig({ ...yesNoConfig, openPrompt: true, handleConfirm: handleDeleteItemConfirm })
    }

    return (
        <>
            <YesNo config={yesNoConfig} />
            <Paper sx={printStyles.root}>
                <Box sx={printStyles.itemIcons}>
                    <IconButton onClick={handleDeleteItem}>
                        <DeleteIcon />
                    </IconButton>
                </Box>

                <Box sx={printStyles.selectTypeAndFile}>
                    {printItem && (
                        <Controls.Select
                            name="type"
                            label={
                                <Typography variant="body2" component={"span"}>
                                    Type
                                </Typography>
                            }
                            value={printItem.type}
                            options={options}
                            onChange={(event) => !isAdding && handleChange(event, printItem.id)}
                        />
                    )}
                    {printItem && (
                        <Controls.Select
                            name="file"
                            label="File"
                            value={printItem.file}
                            options={fileOptions}
                            onChange={(event) => handleChange(event, printItem.id)}
                        />
                    )}
                </Box>

                {printItem && (
                    <ElementListGroupedItem
                        printItems={printItems}
                        id={id}
                        modelCacheItem={modelCacheItem}
                        handleChange={handleChange}
                    />
                )}

                {printItem && printItem.type === "V" && (
                    <ViewItem printItem={printItem} modelCacheItem={modelCacheItem} />
                )}
            </Paper>
        </>
    )
}

const ElementListGroupedItem = (props) => {
    const { printItems, id, modelCacheItem, handleChange } = props

    const [elements, setElements] = useState()

    const [printItem, setPrintItem] = useState()

    const setElementTypes = (elementTypes) => {
        const eventUpdate = { target: { name: "element_types", value: elementTypes } }

        handleChange(eventUpdate, id)
    }

    useEffect(() => {
        if (printItems && id) {
            setPrintItem(printItems.find((item) => item.id === id))
        }
    }, [printItems, id])

    const setSubElementTypes = (subElementTypes) => {
        const eventUpdate = { target: { name: "sub_element_types", value: subElementTypes } }

        handleChange(eventUpdate, id)
    }

    useEffect(() => {
        if (modelCacheItem && printItems && id) {
            const printItem = printItems.find((item) => item.id === id)

            const els = modelCacheItem.model.elements
                .filter((element) => printItem.element_types?.includes(element.type))
                .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
            setElements(els)
        }
    }, [printItems, id, modelCacheItem])

    const getChildElements = (parentElement, childTypes) => {
        const childRels = modelCacheItem.model.elements.filter(
            (element) => element.source === parentElement.id
        )

        const childElIds = childRels.map((rel) => rel.target)
        const childEls = modelCacheItem.model.elements
            .filter((el) => childElIds.includes(el.id))
            .filter((el) => childTypes.includes(el.type))

        return childEls
    }

    return (
        <>
            <Box>
                <Box>
                    {printItem &&
                        printItem.elements &&
                        printItem.elements.map((element) => <Box key={element}>{element}</Box>)}
                </Box>
                <Box sx={styles.moreVertAndItems}>
                    <Box>
                        <ElementTypeSelector setElementTypes={setElementTypes} />
                    </Box>
                    <Box sx={styles.elementTypeList}>
                        {printItem &&
                            printItem.element_types &&
                            printItem.element_types.map((type) => <Box key={type}>{type}</Box>)}
                    </Box>
                </Box>
                <Box sx={styles.moreVertAndItems}>
                    <Box>
                        <ElementTypeSelector setElementTypes={setSubElementTypes} />
                    </Box>
                    <Box sx={styles.elementTypeList}>
                        {printItem &&
                            printItem.sub_element_types &&
                            printItem.sub_element_types.map((type) => <Box key={type}>{type}</Box>)}
                    </Box>
                </Box>
                {!modelCacheItem && <Box>File not loaded</Box>}
                <Box sx={styles.elements}>
                    {elements &&
                        elements.map((element) => (
                            <Box key={element.id}>
                                <Box>{element.name}</Box>
                                <Box sx={styles.subElements}>
                                    {getChildElements(element, printItem.sub_element_types).map(
                                        (child) => (
                                            <Box key={child.name}>{child.name}</Box>
                                        )
                                    )}
                                </Box>
                            </Box>
                        ))}
                </Box>
            </Box>
        </>
    )
}

const ViewItem = (props) => {
    const { printItem } = props

    return (
        <Box>
            <Box>view</Box>
            <Box>{JSON.stringify(printItem)}</Box>
        </Box>
    )
}

export default PrintForm
