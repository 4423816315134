import React, { useState } from "react"
import Svg from "./Svg"
import { useFill } from "./useFill"
import * as common from "./common"

const Aggregation = (props) => {
    // When we have multi-segment lines, we only show the start and end markers
    // for a line on the 0th, and last line segment respectively.
    const { x1, y1, x2, y2, showStartMarker = true, highlight, lineColor } = props

    const markerStart = showStartMarker ? "url(#aggregation-diamond)" : ""

    const hoverColor = common.defaultLineHighlightColor
    const defaultColor = lineColor || common.defaultLineColor

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, setFill })

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <defs>
                <marker
                    id='aggregation-diamond'
                    markerWidth='10'
                    markerHeight='10'
                    refX='0'
                    refY='4.5'
                    orient='auto'
                >
                    <polygon points='0 4.5, 5 1, 10 4.5, 5 8' stroke='#000' fill='#fff' />
                </marker>
            </defs>
            <line
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                stroke={fill}
                strokeWidth='2'
                markerStart={markerStart}
            />
        </Svg>
    )
}

export default Aggregation
