import React, { useState, useEffect } from "react"
import Grid from "@mui/material/Grid"
import Controls from "./controls/Controls"
import { useForm, Form } from "./useForm"
import * as dataServices from "../pages/services/dataServices"
import SaveIcon from "@mui/icons-material/Save"
import DeleteIcon from "@mui/icons-material/Delete"
import db from "../Firestore"
import { useSnackbar } from "notistack"
import { useHistory } from "react-router-dom"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import CheckIcon from "@mui/icons-material/Check"
import _ from "lodash"
import {
  Box,
  IconButton,
  Chip,
  Typography,
  Checkbox,
  Tooltip,
  FormControlLabel,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@mui/material"
import YesNo from "./YesNo"
import * as palette from "./symbols/palette"
import * as colors from "@mui/material/colors"
import RuleTags from "./RuleTags"
import { spacing } from "../pages/services/styleServices"

const initialValues = () => {
  return {
    name: "",
    description: "",
    created: dataServices.localTimestamp(),
    modified: dataServices.localTimestamp(),
    rule_type: "parent_child",
    tags: [],
    rule_data: {
      sources: [],
      targets: [],
      connectors: [],
      source_to_target: true,
      source_to_target_msg: "",
      target_to_source: false,
      target_to_source_msg: "",
      required: "Y",
    },
  }
}

const styles = {
  ruleFields: {
    marginBottom: spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: spacing(1),
  },
  chips: {
    paddingLeft: spacing(1.5),
    paddingBottom: spacing(1),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: spacing(1),
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    gap: 1,
    marginTop: spacing(2),
  },
}

function RuleEditForm(props) {
  const { setTitle } = props

  const { enqueueSnackbar } = useSnackbar()

  const [ruleId, setRuleId] = useState(props.computedMatch.params.id)

  const history = useHistory()

  const isNew = () => ruleId === undefined || ruleId === ""

  const { values, setValues, handleInputChange } = useForm(initialValues())

  const COLLECTION_NAME = "rules"

  // List of available rule types
  const [ruleTypes] = useState({ parent_child: ParentChildRule })

  // By default use a parent/child rule
  const [ruletype, setRuleType] = useState({ element: ParentChildRule })

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Delete rule?",
    description: "This delete is permanent",
    openPrompt: false,

    // this method is set when we prompt for deletion
    handleConfirm: null,
  })

  useEffect(() => {
    //console.log("load rule", ruleId)

    if (ruleId != null) {
      loadRule(ruleId)
    }
  }, [ruleId])

  const loadRule = (ruleId) => {
    db.collection(COLLECTION_NAME)
      .doc(ruleId)
      .get()
      .then((snapshot) => {
        const ruleRec = _.merge({}, initialValues(), snapshot.data())

        //console.log("loaded rule", ruleRec)
        setValues(ruleRec)

        const type = ruleTypes[values.rule_type]
        setRuleType({ element: type })
      })
  }

  useEffect(() => setTitle(values.name), [values.name, setTitle])

  // 'confirmed' is true or false
  const handleDeleteConfirmed = () => {
    // Reset prompt flag, so if we'd clicked No, we can still click Delete again and get prompted
    const newPromptConfig = {
      ...yesNoConfig,
      openPrompt: false,
    }
    setYesNoConfig(newPromptConfig)

    if (ruleId !== undefined && ruleId !== "" && ruleId !== null) {
      db.collection(COLLECTION_NAME)
        .doc(ruleId)
        .delete()
        .then(history.goBack())
        .then(enqueueSnackbar("Rule deleted", { variant: "success" }))
    }
  }

  const handleUpdateTags = (tags) => {
    const newValues = {
      ...values,
      tags,
    }
    setValues(newValues)
    console.log("update tags", { tags, newValues })
  }

  const handlePromptConfirmDelete = (event) => {
    event.preventDefault()

    const newPromptConfig = {
      ...yesNoConfig,
      openPrompt: true,
      handleConfirm: handleDeleteConfirmed,
    }
    setYesNoConfig(newPromptConfig)
  }

  const setRuleData = (ruleData) => {
    const newValues = {
      ...values,
      rule_data: ruleData,
    }

    setValues(newValues)
  }

  const getValidationMessage = () => {
    const sourceCount = values.rule_data.sources.length
    const targetCount = values.rule_data.targets.length
    const connectorCount = values.rule_data.connectors.length

    // Must have at least one source or target
    if (sourceCount === 0 && targetCount === 0) {
      return "Must have at least 1 source or target"
    }

    // Can't have source and target if no connectors
    if (sourceCount > 0 && targetCount > 0 && connectorCount === 0) {
      return "Must have at least 1 connector type if source and target are specified"
    }

    // Must select that source to target or target to source

    if (
      values.rule_data.source_to_target === false &&
      values.rule_data.target_to_source === false
    ) {
      return "Must select source to target and/or target to source"
    }
  }

  const navigateAfterSubmit = () => {
    if (history.length > 0) {
      history.goBack()
    } else {
      history.push("/providers")
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const msg = getValidationMessage()
    if (msg) {
      enqueueSnackbar(msg, { variant: "warning" })
      return
    }

    if (values.name === "") {
      enqueueSnackbar("Enter rule name", { variant: "warning" })
    } else {
      if (isNew()) {
        dataServices
          .getCurrentUser()
          .then((user) => {
            const newRecord = {
              ...values,
              account_id: user.account_id,
              created: dataServices.serverTimestamp(),
              modified: dataServices.serverTimestamp(),
            }

            console.log("%csaving rule", "color: green", newRecord)

            db.collection(COLLECTION_NAME)
              .add(newRecord)
              .then((docRef) => setRuleId(docRef.id))
              .then(enqueueSnackbar("Created", { variant: "success" }))
              .then(() => history.push("/rules"))
          })
          .catch(function (error) {
            console.error("Error:" + error)
            enqueueSnackbar("Error", { variant: "error " })
          })
      } else {
        const updateRecord = {
          ...values,
          modified: dataServices.serverTimestamp(),
        }

        console.log("%cupdating rule", "color:green", updateRecord)

        db.collection(COLLECTION_NAME)
          .doc(ruleId)
          .update(updateRecord)
          .then(enqueueSnackbar("Updated", { variant: "success" }))
          .then(navigateAfterSubmit())
      }
    }
  }

  return (
    <>
      {yesNoConfig && <YesNo config={yesNoConfig} />}

      <Form>
        <Grid container direction="column">
          <Grid item>
            <Controls.TextInput
              name="name"
              label="Name"
              value={values.name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <Controls.TextInput
              name="description"
              label="Description"
              multiline
              value={values.description}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item>
            <Box sx={{ marginTop: "10px", marginLeft: "5px" }}>
              <Tooltip title="Allows for applying different rule sets against each project/component">
                <Typography
                  variant="caption"
                  color="textSecondary"
                  component={"span"}
                >
                  Rule type
                </Typography>
              </Tooltip>
            </Box>
            <Box sx={{ marginLeft: "5px" }}>
              <RuleTags
                editTags={true}
                handleUpdateTags={handleUpdateTags}
                tags={values.tags}
              />
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ marginLeft: "5px" }}>
          {ruletype && values && (
            <ruletype.element
              ruleData={values.rule_data}
              setRuleData={setRuleData}
            />
          )}
        </Box>

        <Grid item sx={styles.buttons}>
          {!isNew() ? (
            <Controls.Button
              text="Delete"
              type="button"
              endIcon={<DeleteIcon />}
              onClick={handlePromptConfirmDelete}
            />
          ) : null}
          <Controls.Button
            type="button"
            text="Save"
            variant="contained"
            endIcon={<SaveIcon />}
            onClick={handleSubmit}
          />
        </Grid>
      </Form>
    </>
  )
}

// 1+ parents with 1+ possibe connectors and 1+ possible children
const ParentChildRule = (props) => {
  const { ruleData, setRuleData } = props

  const [elementMenuItemInfo, setElementMenuItemInfo] = useState([])

  const [connectorMenuItemInfo, setConnectorMenuItemInfo] = useState([])

  const [elementTypesHash, setElementTypesHash] = useState([])

  const [sourceAnchorEl, setSourceAnchorEl] = useState(null)

  const [targetAnchorEl, setTargetAnchorEl] = useState(null)

  const [connectorAnchorEl, setConnectorAnchorEl] = useState(null)

  const selectHeaders = (elementTypes) => {
    const selectedHeaders = []

    palette.LAYERS.forEach((layer) => {
      const allLayerItems = palette.ELEMENT_INDEX.filter(
        (item) => item.layer.name === layer.name
      ).map((item) => item.name)

      const isAllLayerItemsSelected = allLayerItems.every((item) =>
        elementTypes.includes(item)
      )

      if (isAllLayerItemsSelected) {
        selectedHeaders.push(layer.name)
      }
    })

    return selectedHeaders
  }

  // element types to filter by
  const [selectedSourceElementTypes, setSelectedSourceElementTypes] = useState(
    ruleData.sources
  )

  const [selectedSourceElementHeaders, setSelectedSourceElementHeaders] =
    useState([])

  const [selectedTargetElementTypes, setSelectedTargetElementTypes] = useState(
    ruleData.targets
  )

  const [selectedTargetElementHeaders, setSelectedTargetElementHeaders] =
    useState([])

  const [selectedConnectorTypes, setSelectedConnectorTypes] = useState(
    ruleData.connectors
  )

  useEffect(() => {
    if (ruleData) {
      setSelectedSourceElementHeaders(selectHeaders(ruleData.sources))
      setSelectedTargetElementHeaders(selectHeaders(ruleData.targets))
    }
  }, [ruleData])

  useEffect(() => {
    console.log("%crule data", "color: pink", props)
  }, [])

  const buildElementTypeMenuItems = () => {
    const menuInfo = []

    const elementTypes = palette.getElementTypes()

    Object.keys(elementTypes).forEach((layerKey) => {
      const layer = palette.LAYERS.find((layer) => layer.id === layerKey)
      const name = layer.name

      menuInfo.push({
        type: "header",
        label: name,
        menuProps: { style: { backgroundColor: layer.color } },
      })

      elementTypes[layerKey].forEach((element) => {
        menuInfo.push({
          type: "item",
          label: element.name,
          value: element.name,
          menuProps: {},
        })
      })
    })
    //console.log("%cmenu item info", "color:yellow", menuInfo)

    setElementTypesHash(_.flatten(Object.values(elementTypes)))
    setElementMenuItemInfo(menuInfo)
  }

  const buildConnectorTypeMenuItems = () => {
    const menuInfo = palette.getConnectorTypes().map((connector) => ({
      type: "item",
      label: connector,
      value: connector,
      menuProps: {},
    }))

    //console.log("%cmenu item info", "color:yellow", menuInfo)

    setConnectorMenuItemInfo(menuInfo)
  }

  useEffect(() => {
    buildElementTypeMenuItems()
    buildConnectorTypeMenuItems()
  }, [])

  const handleDeleteSource = (source) => {
    const newSources = ruleData.sources.filter((item) => item !== source)
    const newRules = {
      ...ruleData,
      sources: newSources,
    }
    setRuleData(newRules)
    setSelectedSourceElementTypes(newSources)
  }

  const handleDeleteTarget = (target) => {
    const newTargets = ruleData.targets.filter((item) => item !== target)
    const newRules = {
      ...ruleData,
      targets: newTargets,
    }
    setRuleData(newRules)
    setSelectedTargetElementTypes(newTargets)
  }

  const handleDeleteConnector = (connector) => {
    const newConnectors = ruleData.connectors.filter(
      (item) => item !== connector
    )
    const newRules = {
      ...ruleData,
      connectors: newConnectors,
    }
    setRuleData(newRules)
    setSelectedConnectorTypes(newConnectors)
  }

  const handleToggleConnector = (type, connector) => {
    //console.log("%ctoggle connector", "color:yellow", { connector, ruleData })

    const newConnectors = ruleData.connectors.includes(connector)
      ? ruleData.connectors.filter((item) => item !== connector)
      : [...ruleData.connectors, connector]

    const newRules = {
      ...ruleData,
      connectors: newConnectors,
    }
    //console.log("%cnew connectors", "color:yellow", { newConnectors, newRules })
    setRuleData(newRules)
    setSelectedConnectorTypes(newConnectors)
  }

  const handleToggleElementType = (
    type,
    label,
    setElementsFunc,
    setHeadersFunc,
    elementsList,
    headersList
  ) => {
    //console.log("%ctoggle element type", "color:yellow", { type, label })

    if (type === "header") {
      const isHeaderSelected = headersList.includes(label)

      const newHeaders = isHeaderSelected
        ? headersList.filter((header) => header !== label)
        : [...headersList, label]

      if (isHeaderSelected) {
        // deselect all elements of this type
        const elementsOfType = elementTypesHash
          .filter((item) => item.layer.name === label)
          .map((item) => item.name)

        const newElements = elementsList.filter(
          (element) => element !== label && !elementsOfType.includes(element)
        )

        setElementsFunc(newElements)
      } else {
        // select all elements of this type

        const newElements = _.uniq([
          ...elementsList,
          ...elementTypesHash
            .filter((item) => item.layer.name === label)
            .map((item) => item.name),
        ])

        setElementsFunc(newElements)
      }

      setHeadersFunc(newHeaders)
    } else if (type === "item") {
      const isItemSelected = elementsList.includes(label)

      const newItems = isItemSelected
        ? elementsList.filter((name) => name !== label)
        : [...elementsList, label]

      setElementsFunc(newItems)

      // If deselecting an item, make sure it's header row is deselected

      const layer = elementTypesHash.find((item) => item.name === label).layer

      const allLayerItems = elementTypesHash
        .filter((item) => item.layer.name === layer.name)
        .map((item) => item.name)

      const isAllLayerItemsSelected = allLayerItems.every((item) =>
        newItems.includes(item)
      )

      const newHeaders = isAllLayerItemsSelected
        ? [...headersList, layer.name]
        : headersList.filter((item) => item !== layer.name)

      setHeadersFunc(newHeaders)
      setElementsFunc(newItems)
      console.log(newItems)
    }
  }

  const handleToggleCheckbox = (event) => {
    const newRules = {
      ...ruleData,
      [event.target.name]: event.target.checked,
    }
    setRuleData(newRules)

    //console.log("%cupdate rule data", "color: yellow", newRules, event)
  }

  const handleValueChange = (event) => {
    const newRules = {
      ...ruleData,
      [event.target.name]: event.target.value,
    }
    setRuleData(newRules)

    //console.log("%cupdate rule data", "color: yellow", newRules, event)
  }

  return (
    <Box sx={styles.ruleFields}>
      <ElementMenu
        anchorEl={sourceAnchorEl}
        setAnchorEl={setSourceAnchorEl}
        selectedElementTypes={selectedSourceElementTypes}
        selectedElementHeaders={selectedSourceElementHeaders}
        handleToggleElementType={handleToggleElementType}
        setElementsFunc={setSelectedSourceElementTypes}
        setHeadersFunc={setSelectedSourceElementHeaders}
        setRuleData={setRuleData}
        ruleData={ruleData}
        menuItemInfo={elementMenuItemInfo}
        attrName="sources"
      />

      <ElementMenu
        anchorEl={targetAnchorEl}
        setAnchorEl={setTargetAnchorEl}
        selectedElementTypes={selectedTargetElementTypes}
        selectedElementHeaders={selectedTargetElementHeaders}
        handleToggleElementType={handleToggleElementType}
        setElementsFunc={setSelectedTargetElementTypes}
        setHeadersFunc={setSelectedTargetElementHeaders}
        setRuleData={setRuleData}
        ruleData={ruleData}
        menuItemInfo={elementMenuItemInfo}
        attrName="targets"
      />

      <ConnectorMenu
        anchorEl={connectorAnchorEl}
        setAnchorEl={setConnectorAnchorEl}
        selectedConnectorTypes={selectedConnectorTypes}
        handleToggleConnector={handleToggleConnector}
        setRuleData={setRuleData}
        ruleData={ruleData}
        menuItemInfo={connectorMenuItemInfo}
      />

      <Typography
        variant="caption"
        color="textSecondary"
        style={{ marginTop: "10px" }}
        component={"span"}
      >
        Source type(s)
      </Typography>

      <Box sx={styles.chips}>
        {ruleData &&
          ruleData.sources &&
          ruleData.sources.map((source) => (
            <Chip
              key={source}
              label={source}
              style={{ backgroundColor: palette.getElementLayerColor(source) }}
              onDelete={() => handleDeleteSource(source)}
            />
          ))}

        <IconButton
          name="change_sources"
          onClick={(event) => {
            setSourceAnchorEl(event.currentTarget)
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      </Box>

      <Typography variant="caption" color="textSecondary" component={"span"}>
        Connector(s)
      </Typography>

      <Box sx={styles.chips}>
        {ruleData &&
          ruleData.connectors &&
          ruleData.connectors.map((connector) => (
            <Chip
              key={connector}
              label={connector}
              onDelete={() => handleDeleteConnector(connector)}
            />
          ))}

        <IconButton
          name="change_connectors"
          onClick={(event) => {
            setConnectorAnchorEl(event.currentTarget)
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      </Box>

      <Typography variant="caption" color="textSecondary" component={"span"}>
        Target type(s)
      </Typography>

      <Box sx={styles.chips}>
        {ruleData &&
          ruleData.targets &&
          ruleData.targets.map((target) => (
            <Chip
              key={target}
              label={target}
              style={{ backgroundColor: palette.getElementLayerColor(target) }}
              onDelete={() => handleDeleteTarget(target)}
            />
          ))}

        <IconButton
          name="change_targets"
          onClick={(event) => {
            setTargetAnchorEl(event.currentTarget)
          }}
        >
          <MoreHorizIcon />
        </IconButton>
      </Box>

      <Typography variant="caption" color="textSecondary" component={"span"}>
        Require that Source(s) must have Target(s) defined
      </Typography>

      <Box>
        <FormControlLabel
          control={
            <Checkbox
              name="source_to_target"
              checked={ruleData.source_to_target}
              onChange={(event) => handleToggleCheckbox(event)}
            />
          }
          label={
            <Typography variant="caption" component={"span"}>
              Source must have Target
            </Typography>
          }
          sx={{ marginLeft: "auto" }}
        />
      </Box>

      {ruleData.source_to_target && (
        <Box style={{ maxWidth: 500, marginTop: 0 }}>
          <Controls.TextInput
            name="source_to_target_msg"
            label="Message"
            multiline
            value={ruleData.source_to_target_msg}
            onChange={handleValueChange}
            helperText="Message to display if rule triggered. Otherwise default message is used"
          />
        </Box>
      )}

      <Typography
        variant="caption"
        color="textSecondary"
        style={{ marginTop: "15px" }}
        component={"span"}
      >
        Require that Target(s) must have Source(s) defined
      </Typography>

      <Box>
        <FormControlLabel
          control={
            <Checkbox
              name="target_to_source"
              checked={ruleData.target_to_source}
              onChange={(event) => handleToggleCheckbox(event)}
            />
          }
          label={
            <Typography variant="caption" component={"span"}>
              Target must have Source
            </Typography>
          }
          sx={{ marginLeft: "auto" }}
        />
      </Box>

      <Typography variant="caption" color="textSecondary" component={"span"}>
        Should models match this rule, or avoid matching this rule?
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Chip
          label="Match"
          onClick={() =>
            handleValueChange({ target: { name: "required", value: "Y" } })
          }
          style={{
            backgroundColor:
              ruleData.required === "Y" ? colors.green[200] : "white",
          }}
        />
        <Chip
          label="Avoid Match"
          onClick={() =>
            handleValueChange({ target: { name: "required", value: "N" } })
          }
          style={{
            backgroundColor:
              ruleData.required === "N" ? colors.red[200] : "white",
          }}
        />
      </Box>

      {ruleData.target_to_source && (
        <Box style={{ maxWidth: 500 }}>
          <Controls.TextInput
            name="target_to_source_msg"
            label="Message"
            multiline
            value={ruleData.target_to_source_msg}
            onChange={handleValueChange}
            helperText="Message to display if rule triggered. Otherwise default message is used"
          />
        </Box>
      )}
    </Box>
  )
}

const ConnectorMenu = (props) => {
  const {
    anchorEl,
    setAnchorEl,
    selectedConnectorTypes,
    handleToggleConnector,
    setRuleData,
    ruleData,
    menuItemInfo,
  } = props

  const handleConnectorChange = () => {
    const newRules = {
      ...ruleData,
      connectors: selectedConnectorTypes,
    }

    setRuleData(newRules)

    setAnchorEl(null)
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={anchorEl !== null}
      onClose={handleConnectorChange}
    >
      {menuItemInfo &&
        menuItemInfo.map((item) => (
          <MenuItem
            key={item.label}
            value={item.label}
            onClick={() => handleToggleConnector(item.type, item.label)}
            {...item.menuProps}
          >
            <ListItemIcon>
              {selectedConnectorTypes &&
                selectedConnectorTypes.includes(item.label) && <CheckIcon />}
            </ListItemIcon>
            {item.label.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}
          </MenuItem>
        ))}
    </Menu>
  )
}

const ElementMenu = (props) => {
  const {
    anchorEl,
    setAnchorEl,
    selectedElementTypes,
    selectedElementHeaders,
    handleToggleElementType,
    setElementsFunc,
    setHeadersFunc,
    setRuleData,
    ruleData,
    menuItemInfo,
    attrName,
  } = props

  const handleElementTypeChange = () => {
    const newRules = {
      ...ruleData,
      [attrName]: selectedElementTypes,
    }

    setRuleData(newRules)

    setAnchorEl(null)
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={anchorEl !== null}
      onClose={handleElementTypeChange}
    >
      {menuItemInfo &&
        menuItemInfo.map((item) => (
          <MenuItem
            key={item.label}
            value={item.label}
            onClick={() =>
              handleToggleElementType(
                item.type,
                item.label,
                setElementsFunc,
                setHeadersFunc,
                selectedElementTypes,
                selectedElementHeaders
              )
            }
            {...item.menuProps}
          >
            <ListItemIcon>
              {((selectedElementTypes &&
                selectedElementTypes.includes(item.label)) ||
                (selectedElementHeaders &&
                  selectedElementHeaders.includes(item.label))) && (
                <CheckIcon />
              )}
            </ListItemIcon>
            {item.label.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}
          </MenuItem>
        ))}
    </Menu>
  )
}

export default RuleEditForm
