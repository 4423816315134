import React from "react"
import Header from "../components/Header"
import { Box } from "@mui/material"
import PromptGrid from "../components/PromptGrid"

const Stories = () => {
    return (
        <Header title="Prompts">
            <Box padding={1}>
                <PromptGrid />
            </Box>
        </Header>
    )
}

export default Stories
