import React from "react"

// A Connection is from a Note to another object
const Connection = (props) => {
    const { x1, y1, x2, y2 } = props

    //console.log('%cConnection symbols', 'color:orange', props)

    return (
        <svg>
            <line x1={x1} y1={y1} x2={x2} y2={y2} stroke='#777' strokeWidth='1' />
        </svg>
    )
}

export default Connection
