export const SET_PROJECT_GRID_PAGINATION = "SET_PROJECT_GRID_PAGINATION"

export const SET_PROJECT_GRID_PAGE_DOCS = "SET_PROJECT_GRID_PAGE_DOCS"

export const SET_PROJECT_GRID_SELECTED_ITEMS = "SET_PROJECT_GRID_SELECTED_ITEMS"

export const SET_VIEW_SET_GRID_PAGINATION = "SET_VIEW_SET_GRID_PAGINATION"

export const SET_VIEW_SET_GRID_PAGE_DOCS = "SET_VIEW_SET_GRID_PAGE_DOCS"

export const SET_PROMPT_GRID_PAGINATION = "SET_PROMPT_GRID_PAGINATION"

export const SET_PROMPT_GRID_PAGE_DOCS = "SET_PROMPT_GRID_PAGE_DOCS"

export const SET_STORY_PROMPT_GRID_PAGINATION = "SET_STORY_PROMPT_GRID_PAGINATION"

export const SET_STORY_PROMPT_GRID_PAGE_DOCS = "SET_STORY_PROMPT_GRID_PAGE_DOCS"

export const SET_ELEMENT_GRID_PAGINATION = "SET_ELEMENT_GRID_PAGINATION"

export const SET_ELEMENT_GRID_PAGE_DOCS = "SET_ELEMENT_GRID_PAGE_DOCS"

export const SET_TEMPLATE_GRID_PAGINATION = "SET_TEMPLATE_GRID_PAGINATION"

export const SET_TEMPLATE_GRID_PAGE_DOCS = "SET_TEMPLATE_GRID_PAGE_DOCS"

export const SET_VECTOR_STORE_GRID_PAGINATION = "SET_VECTOR_STORE_GRID_PAGINATION"

export const SET_VECTOR_STORE_GRID_PAGE_DOCS = "SET_VECTOR_STORE_GRID_PAGE_DOCS"

export const SET_STORY_GRID_PAGINATION = "SET_STORY_GRID_PAGINATION"

export const SET_STORY_GRID_PAGE_DOCS = "SET_STORY_GRID_PAGE_DOCS"

export const SET_MODEL_EDIT_DASHBOARD_TAB = "SET_MODEL_EDIT_DASHBOARD_TAB"

export const SET_STORY_GRID_SELECTED_ITEMS = "SET_STORY_GRID_SELECTED_ITEMS"

export const SET_SHOW_DIAGRAMS = "SET_SHOW_DIAGRAMS"

export const SET_SHOW_DASHBOARD_PROMPTS = "SET_SHOW_DASHBOARD_PROMPTS"

export const SET_DASHBOARD_VIEW_TOGGLES = "SET_DASHBOARD_VIEW_TOGGLES"

export const SET_SHOW_MORE_DASHBOARD = "SET_SHOW_MORE_DASHBOARD"

export const SET_DIAGRAM_SCALE = "SET_DIAGRAM_SCALE"

export const SET_MENU_OPEN = "SET_MENU_OPEN"

export const SET_CONFIG_MENU_OPEN = "SET_CONFIG_MENU_OPEN"

export const SET_SHOW_DOCUMENTATION_INDICATOR = "SET_SHOW_DOCUMENTATION_INDICATOR"

export const SET_SHOW_TOOLTIPS_INDICATOR = "SET_SHOW_TOOLTIPS_INDICATOR"

export const SET_SHOW_SUGGESTED_VIEWS = "SET_SHOW_SUGGESTED_VIEWS"

export const SET_AUTO_LEGEND = "SET_AUTO_LEGEND"

export const SET_SELECTED_ENTERPRISE_VIEW = "SET_SELECTED_ENTERPRISE_VIEW"

export const SET_AUTOFIT_DIAGRAM = "SET_AUTOFIT_DIAGRAM"

export const SET_SHOW_RULES = "SET_SHOW_RULES"

export const SET_SHOW_RULE_DETAIL = "SET_SHOW_RULE_DETAIL"

export const SET_EDIT_MODE = "SET_EDIT_MODE"

export const SET_COMPONENT_GRID_PAGINATION = "SET_COMPONENT_GRID_PAGINATION"

export const SET_CHART_PROJECTS_BY_TAG = "SET_CHART_PROJECTS_BY_TAG"

export const SET_COMPONENT_GRID_PAGE_DOCS = "SET_COMPONENT_GRID_PAGE_DOCS"

export const SET_COMPONENT_GRID_SELECTED_ITEMS = "SET_COMPONENT_GRID_SELECTED_ITEMS"

export const SET_MODEL = "SET_MODEL"

export const SET_SELECTED_RULES = "SET_SELECTED_RULES"

export const REMOVE_MODEL = "REMOVE_MODEL"

export const UPDATE_MODEL_MESSAGES = "UPDATE_MODEL_MESSAGES"

export const SET_ML_MODELS = "SET_ML_MODELS"

export const SET_SELECTED_MODEL_FILE_NAME = "SET_SELECTED_MODEL_FILE_NAME"

export const SET_ACCOUNT_GRID_PAGINATION = "SET_ACCOUNT_GRID_PAGINATION"

export const SET_ACCOUNT_GRID_PAGE_DOCS = "SET_ACCOUNT_GRID_PAGE_DOCS"

export const SET_ACCOUNT_GRID_SELECTED_ITEMS = "SET_ACCOUNT_GRID_SELECTED_ITEMS"

export const SET_SELECTED_TAB_INDEX = "SET_SELECTED_TAB_INDEX"

export const SET_PROJECTS = "SET_PROJECTS"

export const SET_COMPONENTS = "SET_COMPONENTS"

export const SET_ELEMENT_DEFINITIONS = "SET_ELEMENT_DEFINITIONS"

export const SET_PROJECT_MODEL_INDEX = "SET_PROJECT_MODEL_INDEX"

export const SET_COMPONENT_MODEL_INDEX = "SET_COMPONENT_MODEL_INDEX"

export const SET_PROJECT_EXPLORER_SETTINGS = "SET_PROJECT_EXPLORER_SETTINGS"

export const SET_COMPONENT_EXPLORER_SETTINGS = "SET_COMPONENT_EXPLORER_SETTINGS"
