import React, { useState } from "react"
import Svg from "./Svg"
import { useFill } from "./useFill"
import * as common from "./common"

const Specialization = (props) => {
    const { x1, y1, x2, y2, showEndMarker = true, highlight, highlightColor, lineColor } = props

    const markerEnd = showEndMarker ? "url(#specialization-end)" : ""

    const hoverColor = common.defaultLineHighlightColor
    const defaultColor = lineColor || common.defaultLineColor

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <defs>
                <marker
                    id='specialization-end'
                    markerWidth='12'
                    markerHeight='12'
                    refX='8'
                    refY='5'
                    orient='auto'
                >
                    <polygon points='1 1, 8 5, 1 9' fill='#fff' stroke='#000' />
                </marker>
            </defs>
            <line
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                stroke={fill}
                strokeWidth='2'
                markerEnd={markerEnd}
            />
        </Svg>
    )
}

export default Specialization
