import React from "react"
import { createRoot } from "react-dom/client"
import "./index.css"
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles"
import { Provider } from "react-redux"
import store from "./redux/store"
import { FirebaseAuthProvider } from "@react-firebase/auth"
import { SnackbarProvider } from "notistack"
import firebase from "firebase/compat/app"
import { firebaseConfig } from "./Firestore"
import TimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en"
import au from "javascript-time-ago/locale/en-AU"
import { blue, lime, blueGrey, lightBlue } from "@mui/material/colors"
import { createTheme } from "@mui/material"
import AppMenu from "./AppMenu"

const theme = createTheme({
    typography: {
        //fontFamily: "Noto Sans Display",
        fontFamily: "Manrope",
        h1: {
            fontSize: "2.5rem",
        },
        h2: {
            fontSize: "2rem",
        },
        h3: {
            fontSize: "1.5rem",
        },
        h4: {
            fontSize: "1.25rem",
        },
        h5: {
            fontSize: "1.1rem",
        },
        h6: {
            fontSize: "1rem",
            fontWeight: "bold",
        },
        subtitle1: {
            fontSize: "0.75rem",
        },
        subtitle2: {
            fontSize: "0.75rem",
        },
        body1: {
            fontSize: "0.90rem",
        },
        body2: {
            fontSize: "0.90rem",
        },
        button: {
            fontSize: "0.75rem",
        },
        caption: {
            fontSize: "0.75rem",
        },
        overline: {
            fontSize: "0.75rem",
        },
    },
    palette: {
        primary: { main: blue[500] },
        secondary: { main: lime[500] },
        text: {
            secondary: lightBlue[900],
            disabled: blueGrey,
        },
        link: { main: blue[800] },
    },
})

navigator.serviceWorker.addEventListener("controllerchange", () => {
    if (navigator.serviceWorker.controller) {
        console.log(
            "%c[sw] New service worker has taken control. Reloading page...",
            "color:orange; fontSize: 16pt"
        )
        window.location.reload()
        console.log("%c[sw] Reloaded page.", "color:orange;fontSize: 16pt")
    }
})

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/serviceWorker.js').then(registration => {
        console.log('SW registered: ', registration);
        registration.onupdatefound = () => {
          const newWorker = registration.installing;
          newWorker.onstatechange = () => {
            if (newWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                // Show a message to the user asking them to reload the page
                const reload = window.confirm("New version available! Do you want to reload the page?");
                if (reload) {
                  window.location.reload();
                }
              }
            }
          };
        };
      }).catch(registrationError => {
        console.log('SW registration failed: ', registrationError);
      });
    });
  }
  

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(au)

const container = document.getElementById("root")
const root = createRoot(container)
root.render(
    <Provider store={store}>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
                    <SnackbarProvider maxSnack={3} preventDuplicate>
                        <AppMenu />
                    </SnackbarProvider>
                </FirebaseAuthProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals()
