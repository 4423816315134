import React from "react"
import Header from "../components/Header"
import { Box } from "@mui/material"
import ComponentGrid from "../components/ComponentGrid"

const ComponentFind = () => {
    return (
        <Header title="Components">
            <Box padding={1}>
                <ComponentGrid />
            </Box>
        </Header>
    )
}

export default ComponentFind
