import React, { useState } from "react"
import * as common from "./common"
import { green } from "@mui/material/colors"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const Path = (props) => {
    const { x, y, height, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = green[200]
    const defaultColor = fillColor || green[100]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <rect
                x={x + 1}
                y={y + 1}
                fill={fill}
                stroke={common.outlineColor}
                width={width - 1}
                height={height - 1}
            />

            <svg x={x + width - 24} y={y}>
                <line
                    x1={0}
                    y1={12}
                    x2={16}
                    y2={12}
                    strokeDasharray='2 2'
                    stroke={common.outlineColor}
                />

                <path d={`M6,6 l -6 6 l 6 6`} fill='none' stroke={common.outlineColor} />

                <path d={`M12,6 l 6 6 l -6 6`} fill='none' stroke={common.outlineColor} />
            </svg>

            <ElementText {...props} label={label} />
        </Svg>
    )
}

export default Path
