import React, { useEffect, useState } from "react"
import * as common from "./common"
import { lightBlue } from "@mui/material/colors"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const ApplicationComponent = (props) => {
    const { x, y, height, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = lightBlue[200]
    const defaultColor = fillColor || lightBlue[100]

    const defaultNoduleColor = lightBlue[200]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill, dynFillColor } = useFill({
        defaultColor,
        hoverColor,
        highlight,
        highlightColor,
        setFill,
    })

    const [noduleColor, setNoduleColor] = useState(lightBlue[200])

    const leftSideOffset = 10

    useEffect(() => {
        setNoduleColor(fill)
    }, [fill])

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <rect
                x={x + leftSideOffset}
                y={y + 1}
                fill={fill}
                stroke={common.outlineColor}
                width={width - leftSideOffset}
                height={height - 1}
            />

            <rect
                onMouseOver={() => setNoduleColor(dynFillColor)}
                onMouseOut={() => setNoduleColor(defaultNoduleColor)}
                x={x + 1}
                y={y + 8}
                stroke={common.outlineColor}
                fill={noduleColor}
                width={20}
                height={12}
            />

            <rect
                onMouseOver={() => setNoduleColor(dynFillColor)}
                onMouseOut={() => setNoduleColor(defaultNoduleColor)}
                x={x + 1}
                y={y + 25}
                stroke={common.outlineColor}
                fill={noduleColor}
                width={20}
                height={12}
            />

            <ElementText
                {...props}
                label={label}
                x={x + leftSideOffset + 11}
                width={width - leftSideOffset - 8}
            />
        </Svg>
    )
}

export default ApplicationComponent
