import {
    Box,
    FormControlLabel,
    Switch,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
} from "@mui/material"

const ModelEditLayoutSelector = (props) => {
    const {
        orientation,
        showEditable,
        setOrientation,
        wrap,
        setWrap,
        tooltipTitle,
        level,
        setHighlightLevel,
    } = props

    return (
        <Box
            sx={{ border: "1px dotted #ccc" }}
            onMouseOver={() => {
                if (showEditable) {
                    setHighlightLevel(level)
                }
            }}
            onMouseOut={() => {
                if (showEditable) {
                    setHighlightLevel(undefined)
                }
            }}
        >
            <Typography variant="caption" sx={{ marginLeft: "3px" }}>
                Level {level}
            </Typography>
            <Tooltip title={tooltipTitle}>
                <>
                    <ToggleButtonGroup
                        sx={{ marginLeft: "10px" }}
                        variant="outlined"
                        size="small"
                        aria-label="outlined button group"
                        value={orientation}
                        onChange={(event) => setOrientation(event.target.value)}
                    >
                        <ToggleButton sx={{ textTransform: "none" }} value="row">
                            Across
                        </ToggleButton>
                        <ToggleButton sx={{ textTransform: "none" }} value="column">
                            Down
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <FormControlLabel
                        labelPlacement="top"
                        label={<Typography variant="caption">Wrap</Typography>}
                        control={
                            <Switch
                                checked={wrap === "wrap"}
                                onChange={(event, val) => {
                                    setWrap(val ? "wrap" : "nowrap")
                                }}
                            />
                        }
                    />
                </>
            </Tooltip>
        </Box>
    )
}

export default ModelEditLayoutSelector
