import React, { useState } from "react"
import * as common from "./common"
import BaseShapeRoundedRect from "./BaseShapeRoundedRect"
import { green } from "@mui/material/colors"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const TechnologyService = (props) => {
    const { x, y, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = green[200]
    const defaultColor = fillColor || green[100]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <BaseShapeRoundedRect {...props} fill={fill} setFill={handleFill} />

            <svg x={width - 28} y={6}>
                <BaseShapeRoundedRect
                    x={x}
                    y={y}
                    width={18}
                    height={10}
                    r={4}
                    fill={fill}
                    stroke={common.outlineColor}
                    setFill={handleFill}
                />
            </svg>

            <ElementText {...props} label={label} />
        </Svg>
    )
}

export default TechnologyService
