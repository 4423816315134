import React, { useState, useEffect, createRef } from "react"
import Grid from "@mui/material/Grid"
import Controls from "./controls/Controls"
import { useForm } from "./useForm"
import db from "../Firestore"
import * as dataServices from "../pages/services/dataServices"
import { withSnackbar, useSnackbar } from "notistack"
import { useHistory, withRouter } from "react-router-dom"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { saveAs } from "file-saver"
import ReactTimeAgo from "react-time-ago"
import firebase from "firebase/compat/app"
import EditIcon from "@mui/icons-material/Edit"
import ArchiveIcon from "@mui/icons-material/Archive"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh"
import SmartToyIcon from "@mui/icons-material/SmartToy"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import UnarchiveIcon from "@mui/icons-material/Unarchive"
import SaveIcon from "@mui/icons-material/Save"
import RuleIcon from "@mui/icons-material/Rule"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import ElementCountSummaryCard from "./ElementCountSummaryCard"
import * as Roles from "../pages/services/roleServices"
import YesNo from "./YesNo"
import { getProviderValues } from "../pages/services/providerServices"
import { useDispatch, useSelector } from "react-redux"
import DeleteIcon from "@mui/icons-material/Delete"
import {
  selectModelState,
  selectSelectedModelFileName,
  selectEditMode,
} from "../redux/selectors"
import { selectShowDiagrams, selectShowRules } from "../redux/selectors"
import {
  setModelState,
  setEditMode,
  removeModel,
  setSelectedModelFileName,
} from "../redux/actions"
import { setShowDiagrams, setShowRules } from "../redux/actions"
import { updateModelMessages } from "../redux/actions"
import * as modelServices from "../pages/services/modelServices"
import * as ruleServices from "../pages/services/ruleServices"
import ModelFileList from "./ModelFileList"
import _ from "lodash"
import { getGroupedElements } from "../model.mjs"
import * as icons from "../icons"
import * as moment from "moment"
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Switch,
  Box,
  ListItemIcon,
  Tooltip,
  Alert,
  Divider,
  ListItemButton,
} from "@mui/material"
import ViewCard from "./ViewCard"
import SelectComponentDialog from "./SelectComponentDialog"
import Heading from "./controls/Heading"
import TabPanel from "./TabPanel"
import WorkBreakdownStructure from "./WorkBreakdownStructure"
import { selectProjectModelIndex } from "../redux/selectors"
import { setProjectModelIndex } from "../redux/actions"
import Tags from "./Tags"
import TooltipHover from "./TooltipHover"
import CreateNewModelDialog from "./CreateNewModelDialog"
import TagPanel from "./TagPanel"
import RunRulesDialog from "./RunRulesDialog"
import * as tagServices from "../pages/services/tagServices"
import useAccountStatus from "./useAccountStatus"
import ProgressBackdrop from "./ProgressBackdrop"
import Bold from "./Bold"
import TagSummary from "./TagSummary"
import { spacing } from "../pages/services/styleServices"

const initialValues = () => {
  return {
    name: "",
    description: "",
    created: dataServices.localTimestamp(),
    modified: dataServices.localTimestamp(),
    user: "",
    files: [],
    provider_id: "",

    // The components that are used by this project, with each array element being:
    // {
    //    component_id: <some id>,
    //    prefab_name: <view name> // The name of the view in the Archi model that is being used
    // }
    components: [],
    tags: [],
    archived: false,
    ml_training: false,
    view_tags: [],
    tags_index: [],
  }
}

const styles = {
  input: {
    display: "none",
  },
  buttons: {
    marginTop: spacing(3),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "5px",
  },
  elementSummary: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: spacing(1),
    },
  },
  views: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "top",
    justifyContent: "flex-start",
    "& > *": {
      marginLeft: spacing(0.5),
    },
  },
  mainGrid: {
    display: "flex",
    flexDirection: "column",
  },
  centredWidget: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
}

const ProjectEditForm = (props) => {
  const history = useHistory()

  const [isShowProgress, setShowProgress] = useState(false)

  const dispatch = useDispatch()

  const { setTitle, tabIndex, setTabIndex } = props

  const STORIES_TAB = 3

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [projectId, setProjectId] = useState(props.computedMatch.params.id)

  // We need to track if the project name has changed, so we can update the Stories collection 'parent_name' attribute
  const [originalProjectName, setOriginalProjectName] = useState("")

  // The views from the selected model to show
  const [views, setViews] = useState()

  const projectEditInitialState = useSelector(selectEditMode)

  const [isProjectEditing, setProjectEditing] = useState(
    projectEditInitialState
  )

  const [accountId, setAccountId] = useState()

  const [hasAIMAIRole, setHasAIMAIRole] = useState(false)

  const [selectComponentOpen, setSelectComponentOpen] = useState(false)

  const [components, setComponents] = useState([])

  const [stories, setStories] = useState()

  const [modelIndexes, setModelIndexes] = useState()

  const addFileRef = createRef()

  const showDiagrams = useSelector(selectShowDiagrams)

  const showRules = useSelector(selectShowRules)

  const [selectedViewTags, setSelectedViewTags] = useState([])

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Delete",
    openPrompt: false,
    description: "Delete?",

    // Callback if user clicks 'Yes' to delete a child record.
    // We set the callback and label depending on what the user is deleting
    handleConfirm: null,
  })

  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false)

  const { isUploadModelActive } = useAccountStatus()

  const [isRunRulesDialogOpen, setRunRulesDialogOpen] = useState(false)

  const isNew = () => projectId === undefined || projectId === ""

  const { values, setValues, handleInputChange } = useForm(initialValues())

  const [user, setUser] = useState()

  const modelCache = useSelector(selectModelState)

  const indexCache = useSelector(selectProjectModelIndex)

  const selectedFileName = useSelector(selectSelectedModelFileName)

  const [selectedFileIndex, setSelectedFileIndex] = useState()

  const blankModel = { name: "", model: { elements: [], views: [] } }
  const [model, setModel] = useState(blankModel)

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      setUser(user)

      if (user) {
        user.getIdTokenResult(false).then((token) => {
          console.log("token", token)
          setAccountId(token.claims.account_id)
          setHasAIMAIRole(token.claims.roles.includes(Roles.AIM_AI))
        })
      }
    })

    return unsub
  }, [])

  const getModelCacheKey = (index) => {
    return modelServices.createModelCacheKey(
      values.files[index],
      projectId,
      "project"
    )
  }

  const handleSelectFile = async (index) => {
    setSelectedFileIndex(index)

    selectFile(index)
  }

  const updateSelectedFileNameState = (fileName) => {
    const selectedFileNameState = {
      parentId: projectId,
      fileName: fileName,
    }
    dispatch(setSelectedModelFileName(selectedFileNameState))
  }

  const selectFile = async (index) => {
    const fileName = values.files[index]

    updateSelectedFileNameState(fileName)

    const key = getModelCacheKey(index)

    // const cachedModel = Object.values(modelCache).find(
    //     (cacheEntry) =>
    //         cacheEntry.parent_id === key.parentId && cacheEntry.model.file === key.fileName
    // )

    const cachedModel = modelServices.searchModelCache({
      modelCacheKey: key,
      modelCache: modelCache,
    })

    console.log("%cloaded file", "color:lightgreen", { fileName, cachedModel })

    if (cachedModel) {
      setModel(cachedModel)
      setViews(
        cachedModel.model.views.sort((a, b) => a.name.localeCompare(b.name))
      )
    } else {
      await modelServices.loadFile(
        getFilePath(),
        fileName,
        loadModelIntoCache,
        {
          createModelIndex: false,
        },
        // Indicate if a load error has occurred
        showLoadStatus
      )
    }
  }

  const toggleEdit = () => {
    const newVal = !isProjectEditing
    setProjectEditing(newVal)
    dispatch(setEditMode(newVal))
  }

  // See what model indexes exist for each file

  useEffect(() => {
    if (values.files && values.files.length > 0 && projectId && accountId) {
      console.log("%cchecking model indexes", "color:pink", projectId, {
        files: values.files,
      })

      updateModelIndexList(projectId, accountId)
    }
  }, [values.files, projectId, accountId])

  const updateModelIndexList = (projectId, accountId) => {
    db.collection("model_index")
      .where("parent_id", "==", projectId)
      .where("account_id", "==", accountId)
      .get()
      .then((querySnapshot) => {
        setModelIndexes(querySnapshot.docs.map((doc) => doc.data().file_name))
      })
  }

  const createModelCacheEntry = (model, fileName) => {
    const modelState = modelServices.createModelCacheItem(
      model,
      fileName,
      values.name,
      projectId,
      "project"
    )

    return modelState
  }

  const loadModelIntoCache = (model, fileName, rawText, props) => {
    // Add a new cache item into the cache
    const modelState = createModelCacheEntry(model, fileName)

    // Replace the model cache with the updated state, incl. the newly added item
    dispatch(setModelState(modelState))

    if (props.createModelIndex) {
      createModelIndex(accountId, projectId, fileName, model)
    }
  }

  const createModelIndex = async (accountId, projectId, fileName, model) => {
    await dataServices.deleteExistingModelIndex(accountId, projectId, fileName)
    const index = await modelServices.createModelIndexObject(
      model,
      fileName,
      projectId,
      "project",
      accountId,
      hasAIMAIRole
    )

    console.log("%cadding model index", "color: yellow", index)

    db.collection("model_index").add(index)

    const newModelIndexes = [...(modelIndexes || []), fileName]
    setModelIndexes(newModelIndexes)
  }

  useEffect(() => {
    if (modelCache) {
      const key = getModelCacheKey(selectedFileIndex)

      const cachedModel = modelServices.searchModelCache({
        modelCacheKey: key,
        modelCache: modelCache,
      })
      // const cachedModel = Object.values(modelCache).find(
      //     (cacheEntry) =>
      //         cacheEntry.parent_id === key.parentId && cacheEntry.model.file === key.fileName
      // )

      if (cachedModel) {
        setModel(cachedModel)

        setViews(
          cachedModel.model.views.sort((a, b) => a.name.localeCompare(b.name))
        )
      }
    }
  }, [modelCache])

  useEffect(() => {
    if (selectedFileName && values.files.length > 0) {
      if (selectedFileIndex === undefined) {
        const fileName = selectedFileName.value[projectId]
        if (fileName !== undefined) {
          const index = values.files.findIndex((name) => name === fileName)
          handleSelectFile(index)
        }
      }
    }
  }, [selectedFileName, values, projectId])

  const showLoadStatus = (status) => {
    console.log("%cload status", "color:lightgreen", status)

    if (status.err) {
      enqueueSnackbar(
        `Unable to upload file. Check file format is an Archi file (.archimate) or OpenExchange (.xml). Error: ${status.err}`,
        {
          variant: "error",
        }
      )
    }
  }

  const handleAddFile = (event) => {
    if (event.target.files) {
      if (event.target.files.length !== 0) {
        const file = event.target.files[0]

        clearSelectedFile()

        var fileReader = new FileReader()
        fileReader.onload = async function (fileLoadedEvent) {
          var modelText = fileLoadedEvent.target.result

          await storeProjectFile({
            name: file.name,
            modelText: modelText,
          })

          // Refresh file names on page
          const newFiles = Array.from(
            new Set([...values.files, file.name])
          ).sort()

          await db
            .collection("projects")
            .doc(projectId)
            .update({ files: newFiles }, { merge: true })

          const newValues = {
            ...values,
            files: newFiles,
          }
          setValues(newValues)

          await modelServices.loadFile(
            getFilePath(),
            file.name,
            loadModelIntoCache,
            {
              createModelIndex: true,
            },
            showLoadStatus
          )
        }

        fileReader.readAsText(file, "UTF-8")

        // Do this in case we want to try to upload the same file.
        // Without this line, trying to re-upload the same file we just
        // uploaded does not work.
        event.target.value = null
      }
    }
  }

  // Load project

  const [loadState, setLoadState] = useState({})

  useEffect(() => {
    if (user === undefined || accountId === undefined) {
      return
    }

    const newLoadState = { id: projectId, accountId: accountId }

    const isLoadStateChanged = !_.isEqual(newLoadState, loadState)

    setLoadState(newLoadState)

    if (!isLoadStateChanged) {
      return
    }

    if (projectId !== null && projectId !== undefined) {
      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then((token) => {
          db.collection("projects")
            .doc(projectId)
            .get()
            .then(async (snapshot) => {
              const projectData = snapshot.data()

              let data = {
                ...initialValues(),
                ...projectData,
              }

              if (data.provider_id === null) {
                data.provider_id = ""
              }

              // old property, not used any more
              delete data.status

              if (
                projectData.files === undefined ||
                projectData.files.length === 0
              ) {
                const fileDetails = await modelServices.getFileDetails(
                  getFilePath()
                )
                const fileNames = fileDetails.map(
                  (fileDetail) => fileDetail.meta.name
                )

                data = {
                  ...data,
                  files: fileNames.sort(),
                }
              }

              console.log("%cloaded project data", "color:yellow", { data })

              setValues(data)
              setOriginalProjectName(data.name)
            })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, accountId, user])

  useEffect(() => setTitle(values.name), [values.name, setTitle])

  const getFilePath = () => {
    if (!accountId || !projectId) {
      console.error("getFilePath", "accountId or projectId not set", {
        accountId,
        projectId,
      })
    }
    return `accounts/${values.account_id}/projects/${projectId}/`
  }

  const storeProjectFile = async (fileInfo) => {
    await modelServices.storeFile(getFilePath(), fileInfo)
  }

  const handleUploadProject = (event) => {
    addFileRef.current.click()
  }

  const handlePromptDeleteFile = async (index) => {
    const newYesNoConfig = {
      ...yesNoConfig,
      title: "Delete File?",
      description: `Remove file ${values.files[index]} from Project?`,
      openPrompt: true,
      handleConfirm: () => handleDeleteFile(index),
    }

    setYesNoConfig(newYesNoConfig)
  }

  const clearSelectedFile = () => {
    setSelectedFileIndex(undefined)
    updateSelectedFileNameState(undefined)
    setViews(undefined)
    setModel(blankModel)
  }

  const handleDeleteFile = async (index) => {
    const fileNameToDelete = values.files[index]
    await deleteFileByName(fileNameToDelete)
  }

  const deleteFileByName = async (fileNameToDelete) => {
    // Remove model from cache

    console.log("%cremoving model from cache", "color: blue", {
      parentId: projectId,
      fileName: fileNameToDelete,
    })
    dispatch(
      removeModel(
        modelServices.getModelCacheId({
          parentId: projectId,
          fileName: fileNameToDelete,
        })
      )
    )

    modelServices.deleteFile(getFilePath(), fileNameToDelete)

    // Remove file from list of files in the project firestore document

    const newFiles = values.files.filter(
      (fileName) => fileName !== fileNameToDelete
    )

    // Merge changed file names in to doc

    await db
      .collection("projects")
      .doc(projectId)
      .update({ files: newFiles }, { merge: true })

    const newValues = {
      ...values,
      files: newFiles.sort(),
    }

    console.log("updated files values", newValues)

    setValues(newValues)

    // Clear out the model, model views, and element summary
    clearSelectedFile()

    // Delete model_index data

    await db
      .collection("model_index")
      .where("account_id", "==", accountId)
      .where("parent_id", "==", projectId)
      .where("file_name", "==", fileNameToDelete)
      .get()
      .then((docRefs) => {
        docRefs.docs.forEach((doc) => {
          db.collection("model_index").doc(doc.id).delete()
        })
      })

    enqueueSnackbar(`Deleted file ${fileNameToDelete}`, { variant: "info" })
  }

  const hideDeletePrompt = () => {
    const newConfig = {
      ...yesNoConfig,
      openPrompt: false,
    }
    setYesNoConfig(newConfig)
  }

  const removeFileFromIndexCache = (accountId, projectId, fileName) => {
    // Remove index entry from redux cache -- since the listener in the Explorer page won't detect deleted records

    const newIndexCache = {
      lastModified: indexCache.lastModified,
      items: indexCache.items.filter(
        (item) =>
          !(
            item.account_id === accountId &&
            item.parent_id === projectId &&
            item.file_name === fileName
          )
      ),
    }

    dispatch(setProjectModelIndex(newIndexCache))
  }

  useEffect(() => {
    if (tabIndex === STORIES_TAB && !stories && projectId && accountId) {
      console.log("%cloading stories", "color: blue", { projectId, accountId })
      dataServices.getStories(projectId, accountId).then((stories) => {
        setStories(stories.sort((a, b) => a.name.localeCompare(b.name)))

        // Check if project stories summary matches the stories just loaded

        dataServices
          .updateStoriesSummary("projects", projectId, values?.stories, stories)
          .then((storySummary) => {
            //if (storySummary) {
            // const newValues = {
            //     ...values,
            //     stories: storySummary,
            // }
            // console.log("%csetting values", "color: yellow", { newValues })
            //setValues(newValues)
            //}
          })
      })
    }
  }, [tabIndex, accountId, projectId])

  const handlePromptDeleteStory = (story, projectId) => {
    setYesNoConfig({
      title: "Delete",
      openPrompt: true,
      description: "Delete?",
      handleConfirm: () => handleDeleteStory(story.id, projectId),
    })
  }

  const handleDeleteStory = (storyId, projectId) => {
    dataServices
      .deleteStory({ storyId, parentId: projectId, type: "project" })
      .then(() => {
        enqueueSnackbar("Story deleted", { variant: "success" })
        setStories(stories.filter((s) => s.id !== storyId))
      })
  }

  const handleDeleteProjectConfirmed = async () => {
    hideDeletePrompt()

    if (projectId !== undefined && projectId !== "" && projectId !== null) {
      // Delete store and model_index

      console.log("remove model indexes", { projectId })

      await dataServices.deleteParentModelIndexes(projectId, accountId)
      values?.files.forEach((fileName) => {
        // Delete files from storage

        console.log("removing file", { fileName })
        modelServices.deleteFile(getFilePath(), fileName)

        removeFileFromIndexCache(accountId, projectId, fileName)
      })

      console.log("remove project from firestore", { projectId })
      // Delete project
      db.collection("projects")
        .doc(projectId)
        .delete()
        .then(() => {
          // Delete stories for this project

          db.collection("stories")
            .where("parent_id", "==", projectId)
            .where("account_id", "==", accountId)
            .get()
            .then(async (snapshot) => {
              snapshot.docs.forEach(async (doc) => {
                await db.collection("stories").doc(doc.id).delete()
              })
            })
        })
        .then(history.push("/projects"))
        .then(enqueueSnackbar("Deleted", { variant: "success" }))
    }
  }

  // Confirm if the user really wants to delete this project

  const handlePromptConfirmDelete = (event) => {
    event.preventDefault()

    setYesNoConfig({
      title: "Delete Project?",
      openPrompt: true,
      description:
        "This delete is permanent. All model files uploaded and stories created for this project will be deleted. Are you sure?",
      handleConfirm: () => handleDeleteProjectConfirmed(),
    })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (values.name === "") {
      enqueueSnackbar("Enter project name", { variant: "warning" })
    } else if (
      values.provider_id === "" ||
      values.provider_id === null ||
      !values.hasOwnProperty("provider_id")
    ) {
      enqueueSnackbar("Enter provider", { variant: "warning" })
    } else {
      if (isNew()) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then(async (token) => {
            const providerValues = await getProviderValues(values, true)

            const newProject = {
              ...values,
              account_id: token.claims.account_id,
              created: dataServices.serverTimestamp(),
              modified: dataServices.serverTimestamp(),

              // This will include the provider_account_id if the provider is external
              ...providerValues,
            }

            await db
              .collection("projects")
              .add(newProject)
              .then((docRef) => {
                setProjectId(docRef.id)
                history.replace(`/project/${docRef.id}`)
              })
              .then(
                enqueueSnackbar("Created", {
                  variant: "success",
                })
              )
          })
          .catch(function (error) {
            console.error("Error:" + error)
            enqueueSnackbar("Error", { variant: "error " })
          })
      } else {
        getProviderValues(values, false)
          .then((providerValues) => {
            const updateRecord = {
              ...values,
              tags_index: tagServices.toStringArray(values.tags),
              modified: dataServices.serverTimestamp(),

              ...providerValues,
            }

            db.collection("projects")
              .doc(projectId)
              .update(updateRecord)
              .then(
                enqueueSnackbar("Project saved", {
                  variant: "success",
                  vertical: "bottom",
                  horizontal: "right",
                })
              )
          })
          .then(() => {
            // Update story names
            if (originalProjectName !== values.name) {
              console.log("%cproject name changed", "color:yellow", {
                from: originalProjectName,
                to: values.name,
              })
            }
            setOriginalProjectName(values.name)

            console.log("updating stories", { projectId, accountId })

            db.collection("stories")
              .where("parent_id", "==", projectId)
              .where("account_id", "==", accountId)
              .get()
              .then(async (snapshot) => {
                snapshot.docs.forEach(async (doc) => {
                  // We update the story parent name to be the project name
                  await db.collection("stories").doc(doc.id).update({
                    parent_name: values.name,
                  })
                })
              })
          })
      }
    }
  }

  const handleOpenRule = (ruleId) => {
    if (ruleId) {
      history.push(`/RuleEdit/${ruleId}`)
    } else {
      console.error("Expecting ruleId", { ruleId })
    }
  }

  // Toggle for inclusion in ML training
  const handleToggleMlTraining = async () => {
    const newValues = {
      ...values,
      ml_training: !values.ml_training,
    }
    setValues(newValues)

    await db.collection("projects").doc(projectId).update(
      {
        ml_training: !values.ml_training,
        modified: dataServices.serverTimestamp(),
      },
      { merge: true }
    )

    enqueueSnackbar(
      newValues.ml_training
        ? "Project added as candidate to ML training"
        : "Project removed as candidate from ML training",
      { variant: "success" }
    )
  }

  const handleRunRules = async (ruleConfig) => {
    console.log("%chandleRunRules", "color:yellow", ruleConfig)

    setShowProgress(true)

    ruleServices
      .runRules(
        model,
        ruleConfig.rules,
        ruleConfig.mlModels,
        ruleConfig.mlRules,
        accountId,
        dispatch
      )
      .then((allMsgs) => {
        const cacheId = modelServices.getModelCacheId({
          parentId: projectId,
          fileName: model.model.file,
        })

        dispatch(updateModelMessages(cacheId, allMsgs))

        enqueueSnackbar(`Validated model. Found ${allMsgs.length} messages`, {
          variant: "info",
        })

        setShowProgress(false)
      })
  }

  const handleToggleIndexed = (fileName) => {
    if (values.archived) {
      enqueueSnackbar("Unarchive the project first", {
        variant: "warning",
      })
      return
    }

    console.log("%cmodel indexes", "color:yellow", { modelIndexes, fileName })

    if (modelIndexes.includes(fileName)) {
      console.log("%cremoving index", "color:yellow", { fileName })
      db.collection("model_index")
        .where("parent_id", "==", projectId)
        .where("account_id", "==", accountId)
        .where("file_name", "==", fileName)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(async (doc) => {
            await doc.ref.delete()
          })
        })
        //.then(() => updateModelIndexList(projectId, accountId))
        .then(() => {
          setModelIndexes((curr) => curr.filter((name) => name !== fileName))
          console.log("%cRemove index", "color:yellow", { fileName })
        })
        .then(() =>
          enqueueSnackbar(
            "Removed index. Model will no longer be searchable in Project Explorer",
            { variant: "info" }
          )
        )
        .then(() => {
          // Remove index entry from redux cache -- since the listener in the Explorer page won't detect deleted records

          removeFileFromIndexCache(accountId, projectId, fileName)
        })
    } else {
      // Get model for file

      modelServices
        .loadFile(
          getFilePath(),
          fileName,
          loadModelIntoCache,
          {
            createModelIndex: true,
          },
          // Indicate if a load error has occurred
          showLoadStatus
        )
        .then(() => {
          setModelIndexes((curr) => [...curr, fileName])
          console.log("%cAdd index", "color:yellow", { fileName })
        })
        .then(() =>
          enqueueSnackbar(
            "Index created. Model is now searchable in Project Explorer",
            {
              variant: "success",
            }
          )
        )
    }
  }

  const handleUpdateTags = (newTags) => {
    const newValues = {
      ...values,
      tags: newTags,
    }
    setValues(newValues)
  }

  const handleDownload = (index) => {
    const fileName = values.files[index]

    const extraCallbackProps = {}
    modelServices.loadFile(
      getFilePath(),
      fileName,
      (model, fileName, rawText) => {
        var blob = new Blob([rawText], {
          type: "text/plain;charset=utf-8",
        })
        saveAs(blob, fileName)
      },
      extraCallbackProps,
      showLoadStatus
    )
  }

  useEffect(() => {
    if (accountId) {
      if (isNew() && values.provider_id === "") {
        db.collection("providers")
          .where("account_id", "==", accountId)
          .get()
          .then((providers) => {
            if (!providers.empty) {
              const providerId = providers.docs[0].id

              const newValues = {
                ...values,
                provider_id: providerId,
              }
              setValues(newValues)
            }
          })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId])

  // Initialise account if it a new record

  useEffect(() => {
    if (projectId === null || projectId === undefined) {
      if (accountId === undefined) {
        return
      }

      const newValues = {
        ...values,
        account_id: accountId,
      }

      setValues(newValues)
    }
  }, [accountId])

  useEffect(() => {
    if (accountId && values.components.length > 0) {
      dataServices
        .getComponentsByIdChunks(
          accountId,
          values.components.map((c) => c.id)
        )
        .then((components) => {
          setComponents(components)
        })
    }
  }, [accountId, values.components])

  const storeFileAndLoadToCache = async (fileName, modelText) => {
    await storeProjectFile({ name: fileName, modelText: modelText })

    // Refresh file names on page
    const newFiles = Array.from(new Set([...values.files, fileName])).sort()

    await db
      .collection("projects")
      .doc(projectId)
      .update({ files: newFiles }, { merge: true })

    const newValues = {
      ...values,
      files: newFiles,
    }
    setValues(newValues)

    await modelServices.loadFile(getFilePath(), fileName, loadModelIntoCache, {
      createModelIndex: true,
    })
  }

  const handleAddNewGeneratedFileToProject = async (fileName, modelText) => {
    console.log("Add new generated file to project", {
      fileName,
      modelText,
    })

    setCreateDialogOpen(false)
    storeFileAndLoadToCache(fileName, modelText)

    enqueueSnackbar("File added", { variant: "success" })
  }

  const handlePrint = () => {
    history.push(`/print/project/${projectId}`)
  }

  // https://stackoverflow.com/questions/64456780/how-can-i-get-input-data-from-mui-rte-editor
  // const handleEditorChange = (event) => {
  //     const rteContent = convertToRaw(event.getCurrentContent()) // for rte content with text formating
  //     console.log("rte content", JSON.stringify(rteContent))

  //     setEditorValue(JSON.stringify(rteContent))
  // }

  const handleSelectComponents = (components) => {
    const addedComponents = components
      .filter(
        (component) =>
          (values.components || []).find((c) => c.id === component.id) ===
          undefined
      )
      .map((c) => ({ id: c.id, notes: "" }))

    const newComponents = [...(values.components || []), ...addedComponents]
    const newValues = {
      ...values,
      components: newComponents,
    }

    setValues(newValues)
  }

  const handleRemoveComponent = (id) => {
    const newComponents = values.components.filter((c) => c.id !== id)
    const newValues = {
      ...values,
      components: newComponents,
    }

    console.log("removing selected component", { values, newValues })

    setValues(newValues)
  }

  const handleUnarchive = () => {
    // Remove unarchived status

    const mergeValues = {
      archived: false,
      modified: dataServices.localTimestamp(),
    }

    const newValues = {
      ...values,
      ...mergeValues,
    }

    setValues(newValues)

    db.collection("projects")
      .doc(projectId)
      .update(mergeValues, { merge: true })
      .then(() =>
        enqueueSnackbar(
          "Project unarchived. You will need to click on the Index button next to each model that you wish to be searchable in Project Explorer",
          { variant: "success" }
        )
      )
  }

  const handleArchive = () => {
    // Remove indexes and tag project as archived

    db.collection("model_index")
      .where("parent_id", "==", projectId)
      .where("account_id", "==", accountId)
      .get()
      .then((indexesSnapshot) => {
        indexesSnapshot.forEach((indexDoc) => {
          indexDoc.ref.delete()
        })
        setModelIndexes([])
      })
      .then(() => {
        const mergeValues = {
          archived: true,
          modified: dataServices.localTimestamp(),
        }

        const newValues = {
          ...values,
          ...mergeValues,
        }
        setValues(newValues)

        db.collection("projects")
          .doc(projectId)
          .update(mergeValues, { merge: true })
          .then(() =>
            enqueueSnackbar(
              "Project archived. Will no longer appear in Project Explorer",
              { variant: "success" }
            )
          )
      })
  }

  const handleCreateNewModel = () => {
    setCreateDialogOpen(true)
  }

  // Get tags for selected view
  const getViewTags = () => {
    const tags = _.uniqWith(
      _.flatten(Object.values(values.view_tags).map((item) => item.tags)),
      (a, b) => a.label === b.label && a.type === b.type
    )

    return tags
  }

  const getFilteredViews = () => {
    // Find views which have the selected tags

    const selectedViewIds = values.view_tags
      .filter((viewTagInfo) => {
        const hasSelectedTags = viewTagInfo.tags.find((tag) =>
          selectedViewTags.find(
            (st) => st.type === tag.type && st.label === tag.label
          )
        )
        return hasSelectedTags
      })
      .map((item) => item.view_id)

    const newFilteredViews =
      selectedViewTags.length === 0
        ? views
        : views.filter((view) => selectedViewIds.includes(view.id))

    return newFilteredViews
  }

  const isModelCacheItemLoaded = () => {
    // console.log("%cissModelCacheItemLoaded", "color:yellow", {
    //     modelCache,
    //     selectedFileName,
    // })

    // const modelCacheItem = Object.values(modelCache).find(
    //     (cacheEntry) =>
    //         cacheEntry.parent_id === selectedFileName.value.parentId &&
    //         cacheEntry.model.file === selectedFileName.value.fileName
    // )

    const modelCacheItem = modelServices.searchModelCache({
      modelCacheKey: selectedFileName.value,
      modelCache: modelCache,
    })

    //console.log("%cmodelCacheItem", "color:yellow", { modelCacheItem })

    return modelCacheItem
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <YesNo config={yesNoConfig} />

      <ProgressBackdrop open={isShowProgress} />

      <TooltipHover />

      {selectComponentOpen && (
        <SelectComponentDialog
          open={selectComponentOpen}
          setOpen={setSelectComponentOpen}
          setSelectedComponents={handleSelectComponents}
        />
      )}

      {isCreateDialogOpen && (
        <CreateNewModelDialog
          open={isCreateDialogOpen}
          setOpen={setCreateDialogOpen}
          values={values}
          projectId={projectId}
          components={components}
          modelCache={modelCache}
          handleFileGenerated={handleAddNewGeneratedFileToProject}
        />
      )}

      {isRunRulesDialogOpen && (
        <RunRulesDialog
          open={isRunRulesDialogOpen}
          setOpen={setRunRulesDialogOpen}
          handleRunRules={handleRunRules}
        />
      )}

      <TabPanel value={tabIndex || 0} index={0}>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "center",
            }}
          >
            {!isProjectEditing && (
              <>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="h5">
                    <b>{values.name}</b>
                  </Typography>
                  <Typography variant="body2">{values.description}</Typography>
                  <TagSummary tags={values.tags} isEditing={isProjectEditing} />
                </Box>
                <Divider />
              </>
            )}
            {isProjectEditing && (
              <ProjectEditFields
                values={values}
                handleInputChange={handleInputChange}
                handleUpdateTags={handleUpdateTags}
                isEditing={isProjectEditing}
              />
            )}

            {/* <Box
                            sx={{
                                display: "flex",
                                marginLeft: "auto",
                            }}
                        >
                            <ToggleEditing toggleEdit={toggleEdit} />
                        </Box> */}
          </Box>

          {!isNew() && (
            <Box>
              <Heading>Project Models</Heading>

              <ModelFileList
                files={values.files}
                modelIndexes={modelIndexes}
                selectedFileIndex={selectedFileIndex}
                handleSelectFile={handleSelectFile}
                handleDownload={handleDownload}
                handleToggleIndexed={handleToggleIndexed}
                handlePromptDeleteFile={handlePromptDeleteFile}
              />
            </Box>
          )}

          {isNew() && (
            <Alert severity="info">
              Enter a project name and click <Bold>Save</Bold> to be able to
              upload model files
            </Alert>
          )}

          <Box sx={styles.buttons}>
            <Controls.Button
              text="Edit"
              endIcon={<EditIcon />}
              onClick={toggleEdit}
            />

            {!isNew() && (
              <Controls.Button
                text="Delete"
                type="button"
                tooltip="Delete this project including all uploaded files"
                endIcon={<DeleteIcon />}
                onClick={handlePromptConfirmDelete}
              />
            )}

            {!isNew() && (
              <Controls.Button
                text="Run Rules"
                type="button"
                tooltip="Run Architecture rules against the selected model"
                endIcon={<RuleIcon />}
                onClick={() => setRunRulesDialogOpen(true)}
                disabled={selectedFileIndex === undefined}
              />
            )}

            {!isNew() && (
              <Controls.Button
                text="Import Components"
                type="button"
                tooltip="Import views from Component models into your project architecture models"
                endIcon={<AutoFixHighIcon />}
                onClick={handleCreateNewModel}
                disabled={values.files.length === 0}
              />
            )}

            {!isNew() && (
              <Controls.Button
                text="ML Training"
                type="button"
                tooltip={
                  values && values.ml_training
                    ? "Remove as candidate from ML training"
                    : "Include as candidate in ML training"
                }
                startIcon={
                  values && values.ml_training ? <RemoveIcon /> : <AddIcon />
                }
                endIcon={
                  <SmartToyIcon
                    style={{
                      color: values && values.ml_training && "#2f2",
                    }}
                  />
                }
                onClick={handleToggleMlTraining}
              />
            )}

            {/* {!isNew() && (
                            <Controls.Button
                                text="Print"
                                type="button"
                                tooltip="Print"
                                endIcon={<PrintIcon />}
                                onClick={handlePrint}
                                disabled={values.files.length === 0}
                            />
                        )} */}

            {projectId && (
              <Controls.Button
                text="Upload Model"
                type="button"
                tooltip="Upload an OpenExchange (.xml) or Archi (.archimate) model file"
                endIcon={<CloudUploadIcon />}
                onClick={handleUploadProject}
                disabled={!isUploadModelActive(values.files?.length)}
              />
            )}

            {projectId &&
              values &&
              (values.archived ? (
                <Controls.Button
                  text="Unarchive"
                  endIcon={<UnarchiveIcon />}
                  type="button"
                  tooltip="Unarchive this project so that it appears in Project Explorer"
                  onClick={handleUnarchive}
                />
              ) : (
                <Controls.Button
                  text="Archive"
                  endIcon={<ArchiveIcon />}
                  type="button"
                  tooltip="Archive this project so that it does not appear in Project Explorer. You might do this for projects delivered long ago"
                  onClick={handleArchive}
                />
              ))}

            <Controls.Button
              type="button"
              text="Save"
              tooltip="Save project"
              variant="contained"
              endIcon={<SaveIcon />}
              onClick={(event) => handleSubmit(event)}
            />
          </Box>

          {!isNew() &&
            values.files.length > 0 &&
            selectedFileIndex !== undefined && (
              <Box>
                <Heading>Views</Heading>

                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    gap: "5px",
                    flexWrap: "wrap",
                  }}
                >
                  <Box sx={styles.centredWidget}>
                    <Box style={{ marginLeft: "15px" }}>
                      <Switch
                        checked={showDiagrams}
                        onChange={() => {
                          const show = !showDiagrams
                          dispatch(setShowDiagrams(show))
                          setShowDiagrams(show)
                        }}
                        color="primary"
                        size="small"
                      />
                    </Box>
                    <Box>
                      <Typography variant="caption" component={"span"}>
                        Show Diagrams
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={styles.centredWidget}>
                    <Box style={{ marginLeft: "15px" }}>
                      <Switch
                        checked={showRules}
                        onChange={() => {
                          const show = !showRules
                          dispatch(setShowRules(show))
                          setShowRules(show)
                        }}
                        color="primary"
                        size="small"
                      />
                    </Box>
                    <Box>
                      <Tooltip title="Show rules that apply to the selected view. Click 'Run Rules' first.">
                        <Typography variant="caption" component={"span"}>
                          Show Rules
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Box style={{ marginLeft: "20px" }}>
                    <Tags
                      tags={getViewTags()}
                      notifySelected={setSelectedViewTags}
                    />
                  </Box>
                </Box>

                <Box style={{ marginTop: "10px" }}>
                  {showRules &&
                    model &&
                    model.messages &&
                    model.messages.length > 0 && (
                      <Box sx={{ marginTop: "20px" }}>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component={"span"}
                        >
                          Model-wide messages
                        </Typography>

                        {model.messages.find((msg) => !msg.element) ===
                          undefined && (
                          <Typography variant="body2">None</Typography>
                        )}

                        <List dense sx={{ maxWidth: 500 }}>
                          {model.messages
                            .filter((message) => !message.element)
                            .map((message, index) => (
                              <ListItemButton key={message.id}>
                                <ListItemIcon>
                                  <RuleIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    <Typography
                                      variant="caption"
                                      component={"span"}
                                    >
                                      {message.msg}
                                    </Typography>
                                  }
                                />
                                <ListItemSecondaryAction>
                                  <IconButton
                                    edge="end"
                                    aria-label="open rule"
                                    onClick={() =>
                                      handleOpenRule(message.ruleId)
                                    }
                                  >
                                    <Tooltip title="Open rule">
                                      <EditIcon />
                                    </Tooltip>
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItemButton>
                            ))}
                        </List>
                      </Box>
                    )}
                </Box>

                <Box sx={styles.views}>
                  {views &&
                    isModelCacheItemLoaded() &&
                    getFilteredViews().map((view) => (
                      <ViewCard
                        key={`${view.id}-${selectedFileIndex}`}
                        view={view}
                        modelCacheKey={getModelCacheKey(selectedFileIndex)}
                        showDiagram={showDiagrams}
                        showRules={showRules}
                        showLabels={true}
                        constrainRuleWidth={true}
                        tags={
                          values?.view_tags.find(
                            (item) => item.view_id === view.id
                          )?.tags || []
                        }
                      />
                    ))}
                </Box>

                <Heading>
                  Components
                  <Tooltip title="IT Components reused by this Project">
                    <IconButton
                      style={{ marginLeft: "10px" }}
                      onClick={() => setSelectComponentOpen(true)}
                      size="large"
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  </Tooltip>
                </Heading>

                {values.components && values.components.length === 0 && (
                  <Alert severity="info">
                    No components defined. Add references to any components that
                    this project uses. This allows you to then import selected
                    views from those components into your project model(s)
                  </Alert>
                )}

                <Box>
                  {values.components.length > 0 && components && (
                    <List dense>
                      {values.components.map((component) => (
                        <ComponentListItem
                          key={component.id}
                          component={components.find(
                            (c) => c.id === component.id
                          )}
                          handleRemoveComponent={handleRemoveComponent}
                        />
                      ))}
                    </List>
                  )}
                </Box>
              </Box>
            )}

          <input
            hidden
            accept="*/*"
            sx={styles.input}
            id="icon-button-file"
            type="file"
            capture="environment"
            ref={addFileRef}
            onChange={(e) => handleAddFile(e)}
          />
        </Box>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        {tabIndex === 1 && (
          <>
            {selectedFileIndex === undefined && (
              <Alert severity="info">Select a model from the Project tab</Alert>
            )}

            {selectedFileIndex !== undefined && (
              <WorkBreakdownStructure model={model} />
            )}
          </>
        )}
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        {tabIndex === 2 && (
          <>
            {selectedFileIndex === undefined && (
              <Alert severity="info">Select a model from the Project tab</Alert>
            )}

            <Box sx={styles.elementSummary}>
              {model &&
                getGroupedElements(model)
                  .filter((item) => !item.key.endsWith("Relationship"))
                  .map((item) => (
                    <Grid item key={item.key}>
                      <ElementCountSummaryCard
                        elementType={item}
                        modelState={model}
                      />
                    </Grid>
                  ))}
            </Box>
          </>
        )}
      </TabPanel>

      <TabPanel value={tabIndex} index={3}>
        {tabIndex === 3 && (
          <ProjectStories
            stories={stories}
            handlePromptDeleteStory={handlePromptDeleteStory}
            modelCache={modelCache}
            projectId={projectId}
            selectedFileIndex={selectedFileIndex}
          />
        )}
      </TabPanel>
    </LocalizationProvider>
  )
}

const ProjectEditFields = (props) => {
  const { values, handleInputChange, handleUpdateTags, isEditing } = props

  return (
    <Box sx={styles.mainGrid}>
      <Box>
        <Controls.TextInput
          name="name"
          label="Project"
          value={values.name}
          onChange={handleInputChange}
          helperText={
            values.name === "" ? (
              "Enter project name"
            ) : (
              <>
                Created
                {moment(values.created.toDate()).format(
                  " D-MMM-yyyy hh:mm a"
                )}{" "}
                : <ReactTimeAgo date={values.created.toDate()} locale="en-AU" />
              </>
            )
          }
        />
      </Box>

      <Box>
        <Controls.TextInput
          name="description"
          label="Description"
          value={values.description}
          onChange={handleInputChange}
          multiline={true}
        />
      </Box>

      <Box sx={{ marginTop: 1 }}>
        <TagPanel
          tags={values.tags}
          handleUpdateTags={handleUpdateTags}
          heading={"Project Tags"}
          isEditing={isEditing}
        />
      </Box>
    </Box>
  )
}

const ComponentListItem = (props) => {
  const { component, handleRemoveComponent } = props

  const history = useHistory()

  const handleOpenComponent = (id) => {
    history.push(`/Component/${id}`)
  }

  return (
    <>
      {component && (
        <ListItemButton
          key={component.id}
          onClick={() => handleOpenComponent(component.id)}
        >
          <ListItemIcon>
            <icons.ComponentIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography component={"span"}>{component.name}</Typography>
            }
            secondary={
              <>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  component={"span"}
                >
                  {component.description}
                </Typography>

                {component.tags && (
                  <Tags tags={component.tags} editTags={false} />
                )}
              </>
            }
          />
          <ListItemSecondaryAction>
            <IconButton onClick={() => handleRemoveComponent(component.id)}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItemButton>
      )}
    </>
  )
}

const ProjectStories = (props) => {
  const history = useHistory()

  const {
    stories,
    handlePromptDeleteStory,
    modelCache,
    projectId,
    selectedFileIndex,
  } = props

  //const { storiesAndDiagrams, setStoriesAndDiagrams } = useState([])

  // useEffect(() => {
  //     if (stories) {
  //         const storiesAndDiagrams = stories.map((story) => {
  //             const key = modelServices.createModelCacheKey(
  //                 story.file_name,
  //                 story.parent_id,
  //                 story.type
  //             )

  //             const cachedModel = Object.values(modelCache).find(
  //                 (cacheEntry) =>
  //                     cacheEntry.parent_id === key.parentId &&
  //                     cacheEntry.model.file === key.fileName
  //             )

  //             return {
  //                 story,
  //                 model: cachedModel,
  //             }
  //         })
  //     }
  // }, [stories, modelCache])

  return (
    <Box>
      <Box>
        {/* {selectedFileIndex === undefined && (
                    <Alert severity="info">Select a model from the Project tab</Alert>
                )} */}

        {selectedFileIndex !== undefined && stories && stories.length === 0 && (
          <Alert severity="info">
            No stories defined. Select a view from the Project tab and choose
            Create Story.
          </Alert>
        )}
        <List dense style={{ maxWidth: 600 }}>
          {stories &&
            stories.map((story) => (
              <ListItem
                button
                key={story.id}
                onClick={() => {
                  history.push(`/Story/${story.id}`)
                }}
              >
                <ListItemText
                  primary={story.name}
                  secondary={
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      component={"span"}
                    >
                      {story.description}{" "}
                      <Tags
                        tags={story.tags || []}
                        editTags={false}
                        maxWidth={300}
                      />
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => handlePromptDeleteStory(story, projectId)}
                    size="large"
                  >
                    <icons.DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      </Box>
    </Box>
  )
}

export default withSnackbar(withRouter(ProjectEditForm))
