import * as actions from "../actionTypes"

// autofit diagram to page - global setting
const initialState = {
    value: true,
}

export default function autofitDiagram(state = initialState, action) {
    switch (action.type) {
        case actions.SET_AUTOFIT_DIAGRAM: {
            return {
                ...state,
                value: action.payload.value,
            }
        }

        default:
            return state
    }
}
