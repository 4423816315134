import React from "react"
import _ from "lodash"
import { FormControlLabel, Tooltip, Switch, Typography } from "@mui/material"
import * as colors from "@mui/material/colors"
import { useDispatch, useSelector } from "react-redux"
import { selectShowDocumentationIndicator } from "../redux/selectors"
import { setShowDocumentationIndicator } from "../redux/actions"

const DocumentationIndicator = (props) => {
    const dispatch = useDispatch()

    const show = useSelector(selectShowDocumentationIndicator)

    return (
        <Tooltip title='Highlight diagram elements that have documentation'>
            <FormControlLabel
                sx={{ marginLeft: "10px" }}
                control={
                    <Switch
                        size='small'
                        checked={show}
                        onChange={() => {
                            dispatch(setShowDocumentationIndicator(!show))
                        }}
                        style={{ color: show && colors.pink[500] }}
                    />
                }
                label={
                    <Typography variant='caption' color='textSecondary' component={"span"}>
                        Description
                    </Typography>
                }
            />
        </Tooltip>
    )
}

export default DocumentationIndicator
