import React, { useState } from "react"
import Svg from "./Svg"
import { useFill } from "./useFill"
import * as common from "./common"

const Influence = (props) => {
    const { x1, y1, x2, y2, lastLineSegment = true, highlight, lineColor } = props

    const markerEnd = lastLineSegment ? "url(#influence-end)" : ""

    const hoverColor = common.defaultLineHighlightColor
    const defaultColor = lineColor || common.defaultLineColor

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, setFill })

    const influenceLineStyle = {
        stroke: fill,
        strokeWidth: 2,
        strokeDasharray: "6 4",
        strokeDashoffset: 10, // start with a value equal to sum of stroke-dasharray values
        // 'influence' is defined in index.css
        animation: "influence 0.7s linear infinite", // use infinite to make the animation loop forever
    }

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <defs>
                <marker
                    id='influence-end'
                    markerWidth='7'
                    markerHeight='7'
                    refX='5'
                    refY='2.5'
                    orient='auto'
                >
                    <path d='M0 0, 5 2.5, 0 5' stroke='#000' fill='none' />
                </marker>
            </defs>
            <line
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                stroke={fill}
                strokeWidth='2'
                strokeDasharray='6 4'
                markerEnd={markerEnd}
                style={influenceLineStyle}
            />
        </Svg>
    )
}

export default Influence
