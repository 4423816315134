import React from "react"
import FormControl from "@mui/material/FormControl"
import { TextField, Typography } from "@mui/material"
import { InputAdornment } from "@mui/material"

export default function Readonly({
  name,
  label,
  value,
  helperText,
  icon,
  variant = "outlined",
  size = "small",
  sx = {},
}) {
  const helperTextId = name + "_helper_text"

  const inputProps = icon
    ? {
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">{icon}</InputAdornment>
          ),
        },
      }
    : {}

  return (
    <FormControl>
      <TextField
        name={name}
        variant={variant}
        id={name}
        size={size}
        sx={sx}
        label={label}
        {...inputProps}
        value={value}
        disabled={true}
        aria-describedby={helperTextId}
        helperText={
          <Typography variant="caption" component={"span"}>
            {helperText}
          </Typography>
        }
      />
    </FormControl>
  )
}
