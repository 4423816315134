import React, { useState } from "react"
import Svg from "./Svg"
import { useFill } from "./useFill"
import * as common from "./common"

const Association = (props) => {
    const { x1, y1, x2, y2, highlight, lineColor } = props

    const hoverColor = common.defaultLineHighlightColor
    const defaultColor = lineColor || common.defaultLineColor

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, setFill })

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={fill} strokeWidth='2' />
        </Svg>
    )
}

export default Association
