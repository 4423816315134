import React from "react"
import { FormControlLabel, Tooltip, Switch, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { selectShowTooltipsIndicator } from "../redux/selectors"
import { setShowTooltipsIndicator } from "../redux/actions"

const TooltipsIndicator = (props) => {
    const dispatch = useDispatch()

    const show = useSelector(selectShowTooltipsIndicator)

    return (
        <Tooltip title="Show element description on hover">
            <FormControlLabel
                sx={{ marginLeft: "10px" }}
                control={
                    <Switch
                        size="small"
                        checked={show}
                        onChange={() => {
                            dispatch(setShowTooltipsIndicator(!show))
                        }}
                    />
                }
                label={
                    <Typography variant="caption" color="textSecondary" component={"span"}>
                        Tooltips
                    </Typography>
                }
            />
        </Tooltip>
    )
}

export default TooltipsIndicator
