import _ from "lodash"

const parseTags = (value) => {
    const tags = value
        .split(",")
        .map((val) => val.trim())
        .filter((item) => item !== "")

    const enteredTags = tags.map((tagValue) => {
        let label = tagValue
        let type = ""
        if (label.indexOf(":") !== -1) {
            label = tagValue.split(":")[1].trim()
            type = tagValue.split(":")[0].trim()
        }
        return { type, label }
    })

    const uniqueTags = _.uniqBy(enteredTags, (tag) => tag.type + tag.label)

    return uniqueTags
}

/**
 * Convert tag objects with a type + label attribute into a single uppercase string separated by an = sign, so
 * that we can store this as an array instead of array of objects in Firestore and thereby search on it.
 *
 * We also store all types by themselves as '<TYPE>=', and also all values as '=<LABEL>'
 * @param {*} tags [{ type: "X", label: "Y" }, { type: "X", label: "Z" }]
 * @returns ["X=Y", "X=Z", 'X=', '=Y', '=Z']
 */
const toStringArray = (tags) => {
    const full = tags.map((tag) => `${tag.type.toUpperCase()}=${tag.label.toUpperCase()}`)
    const types = tags.map((tag) => `${tag.type.toUpperCase()}=`)
    const labels = tags.map((tag) => `=${tag.label.toUpperCase()}`)

    return Array.from(new Set([...full, ...types, ...labels]))
}

const toSearchExpression = (tags) => {
    const result = tags.map((tag) => {
        if (tag.type === "") {
            return `=${tag.label.toUpperCase()}`
        } else if (tag.label === "") {
            return `${tag.type.toUpperCase()}=`
        } else {
            return `${tag.type.toUpperCase()}=${tag.label.toUpperCase()}`
        }
    })

    return result
}

const mergeTags = (existingTags, enteredTags) => {
    const newTags = enteredTags.filter((newTag) => {
        const exists =
            existingTags.find((tag) => tag.label === newTag.label && tag.type === newTag.type) !==
            undefined

        return !exists
    })

    const updatedTags = [...existingTags, ...newTags]
    return updatedTags
}

export { parseTags, mergeTags, toStringArray, toSearchExpression }
