import { Alert, Box, Paper, Stack, Tooltip, Typography } from "@mui/material"
import { formatAmount } from "../pages/services/formatting"
import {
  getStripeTaxRates,
  AIM_AI_PRODUCT,
  AIM_PRO_PRODUCT,
} from "../pages/services/stripeServices"
import { useEffect, useMemo, useState } from "react"
import Controls from "./controls/Controls"
import { spacing } from "../pages/services/styleServices"
const styles = {
  pageContent: {
    marginTop: spacing(1),
    marginLeft: spacing(0),
    marginRight: spacing(1),
    padding: spacing(1),
  },
}

const StripeSubscriptionQuantities = ({
  accountId,
  userCountTotal,
  userCountWithAimAIRole,
  aimAiRoleSeatsInStripe,
  products,
  prices,
  stripeAccountInfo,
  handleCreateSubscription,
  handleCancelSubscription,
  handleRestartSubscription,
  handleUpdateSubscription,
  isCardDefined,
}) => {
  const [taxRates, setTaxRates] = useState([])

  useEffect(() => {
    getStripeTaxRates().then((result) => {
      setTaxRates(result)
    })
  }, [])

  const [quantities, setQuantities] = useState([])

  const totalQtyCount = useMemo(
    () => quantities.reduce((acc, curr) => acc + curr.qty, 0),
    [quantities]
  )

  const hasDefaultPaymentMethod = useMemo(() => {
    return (
      stripeAccountInfo?.customer?.invoice_settings?.default_payment_method !==
      null
    )
  }, [stripeAccountInfo])

  useEffect(() => {
    console.log("products", products)

    const defaultQuantities = products.map((product) => ({
      productId: product.id,
      productName: product.name,
      qty: product.name === AIM_AI_PRODUCT ? 1 : 0,
      total: 0,
    }))

    console.log(
      "%csetting default quantities",
      "color:lightGreen",
      defaultQuantities
    )
    setQuantities(defaultQuantities)
  }, [products])

  const getSubscriptionQuantities = ({ stripeAccountInfo }) =>
    stripeAccountInfo.customer.subscriptions.flatMap((s) =>
      s.items.data.map((item) => ({
        productId: item.price.product,
        qty: item.quantity,
        total: item.quantity * item.price.unit_amount,
      }))
    )

  // Check if licenses are adequate
  const [userLicenseMessage, setUserLicenseMessage] = useState(undefined)

  const calcUserLicenseMessage = ({
    quantities,
    products,
    userCountTotal,
    stripeAccountInfo,
    userCountWithAimAIRole,
    aimAiRoleSeatsInStripe,
  }) => {
    if (stripeAccountInfo?.customer?.subscriptions.length === 0) {
      setUserLicenseMessage({
        msg: "Create a subscription. This will charge your payment method monthly. You can cancel at any time.",
        severity: "info",
        // Is the user allowed to change subscription quantities?
        changeSubscription: true,
      })
      return
    }

    if (
      userCountTotal !== undefined &&
      userCountWithAimAIRole !== undefined &&
      products &&
      quantities &&
      stripeAccountInfo
    ) {
      const totalLicenses = quantities.reduce((acc, curr) => acc + curr.qty, 0)

      const aimAiProduct = products.find((p) => p.name === AIM_AI_PRODUCT)
      const aimProProduct = products.find((p) => p.name === AIM_PRO_PRODUCT)

      const subAimAIQty =
        quantities.find((item) => item.productId === aimAiProduct?.id)?.qty || 0
      const subAimProQty =
        quantities.find((item) => item.productId === aimProProduct?.id)?.qty ||
        0

      if (
        stripeAccountInfo?.customer?.invoice_settings
          ?.default_payment_method === null
      ) {
        setUserLicenseMessage({
          msg: "Add a default Payment Method before creating a Subscription.",
          severity: "warning",
          // Is the user allowed to change subscription quantities?
          changeSubscription: false,
        })
        return
      }

      if (subAimAIQty < userCountWithAimAIRole) {
        // Indicate how many more licenses are required for AIM AI users
        const licensesRequired = userCountWithAimAIRole - subAimAIQty
        setUserLicenseMessage({
          msg: `You need ${licensesRequired} more AIM AI license${
            licensesRequired > 1 ? "s" : ""
          } to cover all users with an 'AIM AI' role.`,
          severity: "warning",
          // Is the user allowed to change subscription quantities?
          changeSubscription: false,
        })
        return
      }

      if (
        subAimAIQty + subAimProQty === userCountTotal &&
        aimAiRoleSeatsInStripe !== subAimAIQty
      ) {
        setUserLicenseMessage({
          msg: `Licenses match the number of users and their roles.`,
          severity: "success",
          // Is the user allowed to change subscription quantities?
          changeSubscription: true,
        })
        return
      }

      if (subAimAIQty > userCountWithAimAIRole) {
        // Indicate license excess
        const licensesExcess = subAimAIQty - userCountWithAimAIRole
        setUserLicenseMessage({
          msg: `Allocate ${licensesExcess} AIM AI license${
            licensesExcess > 1 ? "s" : ""
          }: Open Config > Users and allocate ${licensesExcess} user${
            licensesExcess > 1 ? "s" : ""
          } to have the AIM AI role, so that they can use AI features.`,
          severity: "warning",
          // Is the user allowed to change subscription quantities?
          changeSubscription: true,
        })

        return
      }

      if (totalLicenses < userCountTotal) {
        // Indicate how many more licenses are required in total

        console.log(
          "totalLicenses",
          totalLicenses,
          "userCountTotal",
          userCountTotal
        )

        const licensesRequired = userCountTotal - totalLicenses
        setUserLicenseMessage({
          msg: `You need ${licensesRequired} more license${
            licensesRequired > 1 ? "s" : ""
          } (AIM AI and/or AIM Pro) to cover all users ${userCountTotal} in your organisation.`,
          severity: "warning",
          // Is the user allowed to change subscription quantities?
          changeSubscription: false,
        })
        return
      }

      // Check if total licenses is more than required
      // Indicate license excess
      if (totalLicenses > userCountTotal) {
        const licensesExcess = totalLicenses - userCountTotal
        setUserLicenseMessage({
          msg: `You have ${licensesExcess} more license${
            licensesExcess > 1 ? "s" : ""
          } than required. You should allocate these to users or reduce your license count to reduce your licensing cost.`,
          severity: "info",
          // Is the user allowed to change subscription quantities?
          changeSubscription: true,
        })
        return
      }

      if (subAimAIQty + subAimProQty < userCountTotal) {
        setUserLicenseMessage({
          msg: `You have ${userCountTotal} users, and so AIM AI plus AIM Pro user counts need to add to ${userCountTotal} licenses.`,
          severity: "warning",
          // Is the user allowed to change subscription quantities?
          changeSubscription: false,
        })
        return
      }

      if (subAimAIQty + subAimProQty === userCountTotal) {
        setUserLicenseMessage({
          msg: "Licenses match the number of users and their roles.",
          severity: "success",
          // Is the user allowed to change subscription quantities?
          changeSubscription: true,
        })
        return
      }

      console.log("no message shown")
    } else if (!stripeAccountInfo) {
      if (!isCardDefined) {
        setUserLicenseMessage({
          msg: `Create a card on the 'Cards' tab before creating a Subscription.`,
          severity: "info",
        })
      } else {
        setUserLicenseMessage({
          msg: "Create a subscription.",
          severity: "info",
        })
      }
    } else {
      console.log("not all data available to calcUserLicenseMessage", {
        userCountTotal,
        userCountWithAimAIRole,
        products,
        quantities,
        stripeAccountInfo,
      })
    }
  }

  useEffect(() => {
    calcUserLicenseMessage({
      quantities,
      products,
      userCountTotal,
      stripeAccountInfo,
      userCountWithAimAIRole,
      aimAiRoleSeatsInStripe,
    })
  }, [
    quantities,
    products,
    userCountTotal,
    stripeAccountInfo,
    userCountWithAimAIRole,
    aimAiRoleSeatsInStripe,
  ])

  const totalCost = useMemo(
    () => quantities.reduce((acc, curr) => acc + curr.total, 0),
    [quantities]
  )

  const gst = useMemo(() => {
    return taxRates?.find((tr) => tr.display_name === "GST")
  }, [taxRates])

  const taxLabel = useMemo(() => {
    return gst
      ? `plus ${gst?.percentage}% ${gst?.display_name} in Australia`
      : ""
  }, [gst])

  useEffect(() => {
    if (stripeAccountInfo?.customer) {
      const subQuantities = getSubscriptionQuantities({ stripeAccountInfo })

      // Override the 'qty' attribute in 'quantities' for each product

      setQuantities((curr) => {
        const newQuantities = curr.map((item) => {
          const subQty = subQuantities.find(
            (subItem) => subItem.productId === item.productId
          )
          if (subQty) {
            return {
              ...item,
              qty: subQty.qty,
              total: subQty.total,
            }
          } else {
            return item
          }
        })
        return newQuantities
      })
    }
  }, [stripeAccountInfo])

  return (
    <Paper sx={styles.pageContent}>
      <Stack direction="column" gap={2}>
        <Stack>
          <Typography
            variant="h6"
            component={"span"}
            gutterBottom={false}
            paragraph={false}
          >
            Change Subscription Quantities
          </Typography>
          {!stripeAccountInfo?.sub && (
            <>
              <Typography variant="caption" color="text.secondary">
                Enter your required user count for AIM AI (can use OpenAI
                features), or AIM Pro.
              </Typography>
              <Typography variant="caption" color="text.secondary">
                To upgrade a user from AIM Pro to AIM AI, decrease AIM Pro users
                by 1, and increase AIM AI users by 1, then click Update
                Subscription which will prorate your invoice charges.
              </Typography>
            </>
          )}
        </Stack>

        <Stack gap={2}>
          {products &&
            prices &&
            products.map((product, index) => {
              const price = prices.find((pr) => pr.product === product.id)

              return (
                <Box key={product.id}>
                  <Stack direction={"row"} gap={2}>
                    <Box
                      sx={{
                        width: "300px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        {product.name}
                      </Typography>
                      <Typography>{`${formatAmount(
                        price.unit_amount
                      )} ${price.currency.toUpperCase()} / user / month ex tax `}</Typography>

                      <Typography variant="caption" color="text.secondary">
                        {product.description}
                      </Typography>
                      {taxLabel && (
                        <Typography variant="caption" color="text.secondary">
                          {taxLabel}
                        </Typography>
                      )}
                    </Box>
                    <Box sx={{ width: "60px", marginTop: "10px" }}>
                      <Controls.TextInput
                        name={product.id}
                        label="Users"
                        value={
                          quantities.find(
                            (item) => item.productId === product.id
                          )?.qty || ""
                        }
                        onChange={(e) => {
                          console.log("e.target.value", e.target.value)
                          // Check value is a whole number, not a fraction, and does not contain letters

                          const isInteger = /^\d+$/.test(e.target.value)
                          if (!isInteger && e.target.value !== "") {
                            return
                          }

                          // convert value to numeric type
                          const qty = parseInt(e.target.value)

                          setQuantities((curr) => {
                            const result = curr.map((item) => {
                              if (item.productId === product.id) {
                                return {
                                  ...item,
                                  qty: qty,
                                  total: (qty || 0) * price.unit_amount,
                                }
                              } else {
                                return item
                              }
                            })

                            console.log(
                              "%cnew quantities",
                              "color:lightGreen",
                              result
                            )

                            return result
                          })
                        }}
                      />
                    </Box>
                    <Box sx={{ width: "170px", marginTop: "10px" }}>
                      <Controls.Readonly
                        name="item_total"
                        label="Item Total Ex. Tax"
                        value={`${formatAmount(
                          quantities.find(
                            (item) => item.productId === product.id
                          )?.total || 0
                        )} ${price.currency.toUpperCase()} / month`}
                      />
                    </Box>
                  </Stack>
                </Box>
              )
            })}
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ width: "390px" }}></Box>
            <Box sx={{ maxWidth: "170px" }}>
              {prices && prices[0] && (
                <Controls.Readonly
                  name="total"
                  label="Total Ex. Tax"
                  value={`${formatAmount(
                    totalCost
                  )} ${prices[0].currency.toUpperCase()} / month`}
                />
              )}
              <Typography variant="caption">{taxLabel}</Typography>
            </Box>
          </Box>
          <Box>
            {userLicenseMessage && (
              <Alert severity={userLicenseMessage.severity}>
                {userLicenseMessage.msg}
              </Alert>
            )}
          </Box>
        </Stack>
        <SubscriptionActions
          canChangeSubscription={userLicenseMessage?.changeSubscription}
          canCreateSubscription={
            totalQtyCount >= userCountTotal && hasDefaultPaymentMethod
          }
          sub={stripeAccountInfo?.customer?.subscriptions[0]}
          handleCancelSubscription={handleCancelSubscription}
          handleRestartSubscription={handleRestartSubscription}
          handleCreateSubscription={() => {
            if (totalQtyCount > 0) {
              handleCreateSubscription({ accountId, quantities })
            }
          }}
          handleUpdateSubscription={() =>
            handleUpdateSubscription(
              stripeAccountInfo.customer.subscriptions[0].id,
              quantities
            )
          }
        />
      </Stack>
    </Paper>
  )
}

const SubscriptionActions = ({
  canChangeSubscription,
  canCreateSubscription,
  sub,
  handleCancelSubscription,
  handleRestartSubscription,
  handleUpdateSubscription,
  handleCreateSubscription,
}) => {
  useEffect(() => {
    console.log("sub actions", { sub })
  }, [sub])
  return (
    <Stack gap={1} alignItems={"flex-end"}>
      {!sub && (
        <Controls.Button
          text="Create Subscription"
          variant="contained"
          tooltip="Create a new monthly subscription which will charge your payment method"
          onClick={handleCreateSubscription}
          disabled={!canCreateSubscription}
        />
      )}
      {sub && (
        <>
          {sub.cancel_at_period_end && (
            <Box sx={{ width: "100%" }}>
              <Alert severity="warning" sx={{ marginTop: "5px" }}>
                This subscription will be cancelled at period end and no further
                invoices produced.
              </Alert>
            </Box>
          )}
          {!sub.cancel_at_period_end && (
            <Alert
              severity="info"
              sx={{ marginTop: "15px", marginBottom: "15px" }}
            >
              If you would like to Cancel your subscription, it will cancel at
              end of month and no further payments/invoices will be processed.
              If you cancel, you can also undo this cancellation / re-start the
              subscription before end of month.
            </Alert>
          )}

          <Stack direction="row" gap={2} sx={{ mt: "20px" }}>
            {!sub.cancel_at_period_end && (
              <Controls.Button
                text="Cancel Subscription"
                onClick={() => handleCancelSubscription(sub.id)}
              />
            )}
            <Tooltip title="Update monthly subscription payments">
              <span>
                <Controls.Button
                  text="Save Subscription"
                  variant={sub.cancel_at_period_end ? "outlined" : "contained"}
                  onClick={() => handleUpdateSubscription(sub.id)}
                  disabled={!canChangeSubscription}
                />
              </span>
            </Tooltip>
            {sub.cancel_at_period_end && (
              <Controls.Button
                text="Restart Subscription"
                tooltip="Restart monthly subscription payments"
                onClick={() => handleRestartSubscription(sub.id)}
                variant={sub.cancel_at_period_end ? "contained" : "outlined"}
              />
            )}
          </Stack>
        </>
      )}
    </Stack>
  )
}

export default StripeSubscriptionQuantities
