import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material"
import { createRef } from "react"
import Controls from "./controls/Controls"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import { Form } from "./useForm"
import * as xlsx from "xlsx"
import { useState } from "react"
import { useMemo } from "react"
import DraggablePaper from "../components/DraggablePaper"
import {
  NAME_COL,
  TYPE_COL,
  PARENT_COL,
  DESCRIPTION_COL,
  getWorksheetInfo,
} from "../pages/services/modelEditServices"
import * as palette from "./symbols/palette"

const ModelEditImportFile = ({ open, onClose, handleImportData }) => {
  const addFileRef = createRef()

  const [workbook, setWorkbook] = useState(null)

  // This field is set when the Excel sheet does not contain the normally expected headings
  // and the user may want to specify 1 or more columns to use as the parent/child hierarchy
  // e.g. selecting 3 headings means a 3-level hierarchy
  const [hierarchyCols, setHierarchyCols] = useState([])

  // This is the corresponding set of element types for the hierarchy columns
  // Must be the same number for each
  // Only able to be specified if the worksheet does not contain the normally expected headings
  const [hierarchyTypes, setHierarchyTypes] = useState([])

  const DESELECTED = -1

  const [selectedIndex, setSelectedIndex] = useState(DESELECTED)

  const worksheetInfo = useMemo(() => {
    if (workbook && selectedIndex !== DESELECTED) {
      const worksheetInfo = getWorksheetInfo(
        workbook,
        workbook.SheetNames[selectedIndex],
        true
      )
      console.log("%cworksheetInfo", "color:lightgreen", worksheetInfo)
      return worksheetInfo
    }
  }, [workbook, selectedIndex])

  const expectedHeadings = [NAME_COL, TYPE_COL, PARENT_COL, DESCRIPTION_COL]

  const sheetInfo = useMemo(() => {
    return (
      workbook &&
      workbook.SheetNames.map((name, index) => {
        const wsCells = workbook.Sheets[name]

        // Get 1st row of worksheet

        const headings = Object.keys(wsCells)
          .filter((key) => key.match(/[A-Z]+1$/))
          .map((key) => wsCells[key].v)

        // See if 1st row has any of the expected headings

        const hasSomeHeadings = headings.some((value) =>
          expectedHeadings.includes(value)
        )

        const hasAllHeadings = expectedHeadings.every((value) =>
          headings.includes(value)
        )

        const missingHeadings = expectedHeadings
          .filter((value) => !headings.includes(value))
          .join(", ")

        const headingsInfo = hasAllHeadings
          ? "Has all headings"
          : `Missing headings - ${missingHeadings}`

        // Get number of rows in wsCells where the key is in the format A1, B1, C1, etc.
        // and the number after the letter represents the maxium row number in the worksheet

        const maxRows =
          Object.keys(wsCells)
            .filter((key) => key.match(/[A-Z]+[0-9]+/))
            .map((key) => parseInt(key.match(/[0-9]+/)[0]))
            .reduce((a, b) => Math.max(a, b), 0) - (hasSomeHeadings ? 1 : 0)

        return {
          name,
          headings,
          headingsInfo,
          maxRows,
          hasSomeHeadings,
          hasAllHeadings,
        }
      })
    )
  }, [workbook])

  const handleSelectFile = (event) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result)
      const workbook = xlsx.read(data, { type: "array" })
      console.log(workbook)
      setWorkbook(workbook)

      event.target.files = null
    }
    console.log("reading", event.target.files[0])
    reader.readAsArrayBuffer(event.target.files[0])
  }

  const sheetName = useMemo(
    () =>
      selectedIndex !== DESELECTED && workbook
        ? workbook.SheetNames[selectedIndex]
        : null,
    [selectedIndex, workbook, DESELECTED]
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      PaperComponent={DraggablePaper}
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        Import File
      </DialogTitle>
      <DialogContent>
        <Form>
          <input
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            style={{ display: "none" }}
            ref={addFileRef}
            multiple
            id="icon-button-file"
            type="file"
            onChange={(e) => handleSelectFile(e)}
          />

          <Controls.Button
            text="Upload Files"
            type="button"
            onClick={() => addFileRef.current.click()}
            endIcon={<FileUploadIcon />}
          />

          <List
            dense
            component="nav"
            sx={{ display: "flex", flexDirection: "column", gap: 0 }}
          >
            {sheetInfo &&
              sheetInfo.map((info, index) => {
                return (
                  <Box key={info.name}>
                    <ListItemButton
                      selected={selectedIndex === index}
                      onClick={() => {
                        setSelectedIndex((curr) =>
                          curr === index ? DESELECTED : index
                        )
                        setHierarchyCols([]) // Reset the hierarchy columns
                        setHierarchyTypes([]) // Reset the hierarchy types
                      }}
                      sx={{ margin: 0, padding: 0 }}
                    >
                      <ListItemText
                        primary={
                          <Typography sx={{ fontWeight: "bold" }}>
                            {index + 1}. {info.name}
                          </Typography>
                        }
                        secondary={
                          <Typography variant="caption" color="text.secondary">
                            {info.maxRows} rows. {info.headingsInfo}
                          </Typography>
                        }
                        sx={{
                          marginLeft: "10px",
                          marginRight: "10px",
                        }}
                      />
                    </ListItemButton>
                    {selectedIndex === index && !info.hasAllHeadings && (
                      <SelectHierarchyHeadings
                        headings={info.headings}
                        hierarchCols={hierarchyCols}
                        setHierarchCols={setHierarchyCols}
                        hierarchyTypes={hierarchyTypes}
                        setHierarchyTypes={setHierarchyTypes}
                      />
                    )}
                  </Box>
                )
              })}
          </List>
        </Form>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginLeft: "20px",
          }}
        >
          {worksheetInfo &&
            worksheetInfo.props.map((p, index) => (
              <Box key={`key-${p.name}`}>
                <Box>
                  <Typography variant="caption" color="text.primary">
                    {index + 1}. {p.name}
                  </Typography>
                </Box>
                <Box sx={{ marginLeft: "10px" }}>
                  {p.values.map((value) => (
                    <Box key={`value-${value}`}>
                      <Typography variant="caption" color="text.secondary">
                        - {value}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
            setSelectedIndex(DESELECTED)
            setWorkbook(null)
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onClose()
            handleImportData(workbook, sheetName, hierarchyCols, hierarchyTypes)
            setSelectedIndex(DESELECTED)
            setWorkbook(null)
          }}
          color="primary"
          disabled={selectedIndex === -1 || !workbook}
        >
          Import
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const SelectHierarchyHeadings = (props) => {
  const {
    headings,
    hierarchCols,
    setHierarchCols,
    hierarchyTypes,
    setHierarchyTypes,
  } = props

  const elementTypes = palette.getElementTypeNames()

  return (
    <>
      <Autocomplete
        multiple
        id="hierarchy_headings"
        options={headings}
        size="small"
        value={hierarchCols}
        onChange={(e, newValue) => {
          setHierarchCols(newValue)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Hierarchy"
            variant="outlined"
            size="small"
            sx={{ maxWidth: "300px" }}
          />
        )}
      />
      <Autocomplete
        multiple
        id="hierarchy_types"
        options={elementTypes}
        size="small"
        value={hierarchyTypes}
        onChange={(e, newValue) => {
          setHierarchyTypes(newValue)
        }}
        getOptionLabel={(option) => palette.formatLabel(option)}
        isOptionEqualToValue={() => false}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Element Types"
            variant="outlined"
            size="small"
            sx={{ maxWidth: "300px" }}
          />
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              size="small"
              label={palette.formatLabel(option)}
              {...getTagProps({ index })}
              sx={{ backgroundColor: palette.getElementLayerColor(option) }}
            />
          ))
        }
      />
    </>
  )
}

export default ModelEditImportFile
