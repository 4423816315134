/**
 * Handles the change event when a heading is dragged and dropped.
 * Determines whether to indent, outdent, or reorder based on drag direction.
 */
const handleChange = ({ source, target, headings, setHeadings }) => {
  console.log("Move:", source, "->", target)

  const THRESHOLD = 20
  const isOutdent =
    source.level === target.level && source.x_pos - target.x_pos > THRESHOLD
  const isIndent =
    source.level === target.level && target.x_pos - source.x_pos > THRESHOLD

  // Step 1: Assign indent levels based on current 'level' strings
  const headingsWithIndent = assignIndentLevels(headings)

  if (isIndent) {
    console.log("Action: Indenting")
    if (canIndent(source, headingsWithIndent)) {
      handleIndent(source, headingsWithIndent)
      // Step 3: Renumber the 'level' values based on updated indent levels
      const renumberedHeadings = renumberLevels(headingsWithIndent)
      // Update the state with the renumbered headings
      setHeadings(renumberedHeadings)
    } else {
      console.log(
        "Cannot indent: Indentation would cause duplicate levels or invalid hierarchy."
      )
      // Do not proceed with renumbering
      return
    }
  } else if (isOutdent) {
    console.log("Action: Outdenting")
    handleOutdent(source, headingsWithIndent)
    // Step 3: Renumber the 'level' values based on updated indent levels
    const renumberedHeadings = renumberLevels(headingsWithIndent)
    // Update the state with the renumbered headings
    setHeadings(renumberedHeadings)
  } else {
    console.log("Action: Reordering")
    handleReorder(source, target, headingsWithIndent)
    // Step 3: Renumber the 'level' values based on updated indent levels
    const renumberedHeadings = renumberLevels(headingsWithIndent)
    // Update the state with the renumbered headings
    setHeadings(renumberedHeadings)
  }
}

/**
 * Assigns an indent level to each heading based on its 'level' string.
 * Top-level headings have indentLevel 0.
 * Child headings have indentLevel one greater than their parent.
 * @param {Array} headings - The array of heading objects.
 * @returns {Array} - The array with assigned indentLevel.
 */
const assignIndentLevels = (headings) => {
  return headings.map((heading) => ({
    ...heading,
    indentLevel: heading.level.split(".").length - 1,
  }))
}

/**
 * Determines if a heading can be indented without causing duplicate levels or invalid hierarchy.
 * Specifically, prevents indenting a heading if there is no valid parent heading for the new indent level.
 * @param {Object} source - The source heading object.
 * @param {Array} headings - The array of heading objects with indentLevel.
 * @returns {boolean} - True if indenting is allowed, false otherwise.
 */
const canIndent = (source, headings) => {
  // Find the index of the source heading
  const index = headings.findIndex((h) => h.level === source.level)
  if (index === -1) {
    console.error("Source heading not found.")
    return false
  }

  const sourceHeading = headings[index]
  const desiredIndentLevel = sourceHeading.indentLevel + 1

  const parentHeading = headings[index - 1]

  // parent heading level should be equal to source heading to enable indenting
  const hasValidParent =
    parentHeading.indentLevel === sourceHeading.indentLevel ||
    sourceHeading.indentLevel === parentHeading.indentLevel - 1

  if (!hasValidParent) {
    // No valid parent exists for the new indent level
    return false
  }

  // Additionally, check if the new level would cause a duplicate
  // Simulate the new level assignment and check for duplicates
  const potentialNewLevel = getPotentialNewLevel(
    headings,
    index,
    desiredIndentLevel
  )
  if (headings.some((h) => h.level === potentialNewLevel)) {
    // A heading with the potential new level already exists
    return false
  }

  return true
}

/**
 * Generates the potential new 'level' string for a heading being indented.
 * This function simulates what the 'level' would be after indentation.
 * @param {Array} headings - The array of heading objects with indentLevel.
 * @param {number} index - The index of the heading being indented.
 * @param {number} desiredIndentLevel - The desired indent level after indentation.
 * @returns {string} - The potential new 'level' string.
 */
const getPotentialNewLevel = (headings, index, desiredIndentLevel) => {
  // Find the last heading before the current one with indentLevel = desiredIndentLevel - 1
  let parentIndex = -1
  for (let i = index - 1; i >= 0; i--) {
    if (headings[i].indentLevel === desiredIndentLevel - 1) {
      parentIndex = i
      break
    }
  }

  if (parentIndex === -1) {
    // No valid parent found; this should not happen as 'canIndent' already checked
    return ""
  }

  const parentLevel = headings[parentIndex].level
  // Count how many existing children the parent has to determine the next number
  const existingChildren = headings.filter(
    (h, idx) =>
      h.level.startsWith(`${parentLevel}.`) &&
      h.indentLevel === desiredIndentLevel &&
      idx < index
  )

  const nextNumber = existingChildren.length + 1
  return `${parentLevel}.${nextNumber}`
}

/**
 * Handles indenting a heading by incrementing its indentLevel.
 * @param {Object} source - The source heading object.
 * @param {Array} headings - The array of heading objects with indentLevel.
 */
const handleIndent = (source, headings) => {
  // Find the index of the source heading
  const index = headings.findIndex((h) => h.level === source.level)
  if (index === -1) {
    console.error("Source heading not found.")
    return
  }

  // Increment the indentLevel
  headings[index].indentLevel += 1
}

/**
 * Handles outdenting a heading by decrementing its indentLevel.
 * Ensures that indentLevel does not go below 0.
 * @param {Object} source - The source heading object.
 * @param {Array} headings - The array of heading objects with indentLevel.
 */
const handleOutdent = (source, headings) => {
  // Find the index of the source heading
  const index = headings.findIndex((h) => h.level === source.level)
  if (index === -1) {
    console.error("Source heading not found.")
    return
  }

  // Decrement the indentLevel, ensuring it doesn't go below 0
  headings[index].indentLevel = Math.max(0, headings[index].indentLevel - 1)
}

/**
 * Handles reordering a heading by moving it to the target's position.
 * This function updates the order of headings accordingly without altering indentLevels.
 * @param {Object} source - The source heading object.
 * @param {Object} target - The target heading object.
 * @param {Array} headings - The array of heading objects with indentLevel.
 */
const handleReorder = (source, target, headings) => {
  // Find the indices of source and target headings
  const sourceIndex = headings.findIndex((h) => h.level === source.level)
  const targetIndex = headings.findIndex((h) => h.level === target.level)

  if (sourceIndex === -1 || targetIndex === -1) {
    console.error("Source or target heading not found.")
    return
  }

  // Remove the source heading from its current position
  const [sourceHeading] = headings.splice(sourceIndex, 1)

  // Adjust targetIndex if sourceIndex was before targetIndex
  let adjustedTargetIndex = targetIndex
  if (sourceIndex < targetIndex) {
    adjustedTargetIndex -= 1
  }

  // Insert the source heading after the target
  headings.splice(adjustedTargetIndex + 1, 0, sourceHeading)
}

/**
 * Renumbers the 'level' values based on indentLevel.
 * Ensures hierarchical integrity where child headings follow their parents.
 * If a heading cannot be properly placed under a parent, it assigns the next available top-level number.
 * @param {Array} headings - The array of heading objects with indentLevel.
 * @returns {Array} - The array with updated 'level' values.
 */
const renumberLevels = (headings) => {
  const counters = [] // Array to keep track of numbering at each indent level
  const renumberedHeadings = headings.map((heading) => {
    const indent = heading.indentLevel

    // Initialize counters up to the current indent level
    while (counters.length <= indent) {
      counters.push(0)
    }

    // Increment the counter for the current indent level
    counters[indent] += 1

    // Reset counters for deeper levels
    for (let i = indent + 1; i < counters.length; i++) {
      counters[i] = 0
    }

    // Construct the new level string based on counters
    const newLevel = counters
      .slice(0, indent + 1)
      .filter((count) => count > 0)
      .join(".")

    return {
      ...heading,
      level: newLevel,
    }
  })

  return renumberedHeadings
}

export { handleChange }
