import React, { useState } from "react"
import * as common from "./common"
import { deepPurple } from "@mui/material/colors"
import BaseShapeOctagon from "./BaseShapeOctagon"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const Stakeholder = (props) => {
    const { x, y, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = deepPurple[200]
    const defaultColor = fillColor || deepPurple[100]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    const roleHeight = 5

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <BaseShapeOctagon {...props} fill={fill} setFill={handleFill} />

            <svg x={x + width - 25} y={y + 5} width={6} height={20}>
                <ellipse
                    cx={5}
                    cy={8}
                    rx={4}
                    ry={roleHeight}
                    fill='none'
                    stroke={common.outlineColor}
                />
            </svg>
            <svg x={x + width - 25} y={y + 5} width={20} height={20}>
                <ellipse
                    cx={15}
                    cy={8}
                    rx={4}
                    ry={roleHeight}
                    fill='none'
                    stroke={common.outlineColor}
                />
                <line x1={4} y1={3} x2={15} y2={3} stroke={common.outlineColor} />
                <line x1={4} y1={13} x2={15} y2={13} stroke={common.outlineColor} />
            </svg>

            <ElementText {...props} label={label} x={props.x + 3} />
        </Svg>
    )
}

export default Stakeholder
