import React, { useState } from "react"
import { pink } from "@mui/material/colors"
import BasicShapeWavyBox from "./BasicShapeWavyBox"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const Deliverable = (props) => {
    const { label, highlight, highlightColor, fillColor } = props

    const hoverColor = pink[100]
    const defaultColor = fillColor || pink[50]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <BasicShapeWavyBox {...props} fill={fill} />

            <ElementText {...props} label={label} />
        </Svg>
    )
}

export default Deliverable
