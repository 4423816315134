import React, { useState } from "react"
import Header from "../components/Header"
import { Paper } from "@mui/material"
import RuleEditForm from "../components/RuleEditForm"
import { spacing } from "./services/styleServices"

const styles = {
    pageContent: {
        margin: spacing(1),
        padding: spacing(1),
    },
}

function RuleEdit(props) {
    const [title, setTitle] = useState("Edit Rule")

    return (
        <Header title={title}>
            <Paper sx={styles.pageContent}>
                <RuleEditForm {...props} setTitle={setTitle} />
            </Paper>
        </Header>
    )
}

export default RuleEdit
