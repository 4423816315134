import React, { useEffect, useState } from "react"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import {
  Avatar,
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Dialog,
  DialogTitle,
} from "@mui/material"
import Controls from "./controls/Controls"
import DeleteIcon from "@mui/icons-material/Delete"
import SaveIcon from "@mui/icons-material/Save"
import { useForm, Form } from "./useForm"
import { useSnackbar } from "notistack"
import firebase from "firebase/compat/app"
import * as Icons from "../icons"
import * as Roles from "../pages/services/roleServices"
import * as cloudFunctions from "../pages/services/cloudFunctions"
import YesNo from "./YesNo"
import * as stripeServices from "../pages/services/stripeServices"
import ProgressBackdrop from "./ProgressBackdrop"

const initialValues = () => {
  return {
    email: "",
    name: "",
    type: "client",
    roles: [],
    created: dataServices.localTimestamp(),
  }
}

const InviteList = (props) => {
  const [invites, setInvites] = useState([])

  const COLLECTION_NAME = "invites"

  const { values, setValues, handleInputChange } = useForm(initialValues())

  const [maxCreated, setMaxCreated] = useState()

  const [isShowProgress, setShowProgress] = useState(false)

  const [user, setUser] = useState()

  const [accountId, setAccountId] = useState()

  const { enqueueSnackbar } = useSnackbar()

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Approve Subscription Update",
    description:
      "If the user accepts the invite, your subcription will be modified to include additional 'AIM Pro' licenses if necessary to cover this additional user. Do you approve?",
    openPrompt: false,

    // this method is set when we prompt for deletion
    handleConfirm: null,
  })

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      console.log("### user changed", user)
      setUser(user)
      user.getIdTokenResult(false).then((token) => {
        console.log("### setting account id", token.claims.account_id)
        setAccountId(token.claims.account_id)
      })
    })

    return unsub
  }, [])

  // Listen for invite changes

  useEffect(() => {
    if (user === undefined || accountId === undefined) {
      return
    }

    const unsubscribe = db
      .collection(COLLECTION_NAME)
      .where("account_id", "==", accountId)
      .onSnapshot((querySnapshot) => {
        let newMaxCreated = null

        querySnapshot.docChanges().forEach((change) => {
          if (
            newMaxCreated === null ||
            change.doc.data().created.seconds > newMaxCreated.seconds
          ) {
            //console.log('=== max created ===', change.doc.id, change.doc.data().email, change.doc.data().created)
            newMaxCreated = change.doc.data().created
          }
        })
        if (newMaxCreated !== null) {
          setMaxCreated(newMaxCreated)
        }
      })

    return unsubscribe // gets called on unmount
  }, [user, accountId])

  const handleSelectRole = (event) => {
    console.log("selectRole", event.target.value)

    if (event.target.checked) {
      // Adding
      const newRoles = [...values.roles]
      newRoles.push(event.target.value)
      const newValues = {
        ...values,
        roles: newRoles,
      }
      console.log("newValues", newValues)
      setValues(newValues)
    } else {
      // Removing
      const newRoles = values.roles.filter(
        (roleId) => roleId !== event.target.value
      )
      const newValues = {
        ...values,
        roles: newRoles,
      }
      console.log("newValues", newValues)
      setValues(newValues)
    }
  }

  /**
   * If the user has a subscription then prompt if they are ok to extend the subscription if the user accepts the invite?
   * Otherwise, do not prompt the user
   */
  const handleCheckCreateInvite = async (event) => {
    event.preventDefault()

    // If the user has a subscription then prompt if they are ok to extend the subscription if the user accepts the invite?
    // Otherwise, do not prompt the user

    setShowProgress(true)

    const stripeAccountInfo = await stripeServices.getStripeCustomer(accountId)

    const isBillingSetup = stripeServices.isBillingSetup({
      stripeAccountInfo: stripeAccountInfo,
    })

    console.log("isBillingSetup", isBillingSetup)

    if (isBillingSetup) {
      setYesNoConfig({
        ...yesNoConfig,
        openPrompt: true,
        handleConfirm: handleCreateInvite,
      })
    } else {
      handleCreateInvite()
    }

    setShowProgress(false)
  }

  const handleCreateInvite = (event) => {
    console.log("Create invite")

    if (values.email === "") {
      enqueueSnackbar("Enter email", { variant: "info" })
      return
    }

    if (values.type === "") {
      enqueueSnackbar("Enter type", { variant: "info" })
      return
    }

    // Check invite doesn't already exist
    dataServices
      .findInviteByEmail(values.email, accountId)
      .then((existingInvites) => {
        console.log("existing", existingInvites)

        if (existingInvites.length > 0) {
          enqueueSnackbar("Invite already exists for " + values.email, {
            variant: "info",
          })
        } else {
          console.log("Check if user exists", values.email)

          dataServices
            .getUser(values.email, accountId)
            .then((user) => {
              console.log("user", user)
              if (user) {
                console.log("user already exists - not sending invite")
                enqueueSnackbar("User email exists. Not sending invite", {
                  variant: "info",
                })
              }
            })
            .catch((err) => {
              console.log(err)

              dataServices
                .createInvite(values, accountId)
                .then(sendEmailInvite(values.email, values.type))
                .then(setValues(initialValues()))
            })
        }
      })
  }

  const sendEmailInvite = async (email, type) => {
    const baseMsg = `You have been sent an invite by ${user.email} to join the Architecture In Motion platform. Visit `
    const aimUrl = "https://aim-01.web.app"
    const endMsg =
      ", authenticate your email address, and then accept the invite which will appear once your email address is authenticated."
    const textMsg = `${baseMsg} ${aimUrl} ${endMsg}`
    const htmlMsg = `${baseMsg} <a>${aimUrl}</a> ${endMsg}`
    cloudFunctions
      .sendEmailMessage({
        to: email,
        subject: "Invite to join AIM",
        text: textMsg,
        html: htmlMsg,
      })
      .then(enqueueSnackbar("Sent invite", { variant: "success" }))
      .catch((err) => console.error("Error calling sendEmail", err))
  }

  const handleDelete = (inviteId) => {
    console.log("delete invite", inviteId)

    dataServices.deleteInvite(inviteId)
  }

  // Load invites

  useEffect(() => {
    if (user === undefined || accountId === undefined) {
      return
    }

    console.log("Loading invites", { user, accountId, maxCreated })

    let query = db.collection(COLLECTION_NAME)
    query = dataServices
      .modifyQuery(query, "description", "")
      .where("account_id", "==", accountId)

    dataServices.loadData("(Load invites)", query).then((invites) => {
      // Add missing roles attribute -- which may not exist for historical data
      // This code can probably be removed once all invites have a roles attribute
      const inviteList = invites.map((invite) => {
        return { ...initialValues(), ...invite }
      })

      console.log("invites", inviteList)

      setInvites(inviteList)
    })
  }, [maxCreated, user, accountId])

  const getRoleNames = (roles) => {
    if (roles.length === 0) {
      return "No roles assigned"
    }
    return roles.map((roleId) => Roles.userAssignableRoles[roleId]).join(", ")
  }

  return (
    <>
      <YesNo config={yesNoConfig} />

      <ProgressBackdrop
        open={isShowProgress}
        label={"Checking subscriptions..."}
      />

      <Form>
        <Grid container direction="column">
          <Grid item>
            <Controls.TextInput
              name="email"
              label="Invitee Email"
              value={values.email}
              onChange={handleInputChange}
              icon={<Icons.EmailIcon />}
              autoFocus
            />
          </Grid>

          <Grid item>
            <Controls.TextInput
              name="name"
              label="Invitee Name"
              value={values.name}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>

        <Grid item>
          <FormControl component="fieldset">
            <FormLabel component="legend">Assign user role(s)</FormLabel>
            <FormGroup>
              {Roles.getRoles().map((role) => (
                <FormControlLabel
                  key={role.name}
                  control={
                    <Checkbox
                      color="primary"
                      checked={values.roles.indexOf(role.name) !== -1}
                      value={role.name}
                      name="role"
                      onChange={handleSelectRole}
                      disabled={role.name === Roles.AIM_AI}
                    />
                  }
                  label={`${role.label} - ${role.description}`}
                />
              ))}
            </FormGroup>
          </FormControl>
          <Alert severity="info">
            The 'AIM AI' role can be added to users after they accept the invite
            where you have added 'AIM AI' to your subscription.
          </Alert>
        </Grid>

        <Grid item sx={{ marginTop: "10px" }}>
          <Controls.Button
            type="submit"
            text="Create Invite"
            variant="contained"
            endIcon={<SaveIcon />}
            onClick={handleCheckCreateInvite}
            tooltip="Invite another person to access this account"
          />
        </Grid>
      </Form>
      <List>
        {invites.map((invite) => (
          <ListItem key={invite.id}>
            <ListItemAvatar>
              <Avatar>
                {invite.type === "client" ? (
                  <Icons.UserIcon />
                ) : (
                  <Icons.SupplierIcon />
                )}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${invite.email} as ${getRoleNames(invite.roles)}`}
              //secondary={invite.type}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDelete(invite.id)}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        {invites.length === 0 && (
          <Alert severity="info">
            There are no open invites for other users to join this account
          </Alert>
        )}
      </List>
    </>
  )
}

export default InviteList
