import firebase from "firebase/compat/app"
import db from "../../Firestore"
import { loadStripe } from "@stripe/stripe-js"
import { functions } from "../../Firestore"
import { httpsCallable } from "firebase/functions"
import * as userServices from "./userServices"

const getEmptyStripeAccountInfo = () => {}

const stripePromise = loadStripe(
  "pk_live_518SiJcLH9TWo7JIhggKHwqsKqqd8Gpg2GytDdvZY9LzfO2m80VYQWHoPM105i5ZUyMphLEdGEJmsf1vBpuTHhc5n009NgqjgcO"
)

const stripePromise_DEV = loadStripe("pk_test_2DVoEbqo3eIaNkonhEcFHT64")

// To use the Stripe DEV account, modify the value returned below to have _DEV appended, and uncomment the log message to remind to change this back (to prod) before deploying to prod
const getStripePromise = () => {
  //console.log("%cUsing Stripe DEV", "color:red; font-size: 20px")
  //return stripePromise_DEV
  return stripePromise
}

const getStripeMode = () => {
  //return "test"
  return "live"
}

const checkAndUpdateSubscriptionCounts = async (accountId) => {
  // Check if there is a subscription for this account

  console.log("getting stripe customer", { accountId })

  const promises = [
    getStripeCustomer(accountId),
    userServices.getUserCountTotal(accountId),
  ]

  const [stripeAccountInfo, dbUserCount] = await Promise.all(promises)

  const isSubscription = isBillingSetup({ stripeAccountInfo })

  // If so, work out if the user counts for AIM AI and AIM Pro cover existing users

  console.log("dbUserCount", dbUserCount)

  const subscription = stripeAccountInfo.customer.subscriptions[0]
  const subscriptionItems = subscription.items.data
  console.log("subscriptionItems", subscriptionItems)
  const subscriptionUserCount = subscriptionItems.reduce(
    (acc, curr) => acc + curr.quantity,
    0
  )

  // If not, bump up AIM Pro user counts to cover existing users

  console.log("checkAndUpdateSubscriptionCounts", {
    isSubscription,
    subscriptionUserCount,
  })

  const userCountGap = dbUserCount - subscriptionUserCount

  if (userCountGap > 0) {
    // Add the missing user counts to the AIM Pro subscription
    console.log("sub id", { subId: subscription.id, userGap: userCountGap })

    // Get products

    const stripeProducts = await listStripeProducts()
    // const products = stripeProducts.data.filter((product) =>
    //     AIM_PRODUCT_LIST.includes(product.name)
    // )

    const aimProProduct = stripeProducts.data.find(
      (product) => product.name === AIM_PRO_PRODUCT
    )

    const aimAiProduct = stripeProducts.data.find(
      (product) => product.name === AIM_AI_PRODUCT
    )

    const aimProSubItem = subscriptionItems.find(
      (item) => item.price.product === aimProProduct.id
    )
    const aimAiSubItem = subscriptionItems.find(
      (item) => item.price.product === aimAiProduct.id
    )

    const aimProQty = aimProSubItem?.quantity || 0
    const aimProQtyNew = aimProQty + userCountGap

    const aimAiQty = aimAiSubItem?.quantity || 0

    console.log("aimProQtyNew", {
      subItems: { aimProSubItem, aimAiSubItem },
      quantities: { aimAiQty, aimProQty, aimProQtyNew },
      userCountGap,
    })

    const quantities = [
      {
        productId: aimProProduct.id,
        qty: aimProQtyNew,
      },
      {
        productId: aimAiProduct.id,
        qty: aimAiQty,
      },
    ]

    console.log("quantities", quantities)

    const result = await updateStripeSubscriptions(subscription.id, quantities)

    console.log("result", result)
  } else {
    console.log("no user count gap")
  }
}

const isBillingSetup = ({ stripeAccountInfo }) => {
  const isSubscriptionCreated =
    stripeAccountInfo?.customer?.subscriptions?.length > 0 || undefined
  const isCardDefined = stripeAccountInfo?.cards?.length > 0 || undefined

  return isSubscriptionCreated && isCardDefined
}

const getStripeProducts = async (productIds) => {
  const getStripeProducts = httpsCallable(functions, "getStripeProducts")

  const products = await getStripeProducts({
    product_ids: productIds,
    stripe_mode: getStripeMode(),
  })

  console.log("loaded products", products)
  return products.data
}

const listStripeProducts = async () => {
  const list = httpsCallable(functions, "listStripeProducts")

  const result = await list({ stripe_mode: getStripeMode() })

  return result
}

const stripeAttachPaymentMethodToCustomer = async (
  paymentMethodId,
  customerId
) => {
  const attachPaymentMethod = httpsCallable(
    functions,
    "stripeAttachPaymentMethodToCustomer"
  )

  return await attachPaymentMethod({
    paymentMethodId,
    customerId,
    stripe_mode: getStripeMode(),
  })
}

const createStripeSetupIntent = async ({ customerId, paymentMethodId }) => {
  const createSetupIntent = httpsCallable(functions, "createStripeSetupIntent")

  console.log("calling createStripeSetupIntent", customerId, paymentMethodId)

  return await createSetupIntent({
    customerId,
    paymentMethodId,
    stripe_mode: getStripeMode(),
  })
}

const getStripePlan = async (priceId) => {
  const getStripePlan = httpsCallable(functions, "getStripePlan")

  return await getStripePlan({ plan_id: priceId, stripe_mode: getStripeMode() })
}

const getStripeProductPrices = async (productId) => {
  const getProductPrices = httpsCallable(functions, "getProductPrices")

  return await getProductPrices({
    productId: productId,
    stripe_mode: getStripeMode(),
  })
}

const setStripeCardAsDefault = async (paymentMethodId, customerId) => {
  const setDefaultCard = httpsCallable(functions, "setStripeCardAsDefault")

  return await setDefaultCard({
    paymentMethodId,
    custId: customerId,
    stripe_mode: getStripeMode(),
  })
}

const getStripeInvoices = async (customerId) => {
  const getInvoices = httpsCallable(functions, "getStripeInvoices")

  return await getInvoices({ customerId, stripe_mode: getStripeMode() })
}

/**
 * @param {*} subscriptionId
 * @param {*} quantities array of objects with product_id and quantity
 */
const updateStripeSubscriptions = async (subscriptionId, quantities) => {
  // Quantities NaN as 0, i.e. convert NaN to 0
  const quantitiesNaNasZero = quantities.map((q) => {
    if (isNaN(q.qty)) {
      q.qty = 0
      q.total = 0
    }
    return q
  })

  console.log("updateStripeSubscriptions", {
    subscriptionId,
    quantities: quantitiesNaNasZero,
  })

  const updateSubscription = httpsCallable(
    functions,
    "updateStripeSubscriptions"
  )

  const result = await updateSubscription({
    subscriptionId,
    quantities: quantitiesNaNasZero,
    stripe_mode: getStripeMode(),
  })

  return result
}

// Specify the quantity of pro and ai products to create in a single subscription
const createStripeSubscription = async (quantities, accountId) => {
  const createSubscription = httpsCallable(
    functions,
    "createStripeSubscription"
  )

  console.log("Create subscription", { quantities, accountId })

  const result = await createSubscription({
    quantities,
    accountId,
    stripe_mode: getStripeMode(),
  })

  return result
}

const getStripeTaxRates = async () => {
  const getTaxRates = httpsCallable(functions, "getStripeTaxRates")

  const result = await getTaxRates({ stripe_mode: getStripeMode() })

  return result.data
}

/**
 * Cancels a Stripe subscription at the end of the period
 *
 * @param {*} productName
 * @param {*} accountId
 * @returns
 */
const changeStripeSubscriptionCancellationStatus = async (
  subscriptionId,
  cancellationStatus
) => {
  const cancelSubscriptionCancellationStatus = httpsCallable(
    functions,
    "changeStripeSubscriptionCancellationStatus"
  )

  console.log("Change subscription cancellation status", {
    subscriptionId,
    cancellationStatus,
  })

  const result = await cancelSubscriptionCancellationStatus({
    subscriptionId,
    cancellationStatus,
    stripe_mode: getStripeMode(),
  })

  return result
}

const changeStripeSubscriptionQuantity = (subscriptionItemId, quantity) => {
  const changeSubscriptionQuantity = httpsCallable(
    functions,
    "changeStripeSubscriptionQuantity"
  )

  return changeSubscriptionQuantity({
    subscription_item_id: subscriptionItemId,
    quantity: quantity,
    stripe_mode: getStripeMode(),
  })
}

const createStripeCustomer = async (accountId) => {
  console.log("Create stripe customer for account", accountId)
  const accountDoc = await db.collection("accounts").doc(accountId).get()

  const account = accountDoc.data()

  const createStripeCust = httpsCallable(functions, "createStripeCustomer")

  const result = await createStripeCust({
    customer_account_id: accountId,

    // TODO: work out if we need to provide a billing address
    address: {
      city: "",
      country: "",
      line1: "",
      line2: "",
      postal_code: "",
      state: "",
    },
    email: account.email,
    name: account.name,
    phone: account.phone,

    // live or test
    stripe_mode: getStripeMode(),
  })

  console.log("createStripeCustomer result", result)

  return result.data
}

const getStripeCustomer = async (accountId) => {
  const result = {}

  if (firebase.auth().currentUser !== null) {
    const docRef = await db.collection("accounts").doc(accountId).get()

    const account = docRef.data()

    const getStripeCust = httpsCallable(functions, "getStripeCustomer")

    const stripeCust = await getStripeCust({
      account_id: accountId,
      stripe_mode: getStripeMode(),
    })

    const isStripeCustomerDeleted =
      stripeCust.data.customer &&
      stripeCust.data.customer.hasOwnProperty("deleted") &&
      stripeCust.data.customer.deleted === true

    if (!stripeCust.data.customer || isStripeCustomerDeleted) {
      return stripeCust.data
    }

    const cust = stripeCust.data.customer

    const customer = {
      name: cust.name,
      phone: cust.phone,
      email: cust.email,
      address: {
        city: cust.address.city,
        country: cust.address.country,
        line1: cust.address.line1,
        line2: cust.address.line2,
        postal_code: cust.address.postal_code,
        state: cust.address.state,
      },
      created: new Date(cust.created),
      default_source: cust.default_source,
      subscriptions: cust.subscriptions.data,
      invoice_settings: cust.invoice_settings,
    }

    result.customer = customer

    if (stripeCust.data.invoice) {
      result.invoice = stripeCust.data.invoice
    }

    const cards = stripeCust.data.payment_methods.data.map((pm) => {
      const paymentMethod = {
        id: pm.id,
        billing_details: {
          ...pm.billing_details,
        },
        brand: pm.card.brand,
        last4: pm.card.last4,
        exp_month: pm.card.exp_month,
        exp_year: pm.card.exp_year,
        is_default: pm.id === customer.invoice_settings.default_payment_method,
      }

      if (paymentMethod.billing_details.address.line2 === null) {
        paymentMethod.billing_details.address.line2 = ""
      }

      return paymentMethod
    })

    result.cards = cards
  }

  return result
}

const AIM_PRO_PRODUCT = "AIM Pro"
const AIM_AI_PRODUCT = "AIM AI"
const AIM_PRODUCT_LIST = [AIM_PRO_PRODUCT, AIM_AI_PRODUCT]

export {
  getStripeCustomer,
  getEmptyStripeAccountInfo,
  getStripeProducts,
  listStripeProducts,
  getStripePlan,
  getStripePromise,
  getStripeMode,
  getStripeProductPrices,
  getStripeInvoices,
  getStripeTaxRates,
  setStripeCardAsDefault,
  stripeAttachPaymentMethodToCustomer,
  createStripeSetupIntent,
  changeStripeSubscriptionQuantity,
  createStripeSubscription,
  updateStripeSubscriptions,
  changeStripeSubscriptionCancellationStatus,
  checkAndUpdateSubscriptionCounts,
  createStripeCustomer,
  isBillingSetup,
  AIM_PRO_PRODUCT,
  AIM_AI_PRODUCT,
  AIM_PRODUCT_LIST,
}
