import React, { useEffect, useState } from "react"
import { Breadcrumbs, Typography } from "@mui/material"
import * as colors from "@mui/material/colors"
import { spacing } from "../pages/services/styleServices"
import StyledLink from "./controls/StyledLink"

const styles = {
    breadcrumbs: {
        marginBottom: spacing(2),
        fontSize: "0.8rem",
    },
    breadcrumbLink: {
        color: colors.blueGrey[600],
        "&:hover": {
            color: colors.pink[700],
        },
        textDecoration: "none",
        fontSize: "0.8rem",
    },
    strong: {
        fontSize: "1em",
        fontWeight: "700",
    },
}

const ModelBreadcrumbs = (props) => {
    const { modelState, suffix } = props

    // Link to project or component, top level for breadcrumbs
    const [links, setLinks] = useState([])

    useEffect(() => {
        if (modelState) {
            const links = [
                {
                    label: `${modelState.type}s`,

                    // will be /projects or /components
                    url: `/${modelState.type}s`,
                },
                {
                    label: modelState.name,
                    url: `/${modelState.type}/${modelState.parent_id}`,
                },
            ]

            //console.log("Setting links", links)
            setLinks(links)
        }
    }, [modelState])

    const endsWithView = (label) => {
        return props?.children?.toString().toLowerCase().endsWith("view")
    }

    return (
        <Breadcrumbs sx={styles.breadcrumbs}>
            {links.map((link) => (
                <StyledLink key={link.url} color="inherit" sx={styles.breadcrumbLink} to={link.url}>
                    {link.label}
                </StyledLink>
            ))}
            <Typography color="textSecondary" sx={styles.strong} component={"span"}>
                {props.children} {suffix && !endsWithView(props.children) && suffix}
            </Typography>
        </Breadcrumbs>
    )
}

export default ModelBreadcrumbs
