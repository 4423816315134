import React, { useEffect, useState, useCallback } from "react"
import PropTypes from "prop-types"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import { format } from "date-fns"
import { Box, Tooltip } from "@mui/material"
import firebase from "firebase/compat/app"
import { useDispatch, useSelector } from "react-redux"
import { selectPromptGridPagination } from "../redux/selectors"
import { setPromptGridPagination } from "../redux/actions"
import { selectPromptGridPageDocs } from "../redux/selectors"
import { setPromptGridPageDocs } from "../redux/actions"
import Controls from "./controls/Controls"
import { spacing } from "../pages/services/styleServices"
import StyledLink from "./controls/StyledLink"
import PageNo from "./PageNo"
import { useHistory } from "react-router-dom"

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Prompt Name",
    sortable: true,
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
    sortable: false,
  },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Created",
    sortable: true,
  },
]

const PromptGrid = (props) => {
  const { order, orderBy, onRequestSort } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={headCell.sortable && orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {headCell.label}
                </Typography>
              </TableSortLabel>
            ) : (
              <Typography sx={{ fontWeight: "bold" }}>
                {headCell.label}
              </Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

PromptGrid.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
}

const styles = {
  root: {
    marginTop: spacing(2),
  },
  paper: {
    marginBottom: spacing(2),
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  navButtons: {
    display: "flex",
    flexDirection: "row",
    gap: 1,
  },
}

export default function EnhancedTable() {
  // 'next', or 'prev'. Used to inform pagination logic
  const [direction, setDirection] = useState("")

  const [dense, setDense] = React.useState(true)

  const pag = useSelector(selectPromptGridPagination)
  const [pagination, setPagination] = useState(pag)

  const dispatch = useDispatch()

  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const [prompts, setPrompts] = useState([])

  const [user, setUser] = useState()

  const [accountId, setAccountId] = useState()

  const history = useHistory()

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      console.log("### user changed", user)
      setUser(user)

      if (user !== null) {
        user.getIdTokenResult(false).then((token) => {
          console.log("### setting account id", token.claims.account_id)
          setAccountId(token.claims.account_id)
        })
      }
    })

    return unsub
  }, [])

  const pgDocs = useSelector(selectPromptGridPageDocs)
  const [pageDocs, setPageDocs] = useState(pgDocs)

  const COLLECTION_NAME = "prompts"

  const [maxModified, setMaxModified] = useState()

  // Listen for changes

  useEffect(() => {
    console.log("loading prompts")
    if (user === undefined || accountId === undefined) {
      return
    }

    const whereClauseLog = []

    let query = db
      .collection(COLLECTION_NAME)
      .where("modified", ">=", dataServices.localTimestamp())
      .orderBy("modified", "desc")

    whereClauseLog.push(
      "modified >= " +
        dataServices.localTimestamp() +
        " [" +
        dataServices.localTimestamp().toDate() +
        "]"
    )

    let unsubscribe

    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((token) => {
        switch (token.claims.account_type) {
          case "client":
            query = query.where("account_id", "==", token.claims.account_id)
            whereClauseLog.push("account_id == " + token.claims.account_id)
            break

          default:
            throw new Error("Unknown account type " + token.claims.account_type)
        }

        //console.log('whereClause for listener', whereClauseLog)

        let newMaxModified = null
        unsubscribe = query.onSnapshot((querySnapshot) => {
          querySnapshot.docChanges().forEach((change) => {
            if (
              newMaxModified === null ||
              change.doc.data().modified.seconds > newMaxModified.seconds
            ) {
              // trigger refresh
              newMaxModified = change.doc.data().modified
            }
          })
        })

        if (newMaxModified !== null) {
          setMaxModified(newMaxModified)
        }
      })
    return unsubscribe // gets called on unmount
  }, [user, accountId])

  // Get most recent modified record

  useEffect(() => {
    if (user === undefined || accountId === undefined) {
      return
    }

    console.log("=== get max modified date ===")

    let query = db.collection(COLLECTION_NAME)

    let unsubscribe

    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((token) => {
        switch (token.claims.account_type) {
          case "client":
            query = query.where("account_id", "==", token.claims.account_id)
            break

          default:
            throw new Error("Unknown account type " + token.claims.account_type)
        }

        console.log("%cload prompts, pagination", "color:yellow", {
          pagination,
        })
        //query = query.where("tags_index", "array-contains-any", ["Type=Tech Debt"])

        query = query.orderBy("modified", "desc").limit(30)

        unsubscribe = query.onSnapshot((querySnapshot) => {
          let newMaxModified = null
          querySnapshot.docChanges().forEach((change) => {
            if (
              newMaxModified === null ||
              change.doc.data().modified.seconds > newMaxModified.seconds
            ) {
              console.log(
                "=== max modified ===",
                change.doc.id,
                change.doc.data().name,
                change.doc.data().modified
              )
              newMaxModified = change.doc.data().modified
            }
          })
          if (newMaxModified !== null) {
            setMaxModified(newMaxModified)
          }
        })
      })
    return unsubscribe
  }, [user, accountId, pagination])

  const updatePageDocs = useCallback(() => {
    if (prompts.length > 0 && direction !== "prev") {
      const newPageDocs = [...pageDocs]

      const newPageDoc = {
        first: prompts[0].doc,
        last: prompts[prompts.length - 1].doc,
      }

      newPageDocs[pagination.page] = newPageDoc
      setPageDocs(newPageDocs)
      dispatch(setPromptGridPageDocs(newPageDocs))
    }
  }, [prompts, pagination.page, direction])

  useEffect(() => {
    updatePageDocs(prompts)
  }, [prompts, updatePageDocs])

  const handleNewPrompt = (event) => {
    console.log("handleNewPrompt")
    history.push("/Prompt")
  }

  // Load prompts

  useEffect(() => {
    if (user === undefined || accountId === undefined) {
      return
    }

    if (maxModified === undefined) {
      return
    }

    console.log("(1) load prompts", {
      accountId,
      user,
      maxModified,
      pagination,
    })

    const queryMods = []

    let query = db.collection(COLLECTION_NAME)

    if (!firebase.auth().currentUser) {
      return
    }

    firebase
      .auth()
      .currentUser.getIdTokenResult()
      .then((token) => {
        switch (token.claims.account_type) {
          case "client":
            query = query.where("account_id", "==", token.claims.account_id)
            queryMods.push("where account_id == " + token.claims.account_id)
            break

          default:
            throw new Error("Unknown account type " + token.claims.account_type)
        }

        query = query.orderBy(pagination.orderBy, pagination.order)
        queryMods.push(
          "order by " + pagination.orderBy + " " + pagination.order
        )
        query = query.orderBy(
          firebase.firestore.FieldPath.documentId(),
          pagination.order
        )
        queryMods.push("order by doc id " + pagination.order)

        if (pagination.page > 0 && direction !== "prev") {
          // Use pageDocs if available, i.e. if we've gone forward, then back, then forward again through collection.
          // But if not found, it just means this is the first time we've clicked Next through the collection
          if (pageDocs[pagination.page - 1]) {
            const lastDoc = pageDocs[pagination.page - 1].last
            //console.log('startAfter', lastDoc.id)
            query = query.startAfter(lastDoc)
            queryMods.push(
              "start after last doc on previous page " + lastDoc.id
            )
          }
        } else if (direction === "prev") {
          if (!pageDocs[pagination.page]) {
            console.error("Cant find pagedocs for page", pagination.page)
          }
          query = query.startAt(pageDocs[pagination.page].first)
          queryMods.push(
            "start at 1st doc on page " + pageDocs[pagination.page].first.id
          )
        }

        console.log("queryMods", queryMods)
        console.groupEnd()

        query = query.limit(rowsPerPage)

        dataServices
          .loadData("(Load prompt grid)", query)
          .then((prompts) => setPrompts(prompts))
          .then(updatePageDocs())

        console.groupEnd()
      })
  }, [accountId, user, maxModified, pagination])

  const handleRequestSort = (event, property) => {
    const isAsc = pagination.orderBy === property && pagination.order === "asc"

    const updatedPagination = {
      //tags: [],
      ...pagination,
      page: 0,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    }

    setPagination(updatedPagination)
    dispatch(setPromptGridPagination(updatedPagination))

    setPageDocs([])
    dispatch(setPromptGridPageDocs([]))

    setDirection("")
  }

  const handlePageNav = (pageChange) => {
    const newPage = pagination.page + pageChange
    if (newPage >= 0) {
      setDirection(pageChange === 1 ? "next" : "prev")

      const updatedPagination = {
        ...pagination,
        page: newPage,
      }

      setPagination(updatedPagination)
      dispatch(setPromptGridPagination(updatedPagination))
    }
  }

  return (
    <>
      <Box sx={styles.root}>
        <Paper sx={styles.paper}>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="Prompts"
            >
              <PromptGrid
                prompts={prompts}
                order={pagination.order}
                orderBy={pagination.orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {prompts.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        <StyledLink to={"/Prompt/" + row.id}>
                          <Tooltip title={row.description}>
                            <Typography
                              noWrap={true}
                              variant="body2"
                              component={"span"}
                            >
                              {row.name}
                            </Typography>
                          </Tooltip>
                        </StyledLink>
                      </TableCell>

                      <TableCell component="th" padding="none">
                        <Typography
                          noWrap={true}
                          variant="body2"
                          component={"span"}
                        >
                          {row.description}
                        </Typography>
                      </TableCell>

                      <TableCell align="left">
                        <Typography
                          variant="body2"
                          noWrap={true}
                          component={"span"}
                        >
                          {format(row.created.toDate(), "dd-M-yy")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box sx={styles.navButtons}>
          <Controls.Button
            size="small"
            disabled={pagination.page === 0}
            onClick={() => handlePageNav(-1)}
            text="Prev"
          />

          <Controls.Button
            size="small"
            disabled={prompts.length < rowsPerPage}
            onClick={() => handlePageNav(1)}
            text="Next"
          />

          <Controls.Button
            size="small"
            onClick={(event) => handleNewPrompt(event)}
            text="New Prompt"
            variant="contained"
            tooltip="Create a new prompt"
          />
        </Box>
        <PageNo pageNo={pagination.page + 1} />
      </Box>
    </>
  )
}
