import React, { useState } from "react"
import Header from "../components/Header"
import VectorStoreEditForm from "../components/VectorStoreEditForm"

const VectorStoreEdit = (props) => {
    const [title, setTitle] = useState("Vector Store")

    return (
        <Header title={title}>
            <VectorStoreEditForm {...props} setTitle={setTitle} />
        </Header>
    )
}

export default VectorStoreEdit
