import React, { useState, useEffect, useRef, useMemo } from "react"
import Controls from "../components/controls/Controls"
import {
  Paper,
  Grid,
  Typography,
  Stepper,
  Step,
  StepContent,
  Button,
  StepButton,
  AppBar,
  Toolbar,
  IconButton,
  Box,
  TextField,
  Stack,
  Autocomplete,
} from "@mui/material"
import { FcGoogle } from "react-icons/fc"
import { useForm } from "../components/useForm"
import { useHistory } from "react-router-dom"
import firebase from "firebase/compat/app"
import { Alert, AlertTitle } from "@mui/material"
import { useSnackbar } from "notistack"
import { obtainCustomClaims } from "./services/cloudFunctions"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import * as countryServices from "../pages/services/countryServices"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import ProgressBackdrop from "../components/ProgressBackdrop"
import { functions } from "../Firestore"
import { httpsCallable } from "firebase/functions"
import AcceptEULADialog from "../components/AcceptEULADialog"
import { Link } from "react-router-dom"
import { Link as MuiLink } from "@mui/material"
import { isEmailValid } from "./services/emailServices"
import QRCode from "qrcode"
import * as authenticationServices from "../pages/services/cloudFunctions"
import PasswordFields from "../components/PasswordFields"
import { spacing } from "../pages/services/styleServices"
import * as cloudFunctions from "../pages/services/cloudFunctions"

const styles = {
  root: {
    width: "100%",
    margin: spacing(1),
  },
  pageContent: {
    margin: spacing(2),
    padding: spacing(2),
  },
  button: {
    marginTop: spacing(1),
    marginRight: spacing(1),
  },
  actionsContainer: {
    marginBottom: spacing(2),
    marginTop: spacing(2),
  },
  resetContainer: {
    padding: spacing(3),
  },
  stepUI: {
    padding: spacing(1),
    marginTop: spacing(1),
  },
  title: {
    flexGrow: 1,
  },
  paddingBottom: {
    paddingBottom: spacing(2),
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
  },
  agreeToLicense: {
    marginLeft: spacing(2),
  },
}

const initialValues = {
  account_type: "client",
  account_name: "My Account",
  name: "",
  email: "",
  phone: "",
  country: "",
  jobs_module: false,
  agree_to_license: false,
  new_password_1: "",
  new_password_2: "",
  otp: "",
  plan: "basic", // can be 'basic' or 'business'
}

const labels = {
  account_type: "Account Type",
  account_name: "Account Name",
  name: "Name",
  email: "Email",
  phone: "Phone",
  country: "Country",
  new_password_1: "Password",
  new_password_2: "Confirm Password",
}

function SignUp() {
  const [isShowProgress, setShowProgress] = useState(false)

  const history = useHistory()

  const [isSignedUp, setSignedUp] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const { values, setValues, handleInputChange } = useForm(initialValues)

  const [acceptEULAOpen, setAcceptEULAOpen] = useState(false)

  const [isUserAuthenticated, setUserAuthenticated] = useState(false)

  const [usePassword, setUsePassword] = useState(true)

  const [otpEmailSent, setOtpEmailSent] = useState(false)

  const [countrySelection, setCountrySelection] = useState({ name: "" })

  const [user, setUser] = useState()

  //const [priceDescription, setPriceDescription] = useState("")

  const QRCODE = "qrcode"

  const EMAIL = "email"

  const [deliveryMethod, setDeliveryMethod] = useState(EMAIL)

  const [activeStep, setActiveStep] = useState(0)

  const [qrCodeUrl, setQrCodeUrl] = useState()

  const isShowQRCode = useMemo(() => {
    const result =
      deliveryMethod === QRCODE &&
      qrCodeUrl !== undefined &&
      qrCodeUrl !== "" &&
      values.email !== ""
    //console.log("isShowQRCode", result)
    return result
  }, [deliveryMethod, qrCodeUrl, values.email])

  const countryOptions = useMemo(() => {
    return countryServices.countries.map((country) => ({
      name: country.name,
      code: country.code,
    }))
  }, [])

  // useEffect(() => {
  //     if (isUserAuthenticated) {
  //         stripeServices.listStripeProducts().then((products) => {
  //             console.log("%cproducts", "color:pink", { products })

  //             const aimWebBeta = products.data.find(
  //                 (product) =>
  //                     stripeServices.AIM_PRODUCT_LIST.includes(product.name) && product.active
  //             )
  //             console.log("%caimWebBeta", "color:pink", { aimWebBeta, id: aimWebBeta.id })
  //             stripeServices.getStripeProductPrices(aimWebBeta.id).then((prices) => {
  //                 console.log("%cprices", "color:pink", { prices })

  //                 if (prices.data.length > 0) {
  //                     const price = prices.data[0]

  //                     const desc = `${price.currency?.toUpperCase()} ${formatAmount(
  //                         price.unit_amount
  //                     )} per user per month. GST (10%) is added for Australian users.`
  //                     console.log("price", desc)
  //                     setPriceDescription(desc)
  //                 }
  //             })
  //         })
  //     }
  // }, [isUserAuthenticated])

  const showImg = useMemo(() => {
    return isShowQRCode ? "" : "none"
  }, [isShowQRCode])

  const imgRef = useRef()

  useEffect(() => {
    if (
      deliveryMethod === QRCODE &&
      isEmailValid(values.email) &&
      activeStep === 0
    ) {
      //console.log("useEffect:qrCodeUrl", qrCodeUrl)
      if (qrCodeUrl && imgRef && imgRef.current) {
        imgRef.current.src = qrCodeUrl
      } else {
        console.log("Get QR Code URI", { email: values.email })
        authenticationServices.createTotpURI(values.email).then((result) => {
          console.log("result", result)

          QRCode.toDataURL(result.data.uri, { errorCorrectionLevel: "L" }).then(
            (url) => {
              //console.log(url)
              setQrCodeUrl(url)

              if (imgRef.current) {
                imgRef.current.src = url
              }
            }
          )
        })
      }
    }
  }, [values.email, deliveryMethod, isEmailValid, activeStep])

  const handleNext = async () => {
    // Validate current step
    const validateFunc = getStepInfo()[activeStep].validate

    //console.log("validateFunc", validateFunc)

    let validateResult = true

    if (validateFunc) {
      validateResult = await validateFunc()
    }

    if (!validateResult) {
      return
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const validatePersonalDetailsStep = () => {
    let validated = true

    if (values.name === "") {
      validated = false
      enqueueSnackbar("Enter you name", { variant: "warning" })
    }

    if (values.account_name === "") {
      validated = false
      enqueueSnackbar("Enter an account name", { variant: "warning" })
    }

    console.log("country", values.country, countryOptions)

    if (
      !countryOptions.find((option) => option.name === countrySelection.name)
    ) {
      validated = false
      enqueueSnackbar("Enter your country", { variant: "warning" })
    }

    return validated
  }

  const validateEmailStep = async () => {
    if (isUserAuthenticated) {
      return true
    }

    let validated = true
    setShowProgress(true)

    console.log("isUserAuthenticated", isUserAuthenticated)

    if (values.email === "") {
      enqueueSnackbar("Email is required", { variant: "warning" })
      setShowProgress(false)
      return false
    } else if (values.otp === "") {
      enqueueSnackbar("6-digit code is required", { variant: "warning" })
      setShowProgress(false)
      return false
    }

    if (deliveryMethod === QRCODE) {
      const verifyResult = await authenticationServices.verifyTotpToken(
        values.otp
      )

      console.log("verifyResult", verifyResult)

      if (verifyResult?.data?.status === "error") {
        enqueueSnackbar(verifyResult.data.message, { variant: "warning" })
        setShowProgress(false)
        return false
      }

      const validateProps = {
        email: values.email,
        password: values.new_password_1,
        otp: values.otp,
        usePassword: usePassword,
      }

      console.log("validateProps", validateProps)
      const result = await authenticationServices.validateQRCodeOTPAndGetToken(
        validateProps
      )

      if (result.data.status === "success") {
        validated = true

        firebase
          .auth()
          .signInWithCustomToken(result.data.token)
          .then((userCredential) => {
            // Signed in
            var user = userCredential.user
            console.log("user signed in with QR Code", user)
          })
          .catch((error) => {
            console.log("Error signing in", error)
            validated = false
          })
      }

      console.log(
        "%ctoken validation result from QRCode OTP",
        "color:lightgreen",
        result
      )
    } else {
      const validateResult =
        await authenticationServices.validateOTPAndGetToken(
          values.email,
          values.otp,
          true,
          values.new_password_1
        )

      console.log(
        "%ctoken validation result from email OTP",
        "color:lightgreen",
        validateResult
      )

      if (validateResult?.data?.status === "error") {
        enqueueSnackbar(validateResult.data.message, { variant: "warning" })
        setShowProgress(false)
        return false
      }

      if (validateResult.data.hasOwnProperty("token")) {
        enqueueSnackbar("Email authenticated. Please wait...", {
          variant: "success",
        })
        const userCredential = await firebase
          .auth()
          .signInWithCustomToken(validateResult.data.token)

        // Signed in
        console.log("user", { userCredential })

        validated = true
      } else {
        enqueueSnackbar(
          validateResult.data?.message || "One time password login failed",
          {
            variant: "warning",
          }
        )
      }
    }

    if (!validated) {
      enqueueSnackbar("Please authenticate first", { variant: "warning" })
    }

    setShowProgress(false)
    return validated
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReturnToSignUp = () => {
    history.goBack()
  }

  const signUpNewAccount = async () => {
    setShowProgress(true)
    enqueueSnackbar("Creating account", { variant: "info" })

    const createNewAccount = httpsCallable(functions, "createNewAccount")

    const payload = {
      signUpDetails: {
        account_type: values.account_type,
        email: values.email,
        // When creating a subscription
        country: values.country,
        name: values.name,
        account_name: values.account_name,
        phone: values.phone,
        plan: values.plan,
      },
    }

    console.log("creating new account with values", { payload })

    const result = await createNewAccount(payload)
      .then(async (result) => {
        console.log("%ccreated account, updating claims", "color:pink", {
          result,
        })
        await obtainCustomClaims()

        // send email to support@architectureinmotion.com.au to inform that a new account was created
        // do this async so it doesn't disturb the user

        cloudFunctions
          .sendEmailMessage({
            to: "gregmatthews555@gmail.com",
            subject: `User signed up - ${values.email}`,
            text: `User signed up with email ${values.email}`,
            html: `User signed up with email ${values.email}`,
          })
          .then((result) => console.log("Email sent to support", result))
          .catch((err) =>
            console.error(
              "Error calling sendEmail to indicate new account created",
              err
            )
          )

        return result
      })
      .catch((err) => console.error("Error calling createNewAccount", err))

    //console.log("signup result", result)

    setShowProgress(false)

    return result
  }

  // See if user is already signed up

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user)

        console.log("auth state changed", { user })
        user.getIdTokenResult(true).then((token) => {
          console.log("token", { token })
          setUserAuthenticated(true)

          setSignedUp(token.claims.account_id !== undefined)

          const newValues = {
            ...values,
            email: token.claims.email,
          }

          if (user.displayName) {
            if (newValues.name === "") {
              newValues.name = user.displayName
            }
            if (newValues.account_name === "") {
              newValues.account_name = user.displayName
            }
          }

          setValues(newValues)
        })
      } else {
        setSignedUp(false)
      }
    })

    return unsub
  }, [])

  // See if user has already authenticated their email, but perhaps doesn't have an account

  useEffect(() => {
    const user = firebase.auth().currentUser

    if (!user) {
      return
    }

    const newValues = {
      ...values,
      email: user.email,
      account_email: user.email,
    }

    //console.log("setting new form values", newValues)
    setValues(newValues)
  }, [])

  const handleSignUp = async () => {
    //console.log("sign up...")
    setShowProgress(true)

    const valuesToCheck = { ...values }

    // Phone not mandatory
    delete valuesToCheck.phone

    //console.log("Checking values", { valuesToCheck, usePassword })
    if (!usePassword) {
      delete valuesToCheck.new_password_1
      delete valuesToCheck.new_password_2
    }
    delete valuesToCheck.otp

    if (isUserAuthenticated) {
      delete valuesToCheck.email
      delete valuesToCheck.new_password_1
      delete valuesToCheck.new_password_2
    }

    console.log("checking field values", valuesToCheck)

    const emptyFieldName = Object.keys(valuesToCheck).find(
      (key) => values[key] === undefined || values[key] === ""
    )

    //console.log("emptyFieldName", emptyFieldName)

    if (emptyFieldName !== undefined) {
      const label = labels[emptyFieldName]
      //console.log("label", label)
      enqueueSnackbar("Enter " + label, { variant: "warning" })
    } else {
      let result = await signUpNewAccount()
      console.log("signUp result", result)

      enqueueSnackbar("Account created. Welcome", { variant: "success" })

      history.push("/dashboard")
    }
    setShowProgress(false)
  }

  const handleStep = (step) => () => {
    //console.log("handleStep", step)
    setActiveStep(step)
  }

  const handleGoogleAuthenticate = () => {
    const provider = new firebase.auth.GoogleAuthProvider()

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        console.log("google auth result", result)
      })
      .catch((err) => console.error("Error authenticating with google", err))
  }

  const handleRequestOTPViaEmail = () => {
    console.log("Request OTP")

    if (!isEmailValid(values.email)) {
      enqueueSnackbar("Enter valid email", { variant: "warning" })
      return
    } else {
      console.log("valid email", values.email)
    }

    // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    const match = values.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)

    console.log("email match", match)

    if (match !== null) {
      cloudFunctions
        .createAndSendOTPViaEmail({ email: values.email })
        .then(() => {
          enqueueSnackbar(
            "A 6-digit code is being emailed to you. This can sometimes take a few minutes. Check your inbox incl. junk mail folder",
            {
              variant: "success",
            }
          )
        })
    } else {
      enqueueSnackbar("Enter a valid email address", { variant: "warning" })
    }
  }

  const getEmailUI = () => {
    return (
      <Paper sx={styles.stepUI}>
        <Stack gap={2}>
          {isUserAuthenticated && <Typography>{user.email}</Typography>}

          {!isUserAuthenticated && (
            <>
              <Button
                variant="outlined"
                fullWidth={true}
                size="small"
                onClick={() => handleGoogleAuthenticate()}
                startIcon={<FcGoogle />}
              >
                Sign In with Google
              </Button>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Typography variant="caption">or</Typography>
              </Box>

              <Controls.TextInput
                autoFocus
                name="email"
                label="Enter email address"
                value={values.email}
                variant="outlined"
                onChange={handleInputChange}
                size="small"
              />

              {usePassword && (
                <PasswordFields
                  showOldPassword={false}
                  handleInputChange={handleInputChange}
                  newPassword1={values.new_password_1}
                  newPassword2={values.new_password_2}
                />
              )}

              <Box sx={{ marginTop: "15px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <Button
                    onClick={() => {
                      handleRequestOTPViaEmail()
                      setOtpEmailSent(true)
                    }}
                    text="Email 6-digit code"
                    size="small"
                    variant="outlined"
                    disabled={
                      isShowProgress ||
                      deliveryMethod !== "email" ||
                      otpEmailSent
                    }
                  >
                    Email 6-digit code
                  </Button>
                  {otpEmailSent && (
                    <MuiLink
                      onClick={() => {
                        setOtpEmailSent(false)
                      }}
                      component="button"
                    >
                      <Typography variant="caption">Resend email</Typography>
                    </MuiLink>
                  )}
                </Box>
                <Box sx={{ marginTop: "5px" }}>
                  <Typography variant="caption">
                    We take security and protection of your content seriously.
                    Click 'Email 6-digit code', then check your email.
                  </Typography>
                </Box>

                {isShowQRCode && (
                  <Box>
                    <Box sx={{ display: showImg }}>
                      <img alt="QRCode" ref={imgRef} />
                    </Box>
                    <Box sx={{ marginBottom: "15px" }}>
                      <Typography variant="caption">
                        Scan this QR code with Google Authenticator to obtain a
                        6-digit code. You only need to register this QR code
                        once.
                      </Typography>
                    </Box>
                  </Box>
                )}
                <Box sx={{ mt: "15px" }}>
                  <TextField
                    label="Enter 6-digit code"
                    variant="outlined"
                    name="otp"
                    value={values.otp}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 6 }}
                    size="small"
                  />
                </Box>
              </Box>
            </>
          )}
        </Stack>
      </Paper>
    )
  }

  const getPersonalDetailsUI = () => {
    return (
      <Paper sx={styles.stepUI}>
        <Stack direction="column" gap={2}>
          <Controls.TextInput
            autoFocus
            name="name"
            label="Your name"
            value={values.name}
            disabled={isSignedUp}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
          />

          <Controls.TextInput
            name="account_name"
            label="Account Name"
            value={values.account_name}
            disabled={isSignedUp}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
          />

          <Controls.Readonly
            name="email"
            label="Email"
            value={values.email}
            sx={{ width: "300px" }}
            variant="outlined"
            size="small"
          />

          <Controls.TextInput
            name="phone"
            label="Phone (optional)"
            value={values.phone}
            disabled={isSignedUp}
            onChange={handleInputChange}
            sx={{ maxWidth: "300px" }}
            variant="outlined"
            size="small"
          />

          <Autocomplete
            id="country"
            value={countrySelection}
            onChange={(e, newValue) => {
              setCountrySelection(newValue)
              setValues((curr) => ({ ...curr, country: newValue?.code || "" }))
            }}
            autoHighlight
            options={countryOptions}
            getOptionLabel={(option) => option?.name || ""}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                variant="outlined"
                sx={{ maxWidth: "300px" }}
              />
            )}
          />
        </Stack>
      </Paper>
    )
  }

  const getStepInfo = () => {
    return [
      {
        title: `Authenticate email`,
        label: "Choose email to use for your account",
        ui: getEmailUI(),
        validate: async () => {
          return await validateEmailStep()
        },
      },
      {
        title: "Enter your details",
        //label: `Enter your details`,
        ui: getPersonalDetailsUI(),
        validate: () => validatePersonalDetailsStep(),
      },
    ]
  }

  const getStepContent = (step) => {
    return getStepInfo()[step].label
  }

  const getStepUI = (step) => {
    return getStepInfo()[step].ui
  }

  const getStepTitles = () => {
    return getStepInfo().map((step) => step.title)
  }

  return (
    <>
      <ProgressBackdrop open={isShowProgress} />

      <AcceptEULADialog
        open={acceptEULAOpen}
        handleAccept={() => {
          setAcceptEULAOpen(false)

          handleSignUp()
          console.log("accepted")
        }}
        handleCancel={() => setAcceptEULAOpen(false)}
      />

      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={styles.title} component={"span"}>
            Sign Up
          </Typography>
          <IconButton
            edge="end"
            sx={styles.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={handleReturnToSignUp}
            size="large"
          >
            <ArrowBackIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {isSignedUp && (
        <Box sx={{ paddingLeft: "10px" }}>
          <Alert severity="info" sx={styles.pageContent}>
            <AlertTitle>Existing Account</AlertTitle>
            You're already signed up
          </Alert>
          <Link to="/SignIn">Go back to Sign In</Link>
        </Box>
      )}

      {!isSignedUp && (
        <Box maxWidth={500}>
          <Box sx={styles.root}>
            <Stepper activeStep={activeStep} nonLinear orientation="vertical">
              {getStepTitles().map((label, index) => (
                <Step key={label}>
                  <StepButton onClick={handleStep(index)}>{label}</StepButton>
                  <StepContent>
                    <Typography variant="caption" component={"span"}>
                      {getStepContent(index)}
                    </Typography>

                    {activeStep === index && getStepUI(activeStep)}

                    <Box sx={styles.actionsContainer}>
                      <Box>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={styles.button}
                          size="small"
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          sx={styles.button}
                          size="small"
                        >
                          Next
                        </Button>
                      </Box>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
            {activeStep === getStepTitles().length && (
              <Paper square elevation={0} sx={styles.resetContainer}>
                <Box sx={{ marginBottom: "10px" }}>
                  <Typography>{values.name}</Typography>
                  <Typography>{values.email}</Typography>
                  <Typography>{values.account_name}</Typography>
                  <Typography>{values.phone}</Typography>
                </Box>

                <Box sx={styles.buttons}>
                  <Button
                    onClick={() => setAcceptEULAOpen(true)}
                    color="primary"
                    variant="contained"
                    sx={styles.button}
                    //disabled={accountNameUsed}
                    size="small"
                    text="Review License and Continue"
                    endIcon={<NavigateNextIcon />}
                  >
                    Review License and Continue
                  </Button>
                </Box>
              </Paper>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}

export default SignUp
