// generateDoc.js

import {
    Document,
    Paragraph,
    TextRun,
    HeadingLevel,
    LevelFormat,
    AlignmentType,
    convertInchesToTwip,
    TableOfContents,
  } from "docx";
  import { colors } from "@mui/material";
  
  /**
   * Maps the numeric level to docx's HeadingLevel.
   * @param {string} level - The level string (e.g., "1", "1.1", "2.1.3").
   * @returns {HeadingLevel} - Corresponding HeadingLevel.
   */
  const getHeadingLevel = (level) => {
    const segments = level.split(".");
    const mainLevel = segments.length;
    switch (mainLevel) {
      case 1:
        return HeadingLevel.HEADING_1;
      case 2:
        return HeadingLevel.HEADING_2;
      case 3:
        return HeadingLevel.HEADING_3;
      case 4:
        return HeadingLevel.HEADING_4;
      case 5:
        return HeadingLevel.HEADING_5;
      case 6:
        return HeadingLevel.HEADING_6;
      default:
        return HeadingLevel.HEADING_1;
    }
  };
  
  /**
   * Simple Markdown Parser to convert markdown string to docx Paragraphs.
   * Supports headings, bold, italics, and bullet/numbered lists.
   * @param {string} markdown - The Markdown string.
   * @returns {Array} - Array of docx Paragraph objects.
   */
  const parseMarkdown = (markdown) => {
    const lines = markdown.split("\n");
    const paragraphs = [];
    let isInNumberedList = false;
  
    lines.forEach((line) => {
      let paragraph = null;
  
      // Trim the line
      const trimmedLine = line.trim();
  
      // Heading
      const headingMatch = trimmedLine.match(/^(#{1,6})\s+(.*)/);
      if (headingMatch) {
        const level = headingMatch[1].length;
        const text = headingMatch[2];
        const inlineStyles = parseInlineStyles(text);
        paragraph = new Paragraph({
          children: inlineStyles,
          heading: getHeadingLevel(level.toString()),
          spacing: { after: 100, line: 360, lineRule: "auto" }, // 1.5 line spacing
          style: `Heading${level}`,
          // Numbering handled separately
        });
        paragraphs.push(paragraph);
        isInNumberedList = false;
        return;
      }
  
      // Unordered List
      const ulMatch = trimmedLine.match(/^[-*]\s+(.*)/);
      if (ulMatch) {
        const text = ulMatch[1];
        const inlineStyles = parseInlineStyles(text);
        paragraph = new Paragraph({
          children: inlineStyles,
          bullet: { level: 0 },
          spacing: { after: 100, line: 360, lineRule: "auto" }, // 1.5 line spacing
        });
        paragraphs.push(paragraph);
        isInNumberedList = false;
        return;
      }
  
      // Ordered List
      const olMatch = trimmedLine.match(/^\d+\.\s+(.*)/);
      if (olMatch) {
        const text = olMatch[1];
        const inlineStyles = parseInlineStyles(text);
        paragraph = new Paragraph({
          children: inlineStyles,
          numbering: {
            reference: "my-numbered-points",
            level: 0,
            // Restart numbering for each new list
            // Assuming docx supports the 'start' property
            // If not, a new numbering reference should be created
            // Here, we'll set start to 1 each time
            // Note: The 'docx' library may not support 'start' directly in numbering
            // This is a placeholder for the intended functionality
            // You might need to manage numbering references dynamically
          },
          numberingRestart: true, // Custom property to indicate restart
          spacing: { after: 100, line: 360, lineRule: "auto" }, // 1.5 line spacing
        });
        paragraphs.push(paragraph);
        isInNumberedList = true;
        return;
      }
  
      // If line is empty, skip
      if (trimmedLine === "") {
        isInNumberedList = false;
        return;
      }
  
      // Regular Paragraph with Inline Styles
      const inlineStyles = parseInlineStyles(trimmedLine);
      paragraph = new Paragraph({
        children: inlineStyles,
        spacing: { after: 100, line: 360, lineRule: "auto" }, // 1.5 line spacing
        style: "Normal",
      });
      paragraphs.push(paragraph);
      isInNumberedList = false;
    });
  
    return paragraphs;
  };
  
  /**
   * Parses inline Markdown styles (bold, italics, code) within a text string.
   * @param {string} text - The text containing inline Markdown.
   * @returns {Array} - Array of docx TextRun objects.
   */
  const parseInlineStyles = (text) => {
    const runs = [];
    let currentIndex = 0;
  
    // Regex to match **bold**, *italics*, and `code`
    // This regex handles multiple occurrences and ensures proper matching
    const regex = /(\*\*([^*]+?)\*\*|\*([^*]+?)\*|`([^`]+?)`)/g;
    let match;
  
    while ((match = regex.exec(text)) !== null) {
      const fullMatch = match[0];
      const boldText = match[2];
      const italicText = match[3];
      const codeText = match[4];
      const index = match.index;
  
      // Add preceding normal text
      if (currentIndex < index) {
        runs.push(new TextRun({ text: text.substring(currentIndex, index) }));
      }
  
      // Determine the type of inline style and create corresponding TextRun
      if (boldText !== undefined) {
        runs.push(
          new TextRun({
            text: boldText,
            bold: true,
          })
        );
      } else if (italicText !== undefined) {
        runs.push(
          new TextRun({
            text: italicText,
            italics: true,
          })
        );
      } else if (codeText !== undefined) {
        runs.push(
          new TextRun({
            text: codeText,
            font: "Courier New",
          })
        );
      }
  
      currentIndex = index + fullMatch.length;
    }
  
    // Add remaining normal text
    if (currentIndex < text.length) {
      runs.push(new TextRun({ text: text.substring(currentIndex) }));
    }
  
    return runs;
  };
  
  /**
   * Assembles docx Paragraphs based on the provided content array.
   * @param {Array} contentArray - The content array from the JSON data.
   * @returns {Array} - Array of docx Paragraph objects.
   */
  const assembleChildren = (contentArray) => {
    const children = [];
    let numberingCount = 0;
  
    contentArray.forEach((item) => {
      // Add Heading with Numbering
      if (item.heading && item.level) {
        // Parse the level string to determine the hierarchical level
        const levelSegments = item.level.split(".");
        const hierarchicalLevel = levelSegments.length; // e.g., "2.1" -> 2
  
        children.push(
          new Paragraph({
            children: [new TextRun({ text: item.heading, bold: true })],
            heading: getHeadingLevel(levelSegments.join(".")),
            spacing: {
              after: 200,
              line: 360, // 1.5 line spacing
              lineRule: "auto",
            },
            style: `Heading${hierarchicalLevel}`,
            numbering: {
              reference: "heading-numbering",
              level: hierarchicalLevel - 1, // docx levels are 0-indexed
            },
            pageBreakBefore: hierarchicalLevel === 1, // Start H1 on a new page
          })
        );
      }
  
      // Add Rationale as italicized text
      if (item.rationale) {
        children.push(
          new Paragraph({
            children: [
              new TextRun({
                text: `Rationale: ${item.rationale}`,
                italics: true,
              }),
            ],
            spacing: {
              after: 200,
              line: 360, // 1.5 line spacing
              lineRule: "auto",
            },
            style: "Caption", // Apply caption style
          })
        );
      }
  
      // Add Content if available
      if (item.content) {
        const markdownParagraphs = parseMarkdown(item.content);
        children.push(...markdownParagraphs);
      }
    });
  
    return children;
  };
  
  /**
   * Generates a docx Document object based on the provided data.
   * @param {Object} data - The data object containing document structure.
   * @returns {Document} - The generated Document object.
   */
  const generateDoc = (data) => {
    if (!data || !Array.isArray(data.content)) {
      throw new Error("Invalid data format: 'content' array is missing.");
    }
  
    // Assemble all children first
    const children = assembleChildren(data.content);
  
    // Define custom styles
    const styles = {
      paragraphStyles: [
        {
          id: "Title",
          name: "Title",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            size: 64, // 32pt * 2
            bold: true,
            color: colors.blueGrey[300],
            font: "Aptos",
          },
          paragraph: {
            spacing: {
              after: 240, // Increased spacing after title
              line: 360, // 1.5 line spacing
              lineRule: "auto",
            },
          },
        },
        {
          id: "Heading1",
          name: "Heading 1",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            size: 40, // 20pt * 2
            bold: true,
            color: colors.blueGrey[800],
            font: "Aptos",
          },
          paragraph: {
            spacing: {
              after: 120,
              line: 360, // 1.5 line spacing
              lineRule: "auto",
            },
          },
        },
        {
          id: "Heading2",
          name: "Heading 2",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            size: 36, // 18pt * 2
            bold: true,
            color: colors.blueGrey[700],
            font: "Aptos",
          },
          paragraph: {
            spacing: {
              before: 80,
              after: 80,
              line: 360, // 1.5 line spacing
              lineRule: "auto",
            },
          },
        },
        {
          id: "Heading3",
          name: "Heading 3",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            size: 32, // 16pt * 2
            bold: true,
            color: colors.grey[700],
            font: "Aptos",
          },
          paragraph: {
            spacing: {
              before: 120,
              after: 80,
              line: 360, // 1.5 line spacing
              lineRule: "auto",
            },
          },
        },
        {
          id: "Heading4",
          name: "Heading 4",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            size: 28, // 14pt * 2
            bold: true,
            color: colors.grey[900],
            font: "Aptos",
          },
          paragraph: {
            spacing: {
              after: 20,
              before: 20,
              line: 360, // 1.5 line spacing
              lineRule: "auto",
            },
          },
        },
        // Normal paragraph style in Aptos 12 pt (24 half-points)
        {
          id: "Normal",
          name: "Normal",
          basedOn: "Normal",
          next: "Normal",
          run: {
            size: 24, // 12pt * 2
            font: "Aptos",
          },
          paragraph: {
            spacing: {
              after: 30,
              line: 360, // 1.5 line spacing
              lineRule: "auto",
            },
          },
        },
        {
          id: "Props",
          name: "Props",
          basedOn: "Normal",
          next: "Normal",
          run: {
            size: 22, // 11pt * 2
            font: "Aptos",
          },
          paragraph: {
            spacing: {
              after: 30,
              line: 360, // 1.5 line spacing
              lineRule: "auto",
            },
          },
        },
        {
          id: "Caption",
          name: "Caption",
          basedOn: "Normal",
          next: "Normal",
          run: {
            size: 20, // 10pt * 2
            font: "Aptos",
          },
          paragraph: {
            spacing: {
              after: 200,
              line: 360, // 1.5 line spacing
              lineRule: "auto",
            },
          },
        },
      ],
    };
  
    // Define numbering for bullet points, numbered lists, and headings
    const numbering = {
      config: [
        // Bullet points
        {
          reference: "my-bullet-points",
          levels: [
            {
              level: 0,
              format: LevelFormat.BULLET,
              text: "•",
              alignment: AlignmentType.LEFT,
              style: {
                paragraph: {
                  indent: {
                    left: convertInchesToTwip(0.25),
                    hanging: convertInchesToTwip(0.25),
                  },
                },
              },
            },
            {
              level: 1,
              format: LevelFormat.BULLET,
              text: "•",
              alignment: AlignmentType.LEFT,
              style: {
                paragraph: {
                  indent: {
                    left: convertInchesToTwip(0.5),
                    hanging: convertInchesToTwip(0.25),
                  },
                },
              },
            },
          ],
        },
        // Numbered lists
        {
          reference: "my-numbered-points",
          levels: [
            {
              level: 0,
              format: LevelFormat.DECIMAL,
              text: "%1.",
              alignment: AlignmentType.LEFT,
              style: {
                paragraph: {
                  indent: {
                    left: convertInchesToTwip(0.25),
                    hanging: convertInchesToTwip(0.25),
                  },
                },
              },
            },
            {
              level: 1,
              format: LevelFormat.DECIMAL,
              text: "%1.%2.",
              alignment: AlignmentType.LEFT,
              style: {
                paragraph: {
                  indent: {
                    left: convertInchesToTwip(0.5),
                    hanging: convertInchesToTwip(0.25),
                  },
                },
              },
            },
          ],
        },
        // Numbered headings
        {
          reference: "heading-numbering",
          levels: [
            {
              level: 0,
              format: LevelFormat.DECIMAL,
              text: "%1.",
              alignment: AlignmentType.LEFT,
              style: {
                paragraph: {
                  indent: {
                    left: convertInchesToTwip(0.0),
                    hanging: convertInchesToTwip(0.25),
                  },
                },
              },
            },
            {
              level: 1,
              format: LevelFormat.DECIMAL,
              text: "%1.%2.",
              alignment: AlignmentType.LEFT,
              style: {
                paragraph: {
                  indent: {
                    left: convertInchesToTwip(0.25),
                    hanging: convertInchesToTwip(0.25),
                  },
                },
              },
            },
            {
              level: 2,
              format: LevelFormat.DECIMAL,
              text: "%1.%2.%3.",
              alignment: AlignmentType.LEFT,
              style: {
                paragraph: {
                  indent: {
                    left: convertInchesToTwip(0.5),
                    hanging: convertInchesToTwip(0.25),
                  },
                },
              },
            },
            // Add more levels if needed
          ],
        },
      ],
    };
  
    // Initialize the document with styles and numbering
    const doc = new Document({
      numbering: numbering,
      styles: styles,
      sections: [
        {
          properties: {},
          children: [
            // Table of Contents
            new Paragraph({
              text: "Table of Contents",
              heading: HeadingLevel.HEADING_1,
              spacing: { after: 200, line: 360 },
              style: "Heading1",
            }),
            new TableOfContents("Contents", {
              hyperlink: true,
              headingStyleRange: "1-6",
            }),
            ...children,
          ],
        },
      ],
    });
  
    return doc;
  };
  
  export { generateDoc };
  