import { Box, Collapse, IconButton, Stack, Typography } from "@mui/material"
import { useState } from "react"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"

const CollapsablePanel = ({ title, children }) => {
    const [open, setOpen] = useState(true)

    return (
        <Box>
            <Stack direction="row" sx={{ alignItems: "center" }}>
                <Typography variant="subtitle1">
                    {title}
                </Typography>
                <IconButton onClick={() => setOpen(!open)}>
                    {open ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
            </Stack>
            <Collapse in={!open}>{children}</Collapse>
        </Box>
    )
}

export default CollapsablePanel
