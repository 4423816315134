import React, { useState } from "react"
import * as common from "./common"
import { green } from "@mui/material/colors"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const Artifact = (props) => {
    const { x, y, height, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = green[200]
    const defaultColor = fillColor || green[100]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    // corner length
    const c = 15

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <path
                d={`M${x + 1},${y} h${width - c} l ${c} ${c} v${
                    height - c
                } h${-width} v${-height}  z`}
                stroke={common.outlineColor}
                fill={fill}
            />

            <path
                d={`M${x + 1 + width - c},${y} v${c} h${c} z`}
                fill={fill === highlightColor ? fill : green[300]}
                stroke={common.outlineColor}
                strokeWidth={0.5}
            />

            <ElementText {...props} label={label} />
        </Svg>
    )
}

export default Artifact
