/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from "react"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"

export default function ComboBox(props) {
  const {
    name,
    label,
    value,
    onChange,
    onKeyPress,
    items,
    disabled = false,
    helperText,
    other,
    groupBy,
    renderGroup,
    noneLabel = "(None)",
    sx = {},
  } = props

  const [inputValue, setInputValue] = useState("")

  const findInitialValue = () => {
    const matched = items.find((option) => option.id === value)
    if (matched) {
      return matched
    }
    return { id: "", label: noneLabel }
  }

  const updateVal = (val) => {
    const selectedValue = val ? val.id : null
    const payload = { target: { name: name, value: selectedValue } }
    onChange(payload)
  }

  return (
    <Autocomplete
      getOptionLabel={(option) => option.label || ""}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      autoHighlight
      autoSelect
      autoComplete
      groupBy={groupBy}
      renderGroup={renderGroup}
      id={name}
      value={findInitialValue()}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      onChange={(_, newValue) => updateVal(newValue)}
      options={items}
      size="small"
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size="small"
          onKeyPress={onKeyPress}
          variant="outlined"
          helperText={helperText}
          sx={sx}
        />
      )}
      {...other}
    />
  )
}
