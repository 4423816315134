import React, { useState, useMemo, useEffect } from "react"
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
} from "@mui/material"
import { passwordStrength } from "check-password-strength"
import PasswordFields from "./PasswordFields"
import { spacing } from "../pages/services/styleServices"

const styles = {
    fields: {
        display: "flex",
        flexDirection: "column",
        gap: spacing(3),
    },
}

const ChangePasswordDialog = (props) => {
    const {
        open,
        setOpen,
        handleChangePassword,
        isInvalidCredentials,
        dialogTitle = "Change Password",
        dialogOkButtonText = "Change Password",
        requireOtp = true,
        requireOldPassword = true,
    } = props

    const initialValues = {
        old_password: "",
        new_password_1: "",
        new_password_2: "",
        otp: "",
    }

    const [values, setValues] = useState(initialValues)

    const isNewPasswordConfirmed = useMemo(
        () =>
            values.new_password_1 === values.new_password_2 && values.new_password_1.trim() !== "",
        [values]
    )

    const pwdStrength = useMemo(() => {
        return values.new_password_1.trim() === ""
            ? ""
            : passwordStrength(values.new_password_1).value
    }, [values])

    const canChangePassword = useMemo(
        () =>
            isNewPasswordConfirmed &&
            ((requireOldPassword && values.old_password.trim() !== "") || !requireOldPassword) &&
            pwdStrength !== "Too weak",
        [values, isNewPasswordConfirmed, pwdStrength, requireOldPassword]
    )

    useEffect(() => {
        if (open) {
            setValues(initialValues)
        }
    }, [open])

    const handleClose = () => {
        setOpen(false)
    }

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                {isInvalidCredentials && (
                    <Box sx={{ marginBottom: "10px" }}>
                        <Alert severity="error">Invalid credentials</Alert>
                    </Box>
                )}
                <FormControl>
                    <Box sx={styles.fields}>
                        <PasswordFields
                            showOldPassword={requireOldPassword}
                            handleInputChange={handleChange}
                            oldPassword={values.old_password}
                            newPassword1={values.new_password_1}
                            newPassword2={values.new_password_2}
                        />
                        {requireOtp && (
                            <TextField
                                id="otp"
                                onChange={handleChange}
                                name="otp"
                                label="6-digit code"
                                variant="outlined"
                                value={values.otp}
                                helperText="Google Authenticator code"
                            />
                        )}
                    </Box>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    onClick={() =>
                        handleChangePassword({
                            oldPassword: values.old_password,
                            newPassword: values.new_password_1,
                            otp: values.otp,
                        })
                    }
                    disabled={!canChangePassword}
                >
                    {dialogOkButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ChangePasswordDialog
