import React from "react"
import {
  Box,
  Card,
  CardContent,
  CardActionArea,
  Divider,
  Grid,
  Typography,
  Chip,
} from "@mui/material"
import { Link } from "react-router-dom"
import PhoneIcon from "@mui/icons-material/Phone"
import EmailIcon from "@mui/icons-material/Email"
import { spacing } from "../pages/services/styleServices"
import * as Roles from "../pages/services/roleServices"
import { aiColor } from "../pages/services/colorServices"

const styles = {
  root: {
    flexGrow: 1,
    minWidth: 250,
    maxWidth: 300,
  },

  smallerIcon: {
    width: 15,
    height: 15,
    paddingTop: 3,
    verticalAlign: "middle",
    paddingRight: 5,
  },
  roles: {
    "& > *": {
      marginRight: spacing(1),
      marginTop: spacing(1),
    },
  },
}

const UserCard = (props) => {
  const { user } = props

  const url = "/UserEdit/" + user.id

  return (
    <Grid item xs={2} sm={3} sx={styles.root} key={user.id}>
      <Box padding={0.1}>
        <Card>
          <CardActionArea component={Link} to={url}>
            <CardContent>
              <Typography
                variant="subtitle2"
                color="textPrimary"
                gutterBottom={true}
                noWrap={true}
                component={"span"}
              >
                <b>{user.name}</b>
              </Typography>
              <Divider />

              {user.email && (
                <div>
                  <nobr>
                    <EmailIcon sx={styles.smallerIcon} />
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      noWrap={true}
                      component={"span"}
                    >
                      {user.email}
                    </Typography>
                  </nobr>
                </div>
              )}

              {user.phone && (
                <div>
                  <nobr>
                    <PhoneIcon sx={styles.smallerIcon} />
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      component={"span"}
                    >
                      {user.phone}
                    </Typography>
                  </nobr>
                </div>
              )}

              <Box sx={styles.roles}>
                {user.roles.map((role) => (
                  <Chip
                    key={role}
                    size="small"
                    sx={
                      role === Roles.AIM_AI ? { backgroundColor: aiColor } : {}
                    }
                    label={Roles.getRoles().find((r) => r.name === role)?.label}
                  />
                ))}
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
    </Grid>
  )
}

export default UserCard
