import React, { useState } from "react"
import * as common from "./common"
import { lightGreen } from "@mui/material/colors"
import BasicShapeWavyBox from "./BasicShapeWavyBox"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const Gap = (props) => {
    const { x, y, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = lightGreen[200]
    const defaultColor = fillColor || lightGreen[100]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <BasicShapeWavyBox {...props} fill={fill} />

            <svg x={x + width - 28} y={y + 4}>
                <circle cx={11} cy={9} r={8} fill='none' stroke={common.outlineColor} />
                <line x1={1} y1={7} x2={21} y2={7} stroke={common.outlineColor} />
                <line x1={1} y1={10} x2={21} y2={10} stroke={common.outlineColor} />
            </svg>

            <ElementText {...props} label={label} />
        </Svg>
    )
}

export default Gap
