import React from "react"
import UserCards from "../components/UserCards"
import Header from "../components/Header"
import { Box } from "@mui/material"

function UserFind() {
    return (
        <Header title="Users">
            <Box padding={1}>
                <UserCards />
            </Box>
        </Header>
    )
}

export default UserFind
