import React, { useState } from "react"
import * as common from "./common"
import { green } from "@mui/material/colors"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const Material = (props) => {
    const { x, y, height, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = green[200]
    const defaultColor = fillColor || green[100]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <rect
                x={x + 1}
                y={y + 1}
                fill={fill}
                stroke={common.outlineColor}
                width={width - 1}
                height={height - 1}
            />

            <svg x={x + width - 22} y={y - 2}>
                <path
                    d={`M5,7 h 8 l 5 7 l -5 7 h -8 l -5 -7 z`}
                    fill='none'
                    stroke={common.outlineColor}
                />

                <line x1={7} y1={9} x2={3} y2={14} stroke={common.outlineColor} />
                <line x1={11} y1={9} x2={15} y2={14} stroke={common.outlineColor} />
                <line x1={5} y1={18} x2={13} y2={18} stroke={common.outlineColor} />
            </svg>

            <ElementText {...props} label={label} />
        </Svg>
    )
}

export default Material
