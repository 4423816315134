import React, { useState, useEffect } from "react"
import ProjectEditForm from "../components/ProjectEditForm"
import Header from "../components/Header"
import { Paper, Tab, Tabs, Typography } from "@mui/material"
import { a11yProps } from "../components/TabPanel"
import { setSelectedTabIndex } from "../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { selectSelectedTabIndex } from "../redux/selectors"
import { spacing } from "../pages/services/styleServices"

const styles = {
    pageContent: {},
    tabLabel: {
        textTransform: "none",
    },
    tab: {
        padding: 0,
        paddingRight: spacing(1.5),
        marginLeft: "5px",
    },
}

const ProjectEdit = (props) => {
    const dispatch = useDispatch()

    const [title, setTitle] = useState("Edit Project")

    const [tabIndex, setTabIndex] = useState(0)

    const selectedTabIndex = useSelector(selectSelectedTabIndex)

    useEffect(() => {
        console.log("selectedTabIndexChanged", selectedTabIndex)
        if (selectedTabIndex) {
            setTabIndex(selectedTabIndex.value)
        } else {
            setTabIndex(0)
        }
    }, [selectedTabIndex])

    const tabLabel = (label) => {
        return (
            <Typography sx={styles.tabLabel} component={"span"}>
                {label}
            </Typography>
        )
    }

    return (
        <Header title={title}>
            <Tabs
                value={tabIndex}
                onChange={(event, newIndex) => {
                    dispatch(setSelectedTabIndex(newIndex))
                    setTabIndex(newIndex)
                }}
                aria-label="Project"
                sx={styles.tabs}
            >
                <Tab
                    label={tabLabel("Project")}
                    {...a11yProps(0)}
                    //icon={<BuildIcon />}
                    iconPosition="start"
                    sx={styles.tab}
                />
                <Tab
                    label={tabLabel("WBS")}
                    {...a11yProps(1)}
                    //icon={<AccountTreeIcon />}
                    iconPosition="start"
                    sx={styles.tab}
                />
                <Tab
                    label={tabLabel("Elements")}
                    {...a11yProps(2)}
                    //icon={<CategoryIcon />}
                    iconPosition="start"
                    sx={styles.tab}
                />
                <Tab
                    label={tabLabel("Stories")}
                    {...a11yProps(3)}
                    //icon={<AutoStoriesIcon />}
                    iconPosition="start"
                    sx={styles.tab}
                />
            </Tabs>

            <Paper sx={styles.pageContent}>
                <ProjectEditForm
                    {...props}
                    setTitle={setTitle}
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                />
            </Paper>
        </Header>
    )
}

export default ProjectEdit
