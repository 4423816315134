import * as actions from '../actionTypes'

const initialState = { 
    value: true
}

export default function showRules(state = initialState, action) {

    switch(action.type) {

        case actions.SET_SHOW_RULES: {

            //console.log('[redux] Setting show rules
            return {
                ...state,
                value: action.payload.value
            }
        }

        default:
            return state
    }
}