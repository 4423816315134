import * as actions from "../actionTypes"
import _ from "lodash"

// initial state is no items selected
// each item is:
//
// <firestore model id>: {
//    id: firestore id
//    name: model name
//    model: JSON data for model, parsed from XML
// }

const initialState = {}

export default function models(state = initialState, action) {
    switch (action.type) {
        case actions.SET_MODEL: {
            // console.log("%c[redux] Setting model", "color:lightGreen", action.payload)

            const newState = {
                ...state,
                [action.payload.value.id]: {
                    ...action.payload.value,
                },
            }

            //console.log("%c[redux] New state", "color:lightGreen", newState)

            return newState
        }

        case actions.REMOVE_MODEL: {
            const newStateArray = Object.entries(state).filter(
                (entry) => entry.key !== action.payload.value.id
            )

            const newState = _.transform(
                newStateArray,
                (a, b) => {
                    return a[b.name] === b.value
                },
                {}
            )

            console.log("%c[redux] New state. Removing model", "color:lightGreen", {
                action,
                state,
                newState,
                newStateArray,
            })

            return newState
        }

        case actions.UPDATE_MODEL_MESSAGES: {
            const currentModelState = state[action.payload.value.modelId]

            if (currentModelState) {
                const newModelState = {
                    ...currentModelState,
                    messages: action.payload.value.messages,
                }

                const newState = {
                    ...state,
                    [action.payload.value.modelId]: newModelState,
                }

                console.log("%c[redux] new state, updated messages", "color:lightgreen", {
                    state,
                    newState,
                    action,
                    currentModelState,
                    newModelState,
                })

                return newState
            } else {
                return state
            }
        }

        default:
            return state
    }
}
