import {
  Alert,
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material"
import Button from "./controls/Button"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { useEffect } from "react"
import * as chatPromptServices from "../pages/services/chatPromptServices"
import * as diagramToPromptServices from "../pages/services/diagramToPromptServices"
import { SiOpenai } from "react-icons/si"
import { useState } from "react"
import * as palette from "./symbols/palette"
import DraggablePaper from "../components/DraggablePaper"

const CreatePromptDialog = (props) => {
  const { open, onClose, promptData } = props

  const [prompt, setPrompt] = useState([])

  const [promptLayers, setPromptLayers] = useState([])

  const [layersUsed, setLayersUsed] = useState([])

  const [includeProperties, setIncludeProperties] = useState(true)

  const [includeDoco, setIncludeDoco] = useState(true)

  const handleCopy = () => {
    navigator.clipboard.writeText(prompt.join("\r"))
  }

  useEffect(() => {
    if (promptData) {
      // setPrompt(
      //     chatPromptServices.createChatPrompt({
      //         promptData,
      //         promptLayers,
      //         includeProperties,
      //         includeDoco,
      //     })
      // )
      setPrompt(
        diagramToPromptServices.createChatPrompt({
          promptData,
          promptLayers,
          includeProperties,
          includeDoco,
        })
      )
    }
  }, [promptData, promptLayers, includeProperties, includeDoco])

  // Detect what layers are used
  useEffect(() => {
    const result = chatPromptServices.getPromptLayers(promptData)
    console.log(result)
    setLayersUsed(result)
    setPromptLayers(result)
  }, [promptData])

  return (
    <Dialog
      open={open}
      scroll={"paper"}
      onClose={onClose}
      PaperComponent={DraggablePaper}
    >
      <DialogTitle sx={{ cursor: "move" }} id="draggable-dialog-title">
        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "nowrap" }}>
          <Box>Create Chat Prompt</Box>
          <Box sx={{ marginLeft: "auto", gap: 2, display: "flex" }}>
            <Tooltip title="Open ChatGPT in new tab">
              <IconButton
                size="small"
                onClick={() => {
                  window.open("https://chat.openai.com/?model=gpt-4", "_openai")
                }}
              >
                <SiOpenai />
              </IconButton>
            </Tooltip>
            <Tooltip title="Copy to clipboard">
              <IconButton size="small" onClick={handleCopy}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box>Copy the prompt below and paste it into ChatGPT</Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "5px",
                marginTop: "10px",
                marginBottom: "10px",
                alignItems: "center",
              }}
            >
              {palette.LAYERS.filter((layer) =>
                layersUsed.includes(layer.name)
              ).map((layer) => (
                <Chip
                  key={layer.name}
                  sx={{
                    backgroundColor:
                      promptLayers.includes(layer.name) && layer.color,
                  }}
                  size="small"
                  label={layer.name}
                  disabled={!layersUsed.includes(layer.name)}
                  variant={
                    promptLayers.includes(layer.name) ? "default" : "outlined"
                  }
                  onClick={(e) => {
                    if (!promptLayers.includes(layer.name)) {
                      setPromptLayers([...promptLayers, layer.name])
                    } else {
                      setPromptLayers(
                        promptLayers.filter((item) => item !== layer.name)
                      )
                    }
                  }}
                />
              ))}

              <FormControlLabel
                control={
                  <Switch
                    checked={includeProperties}
                    onChange={() => setIncludeProperties((curr) => !curr)}
                  />
                }
                label={<Typography variant="caption">Properties</Typography>}
                labelPlacement="start"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={includeDoco}
                    onChange={() => setIncludeDoco((curr) => !curr)}
                  />
                }
                label={<Typography variant="caption">Description</Typography>}
                labelPlacement="start"
              />
            </Box>
            <Box>
              {promptLayers.length === 0 && (
                <Alert
                  severity="info"
                  sx={{ marginTop: "5px", marginBottom: "5px", padding: "3px" }}
                >
                  Select layers to include in the prompt
                </Alert>
              )}
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          {prompt &&
            prompt.map((line, index) => (
              <Box key={`${line}-${index}`} component="span">
                <Typography component={"span"}>{line}</Typography>
              </Box>
            ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} text="Close" />
        <Button
          onClick={handleCopy}
          text="Copy"
          startIcon={<ContentCopyIcon />}
        />
      </DialogActions>
    </Dialog>
  )
}

export default CreatePromptDialog
