import React, { useState } from "react"
import * as common from "./common"
import { green } from "@mui/material/colors"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const Facility = (props) => {
    const { x, y, height, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = green[200]
    const defaultColor = fillColor || green[100]
    const borderDefaultColor = green[200]
    const borderHoverColor = green[400]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    const [borderFill, setBorderFill] = useState(borderDefaultColor)

    const handleMouseOver = () => {
        handleFill(hoverColor)
        setBorderFill(borderHoverColor)
    }
 
    const handleMouseOut = () => {
        handleFill(defaultColor)
        setBorderFill(borderDefaultColor)
    }

    // width of the 3d border
    const borderWidth = 12

    return (
        <Svg
            {...props}
            defaultColor={defaultColor}
            hoverColor={hoverColor}
            setFill={handleFill}
            // We have custom handling of mouseover/out colors so override these event handlers
            handleMouseOver={handleMouseOver}
            handleMouseOut={handleMouseOut}
        >
            <rect
                x={x + 1}
                y={y + borderWidth + 1}
                width={width - borderWidth - 1}
                height={height - borderWidth - 1}
                stroke={common.outlineColor}
                fill={fill}
            />

            <svg x={x + 1} y={y + 1}>
                <path
                    d={`M0,${borderWidth} L ${borderWidth} 1 L ${width - 1} 1 L ${
                        width - 1 - borderWidth
                    } ${borderWidth}`}
                    fill={borderFill}
                    stroke={common.outlineColor}
                />

                <path
                    d={`M${width - 1} 1 L ${width - 1} ${height - 1 - borderWidth} L ${
                        width - 1 - borderWidth
                    } ${height - 1} L ${width - 1 - borderWidth} ${borderWidth}`}
                    fill={borderFill}
                    stroke={common.outlineColor}
                />

                <svg x={width - 35} y={16}>
                    <path
                        d={`M2,1 h 4 v 12 l 4 -4 v 4 l 4 -4 v 4 l 4 -4 v 8 h -16 z`}
                        stroke={common.outlineColor}
                        fill='none'
                    />
                </svg>
            </svg>

            <ElementText {...props} label={label} y={y + borderWidth} width={width - borderWidth} />
        </Svg>
    )
}

export default Facility
