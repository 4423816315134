import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import "firebase/compat/auth"
//import { getMessaging } from "firebase/messaging"
import { getFunctions } from "firebase/functions"
import { getStorage } from "firebase/storage"
import { getAnalytics } from "firebase/analytics"
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"
import * as dotenv from "dotenv"

const config = {
    apiKey: "AIzaSyCqQS_PwoUbH6IgWHBMUG_BbM99VHdYw1Y",
    authDomain: "aim-01.firebaseapp.com",
    projectId: "aim-01",
    storageBucket: "aim-01.appspot.com",
    messagingSenderId: "142565917218",
    appId: "1:142565917218:web:4397d6554733588e73807b",
    measurementId: "G-2PC7Y1WLKD",
}

const app = firebase.initializeApp(config)

//const analytics = getAnalytics(app)

//console.log('!!!!!!!! USING EMULATOR');
//firebase.functions().useEmulator("localhost", 5001);
//firebase.auth().useEmulator("localhost", 9099);

export const provider = new firebase.auth.GoogleAuthProvider()
const auth = firebase.auth()

//const functions = firebase.functions()
const functions = getFunctions(app, "australia-southeast1")

export const db = firebase.firestore()
export const firebaseConfig = config
const storage = getStorage()

//const messaging = getMessaging(app)

// console.log("Clearing persistence")
// db.clearPersistence()
// console.log("Cleared persistence")

// db.enablePersistence()

dotenv.config()

if (typeof process !== "undefined") {
    console.log("%cprocess.env", "color:chartreuse", process.env)

    if (process.env.NODE_ENV === "development") {
        // Bypass recaptcha appcheck if in development, so we can call cloud functions
        // This line must be uncommented for the app to work in development.
        window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = true
        console.log("%c[Firebase] Using appcheck debug token", "color:yellow")
    }
} else {
    console.log("%cprocess object is undefined", "color:chartreuse")
}

const appCheck = initializeAppCheck(app, {
    // This key doesn't need to be protected
    provider: new ReCaptchaV3Provider("6Lfg754dAAAAANlKgPaxjX5eM9ZnYSjjZk3bI9-g"),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true,
})

// We seem to be able to connect to google.com,
// however if the emulator is being used uncomment
// this code and start the emulator with:
// firebase emulators:start

// if (window.location.hostname === "localhost") {
//   db.useEmulator("localhost", 8080);
// }

export const fieldval = firebase.firestore.FieldValue
export default db
export { auth, functions }
