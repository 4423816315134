import React, { useState } from "react"
import Header from "../components/Header"
import TemplateEditForm from "../components/TemplateEditForm"

const TemplateEdit = (props) => {
    const [title, setTitle] = useState("Create Template")

    return (
        <Header title={title}>
            <TemplateEditForm {...props} setTitle={setTitle} />
        </Header>
    )
}

export default TemplateEdit
