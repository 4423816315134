import { Alert, Box, Fab, Stack, Tooltip, Typography } from "@mui/material"
import Controls from "./controls/Controls"
import { useState } from "react"
import AddIcon from "@mui/icons-material/Add"
import * as palette from "./symbols/palette"
import DeleteIcon from "@mui/icons-material/Delete"
import SaveIcon from "@mui/icons-material/Save"
import { useSnackbar, withSnackbar } from "notistack"
import db from "../Firestore"
import * as dataServices from "../pages/services/dataServices"
import { useEffect } from "react"
import firebase from "firebase/compat/app"
import { useHistory, withRouter } from "react-router-dom"
import { spacing } from "../pages/services/styleServices"
import YesNo from "./YesNo"
import LevelSpec from "./LevelSpec"
import { suggestElementType } from "../pages/services/chatGenerationServices"

const styles = {
  fab: {
    position: "fixed",
    bottom: 16,
    right: 16,
    top: "auto",
    left: "auto",
  },

  buttons: {
    marginTop: spacing(3),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "5px",
  },
}

const PromptEditForm = (props) => {
  const [promptId, setPromptId] = useState(props.computedMatch.params.id)

  const [accountId, setAccountId] = useState()

  const [roles, setRoles] = useState()

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const BLANK_LEVEL_SPEC = {
    type: palette.getIndex(palette.CAPABILITY),
    levels: 1,
    info: "",
    attrs: ["name"],
    qty: 5,
    auto_qty: true,
    props: [],
  }

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Delete",
    openPrompt: false,
    description: "Delete?",

    // Callback if user clicks 'Yes' to delete a child record.
    // We set the callback and label depending on what the user is deleting
    handleConfirm: null,
  })

  const [values, setValues] = useState({
    account_id: "",
    name: "",
    description: "",
    levels: [
      {
        ...BLANK_LEVEL_SPEC,
        seq: 1,
        props: [],
      },
    ],
  })

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      if (user !== null) {
        user.getIdTokenResult(true).then((token) => {
          setAccountId(token.claims.account_id)
          setRoles(token.claims.roles)
        })
      }
    })

    return unsub
  }, [])

  useEffect(() => {
    if (promptId) {
      db.collection("prompts")
        .doc(promptId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data()
            const newValues = {
              ...data,
              levels: data.levels.map((lvl) => ({
                ...BLANK_LEVEL_SPEC,
                ...lvl,
              })),
            }
            console.log("prompt values", { newValues })
            setValues(newValues)
          } else {
            enqueueSnackbar("Prompt not found", { variant: "error" })
          }
        })
        .catch((error) => {
          enqueueSnackbar("Error getting prompt", { variant: "error" })
        })
    }
  }, [promptId])

  const isNew = () => promptId === undefined || promptId === ""

  const handlePromptConfirmDelete = (event) => {
    event.preventDefault()

    setYesNoConfig({
      title: "Delete Prompt?",
      openPrompt: true,
      description: "This delete is permanent. Are you sure?",
      handleConfirm: () => handleDeletePromptConfirmed(),
    })
  }

  const hideDeletePrompt = () => {
    const newConfig = {
      ...yesNoConfig,
      openPrompt: false,
    }
    setYesNoConfig(newConfig)
  }

  const handleDeletePromptConfirmed = async () => {
    hideDeletePrompt()

    if (promptId !== undefined && promptId !== "" && promptId !== null) {
      console.log("remove prompt from firestore", { promptId })
      // Delete prompt
      db.collection("prompts")
        .doc(promptId)
        .delete()
        .then(history.push("/prompts"))
        .then(() => {
          enqueueSnackbar("Deleted", { variant: "success" })
        })
    }
  }

  const handleAddLevelSpec = () => {
    setValues((curr) => ({
      ...curr,
      levels: [
        ...curr.levels,
        { ...BLANK_LEVEL_SPEC, seq: findMaxSeq(curr.levels) + 1 },
      ],
    }))
  }

  // Suggest element type based on the prompt only, i.e. we don't have access to the purpose or context
  // if we're just creating reusable prompts
  const handleSuggestElementType = async (levelSpec) => {
    const suggestedElementType = await suggestElementType({
      scope: undefined,
      overview: undefined,
      purpose: undefined,
      prompt: levelSpec.info,
    })

    console.log("suggestedElementType", { suggestedElementType })

    const elementType = palette.getElementType(suggestedElementType.type)
    suggestedElementType.id = elementType.index

    console.log("get type id", { suggestedElementType, elementType })

    return suggestedElementType
  }

  const handleSave = async (event) => {
    event.preventDefault()

    if (values.name === "") {
      enqueueSnackbar("Enter prompt name", { variant: "warning" })
    } else {
      if (isNew()) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then(async (token) => {
            const newPrompt = {
              ...values,
              account_id: token.claims.account_id,
              created: dataServices.serverTimestamp(),
              modified: dataServices.serverTimestamp(),
            }

            console.log("saving prompt", { newPrompt })

            await db
              .collection("prompts")
              .add(newPrompt)
              .then((docRef) => {
                setPromptId(docRef.id)
                history.replace(`/prompt/${docRef.id}`)
              })
              .then(() => {
                enqueueSnackbar("Created", {
                  variant: "success",
                })
              })
          })
          .catch(function (error) {
            console.error("Error:" + error)
            //enqueueSnackbar("Error", { variant: "error " })
          })
      } else {
        const updateRecord = {
          ...values,
          //tags_index: tagServices.toStringArray(values.tags),
          modified: dataServices.serverTimestamp(),
        }

        console.log("saving record", { promptId, updateRecord })

        db.collection("prompts")
          .doc(promptId)
          .update(updateRecord)
          .then(() => {
            enqueueSnackbar("Prompt saved", {
              variant: "success",
              vertical: "bottom",
              horizontal: "right",
            })
          })
      }
    }
  }

  return (
    <>
      <YesNo config={yesNoConfig} />

      <Box>
        <Stack
          direction="column"
          spacing={1}
          sx={{ marginTop: "20px", marginBottom: "20px", gap: 1 }}
        >
          <Controls.TextInput
            label="Prompt Name"
            value={values.name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
            sx={{ width: "350px" }}
            helperText="You can choose to use this prompt in AI Designer to make it easier to create content."
          />

          <Controls.TextInput
            label="Description"
            value={values.description}
            multiline={true}
            onChange={(e) =>
              setValues({ ...values, description: e.target.value })
            }
            sx={{ width: "500px" }}
          />
        </Stack>

        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

        {values.levels.length === 0 && <NoLevelSpecsAlert />}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 1,
          }}
        >
          {values.levels.map((levelSpec, index) => (
            <LevelSpec
              key={levelSpec.seq}
              index={index}
              accountId={accountId}
              mode="extended"
              // This isn't needed when defining prompts to be saved, only when we want to generate content using a prompt in AI Designer
              modelCache={undefined}
              levelSpec={levelSpec}
              levelSpecCount={values.levels.length}
              roles={roles}
              setLevelSpec={(newLevelSpec) => {
                setValues((curr) => {
                  const newValues = { ...curr }
                  newValues.levels[index] = newLevelSpec

                  return newValues
                })
              }}
              handleSuggestElementType={handleSuggestElementType}
              handleDeleteLevelSpec={(seq) => {
                setValues((curr) => ({
                  ...curr,
                  levels: curr.levels.filter((spec) => spec.seq !== seq),
                }))
              }}
            />
          ))}
        </Box>

        <Box sx={styles.buttons}>
          {!isNew() && (
            <Controls.Button
              text="Delete"
              type="button"
              tooltip="Delete this prompt"
              endIcon={<DeleteIcon />}
              onClick={handlePromptConfirmDelete}
            />
          )}

          <Controls.Button
            type="button"
            text="Save"
            variant="contained"
            tooltip="Save prompt"
            endIcon={<SaveIcon />}
            onClick={(event) => handleSave(event)}
          />
        </Box>

        <Tooltip title="Add another level to the hierarchy being created">
          <Fab
            color="primary"
            aria-label="add"
            sx={styles.fab}
            onClick={handleAddLevelSpec}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </Box>
    </>
  )
}

const findMaxSeq = (items) => {
  let maxSeq = 0

  items.forEach((item) => {
    if (item.seq > maxSeq) {
      maxSeq = item.seq
    }
  })

  return maxSeq
}

const NoLevelSpecsAlert = () => {
  return (
    <Box sx={{ width: "300px" }}>
      <Alert severity="info" sx={{ marginTop: "10px" }}>
        <Typography variant="caption">
          Please define at least one level spec. Click <b>Add Level Spec</b>{" "}
          below
        </Typography>
      </Alert>
    </Box>
  )
}

export default withSnackbar(withRouter(PromptEditForm))
