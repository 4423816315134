import React from "react"
import * as common from "./common"

const BaseShapeRoundedRect = (props) => {
    const {
        x,
        y,
        height,
        width,
        fill,
        r = 10,
        borderColor = common.outlineColor,
        borderWidth = 1,
    } = props

    // We need to subtract 2 * r from both the height and width because both height and width each
    // have a radius (r) curve at each end, effectively adding 2 * r to each of the height and width.

    return (
        <path
            d={`M${x + r + 1},${y + 1} h${width - 2 * r} a${r},${r} 0 0 1 ${r},${r} v${
                height - 1 - 2 * r
            } a${r},${r} 0 0 1 -${r},${r} h-${width - 2 * r} a${r},${r} 0 0 1 -${r},-${r} v-${
                height - 1 - 2 * r
            } a${r},${r} 0 0 1 ${r},-${r} z`}
            fill={fill}
            stroke={borderColor}
            strokeWidth={borderWidth}
        />
    )
}

export default BaseShapeRoundedRect
