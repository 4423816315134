import * as actions from '../actionTypes'

// initial state is no items selected
// each item is:
// {
//    id: component doc id
//    label: component name
// }

const initialState = { 
    value: []
}

export default function componentGridSelectedItems(state = initialState, action) {

    switch(action.type) {

        case actions.SET_COMPONENT_GRID_SELECTED_ITEMS: {

            //console.log('[redux] Setting component grid selected items', action.payload)
            return {
                ...state,
                value: action.payload.value
            }
        }

        default:
            return state
    }
}