import { Box, Typography } from "@mui/material"
import { useMemo } from "react"

const ModelEditAppearsIn = (props) => {
    const { currentElement, views, setSelectedItemId, setSelectedViewId } = props

    const appearsInViews = useMemo(() => {
        return views
            .filter((view) => {
                return view.elements.find(
                    (element) =>
                        element.name.toLowerCase() === currentElement?.name?.toLowerCase() &&
                        element.name.trim() !== "" &&
                        element.type === currentElement?.type
                )
            })
            .map((view) => view.name)
    }, [currentElement, views])

    const handleClickView = (viewName) => {
        const itemId = views
            .find((view) => view.name === viewName)
            .elements.find(
                (element) => element.name.toLowerCase() === currentElement.name.toLowerCase()
            )?.id
        setSelectedItemId(itemId)
        setSelectedViewId(views.find((v) => v.name === viewName).id)
    }

    return (
        <Box
            sx={{
                mt: "5px",
                mb: "15px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "5px",
            }}
        >
            <Typography variant="caption" color="primary">
                Appears in:
            </Typography>
            {appearsInViews.map((viewName, index) => (
                <Typography
                    sx={{ cursor: "hand" }}
                    onClick={() => handleClickView(viewName)}
                    key={viewName}
                    variant="caption"
                >
                    {viewName}
                    {index < appearsInViews.length - 1 && ", "}
                </Typography>
            ))}
            {appearsInViews.length === 0 && (
                <Typography variant="caption">
                    No other views
                </Typography>
            )}
        </Box>
    )
}

export default ModelEditAppearsIn
