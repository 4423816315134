import React, { useMemo } from "react"
import { FONT_FAMILY } from "../../pages/services/svgUtils"

const defaultStyles = {
    // Equilvalent of "-webkit-user-select": "none"
    svgText: { WebkitUserSelect: "none", className: "my-text" },
}

const DEFAULT_SHAPE_WIDTH = 140

const ElementText = (props) => {
    const {
        x,
        y,
        width,
        shapeWidth = DEFAULT_SHAPE_WIDTH,
        fontSize = 11,
        fontFamily = FONT_FAMILY,
        label,
        showLabel,
        styles = {},
        labelColor,
        baseCharsPerLine = 20,
    } = props

    const textOffset = { x: 4, y: 4 }

    const splitTextIntoCharacterBlocks = (text) => {
        if (!text) {
            return []
        }

        const scaleWidthFactor = width / shapeWidth
        const maxCharsPerLine = baseCharsPerLine * (scaleWidthFactor > 1 ? scaleWidthFactor : 1)

        const words = (text.constructor === Array ? text.join(" ") : text).split(" ")
        let blocks = []
        let block = ""
        words.forEach((word) => {
            if (block.length + word.length > maxCharsPerLine) {
                blocks.push(block)
                block = ""
            }
            block += word + " "
        })
        blocks.push(block.trim())
        return blocks
    }

    const wrappedText = useMemo(() => {
        if (!showLabel) {
            return []
        }
        const blocks = splitTextIntoCharacterBlocks(label)
        const wrappedText = blocks.map((block, index) => ({ x: 0, y: index * 12, text: block }))
        return wrappedText
    }, [label, showLabel])

    return (
        <g
            fontFamily={fontFamily}
            transform={`translate(${x + textOffset.x},${y + textOffset.y + 10})`}
        >
            <text
                fontSize={fontSize}
                style={{ ...defaultStyles.svgText, ...styles, fill: labelColor }}
            >
                {wrappedText &&
                    wrappedText.map((item, index) => (
                        <tspan key={`${item}-${index}`} x={0} y={index * 14}>
                            {item.text}
                        </tspan>
                    ))}
            </text>
        </g>
    )
}

export default ElementText
