import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import Controls from "./controls/Controls"

const ModelEditCreateDiagramDialog = (props) => {
    const { open, onClose } = props

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create Diagram</DialogTitle>
            <DialogContent>create diagram...</DialogContent>
            <DialogActions>
                <Controls.Button text="Cancel" onClick={onClose} />
            </DialogActions>
        </Dialog>
    )
}

export default ModelEditCreateDiagramDialog
