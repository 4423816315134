import { Box } from "@mui/material"
import React, { useRef } from "react"

const elementStyles = {
    element: {
        margin: "2px",
        padding: "2px",
    },
    elementName: {
        fontWeight: "bold",
    },
}

const ModelEditElementContainer = (props) => {
    const { borderProps } = props

    const elementRef = useRef()

    const childrenWithRef = React.Children.map(props.children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { parentRef: elementRef })
        }
        return child
    })

    return (
        <Box
            sx={{
                ...elementStyles.element,
                ...borderProps,
                //display: "flex",
                //flexDirection: "column",
            }}
            ref={elementRef}
        >
            {childrenWithRef}
        </Box>
    )
}

export default ModelEditElementContainer
