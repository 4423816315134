import firebase from "firebase/compat/app"
import * as cloudFunctions from "./cloudFunctions"

// Authenticate via Google
const PROVIDER_GOOGLE = "google.com"

// Authenticate via app-provided authentication options
const PROVIDER_FIREBASE = "firebase" // a password has not been defined
const PROVIDER_PASSWORD = "password" // a password has been defined

const setPassword = async ({ email, oldPassword, newPassword }) => {
    const user = firebase.auth().currentUser

    if (!isPasswordProviderFound(user)) {
        const newPasswordCredentials = firebase.auth.EmailAuthProvider.credential(
            email,
            newPassword
        )
        console.log("user has no password provider, adding one")
        const linkResult = await user.linkWithCredential(newPasswordCredentials)
        console.log("linkResult", linkResult)
    } else {
        const oldPasswordCredentials = firebase.auth.EmailAuthProvider.credential(
            email,
            oldPassword
        )
        await user.reauthenticateWithCredential(oldPasswordCredentials).then(async () => {
            const updateResult = await user.updatePassword(newPassword)
            console.log("updateResult", updateResult)
        })
    }
}

const isPasswordDefined = (user) => {
    return (
        (user && (user.providerId === PROVIDER_PASSWORD || isPasswordProviderFound(user))) || false
    )
}

const canChangePassword = (user) => {
    return user && (user.providerId === PROVIDER_FIREBASE || user.providerId === PROVIDER_PASSWORD)
}

const changePassword = async ({ email, oldPassword, newPassword, otp }) => {
    console.group("changePassword")

    const result = await cloudFunctions.validateQRCodeOTPAndGetToken({
        email: email,
        password: oldPassword,
        otp: otp,
    })
    console.log("validated QR Code and OTP", { result })

    const user = firebase.auth().currentUser

    user.getIdTokenResult(true).then(async (newToken) => {
        const isDefined = isPasswordDefined(user)

        if (!isDefined) {
            const newPasswordCredential = firebase.auth.EmailAuthProvider.credential(
                email,
                newPassword
            )

            const linkResult = await firebase
                .auth()
                .currentUser.linkWithCredential(newPasswordCredential)

            console.log("linkResult", linkResult)
        } else {
            const oldPasswordCredential = firebase.auth.EmailAuthProvider.credential(
                user.email,
                oldPassword
            )
            await user.reauthenticateWithCredential(oldPasswordCredential)

            const updateResult = await user.updatePassword(newPassword)
            console.log("updateResult", updateResult)
        }
    })
    console.groupEnd()
}

const isPasswordProviderFound = (user) => {
    return user && user.providerData.find((provider) => provider.providerId === PROVIDER_PASSWORD)
}

const isGoogleProvider = (user) => {
    return user && user.providerData.find((provider) => provider.providerId === PROVIDER_GOOGLE)
}

export {
    setPassword,
    isPasswordProviderFound,
    isGoogleProvider,
    canChangePassword,
    isPasswordDefined,
    changePassword,
}
