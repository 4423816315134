import React, { useState } from "react"
import Controls from "./controls/Controls"
import { Box } from "@mui/material"
import Tags from "./Tags"
import { parseTags, mergeTags } from "../pages/services/tagServices"

const RuleTags = (props) => {
    const { tags, handleUpdateTags, editTags = false } = props

    const [addTag, setAddTag] = useState("")

    const handleTagChange = (event) => {
        setAddTag(event.target.value)
    }

    const handleTagKeyPress = (event) => {
        if (event.key === "Enter" && !event.shiftKey) {
            const enteredTags = parseTags(addTag)

            const newTags = enteredTags.filter((newTag) => {
                const exists =
                    tags.find((tag) => tag.label === newTag.label && tag.type === newTag.type) !==
                    undefined

                return !exists
            })

            if (newTags.length > 0) {
                const mergedTags = mergeTags(tags, newTags)
                handleUpdateTags(mergedTags)
            }
            setAddTag("")
        }
    }

    const handleTagDelete = (tagToDelete) => {
        const newTags = tags.filter(
            (tag) => !(tag.type === tagToDelete.type && tag.label === tagToDelete.label)
        )
        handleUpdateTags(newTags)
    }

    return (
        <Box>
            <Box>
                {tags && (
                    <Box style={{ marginTop: "10px" }}>
                        <Tags tags={tags} handleTagDelete={handleTagDelete} editTags={editTags} />
                    </Box>
                )}
            </Box>

            {editTags && (
                <Box style={{ maxWidth: 500, marginTop: "10px" }}>
                    <Controls.TextInput
                        autoFocus
                        name="tag"
                        label="Add rule tag"
                        helperText={`e.g. 'Layer:Application', 'Project Stage: Final'`}
                        value={addTag}
                        onChange={handleTagChange}
                        onKeyPress={(event) => handleTagKeyPress(event)}
                    />
                </Box>
            )}
        </Box>
    )
}

export default RuleTags
