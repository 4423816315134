import { useMemo, useRef } from "react"
import * as palette from "./symbols/palette"
import * as modelServices from "../pages/services/modelServices"
import { Box } from "@mui/material"
import DiagramButtons from "./DiagramButtons"
import { Fragment } from "react"
import {
    getShaderColor,
    getPropTypeCount,
    getLegendLayout,
    START_DATE_LOWER,
    END_DATE_LOWER,
} from "../pages/services/modelEditServices"
import _ from "lodash"
import ElementText from "./symbols/ElementText"
import * as colors from "@mui/material/colors"
import { useWindowDimensions } from "../pages/services/useWindowDimensions"
import { handleDownloadSVG } from "../pages/services/svgServices"
import { FONT_FAMILY } from "../pages/services/svgUtils"

const ModelEditGraphDiagram = (props) => {
    const {
        coords,
        dimensions,
        highlight,
        currentShader,
        shaders,
        showLegend,
        currentView,
        hiddenProps,
        showDocs,
        elementsWithProjects,
        handleCreateChatPrompt,
    } = props

    const svgRef = useRef()

    const legendShapes = [
        // filled circle
        (xPos, yPos, color) => <circle cx={xPos} cy={yPos} r={7} fill={color} />,
        // filled square
        (xPos, yPos, color) => (
            <rect x={xPos - 7} y={yPos - 7} width={14} height={14} fill={color} />
        ),
        // filled triangle
        (xPos, yPos, color) => (
            <svg x={xPos - 7} y={yPos - 7} width={14} height={14}>
                <polygon points="7,0 14,14 0,14" fill={color} />
            </svg>
        ),
        // empty circle
        (xPos, yPos, color) => (
            <circle cx={xPos} cy={yPos} r={6} stroke={color} strokeWidth={2} fill="#fff" />
        ),
        // empty square
        (xPos, yPos, color) => (
            <rect
                x={xPos - 7}
                y={yPos - 7}
                width={12}
                height={12}
                stroke={color}
                strokeWidth={2}
                fill="#fff"
            />
        ),
        // empty triangle
        (xPos, yPos, color) => (
            <svg x={xPos - 7} y={yPos - 7} width={14} height={14}>
                <polygon points="7,0 14,14 0,14" fill="#fff" stroke={color} strokeWidth={2} />
            </svg>
        ),
    ]

    const legendCount = useMemo(() => {
        if (currentView && currentShader) {
            return getPropTypeCount(currentShader.property, currentView.elements)
        }
    }, [currentView, currentShader])

    const sortedCoords = useMemo(() => {
        if (elementsWithProjects) {
            const result = coords
                .sort((a, b) => a.x - b.x > 0 && a.y - b.y > 0)
                .map((element) => {
                    const typeName = palette.getElementNameByIndex(element.item.type)
                    const isRoot =
                        coords.find((coord) =>
                            coord.item.children.find((child) => child.id === element.item.id)
                        ) === undefined
                            ? true
                            : false

                    const elementResult = {
                        ...element,
                        typeName: typeName,
                        isRoot: isRoot,
                        symbol: modelServices.getPaletteItem(typeName),
                        hasChildElements: element.item.children.length > 0,
                        hasProject: Boolean(
                            elementsWithProjects.find(
                                (elementWithProject) =>
                                    elementWithProject.name === element.item.name &&
                                    element.item.type === palette.getIndex(palette.WORK_PACKAGE)
                            )
                        ),
                    }
                    return elementResult
                })

            return result
        }
    }, [coords, elementsWithProjects])

    // See which properties are used to act as an index for what x-position to show an overlay symbol
    const propertiesUsed = useMemo(() => {
        if (currentView) {
            const result = _.uniq(
                currentView.elements.flatMap((element) =>
                    element.props.map((prop) => prop.name.toLowerCase())
                )
            )
                .filter(
                    (name) =>
                        !hiddenProps.includes(name) &&
                        name.toLowerCase() !== START_DATE_LOWER &&
                        name.toLowerCase() !== END_DATE_LOWER
                )
                // Must have a shader defined for the property to be shown in the legend
                .filter((name) => shaders.find((s) => s.name.toLowerCase() === name.toLowerCase()))
                // If there's a current shader, remove this from the list of properties for which
                // a legend will be shown, because if there's a current shader, the legend will be
                // shown for that shader at the top of the diagram
                .filter((name) => !currentShader || name !== currentShader.name.toLowerCase())
                // slice(0, 6) to limit the number of properties shown in the legend to the number of legend shapes that we have
                .slice(0, 6)

            return result
        }
        return undefined
    }, [currentView, hiddenProps, shaders, currentShader])

    const getPropertyValuesUsed = (view, propertyName) => {
        if (view && propertyName) {
            const result = view.elements.reduce((acc, element) => {
                const elementValues = element.props
                    .filter((prop) => prop.name.toLowerCase() === propertyName)
                    .map((prop) => prop.value?.toString())
                return [...acc, ...elementValues]
            }, [])

            return result
        }
    }

    const propertyValuesUsed = useMemo(() => {
        if (currentView && propertiesUsed) {
            const values = currentView.elements.reduce((acc, element) => {
                const elementValues = element.props
                    .filter((prop) => propertiesUsed.includes(prop.name.toLowerCase()))
                    .map((prop) => ({ name: prop.name.toLowerCase(), value: prop.value }))
                return [...acc, ...elementValues]
            }, [])

            const result = _.uniqWith(values, _.isEqual)

            const names = _.uniq(result.map((r) => r.name))

            const keysWithValuesArray = names.map((name) => {
                const values = result.filter((r) => r.name === name).map((r) => r.value)
                return { name, values }
            })

            return keysWithValuesArray
        }
    }, [propertiesUsed, currentView])

    const { width, height } = useWindowDimensions()

    const legendLayouts = useMemo(() => {
        const result =
            propertiesUsed?.map((name, index) => {
                const shader = shaders.find((s) => s.property.toLowerCase() === name.toLowerCase())
                const legendLayout = getLegendLayout(shader, width)
                return legendLayout
            }) || []

        //console.log("legendLayouts", { propertiesUsed, result, currentShader })

        // Add a cumulative line count to each legend layout

        let cumulativeLineCount = 0
        result.forEach((layout) => {
            layout.prevTotalLineCount = cumulativeLineCount
            cumulativeLineCount += layout.lineCount
        })

        return result
    }, [propertiesUsed, shaders, width, currentShader])

    // How much space to leave for the legend at the top of the diagram
    const legendOffset = useMemo(
        () =>
            showLegend && currentShader
                ? getLegendLayout(currentShader, width).lineCount * 25 + 20
                : 0,
        [currentShader, showLegend, width]
    )

    const spaceForLastRowOfDoco = useMemo(() => {
        const result = showDocs === "show" ? 70 : 0
        return result
    }, [showDocs])

    const diagramHeight = useMemo(() => {
        const result =
            //dimensions.height +
            dimensions.boundingHeight +
            20 +
            //spaceForLastRowOfDoco +
            legendOffset +
            // If showing legend, add the cumulative number of space required for all legend rows
            (showLegend
                ? legendLayouts.reduce((acc, layout) => acc + layout.lineCount * 25 + 40, 0)
                : 0)

        return result
    }, [dimensions.height, legendOffset, legendLayouts, showLegend, spaceForLastRowOfDoco])

    const debugDiagramHeight = useMemo(() => {
        const items = []

        if (legendOffset > 0) {
            items.push({ name: "legend", height: legendOffset, color: colors.red[400] })
        }
        items.push({ name: "diagram", height: dimensions.boundingHeight, color: colors.blue[400] })

        // Work out the cumulative y position of each item. The first element is at y=0, the second
        // is at y=height of first element, the third is at y=height of first element + height of second element, etc.

        let cumulativeY = 0
        items.forEach((item) => {
            item.y = cumulativeY
            cumulativeY += item.height
        })

        return items
    }, [dimensions, legendOffset])

    return (
        <Box>
            <Box
                sx={{
                    marginTop: "20px",
                    width: dimensions.width,
                    height: diagramHeight,
                }}
            >
                {dimensions.width > 0 && (
                    <svg
                        width={dimensions.width}
                        height={diagramHeight}
                        viewBox={`0 0 ${dimensions.width} ${diagramHeight}`}
                        ref={svgRef}
                    >
                        <style>{`.my-text { font-family: 'Manrope', sans-serif; }`}</style>

                        <rect
                            x={0}
                            y={0}
                            fill={"#fff"}
                            stroke={"#fff"}
                            width={dimensions.width}
                            height={diagramHeight}
                        />
                        {sortedCoords.map((item) => (
                            <Fragment key={`${item.item.id}-${item.x}-${item.y}`}>
                                <item.symbol
                                    x={item.x}
                                    y={item.y + legendOffset}
                                    width={item.width}
                                    height={item.height}
                                    label={item.item.name}
                                    showLabel={true}
                                    highlight={highlight}
                                    highlightColor={getShaderColor(item.item, currentShader)}
                                />
                                {propertiesUsed &&
                                    item.item.props
                                        .filter((prop) => {
                                            return propertiesUsed.includes(prop.name.toLowerCase())
                                        })
                                        .map((prop) => {
                                            const xPosIndex = propertiesUsed.indexOf(
                                                prop.name.toLowerCase()
                                            )
                                            const xPos = item.x + item.width - xPosIndex * 15
                                            const shader = shaders.find(
                                                (s) =>
                                                    s.property.toLowerCase() ===
                                                    prop.name.toLowerCase()
                                            )
                                            const color = getShaderColor(item.item, shader)

                                            return (
                                                <Fragment key={`${prop.value}-${color}`}>
                                                    {legendShapes[xPosIndex](
                                                        xPos,
                                                        item.y + legendOffset,
                                                        color
                                                    )}
                                                </Fragment>
                                            )
                                        })}
                                {item.hasProject && (
                                    <circle
                                        cx={item.x + 3}
                                        cy={item.y + legendOffset + 3}
                                        r={5}
                                        fill={colors.green[400]}
                                    />
                                )}
                                {showDocs === "show" && (
                                    <ElementText
                                        {...props}
                                        label={[
                                            item.item.description,
                                            ...item.item.props
                                                .filter((prop) => prop?.reason)
                                                .filter(
                                                    (prop) =>
                                                        !hiddenProps.includes(
                                                            prop.name.toLowerCase()
                                                        )
                                                )
                                                .map((prop) => `[${prop.name}]: ${prop.reason}`),
                                        ]}
                                        showLabel={true}
                                        x={item.hasChildElements ? item.x + 10 : item.x - 6}
                                        y={
                                            (item.hasChildElements
                                                ? item.y + 30
                                                : item.y + item.height) + legendOffset
                                        }
                                        width={item.width + 25}
                                        fontSize={11}
                                        shapeWidth={item.width}
                                        // 140 is the standard element width
                                        // 26 is number of chars per line when using 140 as width
                                        baseCharsPerLine={(item.width / 140) * 25}
                                    />
                                )}
                            </Fragment>
                        ))}

                        {showLegend && currentShader && propertyValuesUsed && (
                            <SvgLegend
                                legendValues={getPropertyValuesUsed(
                                    currentView,
                                    currentShader?.property.toLowerCase()
                                )}
                                legendLayouts={[
                                    {
                                        prevTotalLineCount: 0,
                                        ...getLegendLayout(currentShader, width),
                                    },
                                ]}
                                shader={currentShader}
                                title={currentShader.name}
                                yOffset={10}
                                legendCount={legendCount}
                            />
                        )}

                        {showLegend && (
                            <Legends
                                propertiesUsed={propertiesUsed}
                                propertyValuesUsed={propertyValuesUsed}
                                dimensions={dimensions}
                                legendOffset={legendOffset}
                                shaders={shaders}
                                legendShapes={legendShapes}
                                spaceForLastRowOfDoco={spaceForLastRowOfDoco}
                            />
                        )}

                        {/* uncomment this to show a vertical series of lines showing the main height segmentation of the diagram *}
                        {/* {debugDiagramHeight.map((item) => (
                            <Fragment key={item.name}>
                                <rect
                                    x={0}
                                    y={item.y}
                                    fill={item.color}
                                    width={3}
                                    height={item.height}
                                />
                                <text x={5} y={item.y + 10} fontSize={15} fill={item.color}>
                                    {item.name}
                                </text>
                            </Fragment>
                        ))} */}
                    </svg>
                )}
            </Box>

            <Box>
                <DiagramButtons
                    handleCreateStory={() => console.log("no create story")}
                    svgRef={svgRef}
                    handleDownloadSVG={handleDownloadSVG}
                    showCreateStoryButton={false}
                    showCopyButton={true}
                    handleCreateChatPrompt={handleCreateChatPrompt}
                />
            </Box>
        </Box>
    )
}

const Legends = (props) => {
    const {
        propertiesUsed,
        propertyValuesUsed,
        dimensions,
        legendOffset,
        shaders,
        legendShapes,
        spaceForLastRowOfDoco,
    } = props

    const LEGEND_MARGIN_TOP = 20

    const { width, height } = useWindowDimensions()

    const legendLayouts = useMemo(() => {
        if (propertyValuesUsed && shaders && width) {
            const result = propertyValuesUsed.map((pv) => {
                const shader = shaders.find(
                    (s) => s.property.toLowerCase() === pv.name.toLowerCase()
                )
                const legendLayout = getLegendLayout(shader, width)
                return legendLayout
            })

            // Add a cumulative line count to each legend layout

            let cumulativeLineCount = 0
            result.forEach((layout) => {
                layout.prevTotalLineCount = cumulativeLineCount
                cumulativeLineCount += layout.lineCount
            })

            return result
        }
    }, [propertyValuesUsed, shaders, width])

    return (
        <Fragment>
            {propertiesUsed &&
                propertiesUsed.map((name, index) => {
                    const shader = shaders.find(
                        (s) => s.property.toLowerCase() === name.toLowerCase()
                    )
                    const xPosIndex = propertiesUsed.indexOf(name)
                    const shape =
                        xPosIndex >= 0 ? legendShapes[xPosIndex](10, 10, "#bbb") : undefined

                    const legendLayout = legendLayouts[index]

                    if (shader) {
                        return (
                            <SvgLegend
                                key={shader.property}
                                legendValues={propertyValuesUsed
                                    .find(
                                        (pv) =>
                                            pv.name.toLowerCase() === shader.property.toLowerCase()
                                    )
                                    .values.map((val) => val.toString())}
                                legendLayouts={legendLayouts}
                                shader={shader}
                                shape={shape}
                                title={shader.name}
                                yOffset={
                                    LEGEND_MARGIN_TOP +
                                    dimensions.boundingHeight +
                                    //spaceForLastRowOfDoco +
                                    legendOffset +
                                    index * 50 +
                                    legendLayout.prevTotalLineCount * 20
                                }
                            />
                        )
                    }
                    return undefined
                })}
        </Fragment>
    )
}

const SvgLegend = ({
    shader,
    yOffset,
    legendValues,
    legendCount = {},
    title,
    shape,
    legendLayouts,
}) => {
    const legendLayout = useMemo(() => {
        const legendLayout = legendLayouts.find(
            (layout) => layout.property.toLowerCase() === shader.property.toLowerCase()
        )
        return legendLayout
    }, [legendLayouts, shader])

    return (
        <>
            {title && legendLayout && (
                <g fontFamily={FONT_FAMILY} transform={`translate(0,${yOffset + 5})`} fill="#777">
                    <text fontSize={11}>
                        <tspan x={shape ? 25 : 10} y={0}>
                            {title}
                        </tspan>
                    </text>
                </g>
            )}
            {shape && (
                <svg x={5} y={yOffset - 9}>
                    {shape}
                </svg>
            )}
            {legendLayout &&
                legendValues &&
                shader.config.colors
                    .filter((c) => legendValues.includes(c.value))
                    .sort((a, b) => a.value.toString().localeCompare(b.value.toString()))
                    .map((c) => ({ ...c, len: c.value.length }))
                    .map((c, index) => {
                        const row = Math.floor(index / legendLayout.itemsPerLine)
                        const col = index % legendLayout.itemsPerLine

                        const xOffset = legendLayout.xOffset * col
                        const yOffsetMod = yOffset + row * 25

                        return (
                            <Fragment key={`${shader.name}-${c.value}`}>
                                <svg x={xOffset} y={yOffsetMod}>
                                    <g fontFamily={FONT_FAMILY} transform={`translate(0,${12})`}>
                                        <text fontSize={11}>
                                            <tspan x={45} y={17}>
                                                {c.value}
                                            </tspan>
                                        </text>
                                    </g>
                                    <rect x={10} y={14} fill={c.color} width={30} height={20} />
                                    <g fontFamily={FONT_FAMILY} transform={`translate(0,${12})`}>
                                        <text fontSize={11}>
                                            <tspan x={25} y={16} textAnchor="middle">
                                                {legendCount[c.value.toString().toLowerCase()]}
                                            </tspan>
                                        </text>
                                    </g>
                                </svg>
                            </Fragment>
                        )
                    })}
        </>
    )
}

export default ModelEditGraphDiagram
