import React from "react"
import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    RadioGroup as MuiRadioGroup,
    Typography,
} from "@mui/material"
import Radio from "@mui/material/Radio"

const RadioGroup = (props) => {
    const { name, label, value, onChange, items } = props

    return (
        <FormControl>
            <FormHelperText>
                <Typography variant="caption" component={"span"}>
                    {label}
                </Typography>
            </FormHelperText>
            <MuiRadioGroup row name={name} value={value} onChange={onChange}>
                {items.map((item) => (
                    <FormControlLabel
                        key={item.id}
                        value={item.id}
                        control={<Radio />}
                        label={item.title}
                    />
                ))}
            </MuiRadioGroup>
        </FormControl>
    )
}

export default RadioGroup
