import * as actions from "../actionTypes"

const initialState = {
    // projects -- 'items' is named this way since the corresponding 'components' redux reducer also has an 'items' property
    // so that we can treat these 2 reducers the same from the ExplorerForm
    items: [],
}

export default function projects(state = initialState, action) {
    switch (action.type) {
        case actions.SET_PROJECTS: {
            const newState = action.payload.value
            
            console.log("%c[redux] Setting projects", "color:lightgreen", { action, newState })

            return newState
        }

        default:
            return state
    }
}
