import {
    Box,
    CircularProgress,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material"
import StopIcon from "@mui/icons-material/Stop"
import { useState } from "react"

const AIProgress = ({ generationPanelCount, handleRequestStop, message }) => {
    const [requestedStop, setRequestedStop] = useState(false)

    const [overrideMessage, setOverrideMessage] = useState("")

    return (
        <>
            {generationPanelCount > 0 && (
                <Stack direction="row" gap={2} sx={{ alignItems: "center" }}>
                    <Box>
                        <CircularProgress size={20} />
                    </Box>
                    <Box>
                        <Typography variant="caption">{overrideMessage || message}</Typography>
                    </Box>
                    <Box>
                        <Tooltip title="Stop">
                            <span>
                                <IconButton
                                    onClick={() => {
                                        setRequestedStop(true)
                                        handleRequestStop && handleRequestStop()
                                        setOverrideMessage("Stopping...")
                                    }}
                                    disabled={requestedStop}
                                >
                                    <StopIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>
                </Stack>
            )}
        </>
    )
}

const progressMessages = [
    "Resurrecting ghost architects, hold on to your drafting tables!",
    "Mining the cloud for the holy grail of blueprints.",
    "Teaching AI to appreciate the beauty of brutalist architecture.",
    "Deploying intergalactic minions to fetch your data.",
    "Raiding the lost Ark for divine design inspirations.",
    "Herding virtual cats to keep the data flow steady.",
    "Negotiating pixel-pebbles with 3D termites.",
    "Summoning architectural gnomes from their virtual garden.",
    "Playing Tetris with your request, it's a high score!",
    "Digitally reassembling Stonehenge for the perfect layout.",
    "Invoking Fibonacci to balance the universe... and your designs.",
    "Propelling magic carpet to fetch Aladdin's design advice.",
    "Convincing the server hamsters to run just a little bit faster.",
    "Deploying virtual kangaroos to jump through your data hoops.",
    "Bribing the laws of physics for more pleasing architecture.",
    "Pushing pixels uphill, in the snow, both ways!",
    "Energizing the flux capacitor for optimal rendering.",
    "Digitally whitewashing the virtual fence of data.",
    "Asking the Oracle at Delphi for your data... Stay tuned.",
    "Negotiating space-time continuum for faster results.",
    "Sharpening virtual pencils for a grand design reveal.",
    "Activating digital gnomon to cast the perfect shadow.",
    "Donning our virtual hard hats. Safety first!",
    "Practicing the fine art of digital Feng Shui.",
    "Sending carrier pigeons to the cloud for your data.",
    "Training scribes to transcribe the ancient scrolls of your data.",
    "Dispatching knights on a quest for your golden ratios.",
    "Summoning the alchemists to transmute raw data into design gold.",
    "Preparing the castle gates for the influx of your information.",
    "Winding up the drawbridge for your data's royal entry.",
    "Brewing a potion of efficiency in the cauldron of innovation.",
    "Taming the dragon of latency, one fiery breath at a time.",
    "Tuning the lute for a ballad of your architectural masterpiece.",
    "Sending pigeons to the realm of the cloud kingdom for your data.",
    "Polishing the royal crown for your regal design reveal.",
    "Awakening Merlin to cast a spell of speed on your data.",
    "Distilling the essence of the Philosopher's Stone for perfect symmetry.",
    "Consulting the stars for divine architectural inspiration.",
    "Embarking on a Crusade for the Holy Grail of architecture.",
    "Firing the catapults to break the wall of loading time.",
    "Conjuring the spirits of the ancient architects.",
    "Ploughing the digital fields for a fruitful design harvest.",
    "Firing up the blacksmith's forge for your custom data armor.",
    "Unleashing the jesters for a parade of playful design.",
    "Hoisting the sails for a voyage across the sea of data.",
    "Knocking the digital coconuts together for your data horse.",
    "Sharpening the swords for a duel with data delays.",
    "Jacking into the mainframe, brace for Neo-style awesomeness.",
    "Dodging data bullets in super slow-motion.",
    "Deciphering the cascading green code of your design.",
    "Wielding the katana of speed against loading time.",
    "Taking the red pill for a journey into the matrix of your data.",
    "Finding the right keymaker to unlock your architectural dream.",
    "Training in the dojo for a showdown with data discrepancies.",
    "Unleashing digital kung fu on your data challenge.",
    "Calling upon the Oracle for a glimpse of your design's future.",
    "Entering the Zion mainframe for a rave of information.",
    "Uploading the bullet-time program for faster data processing.",
    "Freeing your mind for a zero-gravity design experience.",
    "Diving headfirst into the rabbit hole of creativity.",
    "Activating the Nebuchadnezzar's engines for a cruise through data land.",
    "Preparing to bend the spoon of your architectural reality.",
    "Sentinels on the prowl for your lost data.",
    "Dodging Agent Smith's attempts to slow us down.",
    "Downloading data from the source, hold on to your trench coat!",
    "Recharging in the construct, for a fresh design perspective.",
    "Engaging hyperdrive for light-speed design delivery.",
    "Sharpening our witty retorts and suave design skills.",
    "Infiltrating the danger zone for a perilous data mission.",
    "Mixing a vodka martini for a smooth design execution.",
    "Entering the KGB database, please stand by for intrigue and banter.",
    "Strapping on tactical turtlenecks for a stealthy data operation.",
    "Negotiating with Babou, the ocelot, for faster results.",
    "Jet-setting to exotic locales in search of your elusive data.",
    "Dodging rogue agents and loose cannon coders in the data field.",
    "Deploying Lana's monster hands for heavy-duty data handling.",
    "Awakening Woodhouse to sort through the mess of your request.",
    "Diving deep undercover for covert data extraction.",
    "Tossing a grenade at the wall of slow loading times.",
    "Bracing for a high-speed car chase across the data highway.",
    "Ransacking the fridge for Bloody Mary mix and fresh data.",
    "Piloting the airship Excelsior through turbulent data clouds.",
    "Surviving yet another voicemail prank while waiting for your data.",
    "Navigating through layers of deception and encryption for your request.",
    "Infiltrating the underworld for a rendezvous with your data.",
    "Engaging the enemy in a high-stakes poker game for your results.",
    "Enjoying a relaxing spa day while your data loads... Just kidding, we're decoding!",
    "Mixing danger and design, shaken, not stirred.",
    "Sneaking through the vents to spy on your elusive data.",
    "Commandeering a speedboat for a high-speed data chase.",
    "Stealing secrets from rival agencies for that competitive edge in design.",
    "Using Ray's bionic legs to outrun slow loading times.",
    "Hunting for the perfect tuxedo and your impeccable design.",
    "Calling a field agent for an extraction of your data.",
    "Outrunning gators in the Everglades to retrieve your data.",
    "Disguising ourselves for a covert rendezvous with your request.",
    "Being chased by an angry Russian mob... of data points.",
    "Conducting a clandestine midnight exchange for your design specifics.",
    "Negotiating with Cheryl's ocelot for faster rendering times.",
    "Decoding Cyril's accounting spreadsheets for your architectural needs.",
    "Seducing enemy agents for top-secret design tips.",
    "Hijacking a space shuttle for a trip to the data cosmos.",
    "Unleashing Pam's bare-knuckle fury on laggy load times.",
    "Coping with Krieger's weird science to streamline your design process.",
    "Finding ourselves in another pickle while waiting for your data.",
    "Riding choppers across the desert of data for your perfect design.",
    "Battling cyber-ninjas in a virtual showdown for your request.",
    "Stoking the coal engine for a steam-powered data crunch.",
    "Winding up the cogs and gears for your bespoke design.",
    "Hitching a ride on the airship for a journey through the data fog.",
    "Firing up the brass boiler for full steam data processing.",
    "Setting the chronometer for a timely data delivery.",
    "Assembling the mechanical minions to work on your request.",
    "Polishing the monocle for a closer look at your architectural vision.",
    "Navigating the data whirlwinds in our steam-propelled zeppelin.",
    "Unleashing the steam kraken on the sea of information.",
    "Inspecting the gears and levers of our data processing machine.",
    "Sharpening the quill to etch your design onto brass blueprints.",
    "Summoning mechanical owls for swift data delivery.",
    "Bellowing into the speaking tubes to speed up the data churn.",
    "Charting the star maps for an astral inspired design.",
    "Feeding the raven with a message to retrieve your data.",
    "Turning the brass key in the clockwork engine of your design.",
    "Strapping on the jetpack for a skyward data quest.",
    "Inking the parchment with details of your architectural masterpiece.",
    "Loading the cannons for a barrage on the fortress of slow loading times.",
    "Releasing the steam-driven falcons in search of your data.",
]

export default AIProgress
