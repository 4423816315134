import React, { useState, useEffect } from "react"
import { withSnackbar } from "notistack"
import { useHistory, withRouter } from "react-router-dom"
import {
  selectAutofitDiagram,
  selectDiagramScale,
  selectModelState,
  selectShowDocumentationIndicator,
  selectShowTooltipsIndicator,
  selectShowRules,
  selectAutoLegend,
  selectEditMode,
} from "../redux/selectors"
import {
  Typography,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  IconButton,
} from "@mui/material"
import _ from "lodash"
import GridLabel from "./GridLabel"
import ModelBreadcrumbs from "./ModelBreadcrumbs"
import DiagramResize from "./DiagramResize"
import TooltipHover from "./TooltipHover"
import { splitText } from "../pages/services/tooltipServices"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore"
import Controls from "./controls/Controls"
import * as urlServices from "../pages/services/urlServices"
import { setModelState, setEditMode } from "../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import db from "../Firestore"
import firebase from "firebase/compat/app"
import { Link } from "react-router-dom"
import DocumentationIndicator from "./DocumentationIndicator"
import Diagram from "./Diagram"
import * as mlServices from "../pages/services/mlServices"
import * as modelServices from "../pages/services/modelServices"
import * as palette from "./symbols/palette"
import TooltipsIndicator from "./TooltipsIndicator"
import TagPanel from "./TagPanel"
import * as dataServices from "../pages/services/dataServices"
import AutoLegend from "./AutoLegend"
import ToggleEditing from "./ToggleEditing"
import TagSummary from "./TagSummary"
import { spacing } from "../pages/services/styleServices"
import * as chatPromptServices from "../pages/services/chatPromptServices"
import CreatePromptDialog from "./CreatePromptDialog"
import ChatDialog from "./ChatDialog"

const styles = {
  documentation: {
    display: "flex",
    flexDirection: "column",
    marginBottom: spacing(1),
    marginTop: spacing(1),
  },
  storyLinks: {
    marginTop: "15px",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    gap: spacing(2),
    marginLeft: spacing(2),
  },
  openAiQuery: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: spacing(2),
    marginLeft: spacing(2),
    gap: spacing(2),
  },
}

const ViewEditForm = (props) => {
  const [params, setParams] = useState({})

  const showRules = useSelector(selectShowRules)

  useEffect(() => {
    const { parentId, viewId, fileName } = props.computedMatch.params
    const newParams = { parentId, viewId, fileName, parent: { id: parentId } }
    //console.log("%cupdate params", "color:yellow", { newParams, props })
    setParams(newParams)
  }, [props])

  const modelCache = useSelector(selectModelState)

  const [view, setView] = useState()

  // The model from which this view is taken
  const [model, setModel] = useState()

  const dispatch = useDispatch()

  // Same view name from other files loaded into parent (project or component)
  const [otherViews, setOtherViews] = useState([])

  const [viewOptions, setViewOptions] = useState()

  const [similarViewsByFile, setSimilarViewsByFile] = useState()

  const [showChat, setShowChat] = useState(false)

  const [showChatPrompt, setShowChatPrompt] = useState(false)

  const [chatPromptData, setChatPromptData] = useState({})

  const [stories, setStories] = useState()

  const { setTitle } = props

  const autoFitToWindow = useSelector(selectAutofitDiagram)

  const diagramScale = useSelector(selectDiagramScale)

  const showDocumentation = useSelector(selectShowDocumentationIndicator)

  const autoLegend = useSelector(selectAutoLegend)

  const showTooltips = useSelector(selectShowTooltipsIndicator)

  // TEMPORARY: need to work out where to store these
  const [tags, setTags] = useState([])

  const [width, setWidth] = useState()

  const [messages, setMessages] = useState([])

  const history = useHistory()

  const [loadState, setLoadState] = useState({})

  const [accountId, setAccountId] = useState()

  const [highlightElementIds, setHighlightElementIds] = useState([])

  const viewEditInitialState = useSelector(selectEditMode)

  const [isViewEditing, setViewEditing] = useState(viewEditInitialState)

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult(false).then((token) => {
          setAccountId(token.claims.account_id)
        })
      }
    })

    return unsub
  }, [])

  useEffect(() => {
    if (modelCache && params) {
      console.log("find similar views", { params, modelCache })

      if (params.parentId && params.fileName) {
        const mci = getModelFromCache(
          modelCache,
          params.parentId,
          params.fileName
        )

        //console.log("%cmodelCacheItem", "color:pink", mci)

        if (mci) {
          const view = mci.model.views.find((view) => view.id === params.viewId)

          const similarViews = mlServices
            .findSimilarViews(view, mci, modelCache)
            .filter((sv) => sv.score > 0.5)

          const similarViewsByFile = _.groupBy(
            similarViews,
            (sv) => `${sv.name} - ${sv.file}`
          )
          //console.log("similarViewsByFile", similarViewsByFile)

          setSimilarViewsByFile(similarViewsByFile)
        }
      }
    }
  }, [modelCache, params])

  const [dataReloadState, setDataReloadState] = useState({})

  useEffect(() => {
    if (accountId && params && modelCache) {
      // Load tags for project/component

      const newDataReloadState = {
        accountId,
        params,
        cacheKeys: Object.keys(modelCache),
        //viewId: view.id,
        viewId: params.viewId,
      }
      if (!_.isEqual(newDataReloadState, dataReloadState)) {
        setDataReloadState(newDataReloadState)
      } else {
        return
      }

      //console.log("%cloading", "color:yellow", { newDataReloadState })

      const modelCacheItem = getModelFromCache(
        modelCache,
        params.parentId,
        params.fileName
      )

      if (modelCacheItem === undefined) {
        // Can happen if we refresh page which resets cache
        return
      }

      // console.log("%cgot model cache item", "color:pink", {
      //     modelCacheItem,
      //     params,
      //     modelCache,
      // })
      if (modelCacheItem.type === "project") {
        // console.log("%cgetProjectsByIdChunks", "color:yellow", {
        //     parentId: params.parentId,
        // })
        dataServices
          .getProjectsByIdChunks([params.parentId])
          .then((projects) => {
            //console.log("%cloaded projects", "color: chartreuse", projects)

            if (projects.length > 0) {
              const viewTags =
                //projects[0]?.view_tags?.find((item) => item.view_id === view.id)?.tags ||
                projects[0]?.view_tags?.find(
                  (item) => item.view_id === params.viewId
                )?.tags || []
              //console.log("viewTags", viewTags)
              setTags(viewTags)
            }
          })
      } else {
        dataServices
          .getComponentsByIdChunks(accountId, [params.parentId])
          .then((components) => {
            //console.log("%cloaded components", "color: chartreuse", components)

            if (components.length > 0) {
              const viewTags =
                //components[0]?.view_tags?.find((item) => item.view_id === view.id)
                components[0]?.view_tags?.find(
                  (item) => item.view_id === params.viewId
                )?.tags || []
              setTags(viewTags)
            }
          })
      }
    }
  }, [accountId, modelCache, params])

  const [loadFileState, setLoadFileState] = useState({})

  useEffect(() => {
    if (!params.hasOwnProperty("viewId")) {
      return
    }

    if (!accountId) {
      return
    }

    if (!modelCache) {
      return
    }

    const newLoadFileState = { params, accountId }
    if (!_.isEqual(loadFileState, newLoadFileState)) {
      setLoadFileState(newLoadFileState)
    } else {
      return
    }
    //console.log("%cuseEffect::Loading view", "color:pink", { params, modelCache, accountId })

    // Check if we should run this hook, only run if all params changed
    const { parentId, viewId, fileName } = params

    console.log("%cparams", "color: lightgreen", { params, accountId })
    const newLoadState = { ...params }
    const isReload = !_.isEqual(loadState, newLoadState)
    setLoadState(newLoadState)
    if (!isReload) {
      //console.log("skipping reload, load params did not change")
      return
    }

    // This is a model from the cache

    const modelCacheItem = getModelFromCache(modelCache, parentId, fileName)

    if (modelCacheItem) {
      setModel(modelCacheItem)

      initViewOptions(modelCacheItem)

      const view = modelCacheItem.model.views.find((view) => view.id === viewId)
      //console.log("%cview", "color: chartreuse", view)
      setView(view)

      loadStories({ accountId, parentId, viewId: view.id, fileName: fileName })

      setTitle(`${view.name} :: ${fileName}`)

      initOtherViews({ modelCache, parentId, view, fileName })
    } else {
      db.collection("model_index")
        .where("parent_id", "==", parentId)
        .where("account_id", "==", accountId)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            const modelIndex = doc.data()

            const typeMap = { project: "projects", component: "components" }
            const subFolder = typeMap[modelIndex.type]
            const filePath = `accounts/${accountId}/${subFolder}/${modelIndex.parent_id}/`
            const collection = typeMap[modelIndex.type]

            // Check if this is already in cache

            db.collection(collection)
              .doc(modelIndex.parent_id)
              .get()
              .then(function (doc) {
                modelServices.loadFile(
                  filePath,
                  modelIndex.file_name,
                  loadModelIntoCache,
                  {
                    name: doc.data().name,
                    parentId: modelIndex.parent_id,
                    type: modelIndex.type,
                    viewId: viewId,
                  }
                )
              })
          })
        })

      return
    }
  }, [modelCache, params, accountId])

  const loadModelIntoCache = (model, fileName, text, props) => {
    const { name, parentId, type, viewId } = props

    const newModelCacheItem = modelServices.createModelCacheItem(
      model,
      fileName,
      name,
      parentId,
      type
    )

    dispatch(setModelState(newModelCacheItem))
    setModel(newModelCacheItem)

    const view = newModelCacheItem.model.views.find(
      (view) => view.id === viewId
    )
    if (view) {
      //console.log("%cview", "color: chartreuse", { view })
      setView(view)

      initViewOptions(newModelCacheItem)
      initOtherViews({
        modelCache: newModelCacheItem,
        parentId,
        view,
        fileName,
      })
    }
  }

  const loadStories = ({ accountId, parentId, viewId, fileName }) => {
    console.log("loading stories", { parentId, viewId, fileName })
    db.collection("stories")
      .where("parent_id", "==", parentId)
      .where("account_id", "==", accountId)
      .where("view_id", "==", viewId)
      .where("file_name", "==", fileName)
      .get()
      .then((querySnapshot) => {
        const stories = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          }
        })

        // console.log("%cloaded stories", "color: lightgreen", {
        //     stories,
        //     parentId,
        //     viewId,
        //     fileName,
        // })

        setStories(stories)
      })
  }

  const initOtherViews = ({ modelCache, parentId, view, fileName }) => {
    const otherModels = getOtherModels(modelCache, parentId, fileName) || []

    // Match other views on view name or view id.
    const otherViews = otherModels
      .map((other) => {
        return {
          name: other.model.name,
          file: other.model.file,
          view: other.model.views.find(
            (v) => v.id === view.id || v.name === view.name
          ),
        }
      })
      .filter((other) => other.view !== undefined)

    //TODO: This view matching only works if they're loaded into cache. Probably needs to be added into model index

    setOtherViews(otherViews)
  }

  const initViewOptions = (modelCacheItem) => {
    const newViewOptions = modelCacheItem.model.views.map((view) => ({
      id: view.id,
      title: view.name,
    }))
    setViewOptions(newViewOptions)
  }

  // parent id is the firestore parent id, either from a project or component, and viewId is the ArchiMate DiagramObjectModel id
  const getModelFromCache = (modelCache, parentId, fileName) => {
    console.log("Get model from cache", { modelCache, parentId, fileName })
    // const modelCacheItem = Object.values(modelCache).find(
    //     (cacheEntry) => cacheEntry.parent_id === parentId && cacheEntry.model.file === fileName
    // )

    const modelCacheItem = modelServices.getModelFromCache({
      modelCache: modelCache,
      parentId: parentId,
      fileName: fileName,
    })

    return modelCacheItem
  }

  const getOtherModels = (modelCache, parentId, fileName) => {
    const otherModels = Object.values(modelCache).filter(
      (cacheEntry) =>
        cacheEntry.parent_id === parentId && cacheEntry.model.file !== fileName
    )

    return otherModels
  }

  const handleClickOtherView = (otherView) => {
    //console.log("click other view", { otherView })

    const url = urlServices.createViewEditUrl({
      parentId: params.parentId,
      viewId: otherView.view.id,
      fileName: otherView.file,
    })
    history.push(url)
  }

  const handleNavigateNextView = () => {
    const index = viewOptions.findIndex((item) => item.id === view.id)
    const nextIndex = index === viewOptions.length - 1 ? 0 : index + 1
    const url = urlServices.createViewEditUrl({
      parentId: params.parentId,
      viewId: viewOptions[nextIndex].id,
      fileName: params.fileName,
    })
    history.push(url)
  }

  const handleNavigatePreviousView = () => {
    const index = viewOptions.findIndex((item) => item.id === view.id)
    const nextIndex = index === 0 ? viewOptions.length - 1 : index - 1
    const url = urlServices.createViewEditUrl({
      parentId: params.parentId,
      viewId: viewOptions[nextIndex].id,
      fileName: params.fileName,
    })
    history.push(url)
  }

  const handleSelectView = (event) => {
    const url = urlServices.createViewEditUrl({
      parentId: params.parent.id,
      viewId: event.target.value,
      fileName: params.fileName,
    })
    //console.log("%cselected view", "color: lightgreen", { url })
    history.push(url)
  }

  const handleElementMouseOverEvent = (element) => {
    // Determine what downstream elements exist from this element on this view

    if (!element) {
      return
    }

    const elementId = element.id

    const viewElement = view.elements.find(
      (viewEl) => viewEl.diagramObject.archimateElement === elementId
    )

    const sourceElement = model.model.elements.find((el) => el.id === elementId)
    const sourceElementTypes = [
      palette.BUSINESS_FUNCTION,
      palette.BUSINESS_PROCESS,
    ]

    if (sourceElement && sourceElementTypes.includes(sourceElement.type)) {
      const connectionTypes = [
        palette.FLOW_RELATIONSHIP,
        palette.TRIGGERING_RELATIONSHIP,
        palette.ACCESS_RELATIONSHIP,
      ]
      const targetElementTypes = [
        palette.BUSINESS_FUNCTION,
        palette.BUSINESS_OBJECT,
        palette.BUSINESS_PROCESS,
        palette.BUSINESS_EVENT,
      ]

      if (viewElement) {
        const relIds = viewElement.sourceConnections.map(
          (sc) => sc.connection.archimateRelationship
        )
        const rels = model.model.elements.filter((el) => relIds.includes(el.id))

        const filteredRels = rels.filter((rel) =>
          connectionTypes.includes(rel.type)
        )

        const targetElementIds = filteredRels.map((rel) => rel.target)

        const targetElements = model.model.elements
          .filter((el) => targetElementIds.includes(el.id))
          .filter((el) => targetElementTypes.includes(el.type))

        setHighlightElementIds(targetElements.map((el) => el.id))
      }
    } else {
      setHighlightElementIds([])
    }
  }

  const handleElementMouseOutEvent = (element) => {
    //console.log("mouse exit element", { event: element })
  }

  const handleUpdateTags = async (newTags) => {
    setTags(newTags)

    console.log("%cupdating tags", "color: lightgreen", { newTags })

    const modelCacheItem = getModelFromCache(
      modelCache,
      params.parentId,
      params.fileName
    )

    const collection =
      modelCacheItem.type === "project" ? "projects" : "components"
    await db
      .collection(collection)
      .doc(params.parentId)
      .get()
      .then((docSnapshot) => {
        //console.log("%cparent data", "color: lightgreen", docSnapshot.data())

        const parentData = docSnapshot.data()
        const viewTags = { view_id: view.id, tags: newTags }
        const existingTags = (parentData.view_tags || []).filter(
          (item) => item.view_id !== view.id
        )
        const newViewTags = [...existingTags, viewTags]
        //console.log("%cnew view tags", "color:yellow", newViewTags)
        db.collection(collection)
          .doc(params.parentId)
          .update(
            {
              view_tags: newViewTags,
              modified: dataServices.serverTimestamp(),
            },
            { merge: true }
          )
      })
  }

  const handleShowChat = () => {
    setShowChat(true)
  }

  const handleCreateChatPrompt = () => {
    console.log("create prompt")

    const modelCacheItem = getModelFromCache(
      modelCache,
      params.parentId,
      params.fileName
    )

    const promptData = chatPromptServices.createPromptDataFromModelCache(
      modelCacheItem,
      view
    )

    setChatPromptData(promptData)
    setShowChatPrompt(true)
  }

  const toggleEdit = () => {
    const newVal = !isViewEditing
    setViewEditing(newVal)
    dispatch(setEditMode(newVal))
  }

  return (
    <Box>
      <ModelBreadcrumbs modelState={model} suffix="view">
        {view && view.name}
      </ModelBreadcrumbs>

      {showChatPrompt && (
        <CreatePromptDialog
          open={showChatPrompt}
          onClose={() => setShowChatPrompt(false)}
          promptData={chatPromptData}
        />
      )}

      {showChat && (
        <ChatDialog
          open={showChat}
          onClose={() => setShowChat(false)}
          messages={messages}
          setMessages={setMessages}
        />
      )}

      <Grid item>
        {viewOptions && view && (
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <IconButton
              style={{ marginTop: "10px" }}
              onClick={handleNavigatePreviousView}
              disabled={viewOptions.length === 1}
            >
              <NavigateBeforeIcon />
            </IconButton>
            <IconButton
              style={{ marginTop: "10px" }}
              onClick={handleNavigateNextView}
              disabled={viewOptions.length === 1}
            >
              <NavigateNextIcon />
            </IconButton>
            <Controls.Select
              name="views"
              value={view.id}
              options={viewOptions}
              onChange={handleSelectView}
              addNoneOption={false}
              minWidth={200}
            />

            {stories && (
              <Box sx={styles.storyLinks}>
                {stories.map((story) => (
                  <Tooltip title={story.description} key={story.id}>
                    <Link to={`/Story/${story.id}`}>
                      <Typography variant="body2" color="textSecondary">
                        {story.name}
                      </Typography>
                    </Link>
                  </Tooltip>
                ))}
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                marginLeft: "auto",
              }}
            >
              <ToggleEditing toggleEdit={toggleEdit} />
            </Box>
          </Box>
        )}
      </Grid>

      <Grid item sx={styles.documentation}>
        {view &&
          view.documentation &&
          splitText(view.documentation).map((line) => (
            <Typography key={line} component={"span"} variant="body2">
              {line}
            </Typography>
          ))}
      </Grid>

      {isViewEditing && (
        <TagPanel
          tags={tags}
          handleUpdateTags={handleUpdateTags}
          heading={"View Tags"}
          showNoTagsAlert={true}
          isEditing={isViewEditing}
        />
      )}

      {!isViewEditing && <TagSummary tags={tags} isEditing={isViewEditing} />}

      <TooltipHover />

      <Grid item>
        <DiagramResize setWidth={setWidth} />
      </Grid>

      <Grid item>
        <DocumentationIndicator /> <TooltipsIndicator /> <AutoLegend />
      </Grid>

      <Box sx={{ marginBottom: "10px" }} />

      <Grid item>
        {view && params && (
          <Diagram
            params={params}
            maxWidth={autoFitToWindow ? width - 120 : 3500}
            maxScale={diagramScale / 100}
            showRules={showRules}
            highlightElementIds={highlightElementIds}
            model={model}
            showDocumentation={showDocumentation}
            autoLegend={autoLegend}
            showTooltips={showTooltips}
            showCreateRefButton={true}
            showCopyButton={true}
            showCreateStoryButton={true}
            handleShowChat={handleShowChat}
            handleCreateChatPrompt={handleCreateChatPrompt}
            handleElementMouseOver={handleElementMouseOverEvent}
            handleElementMouseOut={handleElementMouseOutEvent}
          />
        )}
      </Grid>

      {similarViewsByFile && Object.keys(similarViewsByFile).length > 0 && (
        <>
          <GridLabel>Similar views</GridLabel>

          <Box style={{ marginTop: "5px" }}>
            {Object.keys(similarViewsByFile).map((file) => (
              <Box key={file}>
                <Typography variant="body2" style={{ fontWeight: 700 }}>
                  {file}
                </Typography>
                <List dense>
                  {similarViewsByFile[file].map((similarView) => (
                    <ListItem
                      button
                      key={`${similarView.parent_id}-${similarView.file}-${similarView.view.id}`}
                      onClick={() => {
                        history.push(
                          urlServices.createViewEditUrl({
                            parentId: similarView.parent_id,
                            viewId: similarView.view.id,
                            fileName: similarView.file,
                          })
                        )
                      }}
                    >
                      <ListItemText
                        primary={`${similarView.view.name} (${(
                          similarView.score * 100
                        ).toFixed(0)}%)`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
        </>
      )}

      <GridLabel>Different view versions in other model files</GridLabel>
      <Grid item>
        <List dense>
          {otherViews &&
            otherViews.map((otherView) => (
              <ListItem
                button
                key={otherView.file}
                onClick={() => handleClickOtherView(otherView)}
              >
                <ListItemText primary={otherView.name} />
              </ListItem>
            ))}
        </List>
        {otherViews && otherViews.length === 0 && (
          <Typography
            variant="caption"
            style={{
              display: "flex",
              flexDirection: "row",
            }}
            component={"span"}
          >
            This is the only version of this view. No other uploaded models in '
            {view?.name}' have the same name or GUID {model && model.type}.
            Upload other versions of this model to be able to switch between
            different versions of this view.
          </Typography>
        )}
      </Grid>
    </Box>
  )
}

export default withSnackbar(withRouter(ViewEditForm))
