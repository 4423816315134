import React from "react"
import Header from "../components/Header"
import { Box } from "@mui/material"
import AccountGrid from "../components/AccountGrid"

const Accounts = () => {
    return (
        <Header title="Accounts">
            <Box padding={1}>
                <AccountGrid />
            </Box>
        </Header>
    )
}

export default Accounts
