import React, { useRef, useState } from "react"
import QRCode from "qrcode"
import { Button, Box, TextField } from "@mui/material"
import authenticator from "authenticator"
import { createTotpURI, verifyTotpToken } from "./services/cloudFunctions"

const QrTest = () => {
    const imgRef = useRef()

    //const [formattedKey, setFormattedKey] = useState()

    const [enteredToken, setEnteredToken] = useState("")

    // useEffect(() => {
    //     setFormattedKey(authenticator.generateKey())
    // }, [])

    const handleVerifyToken = async () => {
        console.log("verify", enteredToken)
        const verified = await verifyTotpToken(enteredToken)
        console.log(verified)
    }

    const handleTokenChange = (e) => {
        setEnteredToken(e.target.value)
    }

    const handleGenerateQRCode = async () => {
        console.log("authenticator", authenticator)

        //const key = authenticator.generateKey()
        //console.log("result", formattedKey)

        // const url = authenticator.generateTotpUri(
        //     formattedKey,
        //     "greg55@ozemail.com.au",
        //     "AIM",
        //     "SHA1",
        //     6,
        //     30
        // )

        // console.log("url", url)

        const totpResult = await createTotpURI("greg55@ozemail.com.au")
        console.log("uri", totpResult)

        const url = totpResult.data.uri

        // const token = authenticator.generateToken(formattedKey)

        // console.log("token", token)

        // const verified = authenticator.verifyToken(formattedKey, token)

        // console.log("verified", verified)

        QRCode.toDataURL(url, { errorCorrectionLevel: "L" }).then((url) => {
            console.log(url)

            imgRef.current.src = url
        })
    }

    return (
        <Box>
            <Box>
                <Button onClick={handleGenerateQRCode}>Generate</Button>
            </Box>
            <Box>
                <img alt="QRCode" ref={imgRef} />
            </Box>
            <Box>
                <TextField label="Token" onChange={handleTokenChange} />
                <Button onClick={handleVerifyToken}>Verify</Button>
            </Box>
        </Box>
    )
}

export default QrTest
