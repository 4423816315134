import React, { useEffect, useState, useRef, useMemo, useCallback } from "react"
import Header from "../components/Header"
import firebase from "firebase/compat/app"
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  List,
  ListItemButton,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material"
import { useHistory } from "react-router-dom"
import * as colors from "@mui/material/colors"
import { useWindowDimensions } from "../pages/services/useWindowDimensions"
import * as colorServices from "./services/colorServices"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar, getDatasetAtEvent, getElementAtEvent } from "react-chartjs-2"
import * as cloudFunctions from "./services/cloudFunctions"
import { useDispatch, useSelector } from "react-redux"
import { selectChartProjectsByTag } from "../redux/selectors"
import { setChartProjectsByTag } from "../redux/actions"
import _ from "lodash"
import { setProjectExplorerSettings } from "../redux/actions"
import { selectProjectExplorerSettings } from "../redux/selectors"
import { spacing } from "./services/styleServices"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const styles = {
  pageContent: {
    margin: spacing(2),
    padding: spacing(2),
  },
  cardHeader: {
    backgroundColor: colors.blue[200],
    padding: spacing(1),
  },
  cards: {
    paddingTop: spacing(1),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    "& > *": {
      margin: spacing(1),
      maxWidth: 300,
    },
  },
  skeletonChart: {
    display: "flex",
    flexDirection: "column",
    gap: spacing(1),
    padding: spacing(2),
    maxWidth: 800,
  },
}

const Dashboard = () => {
  const history = useHistory()

  const [roles, setRoles] = useState([])

  const chartRef = useRef()

  const { width } = useWindowDimensions()

  const dispatch = useDispatch()

  const projectExplorerSettings = useSelector(selectProjectExplorerSettings)

  const updateProjectExplorerSettings = (type, label) => {
    dispatch(
      setProjectExplorerSettings({
        ...projectExplorerSettings,
        filter: [{ type, label }],
      })
    )
  }

  const [isShowSkeleton, setShowSkeleton] = useState(false)

  const initialChartState = useSelector(selectChartProjectsByTag)

  const [projectsByTypeAndLabelData, setProjectsByTypeAndLabelData] = useState(
    initialChartState.data
  )

  const openProjects = () => {
    history.push("/projects")
  }

  const openAIDesigner = () => {
    history.push("/viewsets")
  }

  const openComponents = () => {
    history.push("/components")
  }

  const openML = () => {
    history.push("/ml")
  }

  const openInvites = () => {
    history.push("/invites")
  }

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult(false).then((token) => {})
      }
    })

    return unsub
  }, [])

  /*
        projects by type and label
        job -> project
        centre -> type (from tag)
        category -> label (from tag)
    */
  const chartDataByTypeAndLabel = useMemo(() => {
    const selected = projectsByTypeAndLabelData.map((item) => ({
      type: item.type,
      label: item.label,
      count: item.count,
    }))

    const typesGrouped = _.groupBy(projectsByTypeAndLabelData, "type")

    const typeCounts = Object.keys(typesGrouped).map((key) => ({
      type: typesGrouped[key][0].type,
      count: typesGrouped[key].reduce((acc, value) => acc + value.count, 0),
    }))

    const typeCountsSorted = typeCounts.sort((a, b) => b.count - a.count)

    const types = typeCountsSorted.map((item) => item.type)

    const labels = _.groupBy(selected, "label")

    // For datasets, each object is a tag label of project and a count.
    // The sub objects are the type labels and the counts
    const datasets = Object.keys(labels).map((key, index) => {
      const data = types.map((type) => {
        const found = selected.find(
          (item) => item.type === type && item.label === key
        )
        return found?.count || 0
      })

      return {
        label: key,
        data,
        backgroundColor: colorServices.getColors(labels.length)[index],
        barThickness: 20,
      }
    })

    const result = {
      labels: types,
      datasets: datasets,
    }

    return result
  }, [projectsByTypeAndLabelData])

  const BAR_HEIGHT = 20

  const calcAspectRatio = useCallback(
    (data) => {
      const labelCount = data.labels.length || 15
      const expectedHeight = labelCount * BAR_HEIGHT + 100

      const ratio = (width - 200) / expectedHeight

      //console.log("calcAspectRatio", { labelCount, expectedHeight, width, ratio })

      return ratio
    },
    [width]
  )

  const typeAndLabelAspectRatio = useMemo(() => {
    return calcAspectRatio(chartDataByTypeAndLabel)
  }, [chartDataByTypeAndLabel, calcAspectRatio])

  /*
    job -> project
    centre -> type (from tag)
    category -> label (from tag)
    */
  const chartOptionsByTypeAndLabel = useMemo(() => {
    return {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: typeAndLabelAspectRatio,
      scales: {
        x: {
          stacked: true,
          ticks: {
            // For a label axis, the val is the index so the lookup via getLabelForValue is needed
            callback: function (val, index) {
              // Hide label if not integer
              //console.log("ticks", val, index)

              const label = this.getLabelForValue(val)
              return Number.isInteger(Number(label)) ? label : ""
            },
          },
        },
        y: {
          stacked: true,
        },
      },
      indexAxis: "y",
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      plugins: {
        legend: {
          position: "right",
          onClick: (evt, legendItem, legend) => {
            const index = legendItem.datasetIndex
            const ci = legend.chart

            legendItem.hidden = !legendItem.hidden
            if (legendItem.hidden) {
              ci.hide(index)
            } else {
              ci.show(index)
            }
          },
        },
        title: {
          display: true,
          text: "Projects by Type and Label",
        },
      },
    }
  }, [typeAndLabelAspectRatio])

  const handleQueryOpenJobsByTypeAndLabel = async () => {
    // Clear data and show skeleton
    setProjectsByTypeAndLabelData([])
    setShowSkeleton(true)

    // Retrieve and set refreshed data
    const results = await cloudFunctions.queryViewByAccountId(
      "project_tags_summary"
    )
    setShowSkeleton(false)
    setProjectsByTypeAndLabelData(results.data[0])

    dispatch(setChartProjectsByTag({ data: results.data[0] }))
  }

  // const handleMessageTest = () => {
  //     //const messaging = firebase.messaging()
  //     console.log("messaging", messaging)
  // }

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdTokenResult(false).then((token) => {
          setRoles(token?.claims?.roles || [])
        })
      }
    })

    return unsub
  }, [])

  const hideAll = (data, ref) => {
    console.log(`hide ${data.datasets.length} items`)
    for (let i = 0; i < data.datasets.length; i++) {
      ref.current.setDatasetVisibility(i, false)
    }
    ref.current.update()
  }

  const getDatasetName = (dataset) => {
    if (!dataset.length) return
    const datasetIndex = dataset[0].datasetIndex
    return chartDataByTypeAndLabel.datasets[datasetIndex].label
  }

  const handleChartClick = (event) => {
    const { current: chart } = chartRef

    if (!chart) {
      return
    }

    const elementAtEvent = getElementAtEvent(chart, event)
    if (elementAtEvent.length > 0) {
      const type = chartDataByTypeAndLabel.labels[elementAtEvent[0].index]
      const label = getDatasetName(getDatasetAtEvent(chart, event))

      console.log("%cdataset at event", "color:lightgreen", {
        type,
        label,
      })

      updateProjectExplorerSettings(type, label)
      history.push("/ProjectExplorer")
    }
  }

  return (
    <Header title="Dashboard">
      <Box sx={styles.pageContent}>
        <Box>
          <Typography component={"span"}>What can I do?</Typography>
        </Box>

        <Box sx={styles.cards}>
          <Card>
            <CardHeader
              sx={styles.cardHeader}
              title={
                <Typography component={"span"}>Get Generative AI</Typography>
              }
            />
            <CardContent sx={styles.cardContent}>
              <Stack gap={2}>
                <Typography>
                  AIM uses GPT-4o to{" "}
                  <b>massively accelerate strategy and planning</b>
                </Typography>
                <Typography>
                  <a
                    href="https://architectureinmotion.com.au/generative-ai-samples/"
                    target="_genai"
                  >
                    View sample outputs
                  </a>
                </Typography>

                <Button
                  onClick={() => history.push("/billing")}
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                >
                  Manage Subscription
                </Button>
                <Button
                  onClick={() => history.push("/viewsets")}
                  variant="outlined"
                  sx={{ textTransform: "none" }}
                >
                  Open AI Designer
                </Button>
                <Box
                  display="flex"
                  justifyContent={"center"}
                  sx={{ mt: "15px" }}
                >
                  <img src="/openai-lockup.svg" alt="openai" width="80px" />
                </Box>
              </Stack>
            </CardContent>
          </Card>

          <Card>
            <CardHeader
              sx={styles.cardHeader}
              title={
                <Typography component={"span"}>Manage Projects</Typography>
              }
            />
            <CardActionArea onClick={openProjects}>
              <CardContent sx={styles.cardContent}>
                <Typography>
                  Upload <b>Project</b> models in either Archi (.archimate) or
                  OpenExchange (.xml) format
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card>
            <CardHeader
              sx={styles.cardHeader}
              title={<Typography>Manage Components</Typography>}
            />
            <CardActionArea onClick={openComponents}>
              <CardContent sx={styles.cardContent}>
                <Typography variant="body2">
                  Upload reusable <b>Component</b> models in either Archi
                  (.archimate) or OpenExchange (.xml) format. Components are
                  reusable Architecture building blocks.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>

          <Card>
            <CardHeader
              sx={styles.cardHeader}
              title={<Typography>Configure Rules</Typography>}
            />
            <CardActionArea onClick={openInvites}>
              <CardContent sx={styles.cardContent}>
                <Typography>
                  Configure <b>Rules</b> to validate Project and Component
                  Architecture models, so that you achieve higher modelling
                  quality and consistency across your team.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>

          <Card>
            <CardHeader
              sx={styles.cardHeader}
              title={<Typography>Train AI</Typography>}
            />
            <CardActionArea onClick={openML}>
              <CardContent sx={styles.cardContent}>
                <Typography>
                  Train AIM's built in AI 'ArchiBot' on your best Architecture
                  models, so that these can be used as the basis for AI
                  generated modelling improvement recommendations.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>

          {roles.includes("admin") && (
            <Card>
              <CardHeader
                sx={styles.cardHeader}
                title={<Typography>Invite Users</Typography>}
              />
              <CardActionArea onClick={openInvites}>
                <CardContent sx={styles.cardContent}>
                  <Typography>
                    Invite other users to join this account (requires Admin)
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          )}
        </Box>

        <>
          {/* <Button
            onClick={async () => {
              console.log("events", await cloudFunctions.queryAuditEvents())
            }}
          >
            Query audit events
          </Button> */}

          {isShowSkeleton && <SkeletonChart />}

          {projectsByTypeAndLabelData.length > 0 && (
            <>
              <Button
                onClick={() => hideAll(chartDataByTypeAndLabel, chartRef)}
              >
                Hide All
              </Button>

              <Button
                onClick={() => {
                  for (
                    let i = 0;
                    i < chartDataByTypeAndLabel.datasets.length;
                    i++
                  ) {
                    chartRef.current.setDatasetVisibility(i, true)
                  }
                  chartRef.current.update()
                }}
              >
                Show All
              </Button>
            </>
          )}

          {chartDataByTypeAndLabel.labels.length > 0 && (
            <Box sx={{ padding: 2 }}>
              <Bar
                options={chartOptionsByTypeAndLabel}
                data={chartDataByTypeAndLabel}
                onClick={handleChartClick}
                ref={chartRef}
              />
            </Box>
          )}
        </>
      </Box>
      {/* <Box>{render(<OTPMessage />)}</Box> */}
    </Header>
  )
}

const SkeletonChart = () => {
  return (
    <>
      <Box sx={styles.skeletonChart}>
        <Skeleton variant="rectangular" sx={{ maxWidth: "800px" }} />
        <Skeleton variant="rectangular" sx={{ maxWidth: "600px" }} />
        <Skeleton variant="rectangular" sx={{ maxWidth: "400px" }} />
        <Skeleton variant="rectangular" sx={{ maxWidth: "200px" }} />
      </Box>
    </>
  )
}

export default Dashboard
