import React from "react"
import { FormControlLabel, Tooltip, Switch, Typography } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { selectAutoLegend } from "../redux/selectors"
import { setAutoLegend } from "../redux/actions"

const AutoLegend = (props) => {
    const dispatch = useDispatch()

    const show = useSelector(selectAutoLegend)

    return (
        <Tooltip title='Auto-generate a legend for this diagram'>
            <FormControlLabel
                sx={{ marginLeft: "10px" }}
                control={
                    <Switch
                        size='small'
                        checked={show}
                        onChange={() => {
                            dispatch(setAutoLegend(!show))
                        }}
                    />
                }
                label={
                    <Typography variant='caption' color='textSecondary' component={"span"}>
                        Legend
                    </Typography>
                }
            />
        </Tooltip>
    )
}

export default AutoLegend
