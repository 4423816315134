import React, { useState } from "react"
import UserGuideForm from "../components/UserGuideForm"
import Header from "../components/Header"
import { Paper } from "@mui/material"
import { spacing } from "./services/styleServices"

const styles = {
    pageContent: {
        margin: spacing(1),
        padding: spacing(1),
    },
}

const UserGuide = (props) => {
    const [title, setTitle] = useState("User Guide")

    return (
        <Header title={title}>
            <Paper sx={styles.pageContent} elevation={0}>
                <UserGuideForm {...props} setTitle={setTitle} />
            </Paper>
        </Header>
    )
}

export default UserGuide
