import React, { useState, useMemo, useEffect } from "react"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Controls from "./controls/Controls"
import { useForm, Form } from "./useForm"
import firebase from "firebase/compat/app"
import { useSnackbar } from "notistack"
import {
  Button,
  Box,
  LinearProgress,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  TextField,
  Alert,
  Link,
  Stack,
} from "@mui/material"
import QrCode2Icon from "@mui/icons-material/QrCode2"
import EmailIcon from "@mui/icons-material/Email"
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone"
import * as cloudFunctions from "../pages/services/cloudFunctions"
import { isEmailValid } from "../pages/services/emailServices"
import PasswordFields from "./PasswordFields"
import { parsePhoneNumberFromString } from "libphonenumber-js"

const LoginDialog = (props) => {
  const { open, setOpen, loginInputs, email } = props

  const [isShowProgress, setShowProgress] = useState(false)

  const QRCODE = "qrcode"

  const EMAIL = "email"

  const SMS = "sms"

  const [deliveryMethod, setDeliveryMethod] = useState(EMAIL)

  const [phone, setPhone] = useState("")

  const [phoneObject, setPhoneObject] = useState()

  const [phoneMessage, setPhoneMessage] = useState({
    severity: "info",
    message: "",
  })

  const [isInvalidCredentials, setInvalidCredentials] = useState(false)

  //const [providerIds, setProviderIds] = useState([])

  const [otpEmailSent, setOtpEmailSent] = useState(false)

  // const [isEmailDefinedInGoogle, setEmailDefinedInGoogle] = useState(false)

  const isPasswordDefined = useMemo(() => {
    if (loginInputs.providerIds === undefined) {
      return undefined
    }
    return loginInputs.providerIds.includes("password")
  }, [loginInputs.providerIds])

  /**
   * Has the user performed a QR Code based login before?
   * Init to 'true', and then determine if it's 'false'
   */
  //const [hasUsedQrCodeLogin, setUsedQrCodeLogin] = useState(true)

  // Should the user be allowed to use a QR code to authenticate? Only if they're an existing user.
  // Need to use email based OTP initially to prove you have control over the email account.
  const isAllowQROTP = useMemo(() => {
    return loginInputs.emailDefinedInGoogle && loginInputs.isQrCodeSecretDefined
  }, [loginInputs])

  useEffect(() => {
    if (isAllowQROTP) {
      if (deliveryMethod === "") {
        setDeliveryMethod(QRCODE)
      }
    }
  }, [isAllowQROTP])

  const deliveryMethodLabel = useMemo(() => {
    if (deliveryMethod === QRCODE) {
      return "Get 6-digit code via authenticator app"
    } else if (deliveryMethod === SMS) {
      return "Get 6-digit code via SMS"
    } else {
      return "Get 6-digit code via email"
    }
  }, [deliveryMethod])

  const { values, setValues, handleInputChange } = useForm({
    email: "",
    otp: "",
    phone: "",
    old_password: "",
    new_password_1: "",
    new_password_2: "",
  })

  const isCodeEntered = useMemo(() => values.otp.length > 0, [values.otp])

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    // If phone doesn't start with + set error message
    if (phone.length > 0 && !phone.startsWith("+")) {
      setPhoneMessage({
        message: "Phone must start with +, then country code, then number",
        severity: "warning",
      })
      return
    }

    if (phone.trim().length === 0) {
      setPhoneMessage("")
      return
    }

    console.log("phone changed", phone)
    const phoneNumberObj = parsePhoneNumberFromString(phone)
    console.log("phoneNumberObj", phoneNumberObj)
    setPhoneObject(phoneNumberObj)
    if (phoneNumberObj?.isValid()) {
      setPhoneMessage({
        message: `Valid phone - ${
          phoneNumberObj?.country
        } ${phoneNumberObj?.formatInternational()}`,
        severity: "success",
      })
    } else {
      setPhoneMessage({
        message: `Invalid phone - country '${
          phoneNumberObj?.country || ""
        }', country number '${
          phoneNumberObj?.countryCallingCode || ""
        }', number '${phoneNumberObj?.nationalNumber || ""}'`,
        severity: "warning",
      })
    }
  }, [phone])

  const handleClose = () => {
    setShowProgress(false)
    setOpen(false)
  }

  const handleOK = async () => {
    if (deliveryMethod === QRCODE) {
      handleValidateQRCodeOTP()
    } else {
      handleValidateEmailorSMSOTP()
    }
  }

  const handleValidateQRCodeOTP = async () => {
    if (values.otp === null || values.otp.length !== 6) {
      enqueueSnackbar("Enter 6-digit code", { variant: "warning" })
      return
    }

    setShowProgress(true)
    setInvalidCredentials(false)

    let result

    if (loginInputs.emailDefinedInGoogle) {
      const result = await cloudFunctions.isQROTPCorrect(values.otp, email)

      if (result.status === "success") {
        try {
          const credential = firebase.auth.EmailAuthProvider.credential(
            email,
            values.old_password
          )
          const loginPwdResult = await firebase
            .auth()
            .signInWithCredential(credential)
        } catch (error) {
          if (error.code === "auth/wrong-password") {
            console.error("wrong password", { error })
            setInvalidCredentials(true)
            setShowProgress(false)
            return
          } else {
            console.error("error", { error })
            enqueueSnackbar(error.message, { variant: "error" })
            setShowProgress(false)
            return
          }
        }
      }
    } else {
      result = await cloudFunctions.createUserAndGetToken(
        email,
        values.new_password_1
      )

      if (result.hasOwnProperty("token")) {
        enqueueSnackbar("Email authenticated. Please wait...", {
          variant: "success",
        })
        firebase
          .auth()
          .signInWithCustomToken(result.token)
          .then(async (userCredential) => {})
          .catch((error) => {
            console.log("Error signing in", error)
          })
      }
    }
    setShowProgress(false)
    setOpen(false)
  }

  const handleValidateEmailorSMSOTP = async () => {
    if (values.otp === null || values.otp.length !== 6) {
      enqueueSnackbar("Enter 6-digit code", { variant: "warning" })
      return
    }

    setShowProgress(true)

    setInvalidCredentials(false)

    let result

    if (loginInputs.emailDefinedInGoogle) {
      const result = await cloudFunctions.isEmailOrSMSOTPCorrect(
        email,
        values.otp
      )

      if (result.status === "success") {
        try {
          const credential = firebase.auth.EmailAuthProvider.credential(
            email,
            values.old_password
          )
          const loginPwdResult = await firebase
            .auth()
            .signInWithCredential(credential)
        } catch (error) {
          if (error.code === "auth/wrong-password") {
            console.error("wrong password", { error })
            setInvalidCredentials(true)
            setShowProgress(false)
            return
          } else {
            console.error("error", { error })
            enqueueSnackbar(error.message, { variant: "error" })
            setShowProgress(false)
            return
          }
        }
      }
    } else {
      result = await cloudFunctions.createUserAndGetToken(
        email,
        values.new_password_1
      )

      if (result.hasOwnProperty("token")) {
        enqueueSnackbar("Email authenticated. Please wait...", {
          variant: "success",
        })
        firebase
          .auth()
          .signInWithCustomToken(result.token)
          .then(async (userCredential) => {})
          .catch((error) => {
            console.log("Error signing in", error)
          })
      }
    }

    setShowProgress(false)
    setOpen(false)
  }

  const submitOnEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleOK()
    }
  }

  // Matches on:
  // +<country code> <number>         i.e. with a space
  // +<country code><number>          i.e. with no space
  const isPhoneValid = () => {
    // const match = phone.match(/^\+\d{1,3}\s?\d{1,14}$/)
    // return match !== null
    console.log("phoneObject", phoneObject)
    return phoneObject && phoneObject.isValid()
  }

  const handleRequestOTPViaEmail = () => {
    if (!isEmailValid(email)) {
      enqueueSnackbar("Enter valid email", { variant: "warning" })
      return
    } else {
      console.log("valid email", email)
    }

    // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    const match = email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)

    if (match !== null) {
      cloudFunctions
        .createAndSendOTPViaEmail({ email: email })
        .then((result) => {
          enqueueSnackbar(
            "A 6-digit code is being emailed to you. Check your inbox incl. junk mail folder",
            {
              variant: "success",
            }
          )
          setShowProgress(false)
        })
    } else {
      enqueueSnackbar("Enter a valid email address", { variant: "info" })
    }
  }

  const handleRequestOTPViaSMS = () => {
    if (!isPhoneValid(phone)) {
      enqueueSnackbar("Enter phone in format +<country code> <number>", {
        variant: "info",
      })
      return
    } else {
      console.log("valid phone", phone)
    }

    // Remove spaces and plus sign
    const formattedPhone = phone.replace(/\s/g, "") //.replace(/\+/g, "")

    cloudFunctions
      .createAndSendOTPViaSMS({ email: email, phone: formattedPhone })
      .then((result) => {
        console.log("send SMS result", { formattedPhone, result })
        enqueueSnackbar("A 6-digit code is being sent to your phone", {
          variant: "success",
        })
      })
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="email-signin">
      {isShowProgress && <LinearProgress />}
      <DialogTitle id="email-signin-dialog">Email Authentication</DialogTitle>
      <DialogContent>
        <Box sx={{ minWidth: 330 }}>
          {isInvalidCredentials && (
            <Alert severity="error">Invalid credentials</Alert>
          )}
          <Form>
            {values && (
              <>
                <Controls.TextInput
                  autoFocus
                  name="email"
                  label="Email"
                  variant="outlined"
                  value={email}
                  onChange={(event) => handleInputChange(event)}
                  onKeyPress={(event) => submitOnEnter(event)}
                  //onBlur={() => handleGetAuthenticationMethods()}
                  disabled={isShowProgress}
                />

                {isPasswordDefined && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Controls.TextInput
                      autoFocus
                      name="old_password"
                      label="Password"
                      type="password"
                      variant="outlined"
                      value={values.old_password}
                      onChange={(event) => handleInputChange(event)}
                    />
                  </Box>
                )}

                {!isPasswordDefined && (
                  <PasswordFields
                    showOldPassword={false}
                    // If we're just logging in then we only need to show 1 password field
                    isPasswordDefined={isPasswordDefined}
                    handleInputChange={handleInputChange}
                    oldPassword={values.old_password}
                    newPassword1={values.new_password_1}
                    newPassword2={values.new_password_2}
                  />
                )}

                <Box sx={{ marginTop: "15px" }}>
                  <Typography variant="caption">
                    {deliveryMethodLabel}
                  </Typography>
                </Box>

                <Box sx={{ marginTop: "15px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    <ToggleButtonGroup
                      orientation="horizontal"
                      value={deliveryMethod}
                      exclusive
                      onChange={(event, value) => setDeliveryMethod(value)}
                      color="primary"
                    >
                      {isAllowQROTP && (
                        <ToggleButton value={QRCODE} aria-label="qrcode">
                          <Tooltip title="Obtain 6-digit code from Google Authenticator">
                            <QrCode2Icon />
                          </Tooltip>
                        </ToggleButton>
                      )}
                      <ToggleButton value="email" aria-label="email">
                        <Tooltip title="Obtain 6-digit code via email">
                          <EmailIcon />
                        </Tooltip>
                      </ToggleButton>
                      {/* <ToggleButton value={SMS} aria-label="sms">
                                                <Tooltip title="Obtain 6-digit code via sms">
                                                    <PhoneIphoneIcon />
                                                </Tooltip>
                                            </ToggleButton> */}
                    </ToggleButtonGroup>
                    {deliveryMethod === "email" && (
                      <Controls.Button
                        onClick={() => {
                          setShowProgress(true)
                          handleRequestOTPViaEmail()
                          setOtpEmailSent(true)
                        }}
                        text="Send 6-digit code via email"
                        size="small"
                        variant="contained"
                        disabled={
                          isShowProgress ||
                          deliveryMethod !== "email" ||
                          otpEmailSent
                        }
                      />
                    )}
                    {deliveryMethod === SMS && (
                      <Controls.Button
                        onClick={() => {
                          handleRequestOTPViaSMS()
                        }}
                        text="Send 6-digit code via SMS"
                        variant="contained"
                        size="small"
                        disabled={isShowProgress || deliveryMethod !== SMS}
                      />
                    )}

                    {otpEmailSent && deliveryMethod === EMAIL && (
                      <Link
                        onClick={() => {
                          setOtpEmailSent(false)
                        }}
                        component="button"
                      >
                        <Typography variant="caption">Resend email</Typography>
                      </Link>
                    )}
                  </Box>

                  {deliveryMethod === SMS && (
                    <Stack
                      direction="column"
                      gap={1}
                      sx={{ marginTop: "10px" }}
                    >
                      <Box>
                        <TextField
                          label={`Enter phone to receive SMS code`}
                          variant="outlined"
                          name="phone"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          helperText="International format, +<country> <number>"
                          sx={{ maxWidth: "250px" }}
                        />
                      </Box>
                      <Box>
                        {phoneMessage.message && (
                          <Alert severity={phoneMessage.severity}>
                            {phoneMessage.message}
                          </Alert>
                        )}
                      </Box>
                    </Stack>
                  )}

                  <Box sx={{ mt: "10px" }}>
                    <TextField
                      label={`Enter 6-digit code${
                        deliveryMethod === "email" ? " from email" : ""
                      }`}
                      variant="outlined"
                      size="small"
                      name="otp"
                      value={values.otp}
                      onChange={handleInputChange}
                      onKeyPress={(event) => submitOnEnter(event)}
                      inputProps={{ maxLength: 6 }}
                      sx={{ maxWidth: "250px" }}
                    />
                  </Box>
                  <Box sx={{ marginTop: "20px" }}>
                    <Typography variant="caption">
                      TIP: You can enable QR-code / authenticator based
                      authentication once you have signed in via the Profile
                      button (top right)
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
          </Form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleOK}
          color="primary"
          variant="contained"
          disabled={isShowProgress || !isCodeEntered || deliveryMethod === ""}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LoginDialog
