import React from "react"
import ProfileForm from "./ProfileForm"
import Header from "../components/Header"
import { Paper } from "@mui/material"
import { spacing } from "./services/styleServices"

const styles = {
    pageContent: {
        margin: spacing(2),
        padding: spacing(2),
    },
}

function ProfilePage() {
    return (
        <Header title="Profile">
            <Paper sx={styles.pageContent}>
                <ProfileForm />
            </Paper>
        </Header>
    )
}

export default ProfilePage
