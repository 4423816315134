import { Box } from "@mui/material"
import { useMemo } from "react"

const ModelEditElementTypes = ({ elementTypes }) => {
  const ELEMENT_WIDTH = 120
  const ELEMENT_HEIGHT = 60

  const legendInfo = useMemo(() => {
    return {
      height: ELEMENT_HEIGHT + 10,
      width: elementTypes.length * ELEMENT_WIDTH + 10,
    }
  }, [elementTypes])

  return (
    <Box>
      {elementTypes && legendInfo && (
        <svg
          height={80}
          width={legendInfo.width}
          viewBox={`0 0 ${legendInfo.width} ${legendInfo.height}`}
        >
          <rect
            x={0}
            y={0}
            fill={"#fff"}
            stroke={"fff"}
            width={legendInfo.width}
            height={legendInfo.height}
          />

          {elementTypes.map((elementType, index) => (
            <elementType.symbol
              key={elementType.type}
              x={index * (ELEMENT_WIDTH + 5)}
              y={5}
              height={ELEMENT_HEIGHT}
              width={ELEMENT_WIDTH}
              label={`${elementType.label}${
                elementType.count > 1 ? ` x ${elementType.count}` : ""
              }`}
              showLabel={true}
            />
          ))}
        </svg>
      )}
    </Box>
  )
}

export default ModelEditElementTypes
