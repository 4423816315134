import React, { useState, useEffect } from "react"
import _ from "lodash"
import { Typography, Box, Chip } from "@mui/material"
import { spacing } from "../pages/services/styleServices"
import * as colors from "@mui/material/colors"

const styles = {
    tags: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        gap: spacing(0.1),
    },
    tagGroups: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        gap: spacing(0.5),
        marginTop: spacing(0.5),
    },
}

const Tags = (props) => {
    const { tags, handleTagDelete, editTags, notifySelected } = props

    const [groups, setGroups] = useState()

    const [selected, setSelected] = useState([])

    useEffect(() => {
        setGroups(_.groupBy(tags, "type"))
    }, [tags])

    const TagProps = (editable, tag, isTagSelected) => {
        const props = {}

        if (isTagSelected) {
            props.sx = { backgroundColor: colors.blueGrey[100] }
        }

        if (editable) {
            props.onDelete = () => handleTagDelete(tag)
        }

        return props
    }

    const TagsProps = (props) => {
        const { maxWidth } = props

        const result = {}

        if (maxWidth) {
            result.style = { maxWidth: maxWidth }
        }

        return result
    }

    const handleSelect = (tag) => {
        const existingIndex = selected.findIndex(
            (selectedTag) => selectedTag.type === tag.type && selectedTag.label === tag.label
        )

        let newSelected

        if (existingIndex === -1) {
            newSelected = [...selected, tag]
        } else {
            newSelected = selected.filter((selectedTag, index) => index !== existingIndex)
        }

        setSelected(newSelected)
        notifySelected(newSelected)
    }

    const ClickProps = (tag) => {
        const props = {}

        if (notifySelected) {
            props.onClick = () => handleSelect(tag)
        }

        return props
    }

    const isTagSelected = (tag) => {
        return selected.find(
            (selectedTag) => selectedTag.type === tag.type && selectedTag.label === tag.label
        )
    }

    return (
        <Box sx={styles.tagGroups} {...TagsProps(props)} component={"span"}>
            {groups &&
                Object.keys(groups).map((type) => (
                    <Box key={type} sx={styles.tags} component={"span"}>
                        <Typography
                            variant="caption"
                            component={"span"}
                            sx={{ marginLeft: "5px", color: colors.blueGrey[900] }}
                        >
                            {type || "General"}
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "row", gap: spacing(0.3) }}>
                            {groups[type].map((tag) => (
                                <Chip
                                    key={`${tag.type}:${tag.label}`}
                                    size="small"
                                    label={
                                        <Typography
                                            component="span"
                                            variant="caption"
                                            sx={{ color: colors.blueGrey[900] }}
                                        >
                                            {tag.label}
                                        </Typography>
                                    }
                                    {...TagProps(editTags, tag, isTagSelected(tag))}
                                    {...ClickProps(tag)}
                                    variant={isTagSelected(tag) ? "standard" : "outlined"}
                                />
                            ))}
                        </Box>
                    </Box>
                ))}
        </Box>
    )
}

export default Tags
