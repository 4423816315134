import React from "react"
import { Box, Paper } from "@mui/material"
import { spacing } from "../pages/services/styleServices"

const styles = {
    lines: {
        fontSize: "14px",
        "& > *": {
            marginBottom: spacing(1),
        },
        padding: spacing(0.5),
        margin: spacing(0.25),
    },
    title: {
        fontWeight: "bold",
        padding: spacing(0.5),
        margin: spacing(0.25),
    },
    tooltip: {
        padding: 0,
        margin: 0,
        maxWidth: 500,
    },
}

const TooltipHover = (props) => {
    return (
        <Paper
            id="tooltip"
            display="none"
            sx={styles.tooltip}
            style={{ position: "absolute", display: "none", zIndex: "1000" }}
        >
            <Box>
                <Box sx={styles.title} id="cardTitle"></Box>
                <Box sx={styles.lines} id="tooltipText"></Box>
            </Box>
        </Paper>
    )
}

export default TooltipHover
