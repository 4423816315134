import React, { useState } from "react"
import * as common from "./common"
import { yellow } from "@mui/material/colors"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const BusinessCollaboration = (props) => {
    const { x, y, height, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = yellow[200]
    const defaultColor = fillColor || yellow[100]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <rect
                x={x + 1}
                y={y + 1}
                fill={fill}
                stroke={common.outlineColor}
                width={width - 1}
                height={height - 1}
            />

            {common.getCollaborationIcon(x + width, y)}

            <ElementText {...props} label={label} />
        </Svg>
    )
}

export default BusinessCollaboration
