import React, { useState, useEffect, createRef } from "react"
import Grid from "@mui/material/Grid"
import Controls from "./controls/Controls"
import { useForm, Form } from "./useForm"
import * as dataServices from "../pages/services/dataServices"
import db from "../Firestore"
import firebase from "firebase/compat/app"
import { saveAs } from "file-saver"
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Typography,
  ListItemIcon,
  Tooltip,
  Divider,
  ListItemButton,
} from "@mui/material"
import { withSnackbar, useSnackbar } from "notistack"
import { useHistory, withRouter } from "react-router-dom"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import SaveIcon from "@mui/icons-material/Save"
import RuleIcon from "@mui/icons-material/Rule"
import EditIcon from "@mui/icons-material/Edit"
import YesNo from "./YesNo"
import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import SmartToyIcon from "@mui/icons-material/SmartToy"
import ProgressBackdrop from "./ProgressBackdrop"
import { getGroupedElements } from "../model.mjs"
import ElementCountSummaryCard from "./ElementCountSummaryCard"
import * as Roles from "../pages/services/roleServices"
import DeleteIcon from "@mui/icons-material/Delete"
import {
  selectModelState,
  selectSelectedModelFileName,
  selectEditMode,
} from "../redux/selectors"
import {
  setModelState,
  setSelectedModelFileName,
  removeModel,
  setEditMode,
} from "../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import { selectShowDiagrams, selectShowRules } from "../redux/selectors"
import { setShowDiagrams, setShowRules } from "../redux/actions"
import ViewCard from "./ViewCard"
import _ from "lodash"
import * as modelServices from "../pages/services/modelServices"
import { Alert } from "@mui/material"
import Heading from "./controls/Heading"
import * as icons from "../icons"
import { updateModelMessages } from "../redux/actions"
import { selectComponentModelIndex } from "../redux/selectors"
import { setComponentModelIndex } from "../redux/actions"
import * as ruleServices from "../pages/services/ruleServices"
import ModelFileList from "./ModelFileList"
import Bold from "./Bold"
import TagPanel from "./TagPanel"
import Tags from "./Tags"
import TabPanel from "./TabPanel"
import RunRulesDialog from "./RunRulesDialog"
import * as tagServices from "../pages/services/tagServices"
import useAccountStatus from "./useAccountStatus"
import TagSummary from "./TagSummary"
import { spacing } from "../pages/services/styleServices"

const initialValues = () => {
  return {
    name: "",
    type: "",
    description: "",
    created: dataServices.localTimestamp(),
    modified: dataServices.localTimestamp(),
    user: "",
    files: [],
    tags: [],
    ml_training: false,
    view_tags: [],
    tags_index: [],
  }
}

const styles = {
  input: {
    display: "none",
  },
  elementSummary: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: spacing(1),
    },
  },
  views: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "top",
    justifyContent: "flex-start",
    "& > *": {
      marginLeft: spacing(0.5),
    },
  },
  buttons: {
    marginTop: spacing(3),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "5px",
  },
  mainGrid: {
    display: "flex",
    flexDirection: "column",
  },
  centredWidget: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
}

const ComponentEditForm = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [componentId, setComponentId] = useState(props.computedMatch.params.id)

  const [originalComponentName, setOriginalComponentName] = useState("")

  const modelCache = useSelector(selectModelState)

  const indexCache = useSelector(selectComponentModelIndex)

  const selectedFileName = useSelector(selectSelectedModelFileName)

  const { isUploadModelActive } = useAccountStatus()

  const [selectedFileIndex, setSelectedFileIndex] = useState()

  const blankModel = { name: "", model: { elements: [], views: [] } }
  const [model, setModel] = useState(blankModel)

  const dispatch = useDispatch()

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Delete Component?",
    description: "This delete is permanent",
    openPrompt: false,

    // this method is set when we prompt for deletion
    handleConfirm: null,
  })

  const [stories, setStories] = useState()

  const { setTitle, tabIndex, setTabIndex } = props

  const history = useHistory()

  // Should we be showing a backdrop with progress indicator?
  // Used when we check if we can delete a components
  const [isShowProgress, setShowProgress] = useState(false)

  const [isRunRulesDialogOpen, setRunRulesDialogOpen] = useState(false)

  // The views from the selected model to show
  const [views, setViews] = useState()

  const isNew = () => componentId === undefined || componentId === ""

  //const [editorValue, setEditorValue] = useState("")

  const { values, setValues, handleInputChange } = useForm(initialValues())

  const [isEditable, setEditable] = useState(false)

  const COLLECTION_NAME = "components"

  const [user, setUser] = useState()

  const [currentUID, setCurrentUID] = useState()

  const componentEditInitialState = useSelector(selectEditMode)

  const [isComponentEditing, setComponentEditing] = useState(
    componentEditInitialState
  )

  const [accountId, setAccountId] = useState()

  const [hasAIMAIRole, setHasAIMAIRole] = useState(false)

  const showDiagrams = useSelector(selectShowDiagrams)

  const showRules = useSelector(selectShowRules)

  const [selectedViewTags, setSelectedViewTags] = useState([])

  const [modelIndexes, setModelIndexes] = useState()

  const addFileRef = createRef()

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      setUser(user)

      if (user) {
        user.getIdTokenResult(false).then((token) => {
          setAccountId(token.claims.account_id)
          setEditable(
            token.claims.roles.includes(Roles.ADMIN) ||
              token.claims.roles.includes(Roles.COMPONENT_ADMIN)
          )
          setCurrentUID(token.claims.user_id)
          setHasAIMAIRole(token.claims.roles.includes(Roles.AIM_AI))
        })
      }
    })

    return unsub
  }, [])

  const getModelCacheKey = (index) => {
    return {
      fileName: values.files[index],
      parentId: componentId,
      type: "component",
    }
  }

  const handleSelectFile = async (index) => {
    setSelectedFileIndex(index)

    selectFile(index)
  }

  const updateSelectedFileNameState = (fileName) => {
    const selectedFileNameState = {
      parentId: componentId,
      fileName: fileName,
    }
    console.log(
      "%cupdate selected file name state",
      "color: green",
      selectedFileNameState
    )
    dispatch(setSelectedModelFileName(selectedFileNameState))
  }

  const toggleEdit = () => {
    const newVal = !isComponentEditing
    setComponentEditing(newVal)
    dispatch(setEditMode(newVal))
  }

  const selectFile = async (index) => {
    const fileName = values.files[index]

    console.log("%cselect file", "color: lightgreen", fileName)

    updateSelectedFileNameState(fileName)

    const key = getModelCacheKey(index)

    console.log("%cmodelCacheKey", "color: lightgreen", { key })

    // See if model is in cache

    // const cachedModel = Object.values(modelCache).find(
    //     (cacheEntry) =>
    //         cacheEntry.parent_id === key.parentId && cacheEntry.model.file === key.fileName
    // )

    const cachedModel = modelServices.searchModelCache({
      modelCacheKey: key,
      modelCache: modelCache,
    })

    console.log("%cselected file in cache?", "color: lightgreen", {
      in_cache: cachedModel ? "Y" : "N",
      fileName: key.fileName,
    })

    if (cachedModel) {
      setModel(cachedModel)

      setViews(
        cachedModel.model.views.sort((a, b) => a.name.localeCompare(b.name))
      )
    } else {
      await modelServices.loadFile(
        getFilePath(),
        fileName,
        loadModelIntoCache,
        {
          createModelIndex: false,
        },
        showLoadStatus
      )
    }
  }

  // See what model indexes exist for each file

  useEffect(() => {
    if (values.files && values.files.length > 0 && componentId && accountId) {
      console.log("%cchecking model indexes", "color:pink", {
        files: values.files,
        componentId,
        accountId,
      })

      updateModelIndexList(componentId, accountId)
    }
  }, [values.files, componentId, accountId])

  const updateModelIndexList = (componentId, accountId) => {
    db.collection("model_index")
      .where("parent_id", "==", componentId)
      .where("account_id", "==", accountId)
      .get()
      .then((querySnapshot) => {
        setModelIndexes(querySnapshot.docs.map((doc) => doc.data().file_name))
      })
  }

  const createModelCacheEntry = (model, fileName) => {
    const modelState = modelServices.createModelCacheItem(
      model,
      fileName,
      values.name,
      componentId,
      "component"
    )

    return modelState
  }

  const loadModelIntoCache = (model, fileName, rawText, props) => {
    const modelState = createModelCacheEntry(model, fileName)

    dispatch(setModelState(modelState))

    if (props.createModelIndex) {
      createModelIndex(accountId, componentId, fileName, model)
    }
  }

  const createModelIndex = async (accountId, componentId, fileName, model) => {
    await dataServices.deleteExistingModelIndex(
      accountId,
      componentId,
      fileName
    )

    const index = await modelServices.createModelIndexObject(
      model,
      fileName,
      componentId,
      "component",
      accountId,
      hasAIMAIRole
    )
    console.log("%cadding component index", "color:yellow", index)
    db.collection("model_index").add(index)

    const newModelIndexes = [...(modelIndexes || []), fileName]
    setModelIndexes(newModelIndexes)
  }

  useEffect(() => {
    if (modelCache) {
      const key = getModelCacheKey(selectedFileIndex)

      // const cachedModel = Object.values(modelCache).find(
      //     (cacheEntry) =>
      //         cacheEntry.parent_id === key.parentId && cacheEntry.model.file === key.fileName
      // )

      const cachedModel = modelServices.searchModelCache({
        modelCacheKey: key,
        modelCache: modelCache,
      })

      if (cachedModel) {
        setModel(cachedModel)

        setViews(
          cachedModel.model.views.sort((a, b) => a.name.localeCompare(b.name))
        )
      }
    }
  }, [modelCache, getModelCacheKey, selectedFileIndex])

  useEffect(() => {
    if (selectedFileName && values.files.length > 0) {
      if (selectedFileIndex === undefined) {
        const fileName = selectedFileName.value[componentId]
        if (fileName !== undefined) {
          const index = values.files.findIndex((name) => name === fileName)
          handleSelectFile(index)
        }
      }
    }
  }, [selectedFileName, values, componentId])

  const handleAddFile = async (event) => {
    if (event.target.files) {
      if (event.target.files.length !== 0) {
        const file = event.target.files[0]

        clearSelectedFile()

        var fileReader = new FileReader()
        fileReader.onload = async function (fileLoadedEvent) {
          var modelText = fileLoadedEvent.target.result

          await storeComponentFile({
            name: file.name,
            modelText: modelText,
          })

          // Refresh file names on page and update firestore
          const newFiles = Array.from(
            new Set([...values.files, file.name])
          ).sort()

          await db
            .collection("components")
            .doc(componentId)
            .update({ files: newFiles }, { merge: true })

          const newValues = {
            ...values,
            files: newFiles,
            views: [],
          }
          setValues(newValues)

          await modelServices.loadFile(
            getFilePath(),
            file.name,
            loadModelIntoCache,
            {
              createModelIndex: true,
            },
            showLoadStatus
          )
        }

        fileReader.readAsText(file, "UTF-8")

        // Do this in case we want to try to upload the same file.
        // Without this line, trying to re-upload the same file we just
        // uploaded does not work.
        event.target.value = null
      }
    }
  }

  // Load component

  const [loadState, setLoadState] = useState({})

  useEffect(() => {
    if (user === undefined || accountId === undefined) {
      return
    }

    const newLoadState = { id: componentId, accountId: accountId }

    const isLoadStateChanged = !_.isEqual(newLoadState, loadState)

    setLoadState(newLoadState)

    if (!isLoadStateChanged) {
      return
    }

    if (componentId != null) {
      db.collection(COLLECTION_NAME)
        .doc(componentId)
        .get()
        .then(async (snapshot) => {
          const componentData = snapshot.data()

          let data = {
            ...initialValues(),
            ...componentData,
          }

          if (
            componentData.files === undefined ||
            componentData.files.length === 0
          ) {
            const fileDetails = await modelServices.getFileDetails(
              getFilePath()
            )
            const fileNames = fileDetails.map(
              (fileDetail) => fileDetail.meta.name
            )

            data = {
              ...data,
              files: fileNames.sort(),
            }
          }

          setValues(data)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentId, accountId, user])

  useEffect(() => setTitle(values.name), [values.name, setTitle])

  const getFilePath = () => {
    if (!accountId || !componentId) {
      console.error("getFilePath", "accountId or componentId not set", {
        accountId,
        componentId,
      })
    }
    return `accounts/${values.account_id}/components/${componentId}/`
  }

  const storeComponentFile = async (fileInfo) => {
    await modelServices.storeFile(getFilePath(), fileInfo)
  }

  const handleUploadComponent = (event) => {
    addFileRef.current.click()
  }

  const handlePromptDeleteFile = async (index) => {
    const newYesNoConfig = {
      ...yesNoConfig,
      title: "Delete File?",
      description: `Remove file ${values.files[index]} from Component?`,
      openPrompt: true,
      handleConfirm: () => handleDeleteFile(index),
    }

    setYesNoConfig(newYesNoConfig)
  }

  const clearSelectedFile = () => {
    setSelectedFileIndex(undefined)
    updateSelectedFileNameState(undefined)
    setViews(undefined)
    setModel(blankModel)
  }

  const handleDeleteFile = async (index) => {
    const fileNameToDelete = values.files[index]
    await deleteFileByName(fileNameToDelete)
  }

  const deleteFileByName = async (fileNameToDelete) => {
    modelServices.deleteFile(getFilePath(), fileNameToDelete)

    // Remove file from list of files in the component firestore document

    const newFiles = values.files.filter(
      (fileName) => fileName !== fileNameToDelete
    )

    // Merge changed file names in to doc

    await db
      .collection("components")
      .doc(componentId)
      .update({ files: newFiles }, { merge: true })

    const newValues = {
      ...values,
      files: newFiles.sort(),
    }

    console.log("updated files values", newValues)

    setValues(newValues)

    // Clear out the model, model views, and element summary
    clearSelectedFile()

    // Delete model_index data

    await db
      .collection("model_index")
      .where("account_id", "==", accountId)
      .where("parent_id", "==", componentId)
      .where("file_name", "==", fileNameToDelete)
      .get()
      .then((docRefs) => {
        docRefs.docs.forEach((doc) => {
          db.collection("model_index").doc(doc.id).delete()
        })
      })

    // Remove model from cache

    console.log("%cremoving model from cache", "color: blue", {
      parentId: componentId,
      fileName: fileNameToDelete,
    })
    dispatch(
      removeModel(
        modelServices.getModelCacheId({
          parentId: componentId,
          fileName: fileNameToDelete,
        })
      )
    )

    enqueueSnackbar(`Deleted file ${fileNameToDelete}`, { variant: "info" })
  }

  const hideDeletePrompt = () => {
    const newConfig = {
      ...yesNoConfig,
      openPrompt: false,
    }
    setYesNoConfig(newConfig)
  }

  const removeFileFromIndexCache = (accountId, componentId, fileName) => {
    // Remove index entry from redux cache -- since the listener in the Explorer page won't detect deleted records

    const newIndexCache = {
      lastModified: indexCache.lastModified,
      items: indexCache.items.filter(
        (item) =>
          !(
            item.account_id === accountId &&
            item.parent_id === componentId &&
            item.file_name === fileName
          )
      ),
    }

    dispatch(setComponentModelIndex(newIndexCache))
  }

  const STORIES_TAB = 1

  useEffect(() => {
    if (tabIndex === STORIES_TAB && !stories) {
      dataServices.getStories(componentId, accountId).then((stories) => {
        setStories(stories.sort((a, b) => a.name.localeCompare(b.name)))

        // // Check if component stories summary matches the stories just loaded

        dataServices
          .updateStoriesSummary(
            "components",
            componentId,
            values?.stories,
            stories
          )
          .then((storySummary) => {
            // if (storySummary) {
            //     setValues({
            //         ...values,
            //         stories: storySummary,
            //     })
            // }
          })
      })
    }
  }, [tabIndex])

  const handlePromptDeleteStory = (story) => {
    setYesNoConfig({
      title: "Delete",
      openPrompt: true,
      description: "Delete?",
      handleConfirm: () => handleDeleteStory(story.id),
    })
  }

  const handleDeleteStory = (storyId) => {
    dataServices
      .deleteStory({ storyId, parentId: componentId, type: "component" })
      .then(() => {
        enqueueSnackbar("Story deleted", { variant: "success" })
        setStories(stories.filter((s) => s.id !== storyId))
      })
  }

  // 'confirmed' is true or false
  const handleDeleteConfirmed = async () => {
    // Reset prompt flag, so if we'd clicked No, we can still click Delete again and get prompted

    hideDeletePrompt()

    if (
      componentId !== undefined &&
      componentId !== "" &&
      componentId !== null
    ) {
      // Delete files from storage

      await dataServices.deleteParentModelIndexes(componentId, accountId)

      values?.files.forEach((fileName) => {
        // Delete files from storage
        modelServices.deleteFile(getFilePath(), fileName)

        removeFileFromIndexCache(accountId, componentId, fileName)
      })

      // Delete component from firestore

      db.collection(COLLECTION_NAME)
        .doc(componentId)
        .delete()
        .then(() => {
          // Delete stories for this component

          db.collection("stories")
            .where("parent_id", "==", componentId)
            .where("account_id", "==", accountId)
            .get()
            .then(async (snapshot) => {
              snapshot.docs.forEach(async (doc) => {
                await db.collection("stories").doc(doc.id).delete()
              })
            })
        })
        .then(history.push("/components"))
        .then(enqueueSnackbar("Deleted", { variant: "success" }))
    }
  }

  const handlePromptConfirmDelete = (event) => {
    event.preventDefault()

    setShowProgress(true)

    enqueueSnackbar("Checking if delete allowed...one moment", {
      variant: "info",
    })

    //TODO: Check if delete allowed - is component referenced by project

    setShowProgress(false)

    const newConfig = {
      ...yesNoConfig,
      openPrompt: true,
      handleConfirm: handleDeleteConfirmed,
    }
    setYesNoConfig(newConfig)
  }

  // Get tags for selected view
  const getViewTags = () => {
    const tags = _.uniqWith(
      _.flatten(Object.values(values.view_tags).map((item) => item.tags)),
      (a, b) => a.label === b.label && a.type === b.type
    )

    return tags
  }

  const getFilteredViews = () => {
    // Find views which have the selected tags

    const selectedViewIds = values.view_tags
      .filter((viewTagInfo) => {
        const hasSelectedTags = viewTagInfo.tags.find((tag) =>
          selectedViewTags.find(
            (st) => st.type === tag.type && st.label === tag.label
          )
        )
        return hasSelectedTags
      })
      .map((item) => item.view_id)

    const newFilteredViews =
      selectedViewTags.length === 0
        ? views
        : views.filter((view) => selectedViewIds.includes(view.id))

    return newFilteredViews
  }

  const handleSubmit = async (event, addMultiple) => {
    console.log("addMultiple?", addMultiple)

    event.preventDefault()

    if (values.name === "") {
      enqueueSnackbar("Enter component name", { variant: "error" })
    } else {
      if (isNew()) {
        console.log("Adding new component")

        const user = await dataServices.getCurrentUser()

        const newRecord = {
          ...values,
          account_id: user.account_id,
          user: currentUID,
          created: dataServices.serverTimestamp(),
          modified: dataServices.serverTimestamp(),
        }

        console.log("saving component", newRecord)

        db.collection(COLLECTION_NAME)
          .add(newRecord)
          .then((docRef) => {
            setComponentId(docRef.id)
            history.replace(`/component/${docRef.id}`)
          })
          .then(
            enqueueSnackbar("Component Created", {
              variant: "success",
            })
          )
          .then(() => {
            if (addMultiple) {
              setValues(initialValues())
              setComponentId("")
            }
          })
      } else {
        const updateRecord = {
          ...values,
          tags_index: tagServices.toStringArray(values.tags),
          modified: dataServices.serverTimestamp(),
        }

        console.log("Updating existing component", componentId, updateRecord)

        await db
          .collection(COLLECTION_NAME)
          .doc(componentId)
          .update(updateRecord)

        enqueueSnackbar("Component saved", {
          variant: "success",
          vertical: "bottom",
          horizontal: "right",
        })

        // Update story names
        if (originalComponentName !== values.name) {
          console.log("%ccomponent name changed", "color:yellow", {
            from: originalComponentName,
            to: values.name,
          })
        }
        setOriginalComponentName(values.name)

        db.collection("stories")
          .where("parent_id", "==", componentId)
          .get()
          .then(async (snapshot) => {
            snapshot.docs.forEach(async (doc) => {
              // We update the story parent name to be the project name

              await db.collection("stories").doc(doc.id).update({
                parent_name: values.name,
              })
            })
          })
      }
    }
  }

  const handleOpenRule = (ruleId) => {
    if (ruleId) {
      history.push(`/RuleEdit/${ruleId}`)
    } else {
      console.error("Expecting ruleId", { ruleId })
    }
  }

  // Toggle for inclusion in ML training
  const handleToggleMlTraining = async (index) => {
    const newValues = {
      ...values,
      ml_training: !values.ml_training,
    }
    setValues(newValues)

    await db.collection("components").doc(componentId).update(
      {
        ml_training: !values.ml_training,
        modified: dataServices.serverTimestamp(),
      },
      { merge: true }
    )

    enqueueSnackbar(
      newValues.ml_training
        ? "Component added as candidate to ML training"
        : "Component removed as candidate from ML training",
      { variant: "success" }
    )
  }

  const handleRunRules = async (ruleConfig) => {
    console.log("%chandleRunRules", "color:yellow", ruleConfig)
    const allMsgs = await ruleServices.runRules(
      model,
      ruleConfig.rules,
      ruleConfig.mlModels,
      ruleConfig.mlRules,
      accountId,
      dispatch
    )

    const cacheId = modelServices.getModelCacheId({
      parentId: componentId,
      fileName: model.model.file,
    })

    dispatch(updateModelMessages(cacheId, allMsgs))
  }

  // https://stackoverflow.com/questions/64456780/how-can-i-get-input-data-from-mui-rte-editor
  // const handleEditorChange = (event) => {
  //     const rteContent = convertToRaw(event.getCurrentContent()) // for rte content with text formating
  //     console.log("rte content", JSON.stringify(rteContent))

  //     // This is how to get the HTML from the rich text editor data
  //     //console.log('html', stateToHTML(event.getCurrentContent()))

  //     setEditorValue(JSON.stringify(rteContent))
  // }

  const handleToggleIndexed = (fileName) => {
    console.log("%ctoggle indexing", "color:yellow", { fileName })

    if (modelIndexes.includes(fileName)) {
      db.collection("model_index")
        .where("parent_id", "==", componentId)
        .where("account_id", "==", accountId)
        .where("file_name", "==", fileName)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(async (doc) => {
            console.log("%cremoving index", "color:yellow", {
              indexData: doc.data(),
            })
            await doc.ref.delete()
          })
        })
        //.then(() => updateModelIndexList(componentId, accountId))
        .then(() => {
          setModelIndexes((curr) => curr.filter((name) => name !== fileName))
          console.log("%cRemove index", "color:yellow", { fileName })
        })
        .then(() =>
          enqueueSnackbar(
            "Removed index. Model will no longer be searchable in Component Explorer",
            { variant: "info" }
          )
        )
        .then(() => {
          // Remove index entry from redux cache -- since the listener in the Explorer page won't detect deleted records

          removeFileFromIndexCache(accountId, componentId, fileName)
        })
    } else {
      // Get model for file

      modelServices
        .loadFile(
          getFilePath(),
          fileName,
          loadModelIntoCache,
          {
            createModelIndex: true,
          },
          showLoadStatus
        )
        .then(() => {
          setModelIndexes([...modelIndexes, fileName])
        })
        .then(() =>
          enqueueSnackbar(
            "Index created. Model is now searchable in Component Explorer",
            {
              variant: "success",
            }
          )
        )
    }
  }

  const handleUpdateTags = (newTags) => {
    const newValues = {
      ...values,
      tags: newTags,
    }
    setValues(newValues)
  }

  const handleDownload = (index) => {
    console.log("downloading", { index, files: values.files })
    const fileName = values.files[index]

    const extraCallbackProps = {}

    modelServices.loadFile(
      getFilePath(),
      fileName,
      (model, fileName, rawText) => {
        console.log("%csaving raw text", "color:yellow", { rawText })
        var blob = new Blob([rawText], {
          type: "text/plain;charset=utf-8",
        })
        saveAs(blob, fileName)
      },
      extraCallbackProps,
      showLoadStatus
    )
  }

  const showLoadStatus = (status) => {
    console.log("%cload status", "color:lightgreen", status)

    if (status.err) {
      enqueueSnackbar(
        `Unable to upload file. Check file format is an Archi file (.archimate) or OpenExchange (.xml). Error: ${status.err}`,
        {
          variant: "error",
        }
      )
    }
  }

  const isModelCacheItemLoaded = () => {
    // console.log("%cissModelCacheItemLoaded", "color:yellow", {
    //     modelCache,
    //     selectedFileName,
    // })

    // const modelCacheItem = Object.values(modelCache).find(
    //     (cacheEntry) =>
    //         cacheEntry.parent_id === selectedFileName.value.parentId &&
    //         cacheEntry.model.file === selectedFileName.value.fileName
    // )

    const modelCacheItem = modelServices.searchModelCache({
      modelCacheKey: selectedFileName.value,
      modelCache: modelCache,
    })

    //console.log("%cmodelCacheItem", "color:yellow", { modelCacheItem })

    return modelCacheItem
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <YesNo config={yesNoConfig} />

      <ProgressBackdrop open={isShowProgress} />

      {isRunRulesDialogOpen && (
        <RunRulesDialog
          open={isRunRulesDialogOpen}
          setOpen={setRunRulesDialogOpen}
          handleRunRules={handleRunRules}
        />
      )}

      <TabPanel value={tabIndex || 0} index={0}>
        <Form>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "center",
            }}
          >
            {!isComponentEditing && (
              <>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="h5">
                    <b>{values.name}</b>
                  </Typography>
                  <Typography variant="body2">{values.description}</Typography>
                  <TagSummary
                    tags={values.tags}
                    isEditing={isComponentEditing}
                  />
                </Box>
                <Divider />
              </>
            )}
            {isComponentEditing && (
              <ComponentEditFields
                values={values}
                handleInputChange={handleInputChange}
                handleUpdateTags={handleUpdateTags}
                isEditing={isComponentEditing}
                isEditable={isEditable}
              />
            )}

            {/* <Box
                            sx={{
                                display: "flex",
                                marginLeft: "auto",
                            }}
                        >
                            <ToggleEditing toggleEdit={toggleEdit} />
                        </Box> */}
          </Box>

          {!isNew() && (
            <Box>
              <Heading>Component Models</Heading>

              <ModelFileList
                files={values.files}
                modelIndexes={modelIndexes}
                selectedFileIndex={selectedFileIndex}
                handleSelectFile={handleSelectFile}
                handleDownload={handleDownload}
                handleToggleIndexed={handleToggleIndexed}
                handlePromptDeleteFile={handlePromptDeleteFile}
                handleToggleMlTraining={handleToggleMlTraining}
              />
            </Box>
          )}

          {isNew() && (
            <Alert severity="info">
              Enter a component name and click <Bold>Save</Bold> to be able to
              upload model files
            </Alert>
          )}

          <Box sx={styles.buttons}>
            <Controls.Button
              type="button"
              text="Edit"
              endIcon={<EditIcon />}
              onClick={toggleEdit}
            />

            {!isNew() && isEditable && (
              <Controls.Button
                text="Delete"
                type="button"
                endIcon={<DeleteIcon />}
                onClick={handlePromptConfirmDelete}
              />
            )}

            {!isNew() && isEditable && (
              <Controls.Button
                text="Run Rules"
                type="button"
                tooltip="Run Architecture rules against the selected model"
                endIcon={<RuleIcon />}
                onClick={() => setRunRulesDialogOpen(true)}
                disabled={selectedFileIndex === undefined}
              />
            )}

            {componentId && (
              <Controls.Button
                text="Upload Model"
                type="button"
                tooltip="Upload an OpenExchange (.xml) or Archi (.archimate) model file"
                endIcon={<CloudUploadIcon />}
                onClick={handleUploadComponent}
                disabled={!isUploadModelActive(values.files?.length)}
              />
            )}

            {!isNew() && (
              <Controls.Button
                text="ML Training"
                type="button"
                tooltip={
                  values && values.ml_training
                    ? "Remove as candidate from ML training"
                    : "Include as candidate in ML training"
                }
                startIcon={
                  values && values.ml_training ? <RemoveIcon /> : <AddIcon />
                }
                endIcon={
                  <SmartToyIcon
                    style={{
                      color: values && values.ml_training && "#2f2",
                    }}
                  />
                }
                onClick={handleToggleMlTraining}
              />
            )}

            {isEditable && (
              <Controls.Button
                type="button"
                text="Save"
                variant="contained"
                endIcon={<SaveIcon />}
                onClick={(event) => handleSubmit(event, false)}
              />
            )}
          </Box>

          {values.files.length > 0 && selectedFileIndex !== undefined && (
            <Box>
              <Heading>Views</Heading>

              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  gap: "5px",
                  flexWrap: "wrap",
                }}
              >
                <Box sx={styles.centredWidget}>
                  <Box style={{ marginLeft: "15px" }}>
                    <Switch
                      checked={showDiagrams}
                      onChange={() => {
                        const show = !showDiagrams
                        dispatch(setShowDiagrams(show))
                        setShowDiagrams(show)
                      }}
                      color="primary"
                      size="small"
                    />
                  </Box>
                  <Box>
                    <Typography variant="caption" component={"span"}>
                      Show Views
                    </Typography>
                  </Box>
                </Box>
                <Box sx={styles.centredWidget}>
                  <Box style={{ marginLeft: "15px" }}>
                    <Switch
                      checked={showRules}
                      onChange={() => {
                        const show = !showRules
                        dispatch(setShowRules(show))
                        setShowRules(show)
                      }}
                      color="primary"
                      size="small"
                    />
                  </Box>
                  <Box>
                    <Typography variant="caption">Show Rules</Typography>
                  </Box>
                </Box>
                <Box style={{ marginLeft: "20px" }}>
                  <Tags
                    tags={getViewTags()}
                    notifySelected={setSelectedViewTags}
                  />
                </Box>
              </Box>
            </Box>
          )}

          <Box>
            {showRules &&
              model &&
              model.messages &&
              model.messages.length > 0 && (
                <Box sx={{ marginTop: "20px" }}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component={"span"}
                  >
                    Model-wide messages
                  </Typography>

                  {model.messages.find((msg) => !msg.element) === undefined && (
                    <Typography variant="body2">None</Typography>
                  )}

                  <List dense>
                    {model.messages
                      .filter((message) => !message.element)
                      .map((message, index) => (
                        <ListItemButton key={message.id}>
                          <ListItemIcon>
                            <RuleIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="caption">
                                {message.msg}
                              </Typography>
                            }
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="open rule"
                              onClick={() => handleOpenRule(message.ruleId)}
                            >
                              <Tooltip title="Open rule">
                                <EditIcon />
                              </Tooltip>
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItemButton>
                      ))}
                  </List>
                </Box>
              )}
          </Box>

          <Box sx={styles.views}>
            {views &&
              isModelCacheItemLoaded() &&
              getFilteredViews().map((view) => (
                <ViewCard
                  key={`${view.id}-${selectedFileIndex}`}
                  view={view}
                  modelCacheKey={getModelCacheKey(selectedFileIndex)}
                  showDiagram={showDiagrams}
                  showRules={showRules}
                  constrainRuleWidth={true}
                  tags={
                    values?.view_tags.find((item) => item.view_id === view.id)
                      ?.tags || []
                  }
                />
              ))}
          </Box>

          {!isNew() && selectedFileIndex !== undefined && (
            <Box>
              <Heading>
                Element Summary - {values.files[selectedFileIndex]}
              </Heading>
              <Box>
                <Box sx={styles.elementSummary}>
                  {model &&
                    getGroupedElements(model)
                      .filter((item) => !item.key.endsWith("Relationship"))
                      .map((item) => (
                        <Grid item key={item.key}>
                          <ElementCountSummaryCard
                            elementType={item}
                            modelState={model}
                          />
                        </Grid>
                      ))}
                </Box>
              </Box>
            </Box>
          )}

          <input
            hidden
            accept="*/*"
            sx={styles.input}
            id="icon-button-file"
            type="file"
            capture="environment"
            ref={addFileRef}
            onChange={(e) => handleAddFile(e)}
          />
        </Form>
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        {tabIndex === 1 && (
          <ComponentStories
            stories={stories}
            handlePromptDeleteStory={handlePromptDeleteStory}
            modelCache={modelCache}
            selectedFileIndex={selectedFileIndex}
          />
        )}
      </TabPanel>
    </LocalizationProvider>
  )
}

const ComponentStories = (props) => {
  const history = useHistory()

  const { stories, handlePromptDeleteStory, modelCache, selectedFileIndex } =
    props

  // const { storiesAndDiagrams, setStoriesAndDiagrams } = useState([])

  // useEffect(() => {
  //     if (stories) {
  //         const storiesAndDiagrams = stories.map((story) => {
  //             const key = modelServices.createModelCacheKey(
  //                 story.file_name,
  //                 story.parent_id,
  //                 story.type
  //             )

  //             const cachedModel = Object.values(modelCache).find(
  //                 (cacheEntry) =>
  //                     cacheEntry.parent_id === key.parentId &&
  //                     cacheEntry.model.file === key.fileName
  //             )

  //             return {
  //                 story,
  //                 model: cachedModel,
  //             }
  //         })
  //     }
  // }, [stories, modelCache])

  return (
    <Box>
      <Box>
        {/* {selectedFileIndex === undefined && (
                    <Alert severity="info">Select a model from the Component tab</Alert>
                )} */}

        {selectedFileIndex !== undefined && stories && stories.length === 0 && (
          <Alert severity="info">
            No stories defined. Select a view from the Component tab and choose
            Create Story.
          </Alert>
        )}
        <List dense style={{ maxWidth: 600 }}>
          {stories &&
            stories.map((story) => (
              <ListItem
                button
                key={story.id}
                onClick={() => {
                  history.push(`/Story/${story.id}`)
                }}
              >
                <ListItemText
                  primary={story.name}
                  secondary={
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      component={"span"}
                    >
                      {story.description}{" "}
                      <Tags
                        tags={story.tags || []}
                        editTags={false}
                        maxWidth={300}
                      />
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={() => handlePromptDeleteStory(story)}
                    size="large"
                  >
                    <icons.DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      </Box>
    </Box>
  )
}

const ComponentEditFields = (props) => {
  const { values, handleInputChange, handleUpdateTags, isEditing, isEditable } =
    props

  return (
    <Box sx={styles.mainGrid}>
      <Box style={{ maxWidth: 350 }}>
        <Controls.TextInput
          name="name"
          label="Component Name"
          value={values.name}
          disabled={!isEditable}
          onChange={handleInputChange}
        />
      </Box>

      <Box style={{ maxWidth: 500 }}>
        <Controls.TextInput
          name="description"
          label="Component Description"
          value={values.description}
          multiline
          onChange={handleInputChange}
        />
      </Box>

      <TagPanel
        tags={values.tags}
        handleUpdateTags={handleUpdateTags}
        heading={"Component Tags"}
        isEditing={isEditing}
      />
    </Box>
  )
}

export default withSnackbar(withRouter(ComponentEditForm))
