import React, { useState } from "react"
import * as common from "./common"
import BaseShapeRoundedRect from "./BaseShapeRoundedRect"
import { pink } from "@mui/material/colors"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const ImplementationEvent = (props) => {
    const { x, y, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = pink[100]
    const defaultColor = fillColor || pink[50]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <BaseShapeRoundedRect {...props} fill={fill} setFill={handleFill} />

            {common.getEventIcon(x + width, y + 5)}

            <ElementText {...props} label={label} />
        </Svg>
    )
}

export default ImplementationEvent
