import React, { useState } from "react"
import * as common from "./common"
import { blueGrey } from "@mui/material/colors"
import ElementText from "./ElementText"
import { useHistory, withRouter } from "react-router-dom"

const DiagramModelReference = (props) => {
    const { x, y, height, width, label, linkInfo } = props

    const hoverColor = blueGrey[100]
    const defaultColor = blueGrey[50]

    const history = useHistory()

    const [fill, setFill] = useState(defaultColor)

    const handleNavigate = () => {
        history.push(linkInfo.url)
    }

    return (
        <svg
            onMouseOver={() => {
                setFill(hoverColor)
            }}
            onMouseOut={() => {
                setFill(defaultColor)
            }}
            onClick={handleNavigate}
            style={common.elementStyle}
        >
            <rect
                x={x}
                y={y}
                fill={fill}
                stroke={common.outlineColor}
                width={width}
                height={height}
            />

            <ElementText {...props} label={label} />
        </svg>
    )
}

export default withRouter(DiagramModelReference)
