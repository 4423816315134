import React, { useState } from "react"
import Header from "../components/Header"
import { Paper } from "@mui/material"
import ExplorerForm from "../components/ExplorerForm"
import { setProjects } from "../redux/actions"
import { selectProjects } from "../redux/selectors"
import { setProjectModelIndex } from "../redux/actions"
import { selectProjectModelIndex } from "../redux/selectors"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setProjectExplorerSettings } from "../redux/actions"
import { selectProjectExplorerSettings } from "../redux/selectors"
import { spacing } from "./services/styleServices"

const styles = {
    pageContent: {
        margin: spacing(1),
        padding: spacing(1),
    },
}

const ProjectExplorer = (props) => {
    const history = useHistory()

    const dispatch = useDispatch()

    const [title, setTitle] = useState("Project Explorer")

    const settings = useSelector(selectProjectExplorerSettings)

    const updateSettings = (newSettings) => {
        dispatch(setProjectExplorerSettings(newSettings))
    }

    const handleClickItem = (id) => {
        history.push(`/Project/${id}`)
    }

    return (
        <Header title={title}>
            <Paper sx={styles.pageContent} elevation={0}>
                <ExplorerForm
                    settings={settings}
                    collectionName="projects"
                    updateSettings={updateSettings}
                    {...props}
                    setTitle={setTitle}
                    handleClickItem={handleClickItem}
                    cacheSelector={selectProjects}
                    cacheUpdater={setProjects}
                    indexSelector={selectProjectModelIndex}
                    indexUpdater={setProjectModelIndex}
                />
            </Paper>
        </Header>
    )
}

export default ProjectExplorer
