import React, { useState, useEffect } from "react"
import db from "../Firestore"
import RuleCard from "./RuleCard"
import { Grid, Box, Fab, Switch, FormControlLabel } from "@mui/material"
import * as dataServices from "../pages/services/dataServices"
import firebase from "firebase/compat/app"
import _ from "lodash"
import AddIcon from "@mui/icons-material/Add"
import { useHistory } from "react-router-dom"
import { selectShowRuleDetail } from "../redux/selectors"
import { setShowRuleDetail } from "../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import Controls from "./controls/Controls"
import * as cloudFunctions from "../pages/services/cloudFunctions"
import { useSnackbar } from "notistack"

const styles = {
    gridContainer: {
        paddingLeft: "5px",
        paddingRight: "5px",
    },
    fab: {
        position: "fixed",
        bottom: 16,
        right: 16,
        top: "auto",
        left: "auto",
    },
}

function RuleCards(props) {
    const [rules, setRules] = useState([])

    const [maxModified, setMaxModified] = useState()

    const COLLECTION_NAME = "rules"

    const { enqueueSnackbar } = useSnackbar()

    const [user, setUser] = useState()

    const [accountId, setAccountId] = useState()

    const history = useHistory()

    const dispatch = useDispatch()

    const showRuleDetail = useSelector(selectShowRuleDetail)

    useEffect(() => {
        const unsub = firebase.auth().onAuthStateChanged((user) => {
            console.log("### user changed", user)
            setUser(user)
            user.getIdTokenResult(false).then((token) => {
                console.log("### setting account id", token.claims.account_id)
                setAccountId(token.claims.account_id)
            })
        })

        return unsub
    }, [])

    // Listen for changes

    useEffect(() => {
        if (user === undefined || accountId === undefined) {
            return
        }

        //console.log('listen for changes', 'userDetails', userDetails)

        const unsubscribe = db
            .collection(COLLECTION_NAME)
            .where("modified", ">=", dataServices.localTimestamp())
            .where("account_id", "==", accountId)
            .onSnapshot((querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                    //console.log("=== job change ===", change.type, change.doc.data())

                    // trigger refresh
                    setMaxModified(change.doc.data().modified)
                })
            })

        return unsubscribe // gets called on unmount
    }, [user, accountId])

    // Get most recent modified record

    useEffect(() => {
        if (user === undefined || accountId === undefined) {
            return
        }

        const unsubscribe = db
            .collection(COLLECTION_NAME)
            .where("account_id", "==", accountId)
            .orderBy("modified", "desc")
            .limit(20)
            .onSnapshot((querySnapshot) => {
                let newMaxModified = null
                querySnapshot.docChanges().forEach((change) => {
                    if (
                        newMaxModified === null ||
                        change.doc.data().modified.seconds > newMaxModified.seconds
                    ) {
                        newMaxModified = change.doc.data().modified
                    }
                })
                if (newMaxModified !== null && newMaxModified !== maxModified) {
                    console.log("%csetMaxModified", "color:orange", newMaxModified, maxModified)
                    setMaxModified(newMaxModified)
                }
            })

        return unsubscribe
    }, [user, accountId])

    // Load rules

    const [loadState, setLoadState] = useState({})

    useEffect(() => {
        if (accountId === undefined) {
            return
        }

        const newLoadState = { accountId, maxModified }

        if (!_.isEqual(loadState, newLoadState)) {
            setLoadState(newLoadState)
        }
    }, [accountId, maxModified])

    useEffect(() => {
        if (loadState && loadState.accountId && loadState.maxModified) {
            console.log("%cload state", "color: orange", loadState)
            let query = db
                .collection(COLLECTION_NAME)
                .where("account_id", "==", loadState.accountId)

            dataServices.loadData("(Load rules)", query).then((rules) => {
                setRules(rules.sort((a, b) => a.name.localeCompare(b.name)))
                console.log("%cLoaded rules", "color: lightgreen", rules)
            })
        }
    }, [loadState])

    const handleAddRule = () => {
        history.push("/ruleedit")
    }

    const copyDefaultRules = async () => {
        enqueueSnackbar("Copying rules. Please wait. This can take up to 20 seconds", {
            variant: "info",
        })
        const result = await cloudFunctions.copyDefaultRules()

        console.log("copied default rules", { result })

        enqueueSnackbar("Copied default rules. New rules loading...", { variant: "success" })
    }

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: "20px",
                    marginBottom: "20px",
                }}
            >
                <FormControlLabel
                    control={
                        <Switch
                            size="small"
                            checked={showRuleDetail}
                            onChange={() => dispatch(setShowRuleDetail(!showRuleDetail))}
                        />
                    }
                    label="Show Rule Detail"
                />
                <Controls.Button
                    text="Copy default rules"
                    onClick={() => copyDefaultRules()}
                    tooltip="Copy the AIM default rules into your account"
                />
            </Box>
            <Grid container direction="row" spacing={2} flex="wrap" sx={styles.gridContainer}>
                {rules.map((rule) => (
                    <RuleCard key={rule.id} rule={rule} showRuleDetail={showRuleDetail} />
                ))}
            </Grid>
            <Fab color="primary" aria-label="add" sx={styles.fab} onClick={handleAddRule}>
                <AddIcon />
            </Fab>
        </Box>
    )
}

export default RuleCards
