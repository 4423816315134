import React from "react"
import { pink } from "@mui/material/colors"

const GenericLine = (props) => {
    const { x1, y1, x2, y2 } = props

    return (
        <svg>
            <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={pink[400]} strokeWidth='2' />
        </svg>
    )
}

export default GenericLine
