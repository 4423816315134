const ADMIN = "admin"
const COMPONENT_ADMIN = "comp_admin"
const COMPONENT_USER = "comp_user"
const PROJECT_ADMIN = "project_admin"
const PROJECT_USER = "project_user"
const AIM_AI = "aim_ai"

const userAssignableRoles = {
    [ADMIN]: "Admin",
    [COMPONENT_ADMIN]: "Component Admin",
    [COMPONENT_USER]: "Component User",
    [PROJECT_ADMIN]: "Project Admin",
    [PROJECT_USER]: "Project User",
    [AIM_AI]: "AIM AI",
}

const getRoles = () => {
    return [
        {
            name: ADMIN,
            label: "Admin",
            description: "Manage users, billing, and all other settings",
        },
        {
            name: COMPONENT_ADMIN,
            label: "Component Admin",
            description: "Manage components",
        },
        {
            name: COMPONENT_USER,
            label: "Component User",
            description: "Use components. Manage own components",
        },
        {
            name: PROJECT_ADMIN,
            label: "Project Admin",
            description: "Manage projects",
        },
        {
            name: PROJECT_USER,
            label: "Project User",
            description: "View projects. Manage own projects",
        },
        {
            name: AIM_AI,
            label: "AIM AI",
            description: "Use OpenAI-powered generative AI features",
        },
    ]
}

export {
    getRoles,
    ADMIN,
    COMPONENT_ADMIN,
    COMPONENT_USER,
    PROJECT_ADMIN,
    PROJECT_USER,
    AIM_AI,
    userAssignableRoles,
}
