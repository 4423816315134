import React, { useState } from "react"
import BaseShapeRoundedRect from "./BaseShapeRoundedRect"
import * as common from "./common"
import { deepOrange } from "@mui/material/colors"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const Capability = (props) => {
    const { x, y, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = deepOrange[200]
    const defaultColor = fillColor || deepOrange[100]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    const l = 4.5

    const box = (length, x, y, xGrid, yGrid) => {
        return (
            <rect
                x={x + length * xGrid}
                y={y + length * yGrid}
                stroke={common.outlineColor}
                fill="none"
                width={length}
                height={length}
            />
        )
    }

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <BaseShapeRoundedRect {...props} fill={fill} setFill={handleFill} />

            <svg x={width - 20} y={5}>
                {box(l, x, y, 2, 0)}
                {box(l, x, y, 1, 1)}
                {box(l, x, y, 2, 1)}
                {box(l, x, y, 0, 2)}
                {box(l, x, y, 1, 2)}
                {box(l, x, y, 2, 2)}
            </svg>

            <ElementText {...props} label={label} />
        </Svg>
    )
}

export default Capability
