import { getModel } from "../../model.mjs"
import { getOpenExchangeModel } from "../../openexchangemodel.mjs"
import { parseString } from "xml2js"
import * as palette from "../../components/symbols/palette"
import _ from "lodash"
import * as dataServices from "./dataServices"
import * as urlServices from "./urlServices"
import * as chatPromptServices from "./chatPromptServices"
import * as cloudFunctions from "./cloudFunctions"
import {
    getStorage,
    ref,
    getDownloadURL,
    uploadString,
    listAll,
    deleteObject,
} from "firebase/storage"

const storeFile = async (path, fileInfo) => {
    const fullPath = `${path}/${fileInfo.name}`

    console.log(
        `%cstoring file %c[${fileInfo.name}]%c in path %c${path}, %cfullPath %c${fullPath}`,
        "color:yellow",
        "color:lightgreen",
        "color:white",
        "color:yellow",
        "color:white",
        "color:yellow"
    )

    const storageRef = ref(getStorage(), fullPath)
    const result = await uploadString(storageRef, fileInfo.modelText)
    console.log("%cstored file", "color:lightgreen", result)
}

const createModelCacheKey = (fileName, parentId, type) => {
    return { fileName, parentId, type }
}

const searchModelCache = ({ modelCacheKey, modelCache }) => {
    const cachedModel = Object.values(modelCache).find(
        (cacheEntry) =>
            cacheEntry.parent_id === modelCacheKey.parentId &&
            cacheEntry.model.file === modelCacheKey.fileName
        // TODO: Check if we can safely add this back in
        // && cacheEntry.type === modelCacheKey.type
    )
    return cachedModel
}

const deleteFile = async (path, fileName) => {
    const storageRef = ref(getStorage(), `${path}/${fileName}`)

    await deleteObject(storageRef)
}

const deleteAllFilesInFolder = async (path) => {
    //console.log("%cdelete files in folder", "color:orange", path)
    const folderRef = ref(getStorage(), path)

    await deleteObject(folderRef)
}

const getFileDetails = async (path) => {
    //console.log("getFileDetails", path)

    const storageRef = ref(getStorage(), path)

    const fileRefs = await listAll(storageRef)

    //console.log("fileRefs", fileRefs.items.length, fileRefs)

    const getFileInfo = fileRefs.items.map(async (fileRef) => {
        const meta = await fileRef.getMetadata()
        const downloadUrl = await getDownloadURL(fileRef)
        return { meta: meta, url: downloadUrl }
    })

    const fileUrlResults = await Promise.all(getFileInfo)

    return fileUrlResults
}

// parentId is either a project id or component id
// type is either "project" or "component"
const createModelIndexObject = async (model, fileName, parentId, type, accountId, hasAIMAIRole) => {
    const elementIndex = model.elements
        .filter((element) => element.name)
        .map((element) => ({
            name: element.name,
            id: element.id,
            type: palette.getIndex(element.type),
        }))
        .filter((item) => item.type !== -1)
        .sort((a, b) => a.name.localeCompare(b.name))

    // Unique list of all element names
    const elementNames = Array.from(new Set(elementIndex.map((item) => item.name.toLowerCase())))

    //console.log("%celement index", "color:yellow", { model, elementIndex })

    const modelCacheItem = createModelCacheItem(model, fileName, model.name, parentId, type)

    // Create prompt data for each view

    const viewEmbeddings = []

    // always create the index, just limit semantic search to those who have the AIM AI role
    //if (hasAIMAIRole) {
    const promptDataResults = await model.views.map(async (view) => {
        const promptData = chatPromptServices.createPromptDataFromModelCache(modelCacheItem, view)

        const prompt = chatPromptServices.createChatPrompt({
            promptData,
            promptLayers: palette.LAYERS.map((layer) => layer.name),
            includeProperties: true,
            includeDoco: true,
            includeIds: false,
        })

        console.log('Creating embedding using prompt', { prompt })

        const embeddingResult = await cloudFunctions.createEmbedding(prompt)
        const embedding = embeddingResult.data.response.data[0].embedding

        return {
            model: { name: model.name },
            view: { name: view.name, id: view.id },
            embedding,
        }
    })

    viewEmbeddings.push(...(await Promise.all(promptDataResults)))
    //}

    // Create prompt data for each view

    //console.log("%cprompt data", "color:yellow", viewEmbeddings)

    const index = {
        account_id: accountId,
        parent_id: parentId,
        model_id: model.id,
        type: type,
        file_name: fileName,
        index_version: 1,
        elements: elementIndex,
        view_embeddings: viewEmbeddings,
        names: elementNames,
        created: dataServices.localTimestamp(),
        modified: dataServices.localTimestamp(),
    }

    return index
}

const loadFile = async (folderPath, fileName, setModelCallback, extraCallbackProps, setStatus) => {
    const storageRef = ref(getStorage(), `${folderPath}/${fileName}`)
    const downloadUrl = await getDownloadURL(storageRef)

    var xhr = new XMLHttpRequest()
    xhr.responseType = "text"
    xhr.onload = function (event) {
        var rawText = xhr.response
        parseString(rawText, async (err, result) => {
            //console.log("%cloaded model file", "color:lightgreen", { result, err })

            if (err) {
                console.log("%cerror loading model file", "color:red", { err, setStatus })
                if (setStatus) {
                    setStatus({ status: "error", message: "Error loading model file", err })
                    return
                }
            }

            const fileType = result["archimate:model"] ? "archimate" : "openexchange"

            if (fileType === "archimate") {
                const model = await getModel(result)

                //console.log("%cparsed ArchiMate model", "color:lightgreen", model)
                setModelCallback(model, fileName, rawText, {
                    ...extraCallbackProps,
                })
            } else if (fileType === "openexchange") {
                const model = await getOpenExchangeModel(result)
                //console.log("%cparsed OpenExchange model", "color:lightgreen", model)
                setModelCallback(model, fileName, rawText, {
                    ...extraCallbackProps,
                })
            }
        })
    }
    xhr.open("GET", downloadUrl)
    xhr.send()

    return { status: "ok" }
}

const getPaletteItem = (type, caller) => {
    if (type) {
        const symbol = palette.symbols[type]
        if (symbol) {
            return symbol
        }
    }

    console.log("%ccannot find type", "color:orange", type, caller)

    return palette.GenericSymbol
}

const defaultNullBoundsToZero = (bounds) => {
    if (bounds.x === null) {
        console.log("%cBOUNDS NULL", "color:orange")
    }
    return {
        x: bounds.x || 0,
        y: bounds.y || 0,
        width: bounds.width || 0,
        height: bounds.height || 0,
    }
}

const getNode = (model, nodeId) => {
    return model.model.elements.find((element) => element.id === nodeId)
}

//TODO: detect any cyclic loops, e.g. where A -> B, and B -> A either directly, or through a longer chain of connections -- Need to exit if loop detected
const mapSourceAndTarget = (r, model, relationships) => ({
    ...r,
    sourceNode: () => createGraphNode(getNode(model, r.source), relationships, model),
    targetNode: () => createGraphNode(getNode(model, r.target), relationships, model),
})

const createGraphNode = (element, relationships, model) => {
    const edges_incoming = relationships
        .filter((r) => r.target === element.id)
        .map((r) => mapSourceAndTarget(r, model, relationships))

    const edges_outgoing = relationships
        .filter((r) => r.source === element.id)
        .map((r) => mapSourceAndTarget(r, model, relationships))

    //console.log("%ccreateGraphNode", "color:yellow", { element, edges_incoming, edges_outgoing })

    const elementType = palette.getElementType(element.type)
    if (!elementType) {
        return undefined
    } else {
        return {
            ...element,
            layer: elementType.layer.name,
            edges: {
                in: edges_incoming,
                out: edges_outgoing,
            },
        }
    }
}

const buildGraph = (models) => {
    //console.log("%cbuildGraph", "color:orange", models)

    const result = _.flatten(
        models.map((model) => {
            const relationships = model.model.elements.filter((e) => e.source)

            const elements = model.model.elements
                .filter((e) => !e.source)
                .map((e) => {
                    return createGraphNode(e, relationships, model)
                })
                // This will filter out where creaateGraphNode returns
                // undefined, which happens when the element type is a relationnship
                // and not an element
                .filter((e) => e !== undefined)
            // .sort((a, b) => {
            //     if (a.layer !== b.layer) return a.layer.localeCompare(b.layer)
            //     return a.name.localeCompare(b.name)
            // })
            //console.log("%celements", "color:orange", elements)

            return elements
        })
    )

    return result
}

const getViewElements = (view, modelCacheItem) => {
    const typeName = "xsi:type"

    const fileName = modelCacheItem.model.file
    const parentId = modelCacheItem.parent_id

    const viewElements = view.elements.map((viewElement) => {
        if (viewElement.diagramObject[typeName] === `archimate:${palette.NOTE}`) {
            return {
                ...viewElement,
                element: undefined,
                symbol: getPaletteItem(palette.NOTE, "for Note"),
                symbolName: palette.NOTE,
            }
        }

        if (viewElement.diagramObject[typeName] === `archimate:${palette.GROUP}`) {
            return {
                ...viewElement,
                element: undefined,
                symbol: getPaletteItem(palette.GROUP, "for Group"),
                symbolName: palette.GROUP,
            }
        }

        if (
            viewElement.diagramObject[typeName] === `archimate:${palette.DIAGRAM_MODEL_REFERENCE}`
        ) {
            // Get view name referenced by this element
            const referencedView = modelCacheItem.model.views.find(
                (view) => view.id === viewElement.diagramObject.model
            )

            const diagramModelRef = {
                ...viewElement,
                element: { name: `${referencedView.name}` },
                symbol: getPaletteItem(
                    palette.DIAGRAM_MODEL_REFERENCE,
                    "for diagram model reference"
                ),
                symbolName: palette.DIAGRAM_MODEL_REFERENCE,
                linkInfo: {
                    url: urlServices.createViewEditUrl({
                        parentId: parentId,
                        viewId: referencedView.id,
                        fileName: fileName,
                    }),
                    state: { parent: parentId, type: "???" },
                },
            }

            return diagramModelRef
        }

        const element = modelCacheItem.model.elements.find(
            (el) => el.id === viewElement.diagramObject.archimateElement
        )

        if (element) {
            // console.log(`%celement %c${element.name}`, "color:yellow", "color:pink", {
            //     viewElement,
            //     element,
            // })
        } else {
            console.log(
                `%cCannot find archimateElement %c${viewElement.diagramObject.archimateElement}`,
                "color:yellow",
                "color:pink",
                {
                    type: viewElement.diagramObject[typeName],
                    viewElement,
                    modelElements: modelCacheItem.model.elements,
                    modelCacheItem,
                }
            )
            return undefined
        }

        const url = urlServices.createElementViewUrl({
            parentId: parentId,
            fileName: fileName,
            elementId: element.id,
        })

        const getLabel = (element, viewElement) => {
            switch (element.type) {
                case "Note":
                    return viewElement.noteContent

                case "Group":
                    return "GROUP CONTENT?"

                default:
                    return element ? element.name : "No name"
            }
        }

        const label = getLabel(element, viewElement)

        return {
            ...viewElement,
            element: element,

            // This 'symbol' attribuite wont show when we pretty print the JSON
            // hence why we've added 'symbolName' just for debugging purposes
            symbol: getPaletteItem(element.type, "getViewElements"),
            symbolName: element.type,

            label: label,

            linkInfo: {
                url: url,
            },
        }
    })

    //console.log('%cfound view elements', 'color:yellow', view.name, viewElements)

    return viewElements
}

const splitCamelCase = (str) => {
    str = str.charAt(0).toUpperCase() + str.slice(1) // Capitalize the first letter
    str = str.replace(/([0-9A-Z])/g, " $&") // Add space between camel casing
    return str
}

// Sometimes the numeric bendpoint data is read in as a string, which then causes error with any calcs
// So ensure bendpoint data is represented as ints
const bendpointToInts = (bp) => {
    return {
        // If startX or startY are 0 then the 'startX' and 'startY' attributes are not present
        startX: parseInt(bp.startX) || 0,
        startY: parseInt(bp.startY) || 0,
        endX: parseInt(bp.endX),
        endY: parseInt(bp.endY),
    }
}

const calculateDiagramDimensions = (view, modelCacheItem) => {
    const elements = getViewElements(view, modelCacheItem)

    // const connections = _.flatten(
    //     elements.map((ve) =>
    //         ve.sourceConnections.map((sc) => {
    //             const cnx = {
    //                 ...sc.connection,
    //                 bendpoints: sc.bendpoints?.map((bp) => {
    //                     return {
    //                         // If startX or startY are 0 then the 'startX' and 'startY' attributes are not present
    //                         startX: parseInt(bp.startX) || 0,
    //                         startY: parseInt(bp.startY) || 0,
    //                         endX: parseInt(bp.endX),
    //                         endY: parseInt(bp.endY),
    //                     }
    //                 }),
    //             }

    //             return cnx
    //         })
    //     )
    // )

    let cMinX, cMinY

    const diagramDimensions = calculateDiagramElementDimensions(elements, cMinX, cMinY)

    return { viewId: view.id, dimensions: diagramDimensions }
}

const calculateDiagramElementDimensions = (viewElements, cMinX, cMinY) => {
    let maxWidth = 0,
        maxHeight = 0

    // capture how far left and up we can offset the diagram
    // so that we don't have too much white space on the left/upper side
    let minX, minY

    if (cMinX) {
        minX = cMinX
    }

    if (cMinY) {
        minY = cMinY
    }

    viewElements.forEach((viewElement) => {
        // Could be a 'Note' in which case 'element' will be undefined, since Note's are
        // local to a diagram, and do not have a corresponding archimate element
        //console.log("%ccalculateDiagramDimensions", "color:yellow", viewElement.element?.name)

        const xVal = viewElement.bounds.x + viewElement.bounds.width
        maxWidth = Math.max(xVal, maxWidth)

        const yVal = viewElement.bounds.y + viewElement.bounds.height
        maxHeight = Math.max(maxHeight, yVal)

        if (minX === undefined || viewElement.bounds.x < minX) {
            minX = viewElement.bounds.x
        }

        if (minY === undefined || viewElement.bounds.y < minY) {
            minY = viewElement.bounds.y
        }
    })

    const margin = 0

    // We need to subtract minX and minY because sometimes the model has -ive x/y values (for some reason??)
    // and the diagram all seems to display in the correct width/height if we add on any -ive x/y values.
    // Otherwise if, say minX = -100, and we didn't add 100 on then the diagram would be 100 too narrow.
    const dimensions = {
        width: maxWidth + margin - minX,
        height: maxHeight + margin - minY,
        widthOffset: minX,
        heightOffset: minY,
    }

    if (isNaN(dimensions.width) || isNaN(dimensions.height)) {
        console.log("%cNaN alert", "color:red", { dimensions, viewElements })
    }

    return dimensions
}

const getModelCacheId = ({ parentId, fileName }) => {
    return `${parentId}-${fileName}`
}

const getModelFromCache = ({ modelCache, parentId, fileName }) => {
    const modelCacheItem = Object.values(modelCache).find(
        (cacheEntry) => cacheEntry.parent_id === parentId && cacheEntry.model.file === fileName
    )

    return modelCacheItem
}

/**
 * Add a model cache item into the model cache.
 *
 * @param {*} model
 * @param {*} fileName
 * @param {*} name
 * @param {*} parentId
 * @param {*} parentType
 * @returns
 */
const createModelCacheItem = (model, fileName, name, parentId, parentType) => {
    // console.log("%ccreateModelCacheItem", "color:yellow", {
    //     name,
    //     fileName,
    //     parentId,
    //     parentType,
    // })
    const modelState = {
        //id: `${model.id}-${fileName}`,
        id: getModelCacheId({ parentId, fileName }),
        parent_id: parentId,
        name: name,
        type: parentType,
        model: { ...model, file: fileName },
    }

    // Pre-calculate dimensions for all views, so we can cache this also for performance reasons
    const diagramDimensions = model.views.map((view) =>
        calculateDiagramDimensions(view, modelState)
    )

    const mergedViews = model.views.map((view) => ({
        ...view,
        dimensions: diagramDimensions.find((dim) => dim.viewId === view.id).dimensions,
    }))

    const modelStateWithViewDimensions = {
        ...modelState,
        model: { ...modelState.model, views: mergedViews },
    }

    return modelStateWithViewDimensions
}

export {
    getFileDetails,
    loadFile,
    storeFile,
    deleteFile,
    deleteAllFilesInFolder,
    getViewElements,
    getPaletteItem,
    defaultNullBoundsToZero,
    splitCamelCase,
    calculateDiagramDimensions,
    createModelCacheItem,
    createModelCacheKey,
    searchModelCache,
    createModelIndexObject,
    bendpointToInts,
    getModelCacheId,
    buildGraph,
    createGraphNode,
    getModelFromCache,
}
