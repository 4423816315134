import React, { useEffect, useState } from "react"
import ComboBox from "./ComboBox"
import * as dataServices from "../../pages/services/dataServices"
import { useAuth } from "../AuthContext"

const ProviderCombobox = (props) => {
    const { name, label, value, onChange, helperText, readonly, accountId } = props

    const [userDetails, setUserDetails] = useState(useAuth().userDetails)

    const [providerOptions, setProviderOptions] = useState([
        {
            id: "",
            label: "(None)",
        },
    ])

    function createProviderOption(provider) {
        return {
            id: provider.id,
            label: provider.name,
        }
    }

    useEffect(() => {
        const isLoadFromCacheFirst = false // userDetails.loaded_providers

        if (readonly) {
            if (value !== "" && value !== undefined) {
                const providerIds = [value]
                dataServices.getProvidersById(providerIds).then((providers) => {
                    const options = providers.map((provider) => createProviderOption(provider))
                    setProviderOptions(options)
                })
            }
        } else {
            //console.log("Retrieving providers for account", accountId)

            dataServices
                .getProvidersByAccountId(accountId, isLoadFromCacheFirst)
                .then((providers) => {
                    const options = providers.map((provider) => createProviderOption(provider))
                    options.push({ id: "", label: "(None)" })
                    setProviderOptions(options)
                })

            setUserDetails((userDetails) => ({
                ...userDetails,
                loaded_providers: true,
            }))
        }
    }, [readonly, value, accountId])

    return (
        <ComboBox
            name={name}
            label={label}
            value={value}
            items={providerOptions}
            onChange={onChange}
            disabled={readonly}
            helperText={helperText}
        />
    )
}

export default ProviderCombobox
