import { useEffect, useState } from "react"
import firebase from "firebase/compat/app"
import { getAuth, onAuthStateChanged } from "firebase/auth"

const useAccountStatus = (props) => {
  const [accountStatus, setAccountStatus] = useState("inactive")

  const [plan, setPlan] = useState()

  const [billingSetup, setBillingSetup] = useState()

  const PLAN_BASIC = "basic"
  const PLAN_BUSINESS = "business"

  useEffect(() => {
    // Set up the authentication state listener
    const unsub = onAuthStateChanged(getAuth(), (user) => {
      if (user !== null) {
        // Force refresh the ID token to get the latest claims
        user
          .getIdTokenResult(true)
          .then((token) => {
            console.log("%cupdate account status", "color:yellow", {
              claims: token.claims,
            })
            setAccountStatus(token.claims.account_status)
            setPlan(token.claims.plan)
            setBillingSetup(token.claims.billing_setup)
          })
          .catch((error) => {
            console.error("Error fetching ID token:", error)
          })
      } else {
        // Optionally handle the case when no user is signed in
        setAccountStatus(null)
        setPlan(null)
        setBillingSetup(null)
      }
    })

    // Clean up the listener on component unmount
    return () => unsub()
  }, [])

  const isActive = () => {
    switch (plan) {
      case PLAN_BASIC:
        return true

      case PLAN_BUSINESS:
        return accountStatus === "active"

      default:
        return false
    }
  }

  /**
   * @param fileCount {number} - number of files already uploaded to parent (project/component)
   * @returns
   */
  const isUploadModelActive = (fileCount) => {
    return isActive() ? true : fileCount === 0
  }

  const isNewProjectActive = (projectCount) => {
    return isActive() ? true : projectCount < 5
  }

  const isNewComponentActive = (componentCount) => {
    return isActive() ? true : componentCount < 5
  }

  return {
    isActive,
    isUploadModelActive,
    isNewProjectActive,
    isNewComponentActive,
  }
}

export default useAccountStatus
