import React, { useState } from "react"
import Svg from "./Svg"
import { useFill } from "./useFill"
import * as common from "./common"

const Assignment = (props) => {
    const { x1, y1, x2, y2, showStartMarker = true, showEndMarker = true, highlight, lineColor } = props

    const markerStart = showStartMarker ? "url(#assignment-start)" : ""
    const markerEnd = showEndMarker ? "url(#assignment-end)" : ""

    const hoverColor = common.defaultLineHighlightColor
    const defaultColor = lineColor || common.defaultLineColor

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, setFill })

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <defs>
                <marker
                    id='assignment-end'
                    markerWidth='7'
                    markerHeight='7'
                    refX='4'
                    refY='2.5'
                    orient='auto'
                >
                    <polygon points='0 0, 5 2.5, 0 5' />
                </marker>
                <marker
                    viewBox='0 0 10 10'
                    id='assignment-start'
                    refX={3}
                    refY={3}
                    markerWidth={10}
                    markerHeight={10}
                    orient='auto'
                >
                    <circle cx={3} cy={3} r={2} />
                </marker>
            </defs>
            <line
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                stroke={fill}
                strokeWidth='2'
                markerStart={markerStart}
                markerEnd={markerEnd}
            />
        </Svg>
    )
}

export default Assignment
