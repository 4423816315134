import React, { useState } from "react"
import BaseShapeRoundedRect from "./BaseShapeRoundedRect"
import * as common from "./common"
import { deepOrange } from "@mui/material/colors"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const CourseOfAction = (props) => {
    const { x, y, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = deepOrange[300]
    const defaultColor = fillColor || deepOrange[200]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
        var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

        return {
            x: centerX + radius * Math.cos(angleInRadians),
            y: centerY + radius * Math.sin(angleInRadians),
        }
    }

    const describeArc = (x, y, radius, startAngle, endAngle) => {
        var start = polarToCartesian(x, y, radius, endAngle)
        var end = polarToCartesian(x, y, radius, startAngle)

        var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1"

        var d = ["M", start.x, start.y, "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(
            " "
        )

        return d
    }

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <BaseShapeRoundedRect {...props} fill={fill} setFill={handleFill} />

            <svg x={width - 20} y={6}>
                {common.get3CirclesIcon(x, y)}

                <path
                    d={describeArc(x + 3, y + 17, 7, 280, 340)}
                    fill='none'
                    stroke={common.outlineColor}
                />

                <path
                    d={`M${x},${y + 10} l 0 3 l -3 -3 z`}
                    stroke={common.outlineColor}
                    fill={common.outlineColor}
                />
            </svg>

            <ElementText {...props} label={label} />
        </Svg>
    )
}

export default CourseOfAction
