import React, { useState, useEffect, useMemo, useRef } from "react"
import { withSnackbar, useSnackbar } from "notistack"
import { useHistory, withRouter } from "react-router-dom"
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  Grow,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Rating,
  Select,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material"
import Controls from "./controls/Controls"
import DeleteIcon from "@mui/icons-material/Delete"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import DashboardIcon from "@mui/icons-material/Dashboard"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import LabelIcon from "@mui/icons-material/Label"
import SummarizeIcon from "@mui/icons-material/Summarize"
import SellIcon from "@mui/icons-material/Sell"
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome"
import AddIcon from "@mui/icons-material/Add"
import TitleIcon from "@mui/icons-material/Title"
import SaveIcon from "@mui/icons-material/Save"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import ContentPasteIcon from "@mui/icons-material/ContentPaste"
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate"
import PostAddIcon from "@mui/icons-material/PostAdd"
import ImageIcon from "@mui/icons-material/Image"
import HideImageIcon from "@mui/icons-material/HideImage"
import SettingsIcon from "@mui/icons-material/Settings"
import EditIcon from "@mui/icons-material/Edit"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove"
import NotesIcon from "@mui/icons-material/Notes"
import MenuBookIcon from "@mui/icons-material/MenuBook"
import VisibilityIcon from "@mui/icons-material/Visibility"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import EditAttributesIcon from "@mui/icons-material/EditAttributes"
import DownloadIcon from "@mui/icons-material/Download"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import YesNo from "./YesNo"
import _ from "lodash"
import db from "../Firestore"
import * as palette from "./symbols/palette"
import * as dataServices from "../pages/services/dataServices"
import ShaderDialog from "./ShaderDialog"
import { useWindowDimensions } from "../pages/services/useWindowDimensions"
import ModelEditRenameViewDialog from "./ModelEditRenameViewDialog"
import ModelEditRenamePropertyDialog from "./ModelEditRenamePropertyDialog"
import ModelEditGraphDiagram from "./ModelEditGraphDiagram"
import ModelEditPasteAdd from "./ModelEditPasteAdd"
import ModelEditLayoutSelector from "./ModelEditLayoutSelector"
import {
  createOpenExchangeModel,
  isElementShaded,
  getWorksheetInfo,
  addMissingPropIds,
  getAvailablePropertyValuesForElement,
  handleDownloadElementsAsXlsx,
  getDuplicatePropertyNames,
  createChatPromptData,
  getDuplicateElementsByView,
  getDuplicateElementsThisView,
  getMaxElementId,
  handleImportDataWithHierarchy,
  createGroupedView,
  handleMoveElementLeft,
  handleMoveElementRight,
  handleToggleProperty,
  renameProperty,
  getWorksheetRows,
  findMaxLevels,
  getElementsToPasteAdd,
  handleCalcNewViewElementsFromImport,
  getShaderOptionsForCurrentView,
} from "../pages/services/modelEditServices"
import { saveAs } from "file-saver"
import ModelEditPortolio from "./ModelEditPortfolio"
import ModelEditChildElements from "./ModelEditChildElements"
import ModelEditLegend from "./ModelEditLegend"
import ModelEditImportFile from "./ModelEditImportFile"
import CreatePromptDialog from "./CreatePromptDialog"
import ModelEditElementDialog from "./ModelEditElementDialog"
import TooltipHover from "./TooltipHover"
import { useDispatch, useSelector } from "react-redux"
import { setSelectedEnterpriseView } from "../redux/actions"
import { selectSelectedEnterpriseView } from "../redux/selectors"
import * as Roles from "../pages/services/roleServices"
import ModelEditMoveViewDialog from "./ModelEditMoveViewDialog"
import ChatDialog from "./ChatDialog"
import ModelEditCreateDiagramDialog from "./ModelEditCreateDiagramDialog"
import ModelEditAddPropertiesDialog from "./ModelEditAddPropertiesDialog"
import ModelEditCreateContentDialog from "./ModelEditCreateContentDialog"
import {
  createElementProperties,
  createBusinessProcess,
  createConsultingSummary,
  GPT_4o_LATEST,
  getAIUsageParam,
  MAX_VIEWS,
  IS_AIM_TRIAL,
  QTY_SELECTION_ANY,
  QTY_SELECTION_UPTO,
} from "../pages/services/chatGenerationServices"
import { createElementDescriptions } from "../pages/services/createContentServices"
import * as colors from "@mui/material/colors"
import ViewSetDialog from "./ViewSetDialog"
import GetAIMAIDialog from "./GetAIMAIDialog"
import DeleteDialog from "./DeleteDialog"
import AIProgress from "./AIProgress"
import { aiColor, aiIcon } from "../pages/services/colorServices"
import ModelEditDashboard from "./ModelEditDashboard"
import ModelEditCreateProcessDialog from "./ModelEditCreateProcessDialog"
import { setModelEditDashboardTab } from "../redux/actions"
import { SUGGESTED_VIEWS_TAB } from "../pages/services/modelEditDashboardServices"
import useClaims from "./useClaims"
import {
  createOpenAIFile,
  deleteOpenAIFile,
} from "../pages/services/cloudFunctions"
import TiptapEditor from "./tiptap/Tiptap"
import { markdownToTiptapJSON } from "./tiptap/markdownConverter"

const ModelEditForm = (props) => {
  const { setTitle } = props

  const { assistants } = props

  const { enqueueSnackbar } = useSnackbar()

  const aiRelatedIcon = { color: colors.orange[300] }

  const boundingRef = useRef()

  const { width, height } = useWindowDimensions()

  const [viewSetId, setViewSetId] = useState(props.computedMatch.params.id)

  const lastSelectedView = useSelector(selectSelectedEnterpriseView)

  const [selectedViewId, setSelectedViewId] = useState("")

  const [showBillingDialog, setShowBillingDialog] = useState(false)

  const [selectedItemId, setSelectedItemId] = useState(undefined)

  const [selectedShaderId, setSelectedShaderId] = useState("")

  const [viewDialogOpen, setViewDialogOpen] = useState(false)

  // Is a save required?
  const [isModified, setModified] = useState(false)

  const [highlightLevel, setHighlightLevel] = useState(undefined)

  const [highlightProp, setHighlightProp] = useState(undefined)

  const [shaders, setShaders] = useState([])

  const [sourceItem, setSourceItem] = useState(null)

  const [showImportDialog, setShowImportDialog] = useState(false)

  const [showEditElement, setShowEditElement] = useState(false)

  const [showLayoutOptions, setShowLayoutOptions] = useState(false)

  const [lastElementTypeAdded, setLastElementTypeAdded] = useState(null)

  const dispatch = useDispatch()

  const [showProps, setShowProps] = useState(true)

  const [showUsage, setShowUsage] = useState(false)

  const [duplicateItems, setDuplicateItems] = useState([])

  const [showLegend, setShowLegend] = useState(true)

  const [drawRequestTime, setDrawRequestTime] = useState(0)

  const [applyShaders, setApplyShaders] = useState(true)

  const [generationPanelCount, setGenerationPanelCount] = useState(0)

  const [applyShadersToChips, setApplyShadersToChips] = useState(true)

  const [quicksetProps, setQuicksetProps] = useState(false)

  const [hideUnshaded, setHideUnshaded] = useState(false)

  const [waitingElementIds, setWaitingElementIds] = useState([])

  const [showSample, setShowSample] = useState(false)

  // Which properties are hidden from view as chips under each element
  const [hiddenProps, setHiddenProps] = useState([])

  const [views, setViews] = useState([])

  const [generatingContentMessage, setGeneratingContentMessage] =
    useState("Generating...")

  // Needs to be an object so that changes made at top level are visible in nested function calls
  const [stopRequested, setStopRequested] = useState({ value: false })

  const [showShaderDialog, setShowShaderDialog] = useState(false)

  const [showPasteElementsDialog, setShowPasteElementsDialog] = useState(false)

  const [propNameToEdit, setPropNameToEdit] = useState("")

  const [showRenamePropertyDialog, setShowRenamePropertyDialog] =
    useState(false)

  const [showCreateDiagram, setShowCreateDiagram] = useState(false)

  const [showElementTypeAnchorEl, setShowElementTypeAnchorEl] = useState(null)

  // A method to provide an initial set of level spec info when creating content
  // Used as part of the suggest views / create prompts functionality on the dashboard
  // i.e. GPT-generated prompts that we can use to create content
  const [defaultLevelSpecs, setDefaultLevelSpecs] = useState()

  const [showPropsAnchorEl, setShowPropsAnchorEl] = useState(null)

  const [showMoveViewDialog, setShowMoveViewDialog] = useState(false)

  const { claims, accountId, roles } = useClaims({ forceRefresh: false })

  // Can be 'none', 'indicator', or 'show'
  const [showDocs, setShowDocs] = useState("show")

  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null)

  const [elementsWithProjects, setElementsWithProjects] = useState([])

  const [showDocoAnchorEl, setShowDocoAnchorEl] = useState(null)

  const [showChatPrompt, setShowChatPrompt] = useState(false)

  const [showConfigureViewSet, setShowConfigureViewSet] = useState(false)

  const history = useHistory()

  const [showDeleteViewSet, setShowDeleteViewSet] = useState(false)

  const [isGeneratingDescription, setGeneratingDescription] = useState(false)

  const [showChat, setShowChat] = useState(false)

  const [showCreateContent, setShowCreateContent] = useState(false)

  const [showCreateProcess, setShowCreateProcess] = useState(false)

  const [aiRole, setAiRole] = useState(false)

  const [showAddProperties, setShowAddProperties] = useState(false)

  // Delete me - used for development purposes only.
  const DEMO_THREAD_ID = "thread_obADc9hodQAJJpTN7c8FOUBM"

  const [messages, setMessages] = useState([])

  const [chatPromptData, setChatPromptData] = useState()

  const [allCoords, setAllCoords] = useState([])

  const [coordsByViewId, setCoordsByViewId] = useState({})

  const [showRender, setShowRender] = useState(false)

  //const [suggestedViewToShow, setSuggestedViewToShow] = useState()

  const [showEditable, setShowEditable] = useState(true)

  const [viewSet, setViewSet] = useState(undefined)

  const maxViews = useMemo(() => {
    if (roles) {
      // MAX_VIEWS defines for both free and paid accounts, so the fallback of 30 will never get used
      const result = getAIUsageParam({ roles, paramName: MAX_VIEWS }) || 30

      return result
    }
  }, [roles])

  const canAddView = useMemo(() => {
    if (aiRole) {
      return true
    }

    if (!maxViews) return false

    return views.length < maxViews
  }, [aiRole, views, maxViews])

  const sampleJsonData = [
    {
      type: "Capability",
      name: "Lead Generation",
      description: "Generates leads for the business",
      props: [{ name: "type", value: "Sales" }],
    },
    {
      type: "Capability",
      name: "Finance",
      children: [
        {
          type: "Capability",
          name: "Accounts Receivable",
          description: "Manages accounts receivable",
          props: [{ name: "type", value: "Finance" }],
        },
        {
          type: "Capability",
          name: "Accounts Payable",
          description: "Manages accounts payable for the business",
          props: [{ name: "type", value: "Finance" }],
        },
      ],
    },
  ]

  const addContentMethods = [
    {
      icon: <AutoAwesomeIcon />,
      text: (
        <Typography component="span">
          Click <b>Create Content</b>, which uses generative AI to produce
          content.
        </Typography>
      ),
    },
    {
      icon: <AddCircleIcon />,
      text: (
        <Typography component="span">
          Click <b>Add</b>, which allows you to add an element manually.
        </Typography>
      ),
    },
    {
      icon: <FileUploadIcon />,
      text: (
        <Typography component="span">
          Click <b>Import</b>, which allows you to import model content from an
          Excel file.
        </Typography>
      ),
    },
    {
      icon: <ContentPasteIcon />,
      text: (
        <Typography component="span">
          Click <b>Paste</b>, which allows you to paste JSON data to create a
          view.
        </Typography>
      ),
    },
  ]

  // const widthOptions = [
  //     { id: "1", title: "Browser" },
  //     { id: "2", title: "A4", width: 794, height: 1123 },
  //     { id: "3", title: "A3", width: 1123, height: 1587 },
  //     { id: "4", title: "A2", width: 1587, height: 2245 },
  //     { id: "5", title: "A1", width: 2245, height: 3182 },
  // ]

  // const [selectedWidth, setSelectedWidth] = useState("2")

  // const orientationOptions = [
  //     { id: "1", title: "Portrait" },
  //     { id: "2", title: "Landscape" },
  // ]

  // const [selectedOrientation, setSelectedOrientation] = useState("1")

  // const convertMMToPixels = (mm) => {
  //     return mm * 3.7795275591
  // }

  // const diagramWidth = useMemo(() => {
  //     if (selectedWidth === "1") {
  //         return width
  //     }

  //     const selectedWidthOption = widthOptions.find((option) => option.id === selectedWidth)
  //     const widthInMM =
  //         selectedOrientation === "1" ? selectedWidthOption.width : selectedWidthOption.height
  //     return convertMMToPixels(widthInMM)
  // }, [selectedWidth, selectedOrientation, width])

  // useEffect(() => {
  //     console.log("lastSelectedView", lastSelectedView)
  //     if (lastSelectedView && lastSelectedView.id !== selectedViewId) {
  //         setSelectedViewId(lastSelectedView.id)
  //     }
  // }, [lastSelectedView])

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />
  })

  const setCoords = (coords) => {
    if (showEditable) {
      // Adjust the x,y values to be relative to the bounding box
      const xy = {
        y: coords.y - boundingRef.current.offsetTop,
        x: coords.x - boundingRef.current.offsetLeft,
      }
      setAllCoords((currCoords) => [
        ...currCoords,
        {
          ...coords,
          ...xy,
        },
      ])
    }
  }

  const handleUpdatePromptHistory = ({ levelSpecs }) => {
    //console.log("%cappend prompt history", "color:yellow", { levelSpecs })

    const promptHistory = levelSpecs
      .map((levelSpec) => levelSpec.info)
      .filter((x) => x !== "")
    setViews((curr) => {
      const newViews = [...curr]
      const viewIndex = newViews.findIndex((view) => view.id === selectedViewId)

      console.log("update prompt history", {
        viewIndex,
        promptHistory,
        selectedViewId,
        newViews,
      })

      newViews[viewIndex].prompt_history = [
        ...(newViews[viewIndex].prompt_history || []),
        ...(promptHistory || []),
      ]
      //console.log("update prompt history", newViews)
      return newViews
    })
  }

  const triggerRedraw = () => {
    if (showEditable) {
      setAllCoords([]) // reset coords
    }
    setDrawRequestTime(Date.now())
  }

  const getCoordsWidth = () => {
    // Find the maximum x+width value from coords
    const maxX =
      Math.max(...allCoords.map((coord) => coord.x + coord.width)) + 10
    return maxX
  }

  const getCoordsHeight = () => {
    // Find the maximum y+height value from coords
    const maxY =
      Math.max(...allCoords.map((coord) => coord.y + coord.height)) + 10
    return maxY
  }

  // Find instances where the same property name exists with different case spelling
  const duplicatePropertyNames = useMemo(() => {
    //console.log("get duplicatePropertyNames", { views })
    return getDuplicatePropertyNames(views)
  }, [views])

  const [diagramDimensions, setDiagramDimension] = useState({
    width: 0,
    height: 0,
  })

  const currentShader = useMemo(() => {
    return shaders?.find((shader) => shader.id === selectedShaderId)
  }, [selectedShaderId, shaders])

  // TODO: still working out best way to determine diagram height, e.g. height or boundingHeight below, so that legend can be placed
  // just below the diagram without too much unnecessary white space between the diagram bottom and any legends underneath
  useEffect(() => {
    if (showEditable) {
      const result = {
        width: getCoordsWidth(),
        // Height as calculated by the coords provided by each element of the diagram
        height: getCoordsHeight(),
        // Height of the box that surrounds the top level elements and all children
        boundingHeight: boundingRef.current.clientHeight,
      }
      setDiagramDimension(result)
    }
  }, [boundingRef?.current?.clientHeight, allCoords, showEditable])

  const [deleteViewYesNoConfig, setDeleteViewYesNoConfig] = useState({
    title: "Delete view?",
    description: "This delete is permanent",
    openPrompt: false,

    // this method is set when we prompt for deletion
    handleConfirm: null,
  })

  const [deleteElementYesNoConfig, setDeleteElementYesNoConfig] = useState({
    title: "Delete element?",
    description: "Cannot be undone",
    openPrompt: false,

    // this method is set when we prompt for deletion
    handleConfirm: null,
  })

  const currentView = useMemo(() => {
    if (selectedViewId) {
      const view = views.find((view) => view.id === selectedViewId)

      // Regenerate view data if it is a pivot view

      if (view && view?.pivot_view !== "") {
        const sourceView = views.find((v) => v.id === view?.pivot_view)

        const pivotElements = createGroupedView({
          currentView: sourceView,
          propNames: view.pivot_props,
          sortedPropValues: view.sorted_prop_values,
          elementTypes: [palette.APPLICATION_COMPONENT],
          useTopLevel: view.pivot_use_top_level,
          selectedItemId: undefined,
        })

        view.elements = pivotElements
      }

      if (view) {
        dispatch(setSelectedEnterpriseView(view.id))
      }

      //console.log("selected new view", view)

      triggerRedraw()

      return view
    }
  }, [selectedViewId, views])

  const aiIconProps = useMemo(() => {
    if (currentView !== undefined) return aiIcon
    return {}
  }, [currentView])

  const aiIconDisabledProps = { color: colors.pink[50] }

  // Pivot views need properties defined, otherwise they are empty
  const isPivotViewMissingConfig = useMemo(() => {
    return (
      currentView &&
      currentView.pivot_view &&
      currentView.pivot_props.length === 0
    )
  }, [currentView])

  useEffect(() => {
    if (currentView?.id) {
      setCoordsByViewId((curr) => ({ ...curr, [currentView.id]: allCoords }))
    }
  }, [allCoords, currentView?.id])

  // Reset coords for svg drawing of the view
  useEffect(() => {
    triggerRedraw()
  }, [currentView, showProps, width, selectedShaderId])

  const elementTypesForCurrentView = useMemo(() => {
    const elementTypes =
      _.uniq(currentView?.elements.map((element) => element.type)).map(
        (type) => ({
          type,
          name: palette.getElementNameByIndex(type),
        })
      ) || []

    return elementTypes
  }, [currentView])

  const elements = useMemo(() => {
    return currentView?.elements || []
  }, [currentView])

  const [levels, setLevels] = useState(0)

  // Find the maximum number of hierarchical levels in the current view
  // Do this by going through each element and counting the number of levels to get to a root element
  useEffect(() => {
    if (elements === undefined) return 0

    const result = findMaxLevels(elements)

    if (levels !== result) {
      setLevels(result)
    }
  }, [elements])

  useEffect(() => {
    if (viewSetId) {
      db.collection("view_sets")
        .doc(viewSetId)
        .get()
        .then((doc) => {
          setTitle(doc.data().name)
          // Add purpose and view_suggestions in case it doesn't exist
          setViewSet({
            purpose: "",
            view_suggestions: [],
            view_questions: [],
            docs: [],
            ...doc.data(),
          })
        })
    }
  }, [viewSetId])

  useEffect(() => {
    if (
      levels > 0 &&
      levels !== currentView?.layout?.length &&
      selectedViewId !== "" &&
      selectedViewId !== null
    ) {
      // Increase currentView.layout to match levels
      const layout = currentView?.layout || []
      const newLayout = [...layout]

      // Trim newLayout to be the same length as levels
      if (newLayout.length > levels) {
        newLayout.splice(levels, newLayout.length - levels)
      }

      // Add new rows to newLayout to match levels
      for (let i = layout.length; i < levels; i++) {
        newLayout.push({ orientation: "row", wrap: "wrap" })
      }

      // Update the view but only if the layout has changed
      if (!_.isEqual(layout, newLayout)) {
        const newViews = [...views]
        const viewIndex = newViews.findIndex(
          (view) => view.id === selectedViewId
        )

        if (viewIndex > -1) {
          newViews[viewIndex].layout = newLayout
          setViews(newViews)
          setModified(true)
        }
      }
    }
  }, [levels, currentView, views, selectedViewId])

  const shaderOptions = useMemo(() => {
    if (shaders && views) {
      return getShaderOptionsForCurrentView({ shaders, currentView })
    }
  }, [shaders, views, currentView])

  const currentElement = useMemo(() => {
    const currentElement = elements.find((x) => x.id === selectedItemId)

    if (currentElement) {
      return addMissingPropIds(currentElement)
    }
    return undefined
  }, [selectedItemId, elements])

  useEffect(() => {
    if (currentView && accountId && !generationPanelCount) {
      // Get any work package elements from this view

      const workPackageElements = currentView.elements.filter(
        (element) => element.type === palette.getIndex(palette.WORK_PACKAGE)
      )

      if (workPackageElements.length === 0) return

      // Get batches of 30 work package names

      const batches = _.chunk(
        workPackageElements.map((wp) => wp.name),
        30
      )

      // Create promises to check if project names exist for each batch

      const promises = batches.map((batch) => {
        return db
          .collection("projects")
          .where("account_id", "==", accountId)
          .where("name", "in", batch)
          .get()
      })

      // Wait for all promises to resolve

      Promise.all(promises).then((querySnapshots) => {
        const elementsWithProjects = []

        querySnapshots.forEach((querySnapshot) => {
          elementsWithProjects.push(
            ...querySnapshot.docs.map((doc) => ({
              id: doc.id,
              name: doc.data().name,
            }))
          )
        })

        setElementsWithProjects(elementsWithProjects)
      })
    }
  }, [currentView, accountId, generationPanelCount])

  const isEditable = useMemo(() => {
    if (selectedItemId === undefined) {
      return false
    }

    // If its a pivot, its not editable if its a Grouping element

    if (
      currentView?.pivot_view !== "" &&
      palette.getIndex(palette.GROUPING) === currentElement?.type
    ) {
      return false
    }

    return true
  }, [selectedItemId, currentView, currentElement])

  const propOptions = useMemo(() => {
    if (currentElement) {
      return getAvailablePropertyValuesForElement(currentElement, views)
    }
  }, [currentElement, views])

  const uniquePropKeys = useMemo(() => {
    const allViewElements = views.reduce((acc, view) => {
      return [...acc, ...view.elements]
    }, [])

    // Get 'name' attribute of 'props' object for each element

    const allViewElementKeys = _.flatten(
      allViewElements
        .filter((element) => element.props.length > 0)
        .map((element) => element.props.map((p) => p.name))
    )

    const uniqueKeys = _.uniq(allViewElementKeys).sort((a, b) =>
      a.toLowerCase().localeCompare(b.toLowerCase())
    )

    return uniqueKeys
  }, [views])

  const viewOptions = useMemo(() => {
    const options = views.map((v) => {
      return { id: v.id, label: v.name || "<no name set>" }
    })
    //options.push({ id: "", label: viewNoneLabel })
    return options.sort((a, b) => a.label.localeCompare(b.label))
  }, [views])

  const setElements = (elements, textResponse) => {
    setViews((curr) =>
      curr.map((view) => {
        if (view.id === selectedViewId) {
          const result = { ...view, elements }
          if (textResponse) {
            result.text_response = view.text_response || textResponse
          }
          console.log("%cupdating view", "color:pink", { view: result })
          return result
        } else {
          return view
        }
      })
    )
    setModified(true)
  }

  const setTextResponse = (textResponse) => {
    setViews((curr) =>
      curr.map((view) => {
        if (view.id === selectedViewId) {
          return { ...view, text_response: textResponse }
        } else {
          return view
        }
      })
    )
    setModified(true)
  }

  const handleRequestStop = () => {
    console.log("requesting stop...")
    stopRequested.value = true

    // set generationPanelCount to 0 to hide the progress bar after 3 seconds
    setTimeout(() => {
      setGenerationPanelCount(0)
    }, 3000)
  }

  const handleEditShader = (item) => {
    setPropNameToEdit(item.name)
    setShowShaderDialog(true)
  }

  const handlePromptDeleteView = async (index) => {
    const newYesNoConfig = {
      ...deleteViewYesNoConfig,
      openPrompt: true,
      handleConfirm: () => handleDeleteView(),
    }

    setViewDialogOpen(false)
    setDeleteViewYesNoConfig(newYesNoConfig)
  }

  const handlePromptDeleteElement = async (index) => {
    const newYesNoConfig = {
      ...deleteElementYesNoConfig,
      openPrompt: true,
      handleConfirm: () => handleDeleteElement(),
    }

    setShowEditElement(false)
    setDeleteViewYesNoConfig(newYesNoConfig)
  }

  const handleCreateProcess = ({ values }) => {
    createBusinessProcess({
      name: values.name,
      parentElement: currentElement,
      handlePasteAdd,
      currentView,
    })
  }

  const handleLayoutWrapChange = (wrap, level) => {
    setViews((curr) => {
      const newViews = [...curr]
      const viewIndex = newViews.findIndex((view) => view.id === selectedViewId)
      newViews[viewIndex].layout[level].wrap = wrap
      return newViews
    })
    setModified(true)

    triggerRedraw()
  }

  const handleLayoutOrientationChange = (orientation, level) => {
    setViews((curr) => {
      const newViews = [...curr]
      const viewIndex = newViews.findIndex((view) => view.id === selectedViewId)
      newViews[viewIndex].layout[level].orientation = orientation

      return newViews
    })
    setModified(true)

    triggerRedraw()
  }

  const handleUpdateViewData = (viewData) => {
    // Check view name doesn't already exist

    const viewNameExists = views.find((view) => {
      return view.name === viewData.name && view.id !== viewData.id
    })

    if (viewNameExists) {
      enqueueSnackbar("View name already exists", {
        variant: "error",
      })
      return
    }

    if (viewData.pivot_view !== "") {
      const sourceView = views.find((v) => v.id === viewData.pivot_view)
      const pivotElements = createGroupedView({
        currentView: sourceView,
        propNames: viewData.pivot_props,
        sortedPropValues: viewData.sorted_prop_values,
        elementTypes: [palette.APPLICATION_COMPONENT],
        useTopLevel: viewData.pivot_use_top_level,
        selectedItemId: undefined,
      })

      viewData.elements = pivotElements
    }

    setViewDialogOpen(false)
    setViews((prevViews) => {
      const newViews = [...prevViews]
      const viewIndex = newViews.findIndex((v) => v.id === viewData.id)

      newViews[viewIndex] = viewData

      const viewId = viewData.id

      const dataToUpdate = { ...viewData }
      delete dataToUpdate.id

      db.collection("views")
        .doc(viewId)
        .update(
          { ...dataToUpdate, modified: dataServices.serverTimestamp() },
          { merge: true }
        )
        .then(() => {
          //console.log("updated view", { viewId, view: dataToUpdate })
        })
        .catch((error) => {
          console.error("Error updating view: ", {
            error,
            viewId,
            view: dataToUpdate,
          })
        })

      return newViews
    })

    setModified(true)
  }

  // Find elements that are not the child of any other element
  const rootElements = useMemo(() => {
    const root = elements.filter((item) => {
      const result =
        elements.find((child) =>
          child.children.find((x) => x.id === item.id)
        ) === undefined
      return result
    })

    const result = root.map((item) => ({
      ...item,
      symbol: palette.symbols[palette.getElementNameByIndex(item.type)],
    }))

    return result
  }, [elements])

  // Get all of the elements in the view that need to be shaded if hideUnshaded is true
  // and a shader is selected
  const shadedElementIds = useMemo(() => {
    if (!currentShader) {
      return []
    }

    if (!hideUnshaded) {
      return []
    }

    const elementsCopy = elements.map((element) => ({
      name: element.name,
      id: element.id,
      children: element.children,
      props: element.props,
    }))

    const leafElements = elementsCopy.filter(
      (element) => element.children.length === 0
    )

    const shadedLeafElements = leafElements.filter((leaf) =>
      isElementShaded(leaf, currentShader)
    )

    let currRow = shadedLeafElements
    const result = shadedLeafElements.map((element) => element.id)
    shadedLeafElements.forEach((element) => (element.shaded = true))
    while (true) {
      const oneUp = currRow
        .map((item) => {
          return elementsCopy.find((element) =>
            element.children.find((x) => x.id === item.id)
          )
        })
        .filter((x) => x !== undefined)
      if (oneUp.length === 0) {
        break
      }
      oneUp.forEach((element) => (element.shaded = true))
      currRow = oneUp
      result.push(...oneUp.map((element) => element.id))
    }

    return result
  }, [elements, currentShader, hideUnshaded])

  useEffect(() => {
    if (accountId && viewSetId) {
      const unsubscribe = db
        .collection("views")
        .where("account_id", "==", accountId)
        .where("view_set_id", "==", viewSetId)
        .onSnapshot((snapshot) => {
          const newViews = snapshot.docs.map((doc) => {
            const data = doc.data()

            // Add id attribute in so the UI can use it for a key in React
            const dataWithIdAddedToProps = {
              pivot_view: data.pivot_view || "", // can be normal or pivot
              pivot_use_top_level: data.pivot_use_top_level || false,
              pivot_props: data.pivot_props || [],
              sorted_prop_values: data.sorted_prop_values || [],
              ...data,
              elements: data.elements.map((element) => {
                return {
                  ...element,
                  props: element.props
                    .map((prop, index) => ({
                      ...prop,
                      type: prop.type || "text",
                      id: index,
                    }))
                    .filter((prop) => prop.name !== ""),
                }
              }),
            }

            // obsolete field, replaced by pivot_config
            delete dataWithIdAddedToProps.type

            const maxLevels = findMaxLevels(data.elements)

            // trim layout to have the same number of levels as the elements
            const newLayout = (data.layout || []).slice(0, maxLevels)

            const result = {
              id: doc.id,
              hidden_element_types: [],
              ...dataWithIdAddedToProps,
              layout: newLayout,
            }

            return result
          })
          setViews(newViews)
        })

      return unsubscribe
    }
  }, [accountId, viewSetId])

  useEffect(() => {
    if (accountId) {
      const unsubscribe = db
        .collection("shaders")
        .where("account_id", "==", accountId)
        .onSnapshot((snapshot) => {
          const newShaders = snapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() }
          })
          setShaders(newShaders)
        })

      return unsubscribe
    }
  }, [accountId])

  useEffect(() => {
    if (roles) {
      setAiRole(roles.includes(Roles.AIM_AI))
    }
  }, [roles])

  const handleUpdateTextResponse = (textResponse) => {
    setViews((curr) => {
      const newViews = [...curr]
      const viewIndex = newViews.findIndex((view) => view.id === selectedViewId)
      newViews[viewIndex].text_response = textResponse
      return newViews
    })
    setModified(true)
  }

  const handleGenerateDescription = () => {
    if (!IS_AIM_TRIAL && !aiRole) {
      setShowBillingDialog(true)
      return
    }

    setGenerationPanelCount(1)
    setGeneratingContentMessage("Generating title...")
    setGeneratingDescription(true)

    createConsultingSummary({
      currentView,
      objective: viewSet.purpose,
      assistants,
      roles,
    }).then((result) => {
      //console.log("consulting summary result", result)

      // Update title and direct_statement in view

      setViews((curr) => {
        const newViews = [...curr]
        const viewIndex = newViews.findIndex(
          (view) => view.id === selectedViewId
        )
        newViews[viewIndex].description = result.title
        newViews[viewIndex].direct_statement = result.direct_statement
        newViews[viewIndex].how_to_read = result.how_to_read
        return newViews
      })
      setModified(true)
      setGenerationPanelCount(0)
      setGeneratingDescription(false)
    })
  }

  const handleSaveViewSet = async () => {
    // Save viewset

    db.collection("view_sets")
      .doc(viewSetId)
      .update(
        { ...viewSet, modified: dataServices.serverTimestamp() },
        { merge: true }
      )
      .then(() => {
        //console.log("updated viewset", { viewSetId, viewSet })
      })
      .catch((error) => {
        console.error("Error updating viewset: ", { error, viewSetId, viewSet })
      })

    for (const [index, view] of views.entries()) {
      const elementsToSave = view.elements.map((element) => {
        // remove 'id' attribute from props
        const props = element.props.map((prop) => {
          const { id, ...rest } = prop
          return rest
        })

        return { ...element, props }
      })

      const viewData = {
        account_id: accountId,
        view_set_id: viewSetId,
        name: view.name,
        description: view.description,
        direct_statement: view.direct_statement || "",
        how_to_read: view.how_to_read || "",
        actions: view.actions || [],
        text_response: view.text_response || "",
        sort_order: view.sort_order || 0,
        insights: view.insights || [],
        actions: view.actions || [],
        importance: view.importance || "",
        layout: view.layout,
        hidden_element_types: view.hidden_element_types,
        prompt_history: view.prompt_history || [],
        elements: elementsToSave,
        pivot_view: view.pivot_view,
        pivot_use_top_level: view.pivot_use_top_level,
        pivot_props: view.pivot_props,
        // Array of { name: <propname>, values: [<sorted prop values>] }, used to sort a pivot view
        sorted_prop_values: view.sorted_prop_values || [],
        suggested_props: view.suggested_props || [],
      }

      console.log("%cupdating view", "color:orange", { viewData })

      const viewId = view.id

      if (viewId) {
        await db
          .collection("views")
          .doc(viewId)
          .update(viewData, { merge: true })
      } else {
        await db.collection("views").add(viewData)
      }
    }
    setModified(false)
    enqueueSnackbar("Saved", {
      variant: "success",
    })
  }

  // Create a ref for this view in the format '
  const handleCreateAIDesignerRef = async () => {
    const refName = `[design:${viewSet.name}:${currentView.name}]`
    //console.log("refName", refName)

    enqueueSnackbar(
      `Copied ref to clipboard for use with AI Designer: ${refName}`,
      {
        variant: "info",
      }
    )
    await navigator.clipboard.writeText(refName)
  }

  const handleAddSibling = (parentItem) => {
    if (parentItem === undefined) {
      // Get parent of currentElement
      parentItem = elements.find((x) =>
        x.children.find((y) => y.id === selectedItemId)
      )
    }

    const newId = getMaxElementId(elements) + 1
    const newElement = {
      id: newId,
      name: "",
      type: lastElementTypeAdded || palette.getIndex(palette.CAPABILITY),
      description: "",
      props: [],
      children: [],
    }

    const newElements = elements.map((x) => {
      if (x.id === parentItem?.id) {
        return {
          ...x,
          children: [
            ...x.children,
            { id: newElement.id, connector: palette.ASSOCIATION_RELATIONSHIP },
          ],
        }
      } else {
        return x
      }
    })

    setElements([...newElements, newElement])
    setSelectedItemId(newId)
    setShowEditElement(true)
    setFocusToNameField()
  }

  const setFocusToNameField = () => {
    setTimeout(() => {
      const nameField = document.getElementById("name")
      if (nameField) {
        nameField.focus()
      }
    }, 100)
  }

  const handleOpenPasteAdd = () => {
    setShowPasteElementsDialog(true)
  }

  const handleSelectItem = (itemId, viewId) => {
    setSelectedViewId(viewId)
    setSelectedItemId(itemId)
  }

  const handleUpdateDescriptions = ({ jsonElements }) => {
    //console.log("updateDescriptions", jsonElements)
    const newElements = currentView.elements.map((element) => {
      const jsonElement = jsonElements.find((x) => x.id === element.id)

      if (jsonElement === undefined) {
        return element
      }

      const description = jsonElement.description

      if (description === undefined) {
        return element
      }

      return {
        ...element,
        description,
      }
    })

    //console.log("%chandleUpdateDescriptions", "color:orange", { newElements })

    setElements(newElements)
  }

  const handlePasteAdd = ({
    elementDataToAdd,
    parent,
    viewElements,
    textResponse,
  }) => {
    setShowPasteElementsDialog(false)

    console.log("%chandlePasteAdd", "color:pink", `Adding`, {
      elementDataToAdd,
      parent,
      textResponse,
    })

    const newElements = getElementsToPasteAdd({
      jsonElements: elementDataToAdd,
      parent,
      viewElements,
    })
    setElements(newElements, textResponse)

    return newElements
  }

  const handleAddSuggestedView = (suggestedView) => {
    setViewSet((curr) => {
      const newViewSet = {
        ...curr,
        view_suggestions: [suggestedView, ...curr.view_suggestions],
      }

      return newViewSet
    })

    setModified(true)

    enqueueSnackbar(`Added view suggestion - ${suggestedView.name}`, {
      variant: "success",
    })

    //setSuggestedViewToShow(suggestedView)
    dispatch(setModelEditDashboardTab(SUGGESTED_VIEWS_TAB))
  }

  const setViewSuggestions = ({
    suggestedViews,
    viewContext,
    viewQuestions,
  }) => {
    setViewSet((curr) => {
      const newViewSet = {
        ...curr,
        view_suggestions: suggestedViews,
      }
      if (viewContext) {
        newViewSet.view_context = viewContext
      }
      if (viewQuestions) {
        newViewSet.view_questions = viewQuestions
      }
      return newViewSet
    })
    // console.log("setting view suggestions and modified", {
    //   suggestedViews,
    //   viewContext,
    //   viewQuestions,
    // })
    setModified(true)
  }

  const handleDeleteViewSuggestion = (name) => {
    setViewSet((curr) => {
      const newViewSet = {
        ...curr,
        view_suggestions: curr.view_suggestions.filter((s) => s.name !== name),
      }
      return newViewSet
    })
    setModified(true)
  }

  const handleTogglePinQuestion = (question) => {
    const newViewSet = {
      ...viewSet,
      view_questions: viewSet.view_questions.map((q) => {
        if (q.question_id === question.question_id) {
          return { ...q, pinned: !q.pinned }
        }
        return q
      }),
    }

    setViewSet(newViewSet)
    setModified(true)
  }

  const handleTogglePinSuggestedView = (suggestion) => {
    const newViewSet = {
      ...viewSet,
      view_suggestions: viewSet.view_suggestions.map((s) => {
        if (s.question_id === suggestion.question_id) {
          return { ...s, pinned: !s.pinned }
        }
        return s
      }),
    }
    setViewSet(newViewSet)
    setModified(true)
  }

  const handleUpdateViewSorting = ({ diagramInfo }) => {
    setViews((curr) => {
      const newViews = curr.map((view) => {
        const info = diagramInfo.find((d) => d.diagram_id === view.id)

        if (info) {
          const newView = {
            ...view,
            //sort_order: info.order,
            //importance: info.importance,
            //actions: info.actions,
            //insights: info.insights,
          }
          // Only sort views if creating insights and actions for more than 1 view
          // The diagram_info length will either be 1 or > 1
          if (diagramInfo.length > 1) {
            if (newView.sortOrder !== info.order) {
              console.log(
                "updating sort order",
                newView.id,
                newView.sort_order,
                info.order
              )
            }
            newView.sort_order = info.order
          }
          return newView
        } else {
          return view
        }
      })

      //console.log("%cupdated views", "color:orange", newViews)
      return newViews
    })
    setModified(true)
  }

  const handleUpdateInsightsAndActions = ({ diagramInfo }) => {
    setViews((curr) => {
      const newViews = curr.map((view) => {
        const info = diagramInfo.find((d) => d.diagram_id === view.id)

        if (info) {
          const newView = {
            ...view,
            actions: info.actions,
            insights: info.insights,
            importance: info.importance,
          }
          // Only sort views if creating insights and actions for more than 1 view
          // The diagram_info length will either be 1 or > 1
          if (diagramInfo.length > 1) {
            newView.sort_order = info.order
          }
          return newView
        } else {
          return view
        }
      })

      console.log("%cupdated views", "color:orange", newViews)
      return newViews
    })
    setModified(true)
  }

  const handleUpdateSuggestedProps = (suggestedProps) => {
    console.log("update suggested props", { suggestedProps })
    setViews((curr) => {
      const newViews = curr.map((view) => {
        if (view.id === currentView.id) {
          console.log("%cupdate props for view", "color:orange", view.name)
          return {
            ...view,
            suggested_props: suggestedProps,
          }
        } else {
          return view
        }
      })
      return newViews
    })

    setModified(true)
    // causes an error if the view isn't yet saved
    // db.collection("views").doc(currentView.id).update({
    //   suggested_props: suggestedProps,
    //   modified: dataServices.serverTimestamp(),
    // })
  }

  const handleCreateViewFromSuggestion = async ({ suggestion }) => {
    const newView = createNewBlankView({
      viewName: suggestion.name,
      accountId,
      viewSetId,
      questionId: suggestion.question_id,
      question: suggestion.question,
    })
    await handleSaveNewView({ newView })

    // Create a new view based on the suggestion

    const levelSpecs = suggestion.prompts.map((prompt, index) => {
      // Get corresponding item from element_types array, so we can get the 'reason' for what that element was selected.

      const elementType = suggestion.element_types.find(
        (et) => et.type === prompt.elementType
      )

      const reasonInfo = elementType.reason
        ? `\n\nBe as thorough and specific as possible.`
        : ""

      return {
        seq: index + 1,
        // The default prompt is the generated prompt, and the reason why the element type was selected for use
        info: `${prompt.prompt}${reasonInfo}`.trim(),
        element_type_reason: elementType?.reason || "",
        type: palette.getIndex(prompt.elementType),
        level: prompt.level,
        auto_qty: true,
        qty_selection: index === 0 ? QTY_SELECTION_ANY : QTY_SELECTION_UPTO,
        max_words: 25,
        qty: index === 0 ? 10 : 4,
        props: [],
        attrs: ["name", "description"],
      }
    })

    setDefaultLevelSpecs(levelSpecs)
    handleShowCreateContent()
  }

  const handleAddChild = () => {
    // See if selected item is already a child of the parent item

    if (selectedItemId !== undefined) {
      const newId = getMaxElementId(elements) + 1
      const newElement = {
        id: newId,
        name: "",
        type: lastElementTypeAdded || palette.getIndex(palette.CAPABILITY),
        description: "",
        props: [],
        children: [],
      }

      const newElements = elements.map((x) => {
        if (x.id === selectedItemId) {
          return {
            ...x,
            children: [
              ...x.children,
              {
                id: newElement.id,
                connector: palette.ASSOCIATION_RELATIONSHIP,
              },
            ],
          }
        } else {
          return x
        }
      })
      setElements([...newElements, newElement])
      setSelectedItemId(newId)
      setShowEditElement(true)
      setFocusToNameField()
    }
  }

  // Move the selected item to the left
  const handleMoveLeft = () => {
    const newElements = handleMoveElementLeft({ elements, selectedItemId })
    if (newElements) {
      setElements(newElements)
    }
  }

  const handleMoveRight = () => {
    const newElements = handleMoveElementRight({ elements, selectedItemId })
    if (newElements) {
      setElements(newElements)
    }
  }

  const handleCreateChatPrompt = () => {
    const promptData = createChatPromptData({
      currentView,
      selectedItemId,
      hiddenProps,
    })

    setChatPromptData(promptData)
    setShowChatPrompt(true)
  }

  const propsWithSeq = (props) => {
    // Get max id in props
    let maxId =
      props.reduce((acc, prop) => {
        if (prop.id > acc) {
          return prop.id
        }
        return acc
      }, 0) + 1

    const newProps = props.map((prop) => {
      const id = prop.id || maxId++
      return { ...prop, id, type: "text" }
    })

    return newProps
  }

  /**
   * Add properties to the current view, to elements of the specified type.
   *
   * @param {*} override | If true, override any existing property by that name, otherwise only add if it doesn't already exists
   */
  const handleAddProps = async ({
    elementProps,
    elementType,
    override,
    gptModel = GPT_4o_LATEST,
    selectedFile,
    accountId,
    viewSetId,
  }) => {
    console.log("add props", elementProps, selectedFile)

    setShowAddProperties(false)

    const elementsToAddProps = selectedItemId
      ? getCurrentElementAndChildren({
          elements: currentView.elements,
          selectedItemId,
        })
      : currentView.elements

    let fileStoragePath
    let createFileResult

    if (selectedFile) {
      const folderPath = `accounts/${accountId}/view_sets/${viewSetId}`
      fileStoragePath = `${folderPath}/${selectedFile.name}`
      createFileResult = await createOpenAIFile({
        fileStoragePath: fileStoragePath,
      })
      console.log("createFileResult", createFileResult)
    }

    //console.log("elements to add props", { elementsToAddProps })

    setGeneratingContentMessage("Generating properties...")
    setGenerationPanelCount((curr) => curr + 1)

    const propName = elementProps[0].name

    const elements = elementsToAddProps.filter(
      (element) => element.type === elementType
    )
    createElementProperties({
      elements,
      elementProps,
      elementType,
      override,
      gptModel,
    })
      .then((propGenerationResult) => {
        if (propGenerationResult.error) {
          // Could be a 429 throttling response or similar
          enqueueSnackbar(propGenerationResult.error, { variant: "error" })
          return
        }

        console.log("%cgenerated props", "color:pink", {
          elementProps,
          propGenerationResult,
          elementType,
        })
        // Map the properties onto the elements

        const newElements = currentView.elements.map((element) => {
          // Only apply properties to elements of the specified type
          if (element.type !== elementType) {
            return element
          }

          // item.name is the >element< name, and item.value is the >property< value.
          // This is the most efficient way to organise the response from OpenAI
          const propResult = propGenerationResult.find(
            (item) => item.name === element.name
          )

          if (propResult?.value) {
            //console.log("propResult", propResult)
            // add props according to override rules

            if (override) {
              // Remove any existing property with the same name
              const newProps = element.props.filter((x) => x.name !== propName)

              // Add the new property

              newProps.push({
                name: propName,
                value: propResult.value,
                reason: propResult.reason || "",
              })

              return { ...element, props: propsWithSeq(newProps) }
            } else {
              // Only add the property if it doesn't already exist
              const existingProp = element.props.find(
                (x) => x.name === propName
              )
              if (!existingProp && propResult?.value) {
                const newProps = element.props

                newProps.push({
                  name: propName,
                  value: propResult.value,
                  reason: propResult.reason || "",
                })

                return { ...element, props: propsWithSeq(newProps) }
              }
            }
          }

          return element
        })

        //console.log("newElements", newElements)

        setElements(newElements)
      })
      .then(() => {
        if (fileStoragePath) {
          return deleteOpenAIFile({ fileId: createFileResult.id })
        }
      })
      .catch((err) => {
        console.log("Error getting element props", { err })
        enqueueSnackbar(`Error obtaining element properties ${err.message}`, {
          variant: "error",
        })
      })
      .then(() => {
        setGenerationPanelCount((curr) => curr - 1)
      })
  }

  const handleShowCreateContent = () => {
    console.log("user has ai role?", aiRole)
    // See if user has role to perform this function
    if (!IS_AIM_TRIAL && !aiRole) {
      setShowBillingDialog(true)
      return
    }
    setGenerationPanelCount(0)
    setShowCreateContent(true)
  }

  const handleShowCreateProcess = () => {
    console.log("user has ai role?", aiRole)
    // See if user has role to perform this function
    if (!IS_AIM_TRIAL && !aiRole) {
      setShowBillingDialog(true)
      return
    }
    setGenerationPanelCount(0)
    setShowCreateProcess(true)
  }

  const handleShowAddTags = () => {
    if (!IS_AIM_TRIAL && !aiRole) {
      setShowBillingDialog(true)
    } else {
      setShowAddProperties(true)
    }
  }

  const handleAddDescriptions = () => {
    if (!IS_AIM_TRIAL && !aiRole) {
      setShowBillingDialog(true)
      return
    }
    console.log("create descriptions")

    const currentElementAndChildren = getCurrentElementAndChildren({
      elements,
      selectedItemId,
    }).filter((element) => !element.description)

    if (currentElementAndChildren.length === 0) {
      enqueueSnackbar("All elements already have descriptions", {
        variant: "info",
      })
      return
    }

    setGenerationPanelCount((curr) => curr + 1)
    setGeneratingContentMessage("Generating descriptions...")

    createElementDescriptions({
      elements: currentElementAndChildren,
      handleUpdateDescriptions,
      setWaitingElementIds,
      scope: viewSet.scope,
      assistants,
      roles,
    }).then(() => {
      setGenerationPanelCount((curr) => curr - 1)
    })
    //}
  }

  const getCurrentElementAndChildren = ({ elements, selectedItemId }) => {
    if (!selectedItemId) {
      return currentView.elements
    }

    const currentElement = elements.find((x) => x.id === selectedItemId)
    const currentElementChildren = currentElement.children.map((x) =>
      getCurrentElementAndChildren({ elements, selectedItemId: x.id })
    )
    return [currentElement, ...currentElementChildren].flat()
  }

  const handleDeleteElement = () => {
    // Make sure selected item is a leaf node

    if (selectedItemId) {
      const element = elements.find((x) => x.id === selectedItemId)
      if (element.children.length === 0) {
        const newElements = elements.filter((x) => x.id !== selectedItemId)

        // Remove the element from the children of its parent

        const newElements2 = newElements.map((x) => {
          return {
            ...x,
            children: x.children.filter((y) => y.id !== selectedItemId),
          }
        })

        setElements(newElements2)
        setSelectedItemId(undefined)
      }
    }
  }

  const setPropColor = async (name, value, color) => {
    const shader = shaders.find((x) => x.name === name) || {
      account_id: accountId,
      name: name,
      property: name.toLowerCase(),
      config: { colors: [] },
      created: dataServices.serverTimestamp(),
      modified: dataServices.serverTimestamp(),
    }

    const newColors = shader.config.colors.filter(
      (x) => x.value?.toString() !== value?.toString()
    )
    if (color !== null) {
      newColors.push({ value, color })
    }

    const newShaders = shaders.filter((x) => x.name !== name)
    const newShader = {
      ...shader,
      config: {
        colors: newColors,
      },
      modified: dataServices.serverTimestamp(),
    }

    if (newShader.hasOwnProperty("id")) {
      await db
        .collection("shaders")
        .doc(newShader.id)
        .update(
          {
            modified: dataServices.serverTimestamp(),
            config: { colors: newColors },
          },
          { merge: true }
        )
    } else {
      const docRef = await db.collection("shaders").add(newShader)
      newShader.id = docRef.id
    }
    newShaders.push(newShader)

    setShaders(newShaders)
  }

  const handleToggleProp = (item, prop) => {
    setViews(handleToggleProperty(item, prop, currentView, views))
  }

  useEffect(() => {
    if (selectedItemId) {
      const currentItem = elements.find((x) => x.id === selectedItemId)
      console.log("finding duplicate items", { selectedItemId, currentItem })

      const duplicates = getDuplicateElementsThisView(
        currentView,
        currentItem,
        views
      )
      console.log("duplicates", duplicates)

      setDuplicateItems(duplicates)
    } else {
      setDuplicateItems([])
    }
  }, [selectedItemId, elements, views, currentView])

  const setPropertyType = (index, name, value) => {
    // Find the first property with the same name in elements

    const firstElementWithProp = elements.find((element) => {
      const existingProp = element.props.find((p) => p.name === value)
      if (existingProp) {
        return existingProp
      }
      return null
    })

    const propWithSameName =
      firstElementWithProp &&
      firstElementWithProp.props.find((x) => x.name === value)

    // See if property types are different

    const currentProp = currentElement.props.find((p) => p.name === value)

    if (
      currentProp &&
      propWithSameName &&
      currentProp.type !== propWithSameName.type
    ) {
      const newProps = currentElement.props.map((p, i) => {
        if (i === index) {
          return { ...p, type: propWithSameName.type }
        } else {
          return p
        }
      })
      const newViews = views.map((view) => {
        const newElements = view.elements.map((element) => {
          const requiresUpdate =
            element.id === currentElement.id && view.id === currentView.id
          return requiresUpdate ? { ...element, props: newProps } : element
        })
        return { ...view, elements: newElements }
      })
      setViews(newViews)
      setModified(true)
    }
  }

  const handleUpdateElement = ({ editedElement, originalElement }) => {
    const duplicatesInCurrentView = getDuplicateElementsByView(
      currentView,
      originalElement,
      views
    )

    console.log("saving", {
      editedElement,
      originalElement,
      duplicatesInCurrentView,
    })

    // Update props, type, description in duplicate

    const updatedDuplicates = duplicatesInCurrentView.map((duplicate) => {
      return {
        ...duplicate,
        name: editedElement.name,
        props: editedElement.props,
        type: editedElement.type,
        description: editedElement.description,
      }
    })

    const allElementsToUpdate = [
      { ...originalElement, viewId: currentView.id },
      ...updatedDuplicates,
    ]

    const newViews = views.map((view) => {
      const newElements = view.elements.map((element) => {
        // We want to match on id, type, and view, since name and description can change
        const requiresUpdate = allElementsToUpdate.find(
          (x) =>
            (x.id === element.id ||
              x.name.toLowerCase() === element.name.toLowerCase()) &&
            x.type === element.type &&
            x.viewId === view.id
        )

        return requiresUpdate ? { ...editedElement, id: element.id } : element
      })

      return { ...view, elements: newElements }
    })

    setViews(newViews)
    setModified(true)

    setShowEditElement(false)
    setSelectedItemId(undefined)
  }

  const handleAddViewFromElement = (element) => {
    const viewName = `${currentElement.name} | ${
      palette.getElementTypeByIndex(currentElement.type).name
    }`

    const newView = createNewBlankView({ viewName, accountId, viewSetId })
    newView.elements = [
      {
        ...element,
        children: [],
      },
    ]

    handleSaveNewView({ newView })
  }

  const createNewBlankView = ({
    viewName,
    accountId,
    viewSetId,
    questionId,
    question,
  }) => {
    const newView = {
      account_id: accountId,
      view_set_id: viewSetId,
      name: viewName,
      description: "",
      direct_statement: "",
      how_to_read: "",
      actions: [],
      sort_order: 0,
      insights: [],
      importance: "",
      elements: [],
      layout: [],
      pivot_view: "",
      pivot_use_top_level: false,
      pivot_props: [],
      hidden_element_types: [],
      created: dataServices.serverTimestamp(),
      modified: dataServices.serverTimestamp(),
    }

    if (questionId) {
      // What question this view is based on?
      newView.question_id = questionId
    }

    if (question) {
      newView.question = question
    }

    return newView
  }

  const handleAddView = async () => {
    // Get a unique view name, starting with 'new view'

    const newView = createNewBlankView({ viewName: "", accountId, viewSetId })
    await handleSaveNewView({ newView })
    setViewDialogOpen(true)
  }

  const handleSaveNewView = async ({ newView }) => {
    const newViewDoc = await db.collection("views").add(newView)

    const newViewSaved = { id: newViewDoc.id, ...newView }
    setViews([...views, newViewSaved])
    setModified(false)
    setSelectedViewId(newViewDoc.id)
    return newViewSaved
  }

  const onMouseDown = (item) => {
    setSourceItem(item)
  }

  const onMouseUp = (targetItem) => {
    if (sourceItem === null) {
      return
    }

    if (sourceItem.id === targetItem?.id) {
      return
    }

    const sourceParentElement = elements.find((x) =>
      x.children.find((y) => y.id === sourceItem.id)
    )

    const sourceParentElementWithModifiedChildren = sourceParentElement && {
      ...sourceParentElement,
      children: sourceParentElement.children.filter(
        (x) => x.id !== sourceItem.id
      ),
    }

    const targetElement = elements.find((x) => x.id === targetItem?.id)

    if (targetItem) {
      // Move current element to be under the target element

      // Check if target element is a child of the source parent element

      if (sourceParentElement && targetElement) {
        if (sourceParentElement.id === targetElement.id) {
          return
        }
      }

      const targetElementWithModifiedChildren = {
        ...targetElement,
        children: [
          ...targetElement.children,
          { id: sourceItem.id, connector: palette.ASSOCIATION_RELATIONSHIP },
        ],
      }

      const newElements = elements.map((x) => {
        if (x.id === sourceParentElementWithModifiedChildren?.id) {
          return sourceParentElementWithModifiedChildren
        } else if (x.id === targetElementWithModifiedChildren.id) {
          return targetElementWithModifiedChildren
        } else {
          return x
        }
      })

      setSourceItem(null)

      setElements(newElements)
    } else {
      const newElements = elements.map((x) => {
        if (x.id === sourceParentElementWithModifiedChildren?.id) {
          return sourceParentElementWithModifiedChildren
        } else {
          return x
        }
      })

      setSourceItem(null)

      setElements(newElements)
    }
  }

  const handleToggleElementTypeVisible = (type) => {
    const newView = {
      ...currentView,
      hidden_element_types: currentView.hidden_element_types.includes(type)
        ? currentView.hidden_element_types.filter((x) => x !== type)
        : [...currentView.hidden_element_types, type],
      modified: dataServices.serverTimestamp(),
    }

    delete newView.id

    db.collection("views")
      .doc(currentView.id)
      .set(newView)
      .then(() => {
        setViews(
          views.map((x) =>
            x.id === currentView.id ? { id: currentView.id, ...newView } : x
          )
        )
        setModified(true)
      })
  }

  const handleDeleteView = () => {
    if (selectedViewId) {
      setSelectedViewId("")
      db.collection("views")
        .doc(selectedViewId)
        .delete()
        .then(() => {
          setViews(views.filter((x) => x.id !== selectedViewId))
        })
    }
  }

  const handleSelectFileImport = () => {
    setShowImportDialog(true)
  }

  const handleDownloadAsXlsx = () => {
    handleDownloadElementsAsXlsx(elements)
  }

  // Download views to an OpenExchange file
  const handleDownloadAsOpenExchange = () => {
    // Convert the 'description' property to an array, to match
    // the format of this property when exporting to openexchange from an uploaded model

    const descAsArray = views.map((view) => {
      return {
        ...view,
        elements: view.elements.map((element) => {
          return {
            ...element,
            description: element.description ? [element.description] : [],
          }
        }),
      }
    })
    const fileXml = createOpenExchangeModel(descAsArray, coordsByViewId)

    const blob = new Blob([fileXml], {
      type: "text/plain;charset=utf-8",
    })

    saveAs(blob, "model.xml")
  }

  const toggleShowDiagramAsImage = (show) => {
    setShowRender(show)
    setSelectedItemId(undefined)
    if (show) {
      setShowLayoutOptions(false)
    } else {
      setAllCoords([])
    }
    triggerRedraw()
    setTimeout(() => {
      setShowEditable(!show)
    }, 500)
  }

  const handleImportData = (
    workbook,
    sheetName,
    hierarchyCols,
    hierarchyTypes
  ) => {
    // Look through the workbook and find the index on the column headings Name, Type, Parent, and Description

    const worksheetInfo = getWorksheetInfo(workbook, sheetName)

    if (hierarchyCols.length > 0) {
      const newElements = handleImportDataWithHierarchy({
        elements,
        workbook,
        sheetName,
        hierarchyCols,
        hierarchyTypes,
      })
      setElements(newElements)
      return
    }

    const sheet = workbook.Sheets[sheetName]

    // Convert workbook into rows
    const rows = getWorksheetRows(sheet)

    // Check that parent values exist as elements
    const parentNames = _.uniq(
      rows.map((row) => row[worksheetInfo.parentIndex]).filter((x) => x)
    )

    const newElements = handleCalcNewViewElementsFromImport({
      workbook,
      sheetName,
      currentView,
      views,
    })

    console.log("%cnew elements", "color:lightgreen", newElements)

    const missingParentNames = parentNames.filter(
      (name) => !newElements.find((e) => e.name === name)
    )

    if (missingParentNames.length > 0) {
      enqueueSnackbar(
        `Missing parent element(s): ${missingParentNames.join(", ")}`,
        {
          variant: "error",
        }
      )
      return
    }

    const elementWithError = newElements.find(
      (element) => element.msgs.length > 0
    )
    if (elementWithError) {
      enqueueSnackbar(elementWithError.msgs[0].label, {
        variant: elementWithError.msgs[0].severity,
      })
      return
    }

    newElements.forEach((element) => {
      delete element.msgs
    })

    const newElementsWithParentIds = newElements.map((element) => {
      const parentName = element.parentName
      if (parentName) {
        const parent = newElements.find((e) => e.name === parentName)
        if (parent) {
          element.parentId = parent.id
        }
      }

      return element
    })

    // Now update the children property of each element

    const newElementsWithChildIds = newElementsWithParentIds.map((element) => {
      const childIds = newElementsWithParentIds
        .filter((e) => e.parentId === element.id)
        .map((element) => ({
          id: element.id,
          connector: palette.ASSOCIATION_RELATIONSHIP,
        }))

      return {
        ...element,
        children: childIds,
      }
    })

    // Now find all duplicate elements in other views and update their properties to match this view

    const otherElementsToUpdate = newElementsWithChildIds.flatMap((element) => {
      const duplicateElements = getDuplicateElementsByView(
        currentView,
        {
          name: element.name,
          type: element.type,
          id: element.id,
        },
        views
      )
      return duplicateElements.map((duplicateElement) => ({
        ...duplicateElement,
        props: element.props,
      }))
    })

    // Delete fields that we don't need to store
    newElementsWithChildIds.forEach((element) => {
      delete element.parentName
      delete element.row
    })

    const newViews = views.map((view) => {
      const newView = {
        ...view,
        elements: view.elements.map((element) => {
          const otherElement = otherElementsToUpdate.find(
            (e) =>
              e.name.toLowerCase() === element.name.toLowerCase() &&
              e.type === element.type
          )
          return otherElement
            ? { ...element, props: otherElement.props }
            : element
        }),
      }

      if (view.id === selectedViewId) {
        // Make sure view contains all the new elements
        newView.elements = [...newElementsWithChildIds]
      }

      return newView
    })

    setViews(newViews)
    setModified(true)

    enqueueSnackbar(`Imported ${newElementsWithChildIds.length} elements`, {
      variant: "success",
    })
  }

  const handleRename = (fromName, toName) => {
    setViews((curr) => renameProperty(curr, fromName, toName))
    setModified(true)
  }

  const handleDeleteViewSet = () => {
    // Delete the view set and all documents from the 'views' collection with the same view_set_id

    db.collection("view_sets")
      .doc(viewSetId)
      .delete()
      .then(() => {
        db.collection("views")
          .where("view_set_id", "==", viewSetId)
          .where("account_id", "==", accountId)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              doc.ref.delete()
            })
          })
      })
      .then(() => {
        history.push("/viewsets")
      })
  }

  const handleConfigureViewSet = (viewSet) => {
    if (viewSet) {
      if (viewSet.action) {
        console.log("viewSet", { viewSet })
        switch (viewSet.action) {
          case "update":
            if (viewSet) {
              const newValues = {
                ...viewSet.values,
                modified: dataServices.serverTimestamp(),
              }
              db.collection("view_sets")
                .doc(viewSetId)
                .update(newValues)
                .then(() => {
                  setViewSet(newValues)
                  setTitle(newValues.name)
                })
            }
            break

          case "delete":
            setShowDeleteViewSet(true)
            break

          default:
            break
        }
      }
    }
    setShowConfigureViewSet(false)
  }

  const handleSelectView = ({ viewId }) => {
    setSelectedItemId(undefined)
    setSelectedViewId(viewId)
    if (showRender) {
      toggleShowDiagramAsImage(false)
    }
  }

  const handleMoveView = ({ viewId, targetViewSetId }) => {
    setShowMoveViewDialog(false)

    // Move the view and any pivot views based on it to the target view set

    const viewsToMove = views
      .filter((view) => view.id === viewId || view.pivot_view === viewId)
      .map((view) => view.id)

    viewsToMove.forEach(async (viewIdToMove) => {
      await db.collection("views").doc(viewIdToMove).update({
        view_set_id: targetViewSetId,
        modified: dataServices.serverTimestamp(),
      })
    })

    setViews((curr) => curr.filter((view) => viewsToMove.includes(view.id)))
  }

  return (
    <>
      <YesNo config={deleteViewYesNoConfig} />
      <YesNo config={deleteElementYesNoConfig} />

      <TooltipHover />

      {showBillingDialog && (
        <GetAIMAIDialog
          open={showBillingDialog}
          onClose={() => setShowBillingDialog(false)}
        />
      )}

      {showMoveViewDialog && (
        <ModelEditMoveViewDialog
          open={showMoveViewDialog}
          accountId={accountId}
          onClose={handleMoveView}
          setOpen={setShowMoveViewDialog}
          viewId={selectedViewId}
          currentViewSetId={viewSetId}
        />
      )}
      {showChatPrompt && (
        <CreatePromptDialog
          open={showChatPrompt}
          onClose={() => setShowChatPrompt(false)}
          promptData={chatPromptData}
        />
      )}
      {showConfigureViewSet && (
        <ViewSetDialog
          viewSet={viewSet}
          open={showConfigureViewSet}
          onClose={handleConfigureViewSet}
        />
      )}
      {showDeleteViewSet && (
        <DeleteDialog
          open={showDeleteViewSet}
          viewSetName={viewSet.name}
          onClose={({ shouldDelete }) => {
            console.log("onClose", shouldDelete)
            setShowDeleteViewSet(false)
            if (shouldDelete) {
              handleDeleteViewSet(viewSetId)
            }
          }}
        />
      )}
      {showCreateProcess && (
        <ModelEditCreateProcessDialog
          open={showCreateProcess}
          onClose={() => setShowCreateProcess(false)}
          handleCreateProcess={handleCreateProcess}
        />
      )}

      {showCreateContent && (
        <ModelEditCreateContentDialog
          //transition={Transition}
          open={showCreateContent}
          onClose={() => {
            setDefaultLevelSpecs(undefined)
            setShowCreateContent(false)
          }}
          accountId={accountId}
          selectedViewId={selectedViewId}
          currentElement={currentElement}
          currentView={currentView}
          viewSet={viewSet}
          handlePasteAdd={handlePasteAdd}
          setTextResponse={setTextResponse}
          setWaitingElementIds={setWaitingElementIds}
          generationPanelCount={generationPanelCount}
          setGenerationPanelCount={setGenerationPanelCount}
          // Stop the generation process
          stopRequested={stopRequested}
          // Update message about what is being generated
          setGeneratingContentMessage={setGeneratingContentMessage}
          handleUpdatePromptHistory={handleUpdatePromptHistory}
          views={views}
          roles={roles}
          defaultLevelSpecs={defaultLevelSpecs}
          assistants={assistants}
        />
      )}

      {showAddProperties && (
        <ModelEditAddPropertiesDialog
          open={showAddProperties}
          onClose={() => setShowAddProperties(false)}
          handleAddProps={({
            elementProps,
            elementType,
            override,
            gptModel,
            selectedFile,
          }) =>
            handleAddProps({
              elementProps,
              elementType,
              override,
              gptModel,
              selectedFile,
            })
          }
          handleUpdateSuggestedProps={handleUpdateSuggestedProps}
          accountId={accountId}
          viewSetId={viewSetId}
          view={currentView}
          assistants={assistants}
          scope={viewSet.scope}
          overview={viewSet.overview}
          purpose={viewSet.purpose}
          roles={roles}
          files={viewSet.docs}
        />
      )}

      {showCreateDiagram && (
        <ModelEditCreateDiagramDialog
          open={showCreateDiagram}
          onClose={() => setShowCreateDiagram(false)}
        />
      )}
      {showChat && (
        <ChatDialog
          open={showChat}
          onClose={() => setShowChat(false)}
          messages={messages}
          setMessages={setMessages}
        />
      )}
      {showRenamePropertyDialog && (
        <ModelEditRenamePropertyDialog
          open={showRenamePropertyDialog}
          setOpen={setShowRenamePropertyDialog}
          uniquePropKeys={uniquePropKeys}
          handleRename={handleRename}
        />
      )}
      <ModelEditImportFile
        open={showImportDialog}
        onClose={() => setShowImportDialog(false)}
        handleImportData={handleImportData}
      />
      {showPasteElementsDialog && (
        <ModelEditPasteAdd
          open={showPasteElementsDialog}
          currentElement={currentElement}
          currentView={currentView}
          onClose={() => setShowPasteElementsDialog(false)}
          handlePasteAdd={handlePasteAdd}
        />
      )}
      <Menu
        anchorEl={showElementTypeAnchorEl}
        open={Boolean(showElementTypeAnchorEl)}
        onClose={() => setShowElementTypeAnchorEl(null)}
      >
        {elementTypesForCurrentView &&
          elementTypesForCurrentView.map((x) => (
            <MenuItem
              key={`${x.name}-${x.type}`}
              onClick={(e) => {
                handleToggleElementTypeVisible(x.type)

                if (!e.ctrlKey) {
                  setShowElementTypeAnchorEl(null)
                }
              }}
              onClose={() => setShowElementTypeAnchorEl(null)}
            >
              <ListItemIcon>
                {currentView.hidden_element_types.includes(x.type) ? (
                  <HideImageIcon />
                ) : (
                  <ImageIcon />
                )}
              </ListItemIcon>
              <ListItemText primary={x.name} />
            </MenuItem>
          ))}
      </Menu>
      <Menu
        anchorEl={showPropsAnchorEl}
        open={Boolean(showPropsAnchorEl)}
        onClose={() => setShowPropsAnchorEl(null)}
      >
        {uniquePropKeys &&
          uniquePropKeys.map((propKey) => (
            <MenuItem
              key={propKey}
              onClick={(e) => {
                // Toggle whether so.name is in shown in hiddenProps
                const newHiddenProps = hiddenProps.includes(
                  propKey.toLowerCase()
                )
                  ? hiddenProps.filter(
                      (x) => x.toLowerCase() !== propKey.toLowerCase()
                    )
                  : [...hiddenProps, propKey.toLowerCase()]

                setHiddenProps(newHiddenProps)
                if (!e.ctrlKey) {
                  setShowPropsAnchorEl(null)
                }
              }}
              onClose={() => setShowPropsAnchorEl(null)}
            >
              <ListItemIcon>
                {hiddenProps.includes(propKey.toLowerCase()) ? (
                  <VisibilityOffIcon />
                ) : (
                  <VisibilityIcon />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    sx={{
                      color:
                        duplicatePropertyNames.includes(
                          propKey.toLowerCase()
                        ) && "red",
                    }}
                  >
                    {propKey}
                  </Typography>
                }
              />
            </MenuItem>
          ))}
      </Menu>
      {showShaderDialog && (
        <ShaderDialog
          open={showShaderDialog}
          setOpen={setShowShaderDialog}
          shaders={shaders}
          setShaders={setShaders}
          propNameToEdit={propNameToEdit}
          propOptions={propOptions}
          setPropColor={setPropColor}
        />
      )}
      {viewDialogOpen && (
        <ModelEditRenameViewDialog
          open={viewDialogOpen}
          onClose={() => setViewDialogOpen(false)}
          currentView={currentView}
          handleUpdateViewData={handleUpdateViewData}
          views={views}
          handleDeleteView={handlePromptDeleteView}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "15px",
        }}
      >
        <Box sx={{ marginRight: "20px" }}>
          {viewOptions && (
            <Controls.Select
              name="view"
              label={
                <Typography variant="body2" component={"span"}>
                  View
                </Typography>
              }
              value={selectedViewId}
              options={viewOptions.map((o) => ({ id: o.id, title: o.label }))}
              onChange={(e) => handleSelectView({ viewId: e.target.value })}
            />
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Tooltip title="Configure view">
            <span>
              <IconButton
                disabled={selectedViewId === "" || selectedViewId === null}
                onClick={() => setViewDialogOpen(true)}
              >
                <MoreVertIcon />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title="Add view">
            <span>
              <IconButton onClick={handleAddView} disabled={!canAddView}>
                <AddIcon />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip title="Edit Objective, and Context used for view creation">
            <span>
              <ConfigureViewSetButton
                setShowConfigureViewSet={setShowConfigureViewSet}
              />
            </span>
          </Tooltip>

          <Tooltip title="Show dashboard">
            <IconButton
              onClick={() => {
                setSelectedViewId("")
                toggleShowDiagramAsImage(false)
              }}
            >
              <DashboardIcon />
            </IconButton>
          </Tooltip>

          {currentView && (
            <Box sx={{ marginLeft: "auto" }}>
              <Tooltip title="Show selected view as an image">
                <span>
                  <FormControlLabel
                    label={
                      <Typography variant="caption">
                        Display as image
                      </Typography>
                    }
                    labelPlacement="end"
                    control={
                      <Switch
                        checked={showRender}
                        onChange={(e) => {
                          toggleShowDiagramAsImage(e.target.checked)
                        }}
                        disabled={currentView === undefined}
                      />
                    }
                  />
                </span>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "15px",
          gap: "5px",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <CustomIconButton
          tooltipText="Save all views in design"
          disabled={!isModified}
          onClick={handleSaveViewSet}
          icon={<SaveIcon />}
        />

        {currentView && (
          <>
            <CustomIconButton
              tooltipText="Download view in Excel or OpenExchange format"
              disabled={currentView === undefined}
              onClick={(e) => setDownloadAnchorEl(e.currentTarget)}
              icon={<DownloadIcon />}
            />

            {downloadAnchorEl !== null && (
              <Menu
                anchorEl={downloadAnchorEl}
                open={downloadAnchorEl !== null}
                onClose={() => setDownloadAnchorEl(null)}
              >
                <MenuItem
                  value="openexchange"
                  onClick={() => {
                    setDownloadAnchorEl(null)
                    handleDownloadAsOpenExchange()
                  }}
                >
                  <ListItemText primary="Open Exchange" />
                </MenuItem>
                <MenuItem
                  value="xlsx"
                  onClick={() => {
                    setDownloadAnchorEl(null)
                    handleDownloadAsXlsx()
                  }}
                >
                  <ListItemText primary=".xlsx" />
                </MenuItem>
              </Menu>
            )}

            <CustomIconButton
              tooltipText="Import views in XLSX format"
              onClick={handleSelectFileImport}
              icon={<FileUploadIcon />}
              disabled={
                currentView === undefined || currentView.pivot_view !== ""
              }
            />

            <Tooltip title="Move view to different design">
              <span>
                <IconButton
                  onClick={() => {
                    if (isModified) {
                      enqueueSnackbar(
                        "Please save your changes before moving this view",
                        {
                          variant: "info",
                        }
                      )
                    }
                    setShowMoveViewDialog(true)
                  }}
                  disabled={
                    selectedViewId === "" ||
                    selectedViewId === null ||
                    currentView?.pivot_view !== ""
                  }
                >
                  <DriveFileMoveIcon />
                </IconButton>
              </span>
            </Tooltip>

            <AddSiblingIconButton
              item={undefined}
              handleAddSibling={handleAddSibling}
              disabled={
                currentView === undefined || currentView?.pivot_view !== ""
              }
            />

            <AddChildIconButton
              handleAddChild={handleAddChild}
              disabled={
                selectedItemId === undefined || currentView?.pivot_view !== ""
              }
            />

            <DeleteIconButton
              handlePromptDeleteElement={handlePromptDeleteElement}
              disabled={
                selectedItemId === undefined || currentView?.pivot_view !== ""
              }
            />

            <CustomIconButton
              tooltipText="Move selected item left"
              onClick={handleMoveLeft}
              icon={<ArrowBackIcon />}
              disabled={
                selectedItemId === undefined || currentView?.pivot_view !== ""
              }
            />

            <CustomIconButton
              tooltipText="Move select item right"
              onClick={handleMoveRight}
              icon={<ArrowForwardIcon />}
              disabled={
                selectedItemId === undefined || currentView?.pivot_view !== ""
              }
            />

            <CustomIconButton
              tooltipText="Edit selected element"
              onClick={() => setShowEditElement(true)}
              icon={<EditIcon />}
              disabled={!isEditable}
            />

            <PasteContentIconButton
              handlePasteAdd={handleOpenPasteAdd}
              disabled={
                currentView === undefined || currentView?.pivot_view !== ""
              }
              aiRelatedIcon={aiRelatedIcon}
            />

            <Tooltip title="Create a ChatGPT prompt">
              <span>
                <IconButton
                  onClick={handleCreateChatPrompt}
                  disabled={elementTypesForCurrentView.length === 0}
                >
                  <NotesIcon
                    sx={
                      !(elementTypesForCurrentView.length === 0)
                        ? aiRelatedIcon
                        : {}
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title="Create an AI Designer reference">
              <span>
                <IconButton
                  onClick={handleCreateAIDesignerRef}
                  disabled={elementTypesForCurrentView.length === 0}
                >
                  <LabelIcon
                    sx={
                      !(elementTypesForCurrentView.length === 0)
                        ? aiRelatedIcon
                        : {}
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title="Create content">
              <span>
                <IconButton
                  onClick={handleShowCreateContent}
                  disabled={currentView === undefined}
                >
                  <AutoAwesomeIcon sx={aiIconProps} />
                </IconButton>
              </span>
            </Tooltip>

            {/* TEMPORARY - REVIEW AND RE-ENABLE THIS FUNCTIONALITY */}
            {/* <Tooltip title="Create process">
              <span>
                <IconButton
                  onClick={handleShowCreateProcess}
                  disabled={currentView === undefined}
                >
                  <TrendingFlatIcon sx={aiIconProps} />
                </IconButton>
              </span>
            </Tooltip> */}

            {/* <Tooltip title="Create Embedding">
                    <span>
                        <IconButton
                            onClick={handleCreateEmbedding}
                            disabled={currentView === undefined}
                        >
                            <DataObjectIcon sx={aiIconProps} />
                        </IconButton>
                    </span>
                </Tooltip> */}

            <Tooltip title="Add properties">
              <span>
                <IconButton
                  onClick={handleShowAddTags}
                  disabled={
                    currentView === undefined ||
                    currentView.elements.length === 0
                  }
                >
                  <SellIcon
                    sx={
                      currentView === undefined ||
                      currentView.elements.length === 0
                        ? aiIconDisabledProps
                        : aiIconProps
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title="Add description">
              <span>
                <IconButton
                  onClick={handleAddDescriptions}
                  disabled={
                    currentView === undefined ||
                    currentView.elements.length === 0
                  }
                >
                  <PostAddIcon
                    sx={
                      currentView === undefined ||
                      currentView.elements.length === 0
                        ? aiIconDisabledProps
                        : aiIconProps
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip
              title={
                currentElement
                  ? `Create new '${currentElement?.name}' view${
                      canAddView ? "" : " - max views reached"
                    }`
                  : "Create new view for selected element"
              }
            >
              <span>
                <IconButton
                  onClick={() => handleAddViewFromElement(currentElement)}
                  disabled={
                    currentElement === undefined || canAddView === false
                  }
                >
                  <AddPhotoAlternateIcon />
                </IconButton>
              </span>
            </Tooltip>

            <CustomIconButton
              tooltipText="Rename property"
              onClick={() => setShowRenamePropertyDialog(true)}
              icon={<TitleIcon />}
            />

            <Tooltip title="Toggle displaying properties">
              <span>
                <FormControlLabel
                  labelPlacement="top"
                  label={
                    <Typography variant="caption">Show Properties</Typography>
                  }
                  control={
                    <Switch
                      checked={showProps}
                      onChange={() => setShowProps((curr) => !curr)}
                      disabled={showRender}
                    />
                  }
                />
              </span>
            </Tooltip>

            <Tooltip title="Shade properties under each element">
              <span>
                <FormControlLabel
                  labelPlacement="top"
                  label={
                    <Typography variant="caption">Shade Properties</Typography>
                  }
                  control={
                    <Switch
                      checked={applyShadersToChips}
                      onChange={() => setApplyShadersToChips((curr) => !curr)}
                      disabled={showRender}
                    />
                  }
                />
              </span>
            </Tooltip>

            <Tooltip title="Filter which properties to display. Hold ctrl to multi-select">
              <span>
                <FormControlLabel
                  labelPlacement="top"
                  label={
                    <Typography variant="caption">Property Types</Typography>
                  }
                  control={
                    <IconButton
                      onClick={(event) =>
                        setShowPropsAnchorEl(event.currentTarget)
                      }
                      disabled={shaderOptions.length === 0}
                    >
                      <EditAttributesIcon />
                    </IconButton>
                  }
                />
              </span>
            </Tooltip>

            <Tooltip title="Select how to dislay descriptions for elements">
              <span>
                <FormControlLabel
                  labelPlacement="top"
                  label={<Typography variant="caption">Description</Typography>}
                  control={
                    <IconButton
                      onClick={(event) =>
                        setShowDocoAnchorEl(event.currentTarget)
                      }
                      disabled={showRender}
                    >
                      <MenuBookIcon />
                    </IconButton>
                  }
                />
              </span>
            </Tooltip>

            {showDocoAnchorEl !== null && (
              <ShowDocsMenu
                showDocoAnchorEl={showDocoAnchorEl}
                setShowDocoAnchorEl={setShowDocoAnchorEl}
                setShowDocs={setShowDocs}
              />
            )}

            <Tooltip title="Show element usage and relations into other views. Hold ctrl to multi-select">
              <span>
                <FormControlLabel
                  labelPlacement="top"
                  label={<Typography variant="caption">Show Usage</Typography>}
                  control={
                    <Switch
                      checked={showUsage}
                      onChange={() => setShowUsage((curr) => !curr)}
                      disabled={showRender}
                    />
                  }
                />
              </span>
            </Tooltip>

            <Tooltip title="Enable quicksetting of properties">
              <span>
                <FormControlLabel
                  labelPlacement="top"
                  label={
                    <Typography variant="caption">
                      Quickset Properties
                    </Typography>
                  }
                  control={
                    <Switch
                      checked={quicksetProps}
                      onChange={() => setQuicksetProps((curr) => !curr)}
                      disabled={showRender}
                    />
                  }
                />
              </span>
            </Tooltip>

            <SelectShader
              shaderOptions={shaderOptions}
              setSelectedShaderId={setSelectedShaderId}
              selectedShaderId={selectedShaderId}
              applyShaders={applyShaders}
            />

            <Tooltip title="Shade each element according to the selected shader">
              <span>
                <FormControlLabel
                  labelPlacement="top"
                  label={
                    <Typography variant="caption">Shade Elements</Typography>
                  }
                  control={
                    <Switch
                      checked={applyShaders}
                      onChange={() => setApplyShaders((curr) => !curr)}
                      disabled={selectedShaderId === ""}
                    />
                  }
                />
              </span>
            </Tooltip>

            <Tooltip title="Hide unshaded elements">
              <FormControlLabel
                label={<Typography variant="caption">Hide unshaded</Typography>}
                labelPlacement="top"
                control={
                  <Switch
                    checked={hideUnshaded}
                    onChange={(e) => {
                      setHideUnshaded((curr) => !curr)
                    }}
                    disabled={selectedShaderId === ""}
                  />
                }
              />
            </Tooltip>

            <Tooltip
              title={
                selectedShaderId === ""
                  ? "Select an 'Element Shader' before a legend can be shown"
                  : "Show a legend for the selected shader"
              }
            >
              <FormControlLabel
                labelPlacement="top"
                label={<Typography variant="caption">Legend</Typography>}
                control={
                  <Switch
                    checked={showLegend}
                    disabled={!applyShaders || selectedShaderId === ""}
                    onChange={() => setShowLegend((curr) => !curr)}
                  />
                }
              />
            </Tooltip>

            <Tooltip title="Toggle whether to show elements as an icon or text">
              <span>
                <FormControlLabel
                  labelPlacement="top"
                  label={
                    <Typography variant="caption">
                      Show Element Types
                    </Typography>
                  }
                  control={
                    <IconButton
                      onClick={(event) =>
                        setShowElementTypeAnchorEl(event.currentTarget)
                      }
                      disabled={
                        elementTypesForCurrentView.length === 0 || showRender
                      }
                    >
                      <ImageIcon />
                    </IconButton>
                  }
                />
              </span>
            </Tooltip>

            <Tooltip
              title={`${showLayoutOptions ? "Hide" : "Show"} Layout Options`}
            >
              <span>
                <IconButton
                  onClick={() => setShowLayoutOptions((curr) => !curr)}
                  disabled={showRender}
                >
                  {showLayoutOptions ? (
                    <ArrowBackIosIcon />
                  ) : (
                    <ArrowForwardIosIcon />
                  )}
                </IconButton>
              </span>
            </Tooltip>
          </>
        )}

        {showLayoutOptions && (
          <Collapse orientation="horizontal" in={showLayoutOptions}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                flexWrap: "wrap",
              }}
            >
              {currentView &&
                currentView.layout &&
                currentView.layout.map((layout, index) => (
                  <ModelEditLayoutSelector
                    key={`${currentView.id}-${index}`}
                    showEditable={showEditable}
                    orientation={layout.orientation}
                    setOrientation={(orientation) =>
                      handleLayoutOrientationChange(orientation, index)
                    }
                    wrap={layout.wrap || "wrap"}
                    setWrap={(wrap) => handleLayoutWrapChange(wrap, index)}
                    level={index}
                    setHighlightLevel={setHighlightLevel}
                    tooltipTitle={`Layout ${layout.id} Orientation`}
                  />
                ))}
            </Box>
          </Collapse>
        )}
      </Box>

      <Box>
        <AIProgress
          generationPanelCount={generationPanelCount}
          handleRequestStop={handleRequestStop}
          message={generatingContentMessage}
        />
      </Box>

      {isPivotViewMissingConfig && (
        <Alert severity="warning">
          This view is a pivot view but has no pivot properties defined. Click
          Configure View and add some properties.
        </Alert>
      )}

      <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
      <DiagramDescription
        currentView={currentView}
        handleGenerateDescription={handleGenerateDescription}
        isGeneratingDescription={isGeneratingDescription}
        isGenerating={generationPanelCount > 0}
      />

      {/* {currentView &&
        currentView.prompt_history &&
        currentView.prompt_history.map((history, index) => (
          <Box key={index}>
            <Typography variant="caption" color="text.secondary">
              {history}
            </Typography>
          </Box>
        ))} */}

      <Box sx={{ mt: "5px" }}>
        <ViewRating currentView={currentView} setViews={setViews} />
      </Box>

      {!currentView && viewSet && views && (
        <ModelEditDashboard
          assistants={assistants}
          views={views}
          viewSet={viewSet}
          setViewSet={setViewSet}
          setValues={setViewSet}
          viewSetId={viewSetId}
          handleAddSuggestedView={handleAddSuggestedView}
          handleSelectView={handleSelectView}
          aiRole={aiRole}
          roles={roles}
          setShowConfigureViewSet={setShowConfigureViewSet}
          viewSuggestions={viewSet.view_suggestions || []}
          setViewSuggestions={setViewSuggestions}
          handleCreateViewFromSuggestion={handleCreateViewFromSuggestion}
          handleUpdateViewsorting={handleUpdateViewSorting}
          handleUpdateInsightsAndActions={handleUpdateInsightsAndActions}
          handleTogglePinQuestion={handleTogglePinQuestion}
          handleTogglePinSuggestedView={handleTogglePinSuggestedView}
          handleDeleteViewSuggestion={handleDeleteViewSuggestion}
          canAddView={canAddView}
        />
      )}

      <Box
        sx={{
          marginTop: "10px",
          padding: "5px",
          display: "flex",
          flexWrap: "wrap",
        }}
        onMouseUp={(e) => {
          e.stopPropagation()
          onMouseUp(null)
        }}
        ref={boundingRef}
      >
        {selectedViewId !== "" &&
          rootElements.length === 0 &&
          generationPanelCount === 0 && (
            <Stack direction="column" gap={2}>
              <Typography variant="body2">
                This '{currentView?.name}' view has no elements. You can add
                elements in these ways
              </Typography>
              {addContentMethods.map((m, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ width: "50px" }}>{m.icon}</Box>
                  <Box sx={{ maxWidth: "400px" }}>
                    <Typography variant="body2">{m.text}</Typography>
                  </Box>
                </Box>
              ))}

              <FormControlLabel
                sx={{ marginLeft: "40px" }}
                label={
                  <Typography variant="caption">
                    Show sample JSON data that can be pasted into AI Designer
                  </Typography>
                }
                control={
                  <Switch
                    size="small"
                    checked={showSample}
                    onChange={() => setShowSample((curr) => !curr)}
                  />
                }
              />

              {showSample && (
                <>
                  <Paper sx={{ padding: "1em" }}>
                    <Box sx={{ lineHeight: 1.1 }}>
                      <pre>{JSON.stringify(sampleJsonData, null, 2)}</pre>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography variant="caption" color="text.secondary">
                        Sample of JSON data format that can be pasted into AIM
                      </Typography>
                    </Box>
                  </Paper>
                  <Stack gap={1} sx={{ maxWidth: "500px" }}>
                    <Typography variant="body2">
                      The format of the JSON data is an array of elements, which
                      must have a 'name' attribute and then have an optional
                      'description', 'type', 'children', and 'props' attributes.
                    </Typography>
                    <Typography variant="body2">
                      The 'type' attribute must be a valid ArchiMate type.
                    </Typography>
                    <Typography variant="body2">
                      If you do not specify a 'type' attribute then you will be
                      prompted to specify a type when you Paste the JSON data.
                    </Typography>
                    <Typography variant="body2">
                      You can either provide a flat JSON array of elements, or
                      you can provide a hierarchical JSON structure of elements,
                      where each element has a 'children' attribute which is an
                      array of child elements. You can have any level of
                      nesting.
                    </Typography>
                    <Typography variant="body2">
                      You can also use ChatGPT to generate content and ask it to
                      format it in this way, so that you can copy/paste JSON
                      data from ChatGPT into AIM. e.g. ChatGPT prompt: "Give me
                      a list of business functions [...some criteria] formatted
                      as a JSON array of elements, where each element has a name
                      and description attribute"
                    </Typography>
                  </Stack>
                </>
              )}
            </Stack>
          )}
        {rootElements && showEditable && (
          <ModelEditChildElements
            elements={elements}
            scope={viewSet?.scope || ""}
            overview={viewSet?.overview || ""}
            shadedElementIds={shadedElementIds}
            showRender={showRender}
            children={rootElements}
            item={null}
            handleAddSibling={handleAddSibling}
            selectedItemId={selectedItemId}
            setSelectedItemId={setSelectedItemId}
            setShowEditElement={setShowEditElement}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            layout={currentView?.layout || []}
            setCoords={setCoords}
            highlightLevel={highlightLevel}
            level={0}
            levels={currentView?.layout?.length || 0}
            highlightProp={highlightProp}
            setHighlightProp={setHighlightProp}
            currentShader={currentShader}
            hideUnshaded={hideUnshaded}
            showProps={showProps && !showRender}
            hiddenProps={hiddenProps}
            showUsage={showUsage && !showRender}
            applyShaders={applyShaders}
            applyShadersToChips={applyShadersToChips}
            shaders={shaders}
            hiddenElementTypes={currentView?.hidden_element_types}
            quicksetProps={quicksetProps && !showRender}
            views={views}
            handleToggleProp={handleToggleProp}
            duplicateItems={duplicateItems}
            drawRequestTime={drawRequestTime}
            currentView={currentView}
            handleSelectItem={handleSelectItem}
            // handleCreateDescription={handleCreateDescription}
            // handleExpandItem={handleExpandItem}
            showDocs={showDocs}
            elementsWithProjects={elementsWithProjects}
            waitingElementIds={waitingElementIds}
            generationPanelCount={generationPanelCount}
          />
        )}
      </Box>
      {showLegend &&
        selectedShaderId !== "" &&
        shaders &&
        applyShaders &&
        !showRender &&
        currentView && (
          <ModelEditLegend
            currentView={currentView}
            currentShader={currentShader}
          />
        )}

      {currentElement && selectedItemId && showEditElement && (
        <ModelEditElementDialog
          open={selectedItemId !== undefined}
          onClose={() => {
            setShowEditElement(false)
            setSelectedItemId(undefined)
          }}
          currentElement={currentElement}
          currentView={currentView}
          setSelectedItemId={setSelectedItemId}
          setSelectedViewId={setSelectedViewId}
          handleEditShader={handleEditShader}
          views={views}
          setPropertyType={setPropertyType}
          handleUpdateElement={handleUpdateElement}
          handleDelete={handlePromptDeleteElement}
        />
      )}

      {showRender && (
        <Box>
          <ModelEditGraphDiagram
            coords={allCoords}
            dimensions={diagramDimensions}
            highlight={applyShaders && selectedShaderId !== ""}
            currentShader={currentShader}
            shaders={shaders}
            showLegend={showLegend && applyShaders}
            currentView={currentView}
            hiddenProps={hiddenProps}
            showDocs={showDocs}
            elementsWithProjects={elementsWithProjects}
            handleCreateChatPrompt={handleCreateChatPrompt}
          />
        </Box>
      )}

      {currentView && currentView.question && (
        <Tooltip title="Diagram created to answer this question">
          <Box sx={{ mt: "10px", display: "flex", justifyContent: "center" }}>
            <Typography variant="body2" color="text.primary">
              {currentView.question}
            </Typography>
          </Box>
        </Tooltip>
      )}

      {currentView && currentView.text_response && (
        <TiptapEditor
          content={markdownToTiptapJSON(currentView.text_response)}
          onUpdate={(content) => handleUpdateTextResponse(content)}
        />
      )}

      <ModelEditPortolio
        elements={elements}
        setElements={setElements}
        shaders={shaders}
        selectedShaderId={selectedShaderId}
        currentShader={currentShader}
        width={width}
      />
    </>
  )
}

const ConfigureViewSetButton = ({ setShowConfigureViewSet }) => {
  return (
    <IconButton onClick={() => setShowConfigureViewSet(true)}>
      <SettingsIcon />
    </IconButton>
  )
}

const AddSiblingIconButton = ({ item, handleAddSibling, disabled }) => {
  return (
    <>
      <Tooltip title="Add Sibling">
        <span>
          <IconButton
            size="small"
            onClick={() => {
              handleAddSibling(item)
            }}
            disabled={disabled}
          >
            <AddCircleIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  )
}

const DiagramDescription = ({
  currentView,
  handleGenerateDescription,
  isGeneratingDescription,
  // Are we generating any other content?
  isGenerating,
}) => {
  return (
    <>
      {currentView && (
        <Stack gap={1}>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {currentView.description || "No title"}
            </Typography>

            <Box sx={{ marginLeft: "auto" }}>
              {!isGeneratingDescription && (
                <Tooltip title="Generate diagram title and summary">
                  <span>
                    <Button
                      onClick={handleGenerateDescription}
                      disabled={isGenerating}
                      variant="outlined"
                      sx={{ textTransform: "none" }}
                      endIcon={<SummarizeIcon sx={{ color: aiColor }} />}
                    >
                      Generate Title
                    </Button>
                  </span>
                </Tooltip>
              )}
              {isGeneratingDescription && (
                <CircularProgress size={16} sx={{ color: colors.pink[400] }} />
              )}
            </Box>
          </Box>
          <Typography variant="body1" color="text.secondary">
            {currentView.direct_statement}
          </Typography>
          {currentView.how_to_read && (
            <Typography variant="body1" color="text.secondary">
              {currentView.how_to_read}
            </Typography>
          )}
        </Stack>
      )}
    </>
  )
}

const AddChildIconButton = ({ handleAddChild, disabled }) => {
  return (
    <>
      <Tooltip title="Add Child">
        <span>
          <IconButton size="small" onClick={handleAddChild} disabled={disabled}>
            <AddCircleOutlineIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  )
}

const PasteContentIconButton = ({
  handlePasteAdd,
  disabled,
  aiRelatedIcon,
}) => {
  return (
    <Tooltip title="Add new elements by pasting JSON data">
      <span>
        <IconButton
          size="small"
          onClick={() => {
            handlePasteAdd()
          }}
          disabled={disabled}
        >
          <ContentPasteIcon sx={!disabled ? aiRelatedIcon : {}} />
        </IconButton>
      </span>
    </Tooltip>
  )
}

const DeleteIconButton = (props) => {
  const { handlePromptDeleteElement, disabled } = props
  return (
    <Tooltip title="Delete selected element">
      <span>
        <IconButton
          size="small"
          onClick={() => {
            handlePromptDeleteElement()
          }}
          disabled={disabled}
        >
          <DeleteIcon />
        </IconButton>
      </span>
    </Tooltip>
  )
}

const CustomIconButton = (props) => {
  const { onClick, tooltipText, icon, disabled = false } = props
  return (
    <Tooltip title={tooltipText}>
      <span>
        <IconButton size="small" onClick={onClick} disabled={disabled}>
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  )
}

const SelectShader = ({
  shaderOptions,
  setSelectedShaderId,
  selectedShaderId,
  applyShaders,
}) => {
  return (
    <FormControlLabel
      labelPlacement="top"
      label={
        <Typography sx={{ mb: "5px" }} variant="caption">
          Shader
        </Typography>
      }
      control={
        <Select
          id="elementShader"
          value={selectedShaderId}
          onChange={(e) => setSelectedShaderId(e.target.value)}
          renderValue={(value) => {
            return (
              shaderOptions.find((option) => option.id === value)?.title ||
              "None"
            )
          }}
          disabled={!applyShaders}
          label="Element Shader"
          size="small"
          autoWidth={true}
          variant="standard"
          sx={{ width: "150px" }}
        >
          <MenuItem value="">
            <ListItemIcon />
            <ListItemText>None</ListItemText>
          </MenuItem>
          {shaderOptions &&
            shaderOptions.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                  }}
                >
                  <ListItemIcon>
                    {option.usedInCurrentView && <VisibilityIcon />}
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      color: !option.used && "#BBB",
                      fontStyle: !option.used && "italic",
                    }}
                  >
                    {option.title}
                  </ListItemText>
                </Box>
              </MenuItem>
            ))}
        </Select>
      }
    />
  )
}

const ShowDocsMenu = ({
  showDocoAnchorEl,
  setShowDocoAnchorEl,
  setShowDocs,
}) => {
  return (
    <Menu
      anchorEl={showDocoAnchorEl}
      open={showDocoAnchorEl !== null}
      onClose={() => setShowDocoAnchorEl(null)}
    >
      <MenuItem
        value="none"
        onClick={() => {
          setShowDocoAnchorEl(null)
          setShowDocs("none")
        }}
      >
        <ListItemText primary="None" />
      </MenuItem>
      <MenuItem
        value="indicator"
        onClick={() => {
          setShowDocoAnchorEl(null)
          setShowDocs("indicator")
        }}
      >
        <ListItemText primary="Indicator" />
      </MenuItem>
      <MenuItem
        value="show"
        onClick={() => {
          setShowDocoAnchorEl(null)
          setShowDocs("show")
        }}
      >
        <ListItemText primary="Text" />
      </MenuItem>
    </Menu>
  )
}

/***
 * User can pick from 1 to 5 stars to give a rating to the view
 */
const ViewRating = ({ currentView, setViews }) => {
  return (
    <>
      {currentView && (
        <Box sx={{ mt: "10px" }}>
          <Rating
            onChange={(e) => {
              const { id, ...rest } = currentView

              const starRatingValue =
                e.target.value === "" ? 0 : parseInt(e.target.value)

              // Set the rating DB attribute for the current view
              db.collection("views")
                .doc(currentView.id)
                .update({
                  ...rest,
                  rating: parseInt(starRatingValue),
                })
                .then(() => {
                  setViews((curr) =>
                    curr.map((view) => {
                      if (view.id === currentView.id) {
                        return {
                          ...view,
                          rating: parseInt(starRatingValue),
                        }
                      }
                      return view
                    })
                  )
                })
            }}
            value={currentView?.rating || 0}
          />
        </Box>
      )}
    </>
  )
}

export default withSnackbar(withRouter(ModelEditForm))
