import React, { useState, useEffect } from "react"
import { Box, Menu, MenuItem, ListItemIcon, IconButton } from "@mui/material"
import _ from "lodash"
import * as palette from "../symbols/palette"
import CheckIcon from "@mui/icons-material/Check"
import MoreVertIcon from "@mui/icons-material/MoreVert"

const ElementTypeSelector = (props) => {
    const { setElementTypes } = props

    const [selectedElementTypes, setSelectedElementTypes] = useState([])

    const [selectedElementHeaders, setSelectedElementHeaders] = useState([])

    const [menuItemInfo, setMenuItemInfo] = useState([])

    const [elementTypesHash, setElementTypesHash] = useState([])

    const [anchorEl, setAnchorEl] = useState(null)

    useEffect(() => {
        buildMenuItems()
    }, [])

    useEffect(() => {
        setElementTypes(selectedElementTypes)
    }, [selectedElementTypes])

    const buildMenuItems = () => {
        const menuInfo = []

        const elementTypes = palette.getElementTypes()

        Object.keys(elementTypes).forEach((layerKey) => {
            const layer = palette.LAYERS.find((layer) => layer.id === layerKey)
            const name = layer.name

            menuInfo.push({
                type: "header",
                label: name,
                menuProps: { style: { backgroundColor: layer.color } },
            })

            elementTypes[layerKey].forEach((element) => {
                menuInfo.push({
                    type: "item",
                    label: element.name,
                    value: element.name,
                    menuProps: {},
                })
            })
        })

        setElementTypesHash(_.flatten(Object.values(elementTypes)))
        setMenuItemInfo(menuInfo)
    }

    const handleToggleElementType = (type, label) => {
        console.log("%ctoggle element type", "color:yellow", { type, label })
        if (type === "header") {
            const isHeaderSelected = selectedElementHeaders.includes(label)

            const newHeaders = isHeaderSelected
                ? selectedElementHeaders.filter((header) => header !== label)
                : [...selectedElementHeaders, label]

            if (isHeaderSelected) {
                // deselect all elements of this type
                const elementsOfType = elementTypesHash
                    .filter((item) => item.layer.name === label)
                    .map((item) => item.name)

                const newElements = selectedElementTypes.filter(
                    (element) => element !== label && !elementsOfType.includes(element)
                )

                setSelectedElementTypes(newElements)
            } else {
                // select all elements of this type

                const newElements = _.uniq([
                    ...selectedElementTypes,
                    ...elementTypesHash
                        .filter((item) => item.layer.name === label)
                        .map((item) => item.name),
                ])

                setSelectedElementTypes(newElements)
            }

            setSelectedElementHeaders(newHeaders)
        } else if (type === "item") {
            const isItemSelected = selectedElementTypes.includes(label)

            const newItems = isItemSelected
                ? selectedElementTypes.filter((name) => name !== label)
                : [...selectedElementTypes, label]

            setSelectedElementTypes(newItems)

            // If deselecting an item, make sure it's header row is deselected

            const layer = elementTypesHash.find((item) => item.name === label).layer

            const allLayerItems = elementTypesHash
                .filter((item) => item.layer.name === layer.name)
                .map((item) => item.name)

            const isAllLayerItemsSelected = allLayerItems.every((item) => newItems.includes(item))

            const newHeaders = isAllLayerItemsSelected
                ? [...selectedElementHeaders, layer.name]
                : selectedElementHeaders.filter((item) => item !== layer.name)

            setSelectedElementHeaders(newHeaders)
            setSelectedElementTypes(newItems)
            console.log(newItems)
        }
    }

    const handleElementTypeFilterChange = (event) => {
        console.log("%celement type filter changed", "color:yellow", {
            event,
            selectedElementTypes,
        })

        setAnchorEl(null)
    }

    return (
        <Box>
            <IconButton onClick={(event) => setAnchorEl(event.target)}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={anchorEl !== null}
                onClose={handleElementTypeFilterChange}
            >
                {menuItemInfo &&
                    menuItemInfo.map((item) => (
                        <MenuItem
                            key={item.label}
                            value={item.label}
                            onClick={() => handleToggleElementType(item.type, item.label)}
                            {...item.menuProps}
                        >
                            <ListItemIcon>
                                {(selectedElementTypes.includes(item.label) ||
                                    selectedElementHeaders.includes(item.label)) && <CheckIcon />}
                            </ListItemIcon>
                            {item.label.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}
                        </MenuItem>
                    ))}
            </Menu>
        </Box>
    )
}

export { ElementTypeSelector }
