import React, { useState } from "react"
import Header from "../components/Header"
import { Paper } from "@mui/material"
import ElementListForm from "../components/ElementListForm"
import { withRouter } from "react-router-dom"
import { useSelector } from "react-redux"
import { selectModelState } from "../redux/selectors"
import { useEffect } from "react"
import * as modelServices from "../pages/services/modelServices"
import { spacing } from "../pages/services/styleServices"

const styles = {
    pageContent: {
        margin: spacing(1),
        padding: spacing(1),
    },
}

const ElementListView = (props) => {
    const { parentId, fileName, type } = props.computedMatch.params

    const selectModel = useSelector(selectModelState)

    const [title, setTitle] = useState("Element")

    useEffect(() => {
        const modelCacheId = modelServices.getModelCacheId({ parentId, fileName })
        const state = selectModel[modelCacheId]

        console.log("state", state)
    }, [parentId, fileName, type, selectModel])

    return (
        <Header title={title}>
            <Paper sx={styles.pageContent}>
                <ElementListForm {...props} type={type} setTitle={setTitle} />
            </Paper>
        </Header>
    )
}

export default withRouter(ElementListView)
