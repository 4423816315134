import React, { useEffect, useState } from "react"
import { Card, CardActionArea, CardContent } from "@mui/material"
import * as palette from "./symbols/palette"
import { useHistory } from "react-router-dom"
import * as modelServices from "../pages/services/modelServices"
import { spacing } from "../pages/services/styleServices"

const styles = {
    cardContent: {
        padding: spacing(0.5),
    },
}

const ElementCountSummaryCard = (props) => {
    const { elementType, modelState } = props

    const [elementSymbol, setElementSymbol] = useState()

    const history = useHistory()

    // The way to get the ArchiMate SVG symbol to show, is to hold a reference to an
    // object which has the symbol as one of its properties.
    // It doesn't work if you hold a reference directly to the SVG element.
    useEffect(() => {
        const s = { symbol: palette.symbols[elementType.key] }
        setElementSymbol(s)
    }, [])

    const handleClickView = () => {
        const url = `/ElementList/${modelState.parent_id}/fileName/${modelState.model.file}/type/${elementType.key}`
        console.log("view list", { modelState, elementType, url })
        history.push(url)
    }

    return (
        <Card raised={false} elevation={0}>
            <CardActionArea onClick={handleClickView}>
                <CardContent sx={styles.cardContent}>
                    <svg height={60} width={140} viewBox="0 0 150 70">
                        {elementSymbol && elementSymbol.symbol && (
                            <elementSymbol.symbol
                                height={60}
                                width={140}
                                x={1}
                                y={3}
                                showLabel={true}
                                label={`${modelServices.splitCamelCase(elementType.key)} (${
                                    elementType.count
                                })`}
                                style={{ fontSize: "13px" }}
                            />
                        )}
                    </svg>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default ElementCountSummaryCard
