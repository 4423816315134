import { useEffect, useState } from "react"
import * as colors from "@mui/material/colors"

export const useFill = (props) => {
    const {
        defaultColor,
        hoverColor,
        highlight,
        setFill,

        // Higlight color can be optionally overridden by the StoryEdit page where different color selections can be used
        highlightColor = colors.teal["A200"],
    } = props

    const [dynFillColor, setDynFillColor] = useState()

    const handleFill = (fill) => {
        if (fill === defaultColor) {
            setFill(highlight ? highlightColor : defaultColor)
        } else {
            setFill(hoverColor)
        }
    }

    useEffect(() => {
        if (highlightColor && defaultColor) {
            const c = highlight ? highlightColor : defaultColor

            setFill(c)
            setDynFillColor(c)
        }
    }, [highlight, highlightColor, defaultColor, setFill])

    return { handleFill, dynFillColor }
}
