import { Alert, Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material"
import SubscriptionsIcon from "@mui/icons-material/Subscriptions"
import firebase from "firebase/compat/app"
import { useEffect, useState } from "react"
import * as Roles from "../pages/services/roleServices"
import { useHistory } from "react-router-dom"

const GetAIMAIDialog = ({ open, onClose }) => {
    const [isAdmin, setIsAdmin] = useState(undefined)

    const history = useHistory()

    useEffect(() => {
        const unsub = firebase.auth().onAuthStateChanged((user) => {
            user.getIdTokenResult(false).then((token) => {
                setIsAdmin(token.claims.roles.includes(Roles.ADMIN))
            })
        })

        return unsub
    }, [])

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Get Generative AI</DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <Typography>
                        Elevate your architectural efficiency to new heights with our AI-powered
                        solution. Unleashing the power of advanced Generative AI, we can expedite
                        your architecture content management processes - from Enterprise level to
                        Programs and Projects.
                    </Typography>
                    <Typography>
                        Imagine having a partner that can generate Architecture Reference Models in
                        minutes, a task that previously demanded days or even weeks of manual labor.
                    </Typography>
                    <Typography>
                        Welcome to a world where speed and accuracy come together to optimize your
                        operational output. But we don't just save time. Our AI features are
                        designed to enable better project scoping, thereby derisking projects before
                        issues materialize. This ensures a smoother, more predictable project flow
                        and outcomes.
                    </Typography>
                    {isAdmin === false && (
                        <Alert severity="info">
                            You will need to ask your AIM account admin to allocate the 'AIM AI'
                            role in the 'Users' menu.
                        </Alert>
                    )}
                    <Stack direction="row" gap={1} justifyContent={"flex-end"}>
                        <Button sx={{ textTransform: "none" }} variant="outlined" color="primary" onClick={onClose}>
                            Close
                        </Button>
                        {isAdmin === true && (
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ textTransform: "none" }}
                                endIcon={<SubscriptionsIcon />}
                                onClick={() => {
                                    onClose()
                                    history.push("/Billing")
                                }}
                            >
                                Go to subscriptions
                            </Button>
                        )}
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default GetAIMAIDialog
