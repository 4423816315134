import * as actions from "../actionTypes"

const initialState = {
    // components -- 'items' is named this way since the corresponding 'projects' redux reducer also has an 'items' property
    // so that we can treat these 2 reducers the same from the ExplorerForm
    items: [],
}

export default function components(state = initialState, action) {
    switch (action.type) {
        case actions.SET_COMPONENTS: {
            const newState = action.payload.value
            
            //console.log("%c[redux] Setting components", "color:lightgreen", { action, newState })

            return newState
        }

        default:
            return state
    }
}
