import React, { useState, useEffect, useRef, useCallback, useMemo } from "react"
import _ from "lodash"
import * as modelServices from "../pages/services/modelServices"
import * as viewServices from "../pages/services/viewServices"
import * as palette from "./symbols/palette"
import {
    List,
    Tooltip,
    ListItem,
    IconButton,
    ListItemText,
    ListItemIcon,
    ListItemSecondaryAction,
    Box,
    Typography,
} from "@mui/material"
import Heading from "./controls/Heading"
import ElementText from "../components/symbols/ElementText"
import AssignmentIcon from "@mui/icons-material/Assignment"
import EditIcon from "@mui/icons-material/Edit"
import SmartToyIcon from "@mui/icons-material/SmartToy"
import * as colors from "@mui/material/colors"
import RuleIcon from "@mui/icons-material/Rule"
import { getBlobFromImageElement, copyBlobToClipboard } from "copy-image-clipboard"
import { useHistory } from "react-router-dom"
import firebase from "firebase/compat/app"
import db from "../Firestore"
import * as dataServices from "../pages/services/dataServices"
import { setEditMode } from "../redux/actions"
import { useDispatch } from "react-redux"
import { Skeleton } from "@mui/material"
import * as urlServices from "../pages/services/urlServices"
import { useSnackbar } from "notistack"
import { useWindowDimensions } from "../pages/services/useWindowDimensions"
import { handleDownloadSVG } from "../pages/services/svgServices"
import { selectModelState } from "../redux/selectors"
import { useSelector } from "react-redux"
import { spacing } from "../pages/services/styleServices"
import DiagramButtons from "./DiagramButtons"
import DownloadIcon from "@mui/icons-material/Download"
import { Svg2Roughjs } from "svg2roughjs"
import Controls from "./controls/Controls"
import { FONT_FAMILY } from "../pages/services/svgUtils"

const styles = {
    buttons: {
        marginTop: spacing(2),
    },
    label: {
        textTransform: "none",
    },
    input: {
        display: "none",
    },
    card: {
        marginRight: spacing(2),
        marginBottom: spacing(5),
    },
    svg: {
        marginLeft: spacing(1),
        marginRight: spacing(2),
    },
    ruleMessage: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    legendHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: spacing(3),
        marginLeft: spacing(2),
    },
}

const E2E_CONNECTIONS_CACHE_KEY = "E2EConnections"
const N2E_LINES_COUNT_CACHE_KEY = "N2ELinesCount"
const N2C_LINES_COUNT_CACHE_KEY = "N2CLinesCount"

const Diagram = (props) => {
    // maxWidth is optional, and only used when we display a scaled down version of the view.
    const {
        params,
        maxWidth,

        // for when we want to scale down the diagram, regardless of how much space is available.
        // e.g. showing a small version of a diagram with 1 element on it will show up as full size
        // which we don't want.
        maxScale = 1,
        showRules,
        handleElementMouseOver,
        handleElementMouseOut,
        highlightElementIds = [],
        elementColors = [],
        // Do we show elements not in the highlightElementIds array as grey?
        dimElementIfNotHighlighted = false,
        showCopyButton,
        showCreateStoryButton = true,
        // Callback to create a ChatGPT prompt
        handleCreateChatPrompt,
        // Open a chat with ChatGPT
        handleShowChat,
        showLabels = true,
        // Show a button to create a ref for this diagram that can be used in AI Designer
        showCreateRefButton = false,
        handleClickItem,
        // Callback, to pass out the width of the diagram after scaling, etc, has been applied
        setDiagramWidth,
        diagramBackgroundColor = "#fff",
        diagramBorderColor = "#fff",

        // Should we limit the width of how rules are presented?
        // Where we're showing a single diagram on a page it's not necessary to limit width of each rule result being shown
        constrainRuleWidth,
        showDocumentation,
        showTooltips,

        // Auto-generate a legend under the diagram, using the different element types it uses
        autoLegend = false,

        // If we're showing this diagram as part of Story mode, then what labels do we apply to
        // all elements selected by this story element
        // [{ id: <id>, seq: [1,2,3]}]
        storyElementLabels,
    } = props

    const { ClipboardItem } = window

    const { enqueueSnackbar } = useSnackbar()

    const PADDING = 10

    const dispatch = useDispatch()

    // If the auto legend is shown for a diagram then this variable can hold the element ids
    // for a given selected element type, e.g. Application Component. These element ids are
    // highlighted in addition to any highlightElementIds passed through via props
    const [legendHighlightElementIds, setLegendHighlightElementIds] = useState([])

    // If we click on a legend symbol then we set those element ids to be highlighted
    // These remain highlighted til we click on another legend symbol
    // The structure if this object is:
    // {
    //    type: <element type>
    //    elementIds: []
    //
    // }
    // We need to store the element type so that if we click on the same
    // element type again we can delect the highlighted element ids
    const [legendToggledElementIds, setLegendToggledElementIds] = useState({})

    const history = useHistory()

    const [view, setView] = useState()

    const modelCache = useSelector(selectModelState)

    const [modelCacheItem, setModelCacheItem] = useState()

    const [loadState, setLoadState] = useState({})

    const [messages, setMessages] = useState()

    const [highlights, setHighlights] = useState([])

    const [isShowLineDrawing, setIsShowLineDrawing] = useState(false)

    const [diagramData, setDiagramData] = useState()

    const numberedCircles = useMemo(() => {
        if (diagramData && storyElementLabels) {
            const numberedCircles = storyElementLabels
                .map((sel) => diagramData.viewElements.find((item) => sel.id === item.element?.id))
                .filter((item) => item !== undefined)
                .map((item) => ({
                    id: item.element.id,
                    x: item.bounds.x - diagramData.diagramDimensions.widthOffset,
                    y:
                        item.bounds.y -
                        diagramData.diagramDimensions.heightOffset +
                        item.bounds.height,
                    label: storyElementLabels.find((sel) => sel.id === item.element.id).seq,
                }))

            const result = _.flatten(
                numberedCircles.map((item) => {
                    return item.label.map((label, index) => {
                        return {
                            id: item.id,
                            x: item.x + 22 * index,
                            y: item.y,
                            label: label,
                        }
                    })
                })
            )

            // console.log("%cnumberedCircles", "color:lightgreen", {
            //     numberedCircles,
            //     storyElementLabels,
            //     viewElements: diagramData.viewElements,
            //     result,
            // })

            return result
        }
    }, [diagramData, storyElementLabels])

    const handleCreateAIDesignerRef = async () => {
        console.log("create AI Designer ref", { params, modelCacheItem, view })

        const refName = `[${modelCacheItem.type}:${modelCacheItem.name}:${modelCacheItem.model.file}:${view.name}]`

        enqueueSnackbar(`Copied ref to clipboard for use with AI Designer: ${refName}`, {
            variant: "info",
        })
        await navigator.clipboard.writeText(refName)
        return refName
    }

    //const [diagramSvg, setDiagramSvg] = useState()

    // FIXME: This isn't working when you use the Create New Model page and there are lots of model files
    // YOu seem to get NaN for the width, not sure why?
    const getScaledWidth = useCallback(
        (diagramDimensions) => {
            let width = maxWidth
                ? Math.min(maxWidth, diagramDimensions.width)
                : diagramDimensions.width

            const widthScaling = width / diagramDimensions.width

            if (widthScaling > maxScale) {
                width = width * maxScale
            }

            return width
        },
        [maxScale, maxWidth]
    )

    /**
     * This useEffect fires each time a view is drawn
     */
    useEffect(() => {
        if (view && modelCache && params) {
            const mci = getModelFromCache(
                modelCache,
                params.parentId || params.modelId,
                params.fileName
            )
            // console.log("%c*** model cache changed", "color:yellow", {
            //     mci,
            //     messages: mci.messages,
            // })

            if (mci?.messages) {
                const viewElementIds = view.elements.map(
                    (element) => element.diagramObject.archimateElement
                )

                const applicableMessages = mci.messages
                    .filter((message) => message.element)
                    .filter((message) => viewElementIds.includes(message.element.id))
                    .filter((message) =>
                        message.isShowForView ? message.isShowForView(view) : true
                    )
                    .sort((a, b) => a.msg.localeCompare(b.msg))

                setMessages(applicableMessages)
            }
        }
    }, [modelCache, params, view])

    useEffect(() => {
        //console.log("%cDiagram.useEffect", "color:yellow", { diagramData, maxScale, maxWidth })

        if (setDiagramWidth && diagramData) {
            const scaledWidth = getScaledWidth(diagramData.diagramDimensions)
            //console.log("%cset scaled width (callback)", "color: yellow", scaledWidth)
            setDiagramWidth(scaledWidth)
        }
    }, [diagramData, maxScale, maxWidth, getScaledWidth, setDiagramWidth])

    // const updateMessages = async (modelState, view) => {
    //     const msgs = modelState.messages || []

    //     if (view.messages) {
    //         const viewElementIds = view.elements.map(
    //             (element) => element.diagramObject.archimateElement
    //         )

    //         // Only show messages that apply to this view

    //         const applicableMessages = msgs
    //             .filter((message) => message.element)
    //             .filter((message) => viewElementIds.includes(message.element.id))
    //             .filter((message) => (message.isShowForView ? message.isShowForView(view) : true))
    //             .sort((a, b) => a.msg.localeCompare(b.msg))

    //         setMessages(applicableMessages)
    //     }
    // }

    // useEffect(() => {
    //     if (modelCacheItem && view) {
    //         if (!modelCacheItem) {
    //             console.log("%cmodelCacheItem not found, in useEffect", "color:red")
    //         }

    //         updateMessages(modelCacheItem, view)
    //     }
    // }, [modelCacheItem, view])

    const [viewReloadState, setViewReloadState] = useState({})

    useEffect(() => {
        if (params && modelCache) {
            const newViewReloadState = { params }
            const isChanged = !_.isEqual(viewReloadState, newViewReloadState)
            if (isChanged) {
                setViewReloadState(newViewReloadState)

                // TODO: fix inconsistency across multiple screens where the parent id can either by called paraentId or modelId
                const mci = getModelFromCache(
                    modelCache,
                    params.parentId || params.modelId,
                    params.fileName
                )

                if (mci) {
                    console.assert(mci, "Expecting to find modelCacheItem", {
                        mci,
                        modelCache,
                        params,
                    })
                    setModelCacheItem(mci)

                    const theView = mci.model.views.find((view) => view.id === params.viewId)
                    console.assert(theView, "Expecting to find view", {
                        modelCacheItem,
                        params,
                    })

                    setView(theView)
                } else {
                    console.log(
                        "%cDiagram.useEffect - modelCacheItem not found",
                        "color:lightgreen"
                    )
                }
            }
        }
    }, [params, modelCache])

    const getModelFromCache = (modelCache, modelId, fileName) => {
        console.assert(modelId, "Expecting modelId", { modelId })

        const modelCacheItem = Object.values(modelCache).find(
            (cacheEntry) => cacheEntry.parent_id === modelId && cacheEntry.model.file === fileName
        )

        console.assert(modelCacheItem, "Expecting to find modelCacheItem", {
            modelCache,
            modelId,
            fileName,
        })

        return modelCacheItem
    }

    const allSelectedElementIds = useMemo(() => {
        return [
            ...(highlightElementIds || []),
            ...(legendHighlightElementIds || []),
            ...(legendToggledElementIds.elementIds || []),
        ]
    }, [highlightElementIds, legendHighlightElementIds, legendToggledElementIds])

    const handleDrawRough = () => {
        const svg2roughjs = new Svg2Roughjs("#rough")
        svg2roughjs.svg = svgRef.current

        if (roughRef.current.firstChild) {
            while (roughRef.current.firstChild) {
                roughRef.current.removeChild(roughRef.current.firstChild)
            }
            setIsShowLineDrawing(false)
        } else {
            svg2roughjs.sketch()
            setIsShowLineDrawing(true)
        }
    }

    // Get Note-to-Element lines
    const getN2ELines = async (diagramDimensions, viewEls) => {
        if (!modelCacheItem) {
            console.log("%cmodel not found", "color:red")
        }

        const connections = _.flatten(
            viewEls.map((ve) =>
                ve.sourceConnections.map((sc) => {
                    const cnx = {
                        ...sc.connection,
                        bendpoints: sc.bendpoints?.map((bp) => {
                            return {
                                // If startX or startY are 0 then the 'startX' and 'startY' attributes are not present
                                startX: parseInt(bp.startX) || 0,
                                startY: parseInt(bp.startY) || 0,
                                endX: parseInt(bp.endX),
                                endY: parseInt(bp.endY),
                            }
                        }),
                    }

                    return cnx
                })
            )
        )

        //console.log("%cconnections", "color: orange", connections)

        // Note-to-Element connections
        const N2EConnections = connections.filter((c) => {
            // Is the target of this connection a child element?

            //TODO: Fix this. Doesn't render N2E lines correctly/at all

            const sourceViewElement = viewEls.find((ve) => ve.diagramObject.id === c.source)
            const targetViewElement = viewEls.find((ve) => ve.diagramObject.id === c.target)

            // const isTargetChildElement = viewEls.find((ve) =>
            //     ve.sourceConnections.find((sc) => sc.connection.id === c.target)
            // )

            return (
                sourceViewElement.diagramObject["xsi:type"] === "archimate:Note" &&
                targetViewElement &&
                targetViewElement.diagramObject.archimateElement !== undefined
            )
        })

        //console.log("%cN2EConnections", "color: orange", { N2EConnections, viewEls })

        const N2ELines = N2EConnections.map((c) => {
            // Get source and target bounds

            const sourceViewElement = viewEls.find((ve) => ve.diagramObject.id === c.source)
            const targetViewElement = viewEls.find((ve) => ve.diagramObject.id === c.target)

            const sourceBounds = sourceViewElement.bounds
            const targetBounds = targetViewElement.bounds

            const overlap = viewServices.getElementOverlap(sourceBounds, targetBounds)

            // const lineCoords = viewServices.getLineCoords(
            //     diagramDimensions,
            //     overlap,
            //     sourceBounds,
            //     targetBounds
            // )

            const bendpointCoords = c.bendpoints?.map((bp, index) => {
                const absoluteBp = {
                    x: bp.startX + sourceBounds.x + sourceBounds.width / 2,
                    y: bp.startY + sourceBounds.y + sourceBounds.height / 2,
                }

                return absoluteBp
            })

            const { bendpointLines, bendpointCoordsWithStartAndEndPoints } =
                calculateBendpointLineCoords(bendpointCoords, c, sourceBounds, targetBounds)

            const connectingLineCoords = viewServices.getLineCoords(
                diagramDimensions,
                overlap,
                sourceBounds,
                targetBounds
            )

            const N2ELine = {
                id: c.id,
                lineCoords: connectingLineCoords,
                //relation: relation,
                symbol: palette.symbols[palette.CONNECTION],

                // We don't handle bendpoint based lines from an element to a connect yet. This is a TODO
                bendpointLines: bendpointLines,
                bendpointCoords: bendpointCoordsWithStartAndEndPoints || [],
            }

            return N2ELine
        })

        return N2ELines.filter((item) => item !== undefined)
    }

    // Get Note-to-Connection lines
    const getN2CLines = async (diagramDimensions, viewEls) => {
        if (!modelCacheItem) {
            console.log("%cmodel not found, in getElementConnectionLines", "color:red")
        }

        const connections = _.flatten(
            viewEls.map((ve) =>
                ve.sourceConnections.map((sc) => {
                    const cnx = {
                        ...sc.connection,
                        bendpoints: sc.bendpoints?.map((bp) => {
                            return {
                                // If startX or startY are 0 then the 'startX' and 'startY' attributes are not present
                                startX: parseInt(bp.startX) || 0,
                                startY: parseInt(bp.startY) || 0,
                                endX: parseInt(bp.endX),
                                endY: parseInt(bp.endY),
                            }
                        }),
                    }
                    return cnx
                })
            )
        )

        // Note-to-Connection connections
        const N2CConnections = connections.filter((c) => {
            // Is the target of this connection a child element?

            const isTargetChildElement = viewEls.find((ve) =>
                ve.sourceConnections.find((sc) => sc.connection.id === c.target)
            )

            return isTargetChildElement !== undefined
        })

        const filteredN2CConnections = N2CConnections.filter(
            (c) => c.targetConnections === undefined
        )

        const N2CLines = filteredN2CConnections.map((c) => {
            const targetConnections = _.flatten(
                //view.elements.map((element) =>
                viewEls.map((element) =>
                    element.sourceConnections
                        .map((sc) => {
                            return sc.connection.id === c.target ? sc.connection : null
                        })
                        .filter((item) => item !== null)
                )
            )

            // Should only 1 match. This is the connection between the 2 elements that we need to connect into,
            // either the middle (for 1 segment connections) or mid point of multi-bendpoint lines.
            const targetConnection = targetConnections[0]

            // Get source and target elements for the connection we need to connect into,
            // so that we can work out the connection line co-ordinates, then find
            // the mid point (or otherwise if multiple bend points where to connect to)
            const sourceViewElement = viewEls.find(
                (ve) => ve.diagramObject.id === targetConnection.source
            )

            const targetViewElement = viewEls.find(
                (ve) => ve.diagramObject.id === targetConnection.target
            )

            const sourceElementBounds = sourceViewElement.bounds
            const targetElementBounds = targetViewElement.bounds

            const overlap = viewServices.getElementOverlap(sourceElementBounds, targetElementBounds)

            const lineCoords = viewServices.getLineCoords(
                diagramDimensions,
                overlap,
                sourceElementBounds,
                targetElementBounds
            )

            // Now that we know the end point to connect to, we need to determine where on the original source object we
            // begin the line from, and so need to determine the line coords from the source object to this new end point

            const srcViewElement = viewEls.find((ve) => ve.diagramObject.id === c.source)

            //FIXME: can't just get the mid point here, need to determine if the line has bendpoints and then find the middle of the bendpoint line
            const mid = lineCoords.getMidPoint()
            const midWidthOffset = {
                x: mid.x + diagramDimensions.widthOffset,
                y: mid.y + diagramDimensions.heightOffset,
            }
            const tgtBounds = {
                x: midWidthOffset.x,
                y: midWidthOffset.y,
                height: 1,
                width: 1,
            }

            const sourceBounds = srcViewElement.bounds

            const bendpointCoords = c.bendpoints?.map((bp, index) => {
                const absoluteBp = {
                    x: bp.startX + sourceBounds.x + sourceBounds.width / 2,
                    y: bp.startY + sourceBounds.y + sourceBounds.height / 2,
                }

                return absoluteBp
            })

            const { bendpointLines, bendpointCoordsWithStartAndEndPoints } =
                calculateBendpointLineCoords(bendpointCoords, c, sourceBounds, tgtBounds)

            const connectingLineCoords = viewServices.getLineCoords(
                diagramDimensions,
                overlap,
                srcViewElement.bounds,
                tgtBounds
            )

            const N2CLine = {
                id: c.id,
                lineCoords: connectingLineCoords,
                //relation: relation,
                symbol: palette.symbols[palette.CONNECTION],

                // We don't handle bendpoint based lines from an element to a connect yet. This is a TODO
                bendpointLines: bendpointLines,
                bendpointCoords: bendpointCoordsWithStartAndEndPoints || [],
            }

            return N2CLine
        })

        return N2CLines.filter((item) => item !== undefined)
    }

    // Get lines that connect an Archimate element to an Archimate relationship
    const getE2RLines = async (diagramDimensions, viewEls, modelCacheItem) => {
        // This is a model from the cache

        if (!modelCacheItem) {
            console.log("%cmodel not found, in getElementConnectionLines", "color:red")
        }

        const connections = _.flatten(
            viewEls.map((ve) =>
                ve.sourceConnections.map((sc) => {
                    const cnx = {
                        ...sc.connection,
                        bendpoints: sc.bendpoints?.map((bp) => {
                            return {
                                // If startX or startY are 0 then the 'startX' and 'startY' attributes are not present
                                startX: parseInt(bp.startX) || 0,
                                startY: parseInt(bp.startY) || 0,
                                endX: parseInt(bp.endX),
                                endY: parseInt(bp.endY),
                            }
                        }),
                    }

                    return cnx
                })
            )
        )

        // element-to-relationship connections
        const E2RConnections = connections.filter((c) => {
            // Is the target of this connection a child element?

            const isTargetChildElement = viewEls.find((ve) =>
                ve.sourceConnections.find((sc) => sc.connection.id === c.target)
            )

            return isTargetChildElement !== undefined
        })

        const filteredE2RConnections = E2RConnections.filter(
            (c) => c.targetConnections === undefined
        )

        const E2RLines = filteredE2RConnections.map((c) => {
            const targetConnections = _.flatten(
                //view.elements.map((element) =>
                viewEls.map((element) =>
                    element.sourceConnections
                        .map((sc) => {
                            return sc.connection.id === c.target ? sc.connection : null
                        })
                        .filter((item) => item !== null)
                )
            )

            // Should only 1 match. This is the connection between the 2 elements that we need to connect into,
            // either the middle (for 1 segment connections) or mid point of multi-bendpoint lines.
            const targetConnection = targetConnections[0]

            // Get source and target elements for the connection we need to connect into,
            // so that we can work out the connection line co-ordinates, then find
            // the mid point (or otherwise if multiple bend points where to connect to)
            const sourceViewElement = viewEls.find(
                (ve) => ve.diagramObject.id === targetConnection.source
            )

            const targetViewElement = viewEls.find(
                (ve) => ve.diagramObject.id === targetConnection.target
            )

            const sourceElementBounds = sourceViewElement.bounds
            const targetElementBounds = targetViewElement.bounds

            const overlap = viewServices.getElementOverlap(sourceElementBounds, targetElementBounds)

            const relation = modelCacheItem.model.elements.find(
                (el) => el.id === c.archimateRelationship
            )

            if (!relation) {
                return undefined
            }

            const lineCoords = viewServices.getLineCoords(
                diagramDimensions,
                overlap,
                sourceElementBounds,
                targetElementBounds
            )

            // Now that we know the end point to connect to, we need to determine where on the original source object we
            // begin the line from, and so need to determine the line coords from the source object to this new end point

            const srcViewElement = viewEls.find((ve) => ve.diagramObject.id === c.source)

            const mid = lineCoords.getMidPoint()
            const midWidthOffset = {
                x: mid.x + diagramDimensions.widthOffset,
                y: mid.y + diagramDimensions.heightOffset,
            }
            const tgtBounds = {
                x: midWidthOffset.x,
                y: midWidthOffset.y,
                height: 1,
                width: 1,
            }

            const connectingLineCoords = viewServices.getLineCoords(
                diagramDimensions,
                overlap,
                srcViewElement.bounds,
                tgtBounds
            )

            const eToCLine = {
                id: c.id,
                lineCoords: connectingLineCoords,
                relation: relation,
                symbol: modelServices.getPaletteItem(relation.type, "getElementToConnectionLines"),

                // We don't handle bendpoint based lines from an element to a connect yet. This is a TODO
                bendpointLines: [],
                bendpointCoords: [],
            }

            return eToCLine
        })

        return E2RLines.filter((item) => item !== undefined)
    }

    // Get the co-ordinates of the midpoint of a connection, which could be a single segment or a multi-segment line (with bendpoints)
    const getConnectionMidpointCoords = (c, diagramDimensions) => {
        if (c.bendpointCoords.length === 0) {
            const pos = {
                key: c.id,
                name: c.name,
                position: c.lineCoords.getMidPoint(),
            }

            return pos
        } else {
            if (c.bendpointCoords.length % 2 === 0) {
                // Even number of bendpoints

                const bendpointLine = c.bendpointLines[c.bendpointCoords.length / 2 - 1]

                const mid = {
                    x: (bendpointLine.x1 + bendpointLine.x2) / 2 - diagramDimensions.widthOffset,
                    y: (bendpointLine.y1 + bendpointLine.y2) / 2 - diagramDimensions.heightOffset,
                }

                const pos = {
                    key: c.id,
                    name: `${c.name}`,
                    position: mid,
                }

                return pos
            } else {
                // Odd number of bendpoints - get middle of middle bendpoint

                const middleBendpointCoord = c.bendpointCoords[(c.bendpointCoords.length - 1) / 2]

                const pos = {
                    key: c.id,
                    name: `${c.name}`,
                    position: {
                        x: middleBendpointCoord.x - diagramDimensions.widthOffset,
                        y: middleBendpointCoord.y - diagramDimensions.heightOffset,
                    },
                }

                return pos
            }
        }
    }

    // bendpoints is the series of x,y coordinates that sit within sourceBounds and targetBounds positions
    // return the full end to end set of x,y coordinates of bendpoint lines.
    // If there is 1 bendpoint, add 1 bendpoint at the start and end of the bendpointCoords array so we can
    // then just draw lines between the array of points, i.e. between 0->1, 1->2, 2->3, until we get
    // to the end of the array
    const calculateBendpointLineCoords = (bendpointCoords, c, sourceBounds, targetBounds) => {
        let bendpointCoordsWithStartAndEndPoints = []
        let bendpointLines = []

        if (bendpointCoords) {
            const point1 = { x: bendpointCoords[0].x, y: bendpointCoords[0].y }
            const pointN = {
                x: bendpointCoords[bendpointCoords.length - 1].x,
                y: bendpointCoords[bendpointCoords.length - 1].y,
            }

            const point1Result = viewServices.getBoundsConnectionPoint(point1, sourceBounds)

            const pointNResult = viewServices.getBoundsConnectionPoint(pointN, targetBounds)

            bendpointCoordsWithStartAndEndPoints = [
                point1Result,
                ...bendpointCoords,
                pointNResult,
            ].filter((item) => item !== undefined)

            bendpointLines = bendpointCoordsWithStartAndEndPoints.map((point, index) => {
                if (index > 0) {
                    const result = {
                        x1: bendpointCoordsWithStartAndEndPoints[index - 1].x,
                        y1: bendpointCoordsWithStartAndEndPoints[index - 1].y,
                        x2: point.x,
                        y2: point.y,
                        relation: c.relation,
                    }

                    if (
                        isNaN(result.x1) ||
                        isNaN(result.y1) ||
                        isNaN(result.x2) ||
                        isNaN(result.y2)
                    ) {
                        // console.log("%cNaN alert", "color:red", {
                        //     bendpointCoordsWithStartAndEndPoints,
                        // })
                    }

                    return result
                }
            })

            bendpointLines.shift()
        }
        return { bendpointLines, bendpointCoordsWithStartAndEndPoints }
    }

    const getLabelPositions = async (enhancedConnections, diagramDimensions) => {
        const labelPositions = enhancedConnections
            .filter((c) => c.lineCoords)
            .map((c) => {
                // The centre position for the label depends on if the line has bendpoints.
                // If no bendpoints, find the centre of 'lineCoords'
                // If has bendpoints, then:
                //   If even number of bendpoints, find end/start point of the 2 x middle coordinates, which should be the same
                //   If odd number of bendpoints, find middle of middle bendpoint coordinate

                const midpoint = getConnectionMidpointCoords(c, diagramDimensions)
                return midpoint
            })

        return labelPositions
    }

    const isContained = (srcBounds, tgtBounds) => {
        return (
            srcBounds.x > tgtBounds.x &&
            srcBounds.y > tgtBounds.y &&
            srcBounds.x + srcBounds.width < tgtBounds.x + tgtBounds.width &&
            srcBounds.y + srcBounds.height < tgtBounds.y + tgtBounds.height
        )
    }

    // Get element=to=element connections
    // We deal with element-to-connection connections later, e.g. business object into flow as a connection
    const getE2EConnections = async (view, connections, elements, modelCacheItem) => {
        if (view.CACHE[E2E_CONNECTIONS_CACHE_KEY]) {
            return view.CACHE[E2E_CONNECTIONS_CACHE_KEY]
        }

        const E2EConnections = connections.filter((c) => {
            // Is the target of this connection a child element?

            const isTargetChildElement = elements.find((ve) =>
                ve.sourceConnections.find((sc) => sc.connection.id === c.target)
            )

            return isTargetChildElement === undefined
        })

        // enhanced connection info
        const enhancedConnections = E2EConnections.map((c) => {
            // Get diagram object bounds from connection source and target

            const sourceViewElement = elements.find((ve) => ve.diagramObject.id === c.source)
            const targetViewElement = elements.find((ve) => ve.diagramObject.id === c.target)

            console.assert(targetViewElement, "Expecting targetViewElement", {
                elements,
                modelCacheItem,
            })

            // The source element definition for this connection, which contains it's name (label)
            const relElement = modelCacheItem.model.elements.find(
                (el) => el.id === c.archimateRelationship
            )

            const sourceBounds = sourceViewElement.bounds
            const targetBounds = targetViewElement.bounds

            // Overlap does not include where the source is wholly contained by the target or vice-versa
            // along the x- or y-axis. It's only where source and target
            const overlap = viewServices.getElementOverlap(sourceBounds, targetBounds)

            const relation = modelCacheItem.model.elements.find(
                (el) => el.id === c.archimateRelationship
            )

            if (relation === undefined) {
                return undefined
            }

            // The bendpoint x/y offsets are taken from the centre of the source (start) and target (end)
            // elements respectively, and these calculations give the same absolute result,
            // i.e. startX === endX and startY === endY, so we don't really seem to need both
            // when drawing bendpoint based lines.
            const bendpointCoords = c.bendpoints?.map((bp, index) => {
                const absoluteBp = {
                    x: bp.startX + sourceBounds.x + sourceBounds.width / 2,
                    y: bp.startY + sourceBounds.y + sourceBounds.height / 2,
                }

                return absoluteBp
            })

            const { bendpointLines, bendpointCoordsWithStartAndEndPoints } =
                calculateBendpointLineCoords(bendpointCoords, c, sourceBounds, targetBounds)

            const enhancedConnection = {
                ...c,
                // Default x+y to 0 ... sometimes they can be not provided (if 0)
                sourceBounds: sourceBounds,
                targetBounds: targetBounds,
                relation: relation,
                name: relElement?.name || "",
                sourceElement: sourceViewElement.element,
                targetElement: targetViewElement.element,
                position: viewServices.getPosition(sourceBounds, targetBounds),
                overlap: overlap,
                lineCoords: viewServices.getLineCoords(
                    view.dimensions,
                    overlap,
                    sourceBounds,
                    targetBounds
                ),
                bendpointCoords: bendpointCoordsWithStartAndEndPoints || [],
                bendpointLines: bendpointLines,
                symbol: modelServices.getPaletteItem(
                    relation ? relation.type : palette.CONNECTION,
                    "for enhancedConnections"
                ),
            }

            return enhancedConnection
        })
            // Remove wholly contained connections, since we don't draw lines between nested elements
            .filter((c) => c && c.position !== "contained")
            // xxxx - shouldn't have to filter out c.relation !=== undefined - this is where we have a Note element with a bendpoints connector
            .filter((c) => c.relation !== undefined)
            // Remove connections where visual nesting has been used
            .filter((c) => !isContained(c.sourceBounds, c.targetBounds))

        // Calculate label positions

        const [labelPositions, E2RLines] = await Promise.all([
            getLabelPositions(enhancedConnections, view.dimensions),
            getE2RLines(view.dimensions, elements, modelCacheItem),
        ])

        // This is a kludge for when we've used bendpoint lines, and those lines go outside the
        // already defined diagramDimensions.
        // The FIX is to defer applying diagramDimensions to coordinates until the last possible
        // moment, i.e. when rendering svg, so that we can prior to that check every diagram
        // element/line, etc, and get their position/dimensions to work out the true diagramDimensions
        // more fully.
        enhancedConnections.forEach((c) => {
            c.bendpointCoords.forEach((point) => {
                if (point.x > view.dimensions.width) {
                    view.dimensions.width = point.x
                }

                if (point.y > view.dimensions.height) {
                    console.log("%chigher", "color:orange", {
                        y: point.y,
                        height: view.dimensions.height,
                    })
                    view.dimensions.height = point.y
                }
            })
        })

        const result = {
            E2EConnections: enhancedConnections,
            labelPositions,
            E2RLines,
        }

        view.CACHE[E2E_CONNECTIONS_CACHE_KEY] = result

        return result
    }

    const render = async ({ modelCacheItem, view }) => {
        // Check if we should run this hook, only run if all params changed

        //const view = modelCacheItem.model.views.find((view) => view.id === viewId)

        if (view === undefined) {
            console.log("%cview not defined, returning", "color:orange")
            return
        }

        const viewElements = modelServices
            .getViewElements(view, modelCacheItem)
            .filter((item) => item !== undefined)

        const connections = _.flatten(
            viewElements.map((ve) => {
                //console.log("ve", ve)
                return ve.sourceConnections.map((sc) => {
                    const cnx = {
                        ...sc.connection,
                        bendpoints: sc.bendpoints?.map((bp) => modelServices.bendpointToInts(bp)),
                    }

                    return cnx
                })
            })
        )

        if (!view.CACHE) {
            view.CACHE = {}
        }

        getE2EConnections(view, connections, viewElements, modelCacheItem).then(async (result) => {
            const { E2EConnections, labelPositions, E2RLines } = result

            const calcN2CLines =
                view.CACHE[N2C_LINES_COUNT_CACHE_KEY] === undefined ||
                view.CACHE[N2C_LINES_COUNT_CACHE_KEY] > 0
            const calcN2ELines =
                view.CACHE[N2E_LINES_COUNT_CACHE_KEY] === undefined ||
                view.CACHE[N2E_LINES_COUNT_CACHE_KEY] > 0

            const [N2CLines, N2ELines] = await Promise.all([
                calcN2CLines && getN2CLines(view.dimensions, viewElements),
                calcN2ELines && getN2ELines(view.dimensions, viewElements),
            ])

            if (calcN2CLines) {
                view.CACHE[N2C_LINES_COUNT_CACHE_KEY] = N2CLines.length
            }

            if (calcN2ELines) {
                view.CACHE[N2E_LINES_COUNT_CACHE_KEY] = N2ELines.length
            }

            const newDiagramData = {
                labelPositions,
                view,
                diagramDimensions: view.dimensions,
                viewElements: viewElements,
                // Element-to-Element ilnes
                connections: E2EConnections,
                // Element-to-Relation lines
                elementToConnectionLines: E2RLines,
                // Note-to-Connector lines, joined using a 'Connection' symbol
                N2CLines: N2CLines || [],
                // Note-to-Element lines, joined using a 'Connection' symbol
                N2ELines: N2ELines || [],
            }

            setDiagramData(newDiagramData)
        })
    }

    useEffect(() => {
        if (!view) {
            return
        }

        const newLoadState = {
            viewId: view.id,
            modelId: modelCacheItem.id,
            fileName: modelCacheItem.fileName,
        }
        const isReload = !_.isEqual(loadState, newLoadState)
        if (!isReload) {
            // console.log("%cNot redrawing diagram", "color:orange", {
            //     loadState,
            //     newLoadState,
            // })
            return
        }
        setLoadState(newLoadState)

        // This is a model from the cache

        if (!modelCacheItem) {
            //console.log("%cmodel not found, in useEffect 2", "color:red")
        }

        if (modelCacheItem === undefined) {
            // console.log("%cUnable to find model in cache", "color:red", {
            //     useKeys: { modelCacheItem, modelId, fileName },
            // })
            return
        }

        render({ modelCacheItem, view })
    }, [modelCacheItem, view])

    const X_SVG_MARGIN = 10
    const Y_SVG_MARGIN = 10

    const getScaledHeight = (diagramDimensions) => {
        let width = maxWidth ? Math.min(maxWidth, diagramDimensions.width) : diagramDimensions.width

        let height = width
            ? (width / diagramDimensions.width) * diagramDimensions.height
            : diagramDimensions.height

        const widthScaling = width / diagramDimensions.width
        if (widthScaling > maxScale) {
            height = height * maxScale
        }

        return height
    }

    const handleElementHighlight = (elementId) => {
        setHighlights([elementId])
    }

    const handleElementDehighlight = (elementId) => {
        setHighlights([])
    }

    const getLabel = (item) => {
        switch (item.symbolName) {
            case "Note":
                return item.noteContent

            case "Group":
                return item.diagramObject.name

            default:
                return item.element ? item.element.name : "No name"
        }
    }

    const checkItem = (item) => {
        if (isNaN(item.bounds.x) || item.bounds.x === undefined) {
            console.log("%cNaN", "color:orange", item)
        } else {
            //console.log('%cOK', 'color:lightgreen', item.element?.name, item.element?.type, item?.diagramObject?.['xsi:type'])
        }
        return item
    }

    const handleCreateStory = () => {
        firebase
            .auth()
            .currentUser.getIdTokenResult()
            .then(async (token) => {
                const collection = {
                    project: "projects",
                    component: "components",
                }[modelCacheItem.type]

                const parent = await db.collection(collection).doc(modelCacheItem.parent_id).get()

                const newStory = {
                    name: "Story",
                    description: "",
                    account_id: token.claims.account_id,
                    user_id: token.claims.user_id,
                    parent_id: modelCacheItem.parent_id,
                    parent_name: parent.data().name,
                    type: modelCacheItem.type,
                    file_name: modelCacheItem.model.file,
                    view_id: view.id,
                    created: dataServices.localTimestamp(),
                    modified: dataServices.localTimestamp(),
                    items: [],
                }

                console.log("creating new story", newStory)

                db.collection("stories")
                    .add(newStory)
                    .then((docRef) => {
                        console.log("%ccreated story", "color:lightgreen", docRef.id)

                        // Toggle story edit page to be editable, so the name/description fields can be entered
                        dispatch(setEditMode(true))
                        history.push(`/Story/${docRef.id}`)
                    })

                // Update the parent of this story (project or component) to have a summary of it

                if (collection) {
                    // Update parent 'stories' attribute, which is a summary of stories

                    db.collection(collection)
                        .doc(modelCacheItem.parent_id)
                        .get()
                        .then((doc) => {
                            const existingStories = doc.data().stories || []
                            const newStoriesSummary = [
                                ...existingStories,
                                { id: doc.id, name: newStory.name },
                            ]

                            db.collection(collection).doc(modelCacheItem.parent_id).update(
                                {
                                    stories: newStoriesSummary,
                                    modified: dataServices.localTimestamp(),
                                },
                                { merge: true }
                            )
                        })
                }
            })
    }

    const handleOpenRule = (ruleId) => {
        if (ruleId) {
            history.push(`/RuleEdit/${ruleId}`)
        } else {
            console.error("Expecting ruleId", { ruleId })
        }
    }

    const svgRef = useRef()

    const roughRef = useRef()

    const handleToggleSelectedLegendElementTypeIds = (elementType, elementIds) => {
        if (legendToggledElementIds.type === elementType) {
            setLegendToggledElementIds({
                type: "",
                elementIds: [],
            })
        } else {
            setLegendToggledElementIds({
                type: elementType,
                elementIds: elementIds,
            })
        }
    }

    return (
        <Box>
            {!diagramData && <Skeleton variant="rectangular" height={200} width={200} />}

            <Box>
                {/*
                {diagramData && (
                    <svg
                        ref={svgRef}
                        height={getScaledHeight(diagramData.diagramDimensions) + Y_SVG_MARGIN || 0}
                        width={getScaledWidth(diagramData.diagramDimensions) + X_SVG_MARGIN || 0}
                        viewBox={`0 0 ${diagramData.diagramDimensions.width + X_SVG_MARGIN || 0} ${
                            diagramData.diagramDimensions.height + Y_SVG_MARGIN || 0
                        }`}
                        sx={styles.svg}
                    >
                        {diagramSvg}
                    </svg>
                )}
                */}
            </Box>

            <Box
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                }}
            >
                {diagramData && (
                    <svg
                        ref={svgRef}
                        height={
                            getScaledHeight(diagramData.diagramDimensions) +
                                Y_SVG_MARGIN +
                                PADDING || 0
                        }
                        width={
                            getScaledWidth(diagramData.diagramDimensions) +
                                X_SVG_MARGIN +
                                PADDING || 0
                        }
                        viewBox={`${-PADDING} ${-PADDING} ${
                            diagramData.diagramDimensions.width + X_SVG_MARGIN + PADDING || 0
                        } ${diagramData.diagramDimensions.height + Y_SVG_MARGIN + PADDING || 0}`}
                        sx={styles.svg}
                    >
                        {/* white background for image, so when we copy/paste it there isn't black background */}
                        <rect
                            x={-(X_SVG_MARGIN + PADDING)}
                            y={-(Y_SVG_MARGIN + PADDING)}
                            fill={diagramBackgroundColor}
                            stroke={diagramBorderColor}
                            width={diagramData.diagramDimensions.width + X_SVG_MARGIN + PADDING * 2}
                            height={
                                diagramData.diagramDimensions.height + Y_SVG_MARGIN + PADDING * 2
                            }
                        />
                        {diagramData.viewElements &&
                            diagramData.viewElements
                                .map((item) => checkItem(item))
                                .map((item) => {
                                    // Is there a base reason to highlight the element, i.e.
                                    // excluding the code below to dim (highlight in grey) elements
                                    // that are not selected?
                                    const highlightBase =
                                        allSelectedElementIds.includes(item?.element?.id) ||
                                        // ...or if there are validation messages relating to the element
                                        (showRules &&
                                            messages &&
                                            item?.element?.id !== null &&
                                            item?.element?.id !== undefined &&
                                            highlights.includes(item?.element?.id) &&
                                            messages.find(
                                                (message) =>
                                                    message.element.id === item?.element?.id
                                            ))

                                    // Should we highlight this element, either base it has been
                                    // explicitly selected for highlighting, or because
                                    // we want to dim it (highlight in grey)
                                    const highlight = highlightBase
                                        ? highlightBase
                                        : dimElementIfNotHighlighted

                                    // What is the final highlight color, being either the selected
                                    // color, or grey if we're just seeking to dim it
                                    const highlightColor =
                                        dimElementIfNotHighlighted && !highlightBase
                                            ? "#f4f4f4"
                                            : elementColors.find(
                                                  (element) => element.id === item?.element?.id
                                              )?.color

                                    //console.log("%cdoco", "color:pink", { element: item.element })

                                    return (
                                        <item.symbol
                                            // xsi:type is in the form 'xsi:<archimate symbol name>'
                                            // This prop is for debugging and can be removed
                                            // It is displayed in the GenericSymbol only.
                                            type={
                                                item.element?.type ||
                                                item.diagramObject["xsi:type"].split(":")[1]
                                            }
                                            key={item.diagramObject.id}
                                            elementId={item?.element?.id}
                                            x={
                                                item.bounds.x -
                                                diagramData.diagramDimensions.widthOffset
                                            }
                                            y={
                                                item.bounds.y -
                                                diagramData.diagramDimensions.heightOffset
                                            }
                                            width={item.bounds.width}
                                            height={item.bounds.height}
                                            // Using the label={item.label} approach isn't working for
                                            // Notes or Views.

                                            label={showLabels ? getLabel(item) : ""}
                                            showLabel={showLabels}
                                            documentation={item.element?.documentation}
                                            properties={item.element?.properties}
                                            // Only present for 'or' Junctions
                                            junctionType={item.element?.junctionType}
                                            // For normal elements, this link will go to that element,
                                            // whereas for DiagramModelReference diagram elements the
                                            // linkinfo will navigate to another view (rather than an element)
                                            linkInfo={item.linkInfo}
                                            // click on an element or connector
                                            handleClickItem={handleClickItem}
                                            highlight={highlight}
                                            // This is the color to use if the above highlight prop is true
                                            // It is usually driven of the StoryEdit page that provides for variable color highlighting
                                            highlightColor={highlightColor}
                                            handleElementMouseOver={() =>
                                                handleElementMouseOver &&
                                                handleElementMouseOver(item?.element)
                                            }
                                            handleElementMouseOut={() =>
                                                handleElementMouseOut &&
                                                handleElementMouseOut(item?.element)
                                            }
                                            // Fill color as set in the model. Can be blank if default color used.
                                            fillColor={item.diagramObject.fillColor}
                                            showDocumentationIndicator={showDocumentation}
                                            showTooltips={showTooltips}
                                        />
                                    )
                                })}
                        {/* We only draw the connection lineCoords if there are no bendpointLines, 
                                            i.e. 1 or the other, not both 
                                            Simple straight lines with no bends are drawn with 'lineCoords' 
                                            otherwise (see below) we use 'bendpointLines' */}
                        {diagramData.connections &&
                            diagramData.connections
                                .filter((connection) => connection.bendpointLines.length === 0)
                                .map((connection) => (
                                    <connection.symbol
                                        key={connection.id}
                                        elementId={connection.id}
                                        {...connection.lineCoords}
                                        showStartMarker={true}
                                        showEndMarker={true}
                                        // Only present for 'Access' relations
                                        // Note present for 'Connection' types, that link Notes to other elements
                                        accessType={connection.relation?.accessType}
                                        name={connection.name}
                                        handleClickItem={handleClickItem}
                                        handleElementMouseOver={() =>
                                            handleElementMouseOver &&
                                            handleElementMouseOver(connection?.relation)
                                        }
                                        handleElementMouseOut={() =>
                                            handleElementMouseOut &&
                                            handleElementMouseOut(connection?.relation)
                                        }
                                        lineColor={connection.lineColor}
                                    />
                                ))}
                        {diagramData.connections &&
                            diagramData.connections
                                .filter((connection) => connection.bendpointLines)
                                .map((connection) =>
                                    connection.bendpointLines.map((line, index) => (
                                        <connection.symbol
                                            key={`${connection.id}-line-${index}`}
                                            elementId={connection.id}
                                            showStartMarker={index === 0}
                                            showEndMarker={
                                                index === connection.bendpointLines.length - 1
                                            }
                                            x1={line.x1 - diagramData.diagramDimensions.widthOffset}
                                            y1={
                                                line.y1 - diagramData.diagramDimensions.heightOffset
                                            }
                                            x2={line.x2 - diagramData.diagramDimensions.widthOffset}
                                            y2={
                                                line.y2 - diagramData.diagramDimensions.heightOffset
                                            }
                                            firstLineSegment={index === 0}
                                            lastLineSegment={
                                                index === connection.bendpointLines.length - 1
                                            }
                                            // Only present for 'Access' relations
                                            accessType={connection.relation.accessType}
                                            name={connection.name}
                                            handleClickItem={handleClickItem}
                                            handleElementMouseOver={() =>
                                                handleElementMouseOver &&
                                                handleElementMouseOver(connection?.relation)
                                            }
                                            handleElementMouseOut={() =>
                                                handleElementMouseOut &&
                                                handleElementMouseOut(connection?.relation)
                                            }
                                            lineColor={connection.lineColor}
                                        />
                                    ))
                                )}
                        {diagramData.labelPositions &&
                            diagramData.labelPositions.map((labelPos) => (
                                <ElementText
                                    key={labelPos.key}
                                    label={labelPos.name}
                                    showLabel={showLabels}
                                    // The length * 2.5 is a bit of a kludge to adjust the x-pos of the label based on how wide it is.
                                    // There is probably a more accurate way to do it based on the width of each letter, rather than
                                    // assume each letter takes up 2.5 pixels in the x-direction
                                    x={labelPos.position.x - labelPos.name.length * 2.5}
                                    // Change vertical position of label
                                    y={labelPos.position.y - 20}
                                    width={200}
                                    height={20}
                                />
                            ))}
                        {diagramData.elementToConnectionLines &&
                            diagramData.elementToConnectionLines
                                .filter((connection) => connection.bendpointLines.length === 0)
                                .map((connection) => (
                                    <connection.symbol
                                        key={connection.id}
                                        {...connection.lineCoords}
                                    />
                                ))}
                        {diagramData.N2CLines &&
                            diagramData.N2CLines.filter(
                                (connection) => connection.bendpointLines.length === 0
                            ).map((connection) => {
                                return (
                                    <connection.symbol
                                        key={connection.id}
                                        {...connection.lineCoords}
                                    />
                                )
                            })}
                        {diagramData.N2CLines &&
                            diagramData.N2CLines.filter(
                                (connection) => connection.bendpointLines
                            ).map((connection) =>
                                connection.bendpointLines.map((line, index) => (
                                    <connection.symbol
                                        key={`${connection.id}-line-${index}`}
                                        x1={line.x1 - diagramData.diagramDimensions.widthOffset}
                                        y1={line.y1 - diagramData.diagramDimensions.heightOffset}
                                        x2={line.x2 - diagramData.diagramDimensions.widthOffset}
                                        y2={line.y2 - diagramData.diagramDimensions.heightOffset}
                                        firstLineSegment={index === 0}
                                        lastLineSegment={
                                            index === connection.bendpointLines.length - 1
                                        }
                                    />
                                ))
                            )}
                        {diagramData.N2ELines &&
                            diagramData.N2ELines.filter(
                                (connection) => connection.bendpointLines.length === 0
                            ).map((connection) => {
                                return (
                                    <connection.symbol
                                        key={connection.id}
                                        {...connection.lineCoords}
                                    />
                                )
                            })}
                        {diagramData.N2ELines &&
                            diagramData.N2ELines.filter(
                                (connection) => connection.bendpointLines
                            ).map((connection) =>
                                connection.bendpointLines.map((line, index) => (
                                    <connection.symbol
                                        key={`${connection.id}-line-${index}`}
                                        x1={line.x1 - diagramData.diagramDimensions.widthOffset}
                                        y1={line.y1 - diagramData.diagramDimensions.heightOffset}
                                        x2={line.x2 - diagramData.diagramDimensions.widthOffset}
                                        y2={line.y2 - diagramData.diagramDimensions.heightOffset}
                                        firstLineSegment={index === 0}
                                        lastLineSegment={
                                            index === connection.bendpointLines.length - 1
                                        }
                                    />
                                ))
                            )}
                        {/* Put any diagram overlays here */}
                        {/* {storyLabel && <NumberedCircle x={10} y={10} label={storyLabel} />} */}
                        {numberedCircles &&
                            numberedCircles.map((circle) => (
                                <NumberedCircle
                                    key={`${circle.id}-${circle.label}`}
                                    x={circle.x}
                                    y={circle.y}
                                    label={circle.label}
                                />
                            ))}
                    </svg>
                )}
            </Box>
            <Box>
                {autoLegend && (
                    <DiagramLegend
                        model={modelCacheItem}
                        view={view}
                        autoLegend={autoLegend}
                        setLegendHighlightElementIds={setLegendHighlightElementIds}
                        handleToggleSelectedLegendElementTypeIds={
                            handleToggleSelectedLegendElementTypeIds
                        }
                        selectedLegendElementType={legendToggledElementIds.type}
                        diagramBackgroundColor={diagramBackgroundColor}
                        diagramBorderColor={diagramBorderColor}
                    />
                )}
                {(showCopyButton || showCreateStoryButton) && (
                    <>
                        <DiagramButtons
                            handleCreateStory={handleCreateStory}
                            svgRef={svgRef}
                            showCreateStoryButton={showCreateStoryButton}
                            handleCreateChatPrompt={handleCreateChatPrompt}
                            handleShowChat={handleShowChat}
                            showCopyButton={showCopyButton}
                            handleCreateAIDesignerRef={handleCreateAIDesignerRef}
                            showCreateRefButton={showCreateRefButton}
                            handleDrawRough={handleDrawRough}
                            handleDownloadSVG={handleDownloadSVG}
                        />

                        <Box id="rough" ref={roughRef}></Box>
                        {isShowLineDrawing && (
                            <Controls.Button
                                endIcon={<DownloadIcon />}
                                onClick={() => handleDownloadSVG(roughRef)}
                                text="SVG"
                                tooltip="Download image as SVG"
                            />
                        )}
                    </>
                )}
                {/* [showRules: {JSON.stringify(showRules)}, messages: {messages ? messages.length : -1}
                ] */}
                {showRules && messages && messages.length > 0 && (
                    <Box>
                        <Heading>Rules</Heading>

                        {messages && (
                            <List dense>
                                {messages.map((message) => (
                                    <ListItem
                                        sx={styles.ruleMessage}
                                        button
                                        key={`${message.id}-${view.id}`}
                                        onMouseOver={() => {
                                            if (message && message.element) {
                                                handleElementHighlight(message.element.id)
                                            }
                                        }}
                                        onMouseOut={() => {
                                            if (message && message.element) {
                                                handleElementDehighlight(message.element.id)
                                            }
                                        }}
                                        onClick={() => {
                                            history.push(
                                                urlServices.createElementViewUrl({
                                                    parentId: modelCacheItem.parent_id,
                                                    fileName: modelCacheItem.model.file,
                                                    elementId: message.element.id,
                                                })
                                            )
                                        }}
                                    >
                                        <ListItemIcon>
                                            <RuleIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography variant="caption">
                                                    {message.msg}
                                                </Typography>
                                            }
                                            sx={
                                                constrainRuleWidth && {
                                                    maxWidth: 300,
                                                }
                                            }
                                        />
                                        <ListItemSecondaryAction>
                                            {message.isMlRule ? (
                                                <SmartToyIcon
                                                    sx={{
                                                        color: colors.green[500],
                                                    }}
                                                />
                                            ) : (
                                                <IconButton
                                                    edge="end"
                                                    aria-label="open rule"
                                                    onClick={() => handleOpenRule(message.ruleId)}
                                                >
                                                    <Tooltip title="Open rule">
                                                        <EditIcon />
                                                    </Tooltip>
                                                </IconButton>
                                            )}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                            </List>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    )
}

const NumberedCircle = (props) => {
    const { x, y, label } = props

    const labelX = label < 10 ? -13 : -15
    return (
        <>
            <circle cx={x} cy={y} r={10} fill={colors.blue[500]} stroke={colors.blue[500]} />
            <g fontFamily={FONT_FAMILY} transform={`translate(10,10)`}>
                <text fontSize={10}>
                    <tspan x={labelX + x} y={y - 6} stroke="#fff" strokeWidth={0.75}>
                        {label}
                    </tspan>
                </text>
            </g>
        </>
    )
}

const DiagramLegend = (props) => {
    const {
        model,
        view,
        autoLegend,
        setLegendHighlightElementIds,
        handleToggleSelectedLegendElementTypeIds,
        // The legend element type that has been clicked on
        selectedLegendElementType,
        diagramBackgroundColor,
        diagramBorderColor,
    } = props

    const { width } = useWindowDimensions()

    const { enqueueSnackbar } = useSnackbar()

    const [legendElements, setLegendElements] = useState()

    useEffect(() => {
        if (autoLegend && view && width) {
            findAutoLegendElements(view)
        }
    }, [autoLegend, view, width])

    const legendSvgRef = useRef()
    const legendCanvasRef = useRef()
    const legendImgRef = useRef()

    const legendSvgToCanvas = () => {
        var xml = new XMLSerializer().serializeToString(legendSvgRef.current)
        console.log("%cLegend SVG", "color:orange", xml)

        //var svg64 = btoa(xml)

        const utf8Array = new TextEncoder().encode(xml)

        // Convert the Uint8Array to a binary string
        const binaryString = utf8Array.reduce((acc, byte) => acc + String.fromCharCode(byte), "")

        var svg64 = btoa(binaryString)

        var b64Start = "data:image/svg+xml;base64,"

        // prepend a "header"
        var image64 = b64Start + svg64

        // set it as the source of the img element
        legendImgRef.current.src = image64

        console.log("legend", { legendCanvasRef, legendImgRef })

        // draw the image onto the canvas
        legendCanvasRef.current.getContext("2d").drawImage(legendImgRef.current, 0, 0)

        enqueueSnackbar("Legend copied to clipboard", { variant: "success" })
    }

    // Get unique elements types from this view
    const findAutoLegendElements = (view) => {
        //console.log("%cgetAutoLegendElements", "color:orange", { view })

        const archimateElements = view.elements.map((el) => el.diagramObject.archimateElement)
        // console.log("%carchimateElements", "color:orange", {
        //     archimateElements,
        // })

        const elements = model.model.elements.filter((el) => archimateElements.includes(el.id))

        const elementTypes = Array.from(new Set(elements.map((el) => el.type)))

        // console.log("%celementTypes", "color:orange", {
        //     model,
        //     elements,
        //     elementTypes,
        // })

        const legendRowHeight = 50

        const legendGapPerRow = 10

        // 90 is a magic number found by trial and error and changing the width of the browser and observing how nicely the legend elements wrap to new rows when the width changes
        const usableWidth = width - 90

        // Reduce width down to nearest multiple of LEGEND_ELEMENT_X_SPACE
        const legendWidth =
            Math.floor(usableWidth / LEGEND_ELEMENT_X_SPACE) * LEGEND_ELEMENT_X_SPACE

        const legendRows = Math.ceil((elementTypes.length * LEGEND_ELEMENT_X_SPACE) / usableWidth)

        // console.log("widths", {
        //     usableWidth,
        //     width,
        //     legendWidth,
        //     legendRows,
        //     height: legendRows * legendRowHeight,
        // })

        const legendInfo = {
            rows: legendRows,
            height: legendRows * (legendRowHeight + legendGapPerRow),
            elements: elementTypes.map((type, index) => {
                const row = Math.floor((index * LEGEND_ELEMENT_X_SPACE) / legendWidth)

                const xPos = (index * LEGEND_ELEMENT_X_SPACE) % legendWidth
                const yPos = row * (legendRowHeight + legendGapPerRow)

                //console.log("%crow", "color:orange", { type, row, xPos, yPos })

                return {
                    type: type,
                    label: type,
                    symbol: modelServices.getPaletteItem(type, "findAutoLegendElements"),
                    documentation: [palette.getElementType(type)?.description?.join(" ") || ""],
                    x: xPos,
                    y: yPos,
                }
            }),
        }

        setLegendElements(legendInfo)
    }

    const getElementTypeIds = (elementType) => {
        const elements = model.model.elements.filter((el) => el.type === elementType)
        return elements.map((el) => el.id)
    }

    const handleElementMouseOver = (elementType) => {
        //console.log("%cmouseover", "color:orange", elementType)
        setLegendHighlightElementIds(getElementTypeIds(elementType))
    }

    const handleElementMouseOut = (elementType) => {
        //console.log("%cmouseout", "color:orange", elementType)
        setLegendHighlightElementIds([])
    }

    const handleClickItem = (elementType) => {
        //console.log("%cclick", "color:orange", elementType)
        handleToggleSelectedLegendElementTypeIds(elementType, getElementTypeIds(elementType))
    }

    const WIDTH_REDUCTION = 100

    const LEGEND_ELEMENT_X_SPACE = 120

    return (
        <>
            <img
                style={{ display: "none" }}
                ref={legendImgRef}
                alt="legend"
                onLoad={() => {
                    // The image is loaded above, but takes some time so we need
                    // to drive the copy to clipboard command of this image loaded event.
                    // This is done by calling the svgToCanvas function above.

                    getBlobFromImageElement(legendImgRef.current)
                        .then((blob) => {
                            console.log("%cblob", "color:orange", blob)
                            return copyBlobToClipboard(blob)
                        })
                        .then(() => {
                            console.log("Blob Copied")
                        })
                        .catch((e) => {
                            console.log("Error: ", e.message)
                        })
                }}
            />

            <canvas ref={legendCanvasRef} style={{ display: "none" }}></canvas>
            {model && view && (
                <Box sx={{ marginTop: "10px", marginLeft: "10px" }}>
                    <Box sx={styles.legendHeader}>
                        <Typography variant="caption">Legend</Typography>
                        <Tooltip title="Copy legend to clipboard">
                            <IconButton onClick={legendSvgToCanvas}>
                                <AssignmentIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>

                    {legendElements && (
                        <svg
                            ref={legendSvgRef}
                            height={legendElements.height}
                            width={width - WIDTH_REDUCTION}
                            viewBox={`0 0 ${width - WIDTH_REDUCTION} ${legendElements.height}`}
                            sx={styles.svg}
                        >
                            {/* white background for image, so when we copy/paste it there isn't black background */}
                            <rect
                                x={0}
                                y={0}
                                fill={diagramBackgroundColor}
                                stroke={diagramBorderColor}
                                width={width - WIDTH_REDUCTION}
                                height={legendElements.height}
                            />
                            {legendElements.elements.map((element, index) => (
                                <element.symbol
                                    key={element.type}
                                    x={element.x}
                                    y={element.y + 5}
                                    height={50}
                                    width={110}
                                    label={`   ${element.label
                                        .match(/[A-Z][a-z]+|[0-9]+/g)
                                        .join(" ")}`}
                                    showLabel={true}
                                    documentation={element.documentation}
                                    showTooltips={true}
                                    handleElementMouseOver={() =>
                                        handleElementMouseOver(element.type)
                                    }
                                    handleElementMouseOut={() =>
                                        handleElementMouseOut(element.type)
                                    }
                                    handleClickItem={() => handleClickItem(element.type)}
                                    highlight={element.type === selectedLegendElementType}
                                />
                            ))}
                        </svg>
                    )}
                </Box>
            )}
        </>
    )
}

export default Diagram
