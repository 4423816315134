import React from "react"
import { Grid, Typography } from "@mui/material"
import { spacing } from "../pages/services/styleServices"

const styles = {
    label: {
        marginTop: spacing(2),
    },
}

const GridLabel = (props) => {
    return (
        <Grid item sx={styles.label}>
            <Typography variant="body2" color="textSecondary" component={"span"}>
                {props.children}
            </Typography>
        </Grid>
    )
}

export default GridLabel
