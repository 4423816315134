import React from "react"
import Header from "../components/Header"
import { Box } from "@mui/material"
import TemplateGrid from "../components/TemplateGrid"

const Templates = () => {
  return (
    <Header title="Templates">
      <Box padding={1}>
        <TemplateGrid />
      </Box>
    </Header>
  )
}

export default Templates
