import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material"
import Controls from "./controls/Controls"
import * as palette from "./symbols/palette"
import { useEffect, useState } from "react"

const ModelEditQuestionDialog = ({ open, onClose, values, handleSave }) => {
  const [elementType, setElementType] = useState()

  const [newQuestion, setNewQuestion] = useState(values)

  useEffect(() => {
    if (values.element_types.length > 0) {
      setElementType(values.element_types[0])
    }
  }, [values])

  const layers = palette.LAYERS.filter(
    (layer) => layer.name !== palette.LAYER_NAME_OTHER
  ).map((layer) => ({
    id: layer.name,
    title: layer.name,
  }))

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Question</DialogTitle>
      <DialogContent sx={{ width: "400px" }}>
        <Stack sx={{ mt: "10px" }} gap={2}>
          <Controls.TextInput
            name="question"
            label="Question"
            autoFocus
            multiline
            value={newQuestion.question}
            onChange={(e) =>
              setNewQuestion((curr) => ({ ...curr, question: e.target.value }))
            }
          />
          <Controls.TextInput
            name="rationale"
            label="Rationale"
            value={newQuestion.rationale}
            onChange={(e) =>
              setNewQuestion((curr) => ({ ...curr, rationale: e.target.value }))
            }
          />
          {values.element_types.join(", ")}
          <Controls.Select
            name="layer"
            label="Layer"
            value={newQuestion.layer}
            options={layers}
            onChange={(e) =>
              setNewQuestion((curr) => ({ ...curr, layer: e.target.value }))
            }
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          sx={{ textTransform: "none" }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleSave(newQuestion)
          }}
          sx={{ textTransform: "none" }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModelEditQuestionDialog
