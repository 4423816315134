import React from "react"
import * as common from "./common"

const BasicShapeWavyBox = (props) => {
    const { x, y, width, height, fill } = props

    const xOffset = x + 1
    return (
        <path
            d={`M${xOffset},${y + 1} h${width} v ${height - 20} L ${xOffset + width} ${
                y + height - 5
            } C ${xOffset + width - 20} ${y + height - 5}, ${xOffset + width - 30} ${
                y + height / 2 + 10
            }, ${xOffset + width / 2} ${y + height - 10} S ${xOffset + 20} ${
                y + height + 5
            }, ${xOffset} ${y + height - 10} z`}
            fill={fill}
            stroke={common.outlineColor}
        />
    )
}

export default BasicShapeWavyBox
