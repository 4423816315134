import * as palette from "../../components/symbols/palette"
import * as modelServices from "./modelServices"

const RELATIONSHIP_DESCRIPTORS = {
  [palette.TRIGGERING_RELATIONSHIP]: "triggers",
  [palette.ASSOCIATION_RELATIONSHIP]: "is associated with",
  [palette.COMPOSITION_RELATIONSHIP]: "composes",
  [palette.AGGREGATION_RELATIONSHIP]: "aggregates",
  [palette.ACCESS_RELATIONSHIP]: "accesses",
  [palette.REALIZATION_RELATIONSHIP]: "realizes",
  [palette.FLOW_RELATIONSHIP]: "flows to",
  [palette.ASSIGNMENT_RELATIONSHIP]: "assigns",
  [palette.SERVING_RELATIONSHIP]: "serves",
  [palette.INFLUENCE_RELATIONSHIP]: "influences",
}

const createChatPrompt = ({
  promptData,
  promptLayers,
  includeProperties,
  includeDoco,
  includeIds = true,
}) => {
  //console.log("%ccreate chat prompt", "color:lightgreen", promptData)

  const prompt = []

  const shortIds = {}

  let shortIdCount = 1

  // Return a short id for an element. Short ids start at 1, 2, 3, .... Check if a long id exists first before creating a new short id
  const getShortId = (longId) => {
    if (shortIds[longId]) {
      return shortIds[longId]
    }

    const shortId = `ID:${shortIdCount++}`
    shortIds[longId] = shortId
    return shortId
  }

  prompt.push(
    `The following information is for an Architecture view called '${promptData.view.name}'.`
  )

  const getLabel = (el, includeLabel = true) => {
    if (includeIds) {
      const label = includeLabel ? `(${palette.formatLabel(el.type)}) ` : ""
      return `'${el.name}' ${label}[${getShortId(el.id)}]`
    }
    return el.name
  }

  const { elements } = promptData

  /**
   *
   * @param {*} elementRef | Must have a type and name
   * @param {*} elements
   * @returns
   */
  const getLabelForElementRef = (elementRef) => {
    const el = elements.find(
      (el) => el.name === elementRef.name && el.type === elementRef.type
    )
    if (el) {
      return getLabel(el)
    }
    return `${elementRef.type} ${elementRef.name} - NOT FOUND`
  }

  addLocations(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )

  // Strategy
  addResources(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addCapabilities(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel,
    getShortId
  )
  addValueStreams(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addCoursesOfAction(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )

  // Motivation
  addStakeholders(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addDrivers(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addAssessments(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addGoals(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addOutcomes(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addPrinciples(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addRequirements(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addConstraints(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addMeaning(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addValues(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )

  // Business
  addActors(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  // addBusinessRoles(
  //   elements,
  //   promptLayers,
  //   prompt,
  //   includeProperties,
  //   includeDoco,
  //   getLabel
  // )
  addBusinessBehaviour(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addBusinessFunctions(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addBusinessCollaborations(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addBusinessInteractions(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addBusinessEvents(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addBusinessServices(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addBusinessObjects(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addContracts(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addRepresentations(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addProducts(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addBusinessFlows(
    elements,
    promptLayers,
    prompt,
    getLabel,
    getLabelForElementRef
  )
  addBusinessInformationUsage(
    elements,
    promptLayers,
    prompt,
    includeDoco,
    getLabel
  )

  // Other
  addGroupings(elements, promptLayers, prompt)

  // Application
  addApplicationComponents(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addApplicationFlows(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addApplicationServices(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addApplicationCollaborations(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addApplicationInterfaces(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addApplicationFunctions(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addApplicationInteractions(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addApplicationProcesses(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addApplicationEvents(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addDataObjects(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )

  // Technology
  addNodes(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addDevices(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addSystemSoftware(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addTechnologyCollaborations(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addTechnologyInterfaces(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addPaths(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addCommunicationNetworks(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addTechnologyFunctions(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addTechnologyProcesses(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addTechnologyInteractions(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addTechnologyEvents(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addTechnologyServices(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addArtifacts(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )

  // Physical
  addEquipment(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addFacilities(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addDistributionNetworks(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addMaterials(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )

  // Implementation and Migration
  addWorkPackages(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addPlateaus(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addDeliverables(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addImplementationEvents(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )
  addGaps(
    elements,
    promptLayers,
    prompt,
    includeProperties,
    includeDoco,
    getLabel
  )

  // View notes
  addNotes(promptData.notes, elements, promptLayers, prompt)

  //console.log("%cadded elements", "color:lightgreen", { elements: elements.map((el) => el.name) })

  return prompt
}

const getChildElements = (element, elements) => {
  const children = elements.flatMap((el) =>
    el.connections.filter(
      (c) => c.target.name === element.name && c.target.type === element.type
    )
  )
  // Find children by name and type
  if (children.length > 0) {
    console.log(`children %c${element.name}`, "color:lightgreen", {
      element,
      children,
    })
    const childElements = elements.filter((el) =>
      children.find(
        (c) => c.source.name === el.name && c.source.type === el.type
      )
    )

    return childElements
  }
  return []
}

// const getParentElements = (element, elements) => {
//   const parents = element.connections.map((c) => c)
//   // Find parents by name and type
//   const parentElements = elements.filter((el) =>
//     parents.find((p) => p.target.name === el.name && p.target.type === el.type)
//   )

//   return parentElements
// }

const addNotes = (notes, elements, promptLayers, prompt) => {
  //console.log("%cadding notes", "color:lightgreen", { notes })

  if (notes.length > 0) {
    prompt.push(
      "There are notes attached to the diagram. These are used to provide additional information about the diagram."
    )
    notes.forEach((note) => {
      const lines = [`Note: ${note.note.noteContent}`]

      if (note.elements.length > 0) {
        lines.push(
          `attached to: ${note.elements
            .map((el) => el.element.name)
            .join(", ")}`
        )
      }

      prompt.push(lines.join(", "))
    })
  }
}

const addBusinessObjects = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_BUSINESS)) {
    return
  }

  const businessObjects = elements
    .filter((el) => el.type === palette.BUSINESS_OBJECT)
    .map((el, index) => ({
      position: index + 1,
      ...el,
    }))

  if (businessObjects.length > 0) {
    prompt.push(
      `There ${plural(businessObjects.length, "is", "are")} ${
        businessObjects.length
      } business object${
        businessObjects.length > 1 ? "s" : ""
      } in the diagram, which is information that can be created, consumed or exchanged in order to achieve outcomes.`
    )

    businessObjects.forEach((businessObject, index) => {
      const childData = elements
        .filter((el) => el.type === palette.BUSINESS_OBJECT)
        .flatMap((el) =>
          el.connections.filter(
            (c) =>
              c.source.name === businessObject.name &&
              c.source.type === palette.BUSINESS_OBJECT &&
              [
                palette.AGGREGATION_RELATIONSHIP,
                palette.COMPOSITION_RELATIONSHIP,
              ].includes(c.type) &&
              c.target.type === palette.BUSINESS_OBJECT
          )
        )

      const aggregations = childData.filter(
        (c) => c.type === palette.AGGREGATION_RELATIONSHIP
      )

      const compositions = childData.filter(
        (c) => c.type === palette.COMPOSITION_RELATIONSHIP
      )

      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(businessObject),
          businessObject.documentation,
          businessObject.props,
          includeProperties,
          includeDoco
        )
      )

      if (aggregations.length > 0) {
        prompt.push(
          ` - It contains references to: ${aggregations
            .map((c) => c.target.name)
            .join(", ")}`
        )
      }

      if (compositions.length > 0) {
        prompt.push(
          ` - It wholly contains: ${compositions
            .map((c) => c.target.name)
            .join(", ")}`
        )
      }
    })

    // Get specializations

    const specializationRels = businessObjects.flatMap((el) =>
      el.connections
        .filter(
          (c) =>
            c.type === palette.SPECIALIZATION_RELATIONSHIP &&
            c.source.name === el.name &&
            c.target.type === palette.BUSINESS_OBJECT
        )
        .map((c) => ({
          sourcePosition: el.position,
          ...c,
          targetPosition: businessObjects.find(
            (el) => el.name === c.target.name
          ).position,
        }))
    )

    if (specializationRels.length > 0) {
      prompt.push(
        `Some business objects are sub types of other business objects:`
      )
      specializationRels.forEach((rel) => {
        prompt.push(
          ` - ${rel.sourcePosition} is a sub type of ${rel.targetPosition}`
        )
      })
    }
  }
}

const addGaps = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_IMPLEMENTATION)) {
    return
  }

  const gaps = elements.filter((el) => el.type === palette.GAP)

  if (gaps.length > 0) {
    prompt.push(
      `There ${plural(gaps.length, "is", "are")} ${gaps.length} gap${
        gaps.length > 1 ? "s" : ""
      } in the diagram, which represents the different between two transitional architecture states.`
    )

    gaps.forEach((gap, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(gap),
          gap.documentation,
          gap.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addImplementationEvents = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_IMPLEMENTATION)) {
    return
  }

  const implementationEvents = elements.filter(
    (el) => el.type === palette.IMPLEMENTATION_EVENT
  )

  if (implementationEvents.length > 0) {
    prompt.push(
      `There ${plural(implementationEvents.length, "is", "are")} ${
        implementationEvents.length
      } implementation event${
        implementationEvents.length > 1 ? "s" : ""
      } in the diagram, which represents a state change related to implementation or migration.`
    )

    implementationEvents.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addArtifacts = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_TECHNOLOGY)) {
    return
  }

  const artifacts = elements.filter((el) => el.type === palette.ARTIFACT)

  if (artifacts.length > 0) {
    prompt.push(
      `There ${plural(artifacts.length, "is", "are")} ${
        artifacts.length
      } artifact${
        artifacts.length > 1 ? "s" : ""
      } in the diagram, which represents a piece of data that is used or produced in a software development process, or by deployment and operation of an IT system.`
    )

    artifacts.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addTechnologyEvents = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_TECHNOLOGY)) {
    return
  }

  const technologyEvents = elements.filter(
    (el) => el.type === palette.TECHNOLOGY_EVENT
  )

  if (technologyEvents.length > 0) {
    prompt.push(
      `There ${plural(technologyEvents.length, "is", "are")} ${
        technologyEvents.length
      } technology event${
        technologyEvents.length > 1 ? "s" : ""
      } in the diagram, which represent a technology state change.`
    )

    technologyEvents.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addEquipment = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_PHYSICAL)) {
    return
  }

  const equipment = elements.filter((el) => el.type === palette.EQUIPMENT)

  if (equipment.length > 0) {
    prompt.push(
      `There ${plural(equipment.length, "is", "are")} ${
        equipment.length
      } equipment${
        equipment.length > 1 ? "s" : ""
      } in the diagram, which represent physical machines, tools, or instruments that can create, use, store, move, or transform materials.`
    )

    equipment.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addTechnologyProcesses = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_TECHNOLOGY)) {
    return
  }

  const technologyProcesses = elements.filter(
    (el) => el.type === palette.TECHNOLOGY_PROCESS
  )

  if (technologyProcesses.length > 0) {
    prompt.push(
      `There ${plural(technologyProcesses.length, "is", "are")} ${
        technologyProcesses.length
      } technology process${
        technologyProcesses.length > 1 ? "es" : ""
      } in the diagram, which are a sequence of technology behaviours that achieves a specific result.`
    )

    technologyProcesses.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addTechnologyInteractions = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_TECHNOLOGY)) {
    return
  }

  const technologyInteractions = elements.filter(
    (el) => el.type === palette.TECHNOLOGY_INTERACTION
  )

  if (technologyInteractions.length > 0) {
    prompt.push(
      `There ${plural(technologyInteractions.length, "is", "are")} ${
        technologyInteractions.length
      } technology interaction${
        technologyInteractions.length > 1 ? "s" : ""
      } in the diagram, which are a unit of collective technology behaviour performed by a collection of two or more Nodes.`
    )

    technologyInteractions.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addCommunicationNetworks = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_TECHNOLOGY)) {
    return
  }

  const communicationNetworks = elements.filter(
    (el) => el.type === palette.COMMUNICATION_NETWORK
  )

  if (communicationNetworks.length > 0) {
    prompt.push(
      `There ${plural(communicationNetworks.length, "is", "are")} ${
        communicationNetworks.length
      } communication network${
        communicationNetworks.length > 1 ? "s" : ""
      } in the diagram, which are a set of structures that connect nodes for transmission, routing, and reception of data.`
    )

    communicationNetworks.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addApplicationFunctions = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_APPLICATION)) {
    return
  }

  const applicationFunctions = elements.filter(
    (el) => el.type === palette.APPLICATION_FUNCTION
  )

  if (applicationFunctions.length > 0) {
    prompt.push(
      `There ${plural(applicationFunctions.length, "is", "are")} ${
        applicationFunctions.length
      } application function${
        applicationFunctions.length > 1 ? "s" : ""
      } in the diagram, which represent automated behaviour that can be performed by an Application Component.`
    )

    applicationFunctions.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addApplicationProcesses = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_APPLICATION)) {
    return
  }

  const applicationProcesses = elements.filter(
    (el) => el.type === palette.APPLICATION_PROCESS
  )

  if (applicationProcesses.length > 0) {
    prompt.push(
      `There ${plural(applicationProcesses.length, "is", "are")} ${
        applicationProcesses.length
      } application process${
        applicationProcesses.length > 1 ? "es" : ""
      } in the diagram, which are a sequence of application behaviours that achieve a specific result.`
    )

    applicationProcesses.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addDistributionNetworks = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_PHYSICAL)) {
    return
  }

  const distributionNetworks = elements.filter(
    (el) => el.type === palette.DISTRIBUTION_NETWORK
  )

  if (distributionNetworks.length > 0) {
    prompt.push(
      `There ${plural(distributionNetworks.length, "is", "are")} ${
        distributionNetworks.length
      } distribution network${
        distributionNetworks.length > 1 ? "s" : ""
      } in the diagram, which represents a physical network used to transport materials or energy.`
    )

    distributionNetworks.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addDataObjects = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_APPLICATION)) {
    return
  }

  const dataObjects = elements.filter((el) => el.type === palette.DATA_OBJECT)

  if (dataObjects.length > 0) {
    prompt.push(
      `There ${plural(dataObjects.length, "is", "are")} ${
        dataObjects.length
      } data object${
        dataObjects.length > 1 ? "s" : ""
      } in the diagram, which represent structured data used for automated processing.`
    )

    dataObjects.forEach((dataObject, index) => {
      const lines = [
        nameAndDescription(
          index + 1,
          getLabel(dataObject),
          dataObject.documentation,
          dataObject.props,
          includeProperties,
          includeDoco
        ),
      ]

      const childData = elements
        .filter((el) => el.type === palette.DATA_OBJECT)
        .flatMap((el) =>
          el.connections.filter(
            (c) =>
              c.source.name === dataObject.name &&
              c.source.type === palette.DATA_OBJECT &&
              [
                palette.AGGREGATION_RELATIONSHIP,
                palette.COMPOSITION_RELATIONSHIP,
              ].includes(c.type) &&
              c.target.type === palette.DATA_OBJECT
          )
        )

      const aggregations = childData.filter(
        (c) => c.type === palette.AGGREGATION_RELATIONSHIP
      )

      const compositions = childData.filter(
        (c) => c.type === palette.COMPOSITION_RELATIONSHIP
      )

      if (aggregations.length > 0) {
        prompt.push(
          ` - It contains references to: ${aggregations
            .map((c) => c.target.name)
            .join(", ")}`
        )
      }

      if (compositions.length > 0) {
        prompt.push(
          ` - It wholly contains: ${compositions
            .map((c) => c.target.name)
            .join(", ")}`
        )
      }

      prompt.push(lines.join(". "))
    })
  }
}

const addApplicationCollaborations = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_APPLICATION)) {
    return
  }

  const applicationCollaborations = elements.filter(
    (el) => el.type === palette.APPLICATION_COLLABORATION
  )

  if (applicationCollaborations.length > 0) {
    prompt.push(
      `There ${plural(applicationCollaborations.length, "is", "are")} ${
        applicationCollaborations.length
      } application collaboration${
        applicationCollaborations.length > 1 ? "s" : ""
      } in the diagram, which is when two or more applications work together to perform collective application behaviour.`
    )

    applicationCollaborations.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addPlateaus = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_IMPLEMENTATION)) {
    return
  }

  const plateaus = elements.filter((el) => el.type === palette.PLATEAU)

  if (plateaus.length > 0) {
    const plateauConnections = plateaus.flatMap((plateau) =>
      plateau.connections.filter(
        (c) =>
          c.type === palette.TRIGGERING_RELATIONSHIP &&
          (c.source.type === palette.PLATEAU ||
            c.target.type === palette.PLATEAU)
      )
    )

    const plateauNames = plateauConnections.reduce((acc, rel) => {
      if (
        rel.source.type === palette.PLATEAU &&
        !acc.includes(rel.source.name)
      ) {
        acc.push(rel.source.name)
      }
      if (
        rel.target.type === palette.PLATEAU &&
        !acc.includes(rel.target.name)
      ) {
        acc.push(rel.target.name)
      }
      return acc
    }, [])

    // Sort the plateau names based on the plateau connections

    const sorted = plateauNames
      .reduce((acc, name) => {
        const source = plateauConnections.find((r) => r.source.name === name)
        const target = plateauConnections.find((r) => r.target.name === name)

        if (source && !target) {
          acc.push(name)
        } else if (!source && target) {
          acc.unshift(name)
        } else if (source && target) {
          const sourceIndex = acc.indexOf(source.source.name)
          const targetIndex = acc.indexOf(target.target.name)
          if (sourceIndex < targetIndex) {
            acc.splice(targetIndex, 0, name)
          } else {
            acc.splice(sourceIndex, 0, name)
          }
        }

        return acc
      }, [])
      .reverse()

    // Add in any plateus that are not connected to any other plateau

    const unconnectedPlateaus = plateaus
      .filter((p) => !sorted.includes(p.name))
      .map((p) => p.name)

    sorted.push(...unconnectedPlateaus)

    prompt.push(
      `There ${plural(plateaus.length, "is", "are")} ${
        plateaus.length
      } plateau${
        plateaus.length > 1 ? "s" : ""
      } in the diagram, which is a relatively stable transitional architecture states that exists during a limited period of time.`
    )

    // Sort plateaus according to the sorted array

    const sortedPlateaus = sorted.map((name) =>
      plateaus.find((p) => p.name === name)
    )

    const workPackages = elements
      .filter((el) => el.type === palette.WORK_PACKAGE)
      .map((wp, index) => ({
        ...wp,
        position: index + 1,
      }))

    sortedPlateaus.forEach((el, index) => {
      const lines = [
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        ),
      ]

      const workPackagesForPlateau = elements
        .filter((element) => element.type === palette.WORK_PACKAGE)
        .flatMap((wp) =>
          wp.connections.filter(
            (c) =>
              c.target.name === el.name &&
              c.target.type === palette.PLATEAU &&
              c.source.type === palette.WORK_PACKAGE
          )
        )
        .map((c) => c.source.name)

      const workPackagePositions = workPackages
        .filter((wp) => workPackagesForPlateau.includes(wp.name))
        .map((wp) => wp.position)

      lines.push(
        "It contains these work packages: " + workPackagePositions.join(", ")
      )

      prompt.push(lines.join(". "))
    })
  }
}

const addTechnologyFunctions = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_TECHNOLOGY)) {
    return
  }

  const technologyFunctions = elements.filter(
    (el) => el.type === palette.TECHNOLOGY_FUNCTION
  )

  if (technologyFunctions.length > 0) {
    prompt.push(
      `There ${plural(technologyFunctions.length, "is", "are")} ${
        technologyFunctions.length
      } technology function${
        technologyFunctions.length > 1 ? "s" : ""
      } in the diagram, which is behaviour that can be performed by technology infrastructure.`
    )

    technologyFunctions.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addApplicationInteractions = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_APPLICATION)) {
    return
  }

  const applicationInteractions = elements.filter(
    (el) => el.type === palette.APPLICATION_INTERACTION
  )

  if (applicationInteractions.length > 0) {
    prompt.push(
      `There ${plural(applicationInteractions.length, "is", "are")} ${
        applicationInteractions.length
      } application interaction${
        applicationInteractions.length > 1 ? "s" : ""
      } in the diagram, which represent a unit of collective application behaviour performed by a collaboration of two or more Application Components.`
    )

    applicationInteractions.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addBusinessRoles = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_BUSINESS)) {
    return
  }

  const businessRoles = elements.filter(
    (el) => el.type === palette.BUSINESS_ROLE
  )

  if (businessRoles.length > 0) {
    prompt.push(
      `There ${plural(businessRoles.length, "is", "are")} ${
        businessRoles.length
      } business role${
        businessRoles.length > 1 ? "s" : ""
      } in the diagram, which represents the responsibility for performing specific business behavior, to which an actor can be assigned, or the part an actor plays in a particular action or event.`
    )

    businessRoles.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addTechnologyServices = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_TECHNOLOGY)) {
    return
  }

  const technologyServices = elements.filter(
    (el) => el.type === palette.TECHNOLOGY_SERVICE
  )

  if (technologyServices.length > 0) {
    prompt.push(
      `There ${plural(technologyServices.length, "is", "are")} ${
        technologyServices.length
      } technology service${
        technologyServices.length > 1 ? "s" : ""
      } in the diagram, which represent explicitly defined exposed technology infrastructure behaviour.`
    )

    technologyServices.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addOutcomes = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_MOTIVATION)) {
    return
  }

  const outcomes = elements.filter((el) => el.type === palette.OUTCOME)

  if (outcomes.length > 0) {
    prompt.push(
      `There ${plural(outcomes.length, "is", "are")} ${
        outcomes.length
      } outcome${
        outcomes.length > 1 ? "s" : ""
      } in the diagram, which represent business-oriented results produced by capabilities of an organization.`
    )

    outcomes.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addPrinciples = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_MOTIVATION)) {
    return
  }

  const principles = elements.filter((el) => el.type === palette.PRINCIPLE)

  if (principles.length > 0) {
    prompt.push(
      `There ${plural(principles.length, "is", "are")} ${
        principles.length
      } principle${
        principles.length > 1 ? "s" : ""
      } in the diagram, which represent statement of intent defining a general property that applies to any system in a certain context in the architecture.`
    )

    principles.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addDevices = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_TECHNOLOGY)) {
    return
  }

  const devices = elements.filter((el) => el.type === palette.DEVICE)

  if (devices.length > 0) {
    prompt.push(
      `There ${plural(devices.length, "is", "are")} ${devices.length} device${
        devices.length > 1 ? "s" : ""
      } in the diagram, which represent physical IT resource on which system software and artifacts may be stored or deployed for execution.`
    )

    devices.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addSystemSoftware = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_TECHNOLOGY)) {
    return
  }

  const systemSoftware = elements.filter(
    (el) => el.type === palette.SYSTEM_SOFTWARE
  )

  //console.log("%csystem software", "color:yellow", systemSoftware)

  if (systemSoftware.length > 0) {
    prompt.push(
      `There ${plural(systemSoftware.length, "is", "are")} ${
        systemSoftware.length
      } system software${
        systemSoftware.length > 1 ? "s" : ""
      } in the diagram, which represent software that provides or contributes to an environment for storing, executing, and using software or data deployed within it.`
    )

    systemSoftware.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

// Find all groups and explain what they contain
const addGroupings = (elements, promptLayers, prompt) => {
  //console.log("%caddGroupings", "color:lightgreen", { promptLayers })
  if (!promptLayers.includes(palette.LAYER_NAME_OTHER)) {
    return
  }

  const groupings = elements.filter(
    (element) => element.type === palette.GROUPING
  )

  if (groupings.length > 0) {
    prompt.push("Some diagram elements have been grouped to provide context")
    prompt.push("The groups are: " + groupings.map((g) => g.name).join(", "))
    groupings.forEach((group) => {
      const groupRels = group.connections.filter(
        (c) => c.source.name === group.name
      )
      if (groupRels.length > 0) {
        const groupedByType = groupRels.reduce((acc, curr) => {
          if (!acc[curr.target.type]) {
            acc[curr.target.type] = []
          }
          acc[curr.target.type].push(curr.target.name)
          return acc
        }, {})

        const elementNamesGroupedByTypeDesc = Object.keys(groupedByType).map(
          (type) => {
            const names = groupedByType[type]

            return `${names.length} ${palette.formatLabel(type)}${
              names.length > 1 ? "s" : ""
            } - ${names.join(", ")}.`
          }
        )

        prompt.push(
          `The group '${group.name}' contains: ${elementNamesGroupedByTypeDesc}`
        )
      }
    })
  }
}

const addFacilities = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_PHYSICAL)) {
    return
  }

  const facilities = elements.filter((el) => el.type === palette.FACILITY)

  if (facilities.length > 0) {
    prompt.push(
      `There ${plural(facilities.length, "is", "are")} ${
        facilities.length
      } facility${
        facilities.length > 1 ? "s" : ""
      } in the diagram, which represent a physical resource that has the capability of facilitating (e.g., housing or locating) the use of equipment. It is typically used to model factories, buildings, or outdoor constructions that have an important role in production or distribution processes.`
    )

    facilities.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addGoals = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_MOTIVATION)) {
    return
  }

  const goals = elements.filter((el) => el.type === palette.GOAL)

  if (goals.length > 0) {
    prompt.push(
      `There ${plural(goals.length, "is", "are")} ${goals.length} goal${
        goals.length > 1 ? "s" : ""
      } in the diagram, which represent anything a stakeholder may desire, such as a state of affairs, or a produced value. Goals are typically used to measure success of an organization.`
    )

    goals.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addApplicationEvents = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_APPLICATION)) {
    return
  }

  const applicationEvents = elements.filter(
    (el) => el.type === palette.APPLICATION_EVENT
  )

  if (applicationEvents.length > 0) {
    prompt.push(
      `There ${plural(applicationEvents.length, "is", "are")} ${
        applicationEvents.length
      } application event${
        applicationEvents.length > 1 ? "s" : ""
      } in the diagram. Application Functions and other application behaviour may be triggered or interrupted by an Application Event. Also, application behaviour may raise events that trigger other application behaviour.`
    )

    applicationEvents.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addTechnologyCollaborations = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_TECHNOLOGY)) {
    return
  }

  const technologyCollaborations = elements.filter(
    (el) => el.type === palette.TECHNOLOGY_COLLABORATION
  )

  if (technologyCollaborations.length > 0) {
    prompt.push(
      `There ${plural(technologyCollaborations.length, "is", "are")} ${
        technologyCollaborations.length
      } technology collaboration${
        technologyCollaborations.length > 1 ? "s" : ""
      } in the diagram, which represent two or more technology elements (active structure) working together to perform collective technology behaviour.`
    )

    technologyCollaborations.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addApplicationServices = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_APPLICATION)) {
    return
  }

  const applicationServices = elements.filter(
    (el) => el.type === palette.APPLICATION_SERVICE
  )

  if (applicationServices.length > 0) {
    prompt.push(
      `There ${plural(applicationServices.length, "is", "are")} ${
        applicationServices.length
      } application service${
        applicationServices.length > 1 ? "s" : ""
      } in the diagram, which is functionality exposed for consumption by business processes or other applications.`
    )

    // const applicationComponents = elements.filter(
    //     (el) => el.type === palette.APPLICATION_COMPONENT
    // )

    applicationServices.forEach((el, index) => {
      const lines = []

      lines.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )

      // Get application components that realize this service

      const realizationRels = elements.flatMap((item) =>
        item.connections.filter(
          (c) =>
            c.type === palette.REALIZATION_RELATIONSHIP &&
            c.target.name === el.name &&
            c.target.type === el.type &&
            c.source.type === palette.APPLICATION_COMPONENT
        )
      )

      if (realizationRels.length > 0) {
        lines.push(
          ` provided out of application component: ${realizationRels
            .map((c) => c.source.name)
            .join(", ")}.`
        )
      }

      prompt.push(lines.join(", "))
    })
  }
}

// List interfaces realized by application components
const addApplicationInterfaces = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_APPLICATION)) {
    return
  }

  const interfaces = elements.filter(
    (el) => el.type === palette.APPLICATION_INTERFACE
  )

  if (interfaces.length > 0) {
    prompt.push(
      `There ${plural(interfaces.length, "is", "are")} ${
        interfaces.length
      } application interface${
        interfaces.length > 1 ? "s" : ""
      } in the diagram, which represent a point of access where application services are made available to a user, another application component, or a Node.`
    )

    // prompt.push(`They are: ${interfaces.map((c) => c.name).join(", ")}.`)

    interfaces.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
      const applicationServices = el.connections
        .filter(
          (c) =>
            c.source.name === el.name &&
            c.source.type === el.type &&
            c.target.type === palette.APPLICATION_SERVICE
        )
        .flatMap((c) => c.target)

      if (applicationServices.length > 0) {
        prompt.push(
          `${el.name} provides the application services: ${applicationServices
            .map((c) => c.name)
            .join(", ")}.`
        )
      }
    })

    const interfaceProviders = getElementsWithConnections(
      elements,
      [palette.APPLICATION_COMPONENT],
      [palette.REALIZATION_RELATIONSHIP]
    )
  }
}

const addMaterials = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_TECHNOLOGY)) {
    return
  }

  const materials = elements.filter((el) => el.type === palette.MATERIAL)

  if (materials.length > 0) {
    prompt.push(
      `There ${plural(materials.length, "is", "are")} ${
        materials.length
      } material${
        materials.length > 1 ? "s" : ""
      } in the diagram, which represent raw materials and physical products, and also energy sources such as fuel. Material can be accessed by physical processes.`
    )

    materials.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addRepresentations = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_BUSINESS)) {
    return
  }

  const representations = elements.filter(
    (el) => el.type === palette.REPRESENTATION
  )

  if (representations.length > 0) {
    prompt.push(
      `There ${plural(representations.length, "is", "are")} ${
        representations.length
      } representation${
        representations.length > 1 ? "s" : ""
      } in the diagram, which is a perceptible form of the information carried by a Business Object.`
    )

    representations.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const getDocumentation = (elements, name, type) => {
  const element = elements.find((el) => el.name === name && el.type === type)

  if (!element) {
    return []
  }

  return element.documentation
}

// Find all business functions/processes that input or output business objects
const addBusinessInformationUsage = (
  elements,
  promptLayers,
  prompt,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_BUSINESS)) {
    return
  }

  const businessFunctionAccessors = getElementsWithConnections(
    elements,
    [palette.BUSINESS_FUNCTION],
    [palette.ACCESS_RELATIONSHIP]
  )

  const businessProcessAccessors = getElementsWithConnections(
    elements,
    [palette.BUSINESS_PROCESS],
    [palette.ACCESS_RELATIONSHIP]
  )

  const informationAccessors = businessFunctionAccessors.concat(
    businessProcessAccessors
  )

  if (informationAccessors.length > 0) {
    prompt.push("")
    prompt.push("[Business Object (information) Usage]")

    const businessObjectExplained = []

    informationAccessors.forEach((el) => {
      const accessRels = el.connections.filter(
        (c) =>
          c.type === palette.ACCESS_RELATIONSHIP && c.source.name === el.name
      )

      const accessName = { R: "reads", W: "writes", RW: "reads and writes" }
      accessRels.forEach((rel) => {
        const accessLabel = accessName[rel.accessType] || "accesses"

        let documentation = ""
        if (!businessObjectExplained.includes(rel.target.name)) {
          if (includeDoco) {
            const docInfo = getDocumentation(
              elements,
              rel.target.name,
              rel.target.type
            )
            if (docInfo.length > 0) {
              documentation = `, which has the description: ${docInfo}`
            }
          }
        }
        // prompt.push(
        //   `The ${palette.formatLabel(el.type)} '${el.name}' ${accessLabel} '${
        //     rel.target.name
        //   }' information${documentation}.`
        // )

        prompt.push(
          `${getLabel(el)} ${accessLabel} the '${
            rel.target.name
          }' Business Object${documentation}.`
        )
      })
    })
  }
}

const addBusinessServices = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_BUSINESS)) {
    return
  }

  const businessServices = elements.filter(
    (el) => el.type === palette.BUSINESS_SERVICE
  )

  if (businessServices.length > 0) {
    prompt.push(
      `There ${plural(businessServices.length, "is", "are")} ${
        businessServices.length
      } business service${
        businessServices.length > 1 ? "s" : ""
      } in the diagram. A Business Service defines the visible behavior of a business role or collaboration. It consists of functions, processes, or interactions performed by them, and has a name containing "-ing" or "service".`
    )

    businessServices.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

// List application components
const addApplicationComponents = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  //console.log("%caddApplicationComponents", "color: lightgreen", { promptLayers })
  if (!promptLayers.includes(palette.LAYER_NAME_APPLICATION)) {
    return
  }

  const applicationComponents = elements.filter(
    (el) => el.type === palette.APPLICATION_COMPONENT
  )

  if (applicationComponents.length > 0) {
    prompt.push(
      `There ${plural(applicationComponents.length, "is", "are")} ${
        applicationComponents.length
      } application component${
        applicationComponents.length > 1 ? "s" : ""
      }, which represent a self-contained, modular and replaceable unit of application functionality with one or more interfaces. It performs one or more application functions.`
    )

    applicationComponents.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addNodes = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_TECHNOLOGY)) {
    return
  }

  const nodes = elements.filter((el) => el.type === palette.NODE)

  if (nodes.length > 0) {
    prompt.push(
      `There ${plural(nodes.length, "is", "are")} ${nodes.length} node${
        nodes.length > 1 ? "s" : ""
      }, which represent computational or physical resource that hosts, manipulates, or interacts with other computational or physical resources, e.g. Unix Server Farm, Application Server, Firewall.`
    )

    nodes.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addContracts = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_BUSINESS)) {
    return
  }

  const contracts = elements.filter((el) => el.type === palette.CONTRACT)

  if (contracts.length > 0) {
    prompt.push(
      `There ${plural(contracts.length, "is", "are")} ${
        contracts.length
      } contract${
        contracts.length > 1 ? "s" : ""
      }, which represent formal or informal specification of an agreement between a provider and a consumer that specifies the rights and obligations associated with a product and establishes functional and non-functional parameters for interaction.`
    )

    contracts.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addBusinessCollaborations = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_BUSINESS)) {
    return
  }

  const businessCollaborations = elements.filter(
    (el) => el.type === palette.BUSINESS_COLLABORATION
  )

  if (businessCollaborations.length > 0) {
    prompt.push(
      `There ${plural(businessCollaborations.length, "is", "are")} ${
        businessCollaborations.length
      } business collaboration${
        businessCollaborations.length > 1 ? "s" : ""
      }, which represent an aggregate of two or more business internal active structure elements that work together to perform collective behavior.`
    )

    businessCollaborations.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addBusinessFunctions = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_BUSINESS)) {
    return
  }

  const businessFunctions = elements.filter(
    (el) => el.type === palette.BUSINESS_FUNCTION
  )

  if (businessFunctions.length > 0) {
    prompt.push(
      `There ${plural(businessFunctions.length, "is", "are")} ${
        businessFunctions.length
      } business function${
        businessFunctions.length > 1 ? "s" : ""
      }, which represent collection of business behavior aligned with an organization's criteria. It groups activities according to their required skills, knowledge, and resources. It may trigger or be triggered by other business behavior and may access Business Objects, use Business/Application Services, and have assigned roles/components.`
    )

    businessFunctions.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })

    businessFunctions.forEach((bf) => {
      console.log("%cchecking business functions", "color:lightgreen", bf)
      const childBusinessProcesses = bf.connections.filter(
        (c) => c.target.type === palette.BUSINESS_PROCESS
      )

      console.log(
        "%cchild functions functions",
        "color:lightgreen",
        childBusinessProcesses
      )

      if (childBusinessProcesses.length > 0) {
        prompt.push(
          `${getLabel(bf)} contains the following business processes:`
        )
        childBusinessProcesses.forEach((c) => {
          const child = elements.find(
            (el) => c.target.name === el.name && c.target.type === el.type
          )
          prompt.push(`- ${getLabel(child)}`)
        })
      }
    })
  }
}

const addBusinessInteractions = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_BUSINESS)) {
    return
  }

  const businessInteractions = elements.filter(
    (el) => el.type === palette.BUSINESS_INTERACTION
  )

  if (businessInteractions.length > 0) {
    prompt.push(
      `There ${plural(businessInteractions.length, "is", "are")} ${
        businessInteractions.length
      } business interaction${
        businessInteractions.length > 1 ? "s" : ""
      }, which represent a unit of collective business behavior performed by (a collaboration of) two or more Business Actors, Business Roles, or Business Collaborations.`
    )

    businessInteractions.forEach((el, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(el),
          el.documentation,
          el.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

// Find application elements with flow connectors either incoming or outgoing and explain what information is being passed
const addApplicationFlows = (elements, promptLayers, prompt) => {
  if (!promptLayers.includes(palette.LAYER_NAME_APPLICATION)) {
    return
  }

  const applicationLayerFlows = elements.flatMap((el) => {
    if (
      palette.getElementType(el.type).layer.name ===
      palette.LAYER_NAME_APPLICATION
    ) {
      return el.connections.filter((c) => c.type === palette.FLOW_RELATIONSHIP)
    } else {
      return []
    }
  })

  if (applicationLayerFlows.length > 0) {
    prompt.push(
      "The diagram shows the flow of information in the application layer"
    )
    applicationLayerFlows.forEach((c) => {
      prompt.push(
        `'${c.source.name}' passes '${c.name}' information to '${c.target.name}' using a Flow connector.`
      )
    })
  }
}

// Find elements with flow connectors either incoming or outgoing and explain what information is being passed
const addBusinessFlows = (
  elements,
  promptLayers,
  prompt,
  getLabel,
  getLabelForElementRef
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_BUSINESS)) {
    return
  }

  prompt.push("[Information Flows and Business Object Usage]")

  const businessLayerFlows = elements.flatMap((el) => {
    if (
      palette.getElementType(el.type).layer.name === palette.LAYER_NAME_BUSINESS
    ) {
      return el.connections.filter((c) => c.type === palette.FLOW_RELATIONSHIP)
    } else {
      return []
    }
  })

  if (businessLayerFlows.length > 0) {
    prompt.push("The diagram shows some business layer information flows")
    businessLayerFlows.forEach((c) => {
      prompt.push(
        `Flow connector: '${getLabelForElementRef(
          c.source
        )}' explicitly passes '${
          c.name
        }' information to '${getLabelForElementRef(
          c.target
        )}' using a Flow connector.`
      )
    })
  }
}

// Find business events that trigger something
const addBusinessEvents = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_BUSINESS)) {
    return
  }

  const businessEvents = elements.filter(
    (element) => element.type === palette.BUSINESS_EVENT
  )

  if (businessEvents.length > 0) {
    prompt.push(
      `There ${plural(businessEvents.length, "is", "are")} ${
        businessEvents.length
      } business event${businessEvents.length > 1 ? "s" : ""}.`
    )
    prompt.push(
      `Business Processes and other business behaviour may be triggered or interrupted by a Business Event. Also, Business Processes may raise events that trigger other Business Processes, Functions, or Interactions.`
    )
    businessEvents.forEach((businessEvent, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(businessEvent),
          businessEvent.documentation,
          businessEvent.props,
          includeProperties,
          includeDoco
        )
      )
    })

    const triggeringEvents = getElementsWithConnections(
      elements,
      [palette.BUSINESS_EVENT],
      [palette.TRIGGERING_RELATIONSHIP]
    )

    triggeringEvents.forEach((f) => {
      const target = f.connections.find(
        (c) => c.type === palette.TRIGGERING_RELATIONSHIP
      ).target
      prompt.push(
        `'${f.name}' triggers '${target.name}' which is a ${palette.formatLabel(
          target.type
        )}`
      )
    })
  }
}

const addBusinessBehaviour = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_BUSINESS)) {
    return
  }
  const businessProcesses = elements.filter(
    (element) => element.type === palette.BUSINESS_PROCESS
  )

  if (businessProcesses.length > 0) {
    prompt.push(
      `There ${plural(businessProcesses.length, "is", "are")} ${
        businessProcesses.length
      } business process${businessProcesses.length > 1 ? "es" : ""}`
    )
    businessProcesses.forEach((businessProcess, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(businessProcess),
          businessProcess.documentation,
          businessProcess.props,
          includeProperties,
          includeDoco
        )
      )

      const childRels = businessProcess.connections.filter(
        (c) =>
          c.source.name === businessProcess.name &&
          c.type === palette.COMPOSITION_RELATIONSHIP
      )

      if (childRels.length > 0) {
        prompt.push(
          `It has ${childRels.length} sub functions: ${childRels
            .map((c) => c.target.name)
            .join(", ")}`
        )
      }
    })

    businessProcesses.forEach((bp) => {
      const cnxs = bp.connections.filter(
        (c) => c.source.name === bp.name
        //&& c.type === palette.TRIGGERING_RELATIONSHIP
      )

      console.log("%c", "color:pink", bp.name, {
        cnxs: cnxs,
        connections: bp.connections,
      })

      if (cnxs.length > 0) {
        const targetElements = elements.filter((el) =>
          cnxs.find(
            (c) => c.target.name === el.name && c.target.type === el.type
          )
        )

        console.log("%ctarget elements", "color:lightgreen", bp.name, {
          targetElements,
          cnxs,
        })

        // Group connections by type
        const groupedConnections = cnxs.reduce((acc, curr) => {
          if (!acc[curr.type]) {
            acc[curr.type] = []
          }
          acc[curr.type].push(curr.target)
          return acc
        }, {})

        console.log("%cgrouped connections", "color:orange", {
          groupedConnections,
          cnxs,
        })

        const relTypes = Object.keys(groupedConnections)

        relTypes.forEach((relType) => {
          const targetElements = groupedConnections[relType]

          prompt.push(
            `${getLabel(bp)} ${
              RELATIONSHIP_DESCRIPTORS[relType]
            } ${targetElements.map((t) => getLabel(t)).join(", ")}`
          )
        })

        // prompt.push(
        //   `${getLabel(bp)} triggers ${targetElements
        //     .map((t) => getLabel(t))
        //     .join(", ")}`
        // )
      }
    })
  }

  // Find business function elements that trigger another
  const triggeringBusinessFunctions = getElementsWithConnections(
    elements,
    [palette.BUSINESS_FUNCTION],
    [palette.TRIGGERING_RELATIONSHIP]
  )

  triggeringBusinessFunctions.forEach((f) => {
    const target = f.connections.find(
      (c) => c.type === palette.TRIGGERING_RELATIONSHIP
    ).target
    prompt.push(
      `'${f.name}' triggers '${target.name}' which is a ${palette.formatLabel(
        target.type
      )}`
    )
  })
}

const addValueStreams = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_STRATEGY)) {
    return
  }

  const valueStreams = elements.filter((el) => el.type === palette.VALUE_STREAM)

  if (valueStreams.length > 0) {
    prompt.push(
      `There ${plural(valueStreams.length, "is", "are")} ${
        valueStreams.length
      } value stream${
        valueStreams.length > 1 ? "s" : ""
      }, which represent a sequence of activities that create an overall result for a customer, stakeholder, or end user.`
    )

    prompt.push(
      `A value stream describes how an enterprise organizes its activities to create value. Value streams are typically realized by business processes and possibly other core behavior elements.`
    )
    valueStreams.forEach((valueStream, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(valueStream),
          valueStream.documentation,
          valueStream.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const getRelatedElements = (elements, element, relationshipTypes) => {
  const relatedElements = elements.filter((el) => {
    return element.connections.some((c) => {
      return (
        c.source.name === element.name &&
        c.target.name === el.name &&
        relationshipTypes.includes(c.type)
      )
    })
  })

  return relatedElements
}

const addPaths = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_TECHNOLOGY)) {
    return
  }

  const paths = elements.filter((el) => el.type === palette.PATH)

  if (paths.length > 0) {
    prompt.push(
      `There ${plural(paths.length, "is", "are")} ${paths.length} path${
        paths.length > 1 ? "s" : ""
      }, which represent logical communication (or distribution) relations between Nodes. It is realized by one or more Networks, which represent the physical communication (or distribution) links, e.g. message queueing, data replication path`
    )
    paths.forEach((path, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(path),
          path.documentation,
          path.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addResources = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_STRATEGY)) {
    return
  }

  const resources = elements.filter((el) => el.type === palette.RESOURCE)

  if (resources.length > 0) {
    prompt.push(
      `There ${plural(resources.length, "is", "are")} ${
        resources.length
      } resource${
        resources.length > 1 ? "s" : ""
      }, which are an asset owned or controlled by an individual or organization used to deliver capabilities.`
    )
    resources.forEach((resource, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(resource),
          resource.documentation,
          resource.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addCapabilities = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel,
  getShortId
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_STRATEGY)) {
    return
  }

  const capabilities = elements
    .filter((el) => el.type === palette.CAPABILITY)
    .map((el, index) => ({ ...el, position: index + 1 }))

  //console.log("capabilities", capabilities)

  if (capabilities.length > 0) {
    prompt.push(
      `There ${plural(capabilities.length, "is", "are")} ${
        capabilities.length
      } capabilit${
        capabilities.length > 1 ? "ies" : "y"
      }, representing an ability possessed by an organization aimed at achieving a desired outcome, and typically realized by a combination of organization, people, processes, information, and technology.`
    )
  }
  capabilities.forEach((capability, index) => {
    prompt.push(
      nameAndDescription(
        index + 1,
        getLabel(capability, false),
        capability.documentation,
        capability.props,
        includeProperties,
        includeDoco
      )
    )

    const childElements = getChildElements(capability, elements)
    //console.log("childElements", capability.name, childElements)
    if (childElements.length > 0) {
      //prompt.push(`Children:`)
      childElements.forEach((el) =>
        //prompt.push(`- ${el.name} (${el.type}) [${getShortId(el.id)}]`)
        prompt.push(`- ${getLabel(el)}`)
      )
    }
  })

  capabilities.forEach((capability) => {
    //console.log("capability", capability.name, { capability })
    const subCapabilities = capability.connections
      .filter(
        (c) =>
          c.target.type === palette.CAPABILITY &&
          (c.type === palette.AGGREGATION_RELATIONSHIP ||
            c.type === palette.COMPOSITION_RELATIONSHIP)
      )
      .map((c) => c.target.name)
    const subElements = capabilities.filter(
      (el) =>
        el.type === palette.CAPABILITY && subCapabilities.includes(el.name)
    )
    if (subElements.length > 0) {
      prompt.push(
        `Capability ${
          capability.position
        } contains sub-capabilities: ${subElements
          .map((el) => el.position)
          .join(",")}`
      )
    }
  })
}

const addCoursesOfAction = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_STRATEGY)) {
    return
  }

  const coursesOfAction = elements.filter(
    (el) => el.type === palette.COURSE_OF_ACTION
  )

  if (coursesOfAction.length > 0) {
    prompt.push(
      `There ${plural(coursesOfAction.length, "is", "are")} ${
        coursesOfAction.length
      } course of action${
        coursesOfAction.length > 1 ? "s" : ""
      }, which represent an approach or plan by the Enterprise for configuring some capabilities and resources of the enterprise, undertaken to achieve a goal.`
    )
    coursesOfAction.forEach((courseOfAction, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(courseOfAction),
          courseOfAction.documentation,
          courseOfAction.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addLocations = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_OTHER)) {
    return
  }

  const locations = elements.filter((el) => el.type === palette.LOCATION)

  if (locations.length > 0) {
    prompt.push(
      `There ${plural(locations.length, "is", "are")} ${
        locations.length
      } location${
        locations.length > 1 ? "s" : ""
      }, which represent places where (active and passive) structure elements such as business actors, application components, and devices are located.`
    )
    locations.forEach((location, index) => {
      const lines = [
        nameAndDescription(
          index + 1,
          getLabel(location),
          location.documentation,
          location.props,
          includeProperties,
          includeDoco
        ),
      ]

      const relatedElements = getRelatedElements(elements, location, [
        palette.ASSOCIATION_RELATIONSHIP,
      ])

      if (relatedElements.length > 0) {
        lines.push(
          `Items at this location are: ${relatedElements
            .map((el) => el.name)
            .join(", ")}`
        )
      }
      prompt.push(lines.join(". "))
    })
  }
}

const addStakeholders = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_MOTIVATION)) {
    return
  }

  const stakeholders = elements.filter((el) => el.type === palette.STAKEHOLDER)

  if (stakeholders.length > 0) {
    prompt.push(
      `There ${plural(stakeholders.length, "is", "are")} ${
        stakeholders.length
      } stakeholder${stakeholders.length > 1 ? "s" : ""}`
    )
    prompt.push(
      "Stakeholders have one or more interests in, or concerns about, the organization and its enterprise architecture. In order to direct efforts to these interests and concerns, stakeholders change, set, and emphasize goals."
    )
    stakeholders.forEach((stakeholder, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(stakeholder),
          stakeholder.documentation,
          stakeholder.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addAssessments = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_MOTIVATION)) {
    return
  }

  const assessments = elements
    .filter((el) => el.type === palette.ASSESSMENT)
    .map((el, index) => ({
      ...el,
      position: index + 1,
    }))

  if (assessments.length > 0) {
    prompt.push(
      `There ${plural(assessments.length, "is", "are")} ${
        assessments.length
      } assessment${
        assessments.length > 1 ? "s" : ""
      }, which represent an analysis of the state of affairs of the Enterprise and may reveal strengths, weaknesses, opportunities, or threats for some area of interest.`
    )
    assessments.forEach((assessment, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(assessment),
          assessment.documentation,
          assessment.props,
          includeProperties,
          includeDoco
        )
      )
    })

    prompt.push(
      "The following indicate which assessments have an influence on other assessments."
    )
    assessments.forEach((assessment) => {
      const relatedElements = elements
        .filter((assessment) => assessment.type === palette.ASSESSMENT)
        .flatMap((el) =>
          el.connections.filter(
            (c) =>
              c.type === palette.INFLUENCE_RELATIONSHIP &&
              c.source.name === assessment.name &&
              c.source.type === palette.ASSESSMENT &&
              c.target.type === palette.ASSESSMENT
          )
        )
        .map((c) => c.target.name)

      if (relatedElements.length > 0) {
        const positions = assessments
          .filter(
            (el) =>
              el.type === palette.ASSESSMENT &&
              relatedElements.includes(el.name)
          )
          .map((el) => el.position)

        prompt.push(
          `${assessment.position} influences: ${positions.join(", ")}`
        )
      }
    })
  }
}

const formatDocumentation = (documentation) => {
  const result = documentation
    .map((line) => line)
    .map((line) => line.replace(/\n/g, "").trim())
    .filter((line) => line !== "")
    .join(". ")

  return result
}

const addProducts = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_BUSINESS)) {
    return
  }

  const products = elements.filter((el) => el.type === palette.PRODUCT)

  if (products.length > 0) {
    prompt.push(
      `There ${plural(products.length, "is", "are")} ${
        products.length
      } product${products.length > 1 ? "s" : ""}`
    )
    products.forEach((product, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(product),
          product.documentation,
          product.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

const addDrivers = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_MOTIVATION)) {
    return
  }

  const drivers = elements.filter((el) => el.type === palette.DRIVER)

  if (drivers.length > 0) {
    prompt.push(
      `There ${plural(drivers.length, "is", "are")} ${drivers.length} driver${
        drivers.length > 1 ? "s" : ""
      }, which represent an external or internal condition that motivates an organization to define its goals and implement the changes necessary to achieve them.`
    )

    const assessments = elements
      .filter((el) => el.type === palette.ASSESSMENT)
      .map((el, index) => ({ position: index, ...el }))

    drivers.forEach((driver, index) => {
      const influencingAssessments = assessments.filter((el) =>
        el.connections.some(
          (c) =>
            c.type === palette.INFLUENCE_RELATIONSHIP &&
            c.target.name === driver.name &&
            c.target.type === palette.DRIVER &&
            c.source.type === palette.ASSESSMENT
        )
      )

      const influences =
        (influencingAssessments.length > 0 &&
          `. This is influenced by assessment items ${influencingAssessments
            .map((el) => el.position)
            .join(", ")}.`) ||
        ""

      prompt.push(
        [
          nameAndDescription(
            index + 1,
            getLabel(driver),
            driver.documentation,
            driver.props,
            includeProperties,
            includeDoco
          ),
          influences,
        ].join(". ")
      )
    })
  }
}

const addTechnologyInterfaces = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_TECHNOLOGY)) {
    return
  }

  const technologyInterfaces = elements.filter(
    (el) => el.type === palette.TECHNOLOGY_INTERFACE
  )

  if (technologyInterfaces.length > 0) {
    prompt.push(
      `There ${plural(technologyInterfaces.length, "is", "are")} ${
        technologyInterfaces.length
      } technology interface${
        technologyInterfaces.length > 1 ? "s" : ""
      }, which represent a point of access where technology services offered by a Node can be accessed.`
    )

    technologyInterfaces.forEach((technologyInterface, index) => {
      prompt.push(
        nameAndDescription(
          index + 1,
          getLabel(technologyInterface),
          technologyInterface.documentation,
          technologyInterface.props,
          includeProperties,
          includeDoco
        )
      )
    })
  }
}

/**
 *
 * @param {*} itemId 1, 2, 3, ...
 * @param {*} name
 * @param {*} documentation array of documentation lines
 * @returns
 */
const nameAndDescription = (
  itemId,
  name,
  documentation,
  props = [],
  includeProperties,
  includeDoco
) => {
  const lines = [` ${itemId}. ${name}`]
  if (includeDoco && documentation.length > 0) {
    lines.push(`: ${formatDocumentation(documentation)}`)
  }

  if (includeProperties && props.length > 0) {
    lines.push(
      `. Properties: ${props.map((p) => `${p.name}=${p.value}`).join(", ")}`
    )
  }

  return lines.join("")
}

const addActors = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_BUSINESS)) {
    return
  }
  const actors = elements.filter((el) =>
    [palette.BUSINESS_ACTOR, palette.BUSINESS_ROLE].includes(el.type)
  )

  if (actors.length > 0) {
    prompt.push(
      `There ${plural(actors.length, "is", "are")} ${actors.length} actor${
        actors.length > 1 ? "s" : ""
      }, which represent a business entity that is capable of performing behavior. Some examples just to explain what we mean by this are: A Customer, Marketing & Communications department, Director of Finance, Secretary, Admissions Department, Product Development.`
    )
    actors.forEach((actor, index) => {
      const lines = [
        nameAndDescription(
          index + 1,
          getLabel(actor),
          actor.documentation,
          actor.props,
          includeProperties,
          includeDoco
        ),
      ]

      const related = getRelatedElements(elements, actor, [
        palette.AGGREGATION_RELATIONSHIP,
        palette.COMPOSITION_RELATIONSHIP,
      ])
      if (related.length > 0) {
        lines.push(
          `It contains sub-elements: ${related
            .map((el) => `'${el.name}' (${palette.formatLabel(el.type)})`)
            .join(", ")}`
        )
      }

      prompt.push(lines.join(". "))
    })
  }

  actors.forEach((actor) => {
    const actorRels = actor.connections.filter(
      (c) =>
        c.type === palette.ASSIGNMENT_RELATIONSHIP &&
        c.source.name === actor.name
    )

    if (actorRels.length > 0) {
      prompt.push(
        `The actor '${
          actor.name
        }' performs these business functions: ${actorRels
          .map((rel) => rel.target.name)
          .join(", ")}`
      )
    }
  })
}

const addRequirements = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_MOTIVATION)) {
    return
  }

  const requirements = elements.filter(
    (el, index) => el.type === palette.REQUIREMENT
  )

  const getTargetElement = (name, type) =>
    elements.find((el) => name === el.name && type === el.type)

  if (requirements.length > 0) {
    prompt.push(
      `There ${plural(requirements.length, "is", "are")} ${
        requirements.length
      } requirement${
        requirements.length > 1 ? "s" : ""
      }, which represent a statement of need defining a property that applies to a specific system as described by the architecture.`
    )
    requirements.forEach((requirement, index) => {
      const requirementTargets = elements.flatMap((el) =>
        el.connections.filter(
          (c) =>
            c.type === palette.REALIZATION_RELATIONSHIP &&
            c.source.name === requirement.name &&
            c.source.type === palette.REQUIREMENT
        )
      )

      const requirementSources = elements.flatMap((el) =>
        el.connections.filter(
          (c) =>
            c.type === palette.REALIZATION_RELATIONSHIP &&
            c.target.name === requirement.name &&
            c.target.type === palette.REQUIREMENT
        )
      )

      const lines = [
        nameAndDescription(
          index + 1,
          getLabel(requirement),
          requirement.documentation,
          requirement.props,
          includeProperties,
          includeDoco
        ),
      ]

      if (requirementTargets.length > 0) {
        lines.push(
          `Applies to: ${requirementTargets
            .map(
              (el) =>
                `${getLabel(getTargetElement(el.target.name, el.target.type))}`
            )
            .join(", ")}`
        )
      }

      if (requirementSources.length > 0) {
        lines.push(
          `Is realized by: ${requirementSources
            .map((el) => `${el.source.name} (${el.source.type})`)
            .join(", ")}`
        )
      }
      prompt.push(lines.join(". "))
    })
  }
}

const addValues = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_MOTIVATION)) {
    return
  }

  const values = elements.filter((el) => el.type === palette.VALUE)

  const getTargetElement = (name, type) =>
    elements.find((el) => name === el.name && type === el.type)

  if (values.length > 0) {
    prompt.push(
      `There ${plural(values.length, "is", "are")} ${values.length} value${
        values.length > 1 ? "s" : ""
      }, which represent the relative worth, utility, or importance of a concept.`
    )
    values.forEach((value, index) => {
      const valueTargets = value.connections.filter(
        (c) => c.source.type === palette.VALUE
      )

      const lines = [
        nameAndDescription(
          index + 1,
          getLabel(value),
          value.documentation,
          value.props,
          includeProperties,
          includeDoco
        ),
      ]

      if (valueTargets.length > 0) {
        lines.push(
          `Applies to: ${valueTargets
            .map(
              (el) =>
                `${getLabel(getTargetElement(el.target.name, el.target.type))}`
            )
            .join(", ")}`
        )
      }
      prompt.push(lines.join(". "))
    })
  }
}

const addMeaning = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_MOTIVATION)) {
    return
  }

  const meanings = elements.filter((el) => el.type === palette.MEANING)

  const getTargetElement = (name, type) =>
    elements.find((el) => name === el.name && type === el.type)

  if (meanings.length > 0) {
    prompt.push(
      `There ${plural(meanings.length, "is", "are")} ${
        meanings.length
      } meaning${
        meanings.length > 1 ? "s" : ""
      }, which represent the knowledge or expertise present in, or the interpretation given to, a concept in a particular context.`
    )
    meanings.forEach((meaning, index) => {
      const meaningTargets = meaning.connections.filter(
        (c) => c.source.type === palette.MEANING
      )

      const lines = [
        nameAndDescription(
          index + 1,
          getLabel(meaning),
          meaning.documentation,
          meaning.props,
          includeProperties,
          includeDoco
        ),
      ]

      if (meaningTargets.length > 0) {
        lines.push(
          `Applies to: ${meaningTargets
            .map(
              (el) =>
                `${getLabel(getTargetElement(el.target.name, el.target.type))}`
            )
            .join(", ")}`
        )
      }
      prompt.push(lines.join(". "))
    })
  }
}

const addConstraints = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_MOTIVATION)) {
    return
  }

  const constraints = elements.filter(
    (el, index) => el.type === palette.CONSTRAINT
  )

  const getTargetElement = (name, type) =>
    elements.find((el) => name === el.name && type === el.type)

  if (constraints.length > 0) {
    prompt.push(
      `There ${plural(constraints.length, "is", "are")} ${
        constraints.length
      } constraint${
        constraints.length > 1 ? "s" : ""
      }, which represent a factor that limits the realization of goals.`
    )
    constraints.forEach((constraint, index) => {
      const constraintTargets = elements.flatMap((el) =>
        el.connections.filter(
          (c) =>
            c.type === palette.REALIZATION_RELATIONSHIP &&
            c.source.name === constraint.name &&
            c.source.type === palette.CONSTRAINT
        )
      )

      const constraintSources = elements.flatMap((el) =>
        el.connections.filter(
          (c) =>
            c.type === palette.REALIZATION_RELATIONSHIP &&
            c.target.name === constraint.name &&
            c.target.type === palette.CONSTRAINT
        )
      )

      const lines = [
        nameAndDescription(
          index + 1,
          getLabel(constraint),
          constraint.documentation,
          constraint.props,
          includeProperties,
          includeDoco
        ),
      ]

      if (constraintTargets.length > 0) {
        lines.push(
          `Applies to: ${constraintTargets
            .map(
              (el) =>
                `${getLabel(getTargetElement(el.target.name, el.target.type))}`
            )
            .join(", ")}`
        )
      }

      if (constraintSources.length > 0) {
        lines.push(
          `Is realized by: ${constraintSources
            .map((el) => `${el.source.name} (${el.source.type})`)
            .join(", ")}`
        )
      }

      prompt.push(lines.join(". "))
    })
  }
}

const addDeliverables = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_IMPLEMENTATION)) {
    return
  }

  const deliverables = elements.filter((el) => el.type === palette.DELIVERABLE)

  if (deliverables.length > 0) {
    prompt.push(
      `There ${plural(deliverables.length, "is", "are")} ${
        deliverables.length
      } deliverable${
        deliverables.length > 1 ? "s" : ""
      }, which represent a precisely-defined outcome of a Work Package.`
    )
    deliverables.forEach((deliverable, index) => {
      // See if this deliverable is attached to a work package
      const wpRels = elements.flatMap((el) =>
        el.connections.filter(
          (c) =>
            c.source.name === deliverable.name &&
            c.target.type === palette.WORK_PACKAGE
        )
      )

      const wpRels2 = elements.flatMap((el) =>
        el.connections.filter(
          (c) =>
            c.target.name === deliverable.name &&
            c.source.type === palette.WORK_PACKAGE
        )
      )

      const lines = [
        nameAndDescription(
          index + 1,
          getLabel(deliverable),
          deliverable.documentation,
          deliverable.props,
          includeProperties,
          includeDoco
        ),
      ]

      if (wpRels) {
        lines.push(
          `This deliverable is part of work package(s): '${wpRels
            .map((wp) => wp.target.name)
            .join(", ")}'`
        )
      }

      prompt.push(lines.join(". "))
    })
  }
}

const addWorkPackages = (
  elements,
  promptLayers,
  prompt,
  includeProperties,
  includeDoco,
  getLabel
) => {
  if (!promptLayers.includes(palette.LAYER_NAME_IMPLEMENTATION)) {
    return
  }

  const workPackages = elements.filter((el) => el.type === palette.WORK_PACKAGE)

  if (workPackages.length > 0) {
    prompt.push(
      `There ${plural(workPackages.length, "is", "are")} ${
        workPackages.length
      } work package${
        workPackages.length > 1 ? "s" : ""
      }, which represent a series of actions identified and designed to achieve specific results within specified time and resource constraints.`
    )
    workPackages.forEach((workPackage, index) => {
      const lines = [
        nameAndDescription(
          index + 1,
          getLabel(workPackage),
          workPackage.documentation,
          workPackage.props,
          includeProperties,
          includeDoco
        ),
      ]

      const deliverableRels = elements.flatMap((el) =>
        el.connections.filter(
          (c) =>
            c.source.name === workPackage.name &&
            c.source.type === palette.WORK_PACKAGE &&
            c.target.type === palette.DELIVERABLE
        )
      )

      prompt.push(lines.join(". "))
    })
  }
}

const getElementsWithConnections = (
  elementInfo,
  elementTypes,
  connectionTypes
) => {
  const result = elementInfo
    .filter((element) => elementTypes.includes(element.type))
    .filter((el) =>
      el.connections.find((c) => connectionTypes.includes(c.type))
    )

  return result
}

const plural = (count, one, many) => {
  return count === 1 ? one : many
}

const getPromptLayers = (promptData) => {
  const result = promptData.elements.reduce((acc, curr) => {
    const type = palette.getElementType(curr.type)
    if (!acc.includes(type.layer.name)) {
      acc.push(type.layer.name)
    }
    return acc
  }, [])
  return result
}

const createPromptDataFromModelCache = (modelCacheItem, view) => {
  const allViewElements = modelServices.getViewElements(view, modelCacheItem)

  const viewElements = allViewElements.filter(
    (viewElement) => viewElement.symbolName !== "Note"
  )

  const notes = allViewElements.filter(
    (viewElement) => viewElement.symbolName === "Note"
  )

  //console.log("%cnotes", "color: #00ff00", notes)

  const notesWithReferenceElements = notes.map((note) => {
    const refs = note.sourceConnections.map((c) => c.connection.target)
    const els = viewElements.filter((el) => refs.includes(el.diagramObject.id))
    return {
      note,
      // Connected elements
      elements: els,
    }
  })

  const elementsWithConnections = viewElements
    .filter((element) => element.element)
    .map((element) => {
      const connections = element.sourceConnections
        .map((c) => {
          const relation = modelCacheItem.model.elements.find(
            (el) => el.id === c.connection.archimateRelationship
          )

          // If we can't find the relation it means the connection is a view-level connection only,
          // e.g. a note connection
          if (relation !== undefined) {
            const sourceViewElement = view.elements.find(
              (ve) => ve.diagramObject.id === c.connection.source
            )
            const targetViewElement = view.elements.find(
              (ve) => ve.diagramObject.id === c.connection.target
            )

            const sourceElement = modelCacheItem.model.elements.find(
              (el) => el.id === sourceViewElement.diagramObject.archimateElement
            )

            const targetElement = modelCacheItem.model.elements.find(
              (el) =>
                el.id === targetViewElement?.diagramObject.archimateElement
            )

            return {
              name: relation.name,
              type: relation.type,
              accessType: relation.accessType,
              source: { name: sourceElement?.name, type: sourceElement?.type },
              target: { name: targetElement?.name, type: targetElement?.type },
            }
          }
        })
        .filter((c) => c)

      if (!element || !element.element) {
        console.log("%cno element", "color: #ff0000", element)
      }

      return {
        id: element.element.id,
        name: element.label,
        documentation: element.element?.documentation,
        type: element.element?.type,
        props:
          element.element.properties?.map((p) => ({
            name: p.key,
            value: p.value,
          })) || [],
        connections: connections,
      }
    })
    // Filter out DiagramModelReference elements, i.e. links
    .filter((el) => el.type !== undefined)
    .sort((a, b) => a.name.localeCompare(b.name))

  const promptData = {
    view: { name: view.name, documentation: view.documentation },
    elements: elementsWithConnections,
    notes: notesWithReferenceElements,
  }
  //console.log("%ccreating prompt data", "color:lightgreen", { promptData, view })

  return promptData
}

export { createChatPrompt, createPromptDataFromModelCache, getPromptLayers }
