import * as actions from "../actionTypes"

const initialState = {
    value: {
        grouping: [],
        filter: [],
        searchFilter: "",
        selectedElementTypes: [],
        selectedElementHeaders: [],
        sortMethod: 'type'
    },
}

export default function projectExplorerSettings(state = initialState, action) {
    switch (action.type) {
        case actions.SET_PROJECT_EXPLORER_SETTINGS: {
            //console.log("[redux] Setting project explorer settings", action.payload.value)
            return {
                ...state,
                value: action.payload.value,
            }
        }

        default:
            return state
    }
}
