import React from "react"

const styles = {
    logo: {
        height: 32,
        width: 32,
    },
}

const Logo = () => {
    return <img style={styles.logo} src="/aim-cube-clear.png" alt="logo" />
}

export default Logo
