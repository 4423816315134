import React, { useState } from "react"
import * as common from "./common"
import { deepPurple } from "@mui/material/colors"
import BaseShapeOctagon from "./BaseShapeOctagon"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const Outcome = (props) => {
    const { x, y, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = deepPurple[200]
    const defaultColor = fillColor || deepPurple[100]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <BaseShapeOctagon {...props} fill={fill} setFill={setFill} />
            <svg x={width - 22} y={8}>
                {common.get3CirclesIcon(x, y)}

                <line x1={x + 5} y1={y + 5} x2={x + 14} y2={y - 3} stroke={common.outlineColor} />
                <line x1={x + 11} y1={y} x2={x + 12} y2={y - 5} stroke={common.outlineColor} />
                <line x1={x + 11} y1={y} x2={x + 16} y2={y - 1} stroke={common.outlineColor} />
            </svg>

            <ElementText {...props} label={label} x={props.x + 3} />
        </Svg>
    )
}

export default Outcome
