import React from "react"
import Header from "../components/Header"
import { Box } from "@mui/material"
import StoryGrid from "../components/StoryGrid"

const Stories = () => {
    return (
        <Header title="Stories">
            <Box padding={1}>
                <StoryGrid />
            </Box>
        </Header>
    )
}

export default Stories
