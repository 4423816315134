import * as colors from "@mui/material/colors"

const baseColors = [
    colors.red,
    colors.pink,
    colors.purple,
    colors.deepPurple,
    colors.indigo,
    colors.blue,
    colors.lightBlue,
    colors.cyan,
    colors.teal,
    colors.green,
    colors.lightGreen,
    colors.lime,
    colors.yellow,
    colors.amber,
    colors.orange,
    colors.deepOrange,
    colors.brown,
    colors.blueGrey,
    colors.grey,
]
const colorList = [
    ...baseColors.map((color) => color[500]),
    ...baseColors.map((color) => color[100]),
    ...baseColors.map((color) => color[900]),
]

const getColors = (count) => {
    return colorList.slice(0, count)
}

const aiColor = colors.pink["A100"]

const aiIcon = { color: aiColor }

const defaultColors = ["#d82828", "#f16c13", "#ffac00", "#7ecb0b", "#059ECC", "#8A46A7"]

// from https://codepen.io/njmcode/pen/NWdYBy

/**
 * TODO - refactor this as a (jQuery?) plugin!
 **/

// Converts a #ffffff hex string into an [r,g,b] array
var h2r = function (hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
        ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
        : null
}

// Inverse of the above
var r2h = function (rgb) {
    return "#" + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1)
}

// Interpolates two [r,g,b] colors and returns an [r,g,b] of the result
// Taken from the awesome ROT.js roguelike dev library at
// https://github.com/ondras/rot.js
var _interpolateColor = function (color1, color2, factor) {
    if (arguments.length < 3) {
        factor = 0.5
    }
    var result = color1.slice()
    for (var i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]))
    }
    return result
}

var rgb2hsl = function (color) {
    var r = color[0] / 255
    var g = color[1] / 255
    var b = color[2] / 255

    var max = Math.max(r, g, b),
        min = Math.min(r, g, b)
    var h,
        s,
        l = (max + min) / 2

    if (max === min) {
        h = s = 0 // achromatic
    } else {
        var d = max - min
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0)
                break
            case g:
                h = (b - r) / d + 2
                break
            case b:
                h = (r - g) / d + 4
                break
        }
        h /= 6
    }

    return [h, s, l]
}

var hsl2rgb = function (color) {
    var l = color[2]

    if (color[1] === 0) {
        l = Math.round(l * 255)
        return [l, l, l]
    } else {
        function hue2rgb(p, q, t) {
            if (t < 0) t += 1
            if (t > 1) t -= 1
            if (t < 1 / 6) return p + (q - p) * 6 * t
            if (t < 1 / 2) return q
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
            return p
        }

        var s = color[1]
        var q = l < 0.5 ? l * (1 + s) : l + s - l * s
        var p = 2 * l - q
        var r = hue2rgb(p, q, color[0] + 1 / 3)
        var g = hue2rgb(p, q, color[0])
        var b = hue2rgb(p, q, color[0] - 1 / 3)
        return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
    }
}

var _interpolateHSL = function (color1, color2, factor) {
    if (arguments.length < 3) {
        factor = 0.5
    }
    var hsl1 = rgb2hsl(color1)
    var hsl2 = rgb2hsl(color2)
    for (var i = 0; i < 3; i++) {
        hsl1[i] += factor * (hsl2[i] - hsl1[i])
    }
    return hsl2rgb(hsl1)
}

const interpolate = (hexColor1, hexColor2) => {
    const rgb1 = h2r(hexColor1)
    const rgb2 = h2r(hexColor2)

    console.log("colors", { color1: { hexColor1, rgb1 }, color2: { hexColor2, rgb2 } })

    const colors = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1].map((factor) =>
        _interpolateColor(rgb1, rgb2, factor)
    )

    console.log("colors", colors)

    const result = colors.map((rgb) => r2h(rgb))

    console.log("result", result)
    return result
}

export { getColors, interpolate, baseColors, aiIcon, aiColor, defaultColors }
