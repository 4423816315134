const handleDownloadSVG = (svgRefToDownload) => {
    const svg = svgRefToDownload.current
    const svgData = svg.outerHTML
    const svgBlob = new Blob([svgData], { type: "image/svg+xml" })
    const svgUrl = URL.createObjectURL(svgBlob)
    const downloadLink = document.createElement("a")
    downloadLink.href = svgUrl
    downloadLink.download = "diagram.svg"
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
}

export { handleDownloadSVG }
