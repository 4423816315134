// src/SimpleMarkdownRenderer.jsx

import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Link as MuiLink,
  List,
  ListItem,
  Box,
  Divider,
} from '@mui/material';

/**
 * SimpleMarkdownRenderer Component
 *
 * Parses a Markdown-like string and renders it using Material-UI components,
 * including bold headings and properly incremented ordered lists.
 *
 * @param {string} markdown - The Markdown content to render.
 * @returns {JSX.Element} The rendered Markdown as MUI components.
 */
const SimpleMarkdownRenderer = ({ markdown }) => {
  // Split the markdown into lines
  const lines = markdown.split('\n');

  // Array to hold the rendered elements
  const renderedElements = [];

  // Variables to handle code blocks
  let inCodeBlock = false;
  let codeBlockLanguage = '';
  let codeBlockContent = [];

  /**
   * Helper function to parse inline markdown (bold, italic, links, inline code)
   * @param {string} text - The text to parse.
   * @returns {Array} Array of React elements.
   */
  const parseInline = (text) => {
    const elements = [];
    let lastIndex = 0;

    // Regular expressions for different inline styles
    const regex = /(\*\*[^*]+\*\*)|(\*[^*]+\*)|(`[^`]+`)|(\[([^\]]+)\]\(([^)]+)\))/g;
    let match;

    while ((match = regex.exec(text)) !== null) {
      // Add text before the match
      if (match.index > lastIndex) {
        elements.push(text.substring(lastIndex, match.index));
      }

      if (match[1]) {
        // Bold
        const content = match[1].replace(/\*\*/g, '');
        elements.push(
          <Typography component="span" fontWeight="bold" key={regex.lastIndex}>
            {content}
          </Typography>
        );
      } else if (match[2]) {
        // Italic
        const content = match[2].replace(/\*/g, '');
        elements.push(
          <Typography component="span" fontStyle="italic" key={regex.lastIndex}>
            {content}
          </Typography>
        );
      } else if (match[3]) {
        // Inline Code
        const content = match[3].replace(/`/g, '');
        elements.push(
          <Box
            component="code"
            sx={{
              backgroundColor: 'grey.100',
              padding: '2px 4px',
              borderRadius: '4px',
              fontFamily: 'Monospace',
            }}
            key={regex.lastIndex}
          >
            {content}
          </Box>
        );
      } else if (match[4]) {
        // Link
        const text = match[5];
        const href = match[6];
        elements.push(
          <MuiLink href={href} target="_blank" rel="noopener noreferrer" key={regex.lastIndex}>
            {text}
          </MuiLink>
        );
      }

      lastIndex = regex.lastIndex;
    }

    // Add remaining text
    if (lastIndex < text.length) {
      elements.push(text.substring(lastIndex));
    }

    return elements;
  };

  /**
   * Function to parse the markdown into blocks
   * @returns {Array} Array of block objects.
   */
  const parseMarkdown = () => {
    const blocks = [];
    let i = 0;

    while (i < lines.length) {
      let line = lines[i].trim();

      // Handle code blocks
      if (line.startsWith('```')) {
        const codeBlockLanguage = line.slice(3).trim();
        i++;
        const codeLines = [];
        while (i < lines.length && !lines[i].trim().startsWith('```')) {
          codeLines.push(lines[i]);
          i++;
        }
        i++; // Skip the closing ```
        blocks.push({
          type: 'code_block',
          content: codeLines.join('\n'),
        });
        continue;
      }

      // Handle horizontal rules
      if (/^(\*\s*){3,}$/.test(line) || /^(-\s*){3,}$/.test(line) || /^(_\s*){3,}$/.test(line)) {
        blocks.push({
          type: 'horizontal_rule',
        });
        i++;
        continue;
      }

      // Handle blockquotes
      if (line.startsWith('>')) {
        const content = line.replace('>', '').trim();
        blocks.push({
          type: 'blockquote',
          content,
        });
        i++;
        continue;
      }

      // Handle headings with optional explicit numbering
      const headingMatch = line.match(/^(\d+\.\s+)?(#{1,6})\s+(.*?)(\s+#{1,6})?$/);
      if (headingMatch) {
        const explicitNumbering = headingMatch[1] ? headingMatch[1].trim() : '';
        const hashes = headingMatch[2];
        const text = headingMatch[3].trim();
        const level = hashes.length;

        blocks.push({
          type: 'heading',
          content: { level, text, explicitNumbering },
        });
        i++;
        continue;
      }

      // Handle unordered lists
      const unorderedListMatch = line.match(/^[-*+]\s+(.*)/);
      if (unorderedListMatch) {
        const items = [];
        while (i < lines.length && lines[i].trim().match(/^[-*+]\s+(.*)/)) {
          const itemMatch = lines[i].trim().match(/^[-*+]\s+(.*)/);
          if (itemMatch) {
            items.push(parseInline(itemMatch[1].trim()));
          }
          i++;
        }
        blocks.push({
          type: 'unordered_list',
          content: items,
        });
        continue;
      }

      // Handle ordered lists
      const orderedListMatch = line.match(/^(\d+)\.\s+(.*)/);
      if (orderedListMatch) {
        const startNumber = parseInt(orderedListMatch[1], 10);
        const items = [];
        let currentNumber = startNumber;

        while (i < lines.length && lines[i].trim().match(/^(\d+)\.\s+(.*)/)) {
          const itemMatch = lines[i].trim().match(/^(\d+)\.\s+(.*)/);
          if (itemMatch) {
            const number = parseInt(itemMatch[1], 10);
            const text = itemMatch[2].trim();

            // Optionally, handle non-consecutive numbering
            // For simplicity, we'll use the provided number

            items.push(parseInline(text));
            currentNumber = number + 1;
          }
          i++;
        }
        blocks.push({
          type: 'ordered_list',
          content: items,
          startNumber,
        });
        continue;
      }

      // Handle paragraphs
      if (line !== '') {
        const paragraphLines = [];
        while (
          i < lines.length &&
          lines[i].trim() !== '' &&
          !lines[i].trim().startsWith('```') &&
          !lines[i].trim().match(/^[-*+]\s+/) &&
          !lines[i].trim().match(/^\d+\.\s+/) &&
          !lines[i].trim().startsWith('>') &&
          !lines[i].trim().match(/^(\d+\.\s+)?#{1,6}\s+/) &&
          !/^(\*\s*){3,}$/.test(lines[i].trim()) &&
          !/^(-\s*){3,}$/.test(lines[i].trim()) &&
          !/^(_\s*){3,}$/.test(lines[i].trim())
        ) {
          paragraphLines.push(lines[i].trim());
          i++;
        }
        blocks.push({
          type: 'paragraph',
          content: paragraphLines.join(' '),
        });
        continue;
      }

      // Handle empty lines
      i++;
    }

    return blocks;
  };

  /**
   * Function to render a single block
   * @param {Object} block - The block object.
   * @param {number} index - The index of the block.
   * @returns {JSX.Element|null} The rendered block or null.
   */
  const renderBlock = (block, index) => {
    const { type, content, startNumber } = block;

    switch (type) {
      case 'heading':
        const { level, text, explicitNumbering } = content;

        // If there's explicit numbering, use it; otherwise, no numbering
        let numbering = '';
        if (explicitNumbering) {
          numbering = `${explicitNumbering} `;
        } else if (level === 3) {
          // For level 3 headings, treat them as bold headings without numbering
          numbering = '';
        }

        // Determine Typography variant based on heading level
        let variant = 'h5';
        switch (level) {
          case 1:
            variant = 'h4';
            break;
          case 2:
            variant = 'h5';
            break;
          case 3:
            variant = 'h6';
            break;
          case 4:
            variant = 'subtitle1';
            break;
          case 5:
            variant = 'subtitle2';
            break;
          case 6:
            variant = 'body1';
            break;
          default:
            variant = 'h5';
        }

        // If it's a level 3 heading, apply bold styling
        if (level === 3) {
          return (
            <Typography variant={variant} gutterBottom key={index} fontWeight="bold">
              {numbering}
              {parseInline(text)}
            </Typography>
          );
        }

        // For other headings, render normally with optional numbering
        return (
          <Typography variant={variant} gutterBottom key={index}>
            {numbering}
            {parseInline(text)}
          </Typography>
        );

      case 'paragraph':
        return (
          <Typography variant="body1" paragraph key={index}>
            {parseInline(content)}
          </Typography>
        );

      case 'unordered_list':
        return (
          <List key={index} sx={{ listStyleType: 'disc', pl: 4 }}>
            {content.map((item, idx) => (
              <ListItem key={idx} sx={{ display: 'list-item', pl: 0 }}>
                {item}
              </ListItem>
            ))}
          </List>
        );

      case 'ordered_list':
        return (
          <List key={index} component="ol" sx={{ listStyleType: 'decimal', pl: 4 }} start={startNumber}>
            {content.map((item, idx) => (
              <ListItem key={idx} sx={{ display: 'list-item', pl: 0 }}>
                {item}
              </ListItem>
            ))}
          </List>
        );

      case 'blockquote':
        return (
          <Box
            key={index}
            sx={{
              borderLeft: '4px solid',
              borderColor: 'primary.main',
              paddingLeft: 2,
              color: 'text.secondary',
              my: 2,
            }}
          >
            <Typography variant="body1">{parseInline(content)}</Typography>
          </Box>
        );

      case 'horizontal_rule':
        return <Divider key={index} sx={{ my: 2 }} />;

      case 'code_block':
        return (
          <Box
            component="pre"
            key={index}
            sx={{
              backgroundColor: 'grey.100',
              padding: 2,
              borderRadius: 1,
              overflow: 'auto',
              my: 2,
            }}
          >
            <Typography
              component="code"
              variant="body2"
              fontFamily="Monospace"
            >
              {content}
            </Typography>
          </Box>
        );

      default:
        return null;
    }
  };

  // Parse the markdown into blocks
  const blocks = parseMarkdown();

  // Render each block
  blocks.forEach((block, index) => {
    const element = renderBlock(block, index);
    if (element) {
      renderedElements.push(element);
    }
  });

  return <Box sx={{ padding: 2 }}>{renderedElements}</Box>;
};

// PropTypes for type checking
SimpleMarkdownRenderer.propTypes = {
  markdown: PropTypes.string.isRequired,
};

export default SimpleMarkdownRenderer;
