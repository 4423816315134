function showTooltip(evt, label, text, properties = []) {
    let tooltip = document.getElementById("tooltip")
    let tooltipText = document.getElementById("tooltipText")
    let cardTitle = document.getElementById("cardTitle")

    //const textFormatted = text && text.length > 0 ? convertTextToHtml(text) : ""
    const propertiesFormatted =
        properties && properties.map((p) => `<div>${p.key}:${p.value}</div>`).join("")

    // console.log("%cshowTooltip", "color: chartreuse", {
    //     label,
    //     text,
    //     properties,
    //     textFormatted,
    //     propertiesFormatted,
    // })

    if (cardTitle && label && label.length > 0) {
        cardTitle.innerText = label
    }
    if (tooltip && ((text && text.length) > 0 || properties.length > 0)) {
        const lines =
            (text && text.length > 0 && text[0].split("\n").map((line) => line.trim())) || []
        tooltipText.innerHTML =
            lines.map((line) => `<div>${line}</div>`).join("") + propertiesFormatted

        tooltip.style.display = "block"
        tooltip.style.left = evt.pageX + 10 + "px"
        tooltip.style.top = evt.pageY + 10 + "px"
    }
}

const convertTextToHtml = (textLines) => {
    const lines = splitText(textLines)
    const html = lines.map((line) => `<div>${line}</div>`).join("")
    return html
}

const splitText = (text) => {
    if (text && text.length > 0) {
        return text[0].split("\n").map((line) => line.trim())
    }
    return []
}

function hideTooltip() {
    var tooltip = document.getElementById("tooltip")
    if (tooltip) {
        tooltip.style.display = "none"
    }
}

export { showTooltip, hideTooltip, convertTextToHtml, splitText }
