import { Typography, styled } from "@mui/material"

const TruncatedText = styled(Typography)({
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%", // You can set this to any desired width
})

export default TruncatedText
