import React, { useState } from "react"
import ComponentEditForm from "../components/ComponentEditForm"
import Header from "../components/Header"
import { Paper, Tabs, Tab, Typography } from "@mui/material"
import { a11yProps } from "../components/TabPanel"
import AutoStoriesIcon from "@mui/icons-material/AutoStories"
import { spacing } from "../pages/services/styleServices"

import * as icons from "../icons"

const styles = {
    tabLabel: {
        textTransform: "none",
    },
    tab: {
        padding: 0,
        paddingRight: spacing(1.5),
        marginLeft: "5px",
    },
}

const ComponentEdit = (props) => {
    const [title, setTitle] = useState("Edit Component")

    const [tabIndex, setTabIndex] = useState(0)

    const tabLabel = (label) => {
        return (
            <Typography sx={styles.tabLabel} component={"span"}>
                {label}
            </Typography>
        )
    }

    return (
        <Header title={title}>
            <Tabs
                value={tabIndex}
                onChange={(event, newIndex) => {
                    //dispatch(setSelectedTabIndex(newIndex))
                    setTabIndex(newIndex)
                }}
                aria-label="Component"
                sx={styles.tabs}
            >
                <Tab
                    label={tabLabel("Component")}
                    {...a11yProps(0)}
                    //icon={<icons.ComponentIcon />}
                    iconPosition="start"
                    sx={styles.tab}
                />
                <Tab
                    label={tabLabel("Stories")}
                    {...a11yProps(1)}
                    //icon={<AutoStoriesIcon />}
                    iconPosition="start"
                    sx={styles.tab}
                />
            </Tabs>

            <Paper>
                <ComponentEditForm
                    {...props}
                    setTitle={setTitle}
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                />
            </Paper>
        </Header>
    )
}

export default ComponentEdit
