import { Box, Paper, Stack, Typography } from "@mui/material"
import React, { useMemo } from "react"
import { formatAmount } from "../pages/services/formatting"
import { spacing } from "../pages/services/styleServices"
import Controls from "./controls/Controls"
import { secsToDateStr, secsToLocaleDateStr } from "../pages/services/dateServices"

const styles = {
    pageContent: {
        marginTop: spacing(1),
        marginLeft: spacing(0),
        marginRight: spacing(1),
        padding: spacing(1),
    },
}

const StripeNextInvoice = ({ invoice, taxRates }) => {
    const taxes = useMemo(() => {
        if (invoice && invoice.total_tax_amounts && taxRates) {
            const taxInfo = invoice.total_tax_amounts.map((tax) => {
                console.log("tax", { tax, default_tax_rates: invoice.default_tax_rates })
                //const lineTaxRates = invoice.lines.data[0].tax_rates
                //const allTaxRates = [...invoice.default_tax_rates, ...lineTaxRates]
                const taxRate = taxRates.find((tr) => tr.id === tax.tax_rate)
                console.log("taxRate", taxRate)
                const amount = formatAmount(tax.amount)

                return {
                    description: `${taxRate?.percentage}% ${taxRate?.display_name} (${taxRate?.jurisdiction})`,
                    amount: amount,
                }
            })

            console.log("taxInfo", taxInfo)

            return taxInfo
        }
    }, [invoice, taxRates])
    return (
        <Paper sx={styles.pageContent}>
            <Stack direction="column" gap={2}>
                <Stack>
                    <Typography
                        variant="h6"
                        component={"span"}
                        gutterBottom={false}
                        paragraph={false}
                    >
                        Next Invoice Estimate
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                        Stripe automatically prorates the invoice based on any mid-month changes to
                        the number of licensed seats
                    </Typography>
                </Stack>

                {/* <pre>{JSON.stringify(invoice, null, 2)}</pre> */}

                <Stack>
                    <Typography variant="caption" color="text.secondary">
                        Invoice Amount
                    </Typography>
                    <Box>{formatAmount(invoice.amount_due)}</Box>
                </Stack>
                <Stack gap={1}>
                    <Typography variant="caption" color="text.secondary">
                        Includes taxes
                    </Typography>
                    <Box>
                        {taxes &&
                            taxes.map((tax) => (
                                <Box key={tax.description}>
                                    {tax.description}: {tax.amount}
                                </Box>
                            ))}
                    </Box>
                    {invoice && invoice.lines && invoice.lines?.data.length > 0 && (
                        <Box>
                            <Typography variant="caption" color="text.secondary">
                                Invoice lines
                            </Typography>
                            {invoice.lines.data.map((line) => (
                                <Stack direction="row" key={line.id}>
                                    <Box sx={{ width: "400px" }}>{line.description}</Box>
                                    <Box>{formatAmount(line.amount)}</Box>
                                </Stack>
                            ))}
                        </Box>
                    )}
                </Stack>

                <Box>
                    <Controls.Readonly
                        sx={{ width: "200px" }}
                        name="invoice_period"
                        label="Period"
                        value={`${secsToLocaleDateStr(
                            invoice.period_start
                        )} - ${secsToLocaleDateStr(invoice.period_end)}`}
                    />
                </Box>

                <Typography variant="caption">
                    This is an estimate of your next invoice. It is not payable yet. The total
                    amount will reflect pro-rating of any changes to number of licensed seats during
                    the month. That is, it may not match the number of seats currently shown
                    multiplied by the respective per-seat rates
                </Typography>
            </Stack>
        </Paper>
    )
}

export default StripeNextInvoice
