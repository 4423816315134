import React, { useState } from "react"
import * as common from "./common"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const Location = (props) => {
    const { x, y, height, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = "#F9A34D"
    const defaultColor = fillColor || common.DEFAULT_LOCATION_COLOR

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    // curve width
    const cw = 15

    // curve height
    const ch = 10

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <rect
                x={x + 1}
                y={y + 1}
                fill={fill}
                stroke={common.outlineColor}
                width={width - 1}
                height={height - 1}
            />

            {/* Draw little location marker */}
            <svg x={x + width - 25} y={y + 5}>
                {/* https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths */}
                <path
                    d={`M 0 ${ch} C 0 0, ${cw} 0, ${cw} ${ch} l ${-cw / 2} 10 l ${-cw / 2} -10 z`}
                    stroke={common.outlineColor}
                    fill='transparent'
                />
            </svg>

            <ElementText {...props} label={label} />
        </Svg>
    )
}

export default Location
