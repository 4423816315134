import { Alert } from "@mui/material"

const ModelEditMaxViewsReached = ({ canAddView, isPaid }) => {
  return (
    <>
      {canAddView === false && isPaid && (
        <Alert severity="info">
          You have reached the maximum number of views.
        </Alert>
      )}
      {canAddView === false && !isPaid && (
        <Alert severity="info">
          You have reached the maximum number of views. Go to Config |
          Subscription to upgrade.
        </Alert>
      )}
    </>
  )
}

export default ModelEditMaxViewsReached
