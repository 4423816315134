import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import Controls from "./controls/Controls"
import { useState } from "react"

const ModelEditCreateProcessDialog = ({
  open,
  onClose,
  handleCreateProcess,
}) => {
  const [values, setValues] = useState({ name: "" })
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Process</DialogTitle>
      <DialogContent>
        <DialogContentText>Create a new process</DialogContentText>
        <Box sx={{ marginTop: "10px", marginBottom: "10px" }}>
          <Controls.TextInput
            name="name"
            label="Name"
            value={values.name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleCreateProcess({ values })
              }
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          sx={{ textTransform: "none" }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ textTransform: "none" }}
          onClick={() => handleCreateProcess({ values })}
        >
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModelEditCreateProcessDialog
