import React, { useState } from "react"
import BaseShapeRoundedRect from "./BaseShapeRoundedRect"
import * as common from "./common"
import { lightBlue } from "@mui/material/colors"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const ApplicationProcess = (props) => {
    const { x, y, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = lightBlue[200]
    const defaultColor = fillColor || lightBlue[100]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <BaseShapeRoundedRect {...props} fill={fill} setFill={handleFill} />

            <svg x={x} y={y}>
                <path
                    d={`M${width - 26},${8} ${common.markers["arrow"]} z`}
                    stroke={common.outlineColor}
                    fill="none"
                />
            </svg>

            <ElementText {...props} label={label} />
        </Svg>
    )
}

export default ApplicationProcess
