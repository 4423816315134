import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
} from "@mui/material"
import React, { useMemo, createRef, useState } from "react"
import Controls from "./controls/Controls"
import * as colors from "@mui/material/colors"
import PaletteIcon from "@mui/icons-material/Palette"
import DeleteIcon from "@mui/icons-material/Delete"
import { spacing } from "../pages/services/styleServices"
import DraggablePaper from "../components/DraggablePaper"
import TruncatedText from "./TruncatedText"
import * as colorServices from "../pages/services/colorServices"

const styles = {
  colorPick: {
    width: 25,
    height: 20,
  },
  colorRows: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: spacing(3),
    "& > *": {
      marginBottom: spacing(3),
    },
  },
  colorGrades: {
    marginRight: spacing(1.5),
    "& > *": {
      marginBottom: spacing(1),
    },
  },
}

const ShaderDialog = (props) => {
  const { open, setOpen, shaders, propOptions, propNameToEdit, setPropColor } =
    props

  const propValues = useMemo(() => {
    const values =
      propOptions.find((prop) => prop.name === propNameToEdit)?.values || []

    // Conditionally convert to string if numbers
    if (values.length > 0 && typeof values[0] === "number") {
      const valuesAsStr = values.map((v) => v.toString())
      return valuesAsStr.sort((a, b) => a.localeCompare(b))
    } else {
      return values.sort((a, b) => a.localeCompare(b))
    }
  }, [propNameToEdit, propOptions])

  const shader = useMemo(() => {
    return (
      shaders.find((shader) => shader.name === propNameToEdit) || {
        name: propNameToEdit,
        config: { colors: [] },
      }
    )
  }, [shaders, propNameToEdit])

  // The colors in the shader that aren't used in any of the view
  const unusedColors = useMemo(() => {
    if (shader && propValues) {
      return shader.config.colors
        .filter((c) => propValues.includes(c.value) === false)
        .map((c) => c.value)
    }
  }, [shader, propValues])

  // The combination of all colours that are used and unused
  const combinedColors = useMemo(() => {
    if (unusedColors && propValues) {
      return [...propValues, ...unusedColors].sort((a, b) => a.localeCompare(b))
    }
    return []
  }, [propValues, unusedColors])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      PaperComponent={DraggablePaper}
    >
      <DialogTitle sx={{ cursor: "move" }} id="draggable-dialog-title">
        Select colours for {propNameToEdit}
      </DialogTitle>
      <DialogContent>
        <Box>
          {combinedColors &&
            combinedColors.map((propValue) => {
              const isUnused = unusedColors && unusedColors.includes(propValue)
              const nameProps =
                (isUnused && {
                  fontStyle: "italic",
                  color: colors.grey[500],
                }) ||
                {}

              return (
                <Box
                  key={propValue}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <TruncatedText sx={{ ...nameProps, width: "150px" }}>
                    {propValue}
                  </TruncatedText>

                  <Box sx={{ marginLeft: "auto" }}>
                    <SelectColor
                      data={{
                        color:
                          shader.config.colors.find(
                            (item) => item.value === propValue
                          )?.color || colors.grey[400],
                      }}
                      setData={(colorSelection) =>
                        setPropColor(
                          propNameToEdit,
                          propValue,
                          colorSelection.color
                        )
                      }
                    />
                  </Box>
                </Box>
              )
            })}
        </Box>
      </DialogContent>
      <DialogActions>
        <Controls.Button
          text="OK"
          variant="contained"
          onClick={() => setOpen(false)}
        />
      </DialogActions>
    </Dialog>
  )
}

const SelectColor = (props) => {
  const { data = { color: colors.red[600] }, setData } = props

  const [anchorEl, setAnchorEl] = useState(null)

  const [enteredColor, setEnteredColor] = useState(data.color)

  const iconRef = createRef()

  const handleSelectColor = (color) => {
    const newData = {
      ...data,
      color: color,
    }
    setData(newData)
  }

  const handleMenuOpen = (event) => {
    if (anchorEl === null) {
      setAnchorEl(event.target)
    } else {
      setAnchorEl(null)
    }
  }

  const handleDeleteColor = () => {
    const newData = {
      ...data,
      color: null,
    }
    setData(newData)
  }

  // Validate if the input is a valid color in the form of #XXXXXX, where X = a-f, 0-9, and could be upper or lower case
  const isInputValidColor = (color) => {
    const regex = /^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/
    return regex.test(color)
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 1,
      }}
    >
      <PickColorMenu
        setAnchorEl={setAnchorEl}
        handleSelectColor={handleSelectColor}
        anchorEl={anchorEl}
      />

      <Box
        sx={{
          backgroundColor: data.color,
          width: 30,
          height: 30,
          borderRadius: 2,
          marginRight: "20px",
        }}
      />
      <Box sx={{ width: "75px" }}>
        <Controls.TextInput
          value={enteredColor}
          sx={{ textTransform: "uppercase" }}
          onChange={(e) => setEnteredColor(e.target.value)}
          onBlur={(e) => {
            if (isInputValidColor(e.target.value))
              handleSelectColor(e.target.value)
          }}
        />
      </Box>
      <Tooltip title="Select color">
        <IconButton ref={iconRef} onClick={handleMenuOpen} size="small">
          <PaletteIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton onClick={(e) => handleDeleteColor()} size="small">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

const ColorBox = ({ color, setColor }) => {
  return (
    <Box
      onClick={() => setColor(color)}
      sx={styles.colorPick}
      style={{ backgroundColor: color }}
    />
  )
}

const ColorRow = (props) => {
  const { baseColor, setColor } = props
  const grades = [100, 200, 300, 400, 500, 600, 700, 800, 900]

  const AGrades = ["A100", "A200", "A400", "A700"]

  const colorsWithoutLast4 = [
    colors.blueGrey[50],
    colors.brown[50],
    colors.grey[50],
  ]

  const getGrades = () => {
    return colorsWithoutLast4.includes(baseColor[50])
      ? grades
      : [...grades, ...AGrades]
  }

  return (
    <Box sx={styles.colorGrades}>
      {getGrades().map((grade) => {
        return (
          <ColorBox
            key={`${baseColor[50]}-${baseColor[grade]}`}
            color={baseColor[grade]}
            setColor={setColor}
          />
        )
      })}
    </Box>
  )
}

const PickColorMenu = ({ setAnchorEl, handleSelectColor, anchorEl }) => {
  const rows = [
    [
      colors.red,
      colors.pink,
      colors.purple,
      colors.deepPurple,
      colors.indigo,
      colors.blue,
      colors.lightBlue,
    ],
    [
      colors.cyan,
      colors.teal,
      colors.green,
      colors.lightGreen,
      colors.lime,
      colors.yellow,
      colors.amber,
    ],
    [
      colors.orange,
      colors.deepOrange,
      colors.brown,
      colors.grey,
      colors.blueGrey,
    ],
  ]

  return (
    <Menu
      anchorEl={anchorEl}
      open={anchorEl !== null}
      onClose={() => setAnchorEl(null)}
      sx={styles.colorMenu}
    >
      <MenuItem>
        <Box sx={styles.colorRows}>
          <Stack direction="row" gap={1.5}>
            {colorServices.defaultColors.map((color) => (
              <ColorBox
                key={color}
                color={color}
                setColor={handleSelectColor}
              />
            ))}
          </Stack>
          {rows.map((row, index) => (
            <Box style={{ display: "flex", flexDirection: "row" }} key={index}>
              {row.map((color) => {
                //console.log('color', color)
                return (
                  <ColorRow
                    key={color[50]}
                    baseColor={color}
                    setColor={handleSelectColor}
                  />
                )
              })}
            </Box>
          ))}
        </Box>
      </MenuItem>
      <MenuItem onClick={() => setAnchorEl(null)}>Close</MenuItem>
    </Menu>
  )
}

export default ShaderDialog
