import { spacing } from "../pages/services/styleServices"

const classProps = () => ({
    tooltip: {
        background: "#fff",
        borderRadius: "5px",
        padding: spacing(0.5),
        margin: spacing(0.5),
        maxWidth: 500,
        "& > *": {
            paddingBottom: spacing(1),
        },
    },
})

export { classProps }
