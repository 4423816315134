import { Box } from "@mui/material"
import ElementText from "./symbols/ElementText"
import * as colors from "@mui/material/colors"
import {
    getDate,
    getProjectsDateRange,
    addProjectChartAttributes,
    getElapsedMonths,
    getShaderColor,
    START_DATE_LOWER,
    END_DATE_LOWER,
} from "../pages/services/modelEditServices"
import { Fragment, useEffect, useMemo, useRef } from "react"
import DiagramButtons from "./DiagramButtons"
import { handleDownloadSVG } from "../pages/services/svgServices"
import { FONT_FAMILY } from "../pages/services/svgUtils"

const LINE_OFFSET = 100

const MARGIN_TOP = 20

const LINE_X = 255

const LINE_RIGHT_MARGIN = 90

const ProjectPortfolioChart = (props) => {
    const { projects, width, currentShader, portfolioDateRange } = props

    const height = projects && projects.length * LINE_OFFSET + MARGIN_TOP

    const labelWidth = 300

    const svgRef = useRef()

    const workPackages = useMemo(
        () => addProjectChartAttributes(projects, portfolioDateRange),
        [projects, portfolioDateRange]
    )

    // Get the x position of a date
    const getXPos = (projectDateRange, date) => {
        if (!date || projectDateRange.startDate === null) {
            return null
        }
        // Get % of date between min and max dates
        const percent =
            (date.getTime() - projectDateRange.startDate) /
            (projectDateRange.endDate - projectDateRange.startDate)

        // Get the length of the line
        const lineLength = width - LINE_RIGHT_MARGIN - LINE_X

        // Get the x position
        const xPos = percent * lineLength + LINE_X

        return Math.floor(xPos)
    }

    return (
        <Box>
            {workPackages && workPackages.length > 0 && (
                <>
                    <Box>
                        <svg width={width} viewBox={`0 0 ${width} ${height}`} ref={svgRef}>
                            <rect
                                x={0}
                                y={0}
                                fill={"#fff"}
                                stroke={"#fff"}
                                width={width}
                                height={height}
                            />
                            {workPackages.map((wp, index) => {
                                const y = MARGIN_TOP + index * LINE_OFFSET
                                const lineY = y + 10
                                const lineLength = width - LINE_RIGHT_MARGIN

                                const startDate = getDate(wp, START_DATE_LOWER)
                                const endDate = getDate(wp, END_DATE_LOWER)

                                const projectLines = []
                                const elapsedMonths = getElapsedMonths(startDate, endDate)
                                if (elapsedMonths) {
                                    projectLines.push(`${elapsedMonths} months`)
                                }
                                if (wp.description) {
                                    projectLines.push(wp.description)
                                }

                                const xStartPos = getXPos(portfolioDateRange, startDate)
                                const xEndPos = getXPos(portfolioDateRange, endDate)

                                return (
                                    <Fragment key={wp.id}>
                                        <rect
                                            x={0}
                                            y={y}
                                            width={10}
                                            height={20}
                                            fill={getShaderColor(wp, currentShader)}
                                        />
                                        <ElementText
                                            label={wp.name}
                                            x={10}
                                            y={y}
                                            width={labelWidth}
                                            fontSize={14}
                                            showLabel={true}
                                            styles={{ fontWeight: "bold" }}
                                            fontFamily={FONT_FAMILY}
                                        />
                                        {projectLines.map((line, lineIndex) => (
                                            <ElementText
                                                key={`${line}-${index}`}
                                                label={line}
                                                x={10}
                                                y={y + (lineIndex + 1) * 16}
                                                width={labelWidth}
                                                fontSize={11.5}
                                                showLabel={true}
                                                styles={{ fill: "#555" }}
                                                fontFamily={FONT_FAMILY}
                                            />
                                        ))}

                                        <line
                                            x1={LINE_X}
                                            y1={lineY}
                                            x2={lineLength}
                                            y2={lineY}
                                            stroke={colors.blue[200]}
                                            strokeWidth="3"
                                        />
                                        {xStartPos && xEndPos && (
                                            <line
                                                x1={xStartPos}
                                                y1={lineY}
                                                x2={xEndPos}
                                                y2={lineY}
                                                stroke={colors.blue[500]}
                                                strokeWidth="6"
                                            />
                                        )}
                                        {wp.marks &&
                                            wp.startDate &&
                                            wp.endDate &&
                                            wp.marks
                                                // .filter(
                                                //     (mark) =>
                                                //         mark.value < wp.startDate.getTime() ||
                                                //         mark.value > wp.endDate.getTime()
                                                // )
                                                .map((mark) => {
                                                    const xPos = getXPos(
                                                        portfolioDateRange,
                                                        mark.date
                                                    )
                                                    return (
                                                        <Fragment key={mark.labelVal}>
                                                            <circle
                                                                cx={xPos}
                                                                cy={lineY}
                                                                r={2}
                                                                fill={colors.blue[300]}
                                                            />
                                                            <ElementText
                                                                label={mark.labelVal}
                                                                x={xPos - 20}
                                                                y={lineY + 8}
                                                                width={100}
                                                                showLabel={true}
                                                                fontSize={11}
                                                                fontFamily={FONT_FAMILY}
                                                                styles={{ fill: "#555" }}
                                                            />
                                                        </Fragment>
                                                    )
                                                })}
                                        {xStartPos && (
                                            <>
                                                <circle
                                                    cx={xStartPos}
                                                    cy={lineY}
                                                    r="7"
                                                    fill={colors.blue[500]}
                                                />
                                                <ElementText
                                                    label={wp.startDateLabel}
                                                    x={xStartPos - 30}
                                                    y={lineY - 30}
                                                    width={100}
                                                    showLabel={true}
                                                    fontSize={10}
                                                    fontFamily={FONT_FAMILY}
                                                    styles={{ fill: "#555" }}
                                                />
                                            </>
                                        )}
                                        {xEndPos && (
                                            <>
                                                <circle
                                                    cx={xEndPos}
                                                    cy={lineY}
                                                    r="7"
                                                    fill={colors.blue[500]}
                                                />
                                                <ElementText
                                                    label={wp.endDateLabel}
                                                    x={xEndPos - 30}
                                                    y={lineY - 30}
                                                    width={100}
                                                    showLabel={true}
                                                    fontSize={10}
                                                    fontFamily={FONT_FAMILY}
                                                    styles={{ fill: "#555" }}
                                                />
                                            </>
                                        )}
                                    </Fragment>
                                )
                            })}
                        </svg>
                    </Box>
                    <Box>
                        <DiagramButtons
                            svgRef={svgRef}
                            showCreateStoryButton={false}
                            showCopyButton={true}
                            handleDownloadSVG={handleDownloadSVG}
                        />
                    </Box>
                </>
            )}
        </Box>
    )
}

export default ProjectPortfolioChart
