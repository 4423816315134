import * as actions from '../actionTypes'

// initial state is no items selected
// each item is:
// {
//    id: work order document id
//    label: project name
// }

const initialState = { 
    value: []
}

export default function projectGridSelectedItems(state = initialState, action) {

    switch(action.type) {

        case actions.SET_PROJECT_GRID_SELECTED_ITEMS: {

            //console.log('[redux] Setting project grid selected items', action.payload)
            return {
                ...state,
                value: action.payload.value
            }
        }

        default:
            return state
    }
}