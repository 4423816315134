const outputFormatSpecificationPrompt = {
    role: "user",
    content:
        //"Your response MUST be a JSON array of elements, where each element has a 'name', 'description', and then an 'elementIds' attribute which is an array of diagram ids that relate to the problem area. Element ids are shown after the element name in brackets and have the following format: [ID:<element id>]. When you extract element ids to put into your response you must only extract values after the '[ID:' and before the next ']' ",

        `Generate a story made up of multiple parts. Each part has the following attributes:
'name' - an informative name for this story part
'description' - a piece of narrative explaining this part of the story. This must not include any ID values.
'elementIds', where elementIds is an array of [ID: ...] values of the diagram element relevant to the paragraph. The values in the 'elementIds' array MUST be taken from the [ID: ...] text after each element name.`,
    formatSpec: true,
}

/**
 *
 * @param {*} prompt | which may or may not have a ${prompt} placeholder to be replaced with a diagram description
 * @param {*} diagramDescription | description of the ArchiMate diagram elements
 * @returns
 */
const replaceDiagramPromptPlaceholder = (prompt, diagramDescription) => {
    const vars = { diagram: diagramDescription }
    return prompt.replace(/\$\{([^\}]+)\}/g, (_, match) => vars[match])
}

export { replaceDiagramPromptPlaceholder, outputFormatSpecificationPrompt }
