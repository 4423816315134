import React, { useState } from "react"
import * as common from "./common"
import { yellow } from "@mui/material/colors"
import BasicShapeWavyBox from "./BasicShapeWavyBox"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const Representation = (props) => {
    const { x, y, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = yellow[200]
    const defaultColor = fillColor || yellow[100]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    const barOffset = 12

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <BasicShapeWavyBox {...props} fill={fill} />

            <line
                x1={x}
                y1={y + barOffset}
                x2={x + width}
                y2={y + barOffset}
                stroke={common.outlineColor}
                strokeWidth={1}
            />

            <ElementText {...props} label={label} y={props.y + 10} />
        </Svg>
    )
}

export default Representation
