import React, { useState } from "react"
import * as common from "./common"
import ElementText from "./ElementText"
import Svg from "./Svg"

const Group = (props) => {
    const { x, y, height, width, label, fillColor } = props

    const hoverColor = "#f4f4f4"
    const defaultColor = fillColor || "#dddddd"

    const [fill, setFill] = useState(defaultColor)

    const barOffset = 15

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={setFill}>
            <rect
                x={x}
                y={y}
                fill={fill}
                stroke={common.outlineColor}
                strokeWidth={0.75}
                width={width - Math.min(50, width)}
                height={barOffset}
                strokeDasharray='4 3'
            />

            <rect
                x={x}
                y={y + barOffset}
                fill={fill}
                stroke={common.outlineColor}
                strokeWidth={0.75}
                width={width}
                height={height - barOffset}
                strokeDasharray='4 3'
            />

            <ElementText {...props} label={label} y={y - 3} />
        </Svg>
    )
}

export default Group
