import { Dialog, DialogContent, DialogTitle } from "@mui/material"
import ModelEditCreateContent from "./ModelEditCreateContent"

const ModelEditCreateContentDialog = ({
  open,
  onClose,
  accountId,
  selectedViewId,
  currentElement,
  currentView,
  viewSet,
  handlePasteAdd,
  setTextResponse,
  setWaitingElementIds,
  generationPanelCount,
  setGenerationPanelCount,
  // External request to stop generating content
  stopRequested,
  setGeneratingContentMessage,
  // Pass back the prompt information used for each level of generation. This gets appended to the view prompt history
  handleUpdatePromptHistory,
  views,
  roles,
  defaultLevelSpecs,
  assistants,
}) => {
  return (
    <Dialog open={open} onClose={onClose} keepMounted>
      <DialogTitle>Create View</DialogTitle>
      <DialogContent>
        <ModelEditCreateContent
          accountId={accountId}
          selectedViewId={selectedViewId}
          currentElement={currentElement}
          currentView={currentView}
          viewSet={viewSet}
          handlePasteAdd={handlePasteAdd}
          setTextResponse={setTextResponse}
          setWaitingElementIds={setWaitingElementIds}
          generationPanelCount={generationPanelCount}
          setGenerationPanelCount={(count) => {
            onClose()
            setGenerationPanelCount(count)
            return count
          }}
          handleUpdatePromptHistory={handleUpdatePromptHistory}
          stopRequested={stopRequested}
          setGeneratingContentMessage={setGeneratingContentMessage}
          views={views}
          roles={roles}
          defaultLevelSpecs={defaultLevelSpecs}
          assistants={assistants}
        />
      </DialogContent>
    </Dialog>
  )
}

export default ModelEditCreateContentDialog
