import { useEffect, useState } from "react"
import { listAssistants } from "../pages/services/cloudFunctions"

const Assistants = () => {
  const [assistants, setAssistants] = useState([])

  useEffect(() => {
    listAssistants().then((response) => {
      console.log("%cretrieved assistants", "color:lightGreen", {
        assistants: response,
      })
      setAssistants(response)
    })
  }, [])

  return assistants
}

export default Assistants
