import { Box, Typography } from "@mui/material"

const LabelAndValue = ({ label, value, lineHeight = 1.4 }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
      <Box width="150px" flexShrink={0}>
        <Typography variant="body1">{label}</Typography>
      </Box>
      <Box flex={1}>
        <Typography variant="body2" sx={{ lineHeight: lineHeight }}>
          {value}
        </Typography>
      </Box>
    </Box>
  )
}

export default LabelAndValue
