import { Slider, Typography, Checkbox, Box, Tooltip } from "@mui/material"
import { useWindowDimensions } from "../pages/services/useWindowDimensions"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectAutofitDiagram, selectDiagramScale } from "../redux/selectors"
import { setAutofitDiagram, setDiagramScale } from "../redux/actions"
import { spacing } from "../pages/services/styleServices"

const styles = {
    root: {
        marginTop: spacing(2),
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    slider: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "no-wrap",
        width: 200,
        alignItems: "center",
        gap: spacing(1),
        marginLeft: spacing(2),
    },
}

const DiagramResize = (props) => {
    const { setWidth } = props

    const dispatch = useDispatch()

    const { height, width } = useWindowDimensions()

    const autofit = useSelector(selectAutofitDiagram)

    const diagramScale = useSelector(selectDiagramScale)

    useEffect(() => {
        setWidth(width)
    }, [width])

    return (
        <Box sx={styles.root}>
            <Box style={{ width: "auto" }}>
                <Typography variant="caption" color="textSecondary" component={"span"}>
                    Autofit Diagram
                </Typography>
                <Tooltip title="Autofit diagram(s) to page size">
                    <Checkbox
                        checked={autofit}
                        onChange={(event) => {
                            dispatch(setAutofitDiagram(event.target.checked))

                            if (event.target.checked) {
                                dispatch(setDiagramScale(100))
                            }
                        }}
                    />
                </Tooltip>
            </Box>
            {!autofit && (
                <Box sx={styles.slider}>
                    <Typography variant="caption" color="textSecondary">
                        Scale
                    </Typography>
                    <Tooltip title="Change scale of diagram(s)">
                        <Slider
                            size="small"
                            min={10}
                            max={100}
                            value={diagramScale}
                            onChange={(event, value) => {
                                dispatch(setDiagramScale(value))
                            }}
                            aria-label="Small"
                            valueLabelDisplay="auto"
                        />
                    </Tooltip>
                </Box>
            )}
        </Box>
    )
}

export default DiagramResize
