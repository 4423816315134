import React, { useState } from "react"
import Header from "../components/Header"
import StoryPromptEditForm from "../components/StoryPromptEditForm"

const StoryPromptEdit = (props) => {
    const [title, setTitle] = useState("Create Story Prompt")

    return (
        <Header title={title}>
            <StoryPromptEditForm {...props} setTitle={setTitle} />
        </Header>
    )
}

export default StoryPromptEdit
