import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputBase,
    LinearProgress,
    Paper,
    Typography,
} from "@mui/material"

import { SiOpenai } from "react-icons/si"
import ChatIcon from "@mui/icons-material/Chat"
import SettingsIcon from "@mui/icons-material/Settings"
import CloseIcon from "@mui/icons-material/Close"
import * as colors from "@mui/material/colors"
import DraggablePaper from "../components/DraggablePaper"
import { useState } from "react"
import SendIcon from "@mui/icons-material/Send"
import { createChatCompletion } from "../pages/services/cloudFunctions"
import { startTransition } from "react"
import { getLines } from "../pages/services/chatGenerationServices"

const ChatDialog = (props) => {
    const { open, onClose, messages, setMessages } = props

    const [isBusy, setBusy] = useState(false)

    const icons = {
        user: <ChatIcon sx={{ color: colors.blue[800] }} />,
        assistant: (
            // To resize font-awesome icons to match mui icons sizes
            <Box sx={{ boxSizing: "content-box", padding: "3px", fontSize: "1.125rem" }}>
                <SiOpenai />
            </Box>
        ),
        system: <SettingsIcon sx={{ color: colors.lightGreen[800] }} />,
    }

    const handleSend = (message) => {
        console.log("send message: ", message)

        const newMessages = [
            ...messages,
            { seq: messages.length + 1, role: "user", content: message },
        ]

        setMessages([
            ...newMessages,
            { seq: messages.length + 2, role: "assistant", content: "..." },
        ])

        setBusy(true)

        const messageParams = newMessages.map((message) => ({
            role: message.role,
            content: message.content,
        }))

        startTransition(() => {
            createChatCompletion({
                messages: messageParams,
                model: "gpt-3.5-turbo",
            }).then((result) => {
                console.log("%ccreateChatCompletion result", "color:orange", { result })
                if (result.data.response.choices && result.data.response.choices.length > 0) {
                    setMessages([
                        ...newMessages,
                        { seq: messages.length + 2, ...result.data.response.choices[0].message },
                    ])
                    setBusy(false)
                }
            })
        })
    }

    return (
        <>
            {open && (
                <Dialog
                    open={open}
                    scroll={"paper"}
                    onClose={onClose}
                    PaperComponent={DraggablePaper}
                >
                    <DialogTitle sx={{ cursor: "move" }} id="draggable-dialog-title">
                        Chat
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            {messages &&
                                messages.map((message) => (
                                    <Box
                                        key={message.seq}
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: 1,
                                            alignItems: "flex-start",
                                        }}
                                    >
                                        {icons[message.role]} ({message.seq})
                                        <Box>
                                            {getLines(message.content).map((line, index) => (
                                                <Box key={`${line}-${index}`}>
                                                    {line.type === "json" && (
                                                        <pre
                                                            sx={{
                                                                maxWidth: "300px",
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="body2"
                                                                sx={{ color: colors.blue[900] }}
                                                            >
                                                                {JSON.stringify(
                                                                    line.content,
                                                                    null,
                                                                    2
                                                                )}
                                                            </Typography>
                                                        </pre>
                                                    )}
                                                    {line.type === "text" && (
                                                        <Typography variant="body2">
                                                            {line.content}
                                                        </Typography>
                                                    )}
                                                </Box>
                                            ))}
                                        </Box>
                                    </Box>
                                ))}
                        </Box>
                        <Box sx={{ marginTop: "10px" }}></Box>
                    </DialogContent>
                    <DialogActions>
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
                            {isBusy && <LinearProgress />}
                            <InputPrompt handleSend={handleSend} isBusy={isBusy} />
                        </Box>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}

const InputPrompt = ({ handleSend, isBusy }) => {
    const [value, setValue] = useState("")

    return (
        <Paper
            component="form"
            sx={{ p: "2px 3px", display: "flex", alignItems: "center", width: "100%" }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                multiline
                placeholder="Send a message..."
                inputProps={{ "aria-label": "send a message" }}
                value={value}
                disabled={isBusy}
                onChange={(e) => setValue(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        handleSend(value)
                        setValue("")
                    }
                }}
            />
            <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={() => {
                    handleSend(value)
                    setValue("")
                }}
                disabled={isBusy}
            >
                <SendIcon size="small" />
            </IconButton>
        </Paper>
    )
}

export default ChatDialog
