import EditIcon from "@mui/icons-material/Edit"
import { IconButton, Tooltip } from "@mui/material"

const ToggleEditing = (props) => {
    const { toggleEdit } = props

    return (
        <IconButton color="primary" onClick={toggleEdit} size="large">
            <Tooltip title="Edit">
                <EditIcon />
            </Tooltip>
        </IconButton>
    )
}

export default ToggleEditing
