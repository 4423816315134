import {
  Alert,
  Autocomplete,
  Box,
  Chip,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material"
import { useMemo, useState } from "react"
import Controls from "./controls/Controls"
import * as palette from "./symbols/palette"
import DraggablePaper from "../components/DraggablePaper"
import { replaceTypeNameWithTypeIndex } from "../pages/services/modelEditServices"

const ModelEditPasteAdd = (props) => {
  // If currentElement is undefined then it means we intend to add to the root
  const { open, onClose, handlePasteAdd, currentElement, currentView } = props

  const [jsonElements, setJsonElements] = useState("")

  const [validationMessage, setValidationMessage] = useState(undefined)

  const [elementType, setElementType] = useState("")

  const [parsedJSON, setParsedJSON] = useState(undefined)

  const elementTypes = palette.getElementTypeNames()

  const courierFont = createTheme({
    typography: {
      fontFamily: ["Courier New", "sans-serif"].join(","),
      fontSize: 12,
    },
  })

  const checkJSON = (elementCheck, jsonElements) => {
    if (jsonElements === "") {
      setValidationMessage(undefined)
      return false
    }
    try {
      const elementsToAdd = JSON.parse(jsonElements)

      setParsedJSON(elementsToAdd)

      // Check if elementsToAdd is an array

      if (!Array.isArray(elementsToAdd)) {
        setValidationMessage({
          severity: "warning",
          message: "JSON is not valid. Expecting an array of objects",
        })
        return false
      }

      const checkValid = (element) => {
        if (elementCheck(element)) {
          if (element.children) {
            return element.children.every(checkValid)
          }
          return true
        }
        return false
      }

      const isValid = elementsToAdd.every((element) => {
        return checkValid(element)
      })

      return isValid
    } catch (error) {
      setValidationMessage({ severity: "error", message: error.message })
      return false
    }
  }

  const hasName = useMemo(() => {
    const checkName = (element) => {
      return element.name
    }

    return checkJSON(checkName, jsonElements)
  }, [jsonElements])

  const hasNameAndType = useMemo(() => {
    const checkNameAndType = (element) => {
      return element.name && element.type
    }

    return checkJSON(checkNameAndType, jsonElements)
  }, [jsonElements])

  const isValid = useMemo(() => {
    if (jsonElements === "") {
      setValidationMessage(undefined)
      return false
    }
    if (hasNameAndType) {
      setValidationMessage("")
      return true
    } else if (hasName && !hasNameAndType) {
      console.log("elementType", elementType)
      if (!elementType) {
        setValidationMessage({
          severity: "info",
          message: "Select a type to use for all elements",
        })
        return false
      } else {
        setValidationMessage({
          severity: "success",
          message: "All elements will be added as " + elementType,
        })
        return true
      }
    } else {
      setValidationMessage({
        severity: "warning",
        message:
          "JSON is not valid. Expecting an array of objects with 'name' and 'type' properties",
      })
      return false
    }
  }, [hasName, hasNameAndType, jsonElements, elementType])

  const handleAddType = () => {
    const addType = (element) => {
      element.type = elementType
      if (element.children) {
        element.children.forEach(addType)
      }
    }

    const elementsToAdd = JSON.parse(jsonElements)

    elementsToAdd.forEach(addType)

    return elementsToAdd
  }

  return (
    <Dialog
      open={open}
      sx={{ minWidth: "300px" }}
      onClose={onClose}
      PaperComponent={DraggablePaper}
    >
      <DialogTitle sx={{ cursor: "move" }} id="draggable-dialog-title">
        Paste elements in JSON format
      </DialogTitle>

      <DialogContent>
        <Stack direction="column" gap={1} sx={{ marginBottom: "15px" }}>
          {currentElement ? (
            <Typography variant="caption">
              The content provided will be added under the{" "}
              <b>{currentElement.name}</b> element
            </Typography>
          ) : (
            <Typography variant="caption">
              The content provided will be added as top level elements
            </Typography>
          )}
          <Typography variant="caption">
            The value pasted in must be an array of JSON objects, where each
            object has a 'name' property, and optional 'description' and 'props'
            properties. 'props' is an array of JSON objects, where each object
            has a 'name' property, 'value', and optional 'reason' property.
          </Typography>
        </Stack>
        <ThemeProvider theme={courierFont}>
          <TextField
            id="filled-multiline-flexible"
            label="JSON element data"
            multiline
            value={jsonElements}
            maxRows={10}
            minRows={10}
            variant="filled"
            onChange={(e) => setJsonElements(e.target.value)}
            sx={{ width: "100%", minWidth: "300px", fontFamily: ["courier"] }}
          />
        </ThemeProvider>
        {validationMessage && (
          <Alert severity={validationMessage.severity}>
            {validationMessage.message}
          </Alert>
        )}
        {hasName && !hasNameAndType && (
          <Autocomplete
            id="type"
            options={elementTypes}
            size="small"
            value={elementType}
            onChange={(e, newValue) => {
              setElementType(newValue)
            }}
            // getOptionLabel={(option) => {
            //     console.log("option", option)
            //     //return palette.formatLabel(option)
            //     return option
            // }}
            //isOptionEqualToValue={() => false}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Element Type"
                variant="outlined"
                size="small"
                sx={{ maxWidth: "300px" }}
              />
            )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  size="small"
                  label={palette.formatLabel(option)}
                  {...getTagProps({ index })}
                  sx={{ backgroundColor: palette.getElementLayerColor(option) }}
                />
              ))
            }
          />
        )}

        <pre>{JSON.stringify(parsedJSON, null, 2)}</pre>
      </DialogContent>
      <DialogActions>
        <Controls.Button text="Cancel" onClick={onClose} />
        <Controls.Button
          disabled={!isValid}
          text="Add"
          variant="contained"
          onClick={() => {
            if (hasName && !hasNameAndType && elementType !== null) {
              // Add type to each element

              const elementsToAdd = handleAddType(JSON.parse(jsonElements))
              handlePasteAdd({
                elementDataToAdd: replaceTypeNameWithTypeIndex(elementsToAdd),
                parent: currentElement,
                viewElements: currentView.elements,
              })
            } else {
              handlePasteAdd({
                elementDataToAdd: replaceTypeNameWithTypeIndex(
                  JSON.parse(jsonElements)
                ),
                parent: currentElement,
                viewElements: currentView.elements,
              })
            }
          }}
        />
      </DialogActions>
    </Dialog>
  )
}

export default ModelEditPasteAdd
