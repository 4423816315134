import React, { useState } from "react"
import {
    Alert,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    ListItemIcon,
    Tooltip,
    Menu,
    MenuItem,
} from "@mui/material"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck"
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove"
import DeleteIcon from "@mui/icons-material/Delete"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import * as colors from "@mui/material/colors"
import Bold from "./Bold"
import { spacing } from "../pages/services/styleServices"

const styles = {
    alert: {
        marginTop: spacing(2),
        marginBottom: spacing(2),
    },
}

const ModelFileList = (props) => {
    const {
        files,
        modelIndexes,
        selectedFileIndex,
        handleSelectFile,
        handleDownload,
        handleToggleIndexed,
        handlePromptDeleteFile,
    } = props

    return (
        <Box>
            <Box>
                {files.length > 0 && (
                    <List dense>
                        {files.sort().map((fileName, index) => {
                            const isIndexed = modelIndexes && modelIndexes.includes(fileName)

                            return (
                                <FileRow
                                    key={fileName}
                                    fileName={fileName}
                                    fileIndex={index}
                                    selectedFileIndex={selectedFileIndex}
                                    isIndexed={isIndexed}
                                    handleSelectFile={handleSelectFile}
                                    handleDownload={handleDownload}
                                    handleToggleIndexed={handleToggleIndexed}
                                    handlePromptDeleteFile={handlePromptDeleteFile}
                                />
                            )
                        })}
                    </List>
                )}
            </Box>

            {modelIndexes && files.length > 0 && selectedFileIndex === undefined && (
                <Box sx={styles.alert}>
                    <Alert severity="info">Select a model file to display its views</Alert>
                </Box>
            )}

            {files.length === 0 && (
                <Box sx={styles.alert}>
                    <Alert severity="info">
                        Select <Bold>Upload Model</Bold> to import OpenExchange (.xml) or Archi
                        (.archimate) models
                    </Alert>
                </Box>
            )}
        </Box>
    )
}

const FileRow = (props) => {
    const {
        fileName,
        fileIndex,
        selectedFileIndex,
        isIndexed,
        handleDownload,
        handleToggleIndexed,
        handlePromptDeleteFile,
        handleSelectFile,
    } = props

    const [anchorEl, setAnchorEl] = useState(null)

    const showMenu = (event) => {
        setAnchorEl(event.target)
    }

    return (
        <>
            <Menu
                anchorEl={anchorEl}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                id={"model-file-menu"}
                keepMounted
                open={anchorEl !== null}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem
                    onClick={() => {
                        setAnchorEl(null)
                        handleDownload(fileIndex)
                    }}
                >
                    <ListItemIcon>
                        <CloudDownloadIcon fontSize="small" />
                    </ListItemIcon>
                    Download File
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        setAnchorEl(null)
                        handleToggleIndexed(fileName)
                    }}
                >
                    {!isIndexed ? (
                        <>
                            <ListItemIcon>
                                <Tooltip title="This model is indexed. Click to remove from Explorer searches">
                                    <PlaylistAddCheckIcon />
                                </Tooltip>
                            </ListItemIcon>
                            Create Index
                        </>
                    ) : (
                        <>
                            <ListItemIcon>
                                <Tooltip title="This model is NOT indexed. Click to include in Explorer searches">
                                    <PlaylistRemoveIcon />
                                </Tooltip>
                            </ListItemIcon>
                            Remove Index
                        </>
                    )}
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        setAnchorEl(null)
                        handlePromptDeleteFile(fileIndex)
                    }}
                >
                    <ListItemIcon>
                        <DeleteIcon />
                    </ListItemIcon>
                    Delete File
                </MenuItem>
            </Menu>
            <ListItem
                button
                key={fileName}
                selected={selectedFileIndex === fileIndex}
                onClick={() => handleSelectFile(fileIndex)}
            >
                <ListItemText
                    primaryTypographyProps={
                        isIndexed
                            ? {
                                  variant: "body2",
                              }
                            : {
                                  color: colors.grey[500],
                                  variant: "body2",
                              }
                    }
                    primary={fileName}
                />
                <ListItemSecondaryAction>
                    <IconButton
                        edge="end"
                        aria-label="file-menu"
                        onClick={(event) => showMenu(event)}
                        size="large"
                    >
                        <MoreVertIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </>
    )
}

export default ModelFileList
