import React, { useState } from "react"
import { withSnackbar } from "notistack"
import { useHistory, withRouter } from "react-router-dom"
import { useSelector } from "react-redux"
import { selectModelState } from "../redux/selectors"
import SearchIcon from "@mui/icons-material/Search"
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Switch,
  FormControlLabel,
  Box,
  TextField,
  InputAdornment,
} from "@mui/material"
import { Typography } from "@mui/material"
import { useEffect } from "react"
import * as palette from "./symbols/palette"
import * as modelServices from "../pages/services/modelServices"
import * as urlServices from "../pages/services/urlServices"
import GridLabel from "./GridLabel"
import ModelBreadcrumbs from "./ModelBreadcrumbs"
import { spacing } from "../pages/services/styleServices"

const styles = {
  topRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  svg: {
    flexGrow: 0,
  },
  filter: {
    marginTop: spacing(2),
  },
  filterGridItem: {
    marginLeft: spacing(2),
    marginRight: spacing(2),
  },
}

const ElementListForm = (props) => {
  const { parentId, fileName, type } = props.computedMatch.params

  const history = useHistory()

  const selectModel = useSelector(selectModelState)

  const [modelState, setModelState] = useState()

  const [showDocumentation, setShowDocumentation] = useState(true)

  const [filter, setFilter] = useState("")

  const { setTitle } = props

  const elementType = { symbol: palette.symbols[type] }

  const [elements, setElements] = useState([])

  useEffect(() => {
    console.log("get model state", {
      parentId,
      selectModel,
      fileName,
      element: elementType,
    })

    const modelCacheId = modelServices.getModelCacheId({ parentId, fileName })
    const modelState = selectModel[modelCacheId]
    console.log("model state", modelState)

    setModelState(modelState)

    setElements(
      modelState.model.elements
        .filter((element) => element.type === type)
        .sort((a, b) => a.name.localeCompare(b.name))
    )

    setTitle(getTitle(type))
  }, [selectModel, parentId])

  const getTitle = (type) => {
    return `${modelServices.splitCamelCase(type)} Instances`
  }

  const handleClickView = (elementId) => {
    const url = urlServices.createElementViewUrl({
      parentId: parentId,
      fileName: fileName,
      elementId: elementId,
    })
    console.log("url", url)
    history.push(url)
  }

  const handleFilterChange = (event) => {
    setFilter(event.target.value)
  }

  const getFilteredElements = () => {
    return filter === ""
      ? elements
      : elements.filter(
          (element) =>
            element.name.toUpperCase().indexOf(filter.toUpperCase()) !== -1 ||
            (showDocumentation &&
              element.documentation.length > 0 &&
              element.documentation[0]
                .toUpperCase()
                .indexOf(filter.toUpperCase()) !== -1)
        )
  }

  return (
    <>
      <ModelBreadcrumbs modelState={modelState}>
        {getTitle(type)}
      </ModelBreadcrumbs>

      <Box sx={styles.topRow}>
        <Box sx={styles.svg}>
          <svg width={170} height={70} viewBox="0 0 150 70">
            <elementType.symbol
              label={modelServices.splitCamelCase(type)}
              showLabel={true}
              width={140}
              height={60}
              x={1}
              y={1}
            />
          </svg>
        </Box>
        <Box style={{ flexGrow: 5 }}></Box>
        <Box>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={showDocumentation}
                onChange={() => setShowDocumentation(!showDocumentation)}
                name="showDocumentation"
              />
            }
            label={
              <Typography variant="caption" component={"span"}>
                Description
              </Typography>
            }
          />
        </Box>
      </Box>

      <Grid container direction="column">
        <GridLabel>Instances</GridLabel>
        <Grid item sx={styles.filterGridItem}>
          <TextField
            fullWidth
            sx={styles.filter}
            id="filter"
            variant="outlined"
            size="small"
            value={filter}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            type="filter"
            onChange={handleFilterChange}
          />
        </Grid>
        <Grid item style={{ maxWidth: 600 }}>
          <List dense>
            {getFilteredElements().map((element) => (
              <ListItem
                button
                key={element.id}
                onClick={() => handleClickView(element.id)}
              >
                <ListItemText
                  primary={element.name}
                  secondary={
                    showDocumentation &&
                    (element.documentation.length > 0 ? (
                      element.documentation
                    ) : (
                      <Typography
                        variant="caption"
                        color="primary"
                        component={"span"}
                      >
                        No description
                      </Typography>
                    ))
                  }
                ></ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </>
  )
}

export default withSnackbar(withRouter(ElementListForm))
