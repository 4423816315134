import React from "react"
import Header from "../components/Header"
import { Box } from "@mui/material"
import ViewSetGrid from "../components/ViewSetGrid"

const ViewSets = () => {
    return (
        <Header title="AI Designer">
            <Box padding={1}>
                <ViewSetGrid />
            </Box>
        </Header>
    )
}

export default ViewSets
