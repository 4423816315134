import React, { useState } from "react"
import BaseShapeRoundedRect from "./BaseShapeRoundedRect"
import { pink } from "@mui/material/colors"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const WorkPackage = (props) => {
    const { label, highlight, highlightColor, fillColor, borderColor } = props

    const hoverColor = pink[200]
    const defaultColor = fillColor || pink[100]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <BaseShapeRoundedRect
                {...props}
                fill={fill}
                setFill={handleFill}
                borderColor={borderColor}
            />

            <ElementText {...props} label={label} />
        </Svg>
    )
}

export default WorkPackage
