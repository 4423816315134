import React, { useState } from "react"
import * as common from "./common"
import { yellow } from "@mui/material/colors"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const BusinessActor = (props) => {
    const { x, y, height, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = yellow[200]
    const defaultColor = fillColor || yellow[100]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <rect
                x={x + 1}
                y={y + 1}
                fill={fill}
                stroke={common.outlineColor}
                width={width - 1}
                height={height - 1}
            />

            <svg x={x + width - 15} y={y + 4} width={20} height={20}>
                <circle cx={5} cy={5} r={3} fill="none" stroke={common.outlineColor} />
                <line x1={5} y1={8} x2={5} y2={15} stroke={common.outlineColor} />
                <line x1={1} y1={10} x2={9} y2={10} stroke={common.outlineColor} />
                <line x1={5} y1={14} x2={8} y2={18} stroke={common.outlineColor} />
                <line x1={5} y1={14} x2={2} y2={18} stroke={common.outlineColor} />
            </svg>

            <ElementText {...props} label={label} />
        </Svg>
    )
}

export default BusinessActor
