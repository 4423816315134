import { functions } from "../../Firestore"
import { httpsCallable } from "firebase/functions"
import { getAnalytics, setUserProperties, setUserId } from "firebase/analytics"
import { getCountFromServer, collection, getFirestore, where, query } from "firebase/firestore"
import * as Roles from "./roleServices"

const processInvite = async () => {
    const processInvite = httpsCallable(functions, "processInvite")

    const payload = {}

    const inviteResult = await processInvite(payload)

    return inviteResult
}

const logUserAccess = async (email, accountId, userId) => {
    const analytics = getAnalytics()

    setUserProperties(analytics, { account_id: accountId })
    setUserId(analytics, userId)
}

const getUserCountWithAIMAIRole = async (accountId) => {
    const db = getFirestore()
    const countUsersWithAIMAIRoleQuery = query(
        collection(db, "users"),
        where("account_id", "==", accountId),
        where("roles", "array-contains", Roles.AIM_AI)
    )
    const result = await getCountFromServer(countUsersWithAIMAIRoleQuery)
    console.log("user count with AIM AI role", { count: result.data().count })
    return result.data().count
}

const getUserCountTotal = async (accountId) => {
    const db = getFirestore()
    const countUsersWithAIMAIRoleQuery = query(
        collection(db, "users"),
        where("account_id", "==", accountId)
    )
    const result = await getCountFromServer(countUsersWithAIMAIRoleQuery)
    console.log("user count with AIM AI role", { count: result.data().count })
    return result.data().count
}

export { processInvite, logUserAccess, getUserCountWithAIMAIRole, getUserCountTotal }
