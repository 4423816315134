import React from "react"
import Header from "../components/Header"
import { Box } from "@mui/material"
import StoryPromptGrid from "../components/StoryPromptGrid"

const StoryPrompts = () => {
    return (
        <Header title="AI Story Prompts">
            <Box padding={1}>
                <StoryPromptGrid />
            </Box>
        </Header>
    )
}

export default StoryPrompts
