import React, { useEffect, useState } from "react"
import Header from "../components/Header"
import { Paper } from "@mui/material"
import ExplorerForm from "../components/ExplorerForm"
import { setComponents } from "../redux/actions"
import { selectComponents } from "../redux/selectors"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setComponentExplorerSettings } from "../redux/actions"
import { selectComponentExplorerSettings } from "../redux/selectors"
import { setComponentModelIndex } from "../redux/actions"
import { selectComponentModelIndex } from "../redux/selectors"
import { spacing } from "../pages/services/styleServices"

const styles = {
    pageContent: {
        margin: spacing(1),
        padding: spacing(1),
    },
}

const ComponentExplorer = (props) => {
    const history = useHistory()

    const dispatch = useDispatch()

    const [title, setTitle] = useState("Component Explorer")

    const settings = useSelector(selectComponentExplorerSettings)

    const cache = useSelector(selectComponents)

    const updateSettings = (newSettings) => {
        dispatch(setComponentExplorerSettings(newSettings))
    }

    const handleClickItem = (id) => {
        history.push(`/Component/${id}`)
    }

    useEffect(() => {
        console.log("%ccache changed", "color:yellow", cache)
    }, [cache])

    return (
        <Header title={title}>
            <Paper sx={styles.pageContent}>
                <ExplorerForm
                    settings={settings}
                    collectionName="components"
                    updateSettings={updateSettings}
                    {...props}
                    setTitle={setTitle}
                    handleClickItem={handleClickItem}
                    cacheSelector={selectComponents}
                    cacheUpdater={setComponents}
                    indexSelector={selectComponentModelIndex}
                    indexUpdater={setComponentModelIndex}
                />
            </Paper>
        </Header>
    )
}

export default ComponentExplorer
