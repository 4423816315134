
const getHorizontalLineYPos = (sourceBounds, targetBounds, overlap) => {
    let narrowest

    if (sourceBounds.height < targetBounds.height) {
        narrowest = sourceBounds
        //console.log("narrowest", sourceElement.name, "compared to", targetElement.name)
    } else {
        narrowest = targetBounds
        //console.log("narrowest", targetElement.name, "compared to", sourceElement.name)
    }

    // If there is any overlap, you come in overlap/2 from the edge closest between source and target

    const isTargetBelow = sourceBounds.y < targetBounds.y

    const belowBounds = isTargetBelow ? targetBounds : sourceBounds
    const aboveBounds = isTargetBelow ? sourceBounds : targetBounds

    const yPosFromBelow =
        overlap.y === 0 ? belowBounds.y + narrowest.height / 2 : belowBounds.y + overlap.y / 2

    const yPosFromAbove =
        overlap.y === 0
            ? narrowest.y + narrowest.height / 2
            : aboveBounds.y + aboveBounds.height - overlap.y / 2

    const yPos = isTargetBelow ? yPosFromAbove : yPosFromBelow

    return yPos
}

const getVerticalLineXPos = (sourceBounds, targetBounds, overlap) => {
    let narrowest

    if (sourceBounds.width < targetBounds.width) {
        narrowest = sourceBounds
        //console.log("narrowest", sourceElement.name, "compared to", targetElement.name)
    } else {
        narrowest = targetBounds
        //console.log("narrowest", targetElement.name, "compared to", sourceElement.name)
    }

    // If there is any overlap, you come in overlap/2 from the edge closest between source and target

    const isTargetBelow = sourceBounds.x < targetBounds.x

    const belowBounds = isTargetBelow ? targetBounds : sourceBounds
    const aboveBounds = isTargetBelow ? sourceBounds : targetBounds

    const xPosFromRight =
        overlap.x === 0 ? belowBounds.x + narrowest.width / 2 : belowBounds.x + overlap.x / 2

    const xPosFromLeft =
        overlap.x === 0
            ? narrowest.x + narrowest.width / 2
            : aboveBounds.x + aboveBounds.width - overlap.x / 2

    const xPos = isTargetBelow ? xPosFromLeft : xPosFromRight

    return xPos
}

const isVerticalOverlap = (sb, tb) => {
    return isRangeOverlap(
        { start: sb.y, finish: sb.y + sb.height },
        { start: tb.y, finish: tb.y + tb.height }
    )
}

const isHorizontalOverlap = (sb, tb) => {
    return isRangeOverlap(
        { start: sb.x, finish: sb.x + sb.width },
        { start: tb.x, finish: tb.x + tb.width }
    )
}

const isRangeOverlap = (a, b) => {
    if (b.start < a.start) {
        return b.finish > a.start
    } else {
        return b.start < a.finish
    }
}

const isBetween = (num, range) => {
    return num > range.start && num < range.finish
}

// Return true if one range straddles the start or end of the other range
// but not both edges
const isEdgeOverlap = (a, b) => {
    return isBetween(a.start, b) || isBetween(a.finish, b)
}

const getOverlapAmount = (a, b) => {
    return isEdgeOverlap(a, b)
        ? Math.abs(Math.max(a.start, b.start) - Math.min(a.finish, b.finish))
        : 0
}

const isTargetLeft = (sb, tb) => {
    return tb.x < sb.x && isVerticalOverlap(sb, tb)
}

const isTargetUpperLeft = (sb, tb) => {
    return (
        tb.x < sb.x && sb.y > tb.y && !isVerticalOverlap(sb, tb) && !isHorizontalOverlap(sb, tb)
    )
}

const isTargetUpperRight = (sb, tb) => {
    return (
        tb.x > sb.x && sb.y > tb.y && !isVerticalOverlap(sb, tb) && !isHorizontalOverlap(sb, tb)
    )
}

const isTargetLowerLeft = (sb, tb) => {
    return (
        tb.x < sb.x && sb.y < tb.y && !isVerticalOverlap(sb, tb) && !isHorizontalOverlap(sb, tb)
    )
}

const isTargetLowerRight = (sb, tb) => {
    return (
        tb.x > sb.x && sb.y < tb.y && !isVerticalOverlap(sb, tb) && !isHorizontalOverlap(sb, tb)
    )
}

const isTargetRight = (sb, tb) => {
    return tb.x > sb.x && isVerticalOverlap(sb, tb)
}

const isTargetAbove = (sb, tb) => {
    return sb.y > tb.y && isHorizontalOverlap(sb, tb)
}

const isTargetBelow = (sb, tb) => {
    return sb.y < tb.y && isHorizontalOverlap(sb, tb)
}

const getPosition = (sourceBounds, targetBounds) => {
    if (isContained(sourceBounds, targetBounds)) {
        return "contained"
    } else if (isTargetLeft(sourceBounds, targetBounds)) {
        return "left"
    } else if (isTargetAbove(sourceBounds, targetBounds)) {
        return "above"
    } else if (isTargetBelow(sourceBounds, targetBounds)) {
        return "below"
    } else if (isTargetRight(sourceBounds, targetBounds)) {
        return "right"
    } else if (isTargetUpperLeft(sourceBounds, targetBounds)) {
        return "upper left"
    } else if (isTargetUpperRight(sourceBounds, targetBounds)) {
        return "upper right"
    } else if (isTargetLowerLeft(sourceBounds, targetBounds)) {
        return "lower left"
    } else if (isTargetLowerRight(sourceBounds, targetBounds)) {
        return "lower right"
    }
    return "unknown"
}

const isContained = (sourceBounds, targetBounds) => {
    const contained =
        sourceBounds.x < targetBounds.x &&
        sourceBounds.y < targetBounds.y &&
        sourceBounds.x + sourceBounds.width > targetBounds.x + targetBounds.width &&
        sourceBounds.y + sourceBounds.height > targetBounds.y + targetBounds.height

    return contained
}

const getRelativePositionFromPoint = (point, elementBounds) => {
    return getPosition(elementBounds, {
        x: point.x,
        y: point.y,
        width: 0,
        height: 0,
    })
}

// Calculate the point at which a point will connect into
// a bounds, based on whether the point is above, below, left, etc.

const getBoundsConnectionPoint = (point, bounds) => {
    const position = getRelativePositionFromPoint(point, bounds)

    switch (position) {
        case "left":
            return { x: bounds.x, y: point.y }

        case "above":
            return { x: point.x, y: bounds.y }

        case "below":
            return { x: point.x, y: bounds.y + bounds.height }

        case "right":
            return { x: bounds.x + bounds.width, y: point.y }

        case "upper left":
            return { x: bounds.x, y: bounds.y }

        case "upper right":
            return { x: bounds.x + bounds.width, y: bounds.y }

        case "lower left":
            return { x: bounds.x, y: bounds.y + bounds.height }

        case "lower right":
            return { x: bounds.x + bounds.width, y: bounds.y + bounds.height }

        default:
            break
    }
}

const getElementOverlap = (sourceBounds, targetBounds) => {
    const overlap = {
        x: getOverlapAmount(
            {
                start: sourceBounds.x,
                finish: sourceBounds.x + sourceBounds.width,
            },
            {
                start: targetBounds.x,
                finish: targetBounds.x + targetBounds.width,
            }
        ),
        y: getOverlapAmount(
            {
                start: sourceBounds.y,
                finish: sourceBounds.y + sourceBounds.height,
            },
            {
                start: targetBounds.y,
                finish: targetBounds.y + targetBounds.height,
            }
        ),
    }

    return overlap
}

const getLineCoords = (
    diagramDimensions,
    overlap,
    sourceBounds,
    targetBounds,
) => {
    let result = undefined

    const position = getPosition(sourceBounds, targetBounds)

    switch (position) {
        case "contained":
            // just return undefined, since we don't want to draw a line for wholly contained elements
            break

        case "left":
            const yLeftPos = getHorizontalLineYPos(sourceBounds, targetBounds, overlap)

            result = {
                x1: sourceBounds.x,
                y1: yLeftPos,
                x2: targetBounds.x + targetBounds.width,
                y2: yLeftPos,
            }

            //console.log("left", { result })

            break

        case "right":
            const yRightPos = getHorizontalLineYPos(sourceBounds, targetBounds, overlap)

            result = {
                x1: sourceBounds.x + sourceBounds.width,
                y1: yRightPos,
                x2: targetBounds.x,
                y2: yRightPos,
            }

            //console.log("right", { result })
            break

        case "above":
            // Go from source top to target bottom

            const xAbovePos = getVerticalLineXPos(sourceBounds, targetBounds, overlap)

            result = {
                x1: xAbovePos,
                y1: sourceBounds.y,
                x2: xAbovePos,
                y2: targetBounds.y + targetBounds.height,
            }

            break

        case "below":
            const xBelowPos = getVerticalLineXPos(sourceBounds, targetBounds, overlap)

            result = {
                x1: xBelowPos,
                y1: sourceBounds.y + sourceBounds.height,
                x2: xBelowPos,
                y2: targetBounds.y,
            }

            //console.log("below", { result })

            break

        case "upper left":
            result = {
                x1: sourceBounds.x,
                y1: sourceBounds.y,
                x2: targetBounds.x + targetBounds.width,
                y2: targetBounds.y + targetBounds.height,
            }
            break

        case "upper right":
            result = {
                x1: sourceBounds.x + sourceBounds.width,
                y1: sourceBounds.y,
                x2: targetBounds.x,
                y2: targetBounds.y + targetBounds.height,
            }
            break

        case "lower left":
            result = {
                x1: sourceBounds.x,
                y1: sourceBounds.y + sourceBounds.height,
                x2: targetBounds.x + targetBounds.width,
                y2: targetBounds.y,
            }
            break

        case "lower right":
            result = {
                x1: sourceBounds.x + sourceBounds.width,
                y1: sourceBounds.y + sourceBounds.height,
                x2: targetBounds.x,
                y2: targetBounds.y,
            }
            break

        default:
            break
    }

    return (
        result && {
            x1: result.x1 - diagramDimensions.widthOffset,
            y1: result.y1 - diagramDimensions.heightOffset,
            x2: result.x2 - diagramDimensions.widthOffset,
            y2: result.y2 - diagramDimensions.heightOffset,
            getMidPoint: function () {
                return { x: (this.x1 + this.x2) / 2, y: (this.y1 + this.y2) / 2 }
            },
        }
    )
}

export { getHorizontalLineYPos, getVerticalLineXPos,
    isVerticalOverlap,
    getOverlapAmount,
    getPosition,
    isContained,
    getBoundsConnectionPoint,
    getElementOverlap,
    getRelativePositionFromPoint,
    getLineCoords
}