import { common, grey } from "@mui/material/colors"
import React from "react"
import _ from "lodash"
import { FONT_FAMILY } from "../../pages/services/svgUtils"

const DEFAULT_APP_COLOR = "#B5FFFF"

const DEFAULT_LOCATION_COLOR = "#FBB875"

const outlineColor = grey[800]

const defaultLineColor = "#000"

const defaultLineHighlightColor = grey[500]

const elementLabelStyle = {
    fontSize: "11px",
    fontFamily: FONT_FAMILY,
}

const elementStyle = {
    cursor: "pointer",
}

const getChevronUpPath = () => {
    const chevron = [
        [8, -5],
        [8, 5],
        [0, 10],
        [-8, -5],
        [-8, 5],
        [0, -5],
    ]

    let offset = [0, 0]
    chevron.forEach((item) => {
        offset = [offset[0] + item[0], offset[1] + item[1]]
    })

    const path = `l${chevron.map((xy) => `${xy[0]} ${xy[1]}`).join(" ")}`

    return path
}

const getChevronRightPath = () => {
    const chevronRight = [
        [14, 0],
        [6, 6],
        [-6, 6],
        [-14, 0],
        [6, -6],
        [-6, -6],
    ]

    let offset = [0, 0]
    chevronRight.forEach((item) => {
        offset = [offset[0] + item[0], offset[1] + item[1]]
    })

    const path = `l${chevronRight.map((xy) => `${xy[0]} ${xy[1]}`).join(" ")}`

    return path
}

const getInterfaceIcon = (x, y) => {
    return (
        <svg x={x} y={y}>
            <line x1={2} y1={11} x2={12} y2={11} stroke={outlineColor} />
            <circle cx={18} cy={11} r={6} fill="none" stroke={outlineColor} />
        </svg>
    )
}

const getAssessmentIcon = (x, y) => {
    return (
        <svg x={x} y={y}>
            <line x1={8} y1={18} x2={13} y2={12} stroke={outlineColor} />
            <circle cx={16} cy={8} r={5} fill="none" stroke={outlineColor} />
        </svg>
    )
}

const getParallelogramIcon = (x, y, withExtraLine) => {
    return (
        <svg x={x - 30} y={y} width={20} height={20}>
            <path d={`M5,1 h 14 l -5 10 h -14 z`} stroke={outlineColor} fill="none" />
            {withExtraLine && <path d={`M9,1 l -5, 10`} stroke={outlineColor} />}
        </svg>
    )
}

const getDriverIcon = (x, y) => {
    const cx = x + 8
    const cy = y + 6
    const len = 18

    // use pythagoras to work out half-length of diagonal lines
    const angledLine = ((((len / 2) ^ 2) / 2) ^ (1 / 2)) + 1.5

    return (
        <svg>
            <circle cx={cx} cy={cy} r={2} fill={outlineColor} />

            <circle cx={cx} cy={cy} r={7} fill="none" stroke={outlineColor} strokeWidth={1.2} />

            <line x1={cx} y1={cy - len / 2} x2={cx} y2={cy + len / 2} stroke={outlineColor} />
            <line x1={cx - len / 2} y1={cy} x2={cx + len / 2} y2={cy} stroke={outlineColor} />
            <line
                x1={cx - angledLine}
                y1={cy - angledLine}
                x2={cx + angledLine}
                y2={cy + angledLine}
                stroke={outlineColor}
            />
            <line
                x1={cx - angledLine}
                y1={cy + angledLine}
                x2={cx + angledLine}
                y2={cy - angledLine}
                stroke={outlineColor}
            />
        </svg>
    )
}

const get3CirclesIcon = (x, y) => {
    const xOffset = 8
    const yOffset = 8

    return (
        <svg>
            <circle cx={x + xOffset} cy={y + yOffset} r={2} fill={common.outlineColor} />
            <circle
                cx={x + xOffset}
                cy={y + yOffset}
                r={4}
                fill="none"
                stroke={outlineColor}
                strokeWidth={1.2}
            />
            <circle
                cx={x + xOffset}
                cy={y + yOffset}
                r={7}
                fill="none"
                stroke={outlineColor}
                strokeWidth={1.2}
            />
        </svg>
    )
}

const getInteractionIcon = (x, y) => {
    // The co-ordinates of the semi-circle go from top right ('M' command),
    // then top left, bottom left, bottom right.
    // The co-ordinates are absolute, not relative.

    const arcWidth = 9

    const arcHeight = 14

    const leftArcXOffset = 15

    const rightArcXOffset = leftArcXOffset - 3

    return (
        <svg>
            <path
                d={`M${x - leftArcXOffset},${y} C ${x - leftArcXOffset - arcWidth} ${y}, ${
                    x - leftArcXOffset - arcWidth
                } ${y + arcHeight}, ${x - leftArcXOffset} ${y + arcHeight}`}
                fill="none"
                stroke={outlineColor}
            />

            <line
                x1={x - leftArcXOffset}
                y1={y}
                x2={x - leftArcXOffset}
                y2={y + arcHeight}
                stroke={outlineColor}
            />

            <path
                d={`M${x - rightArcXOffset},${y} C ${x - rightArcXOffset + arcWidth} ${y}, ${
                    x - rightArcXOffset + arcWidth
                } ${y + arcHeight}, ${x - rightArcXOffset} ${y + arcHeight}`}
                fill="none"
                stroke={outlineColor}
            />

            <line
                x1={x - rightArcXOffset}
                y1={y}
                x2={x - rightArcXOffset}
                y2={y + arcHeight}
                stroke={outlineColor}
            />
        </svg>
    )
}

const getCollaborationIcon = (x, y) => {
    return (
        <svg x={x - 26} y={y - 1} width={25} height={25}>
            <circle cx={10} cy={12} r={6} fill="none" stroke={outlineColor} />
            <circle cx={15} cy={12} r={6} fill="none" stroke={outlineColor} />
        </svg>
    )
}

const cos = (degrees, length) => {
    return parseFloat((Math.cos((degrees * Math.PI) / 180) * length).toFixed(3))
}

const sin = (degrees, length) => {
    return parseFloat((Math.sin((degrees * Math.PI) / 180) * length).toFixed(3))
}

// dps = decimal places
const trimNum = (num, dps = 3) => {
    return parseFloat(num.toFixed(dps))
}

const getEquipmentIcon = (x, y, innerRadius, outerRadius, rotate, toothWidthDegrees = 12, fill) => {
    // These degrees are clockwise, and relative to an x/y grid, where x -> right, y -> up,
    // i.e. 0 degrees is 0 offset from 90%, and so on
    const degrees = [0, 45, 90, 135, 180, -135, -90, -45].map((d) => d + rotate).reverse()

    const points = _.flatten(
        degrees.map((d) => {
            let leftDegree = d + toothWidthDegrees
            let rightDegree = d - toothWidthDegrees

            const x1 = cos(leftDegree, innerRadius)
            const y1 = sin(leftDegree, innerRadius)

            const leftInner = { x: x1, y: y1 }

            const x2 = cos(rightDegree, innerRadius)
            const y2 = sin(rightDegree, innerRadius)

            const rightInner = { x: x2, y: y2 }

            // mid point, and on outer edge, along 'top of cog'
            const xOuter = cos(d, outerRadius)
            const yOuter = sin(d, outerRadius)

            const diff = (p1, p2) => {
                return { x: p2.x - p1.x, y: p2.y - p1.y }
            }

            const midInner = { x: cos(d, innerRadius), y: sin(d, innerRadius) }

            const innerLeftDiff = diff(leftInner, midInner)
            const innerRightDiff = diff(midInner, rightInner)

            return [
                { x: x1, y: y1 },
                { x: trimNum(xOuter - innerLeftDiff.x), y: trimNum(yOuter - innerLeftDiff.y) },
                { x: trimNum(xOuter + innerRightDiff.x), y: trimNum(yOuter + innerRightDiff.y) },
                { x: x2, y: y2 },
            ]
        })
    )

    const xOffset = 10,
        yOffset = 10

    const pathData = `M${trimNum(points[0].x + xOffset, 1)},${trimNum(
        points[0].y + yOffset,
        1
    )} L ${points
        .slice(1)
        .map((point) => `${trimNum(point.x + xOffset, 1)} ${trimNum(point.y + yOffset, 1)}`)
        .join(",")} z`

    return (
        <svg x={x} y={y}>
            <svg x={1} y={5}>
                <path d={pathData} stroke={outlineColor} strokeWidth={0.5} fill={fill} />
                <circle
                    cx={xOffset}
                    cy={yOffset}
                    r={innerRadius - 2}
                    stroke={outlineColor}
                    strokeWidth={0.5}
                    fill="none"
                />
            </svg>
        </svg>
    )
}

const getEventIcon = (x, y) => {
    const arcHeight = 5

    return (
        <svg>
            <svg x={x - 22} y={y} width={6} height={20}>
                <ellipse cx={0} cy={8} rx={4} ry={arcHeight} fill="none" stroke={outlineColor} />
            </svg>
            <svg x={x - 10} y={y} width={12} height={20}>
                <ellipse cx={0} cy={8} rx={4} ry={arcHeight} fill="none" stroke={outlineColor} />
            </svg>
            <svg x={x - 25} y={y} width={20} height={20}>
                <line x1={4} y1={3} x2={16} y2={3} stroke={outlineColor} />
                <line x1={4} y1={13} x2={16} y2={13} stroke={outlineColor} />
            </svg>
        </svg>
    )
}

const getArrowPath = () => {
    const arrow = [
        [10, 0],
        [0, -4],
        [10, 7],
        [-10, 7],
        [0, -4],
        [-10, 0],
    ]

    const cumulative = []

    let offset = [0, 0]
    arrow.forEach((item) => {
        offset = [offset[0] + item[0], offset[1] + item[1]]
        cumulative.push(offset)
    })

    const path = `l${arrow.map((xy) => `${xy[0]} ${xy[1]}`).join(", ")}`

    return path
}

const markers = {
    arrow: getArrowPath(),
    chevronUp: getChevronUpPath(),
    chevronRight: getChevronRightPath(),
}

export {
    DEFAULT_APP_COLOR,
    DEFAULT_LOCATION_COLOR,
    elementLabelStyle,
    elementStyle,
    outlineColor,
    markers,
    defaultLineColor,
    defaultLineHighlightColor,
    getInterfaceIcon,
    getEventIcon,
    getCollaborationIcon,
    getInteractionIcon,
    get3CirclesIcon,
    getParallelogramIcon,
    getDriverIcon,
    getAssessmentIcon,
    getEquipmentIcon,
}
