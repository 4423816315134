import { useMemo } from "react"
import * as palette from "../symbols/palette"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"
import _ from "lodash"

const ArchiMateElementSelect = ({
  type,
  setType,
  label = "Element Type",
  filterTypes = [],
}) => {
  const elementOptions = useMemo(() => {
    const filterType =
      filterTypes.length > 0
        ? (item) => filterTypes.includes(item.index)
        : () => true

    const result = palette.ELEMENT_INDEX.filter((item) => filterType(item)).map(
      (item) => {
        return {
          id: Number(item.index),
          label: palette.formatLabel(item.name),
          layer: item.layer.name,
        }
      }
    )
    return result
  }, [filterTypes])

  const elementsForSelect = useMemo(() => {
    const grouped = _.groupBy(elementOptions, "layer")

    const groups = Object.keys(grouped).map((label) => {
      return {
        label: label,
        color: palette.LAYERS.find((x) => x.name === label).color,
        options: grouped[label],
      }
    })

    const result = groups.flatMap((group) => {
      return [
        {
          label: group.label,
          layer: group.label,
          color: group.color,
          group: true,
        },
        ...group.options,
      ]
    })

    return result
  }, [elementOptions])

  const elementTypeMenuItems = useMemo(() => {
    const menuItems = elementsForSelect.map((option) =>
      option.group ? (
        <MenuItem
          key={option.label}
          sx={{ backgroundColor: option.color }}
          value="-1"
        >
          {option.label}
        </MenuItem>
      ) : (
        <MenuItem key={option.label} value={option.id}>
          {option.label}
        </MenuItem>
      )
    )

    return menuItems
  }, [elementsForSelect])

  return (
    <FormControl>
      <InputLabel id="archimate-select">{label}</InputLabel>
      <Select
        labelId="archimate-select"
        id="archimate-select-id"
        label={label}
        onChange={(e) => {
          setType(e.target.value)
        }}
        variant="outlined"
        size="small"
        sx={{ width: "300px" }}
        value={type}
      >
        {elementTypeMenuItems}
      </Select>
    </FormControl>
  )
}

export default ArchiMateElementSelect
