import { Box, Chip } from "@mui/material"
import TagLabel from "./TagLabel"
import * as colors from "@mui/material/colors"

const TagSummary = (props) => {
    const { isEditing, tags } = props

    return (
        <>
            {!isEditing && (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, marginTop: 0 }}>
                    {tags &&
                        tags.map((tag) => (
                            <Chip
                                key={`${tag.type}-${tag.label}`}
                                size="small"
                                label={<TagLabel tag={tag} />}
                                sx={{ backgroundColor: colors.blueGrey[100] }}
                            />
                        ))}
                </Box>
            )}
        </>
    )
}

export default TagSummary
