import { Box, Stack } from "@mui/material"
import Controls from "./controls/Controls"
import { useState } from "react"
import DeleteIcon from "@mui/icons-material/Delete"
import SaveIcon from "@mui/icons-material/Save"
import { useSnackbar, withSnackbar } from "notistack"
import db from "../Firestore"
import { Fragment } from "react"
import * as dataServices from "../pages/services/dataServices"
import { useEffect } from "react"
import firebase from "firebase/compat/app"
import { useHistory, withRouter } from "react-router-dom"
import { spacing } from "../pages/services/styleServices"
import YesNo from "./YesNo"

const styles = {
  fab: {
    position: "fixed",
    bottom: 16,
    right: 16,
    top: "auto",
    left: "auto",
  },

  buttons: {
    marginTop: spacing(3),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "5px",
  },
}

const StoryPromptEditForm = (props) => {
  const [promptId, setPromptId] = useState(props.computedMatch.params.id)

  const { enqueueSnackbar } = useSnackbar()

  const history = useHistory()

  const [yesNoConfig, setYesNoConfig] = useState({
    title: "Delete",
    openPrompt: false,
    description: "Delete?",

    // Callback if user clicks 'Yes' to delete a child record.
    // We set the callback and label depending on what the user is deleting
    handleConfirm: null,
  })

  const [values, setValues] = useState({
    account_id: "",
    name: "",
    description: "",
    prompt: "",
  })

  useEffect(() => {
    if (promptId) {
      db.collection("story_prompts")
        .doc(promptId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data()
            setValues(data)
          } else {
            enqueueSnackbar("Prompt not found", { variant: "error" })
          }
        })
        .catch((error) => {
          enqueueSnackbar("Error getting prompt", { variant: "error" })
        })
    }
  }, [promptId])

  const isNew = () => promptId === undefined || promptId === ""

  const handlePromptConfirmDelete = (event) => {
    event.preventDefault()

    setYesNoConfig({
      title: "Delete Story Prompt?",
      openPrompt: true,
      description: "This delete is permanent. Are you sure?",
      handleConfirm: () => handleDeletePromptConfirmed(),
    })
  }

  const hideDeletePrompt = () => {
    const newConfig = {
      ...yesNoConfig,
      openPrompt: false,
    }
    setYesNoConfig(newConfig)
  }

  const handleDeletePromptConfirmed = async () => {
    hideDeletePrompt()

    if (promptId !== undefined && promptId !== "" && promptId !== null) {
      console.log("remove prompt from firestore", { promptId })
      // Delete prompt
      db.collection("story_prompts")
        .doc(promptId)
        .delete()
        .then(history.push("/storyprompts"))
        .then(() => {
          enqueueSnackbar("Deleted", { variant: "success" })
        })
    }
  }

  const handleSave = async (event) => {
    event.preventDefault()

    if (values.name === "") {
      enqueueSnackbar("Enter prompt name", { variant: "warning" })
    } else {
      if (isNew()) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then(async (token) => {
            const newPrompt = {
              ...values,
              account_id: token.claims.account_id,
              created: dataServices.serverTimestamp(),
              modified: dataServices.serverTimestamp(),
            }

            console.log("saving story prompt", { newPrompt })

            await db
              .collection("story_prompts")
              .add(newPrompt)
              .then((docRef) => {
                setPromptId(docRef.id)
                history.replace(`/storyprompt/${docRef.id}`)
              })
              .then(() => {
                enqueueSnackbar("Created", {
                  variant: "success",
                })
              })
          })
          .catch(function (error) {
            console.error("Error:" + error)
            //enqueueSnackbar("Error", { variant: "error " })
          })
      } else {
        const updateRecord = {
          ...values,
          //tags_index: tagServices.toStringArray(values.tags),
          modified: dataServices.serverTimestamp(),
        }

        console.log("saving record", { promptId, updateRecord })

        db.collection("story_prompts")
          .doc(promptId)
          .update(updateRecord)
          .then(() => {
            enqueueSnackbar("Story Prompt saved", {
              variant: "success",
              vertical: "bottom",
              horizontal: "right",
            })
          })
      }
    }
  }

  return (
    <>
      <YesNo config={yesNoConfig} />

      <Box>
        <Stack
          direction="column"
          spacing={1}
          sx={{ marginTop: "20px", gap: 2 }}
        >
          <Controls.TextInput
            label="Name"
            value={values.name}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
            sx={{ width: "100%" }}
          />

          <Controls.TextInput
            label="Description"
            value={values.description}
            multiline={true}
            onChange={(e) =>
              setValues({ ...values, description: e.target.value })
            }
            sx={{ width: "100%" }}
          />

          <Controls.TextInput
            label="Prompt"
            value={values.prompt}
            multiline={true}
            onChange={(e) => setValues({ ...values, prompt: e.target.value })}
            sx={{ width: "100%" }}
          />
        </Stack>

        <Box sx={styles.buttons}>
          {!isNew() && (
            <Controls.Button
              text="Delete"
              type="button"
              tooltip="Delete this story prompt"
              endIcon={<DeleteIcon />}
              onClick={handlePromptConfirmDelete}
            />
          )}

          <Controls.Button
            type="button"
            text="Save"
            tooltip="Save prompt"
            variant="contained"
            endIcon={<SaveIcon />}
            onClick={(event) => handleSave(event)}
          />
        </Box>
      </Box>
    </>
  )
}

export default withSnackbar(withRouter(StoryPromptEditForm))
