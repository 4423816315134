import React, { useState } from "react"
import * as common from "./common"
import { pink } from "@mui/material/colors"

const styles = {
    elementLabel: common.elementLabelStyle,
}

const GenericSymbol = (props) => {
    const { x, y, height, width, label, type } = props

    const [fill, setFill] = useState(pink[100])

    const textOffset = { x: 10, y: 35 }

    const fillColor = pink[100]

    return (
        <>
            <svg
                onMouseOver={() => {
                    setFill(pink[200])
                }}
                onMouseOut={() => setFill(fillColor)}
            >
                <rect
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill={fill}
                    stroke={pink[300]}
                    strokeWidth="1px"
                />

                <svg x={x + width - 22} y={y}>
                    <circle cx={10} cy={10} r={5} fill={pink[400]} />
                </svg>

                <text x={x + textOffset.x} y={y + textOffset.y} style={styles.elementLabel}>
                    {label} [{type}]
                </text>
            </svg>
        </>
    )
}

export default GenericSymbol
