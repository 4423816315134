// React functional component Dialog window to choose which design to move a view to

import React, { useEffect } from "react"
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItemText,
    ListItemIcon,
    ListItemButton,
    Typography,
} from "@mui/material"
import { Check } from "@mui/icons-material"
import db from "../Firestore"
import { useState } from "react"

const ModelEditMoveViewDialog = ({ accountId, open, onClose, viewId, currentViewSetId }) => {
    const [selectedViewSet, setSelectedViewSet] = useState(null)

    const [viewSets, setViewSets] = React.useState([])

    const onListItemClick = (event, viewSet) => {
        setSelectedViewSet(viewSet)
    }

    const onDialogClose = () => {
        onClose({ viewId: viewId, targetViewSetId: selectedViewSet?.id })
    }

    useEffect(() => {
        db.collection("view_sets")
            .where("account_id", "==", accountId)
            .get()
            .then((querySnapshot) => {
                const viewSets = []
                querySnapshot.forEach((doc) => {
                    viewSets.push({ id: doc.id, ...doc.data() })
                })
                setViewSets(viewSets.sort((a, b) => a.name.localeCompare(b.name)))
            })
    }, [])

    return (
        <Dialog open={open} onClose={onDialogClose}>
            <DialogTitle>Move View</DialogTitle>
            <DialogContent>
                <List dense>
                    {viewSets.map((viewSet, i) => {
                        return (
                            <ListItemButton
                                key={i}
                                selected={selectedViewSet?.id === viewSet.id}
                                onClick={(event) => onListItemClick(event, viewSet)}
                                disabled={currentViewSetId === viewSet.id}
                            >
                                <ListItemIcon>
                                    {selectedViewSet?.id === viewSet.id && <Check />}
                                </ListItemIcon>
                                <ListItemText
                                    primary={viewSet.name}
                                    secondary={
                                        <Typography variant="caption" color="text.secondary">
                                            {viewSet.description}
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                        )
                    })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onDialogClose}>Cancel</Button>
                <Button onClick={onDialogClose} disabled={!selectedViewSet}>
                    Move
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModelEditMoveViewDialog
