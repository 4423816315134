import {
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import DeleteIcon from "@mui/icons-material/Delete"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { useState } from "react"
/**
 * Could represent a file attached to a template definition (/Templates collection), or a file attached to
 * an AI Designer /View_Sets collection item, or /Projects, or /Components item.
 * The callback to down or delete the file is passed in as a prop.
 *
 * @param {*} param0
 * @returns
 */
const FileItem = ({
  fileName,
  fileIndex,
  selectedFileIndex,
  handleDownload,
  handlePromptDeleteFile,
  handleSelectFile,
  extraMenuItems,
  showSecondaryText,
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const showMenu = (event) => {
    setAnchorEl(event.target)
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        id={"model-file-menu"}
        keepMounted
        open={anchorEl !== null}
        onClose={() => setAnchorEl(null)}
      >
        {handleDownload && (
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              handleDownload(fileIndex)
            }}
          >
            <ListItemIcon>
              <CloudDownloadIcon fontSize="small" />
            </ListItemIcon>
            Download File
          </MenuItem>
        )}

        {handlePromptDeleteFile && (
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              handlePromptDeleteFile(fileIndex)
            }}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            Delete File
          </MenuItem>
        )}
        {extraMenuItems &&
          extraMenuItems.map((item) => (
            <MenuItem
              key={item.label}
              disabled={item.disabled}
              onClick={() => {
                setAnchorEl(null)
                item.onClick(fileIndex)
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              {item.label}
            </MenuItem>
          ))}
      </Menu>
      <ListItemButton
        key={fileName}
        selected={fileIndex === selectedFileIndex}
        onClick={() => {
          if (handleSelectFile) {
            handleSelectFile(fileIndex)
          }
        }}
        onDoubleClick={handleDownload}
      >
        <ListItemText
          primaryTypographyProps={{ variant: "body2" }}
          primary={fileName}
          secondary={
            showSecondaryText && (
              <Typography variant="caption" color="primary">
                {showSecondaryText()}
              </Typography>
            )
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            //aria-label="file-menu"
            onClick={(event) => {
              event.stopPropagation()
              showMenu(event)
            }}
            size="large"
          >
            <MoreVertIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItemButton>
    </>
  )
}

export default FileItem
