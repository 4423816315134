import React, { useState, useEffect } from "react"
import { withSnackbar } from "notistack"
import { withRouter, useHistory } from "react-router-dom"
import {
    selectModelState,
    selectShowDocumentationIndicator,
    selectShowTooltipsIndicator,
} from "../redux/selectors"
import _ from "lodash"
import * as palette from "./symbols/palette"
import {
    Grid,
    List,
    ListItemText,
    ListItemIcon,
    Tooltip,
    Typography,
    Box,
    Chip,
    ListItemButton,
} from "@mui/material"
import * as formatting from "../pages/services/formatting"
import ViewCard from "./ViewCard"
import firebase from "firebase/compat/app"
import db from "../Firestore"
import GridLabel from "./GridLabel"
import { setModelState } from "../redux/actions"
import { useDispatch, useSelector } from "react-redux"
import ModelBreadcrumbs from "./ModelBreadcrumbs"
import * as modelServices from "../pages/services/modelServices"
import { Skeleton } from "@mui/material"
import TooltipHover from "./TooltipHover"
import DiagramResize from "./DiagramResize"
import * as dataServices from "../pages/services/dataServices"
import * as icons from "../icons"
import DocumentationIndicator from "./DocumentationIndicator"
import TooltipsIndicator from "./TooltipsIndicator"
import { selectAutofitDiagram } from "../redux/selectors"
import TagSummary from "./TagSummary"
import { spacing } from "../pages/services/styleServices"

const styles = {
    doco: {
        marginTop: spacing(1),
    },

    views: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "top",
        justifyContent: "flex-start",
        "& > *": {
            margin: spacing(1),
        },
    },
    docoPlaceholder: {
        display: "flex",
        flexDirection: "column",
        "& > *": {
            marginTop: spacing(0.2),
            marginBottom: spacing(0.2),
        },
    },
}

const ElementViewForm = (props) => {
    const { parentId, fileName, elementId } = props.computedMatch.params

    const dispatch = useDispatch()

    const modelCache = useSelector(selectModelState)

    const [model, setModel] = useState()

    const [accountId, setAccountId] = useState()

    const [element, setElement] = useState()

    const history = useHistory()

    const [views, setViews] = useState([])

    const [modelCacheKey, setModelCacheKey] = useState()

    const { setTitle } = props

    const showDocumentation = useSelector(selectShowDocumentationIndicator)

    const showTooltips = useSelector(selectShowTooltipsIndicator)

    const autoFitToWindow = useSelector(selectAutofitDiagram)

    // Elements with the same name and type
    const [matchingParents, setMatchingParents] = useState()

    const [width, setWidth] = useState()

    useEffect(() => {
        const unsub = firebase.auth().onAuthStateChanged((user) => {
            console.log("### user changed", user)

            if (user) {
                user.getIdTokenResult(false).then((token) => {
                    console.log("### setting account id", token.claims.account_id)
                    setAccountId(token.claims.account_id)
                })
            }
        })

        return unsub
    }, [])

    const getParents = async (modelIndexes) => {
        const projectIds = modelIndexes.filter((m) => m.type === "project").map((m) => m.parent_id)
        const componentIds = modelIndexes
            .filter((m) => m.type === "component")
            .map((m) => m.parent_id)

        const result = []

        if (projectIds.length > 0) {
            await dataServices.getProjectsByIdChunks(projectIds).then((projects) => {
                result.push(
                    ...projects.map((p) => ({
                        parent_type: "project",
                        ...p,
                    }))
                )
            })
        }

        if (componentIds.length > 0) {
            await dataServices
                .getComponentsByIdChunks(accountId, componentIds)
                .then((components) => {
                    result.push(
                        ...components.map((c) => ({
                            ...c,
                            parent_type: "component",
                        }))
                    )
                })
        }

        return result
    }

    // Find elements in model_index with a matching name
    useEffect(() => {
        if (element) {
            if (element.name !== "") {
                const nameToFind = element.data.name?.toLowerCase()

                db.collection("model_index")
                    .where("parent_id", "!=", model.parent_id)
                    .where("names", "array-contains", nameToFind)
                    .where("account_id", "==", accountId)
                    .get()
                    .then(async (querySnapshot) => {
                        const matchingParents = querySnapshot.docs.map((doc) => {
                            return doc.data()
                        })

                        const results = await getParents(matchingParents)

                        setMatchingParents(results)
                    })
            }
        }
    }, [element])

    useEffect(() => {
        if (!parentId || !accountId) {
            console.log("returning", { parentId, accountId })
            return
        }

        ///xxx cache key is wrong, should be parent id - file name

        const modelCacheId = modelServices.getModelCacheId({
            parentId,
            fileName,
        })
        const modelState = modelCache[modelCacheId]

        if (modelState === undefined) {
            db.collection("model_index")
                .where("parent_id", "==", parentId)
                .where("account_id", "==", accountId)
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        const modelIndex = doc.data()

                        const typeMap = {
                            project: "projects",
                            component: "components",
                        }
                        const subFolder = typeMap[modelIndex.type]
                        const filePath = `accounts/${accountId}/${subFolder}/${modelIndex.parent_id}/`
                        const collection = typeMap[modelIndex.type]

                        // Check if this is already in cache

                        db.collection(collection)
                            .doc(modelIndex.parent_id)
                            .get()
                            .then(function (doc) {
                                const indexData = doc.data()
                                console.log("%cindexData", "color:yellow", {
                                    indexData,
                                    fileName,
                                })

                                modelServices.loadFile(
                                    filePath,
                                    modelIndex.file_name,
                                    loadModelIntoCache,
                                    {
                                        name: doc.data().name,
                                        parentId: modelIndex.parent_id,
                                        type: modelIndex.type,
                                    }
                                )
                            })
                    })
                })

            return
        } else {
            setModel(modelState)
        }
    }, [parentId, fileName, elementId, accountId])

    const [loadState, setLoadState] = useState({})

    useEffect(() => {
        if (!model) {
            return
        }

        if (model.model.file !== fileName) {
            //console.log("%cuseEffect - Model file name mismatch", "color:red", { model, fileName })
            return
        } else {
            // console.log("%cuseEffect - Model file name match", "color:chartreuse", {
            //     model,
            //     fileName,
            // })
        }

        const elementData = model.model.elements.find((el) => el.id === elementId)

        if (elementData === undefined) {
            // console.log("%cuseEffect - Element not found", "color:red", {
            //     model,
            //     elementId,
            //     fileName,
            // })
            return
        }

        const newLoadState = { elementId, fileName, model }
        if (_.isEqual(loadState, newLoadState)) {
            //console.log("%cuseEffect - Load state unchanged", "color:chartreuse", loadState)
            return
        }
        setLoadState(newLoadState)

        const key = getModelCacheKey(fileName, model.parent_id)
        setModelCacheKey(key)

        const elementViews = model.model.views.filter((view) =>
            view.elements.find((element) => element.diagramObject.archimateElement === elementId)
        )

        setViews(elementViews)

        //console.log("%cfind symbol for element", "color:green", { elementId, elementData })

        const symbol = palette.symbols[elementData.type]

        setElement({ data: elementData, symbol: symbol })

        //console.log("%cset element data", "color:orange", { elementData, symbol })

        setTitle(`${elementData.name} :: ${fileName}`)
    }, [model, elementId, fileName])

    const getModelCacheKey = (fileName, parentId) => {
        return { fileName, parentId }
    }

    const loadModelIntoCache = (model, fileName, text, props) => {
        const { name, parentId, type } = props

        const modelState = modelServices.createModelCacheItem(model, fileName, name, parentId, type)

        dispatch(setModelState(modelState))
        setModel(modelState)
    }

    const handleClickParent = (parentId, parentType) => {
        console.log("%chandleClickParent", "color:lightgreen", {
            parentId,
            parentType,
        })

        const url = parentType === "project" ? `/project/${parentId}` : `/component/${parentId}`
        history.push(url)
    }

    return (
        <>
            <ModelBreadcrumbs modelState={model} suffix="element">
                {element?.data?.name}
            </ModelBreadcrumbs>

            <TooltipHover />

            {!element && <Skeleton variant="rectangular" width={200} height={100} />}

            {element && (
                <svg width={170} height={70} viewBox="0 0 150 70">
                    <element.symbol
                        label={element.data.name}
                        showLabel={true}
                        width={140}
                        height={60}
                        x={1}
                        y={1}
                    />
                </svg>
            )}

            <Grid container direction="column" sx={styles.doco}>
                <GridLabel>Description</GridLabel>
                {!element && (
                    <Box sx={styles.docoPlaceholder}>
                        <Skeleton variant="rectangular" width={300} height={10} />
                        <Skeleton variant="rectangular" width={300} height={10} />
                        <Skeleton variant="rectangular" width={300} height={10} />
                        <Skeleton variant="rectangular" width={300} height={10} />
                    </Box>
                )}
                <Grid item>
                    {element?.data.documentation &&
                        formatting
                            .splitDocumentationIntoLines(element.data.documentation)
                            .map((line, index) => (
                                <Typography key={index} variant="body1" component={"span"}>
                                    {line}
                                </Typography>
                            ))}
                    {element?.data.documentation.length === 0 && (
                        <Typography variant="caption" component={"span"}>
                            No description
                        </Typography>
                    )}
                </Grid>

                <Grid item>
                    <TagSummary
                        tags={element?.data.properties.map((p) => {
                            return { type: p.key, label: p.value }
                        })}
                    />
                </Grid>

                {matchingParents?.length > 0 && (
                    <Box>
                        <GridLabel>Element '{element.data.name}' also appears in</GridLabel>
                        <List dense>
                            {matchingParents.map((parent) => (
                                <ListItemButton
                                    key={parent.id}
                                    onClick={() => handleClickParent(parent.id, parent.parent_type)}
                                >
                                    <ListItemIcon>
                                        {parent.parent_type === "project" ? (
                                            <Tooltip title="Project">
                                                <icons.ProjectIcon />
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Component">
                                                <icons.ComponentIcon />
                                            </Tooltip>
                                        )}
                                    </ListItemIcon>
                                    <ListItemText>
                                        {parent.name} {parent.parent_type}
                                    </ListItemText>
                                </ListItemButton>
                            ))}
                        </List>
                    </Box>
                )}

                <Grid item>
                    <DiagramResize setWidth={setWidth} />
                </Grid>

                <Grid item>
                    <DocumentationIndicator /> <TooltipsIndicator />
                </Grid>

                <GridLabel>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "5px",
                            alignItems: "center",
                        }}
                    >
                        <GridLabel>Views in which '{element?.data.name}' is used:</GridLabel>
                        <Chip
                            size="small"
                            sx={{ marginTop: "15px" }}
                            color="primary"
                            label={(views && views.length) || 0}
                        />
                    </Box>
                </GridLabel>

                <Grid item sx={styles.views}>
                    {views &&
                        views.map((view) => (
                            <ViewCard
                                key={view.id}
                                view={view}
                                modelCacheKey={modelCacheKey}
                                highlightElementIds={[elementId]}
                                maxWidth={autoFitToWindow ? width - 120 : 3500}
                                showDocumentation={showDocumentation}
                                showTooltips={showTooltips}
                                showCopyButton={true}
                                showCreateStoryButton={true}
                            />
                        ))}
                </Grid>
            </Grid>
        </>
    )
}

export default withSnackbar(withRouter(ElementViewForm))
