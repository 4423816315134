import { Chip } from "@mui/material"
import { blueGrey } from "@mui/material/colors"
import TagLabel from "./TagLabel"

const TagChip = (props) => {
    const { tag } = props

    return (
        <Chip
            key={`${tag.type}-${tag.label}`}
            size="small"
            label={<TagLabel tag={tag} />}
            sx={{ backgroundColor: blueGrey[50], color: blueGrey[900] }}
        />
    )
}

export default TagChip
