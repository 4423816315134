import React from "react"
import Header from "../components/Header"
import { Box } from "@mui/material"
import VectorStoreGrid from "../components/VectorStoreGrid"

const VectorStores = () => {
  return (
    <Header title="VectorStors">
      <Box padding={1}>
        <VectorStoreGrid />
      </Box>
    </Header>
  )
}

export default VectorStores
