import React from "react"
import Switch from "@mui/material/Switch"
import Grid from "@mui/material/Grid"
import FormControlLabel from "@mui/material/FormControlLabel"
import Box from "@mui/material/Box"
import { spacing } from "../../pages/services/styleServices"

const styles = {
    addMultiple: {
        margin: spacing(1),
        padding: spacing(1),
    },
}

export default function AddMultiple(props) {
    const { addMultiple, setAddMultiple } = props

    const toggleChecked = () => {
        setAddMultiple((prev) => !prev)
    }

    return props.id === undefined ? (
        <Grid item sx={styles.addMultiple}>
            <Box spacing={1}>
                <FormControlLabel
                    control={<Switch size="small" checked={addMultiple} onChange={toggleChecked} />}
                    label="Add Multiple"
                />
            </Box>
        </Grid>
    ) : null
}
