import firebase from "firebase/compat/app"
import { getProviderFromCache } from "./dataServices"

// Used by ProjectEditForm to make sure the 'provider_account_id' attribute
// is set if we pick a provider that has their own account.
// Setting the 'provider_account_id' attribute for the project is used by firestore.rules
// to allow a provider to see any project to which they are assigned

const getProviderValues = async (values, isNew) => {
    const providerValues = {}

    if (
        values.provider_id !== null &&
        values.provider_id !== undefined &&
        values.provider_id !== ""
    ) {
        const provider = await getProviderFromCache(values.provider_id)

        console.log({ supp: provider })

        if (provider.hasOwnProperty("provider_account_id")) {
            providerValues.provider_account_id = provider.provider_account_id
        } else if (!isNew) {
            // No need to delete provider account id for new projects
            providerValues.provider_account_id = firebase.firestore.FieldValue.delete()
        }
        return providerValues
    } else if (!isNew) {
        providerValues.provider_account_id = firebase.firestore.FieldValue.delete()
    }
    return providerValues
}

export { getProviderValues }
