import React, { useMemo, useState } from "react"
import Svg from "./Svg"
import { useFill } from "./useFill"
import * as common from "./common"

// accessType at this stage has been converted to R, W, RW or A from it's original numeric value
// We don't use the showStartMarker and showEndMarker props because Access connectors are a bit
// different, i.e. even though we can draw 2 Access connectors from a Function/Service to a DataObject
// or BusinessObject, the R or W symbol is drawn at different ends of the connector, even though
// the source and target elements are the same. This is different from all other connector types.

const Access = (props) => {
    const {
        x1,
        y1,
        x2,
        y2,
        accessType,
        firstLineSegment = true,
        lastLineSegment = true,
        highlight,
        lineColor,
    } = props

    const isShowStartMarker = () => {
        return ["R", "RW"].includes(accessType) && firstLineSegment
    }

    const isShowEndMarker = () => {
        return ["W", "RW"].includes(accessType) && lastLineSegment
    }

    const markerStart = isShowStartMarker() ? "url(#access-start)" : ""
    const markerEnd = isShowEndMarker() ? "url(#access-end)" : ""

    const hoverColor = common.defaultLineHighlightColor
    const defaultColor = lineColor || common.defaultLineColor

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, setFill })

    const lineStyle = {
        stroke: fill,
        strokeWidth: 2,
        strokeDasharray: "2 3",
        strokeDashoffset: 5, // start with a value equal to sum of stroke-dasharray values
        // 'dash' is defined in index.css
        animation: "dash 0.7s linear infinite", // use infinite to make the animation loop forever
    }

    const lineStyleReverse = {
        stroke: fill,
        strokeWidth: 2,
        strokeDasharray: "2 3",
        strokeDashoffset: 0, // start with a value equal to sum of stroke-dasharray values
        // 'dashReverse' is defined in index.css
        animation: "dashReverse 0.7s linear infinite", // use infinite to make the animation loop forever
    }

    const usedLineStyle = useMemo(() => {
        //console.log("accessType", accessType)
        if (accessType === "R") {
            return lineStyleReverse
        } else if (accessType === "W") {
            return lineStyle
        }
    }, [accessType])

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <defs>
                <marker
                    id="access-start"
                    markerWidth="7"
                    markerHeight="7"
                    refX="0"
                    refY="2.5"
                    orient="auto"
                >
                    <path d="M5 0, 0 2.5, 5 5" stroke="#000" fill="none" strokeDasharray="1 0" />
                </marker>
                <marker
                    id="access-end"
                    markerWidth="7"
                    markerHeight="7"
                    refX="5"
                    refY="2.5"
                    orient="auto"
                >
                    <path d="M0 0, 5 2.5, 0 5" stroke="#000" fill="none" strokeDasharray="1 0" />
                </marker>
            </defs>
            <line
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                stroke={fill}
                strokeWidth="2"
                strokeDasharray="2 3"
                markerStart={markerStart}
                markerEnd={markerEnd}
                style={usedLineStyle}
            />
        </Svg>
    )
}

export default Access
