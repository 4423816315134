import React from "react"
import { useHistory } from "react-router-dom"
import * as common from "./common"
import * as tooltipServices from "../../pages/services/tooltipServices"
import * as colors from "@mui/material/colors"

const styles = {
    element: common.elementStyle,
}

const Svg = (props) => {
    const {
        elementId,
        setFill,
        linkInfo,
        label,
        // The documentation for the element
        documentation,

        // Properties defined for the element
        properties = [],

        hoverColor,
        defaultColor,
        handleMouseOver,
        handleMouseOut,
        handleClickItem,
        handleDoubleClickItem,
        handleElementMouseOver,
        handleElementMouseOut,
        x,
        y,
        showDocumentationIndicator,
        showTooltips,
    } = props

    const history = useHistory()

    return (
        <svg
            onMouseMove={(event) => {
                if (showTooltips && (documentation?.length > 0 || properties?.length > 0)) {
                    //console.log(documentation)
                    tooltipServices.showTooltip(event, label, documentation, properties)
                }
            }}
            onMouseOver={() => {
                handleMouseOver ? handleMouseOver(hoverColor) : setFill(hoverColor)
                if (handleElementMouseOver) {
                    handleElementMouseOver()
                }
            }}
            onMouseOut={() => {
                documentation && showTooltips && tooltipServices.hideTooltip()
                handleMouseOut ? handleMouseOut(defaultColor) : setFill(defaultColor)
                if (handleElementMouseOut) {
                    handleElementMouseOut()
                }
            }}
            onDoubleClick={(e) => {
                if (handleDoubleClickItem) {
                    handleDoubleClickItem({ e, elementId })
                }
            }}
            onClick={(e) => {
                //console.log("%cclick", "color:yellow", { handleClickItem, linkInfo, elementId })
                if (handleClickItem && elementId) {
                    handleClickItem({ e, elementId })
                } else if (handleClickItem) {
                    // This is called when we're clicking on an element displayed as part of a legend, i.e. there is no element id
                    // and typically the element type is curried into the handleClickItem function by the caller.
                    handleClickItem({ e })
                } else if (linkInfo) {
                    history.push(linkInfo.url)
                }
            }}
            sx={styles.element}
        >
            {props.children}
            {showDocumentationIndicator &&
                ((documentation && documentation.length > 0) || properties.length > 0) && (
                    <circle
                        cx={x + 4}
                        cy={y + 4}
                        r={3}
                        fill={colors.pink[500]}
                        stroke={colors.pink[500]}
                    />
                )}
        </svg>
    )
}

export default Svg
