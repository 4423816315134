import React, { useState } from "react"
import * as common from "./common"
import { deepPurple } from "@mui/material/colors"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const Meaning = (props) => {
    const { x, y, height, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = deepPurple[200]
    const defaultColor = fillColor || deepPurple[100]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    // The last point of each 'C' arc needs to be the first point of the next 'L' line in
    // order that the arcs are adjoining.

    const arc1LastPoint = `${width / 2} 5`
    const arc1 = `M5,${height / 2} C 0 5, 5 -5, ${arc1LastPoint}`

    const arc2LastPoint = `${width - 20} ${height / 2 + 5}`
    const arc2 = `L ${arc1LastPoint} C ${width} 0, ${width} ${height * 0.3}, ${arc2LastPoint}`

    const arc3LastPoint = `${width / 2} ${height - 5}`
    const arc3 = `L ${arc2LastPoint} C ${width - 5} ${height}, ${width / 2 + 5} ${
        height - 5
    }, ${arc3LastPoint}`

    const arc4 = `L ${arc3LastPoint} C ${width / 2 - 30} ${height + 5}, -10 ${height}, 5 ${
        height / 2
    }`

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <svg x={x} y={y}>
                <path
                    d={`${arc1} ${arc2} ${arc3} ${arc4}`}
                    fill={fill}
                    stroke={common.outlineColor}
                />
            </svg>

            <ElementText {...props} label={label} x={x + 3} width={width - 5} />
        </Svg>
    )
}

export default Meaning
