import { Typography } from "@mui/material"
import { Box } from "@mui/system"

const PageNo = ({ pageNo }) => {
    return (
        <Box sx={{ marginTop: "5px", marginLeft: "5px" }}>
            <Typography variant="caption" component={"span"} sx={{ color: "#888" }}>
                Page {pageNo}
            </Typography>
        </Box>
    )
}

export default PageNo
