// src/utils/markdownConverter.js

import { Schema } from "prosemirror-model"
import { DOMParser as ProseMirrorDOMParser } from "prosemirror-model"
import { schema as basicSchema } from "prosemirror-schema-basic"
import { addListNodes } from "prosemirror-schema-list"
import { marked } from "marked"

// 1. Define the horizontalRule node manually
const horizontalRule = {
  group: "block",
  parseDOM: [{ tag: "hr" }],
  toDOM: () => ["hr"],
}

// 2. Define the hardBreak node manually
const hardBreak = {
  inline: true,
  group: "inline",
  selectable: false,
  parseDOM: [{ tag: "br" }],
  toDOM: () => ["br"],
}

// 3. Define the link mark
const link = {
  attrs: {
    href: {},
    title: { default: null },
  },
  inclusive: false,
  parseDOM: [
    {
      tag: "a[href]",
      getAttrs(dom) {
        return {
          href: dom.getAttribute("href"),
          title: dom.getAttribute("title"),
        }
      },
    },
  ],
  toDOM(node) {
    const { href, title } = node.attrs
    return ["a", { href, title }, 0]
  },
}

// 4. Define the code_block node
const codeBlock = {
  attrs: {
    language: { default: null }, // Optional: support for syntax highlighting
  },
  content: "text*",
  marks: "",
  group: "block",
  code: true,
  defining: true,
  parseDOM: [
    {
      tag: "pre",
      preserveWhitespace: "full",
      getAttrs(dom) {
        const code = dom.querySelector("code")
        if (code && code.getAttribute("class")) {
          const languageMatch = code.getAttribute("class").match(/language-(\w+)/)
          return { language: languageMatch ? languageMatch[1] : null }
        }
        return { language: null }
      },
    },
  ],
  toDOM(node) {
    const { language } = node.attrs
    const className = language ? `language-${language}` : null
    return ["pre", {}, ["code", { class: className }, 0]]
  },
}

// 5. Clone the existing nodes from basicSchema without converting to a plain object
let nodes = basicSchema.spec.nodes

// 6. Remove the existing 'horizontal_rule' node to prevent conflicts
nodes = nodes.remove("horizontal_rule")

// 7. Add the manually defined 'horizontalRule', 'hardBreak', and 'codeBlock' nodes
nodes = nodes.append({
  horizontalRule,
  hardBreak,
  codeBlock, // Add codeBlock here
})

// 8. Extend the schema with list nodes
nodes = addListNodes(nodes, "paragraph block*", "block")

// 9. Create the custom schema with the link mark
const mySchema = new Schema({
  nodes,
  marks: basicSchema.spec.marks.append({ link }),
})

/**
 * Recursively maps mark types from 'strong' to 'bold', 'em' to 'italic', and handles 'link'.
 *
 * @param {object} node - The current node in the JSON structure.
 */
function mapMarkTypes(node) {
  if (!node || typeof node !== "object") return

  if (node.marks && Array.isArray(node.marks)) {
    node.marks = node.marks.map((mark) => {
      if (mark.type === "strong") {
        return { ...mark, type: "bold" }
      }
      if (mark.type === "em") {
        return { ...mark, type: "italic" }
      }
      if (mark.type === "link") {
        return {
          type: "link",
          attrs: {
            href: mark.attrs.href,
            title: mark.attrs.title || null,
          },
        }
      }
      return mark
    })
  }

  if (node.content && Array.isArray(node.content)) {
    node.content.forEach((child) => mapMarkTypes(child))
  }
}

/**
 * Recursively maps node types to match Tiptap's schema.
 *
 * @param {object} node - The current node in the JSON structure.
 */
function mapNodeTypes(node) {
  if (!node || typeof node !== "object") return

  // Map node types
  if (node.type === "ordered_list") {
    node.type = "orderedList"
  } else if (node.type === "bullet_list") {
    node.type = "bulletList"
  } else if (node.type === "list_item") {
    node.type = "listItem"
  } else if (node.type === "horizontal_rule") {
    node.type = "horizontalRule"
  } else if (node.type === "hard_break") {
    node.type = "hardBreak"
  } else if (node.type === "code_block") {
    node.type = "codeBlock" // Map to Tiptap's codeBlock
    // Optionally, extract language if specified
    if (node.attrs && node.attrs.language) {
      node.attrs.language = node.attrs.language
    }
  }

  // Recursively process child nodes
  if (node.content && Array.isArray(node.content)) {
    node.content.forEach((child) => mapNodeTypes(child))
  }
}

/**
 * Converts a Markdown string to Tiptap-compatible JSON.
 *
 * @param {string} markdown - The Markdown content to convert.
 * @returns {object} - The ProseMirror JSON representation compatible with Tiptap.
 */
export function markdownToTiptapJSON(markdown) {
  try {

    // Convert Markdown to HTML using marked
    const html = marked(markdown)

    // Parse HTML into a DOM Node using the browser's DOMParser
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, "text/html")

    // **NEW STEP**: Remove all <img> elements to prevent 'image' nodes
    const images = doc.querySelectorAll("img")
    images.forEach((img) => img.remove())

    // Use ProseMirror's DOMParser to parse the HTML into a ProseMirror Node
    const prosemirrorDoc = ProseMirrorDOMParser.fromSchema(mySchema).parse(
      doc.body
    )

    // Convert the ProseMirror document to JSON
    const json = prosemirrorDoc.toJSON()

    // Map mark types to match Tiptap's schema
    mapMarkTypes(json)

    // Map node types to match Tiptap's schema
    mapNodeTypes(json)

    return json
  } catch (error) {
    console.error("Error converting Markdown to Tiptap JSON:", error)
    // Return an empty document as a fallback
    return {
      type: "doc",
      content: [],
    }
  }
}
