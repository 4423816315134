import { Box, FormControlLabel, Stack, Switch, Typography } from "@mui/material"
import { useMemo } from "react"
import { useState } from "react"
import ProjectPortfolio from "./ProjectPortfolio"
import ProjectPortfolioChart from "./ProjectPortfolioChart"
import * as palette from "./symbols/palette"

const ModelEditPortolio = (props) => {
    const { elements, setElements, currentShader, width } = props

    const [editPortfolio, setEditPortfolio] = useState(false)

    const [isDateSort, setDateSort] = useState(true)

    const projects = useMemo(() => {
        const result = elements
            .filter((element) => element.type === palette.getIndex(palette.WORK_PACKAGE))
            .sort((a, b) => {
                if (isDateSort) {
                    const aStr = a.props.find((p) => (p.name === "Start Date"))?.value || new Date()
                    const bStr = b.props.find((p) => (p.name === "Start Date"))?.value || new Date()
                    const aDate = new Date(aStr)
                    const bDate = new Date(bStr)

                    // Return 1 if a is after b, -1 if a is before b, 0 if equal
                    const sortVal =
                        aDate.getTime() > bDate.getTime()
                            ? 1
                            : aDate.getTime() < bDate.getTime()
                            ? -1
                            : 0

                    return sortVal
                } else {
                    return a.name.localeCompare(b.name)
                }
            })
        return result
    }, [elements, isDateSort])

    // Date range for portfolio view
    const portfolioDateRange = useMemo(() => {
        // 3 months ago
        const startDate = new Date()
        startDate.setMonth(startDate.getMonth() - 3)

        // 3 years from now
        const endDate = new Date()
        endDate.setFullYear(endDate.getFullYear() + 3)

        return { startDate: startDate.getTime(), endDate: endDate.getTime() }
    }, [])

    return (
        projects &&
        projects.length > 0 && (
            <>
                <Box sx={{ marginTop: "20px", marginBottom: "10px" }}>
                    <Typography variant="h5">Project Portfolio</Typography>
                </Box>

                <FormControlLabel
                    control={
                        <Switch
                            checked={editPortfolio}
                            onChange={() => setEditPortfolio((curr) => !curr)}
                            size={"medium"}
                        />
                    }
                    label={<Typography>Edit Dates</Typography>}
                    labelPlacement="end"
                />

                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>Name Sort</Typography>
                    <Switch checked={isDateSort} onChange={() => setDateSort((curr) => !curr)} />
                    <Typography>Date Sort</Typography>
                </Stack>

                {elements && !editPortfolio && (
                    <Box sx={{ paddingTop: "20px" }}>
                        <ProjectPortfolioChart
                            projects={projects}
                            width={width}
                            currentShader={currentShader}
                            portfolioDateRange={portfolioDateRange}
                        />
                    </Box>
                )}

                {elements && editPortfolio && (
                    <Box sx={{ paddingTop: "20px" }}>
                        <ProjectPortfolio
                            projects={projects}
                            elements={elements}
                            setElements={setElements}
                            currentShader={currentShader}
                            portfolioDateRange={portfolioDateRange}
                        />
                    </Box>
                )}
            </>
        )
    )
}

export default ModelEditPortolio
