import React, { useState } from "react"
import * as common from "./common"
import { lightGreen } from "@mui/material/colors"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const Plateau = (props) => {
    const { x, y, height, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = lightGreen[200]
    const defaultColor = fillColor || lightGreen[100]
    const borderDefaultColor = lightGreen[200]
    const borderHoverColor = lightGreen[400]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    const [borderFill, setBorderFill] = useState(borderDefaultColor)

    const handleMouseOver = () => {
        handleFill(hoverColor)
        setBorderFill(borderHoverColor)
    }

    const handleMouseOut = () => {
        handleFill(defaultColor)
        setBorderFill(borderDefaultColor)
    }

    // width of the 3d border
    const borderWidth = 12

    return (
        <Svg
            {...props}
            defaultColor={defaultColor}
            hoverColor={hoverColor}
            setFill={handleFill}
            // We have custom handling of mouseover/out colors so override these event handlers
            handleMouseOver={handleMouseOver}
            handleMouseOut={handleMouseOut}
        >
            <rect
                x={x + 1}
                y={y + borderWidth + 1}
                width={width - borderWidth - 1}
                height={height - borderWidth - 1}
                stroke={common.outlineColor}
                fill={fill}
            />

            <svg x={x + 1} y={y + 1}>
                <path
                    d={`M0,${borderWidth} L ${borderWidth} 1 L ${width - 1} 1 L ${
                        width - 1 - borderWidth
                    } ${borderWidth}`}
                    fill={fill}
                    stroke={common.outlineColor}
                />

                <path
                    d={`M${width - 1} 1 L ${width - 1} ${height - 1 - borderWidth} L ${
                        width - 1 - borderWidth
                    } ${height - 1} L ${width - 1 - borderWidth} ${borderWidth}`}
                    fill={fill}
                    stroke={common.outlineColor}
                />

                <svg x={width - 40} y={15}>
                    <line x1={7} y1={5} x2={22} y2={5} stroke={common.outlineColor} />
                    <line x1={4} y1={8} x2={19} y2={8} stroke={common.outlineColor} />
                    <line x1={1} y1={11} x2={16} y2={11} stroke={common.outlineColor} />
                </svg>
            </svg>

            <ElementText {...props} label={label} y={y + borderWidth} width={width - borderWidth} />
        </Svg>
    )
}

export default Plateau
