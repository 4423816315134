import React from "react"
import Header from "../components/Header"
import { Box } from "@mui/material"
import ProjectGrid from "../components/ProjectGrid"

const Projects = () => {
    return (
        <Header title="Projects">
            <Box padding={1}>
                <ProjectGrid />
            </Box>
        </Header>
    )
}

export default Projects
