import React from "react"
import RuleCards from "../components/RuleCards"
import Header from "../components/Header"
import { Box } from "@mui/material"

function RuleFind() {
    return (
        <Header title="Rules">
            <Box padding={1}>
                <RuleCards />
            </Box>
        </Header>
    )
}

export default RuleFind
