import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from "@mui/material"
import React from "react"

export default function Select(props) {
  const {
    name,
    label,
    value,
    onChange,
    options,
    variant = "outlined",
    disabled = false,
    addNoneOption = true,
    minWidth = 120,
    ...other
  } = props

  return (
    <FormControl style={{ minWidth: minWidth }} variant={variant}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <MuiSelect
        name={name}
        id={name}
        size="small"
        label={label}
        value={value === undefined ? "" : value}
        onChange={onChange}
        autoWidth={true}
        disabled={disabled}
        {...other}
      >
        {addNoneOption && (
          <MenuItem key="-1" value="">
            None
          </MenuItem>
        )}
        {options.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  )
}
