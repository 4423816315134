import * as palette from "./components/symbols/palette"

const openExchangeAccessTypeMap = { Write: "W", Read: "R", Access: "A", ReadWrite: "RW" }
const getOpenExchangeAccessType = (accessType) => {
    return openExchangeAccessTypeMap[accessType]
}

const getOpenExchangeModel = async (model) => {
    const id = model.model.$.identifier

    const name = model.model.name[0]._

    const diagrams = model.model.views[0].diagrams

    const views = diagrams[0].view.map((rawView) => {
        const id = rawView.$.identifier

        const name = rawView.name[0]._

        const type = "ArchimateDiagramModel"

        const childNodes = []

        // Some models might not have any relationships
        const rawRelationships =
            (model.model.relationships && model.model.relationships[0].relationship) || []

        getChildNodes(rawView.node, rawView, childNodes, rawRelationships)

        const nesting = []
        getDiagramNesting(rawView, nesting)

        const view = {
            id: id,
            name: name,
            documentation: (rawView.documentation && [rawView.documentation[0]._]) || [],
            type: type,
            elements: childNodes,
            nesting: nesting,
        }

        console.log("%cgetOpenExchangeModel", "color: chartreuse", { rawView, view })

        return view
    })

    const propDefs =
        model.model.propertyDefinitions &&
        model.model.propertyDefinitions[0].propertyDefinition.map((propDef) => ({
            id: propDef.$.identifier,
            name: propDef.name[0],
            type: propDef.$.type,
        }))

    const elements = model.model.elements[0].element.map((element) => {
        const elNode = element.$

        let baseType = elNode["xsi:type"]
        let junctionType

        // Since we use an Archi internal storage format, we need to convert OpenExchange to Archi.
        // OpenExchange has separate 'AndJunction' and 'OrJunction' types, whereas Archi has a single 'Junction'
        // type and an additional 'type' attribute which is 'and' or 'or'.
        if (["AndJunction", "OrJunction"].includes(baseType)) {
            if ("AndJunction" === baseType) {
                junctionType = "and"
            } else {
                junctionType = "or"
            }
            baseType = palette.JUNCTION
        }

        const el = {
            id: elNode.identifier,
            type: baseType,
            name: element.name[0]._,
            //documentation: element.documentation ? [element.documentation[0]._] : [],
            documentation: element.documentation || "",
            properties:
                (element.properties &&
                    element.properties[0].property.map((prop) => ({
                        key: propDefs.find((pd) => pd.id === prop.$.propertyDefinitionRef).name,
                        value: prop.value[0]._,
                    }))) ||
                [],
        }

        console.log("%cgetOpenExchangeModel", "color: chartreuse", { element, elNode, el })

        if (baseType === palette.JUNCTION) {
            // There are 2 junction types -- 'and' and 'or'. Only the 'or' type has an attribute called 'type': 'or'. If no 'type' attribute, then it's an 'and' junction.
            el.junctionType = junctionType
        }

        return el
    })

    const relationships =
        (model.model.relationships &&
            model.model.relationships[0].relationship.map((relationship) => {
                const relNode = relationship.$
                const baseType = relNode["xsi:type"]
                const fullType = `${baseType}Relationship`

                const rel = {
                    id: relNode.identifier,
                    type: fullType,
                    documentation: relationship.documentation
                        ? [relationship.documentation[0]._]
                        : [],
                    source: relNode.source,
                    target: relNode.target,
                    name: (relationship.name && relationship.name[0]._) || "",
                    properties:
                        (relationship.properties &&
                            relationship.properties[0].property.map((prop) => ({
                                key: propDefs.find((pd) => pd.id === prop.$.propertyDefinitionRef)
                                    .name,
                                value: prop.value[0]._,
                            }))) ||
                        [],
                }

                // Can't use palette.ACCESS_RELATIONSHIP here since OpenExchange doesn't append the word 'Relationship' to each relationship type.
                if (baseType === "Access") {
                    // Only present for 'Access' type connections
                    rel.accessType = getOpenExchangeAccessType(relNode.accessType)
                }

                return rel
            })) ||
        []

    return { id, name, views, elements: [...elements, ...relationships] }
}

// Details of visual nesting is required in order to export back out to .xml format, and also
// avoid rendering relationships in those cases where incorrect visual nesting has been used by the diagram author
// This function MUST match the output of the same named function in model.mjs, which handles importing .archimate files
const getDiagramNesting = (parent, nesting) => {
    if (parent.node) {
        parent.node.forEach((childItem) => {
            if (parent.$["xsi:type"] !== "Diagram") {
                nesting.push({
                    parent: { id: parent.$.identifier, archimateElement: parent.$.elementRef },
                    child: { id: childItem.$.identifier, archimateElement: childItem.$.elementRef },
                })
            }
            if (childItem.node) {
                getDiagramNesting(childItem, nesting)
            }
        })
    }
}

function rgbToHex({ r, g, b }) {
    return (
        "#" +
        ((1 << 24) + (parseInt(r) << 16) + (parseInt(g) << 8) + parseInt(b)).toString(16).slice(1)
    )
}

const getChildNodes = (node, rawView, result, rawRelationships) => {
    const children = Object.values(node)

    const connections = rawView.connection ? Object.values(rawView.connection) : []

    children.forEach((child) => {
        const targetConnections = connections.filter((c) => {
            return c.$.target === child.$.identifier
        })

        const sourceConnections = connections
            .filter((c) => {
                return c.$.source === child.$.identifier
            })
            .map((c) => {
                const cnx = {
                    connection: {
                        archimateRelationship: c.$.relationshipRef,
                        id: c.$.identifier,
                        source: c.$.source,
                        target: c.$.target,
                        "xsi:type": "archimate:Connection",
                    },
                }

                if (c.bendpoint) {
                    cnx.bendpoints = c.bendpoint.map((bp) => {
                        const relX =
                            parseInt(bp.$.x) - parseInt(child.$.x) - parseInt(child.$.w) / 2
                        const relY =
                            parseInt(bp.$.y) - parseInt(child.$.y) - parseInt(child.$.h) / 2

                        return { startX: relX, startY: relY }
                    })
                }

                return cnx
            })

        let xsiType

        // only populated for DiagramModelReference type elements
        const diagramObjectProps = {}

        if (child.$["xsi:type"] === "Element") {
            xsiType = "archimate:DiagramObject"
        } else if (child.$["xsi:type"] === "Label" && child.viewRef) {
            xsiType = "archimate:DiagramModelReference"
            diagramObjectProps.model = child.viewRef[0].$.ref
        } else {
            xsiType = "archimate:Note"
        }

        if (child.style) {
            diagramObjectProps.fillColor = rgbToHex(child.style[0].fillColor[0].$)
        }

        const element = {
            diagramObject: {
                archimateElement: child.$.elementRef,
                id: child.$.identifier,
                ...diagramObjectProps,
                targetConnections:
                    targetConnections && targetConnections.map((c) => c.$.identifier).join(" "),
                "xsi:type": xsiType,
            },
            bounds: {
                x: parseInt(child.$.x),
                y: parseInt(child.$.y),
                width: parseInt(child.$.w),
                height: parseInt(child.$.h),
            },
            sourceConnections: sourceConnections,

            // Will only be populated for 'Note' elements. Always minimally an empty array.
            // This is similar to 'documentation' but since notes only exist in a view, their 'documentation'
            // is stored per-view as a 'content' attribute (see above)
            noteContent: child.label ? [child.label[0]._] : [],

            viewDocumentation: child.documentation ? [child.documentation[0]._] : [],

            // Some view items (group, note, etc) only exist in the view, and so if they have properties
            // then it is stored here, not in any underlying element, i.e. since there is none.
            // It also seems like the OpenExchange file format does not allow for capturing properties
            // against a Note (or 'Label' as it is called in the OpenExchange file format))
            // and so we always expect this attribute to be empty, whereas in the ArchiMate
            // equivalent it can contain values
            viewProperties: [],
        }

        result.push(element)

        if (child.node) {
            getChildNodes(child.node, rawView, result, rawRelationships)
        }
    })
}

export { getOpenExchangeModel }
