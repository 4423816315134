import React, { Fragment, useEffect, useMemo, useState, useRef } from "react"
import Controls from "../components/controls/Controls"
import { useForm, Form } from "../components/useForm"
import PhoneIcon from "@mui/icons-material/Phone"
import EmailIcon from "@mui/icons-material/Email"
import db from "../Firestore"
import { withSnackbar, useSnackbar } from "notistack"
import firebase from "firebase/compat/app"
import ChangePasswordDialog from "../components/ChangePasswordDialog"
import { validateQRCodeOTPAndGetToken } from "../pages/services/cloudFunctions"
import * as authenticationServices from "../pages/services/authenticationServices"
import ProgressBackdrop from "../components/ProgressBackdrop"
import * as cloudFunctions from "../pages/services/cloudFunctions"
import QRCode from "qrcode"
import { QrCode2Outlined } from "@mui/icons-material"
import {
  Alert,
  Box,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material"
import { spacing } from "./services/styleServices"
import { parsePhoneNumberFromString } from "libphonenumber-js"

const ProfileForm = () => {
  const initialValues = {
    name: "",
    email: "",
    phone: "",
  }

  const [user, setUser] = useState()

  const { enqueueSnackbar } = useSnackbar()

  const { values, setValues, handleInputChange } = useForm(initialValues)

  const [changePasswordOpen, setChangePasswordOpen] = useState(false)

  const [isInvalidCredentials, setInvalidCredentials] = useState(false)

  const [isQRCodeEnabled, setQRCodeEnabled] = useState(false)

  const [isShowProgress, setShowProgress] = useState(false)

  const [isShowQRCode, setShowQRCode] = useState(false)

  // For Google.com email addresses we don't need to allow the user to use a QR code, since Google would
  // handle that if the user had turned it on.
  // QR code enablement is only required for when the app handles login/password
  const [isPasswordProvider, setPasswordProvider] = useState(false)

  const [isGoogleProvider, setGoogleProvider] = useState(false)

  const [qrCodeUrl, setQrCodeUrl] = useState("")

  const [isPhoneValid, setPhoneValid] = useState(false)

  const imgRef = useRef()

  const BLANK_IMAGE = "/blank.png"

  // Does the user have a password to change?
  const hasPassword = useMemo(() => {
    return authenticationServices.isPasswordProviderFound(user)
  }, [user])

  useEffect(() => {
    console.log("phone changed", values.phone)
    const phoneNumberObj = parsePhoneNumberFromString(values.phone)
    console.log("phoneNumberObj", phoneNumberObj)
    if (phoneNumberObj) {
      setPhoneValid(phoneNumberObj.isValid())
    } else {
      setPhoneValid(false)
    }
  }, [values.phone])

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      console.log("user changed", user)
      setUser(user)
      db.collection("users")
        .doc(user.uid)
        .get()
        .then((docRef) => {
          const userRec = docRef.data()

          console.log("loaded user", userRec)
          setValues(userRec)
        })

      cloudFunctions
        .getUserAuthenticationMethods({ email: user.email })
        .then((result) => {
          console.log("user auth methods", result)
          setPasswordProvider(result.data.providers.includes("password"))
          setGoogleProvider(result.data.providers.includes("google.com"))
          setQRCodeEnabled(result.data.isQrCodeSecretDefined)

          if (result.data.isQrCodeSecretDefined) {
            handleCreateTotpURI(user.email)
          }
        })
    })

    return unsub
  }, [])

  useEffect(() => {
    if (imgRef && imgRef.current) {
      if (isShowQRCode && isPasswordProvider && qrCodeUrl !== "") {
        imgRef.current.src = qrCodeUrl
      } else {
        imgRef.current.src = BLANK_IMAGE
      }
    }
  }, [imgRef, isShowQRCode, isPasswordProvider, qrCodeUrl])

  const handleCreateTotpURI = (email) => {
    cloudFunctions.createTotpURI(email).then((result) => {
      console.log("create totp URI", { result })
      QRCode.toDataURL(result.data.uri, { errorCorrectionLevel: "L" }).then(
        (url) => {
          console.log("%cset img to QR code URL", "color:pink", { url })
          setQrCodeUrl(url)
        }
      )
    })
  }

  const handleChangePassword = async ({ oldPassword, newPassword, otp }) => {
    console.log("handleChangePassword", oldPassword, newPassword)

    setInvalidCredentials(false)

    const user = firebase.auth().currentUser

    console.log("user", { user, provider: user.providerData })

    console.log("validate QR code payload", user.email, otp)

    const result = await validateQRCodeOTPAndGetToken({
      email: user.email,
      password: user.oldPassword,
      otp: otp,
    })

    console.log("validate QR code result", result)

    const credential = firebase.auth.EmailAuthProvider.credential(
      user.email,
      oldPassword
    )

    user
      .reauthenticateWithCredential(credential)
      .then(async () => {
        const hasPasswordProvider = user.providerData.find(
          (item) => item.providerId === "password"
        )

        console.log("user has password provider?", hasPasswordProvider)

        if (!hasPasswordProvider) {
          console.log("user has no password provider, adding one")
          const linkResult = await user.linkWithCredential(credential)
          console.log("linkResult", linkResult)
        } else {
          const updateResult = await user.updatePassword(newPassword)
          console.log("updateResult", updateResult)
        }
        enqueueSnackbar("Password changed", { variant: "success" })
        setChangePasswordOpen(false)
      })
      .catch((error) => {
        console.log("Error reauthenticating", error)
        setInvalidCredentials(true)
      })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (values.name === "") {
      enqueueSnackbar("Enter name", { variant: "error" })
    } else {
      await db
        .collection("users")
        .doc(user.uid)
        .update(
          {
            name: values.name,
            email: values.email,
            phone: values.phone,
          },
          { merge: true }
        )
        .then(enqueueSnackbar("Saved", { variant: "success" }))
        .catch(function (error) {
          console.error("Error:" + error)
          enqueueSnackbar("Error", { variant: "error " })
        })
    }
  }

  return (
    <Fragment>
      <ProgressBackdrop open={isShowProgress} />
      <Form>
        <Stack gap={1}>
          <Controls.TextInput
            name="name"
            label="Name"
            value={values.name}
            onChange={handleInputChange}
          />

          <Controls.Readonly
            name="email"
            label="Email"
            variant="outlined"
            value={values.email}
            icon={<EmailIcon />}
          />

          <Controls.TextInput
            name="phone"
            label="Phone"
            helperText="International format, +<country code><number>, e.g. +61412345678"
            value={values.phone}
            icon={<PhoneIcon />}
            onChange={handleInputChange}
          />

          {isPasswordProvider && (
            <Controls.Readonly
              name="qrCode"
              label="QR Code Enabled for Multi-Factor Authentication"
              value={
                isQRCodeEnabled
                  ? "Enabled"
                  : "Not Enabled. Click 'Enable QR Code' below"
              }
              helperText="If not enabled then you will use email based One Time Passwords"
            />
          )}

          {isGoogleProvider && (
            <Alert severity="info">
              To use Google Multi-Factor Authentication, please configure this
              in the Google Account you signed up with
            </Alert>
          )}

          <Box>
            {isQRCodeEnabled && (
              <Box sx={{ marginLeft: spacing(2), marginBottom: "10px" }}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      color="primary"
                      checked={isShowQRCode}
                      onChange={() => setShowQRCode((curr) => !curr)}
                    />
                  }
                  label={
                    <Typography variant="caption">Show QR Code</Typography>
                  }
                />
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 1,
              }}
            >
              <Box>
                <img alt="QRCode" ref={imgRef} src="/blank.png" />
              </Box>
              {isShowQRCode && (
                <Box sx={{ marginTop: "12px", maxWidth: "170px" }}>
                  <Typography variant="caption">
                    Scan this QR code with your favourite authenticator, e.g.
                    Google Authenticator, Microsoft Authenticator, and then use
                    a 6-digit code from the authenticator to login instead of an
                    email 6-digit code
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Stack>

        <Stack direction="row" gap={1} sx={{ marginTop: "10px" }}>
          {hasPassword && (
            <Controls.Button
              type="button"
              text="Change Password"
              onClick={() => setChangePasswordOpen(true)}
            />
          )}

          {isPasswordProvider && !isQRCodeEnabled && (
            <Controls.Button
              text="Enable QR Code"
              onClick={() => {
                setShowProgress(true)
                cloudFunctions.generateTotpKey(user.email).then((result) => {
                  console.log("result", result)
                  setQRCodeEnabled(true)
                  handleCreateTotpURI(user.email)
                  setShowQRCode(true)
                  setShowProgress(false)
                })
              }}
              endIcon={<QrCode2Outlined />}
            />
          )}

          <Controls.Button
            type="submit"
            text="Save"
            variant="contained"
            onClick={handleSubmit}
          />
        </Stack>
      </Form>

      <ChangePasswordDialog
        open={changePasswordOpen}
        setOpen={setChangePasswordOpen}
        handleChangePassword={handleChangePassword}
        isInvalidCredentials={isInvalidCredentials}
        user={user}
      />
    </Fragment>
  )
}

export default withSnackbar(ProfileForm)
