// src/components/TemplateInstanceHeadingCard.jsx

import React, { useMemo } from "react"
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Typography,
  Box,
  Stack,
} from "@mui/material"
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome"
import { markdownToTiptapJSON } from "./tiptap/markdownConverter"
import TiptapEditor from "./tiptap/Tiptap"

const TemplateInstanceHeadingCard = React.memo(
  ({ heading, setSelectedHeading }) => {
    // Memoize the section ID to ensure consistency
    const sectionId = useMemo(
      () => heading.level.replace(/\./g, "_"),
      [heading.level]
    )

    // Memoize the content to prevent unnecessary re-renders
    const memoizedContent = useMemo(() => {
      return markdownToTiptapJSON(heading.content || "")
    }, [heading.content])

    return (
      <Card
        id={`section_${sectionId}`}
        sx={{
          scrollMarginTop: "20px", // Optional: adds spacing when scrolled into view
        }}
      >
        <CardHeader
          title={
            <Stack direction="row" gap={1}>
              <Typography fontWeight="bold">{heading.level}</Typography>
              <Typography fontWeight="bold">{heading.heading}</Typography>
            </Stack>
          }
          subheader={
            <Typography variant="caption">{heading.rationale}</Typography>
          }
        />
        <CardContent>
          <Box
            sx={{
              width: "100%",
              margin: "20px 0",
            }}
          >
            <TiptapEditor content={memoizedContent} />
            {/* <SimpleMarkdownRenderer markdown={heading.content} /> */}
          </Box>
        </CardContent>
        <CardActions sx={{ m: "10px" }}>
          <Button
            onClick={() => setSelectedHeading(heading)}
            variant="outlined"
            endIcon={<AutoAwesomeIcon />}
          >
            Fill
          </Button>
        </CardActions>
      </Card>
    )
  }
)

export default TemplateInstanceHeadingCard
