import React from "react"
import { Redirect } from "react-router-dom"

function ProtectedRoute(props) {
    const Component = props.component

    const { allowAccess } = props

    return allowAccess ? <Component {...props}/> : <Redirect to={{ pathname: "/SignIn" }} />
}

export default ProtectedRoute
