import React from "react"
import Header from "../components/Header"
import { Box } from "@mui/material"
import ElementPromptGrid from "../components/ElementPromptGrid"

const ElementPrompts = () => {
  return (
    <Header title="Elements">
      <Box padding={1}>
        <ElementPromptGrid />
      </Box>
    </Header>
  )
}

export default ElementPrompts
