import * as palette from "./components/symbols/palette"
import * as modelServices from "./pages/services/modelServices"

const ARCHIMATE = "archimate"

const typeNames = {
    [palette.LOCATION]: `${ARCHIMATE}:Location`,
    [palette.GROUPING]: `${ARCHIMATE}:Grouping`,
    [palette.GROUP]: `${ARCHIMATE}:Group`,
    [palette.JUNCTION]: `${ARCHIMATE}:Junction`,

    [palette.RESOURCE]: `${ARCHIMATE}:Resource`,
    [palette.CAPABILITY]: `${ARCHIMATE}:Capability`,
    [palette.VALUE_STREAM]: `${ARCHIMATE}:ValueStream`,
    [palette.COURSE_OF_ACTION]: `${ARCHIMATE}:CourseOfAction`,

    [palette.BUSINESS_ACTOR]: `${ARCHIMATE}:BusinessActor`,
    [palette.BUSINESS_ROLE]: `${ARCHIMATE}:BusinessRole`,
    [palette.BUSINESS_COLLABORATION]: `${ARCHIMATE}:BusinessCollaboration`,
    [palette.BUSINESS_INTERFACE]: `${ARCHIMATE}:BusinessInterface`,
    [palette.BUSINESS_PROCESS]: `${ARCHIMATE}:BusinessProcess`,
    [palette.BUSINESS_FUNCTION]: `${ARCHIMATE}:BusinessFunction`,
    [palette.BUSINESS_INTERACTION]: `${ARCHIMATE}:BusinessInteraction`,
    [palette.BUSINESS_EVENT]: `${ARCHIMATE}:BusinessEvent`,
    [palette.BUSINESS_SERVICE]: `${ARCHIMATE}:BusinessService`,
    [palette.BUSINESS_OBJECT]: `${ARCHIMATE}:BusinessObject`,
    [palette.CONTRACT]: `${ARCHIMATE}:Contract`,
    [palette.REPRESENTATION]: `${ARCHIMATE}:Representation`,
    [palette.PRODUCT]: `${ARCHIMATE}:Product`,

    [palette.APPLICATION_COMPONENT]: `${ARCHIMATE}:ApplicationComponent`,
    [palette.APPLICATION_COLLABORATION]: `${ARCHIMATE}:ApplicationCollaboration`,
    [palette.APPLICATION_INTERFACE]: `${ARCHIMATE}:ApplicationInterface`,
    [palette.APPLICATION_FUNCTION]: `${ARCHIMATE}:ApplicationFunction`,
    [palette.APPLICATION_INTERACTION]: `${ARCHIMATE}:ApplicationInteraction`,
    [palette.APPLICATION_PROCESS]: `${ARCHIMATE}:ApplicationProcess`,
    [palette.APPLICATION_EVENT]: `${ARCHIMATE}:ApplicationEvent`,
    [palette.APPLICATION_SERVICE]: `${ARCHIMATE}:ApplicationService`,
    [palette.DATA_OBJECT]: `${ARCHIMATE}:DataObject`,

    [palette.NODE]: `${ARCHIMATE}:Node`,
    [palette.DEVICE]: `${ARCHIMATE}:Device`,
    [palette.SYSTEM_SOFTWARE]: `${ARCHIMATE}:SystemSoftware`,
    [palette.TECHNOLOGY_COLLABORATION]: `${ARCHIMATE}:TechnologyCollaboration`,
    [palette.TECHNOLOGY_INTERFACE]: `${ARCHIMATE}:TechnologyInterface`,
    [palette.PATH]: `${ARCHIMATE}:Path`,
    [palette.COMMUNICATION_NETWORK]: `${ARCHIMATE}:CommunicationNetwork`,
    [palette.TECHNOLOGY_FUNCTION]: `${ARCHIMATE}:TechnologyFunction`,
    [palette.TECHNOLOGY_PROCESS]: `${ARCHIMATE}:TechnologyProcess`,
    [palette.TECHNOLOGY_INTERACTION]: `${ARCHIMATE}:TechnologyInteraction`,
    [palette.TECHNOLOGY_EVENT]: `${ARCHIMATE}:TechnologyEvent`,
    [palette.TECHNOLOGY_SERVICE]: `${ARCHIMATE}:TechnologyService`,
    [palette.ARTIFACT]: `${ARCHIMATE}:Artifact`,
    [palette.EQUIPMENT]: `${ARCHIMATE}:Equipment`,
    [palette.FACILITY]: `${ARCHIMATE}:Facility`,
    [palette.DISTRIBUTION_NETWORK]: `${ARCHIMATE}:DisitributionNetwork`,
    [palette.MATERIAL]: `${ARCHIMATE}:Material`,

    [palette.STAKEHOLDER]: `${ARCHIMATE}:Stakeholder`,
    [palette.DRIVER]: `${ARCHIMATE}:Driver`,
    [palette.ASSESSMENT]: `${ARCHIMATE}:Assessment`,
    [palette.GOAL]: `${ARCHIMATE}:Goal`,
    [palette.OUTCOME]: `${ARCHIMATE}:Outcome`,
    [palette.PRINCIPLE]: `${ARCHIMATE}:Principle`,
    [palette.REQUIREMENT]: `${ARCHIMATE}:Requirement`,
    [palette.CONSTRAINT]: `${ARCHIMATE}:Constraint`,
    [palette.MEANING]: `${ARCHIMATE}:Meaning`,
    [palette.VALUE]: `${ARCHIMATE}:Value`,

    [palette.WORK_PACKAGE]: `${ARCHIMATE}:WorkPackage`,
    [palette.DELIVERABLE]: `${ARCHIMATE}:Deliverable`,
    [palette.IMPLEMENTATION_EVENT]: `${ARCHIMATE}:ImplementationEvent`,
    [palette.PLATEAU]: `${ARCHIMATE}:Plateau`,
    [palette.GAP]: `${ARCHIMATE}:Gap`,

    [palette.NOTE]: `${ARCHIMATE}:Note`,
}

const STRATEGY_CATEGORY = "Strategy"
const BUSINESS_CATEGORY = "Business"
const APPLICATION_CATEGORY = "Application"
const TECHNOLOGY_AND_PHYSICAL_CATEGORY = "Technology & Physical"
const MOTIVATION_CATEGORY = "Motivation"
const IMPLEMENTATION_AND_MIGRATION_CATEGORY = "Implementation & Migration"
const OTHER_CATEGORY = "Other"

const typeToCategoryMapping = {
    [palette.LOCATION]: OTHER_CATEGORY,
    [palette.GROUPING]: OTHER_CATEGORY,
    [palette.GROUP]: OTHER_CATEGORY,
    [palette.JUNCTION]: OTHER_CATEGORY,

    [palette.RESOURCE]: STRATEGY_CATEGORY,
    [palette.CAPABILITY]: STRATEGY_CATEGORY,
    [palette.VALUE_STREAM]: STRATEGY_CATEGORY,
    [palette.COURSE_OF_ACTION]: STRATEGY_CATEGORY,

    [palette.BUSINESS_ACTOR]: BUSINESS_CATEGORY,
    [palette.BUSINESS_ROLE]: BUSINESS_CATEGORY,
    [palette.BUSINESS_COLLABORATION]: BUSINESS_CATEGORY,
    [palette.BUSINESS_INTERFACE]: BUSINESS_CATEGORY,
    [palette.BUSINESS_PROCESS]: BUSINESS_CATEGORY,
    [palette.BUSINESS_FUNCTION]: BUSINESS_CATEGORY,
    [palette.BUSINESS_INTERACTION]: BUSINESS_CATEGORY,
    [palette.BUSINESS_EVENT]: BUSINESS_CATEGORY,
    [palette.BUSINESS_SERVICE]: BUSINESS_CATEGORY,
    [palette.BUSINESS_OBJECT]: BUSINESS_CATEGORY,
    [palette.CONTRACT]: BUSINESS_CATEGORY,
    [palette.REPRESENTATION]: BUSINESS_CATEGORY,
    [palette.PRODUCT]: BUSINESS_CATEGORY,

    [palette.APPLICATION_COMPONENT]: APPLICATION_CATEGORY,
    [palette.APPLICATION_COLLABORATION]: APPLICATION_CATEGORY,
    [palette.APPLICATION_INTERFACE]: APPLICATION_CATEGORY,
    [palette.APPLICATION_FUNCTION]: APPLICATION_CATEGORY,
    [palette.APPLICATION_INTERACTION]: APPLICATION_CATEGORY,
    [palette.APPLICATION_PROCESS]: APPLICATION_CATEGORY,
    [palette.APPLICATION_EVENT]: APPLICATION_CATEGORY,
    [palette.APPLICATION_SERVICE]: APPLICATION_CATEGORY,
    [palette.DATA_OBJECT]: APPLICATION_CATEGORY,

    [palette.NODE]: TECHNOLOGY_AND_PHYSICAL_CATEGORY,
    [palette.DEVICE]: TECHNOLOGY_AND_PHYSICAL_CATEGORY,
    [palette.SYSTEM_SOFTWARE]: TECHNOLOGY_AND_PHYSICAL_CATEGORY,
    [palette.TECHNOLOGY_COLLABORATION]: TECHNOLOGY_AND_PHYSICAL_CATEGORY,
    [palette.TECHNOLOGY_INTERFACE]: TECHNOLOGY_AND_PHYSICAL_CATEGORY,
    [palette.PATH]: TECHNOLOGY_AND_PHYSICAL_CATEGORY,
    [palette.COMMUNICATION_NETWORK]: TECHNOLOGY_AND_PHYSICAL_CATEGORY,
    [palette.TECHNOLOGY_FUNCTION]: TECHNOLOGY_AND_PHYSICAL_CATEGORY,
    [palette.TECHNOLOGY_PROCESS]: TECHNOLOGY_AND_PHYSICAL_CATEGORY,
    [palette.TECHNOLOGY_INTERACTION]: TECHNOLOGY_AND_PHYSICAL_CATEGORY,
    [palette.TECHNOLOGY_EVENT]: TECHNOLOGY_AND_PHYSICAL_CATEGORY,
    [palette.TECHNOLOGY_SERVICE]: TECHNOLOGY_AND_PHYSICAL_CATEGORY,
    [palette.ARTIFACT]: TECHNOLOGY_AND_PHYSICAL_CATEGORY,

    [palette.STAKEHOLDER]: MOTIVATION_CATEGORY,
    [palette.DRIVER]: MOTIVATION_CATEGORY,
    [palette.ASSESSMENT]: MOTIVATION_CATEGORY,
    [palette.GOAL]: MOTIVATION_CATEGORY,
    [palette.OUTCOME]: MOTIVATION_CATEGORY,
    [palette.PRINCIPLE]: MOTIVATION_CATEGORY,
    [palette.REQUIREMENT]: MOTIVATION_CATEGORY,
    [palette.CONSTRAINT]: MOTIVATION_CATEGORY,
    [palette.MEANING]: MOTIVATION_CATEGORY,
    [palette.VALUE]: MOTIVATION_CATEGORY,

    [palette.WORK_PACKAGE]: IMPLEMENTATION_AND_MIGRATION_CATEGORY,
    [palette.DELIVERABLE]: IMPLEMENTATION_AND_MIGRATION_CATEGORY,
    [palette.IMPLEMENTATION_EVENT]: IMPLEMENTATION_AND_MIGRATION_CATEGORY,
    [palette.PLATEAU]: IMPLEMENTATION_AND_MIGRATION_CATEGORY,
    [palette.GAP]: IMPLEMENTATION_AND_MIGRATION_CATEGORY,
}

const getFolders = (model) => {
    const folders = model["archimate:model"].folder
    return folders
}

const getFolder = (folderName, model) => {
    const folder = getFolders(model).find((folder) => folder.$.name === folderName)
    return folder
}

const getElementByNameAndType = (name, type, model) => {
    const folderName = typeToCategoryMapping[type]

    const folder = getFolder(folderName, model)

    const elements = []
    getTypes(folder, type, elements)

    const element = elements.find((comp) => comp.name === name)

    return element
}

const getElementsByType = (type, model) => {
    const folderName = typeToCategoryMapping[type]

    const folder = getFolder(folderName, model)

    const elements = []
    getTypes(folder, type, elements)

    return elements
}

const getModel = async (model) => {
    const elements = getAllElements(model)
    const views = await getViews(model, elements)

    return {
        id: model["archimate:model"].$.id,
        name: model["archimate:model"].$.name,
        elements: elements,
        views: views,
    }
}

const getViews = async (model, allElements) => {
    const items = []

    const folder = getFolder("Views", model)

    await getDiagramsAndSubFolders(folder, items)

    return items
}

const convertToInts = (bounds) => {
    return {
        // Sometimes there's no 'x' attribute
        x: bounds.hasOwnProperty("x") ? parseInt(bounds.x) : 0,
        y: bounds.hasOwnProperty("y") ? parseInt(bounds.y) : 0,
        width: parseInt(bounds.width),
        height: parseInt(bounds.height) || 0,
    }
}

// Details of visual nesting is required in order to export back out to .xml format, and also
// avoid rendering relationships in those cases where incorrect visual nesting has been used by the diagram author
// This function MUST match the output of the same named function in openchangemodel.mjs
const getDiagramNesting = (parent, nesting) => {
    if (parent.child) {
        parent.child.forEach((childItem) => {
            if (parent.$["xsi:type"] !== "archimate:ArchimateDiagramModel") {
                nesting.push({
                    parent: { id: parent.$.id, archimateElement: parent.$.archimateElement },
                    child: { id: childItem.$.id, archimateElement: childItem.$.archimateElement },
                })
            }
            if (childItem.child) {
                getDiagramNesting(childItem, nesting)
            }
        })
    }
}

const getDiagramElements = (parent, childElements, boundsOffset) => {
    if (parent.child) {
        parent.child.forEach((childItem) => {
            const numericBounds = convertToInts(childItem.bounds[0].$)

            const boundsPlusOffset = {
                ...numericBounds,
                x: numericBounds.x + boundsOffset.x,
                y: numericBounds.y + boundsOffset.y,
            }

            // Only 'Notes' have a 'content' element
            const noteContent = childItem.content || []

            const viewElement = {
                diagramObject: childItem.$,
                bounds: boundsPlusOffset,
                sourceConnections: childItem.sourceConnection
                    ? childItem.sourceConnection.map((connection) => {
                          const cnx = { connection: connection.$ }

                          if (connection.bendpoint) {
                              cnx.bendpoints = connection.bendpoint.map((bp) =>
                                  modelServices.bendpointToInts(bp.$)
                              )
                          }

                          return cnx
                      })
                    : [],

                // Will only be populated for 'Note' elements. Always minimally an empty array.
                // This is similar to 'documentation' but since notes only exist in a view, their 'documentation'
                // is stored per-view as a 'content' attribute (see above)
                noteContent: noteContent,

                viewDocumentation: childItem.documentation ? [childItem.documentation[0]] : [],

                // Some view items (group, note, etc) only exist in the view, and so if they have properties
                // then it is stored here, not in any underlying element, i.e. since there is none.
                viewProperties: childItem.property ? childItem.property.map((p) => p.$) : [],
            }

            childElements.push(viewElement)
            if (childItem.child) {
                const newBoundsOffset = { x: boundsPlusOffset.x, y: boundsPlusOffset.y }
                getDiagramElements(childItem, childElements, newBoundsOffset)
            } else {
                //console.log("No child", childItem)
            }
        })
    }
}

const DIAGRAM = "archimate:DiagramObjectModel"

const getDiagramsAndSubFolders = async (folder, items) => {
    const diagrams =
        folder.type === DIAGRAM
            ? []
            : folder.element?.filter((el) => el.type === DIAGRAM || !el.hasOwnProperty("type")) ||
              []

    const folders =
        (!folder.hasOwnProperty("type") &&
            folder.folder?.filter((f) => !f.hasOwnProperty("type"))) ||
        []

    folders.forEach(async (f) => await getDiagramsAndSubFolders(f, items))

    diagrams.forEach((diagram) => {
        const itemData = diagram.$
        const typeName = "xsi:type"
        const type = itemData[typeName]
        const baseType = type.split(":")[1] // get 2nd element, e.g. "archimate:ApplicationComponent" => "ApplicationComponent"

        const childElements = []

        const boundsOffset = { x: 0, y: 0 }
        getDiagramElements(diagram, childElements, boundsOffset)

        const nesting = []
        getDiagramNesting(diagram, nesting)

        const diagramData = {
            id: itemData.id,
            type: baseType,
            name: itemData.name,
            elements: childElements,
            nesting: nesting,
            documentation: diagram.documentation ? diagram.documentation : [],
        }

        items.push(diagramData)
    })
}

const getGroupedElements = (model) => {
    const grouped = getElementCounts(model)
    return Object.keys(grouped).map((key) => {
        return { key, count: grouped[key].length }
    })
}

const getElementCounts = (model) => {
    //console.log("getElementCounts", model.model.elements)

    const grouped = model.model.elements.reduce((result, currentValue) => {
        ;(result[currentValue["type"]] = result[currentValue["type"]] || []).push(currentValue)

        //console.log(currentValue)
        return result
    }, [])

    //console.log("grouped", grouped)

    return grouped
}

const getAllElements = (model) => {
    // Get all the top level folders, in which we'll extract elements. Ignore the 'Views' folder -- we extact that separately

    const topLevelFolders = model["archimate:model"].folder
        .map((f) => f.$.name)
        .filter((name) => name.toLowerCase() !== "views")

    const elements = []
    const promises = []
    topLevelFolders.forEach((name) => {
        const folder = getFolder(name, model)
        promises.push(getElements(folder, elements))
    })
    Promise.all(promises)
    return elements
}

// Recursive function to find all elements of a given type
const getTypes = (folder, type, items) => {
    folder.element.forEach((item) => {
        const element = item.$
        const typeName = "xsi:type"
        if (element[typeName] === typeNames[type]) {
            items.push({
                name: element.name,
                type: type,
                id: element.id,
                documentation: element.documentation ? element.documentation : [],
            })
        }
    })

    if (folder.folder) {
        folder.folder.forEach((f) => getTypes(f, type, items))
    }
}

// These numeric codes are specific to Archi. OpenExchange uses different codes.
const archiAccessTypeMap = { 1: "R", 2: "A", 3: "RW" }
const getArchiAccessType = (numericAccessType) => {
    let result
    if (numericAccessType === undefined) {
        result = "W"
    } else {
        result = archiAccessTypeMap[numericAccessType]
    }

    return result
}

const getElements = async (folder, items) => {
    if (folder.folder) {
        folder.folder.forEach(async (f) => await getElements(f, items))
    }

    folder.element?.forEach((item) => {
        const itemData = item.$
        const typeName = "xsi:type"
        const type = itemData[typeName]
        const baseType = type.split(":")[1] // get 2nd element, e.g. "archimate:ApplicationComponent" => "ApplicationComponent"
        const elementData = {
            id: itemData.id,
            type: baseType,
            name: itemData.name || "",
            documentation: item.documentation ? item.documentation : [],
            source: itemData.source,
            target: itemData.target,
            properties: item.property?.map((p) => p.$).filter((p) => p !== undefined) || [],
        }

        if (baseType === palette.JUNCTION) {
            // There are 2 junction types -- 'and' and 'or'. Only the 'or' type has an attribute called 'type': 'or'. If no 'type' attribute, then it's an 'and' junction.
            elementData.junctionType = itemData.type || "and"
        }

        if (baseType === palette.ACCESS_RELATIONSHIP) {
            // Only present for 'Access' type connections
            elementData.accessType = getArchiAccessType(itemData.accessType)
        }

        items.push(elementData)
    })
}

const getRelations = (element, model) => {
    const relsFolder = getFolder("Relations", model)
    const allElements = getAllElements(model)
    const children = []
    const childRels = relsFolder.element.filter((el) => el.$.source === element.id)
    childRels.forEach((childRel) => {
        const target = allElements.find((el) => el.id === childRel.$.target)
        children.push(target)
    })
    return children
}

export {
    getFolders,
    getFolder,
    getTypes,
    getRelations,
    getDiagramsAndSubFolders as getChildren,
    getAllElements,
    getElementsByType,
    getElementByNameAndType,
    getViews,
    getModel,
    getElementCounts,
    getGroupedElements,
}
