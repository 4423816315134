import React, { useState } from "react"
import * as common from "./common"
import { green } from "@mui/material/colors"
import BaseShapeRoundedRect from "./BaseShapeRoundedRect"
import ElementText from "./ElementText"
import Svg from "./Svg"
import { useFill } from "./useFill"

const Device = (props) => {
    const { x, y, height, width, label, highlight, highlightColor, fillColor } = props

    const hoverColor = green[200]
    const defaultColor = fillColor || green[100]

    const [fill, setFill] = useState(defaultColor)

    const { handleFill } = useFill({ defaultColor, hoverColor, highlight, highlightColor, setFill })

    return (
        <Svg {...props} defaultColor={defaultColor} hoverColor={hoverColor} setFill={handleFill}>
            <rect
                x={x + 1}
                y={y + 1}
                fill={fill}
                stroke={common.outlineColor}
                width={width - 1}
                height={height - 1}
            />

            <svg x={x + width - 26} y={y}>
                <BaseShapeRoundedRect x={1} y={5} height={12} width={18} r={3} fill='none' />

                <path d={`M5,17 l -4 4 h 18 l -4 -4`} fill='none' stroke={common.outlineColor} />
            </svg>

            <ElementText {...props} label={label} />
        </Svg>
    )
}

export default Device
