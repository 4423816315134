import firebase from "firebase/compat/app"
import { useEffect, useState } from "react"

const useClaims = ({ forceRefresh }) => {
  const [claims, setClaims] = useState(null)
  const [user, setUser] = useState(null)

  useEffect(() => {
    console.log("%cuseClaims", "color:orange", { forceRefresh })

    const unsub = firebase.auth().onAuthStateChanged((user) => {
      if (user !== null) {
        user.getIdTokenResult(forceRefresh).then((token) => {
          console.log("%cretrieving claims", "color:pink")
          setClaims(token.claims)
          setUser(user)
        })
      }
    })

    return unsub
  }, [forceRefresh]) // Include forceRefresh in the dependency array

  return {
    claims,
    accountId: claims?.account_id,
    roles: claims?.roles,
    userId: claims?.sub,
    user: user,
  }
}

export default useClaims
