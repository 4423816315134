import ApplicationComponent from "./ApplicationComponent"
import GenericSymbol from "./GenericSymbol"
import ApplicationProcess from "./ApplicationProcess"
import DataObject from "./DataObject"
import ApplicationService from "./ApplicationService"
import ApplicationFunction from "./ApplicationFunction"
import Location from "./Location"
import Triggering from "./Triggering"
import Association from "./Association"
import Composition from "./Composition"
import Aggregation from "./Aggregation"
import GenericLine from "./GenericLine"
import BusinessProcess from "./BusinessProcess"
import BusinessFunction from "./BusinessFunction"
import BusinessService from "./BusinessService"
import Contract from "./Contract"
import BusinessObject from "./BusinessObject"
import TechnologyProcess from "./TechnologyProcess"
import TechnologyService from "./TechnologyService"
import TechnologyFunction from "./TechnologyFunction"
import WorkPackage from "./WorkPackage"
import Grouping from "./Grouping"
import Group from "./Group"
import ValueStream from "./ValueStream"
import Capability from "./Capability"
import BusinessRole from "./BusinessRole"
import Stakeholder from "./Stakeholder"
import ApplicationInterface from "./ApplicationInterface"
import BusinessInterface from "./BusinessInterface"
import TechnologyInterface from "./TechnologyInterface"
import Requirement from "./Requirement"
import BusinessEvent from "./BusinessEvent"
import ApplicationEvent from "./ApplicationEvent"
import TechnologyEvent from "./TechnologyEvent"
import ImplementationEvent from "./ImplementationEvent"
import Product from "./Product"
import BusinessCollaboration from "./BusinessCollaboration"
import ApplicationCollaboration from "./ApplicationCollaboration"
import TechnologyCollaboration from "./TechnologyCollaboration"
import SystemSoftware from "./SystemSoftware"
import Artifact from "./Artifact"
import ApplicationInteraction from "./ApplicationInteraction"
import BusinessInteraction from "./BusinessInteraction"
import TechnologyInteraction from "./TechnologyInteraction"
import Node from "./Node"
import Device from "./Device"
import CommunicationNetwork from "./CommunicationNetwork"
import Path from "./Path"
import Resource from "./Resource"
import CourseOfAction from "./CourseOfAction"
import Goal from "./Goal"
import Outcome from "./Outcome"
import Constraint from "./Constraint"
import Value from "./Value"
import Driver from "./Driver"
import Assessment from "./Assessment"
import Meaning from "./Meaning"
import Principle from "./Principle"
import Deliverable from "./Deliverable"
import Plateau from "./Plateau"
import Gap from "./Gap"
import Representation from "./Representation"
import BusinessActor from "./BusinessActor"
import Note from "./Note"
import Equipment from "./Equipment"
import Facility from "./Facility"
import DistributionNetwork from "./DistributionNetwork"
import Material from "./Material"
import Junction from "./Junction"
import Assignment from "./Assignment"
import Flow from "./Flow"
import Access from "./Access"
import Specialization from "./Specialization"
import Realization from "./Realization"
import Influence from "./Influence"
import Serving from "./Serving"
import DiagramModelReference from "./DiagramModelReference"
import Connection from "./Connection"
import _ from "lodash"
import * as colors from "@mui/material/colors"

const LOCATION = "Location"
const GROUPING = "Grouping"
const GROUP = "Group"
const JUNCTION = "Junction"

const RESOURCE = "Resource"
const CAPABILITY = "Capability"
const VALUE_STREAM = "ValueStream"
const COURSE_OF_ACTION = "CourseOfAction"

const BUSINESS_ACTOR = "BusinessActor"
const BUSINESS_ROLE = "BusinessRole"
const BUSINESS_COLLABORATION = "BusinessCollaboration"
const BUSINESS_INTERFACE = "BusinessInterface"
const BUSINESS_PROCESS = "BusinessProcess"
const BUSINESS_FUNCTION = "BusinessFunction"
const BUSINESS_INTERACTION = "BusinessInteraction"
const BUSINESS_EVENT = "BusinessEvent"
const BUSINESS_SERVICE = "BusinessService"
const BUSINESS_OBJECT = "BusinessObject"
const CONTRACT = "Contract"
const REPRESENTATION = "Representation"
const PRODUCT = "Product"

const APPLICATION_COMPONENT = "ApplicationComponent"
const APPLICATION_COLLABORATION = "ApplicationCollaboration"
const APPLICATION_INTERFACE = "ApplicationInterface"
const APPLICATION_FUNCTION = "ApplicationFunction"
const APPLICATION_INTERACTION = "ApplicationInteraction"
const APPLICATION_PROCESS = "ApplicationProcess"
const APPLICATION_EVENT = "ApplicationEvent"
const APPLICATION_SERVICE = "ApplicationService"
const DATA_OBJECT = "DataObject"

const NODE = "Node"
const DEVICE = "Device"
const SYSTEM_SOFTWARE = "SystemSoftware"
const TECHNOLOGY_COLLABORATION = "TechnologyCollaboration"
const TECHNOLOGY_INTERFACE = "TechnologyInterface"
const PATH = "Path"
const COMMUNICATION_NETWORK = "CommunicationNetwork"
const TECHNOLOGY_FUNCTION = "TechnologyFunction"
const TECHNOLOGY_PROCESS = "TechnologyProcess"
const TECHNOLOGY_INTERACTION = "TechnologyInteraction"
const TECHNOLOGY_EVENT = "TechnologyEvent"
const TECHNOLOGY_SERVICE = "TechnologyService"
const ARTIFACT = "Artifact"
const EQUIPMENT = "Equipment"
const FACILITY = "Facility"
const DISTRIBUTION_NETWORK = "DistributionNetwork"
const MATERIAL = "Material"

const STAKEHOLDER = "Stakeholder"
const DRIVER = "Driver"
const ASSESSMENT = "Assessment"
const GOAL = "Goal"
const OUTCOME = "Outcome"
const PRINCIPLE = "Principle"
const REQUIREMENT = "Requirement"
const CONSTRAINT = "Constraint"
const MEANING = "Meaning"
const VALUE = "Value"

const COMPOSITION_RELATIONSHIP = "CompositionRelationship"
const AGGREGATION_RELATIONSHIP = "AggregationRelationship"
const ASSIGNMENT_RELATIONSHIP = "AssignmentRelationship"
const REALIZATION_RELATIONSHIP = "RealizationRelationship"
const SERVING_RELATIONSHIP = "ServingRelationship"
const ACCESS_RELATIONSHIP = "AccessRelationship"
const INFLUENCE_RELATIONSHIP = "InfluenceRelationship"
const TRIGGERING_RELATIONSHIP = "TriggeringRelationship"
const FLOW_RELATIONSHIP = "FlowRelationship"
const SPECIALIZATION_RELATIONSHIP = "SpecializationRelationship"
const ASSOCIATION_RELATIONSHIP = "AssociationRelationship"

const CONNECTION = "Connection"

const WORK_PACKAGE = "WorkPackage"
const DELIVERABLE = "Deliverable"
const IMPLEMENTATION_EVENT = "ImplementationEvent"
const PLATEAU = "Plateau"
const GAP = "Gap"

const NOTE = "Note"

const DIAGRAM_MODEL_REFERENCE = "DiagramModelReference"

const LAYER_ID_MOTIVATION = "1"
const LAYER_ID_STRATEGY = "2"
const LAYER_ID_BUSINESS = "3"
const LAYER_ID_APPLICATION = "4"
const LAYER_ID_TECHNOLOGY = "5"
const LAYER_ID_IMPLEMENTATION = "6"
const LAYER_ID_OTHER = "7"
const LAYER_ID_PHYSICAL = "8"

const LAYER_NAME_MOTIVATION = "Motivation"
const LAYER_NAME_STRATEGY = "Strategy"
const LAYER_NAME_BUSINESS = "Business"
const LAYER_NAME_APPLICATION = "Application"
const LAYER_NAME_TECHNOLOGY = "Technology"
const LAYER_NAME_IMPLEMENTATION = "Implementation"
const LAYER_NAME_PHYSICAL = "Physical"
const LAYER_NAME_OTHER = "Other"

const LAYER_MOTIVATION = {
  id: LAYER_ID_MOTIVATION,
  name: LAYER_NAME_MOTIVATION,
  color: colors.deepPurple[100],
  colorDark: colors.deepPurple[400],
  colorForPrinting: colors.deepPurple[400],
}
const LAYER_STRATEGY = {
  id: LAYER_ID_STRATEGY,
  name: LAYER_NAME_STRATEGY,
  color: colors.deepOrange[200],
  colorDark: colors.deepOrange[400],
  colorForPrinting: colors.deepOrange[400],
}
const LAYER_BUSINESS = {
  id: LAYER_ID_BUSINESS,
  name: LAYER_NAME_BUSINESS,
  color: colors.yellow[100],
  colorDark: colors.yellow[400],
  colorForPrinting: "#E2CC00", // a bit darker
}
const LAYER_APPLICATION = {
  id: LAYER_ID_APPLICATION,
  name: LAYER_NAME_APPLICATION,
  color: colors.lightBlue[100],
  colorDark: colors.lightBlue[400],
  colorForPrinting: colors.lightBlue[400],
}
const LAYER_TECHNOLOGY = {
  id: LAYER_ID_TECHNOLOGY,
  name: LAYER_NAME_TECHNOLOGY,
  color: colors.green[100],
  colorDark: colors.green[400],
  colorForPrinting: colors.green[400],
}
const LAYER_PHYSICAL = {
  id: LAYER_ID_PHYSICAL,
  name: LAYER_NAME_PHYSICAL,
  color: colors.green[200],
  colorDark: colors.green[400],
  colorForPrinting: colors.green[400],
}
const LAYER_IMPLEMENTATION = {
  id: LAYER_ID_IMPLEMENTATION,
  name: LAYER_NAME_IMPLEMENTATION,
  color: colors.pink[50],
  colorDark: colors.pink[400],
  colorForPrinting: colors.pink[400],
}
const LAYER_OTHER = {
  id: LAYER_ID_OTHER,
  name: LAYER_NAME_OTHER,
  color: colors.grey[50],
  colorDark: colors.grey[400],
  colorForPrinting: colors.grey[400],
}

const LAYERS = [
  LAYER_MOTIVATION,
  LAYER_STRATEGY,
  LAYER_BUSINESS,
  LAYER_APPLICATION,
  LAYER_TECHNOLOGY,
  LAYER_PHYSICAL,
  LAYER_IMPLEMENTATION,
  LAYER_OTHER,
]

const getConnectorTypes = () => {
  return [
    COMPOSITION_RELATIONSHIP,
    AGGREGATION_RELATIONSHIP,
    ASSIGNMENT_RELATIONSHIP,
    REALIZATION_RELATIONSHIP,
    SERVING_RELATIONSHIP,
    ACCESS_RELATIONSHIP,
    INFLUENCE_RELATIONSHIP,
    TRIGGERING_RELATIONSHIP,
    FLOW_RELATIONSHIP,
    SPECIALIZATION_RELATIONSHIP,
    ASSOCIATION_RELATIONSHIP,
  ].map((name) => name.replace("Relationship", ""))
}

const APPLICATION_INTERFACE_DESCRIPTION = [
  "An Application Interface represents a point of access where application services are made available to a user, another application component, or a Node. An Application Interface specifies how the functionality of a Component can be accessed by other elements.",
  "An Application Interface exposes Application Services to the environment.",
  "An Application Interface may be part of an Application Component.",
  "An Application Interface can be assigned to Application Services, which means that the interface exposes these services to the environment.",
  "The name of an Application Interface should preferably be a noun.",
]

const APPLICATION_INTERFACE_PROMPT =
  "A point of access where application services are provided to a user, another application component, or a Node. It defines how the functionality of a Component can be accessed. The Interface exposes Application Services to the environment and can be a part of an Application Component. It can be assigned to Application Services, meaning it provides these services to the environment. The name of an Application Interface MUST be a noun."

const LOCATION_DESCRIPTION = [
  "A Location represents a conceptual or physical place or position where concepts are located (e.g., structure elements) or performed (e.g., behavior elements).",
  "The Location element is used to model the places where (active and passive) structure elements such as business actors, application components, and devices are located. This is modeled by means of an aggregation relationship from a location to structure element. A location can also aggregate a behavior element, to indicate where the behavior is performed.",
]
const LOCATION_PROMPT =
  "the conceptual or physical place where structure or behavior elements are located or performed. It models the positioning of elements like business actors or devices, and can also indicate where behaviors are performed."

const RESOURCE_DESCRIPTION = [
  "A Resource represents an asset owned or controlled by an individual or organization.",
  "Resources are a central concept in the field of strategic management, economics, computer science, portfolio management, and more. They are often considered, together with capabilities, to be sources of competitive advantage for organizations.",
  "The name of a Resource should preferably be a noun.",
]
const RESOURCE_PROMPT =
  "represents an asset controlled by an entity. Often linked with capabilities, resources are considered a source of competitive advantage. The name of a resource MUST be a noun."

const CAPABILITY_DESCRIPTION = [
  "A Capability represents an ability that an active structure element, such as an organization, person, or system, possesses.",
  "A Capability focuses on business outcomes. It provides a high-level view of the current and desired abilities of an organization, in relation to its strategy and its environment. They are realized by various elements (people, processes, systems). Capabilities may also have serving relationships; for example, to denote that one capability contributes to another.",
  "Capabilities are expressed in general and high-level terms and are typically realized by a combination of organization, people, processes, information, and technology.",
  "Capabilities are typically aimed at achieving some goal or delivering value by realizing an outcome. Capabilities are themselves realized by core elements. To denote that a set of core elements together realizes a capability, Grouping can be used.",
]
const CAPABILITY_PROMPT_OLD =
  "represents an abstract and stable ability a business possesses to achieve a specific outcome, focusing on 'what' it does rather than 'how'. It's typically realized by a combination of people, processes, and technology, and aims at achieving goals or delivering value. A capability may contribute to or be realized by other core elements, and be labelled as a noun phrase, 'Customer Insight', 'Supply Chain Management', 'Innovation Management', 'Regulatory Compliance', 'Data Analytics', etc."

const CAPABILITY_PROMPT =
  "represents the building blocks of an organisation. They describe what functions need to exist for the organisation to provide its products and services and effectively manage the business. Capabilities are made up of the right balance of people, process, information and technology. Each capability should be named using a consistent '[Noun] [Verb]' format. The noun should represent a business construct, such as 'Asset,' 'Customer', or 'Job', and the verb should describe the action or function associated with the capability such as 'Management' or 'Planning'. You may also use qualifiers to provide greater context to the purpose of the capability, eg 'Relationship', 'Performance', 'Activity'. Here are some examples to guide you: 'Asset Management', 'Customer Relationship Management', 'Customer Needs Qualification', 'Supplier Information Management', 'Business Performance Management', 'Investment Planning', 'Tax Planning', 'Risk Management', 'Regulatory & Ethics Compliance', 'Opportunity Management', 'Offer Management'. Do not limit the list of capabilities to the examples provided. Come up with your own comprehensive list that covers the breadth of the industry. Capabilities should not reflect their implementation or be technology specific, eg 'Technology Integration. Also do not use the following words in Capability names: Support, Service, Enhancement, Collaboration, Transformation."

const VALUE_STREAM_DESCRIPTION = [
  "A Value Stream represents a sequence of activities that create an overall result for a customer, stakeholder, or end user.",
  "A value stream describes how an enterprise organizes its activities to create value. Value streams are typically realized by business processes and possibly other core behavior elements. ",
  "Value streams may be defined at different levels of the organization; e.g., at the enterprise level, business unit level, or department level. Value streams can be a composition or aggregation of value-adding activities. These are also modeled with the value stream element and are known as value (stream) stages, each of which creates and adds incremental value from one stage to the next. These stages are typically related using flow relationships to model the flow of value between them.",
  "Resources can be assigned to value streams and capabilities can serve (i.e., enable) a value stream",
]
//    "Denotes a series of activities delivering an overall result for stakeholders. It describes how activities are organized to create value, typically through business processes. Value Streams can exist at various organizational levels, and often consist of stages adding incremental value. They can be served by capabilities and assigned resources."
const VALUE_STREAM_PROMPT =
  "A Value Stream is a high-level sequence of 5-8 primary activities that collectively produce key outcomes for enterprise stakeholders. Organized systematically, these stages form the core through which the organization delivers its main value, distinct from shared or supporting services. Value stream element names should be action-oriented, reflective of the primary outcome or objective of that element, and devoid of jargon or overly technical terms. The collection of Value Streams should form a logical sequence of value creation."

const COURSE_OF_ACTION_DESCRIPTION = [
  "A Course of Action is an approach or plan for configuring some capabilities and resources of the enterprise, undertaken to achieve a goal.",
  "A Course of Action represents what an enterprise has decided to do. Courses of action can be categorized as strategies (long-term) and tactics (short-term).",
]

const COURSE_OF_ACTION_PROMPT =
  "an approach or plan utilizing the enterprise's capabilities and resources to attain a goal. It signifies the enterprise's strategic or tactical decisions."

const BUSINESS_ACTOR_DESCRIPTION = [
  "A Business Actor represents a business entity that is capable of performing behavior.",
  "Typically, a Business Actor performs the behaviour assigned to one or more Business Roles. It's important to separate the actor from the role because a Business Actor can perform more than one Business Role, and a Business Role can be performed by more than one Business Actor.",
  "Business Actors are humans, departments, and business units. They may be individuals or groups. A Business Actor can be aggregated in a Location",
  "The name of a Business Actor should preferably be a noun.",
]

const BUSINESS_ACTOR_PROMPT =
  "denotes a business entity capable of performing behavior, typically fulfilling one or more Business Roles. This could be individuals, departments, or business units. Business Actors, performing multiple roles or shared roles, can be aggregated in a Location. Their names are ideally nouns."

const BUSINESS_ROLE_DESCRIPTION = [
  "A Business Role represents the responsibility for performing specific behavior, to which an actor can be assigned, or the part an actor plays in a particular action or event.",
  "A Business Role can be fulfilled by more than one Business Actor. Conversely, a Business Actor may fulfil more than one Business Role. For example, given a named teacher, their roles may include those in the domains.",
  "A Business Role will usually exist in an organisation whether or not a given actor fulfils it or not.",
  "A Business Actor that is assigned to a Business Roleis responsible for ensuring that the corresponding behavior is carried out, either by performing it or by delegating and managing its performance",
  "A Business Role may be assigned to one or more Business Processes or Business Functions. A Business Interface or an Application Interface may be used by a Business Role, while a Business Interface may be part of a Business Role (Composition relationship).",
  "The name of a Business Role should preferably be a noun.",
]
const BUSINESS_ROLE_PROMPT =
  "signifies a responsibility for certain behavior that can be assigned to a Business Actor. It can be fulfilled by multiple actors and an actor can fulfill multiple roles. A Business Role, possibly linked to processes or functions, persists regardless of actor assignment. Names are ideally nouns."

const BUSINESS_COLLABORATION_DESCRIPTION = [
  "A Business Collaboration represents an aggregate of two or more business internal active structure elements that work together to perform collective behavior.",
  "Unlike a single Business Role, a Business Collaboration does not necessarily have a permanent status in an organisation. Thus it may be a temporary collaboration. It may not require a special name and can be regarded as a 'virtual' role.",
  "A Business Collaboration can occur when two or more Business Roles need to fulfil specific interaction requirements. Business Collaborations represent the collective effort of combined Business Roles.",
  "The name of a Business Collaboration should preferably be a noun.",
]

const BUSINESS_COLLABORATION_PROMPT =
  "symbolizes the aggregate effort of two or more active structure elements performing collective behavior. It may be a temporary or 'virtual' role unlike permanent Business Roles, usually arising when multiple roles need to fulfill specific interactions. Names are ideally nouns."

const BUSINESS_INTERFACE_DESCRIPTION = [
  "A Business Interface represents a point of access where a business service is made available to the environment.",
  "A Business Interface exposes the functionality of a business service to other business roles or actors. It is often referred to as a channel (telephone, Internet, local office, etc.). The same business service may be exposed through different interfaces.",
  "A Business Interface may be part of a business role or actor through a Composition relationship, and a Business Interface may serve a Business Role.",
  "A Business Interface exposes a Business Service provided by a Business Role or Business Collaboration to its environment. A Business Service may also be exposed through different interfaces.",
  "The name of a Business Interface should preferably be a noun.",
]

const BUSINESS_INTERFACE_PROMPT =
  "a point of access where a business service is made available. It exposes a business service's functionality to other roles or actors, often through different channels. It may be part of a business role or actor, and it can serve a Business Role. Names are ideally nouns."

const BUSINESS_PROCESS_DESCRIPTION = [
  "A Business Process represents a sequence of business behaviors that achieves a specific result such as a defined set of products or business services.",
  "A Business Process describes the internal behaviour performed by a Business Role that is required to produce a set of products and services. For a consumer the required behaviour is not of interest so a process is designated 'Internal'.",
  "A complex Business Process may be an aggregation of other, finer-grained processes. To each of these finer-grained roles may be assigned.",
  "The name of a Business Process should preferably be or contain a verb in the simple present tense.",
]

const BUSINESS_PROCESS_PROMPT =
  "a sequence of business behaviors achieving a specific outcome, such as a defined set of products or services. It describes the internal behavior required by a Business Role. Complex processes may aggregate finer-grained processes with assigned roles. The name should preferably contain a present tense verb."

const BUSINESS_FUNCTION_DESCRIPTION = [
  "A Business Function represents a collection of business behavior based on a chosen set of criteria (typically required business resources and/or competencies), closely aligned to an organization, but not necessarily explicitly governed by the organization.",
  "Business Processes describe a flow of activities. Business Functions group activities according to their required skills, knowledge, and resources. A Business Process forms a string of Business Functions.",
  "A Business Function may be triggered by, or trigger, any other business behaviour element (Business Event, Business Process, Business Function, or Business Interaction).",
  "A Business Function may be triggered by, or trigger, any other business behavior element (Business Event, Business Process, Business Function, or Business Interaction). A Business Function may access Business Objects. A Business Function may realize one or more BusinessServices and may use (internal) Business Services or Application Services. A Business role or an Application Component may be assigned to a Business Function.",
  "The name of a Business Function should clearly indicate a well-defined behaviour.",
]

const BUSINESS_FUNCTION_PROMPT =
  "a collection of business behaviors grouped based on criteria like required resources or competencies. Unlike business processes which describe a flow of activities, Business Functions cluster activities by their requirements. They may interact with other business behavior elements and realize or use various services. The name should indicate well-defined behavior."

const BUSINESS_INTERACTION_DESCRIPTION = [
  "A Business Interaction represents a unit of collective business behavior performed by (a collaboration of) two or more Business Actors, Business Roles, or Business Collaborations.",
  "A Business Interaction is similar to a Business Process or Business Function, but while a process/function may be performed by a single role, an interaction is performed by multiple roles in collaboration.",
  "A Business Interaction may be triggered by, or trigger, any other business behaviour element (Business Event, Business Process, Business Function, or Business Interaction).",
  "A Business Interaction may access Business Objects.",
  "A Business Interaction may realize one or more Business Services and may use (internal) Business Services or Application Services.",
  "A Business Collaboration or an Application Collaboration may be assigned to a Business Interaction.",
  "The name of a Business Interaction should preferably be a verb in the simple present tense.",
]

const BUSINESS_INTERACTION_PROMPT =
  "a collective business behavior unit performed by two or more Business Actors, Roles, or Collaborations. Unlike a single-role process/function, an interaction involves multiple roles. It may interact with other business behavior elements, access Business Objects, and realize or use various services. A Business or Application Collaboration may be assigned to it. The name should preferably contain a present tense verb."

const BUSINESS_EVENT_DESCRIPTION = [
  "A Business Event represents an organizational state change.",
  "Business Processes and other business behaviour may be triggered or interrupted by a Business Event.",
  "Also, Business Processes may raise events that trigger other Business Processes, Functions, or Interactions. A Business Event is most commonly used to model something that triggers behaviour.",
  "Unlike Business Processes, Functions, and Interactions, a Business Event is instantaneous: it does not have duration. A Business Event may have a time attribute that denotes the moment or moments at which the event happens.",
  "Events may originate from the environment of the organization (e.g., from a customer), but also internal events may occur generated by, for example, other processes within the organization.",
  "A Business Event may trigger or be triggered (raised) by a Business Process, Business Function, or Business Interaction.",
  "A Business Event may access a Business Object and may be composed of other Business Events.",
  "The name of a Business Event should preferably be a verb in the perfect tense; e.g., 'claim received'",
]

const BUSINESS_EVENT_PROMPT =
  "a state change within an organization, potentially triggering or interrupting Business Processes and other behaviors. These events, instantaneous by nature, may be external or internal to the organization. They can interact with Business Objects and can compose of other Business Events. The event's name should ideally be a verb in the perfect tense, like 'claim received'."

const BUSINESS_SERVICE_DESCRIPTION = [
  "A Business Service represents explicitly defined behavior that a Business Role, Business Actor, or Business Collaboration exposes to its environment.",
  "A Business Service is defined as the externally visible ('logical') functionality, which is meaningful to the environment and is realized by business behaviour (Business Process, Business Function, or Business Interaction).",
  "A Business Service exposes the functionality of Business Roles or Collaborations to their environment. This functionality is accessed through one or more business interfaces. A Business Service is realized by one or more Business Processes, Business Functions, or Business Interactions that are performed by the Business Roles or Business Collaborations, respectively.",
  "The name of a Business Service should preferably be a verb ending with '-ing'; e.g., 'transaction processing'. Also, a name containing the word 'service' may be used. ",
]

const BUSINESS_SERVICE_PROMPT =
  "an explicit outcome-oriented capability offered by an organization. It can be delivered to its business environment and be triggered by a business event. A service can be accessed through various business interfaces and often represents a value proposition to business roles or actors. The name should preferably be a noun, such as 'Order Processing Service.'"

const BUSINESS_OBJECT_DESCRIPTION = [
  "A Business Object represents a concept used within a particular business domain.",
  "A Business Object is used to model an object type of which several instances may exist within the organization. In this case, it may be realised as a Data Object or Representation. It may also be specialised by another Business Object.",
  "Business Objects are passive. They do not trigger or perform processes.",
  "Business Objects may be accessed by a Business Process, Function, Interaction, Event, or Service. A Business Object may have Association, Specialization, Aggregation, or Composition relationships with other Business Objects. A Business Object may be realized by a Representation or by a Data Object (or both).",
  "The name of a Business Object should preferably be a noun.",
]

const BUSINESS_OBJECT_PROMPT =
  "A business object is a noun that describes information that can be created, consumed, or exchanged to achieve business outcomes. It represents logical information that can be accessed by various business elements. It is NOT an IT system. Its name MUST be a noun."

const CONTRACT_DESCRIPTION = [
  "A Contract represents a formal or informal specification of an agreement between a provider and a consumer that specifies the rights and obligations associated with a product and establishes functional and non-functional parameters for interaction.",
  "A Contract may also be or include a Service Level Agreement (SLA), describing an agreement about the functionality and quality of the services that are part of a Product.",
  "A Contract is a specialization of a Business Object.",
  "The relationships that apply to a Business Object also apply to a Contract. A Contract may have an Aggregation relationship with a Product.",
  "The name of a Contract should preferably be a noun.",
]

const CONTRACT_PROMPT =
  "an agreement specifying rights, obligations, and parameters for interaction between a provider and consumer, often encompassing a Service Level Agreement (SLA). As a Business Object specialization, it may aggregate with a Product. Its name should preferably be a noun."

const REPRESENTATION_DESCRIPTION = [
  "A Representation represents a perceptible form of the information carried by a Business Object.",
  "Representations (for example, messages or documents) are the perceptible carriers of information that are related to Business Objects.",
  "A single Business Object can have a number of different Representations. A Representation may realize one or more Business Objects. A Meaning can be associated with a Representation that carries this Meaning.",
  "The name of a Representation is preferably a noun.",
]

const REPRESENTATION_PROMPT =
  "the perceivable form of information carried by a Business Object. It can realize one or more Business Objects and may carry an associated Meaning. Multiple Representations can exist for a single Business Object. Its name is preferably a noun."

const PRODUCT_DESCRIPTION = [
  "A Product represents a coherent collection of services and/or passive structure elements, accompanied by a contract/set of agreements, which is offered as a whole to (internal or external) customers.",
  "A Product consists of a collection of Services, and a Contract that specifies the characteristics, rights, and requirements associated with the Product.",
  "A Product may aggregate Business Services or Application Services, as well as a Contract.",
  "Generally, the Product element is used to specify a product type. A Product may be offered both internally to the organisation and externally to customers.",
  "A Value may be associated with a Product.",
  "The name of a Product is usually the name which is used in the communication with customers, or possibly a more generic noun (e.g., 'travel insurance').",
]

const PRODUCT_PROMPT =
  "a comprehensive collection of services and/or passive structure elements, bundled with a contract, and offered as a unit to customers. It may aggregate Business or Application Services and a Contract. A Product, typically denoting a product type, can have an associated Value and is named for customer communication or as a generic noun."

const APPLICATION_COMPONENT_DESCRIPTION = [
  "An Application Component represents an encapsulation of application functionality aligned to implementation structure, which is modular and replaceable.",
  "An Application Component is a self-contained unit. As such, it is independently deployable, re-usable, and replaceable. An Application Component performs one or more Application Functions. It encapsulates its contents: its functionality is only accessible through a set of Application Interfaces. Cooperating Application Components are connected via Application Collaborations.",
  "An Application Component may be assigned to one or more Application Functions. An Application Component has one or more Application Interfaces, which expose its functionality.",
  "The name of an Application Component should preferably be a noun.",
]

const APPLICATION_COMPONENT_PROMPT =
  "Ecapsulates modular, replaceable application functionality. It represents a self-contained, independently deployable unit, performing one or more Application Functions. Its functionality, accessible solely through Application Interfaces, can be assigned to multiple Application Functions. The name of an Application Component MUST be a noun."

const APPLICATION_COLLABORATION_DESCRIPTION = [
  "An Application Collaboration represents an aggregate of two or more application internal active structure elements that work together to perform collective application behavior.",
  "An Application Collaboration specifies which Application Components or other Application Collaborations cooperate to perform some task. The collaborative behavior, including, for example, the communication pattern of these components, is modeled by an Application Interaction. An Application Collaboration typically models a logical or temporary collaboration of Application Components, and does not exist as a separate entity in the enterprise.",
  "Application Collaboration is a specialization of application internal active structure element, and aggregates two or more (cooperating) Application Components or other Application Collaborations.",
  "The name of an Application Collaboration should preferably be a noun.",
]

const APPLICATION_COLLABORATION_PROMPT =
  "Aggregates two or more application internal active structure elements, such as Application Components, to perform collective application behavior. It models logical or temporary collaborations, not typically existing as a separate entity. The collaborative behavior is modeled by an Application Interaction. The name MUST be a noun."

const APPLICATION_FUNCTION_DESCRIPTION = [
  "An Application Function represents automated behavior that can be performed by an Application Component.",
  "An Application Function describes the internal behaviour of an Application Component. If this behaviour is exposed externally, this is done through one or more services.",
  "An Application Function may realize one or more Application Services. Application Services of other Application Functions and Technology Services may serve an Application Function. An Application Function may access Data Objects. An Application Component may be assigned to an Application Function (which means that the Application Component performs the Application Function).",
  "The name of an Application Function should preferably be a verb ending with 'ing'; e.g., 'accounting'.",
]

const APPLICATION_FUNCTION_PROMPT = `
Application Function refers to automated behavior that can be performed by an application. It represents a defined sequence of application behaviors that produces a specific outcome. In simple terms, an application function describes what an application does or can do, from a functionality perspective. It's not tied to any particular technology, data, or interface; rather, it focuses on the core function of the application.
Application Function can relate to other elements such as Application Component, Application Interface, Application Service, and more.
Here are 6 examples of Application Function:
Customer Information Management: This function encompasses all the application behaviors for capturing, updating, processing, and managing customer data. It's an essential function for any customer-focused application, such as a CRM application.
Inventory Management: This is a function typically found in supply chain or warehouse management applications. It handles tracking of goods, items or assets in a company, including their receipt, storage, and eventual delivery.
Payment Processing: Found in financial or e-commerce applications, this function handles the processing of customer payments. It can include credit/debit card processing, direct bank transfers, digital wallet payments, and more.
Data Analysis and Reporting: This function provides the ability to analyze and interpret data to extract insights, create reports, or make informed decisions. It's often a key function in business intelligence tools or data analytics applications.
Order Management: This function handles everything from order entry, through to fulfillment and invoicing, often found in e-commerce platforms or ERP systems. It typically integrates with other functions such as inventory and payment processing.
Security and Authentication: This function ensures that only authorized users have access to certain features or data within the application. It might involve user login processes, user access rights management, two-factor authentication, and other security measures.`

const APPLICATION_INTERACTON_DESCRIPTION = [
  "An Application Interaction represents a unit of collective application behavior performed by (a collaboration of) two or more Application Components.",
  "An Application Interaction describes the collective behaviour that is performed by the components that participate in an Application Collaboration. An Application Interaction can also specify the externally visible behaviour needed to realize an Application Service.",
  "An Application Collaboration may be assigned to an Application Interaction. An Application Interaction may realize an Application Service. Application Services and tTechnology Services may serve an Application Interaction. An Application Interaction may access Data Objects.",
  "The name of an Application Interaction should clearly identify a series of application behaviours.",
]

const APPLICATION_INTERACTION_PROMPT =
  "A collective application behavior performed by two or more Application Components. This behavior, which might be externally visible, is part of an Application Collaboration and can realize an Application Service. It may use Application Services, Technology Services, and access Data Objects. The name should clearly identify a series of application behaviors."

const APPLICATION_PROCESS_DESCRIPTION = [
  "An Application Process represents a sequence of application behaviors that achieves a specific result.",
  "An Application Process describes the internal behaviour performed by an Application Component that is required to realize a set of services.",
  "An Application Process may realize Application Services. Other Application Services may serve (be used by) an Application Process. An Application Process may access Data Objects. An Application Component may be assigned to an Application Process (which means that this component performs the process).",
  "The name of an Application Process should clearly identify a series of application behaviours.",
]

const APPLICATION_PROCESS_PROMPT =
  "A sequence of application behaviors performed by an Application Component to achieve a specific outcome, usually in realizing a set of services. It can utilize other Application Services and access Data Objects. An Application Component, assigned to perform the process, embodies this sequence. The name should clearly identify a series of application behaviors."

const APPLICATION_EVENT_DESCRIPTION = [
  "An Application Event represents an application state change.",
  "Application Functions and other application behaviour may be triggered or interrupted by an Application Event. Also, application behaviour may raise events that trigger other application behaviour. An event is instantaneous; it does not have duration. Events may originate from the environment of the organization (e.g., from an external application), but also internal events may occur generated by, for example, other applications within the organization.",
  "An Application Event may have a time attribute that denotes the moment or moments at which the event happens. For example, this can be used to model time schedules; e.g., an event that triggers a daily batch process.",
  "An Application Event may trigger or be triggered (raised) by an Application Function, Process, or Interaction. An Application Event may access a Data Object and may be composed of other Application Events.",
  "The name of an Application Event should preferably be a verb in the perfect tense.",
]

const APPLICATION_EVENT_PROMPT =
  "A state change within an application. It can trigger or be triggered by Application Functions, Processes, or Interactions, thus influencing application behaviour. Events can originate internally or externally and are instantaneous, though they may have a time attribute to indicate their occurrence. They may also access Data Objects and can be composed of other Application Events. Names of such events should preferably be a verb in the perfect tense."

const APPLICATION_SERVICE_DESCRIPTION = [
  "An Application Service represents an explicitly defined exposed application behaviour.",
  "An Application Service exposes the functionality of components to their environment. This functionality is accessed through one or more Application Interfaces. An Application Service is realized by one or more Application Functions that are performed by the component. It may require, use, and produce Data Objects.",
  "An Application Service should provide a unit of behaviour that is, in itself, useful to its users. It has a purpose, which states this utility to the environment.",
  "A Purpose may be associated with an Application Service. An Application Service may serve Business Processes, Business Functions, Business Interactions, or Application Functions. An Application Function may realize an Application Service. An Application Interface may be assigned to an Application Service. An Application Service may access Data Objects.",
  "The name of an Application Service should preferably be a verb ending with 'ing'. Also, a name explicitly containing the word 'service' may be used.",
]

const APPLICATION_SERVICE_PROMPT =
  "An explicit application behaviour that is exposed for use. This functionality is made accessible via one or more Application Interfaces and is realized by one or more Application Functions. The service can require, use, and produce Data Objects. It should provide valuable behaviour to its users and is connected to a purpose stating its usefulness. An Application Service can serve Business Processes, Business Functions, Business Interactions, or Application Functions, and may access Data Objects. Names for such services should preferably be verbs ending in 'ing' or include the word 'service'."

const DATA_OBJECT_DESCRIPTION = [
  "A Data Object represents data structured for automated processing.",
  "A Data Object should be a self-contained piece of information with a clear meaning to the business, not just to the application level.",
  "A Data Object typically models an object type of which multiple instances may exist in operational applications.",
  "An Application Function or process can operate on Data Objects. A Data Object may be communicated via interactions and used or produced by Application Services. A Data Object can be accessed by an Application Function, Application Interaction, or Application Service. A Data Object may realize a Business Object, and may be realized by an Artifact. A Data Object may have Association, Specialization, Aggregation, or Composition relationships with other Data Objects.",
  "The name of a Data Object should preferably be a noun.",
]

const DATA_OBJECT_PROMPT =
  "structured information for automated processing, typically representing an object type with multiple instances in operational applications. It can be operated on by Application Functions or processes, used or produced by Application Services, and may realize a Business Object. The name of a Data Object should preferably be a noun."

const NODE_DESCRIPTION = [
  "A Node represents a computational or physical resource that hosts, manipulates, or interacts with other computational or physical resources.",
  "Nodes are elements that perform technology behaviour and execute, store, and process technology objects such as Artifacts. For instance, Nodes are used to model application platforms. ",
  "Nodes can be interconnected by Paths. A node may be assigned to an Artifact to model that the Artifact is deployed on the Node.",
  "The name of a Node should preferably be a noun. A node may consist of sub-nodes.",
]

const NODE_PROMPT =
  "a computational or physical resource that hosts or interacts with other resources, often used to model application platforms. Nodes can be composed of sub-nodes. Examples include 'Web Server', 'Database Server', or 'Network Switch'."

const DEVICE_DESCRIPTION = [
  "A Device represents a physical IT resource upon which system software and artifacts may be stored or deployed for execution.",
  "A Device is a specialization of a Node that represents a physical IT resource with processing capability. It is typically used to model hardware systems such as mainframes, PCs, or routers. Usually, they are part of a node together with system software. Devices may be composite; i.e., consist of sub-devices.",
  "Devices can be interconnected by Networks. Devices can be assigned to Artifacts and to System Software, to model that Artifacts and System Software are deployed on that Device. A Node can contain one or more Devices.",
  "The name of a Device should preferably be a noun referring to the type of hardware.",
]

const DEVICE_PROMPT =
  "A specific type of Node that signifies a physical IT resource with processing capabilities, usually used to represent hardware systems such as mainframes, PCs, or routers. It can host system software and artifacts for execution and may consist of sub-devices. Examples include 'Mainframe', 'Desktop PC', or 'Router'."

const SYSTEM_SOFTWARE_DESCRIPTION = [
  "System Software represents software that provides or contributes to an environment for storing, executing, and using software or data deployed within it.",
  "System Software is a specialization of a Node that is used to model the software environment in which Artifacts run. Usually, System Software is combined with a Device representing the hardware environment to form a general Node.",
  "A Device or System Software can be assigned to other System Software; e.g., to model different layers of software running on top of each other. System Software can be assigned to Artifacts, to model that these Artifacts are deployed on that software. System Software can realize other System Software. A Node can be composed of System Software.",
  "The name of System Software should preferably be a noun referring to the type of execution environment.",
]

const SYSTEM_SOFTWARE_PROMPT =
  "A particular type of Node that symbolizes the software environment where Artifacts are run. It often collaborates with a Device, which signifies the hardware environment, to create a comprehensive Node. System Software can also involve different layers of software running on top of each other. Examples could be 'Windows Operating System', 'Linux Kernel', or 'Apache Web Server'."

const TECHNOLOGY_COLLABORATION_DESCRIPTION = [
  "A Technology Collaboration represents an aggregate of two or more technology internal active structure elements that work together to perform collective technology behavior.",
  "A Technology Collaboration specifies which Nodes cooperate to perform some task. The collaborative behaviouris modeled by a Technology Interaction. A Technology Collaboration typically models a logical or temporary collaboration of Nodes, and does not exist as a separate entity in the enterprise.",
  "Technology Collaboration is a specialization of technology internal active structure element, and aggregates two or more (cooperating) Nodes and/or other Technology Collaborations. A Technology Collaboration may be assigned to one or more Technology Interactions or other technology internal behaviour elements, which model the associated behaviour. A technology interface may serve a Technology Collaboration, and a Technology Collaboration may be composed of Technology Interfaces.",
  "The name of a Technology Collaboration should preferably be a noun.",
]

const TECHNOLOGY_COLLABORATION_PROMPT =
  "a collection of two or more technology internal active structure elements, such as Nodes, that cooperate to achieve collective technology behavior. It's often used to model a logical or temporary collaboration of Nodes. This collaboration is typically governed by a Technology Interaction. Examples might include 'Server Cluster', 'Database Mirroring Setup', or 'Load Balanced Web Servers'."

const TECHNOLOGY_INTERFACE_DESCRIPTION = [
  "A Technology Interface represents a point of access where technology services offered by a Node can be accessed.",
  "A Technology Interface specifies how the technology services of a Node can be accessed by other Nodes. A Technology Interface exposes a Technology Service to the environment.",
  "A Technology Interface specifies a kind of contract that a component realizing this interface must fulfil.",
  "A Technology Interface may be part of a Node through composition, which means that these interfaces are provided by that Node, and can serve other Nodes. A Technology Interface can be assigned to a Technology Service, to expose that service to the environment.",
  "The name of a Technology Interface should preferably be a noun.",
]

const TECHNOLOGY_INTERFACE_PROMPT =
  "a point of access that allows Nodes to access technology services offered by another Node. It sets a contract that a component fulfilling this interface must meet. Examples include APIs like 'RESTful API', 'GraphQL Endpoint', or 'gRPC Interface'."

const PATH_DESCRIPTION = [
  "A Path represents a link between two or more Nodes, through which these nodes can exchange data, energy, or material.",
  "A Path is used to model the logical communication (or distribution) relations between Nodes. It is realized by one or more Networks, which represent the physical communication (or distribution) links.",
  "A Path connects two or more Nodes. A Path is realized by one or more Networks. A Path can aggregate Nodes.",
]

const PATH_PROMPT =
  "a logical link facilitating data, energy, or material exchange between two or more Nodes. It is underpinned by one or more Networks, embodying the physical links. Examples might include 'Data Pipeline', 'Power Transmission Route', or 'Supply Chain Route'."

const COMMUNICATION_NETWORK_DESCRIPTION = [
  "A Communication Network represents a set of structures that connects nodes for transmission, routing, and reception of data.",
  "A Communication Network represents the physical communication infrastructure. A Communication Network connects two or more Devices. The most basic Communication Network is a single link between two Devices. A Communication Network realizes one or more Paths. It embodies the physical realization of the logical path between Nodes.",
  "A Communication Network can consist of sub-networks. It can aggregate Devices and System Software, for example, to model the routers, switches, and firewalls that are part of the network infrastructure.",
]

const COMMUNICATION_NETWORK_PROMPT =
  "a physical structure that interconnects nodes for data transmission, routing, and reception, realizing one or more Paths. It represents the physical embodiment of logical connections between Nodes and can be composed of sub-networks. Examples could be 'Local Area Network (LAN)', 'Wide Area Network (WAN)', or 'Wireless Network'."

const TECHNOLOGY_FUNCTION_DESCRIPTION = [
  "A Technology Function represents a collection of technology behaviour that can be performed by a Node.",
  "A Technology Function describes the internal behaviour of a Node; for the user of a Node that performs a Technology Function, this function is invisible. If its behaviour is exposed externally, this is done through one or more Technology Services. A Technology Function abstracts from the way it is implemented.",
  "A Technology Function may realize Technology Services. Technology Services of other Technology Functions may serve Technology Functions. A Technology Function may access Technology Objects. A Node may be assigned to a Technology Function (which means that the Node performs the Technology Function).",
  "The name of a Technology Function should preferably be a verb ending with 'ing'.",
]

const TECHNOLOGY_FUNCTION_PROMPT =
  "a set of behaviors performed by a Node, often invisible to the user. It abstracts from its implementation and may realize or be served by Technology Services. Additionally, a Technology Function may access Technology Objects. Examples could include 'Data Processing', 'Network Routing', or 'Error Logging'."

const TECHNOLOGY_PROCESS_DESCRIPTION = [
  "A Technology Process represents a sequence of technology behaviours that achieves a specific result.",
  "A Technology Process describes internal behaviour of a Node. If its behaviour is exposed externally, this is done through one or more Technology Services. A Technology Process abstracts from the way it is implemented.",
  "A Technology Process may realize Technology Services. Other Technology Services may serve (be used by) a Technology Process. A Node may be assigned to a Technology Process, which means that this Node performs the process.",
  "The name of a Technology Process should clearly identify a series of technology behaviours using a verb or verb-noun combination.",
]

const TECHNOLOGY_PROCESS_PROMPT =
  "a sequence of technology behaviors performed by a Node to accomplish a specific result. It usually abstracts from its implementation and can be served by or realize Technology Services. For instance, 'Data Backup Process', 'Software Update Process', or 'Cybersecurity Monitoring Process'."

const TECHNOLOGY_INTERACTION_DESCRIPTION = [
  "A Technology Interaction represents a unit of collective technology behaviour performed by (a collaboration of) two or more Nodes.",
  "A Technology Interaction describes the collective behaviour that is performed by the Nodes that participate in a Technology Collaboration. A Technology Interaction can also specify the externally visible behaviour needed to realize a Technology Service.",
  "A Technology Collaboration may be assigned to a Technology Interaction. A Technology Interaction may realize a Technology Service. Technology Services may serve a Technology Interaction. A Technology Interaction may access Artifacts.",
  "The name of a technology interaction should clearly identify a series of technology behaviours.",
]

const TECHNOLOGY_INTERACTION_PROMPT =
  "a coordinated technology behavior carried out by a group of two or more Nodes. It articulates the collective behavior required for a Technology Collaboration and may be needed to manifest a Technology Service. Examples could include 'Data Synchronization', 'Network Load Balancing', or 'Multi-device Integration'."

const TECHNOLOGY_EVENT_DESCRIPTION = [
  "A Technology Event represents a technology state change.",
  "Technology Functions and other technology behaviour may be triggered or interrupted by a Technology Event. Also, Technology Functions may raise events that trigger other infrastructure behaviour. An event is instantaneous: it does not have duration. Events may originate from the environment of the organization, but also internal events may occur generated by, for example, other devices within the organization.",
  "A Technology Event may trigger or be triggered (raised) by a Technology Function, Process, or Interaction. A Technology Event may access a Data Object and may be composed of other Technology Events. The name of a Technology Event should preferably be a verb in the perfect tense.",
]

const TECHNOLOGY_EVENT_PROMPT = `A change in the state of technology. It can trigger or be triggered by a Technology Function, Process, or Interaction, and it may influence or be influenced by other aspects of the technology infrastructure. Examples might include "System Reboot", "Network Failure", or "Security Breach Detected".`

const TECHNOLOGY_SERVICE_DESCRIPTION = [
  "A Technology Service represents an explicitly defined exposed technology behaviour.",
  "A Technology Service exposes the functionality of a Node to its environment. This functionality is accessed through one or more Technology Interfaces. It may require, use, and produce Artifacts.",
  "Typical Technology Services may, for example, include messaging, storage, naming, and directory services. It may access Artifacts; e.g., a file containing a message.",
  "A Technology Service may serve Application Components or Nodes. A Technology Service is realized by a Technology Function or Process. A Technology Service is exposed by a Node by assigning Technology Interfaces to it. A Technology Service may access Artifacts.",
  "The name of a Technology Service should preferably be a verb ending with 'ing'.",
]

const TECHNOLOGY_SERVICE_PROMPT = `an exposed behavior of a Node that provides specific functionality, such as messaging or storage services, and is often accessed through Technology Interfaces. This service is usually realized by a Technology Function or Process. Examples might be "Data Storage", "Message Routing", or "Network Security Monitoring".`

const ARTIFACT_DESCRIPTION = [
  "An Artifact represents a piece of data that is used or produced in a software development process, or by deployment and operation of an IT system.",
  "An Artifact represents a tangible element in the IT world. It is typically used to model (software) products such as source files, executables, scripts, database tables, messages, documents, specifications, and model files.",
  "An Application Component or System Software may be realized by one or more Artifacts. A Data Object may be realized by one or more Artifacts. A Node may be assigned to an Artifact to model that the Artifact is deployed on the Node.",
  "The name of an Artifact should preferably be the name of the file it represents.",
]

const ARTIFACT_PROMPT = `a tangible element in IT, often representing products like source files, executables, or database tables. It's used in software development, deployment, or operation of an IT system. A Node might be assigned to an Artifact, indicating its deployment. Examples might be a "CustomerDatabase.sql" (database table), "MainApp.exe" (executable), or "requirements.doc" (document).`

const EQUIPMENT_DESCRIPTION = [
  "Equipment represents one or more physical machines, tools, or instruments that can create, use, store, move, or transform materials.",
  "Equipment comprises all active processing elements that carry out physical processes in which materials are used or transformed. Equipment is a specialization of the Node element. Therefore, it is possible to model nodes that are formed by a combination of IT infrastructure (devices, system software) and physical infrastructure (equipment); e.g., an MRI scanner at a hospital, a production plant with its control systems, etc.",
  "Material can be accessed (e.g., created, used, stored, moved, or transformed) by equipment. Equipment can serve other Equipment, and also Business Roles and Actors, and Facilities can be assigned to Equipment. A piece of Equipment can be composed of other pieces of Equipment. Equipment can be assigned to (i.e., installed and used in or on) a Facility and can be aggregated in a Location.",
  "The name of a piece of Equipment should preferably be a noun.",
]

const EQUIPMENT_PROMPT = `physical machines, tools, or instruments capable of creating, using, storing, moving, or transforming materials. They are specialized Nodes, combining IT infrastructure and physical infrastructure. Examples include an MRI scanner in a hospital, a 3D printer in a design studio, or a drone used for aerial photography. The name should be a noun`

const FACILITY_DESCRIPTION = [
  "A Facility represents a physical structure or environment.",
  "A Facility is a specialization of a Node. It represents a physical resource that has the capability of facilitating (e.g., housing or locating) the use of equipment. It is typically used to model factories, buildings, or outdoor constructions that have an important role in production or distribution processes.",
  "Facilities can be interconnected by Distribution Networks. Material can be accessed (e.g., created, used, stored, moved, or transformed) by Equipment. A Facility can serve other Facilities, and also Business Roles and Actors. Locations can be assigned to Facilities. A Facility can be composed of other Facilities and can be aggregated in a Location.",
  "The name of a Facility should preferably be a noun referring to the type of Facility.",
]

const FACILITY_PROMPT = `a physical structure or environment that can house or locate equipment. This specialization of a Node often pertains to factories, buildings, or outdoor structures integral to production or distribution processes. Examples include a warehouse for storing goods, a data center housing servers, or a power plant generating electricity.`

const DISTRIBUTION_NETWORK_DESCRIPTION = [
  "A Distribution Network represents a physical network used to transport materials or energy.",
  "A Distribution Network represents the physical distribution or transportation infrastructure. It embodies the physical realization of the logical paths between Nodes.",
  "A Distribution Network connects two or more Nodes. A Distribution Network may realize one or more Paths. A Distribution Network can consist of sub-networks and can aggregate Facilities and Equipment.",
]

const DISTRIBUTION_NETWORK_PROMPT = `a physical infrastructure for the transportation of materials or energy. Essentially, it provides the physical realization of logical paths between Nodes. Examples include a power grid distributing electricity, a pipeline network for oil and gas, or a logistics network for goods transportation.`

const MATERIAL_DESCRIPTION = [
  "Material represents tangible physical matter or energy.",
  "Material can have attributes such as size and weight. It is typically used to model raw materials and physical products, and also energy sources such as fuel. Material can be accessed by physical processes.",
  "The name of Material should be a noun. Pieces of Material may be composed of other pieces of Material.",
]

const STAKEHOLDER_DESCRIPTION = [
  "A Stakeholder represents the role of an individual, team, or organization (or classes thereof) that represents their interests in the effects of the architecture.",
  "A Stakeholder has one or more interests in, or concerns about, the organization and its enterprise architecture. In order to direct efforts to these interests and concerns, stakeholders change, set, and emphasize goals.",
  "The name of a Stakeholder should preferably be a noun.",
]

const STAKEHOLDER_PROMPT = `embodies the role of an individual, team, or organization having interests or concerns tied to the architecture's effects. Stakeholders set and modify goals focusing on these interests and concerns. Examples might include "Investors" concerned with business profitability, "Users" interested in software usability, or "Regulatory Agencies" focused on compliance with laws and regulations.`

const DRIVER_DESCRIPTION = [
  "A Driver represents an external or internal condition that motivates an organization to define its goals and implement the changes necessary to achieve them.",
  "Drivers may be internal, in which case they are usually associated with a Stakeholder. They may also be external, e.g., economic changes or changing legislation.",
  "The name of a Driver should preferably be a noun, and its description should be a declarative statement that identifies a particular business concern, goal, or environmental condition.",
]

const ASSESSMENT_DESCRIPTION = [
  "An Assessment represents the result of an analysis of the state of affairs of the enterprise with respect to some driver.",
  "An Assessment may reveal strengths, weaknesses, opportunities, or threats for some area of interest. These outcomes need to be addressed by adjusting existing goals or setting new ones, which may trigger changes to the enterprise architecture.",
  "The name of an Assessment should preferably be a noun or a very short sentence.",
]

const ASSESSMENT_PROMPT = `the outcome of an analysis concerning a condition in relation to a specific driver. It might expose strengths, weaknesses, opportunities, or threats in a particular area, necessitating the adjustment of existing goals or the creation of new ones. This could prompt modifications to the enterprise architecture. Examples of assessments might be "Increased Market Competition", "Adherence to New Regulations", or "Potential for Expansion".`

const GOAL_DESCRIPTION = [
  "A Goal represents a high-level statement of intent, direction, or desired end state for an organization and its stakeholders.",
  "An end can represent anything a stakeholder may desire, such as a state of affairs, or a produced value. Goals are typically used to measure success of an organization.",
  "Goals are generally expressed using qualitative words.",
]

const OUTCOME_DESCRIPTION = [
  "An Outcome represents an end result.",
  "Outcomes are high-level, business-oriented results produced by capabilities of an organization",
  "Outcomes are closely related to requirements, goals, and other intentions. Outcomes are the end results, and goals or requirements are often formulated in terms of outcomes that should be realized. Capabilities are designed to achieve such outcomes.",
  "Outcome names should identify end results that have been achieved in order to avoid confusion with actions or goals. At a minimum, outcome names should consist of a noun identifying the end result followed by a past-tense verb or adjective indicating that the result has been achieved.",
]

const OUTCOME_PROMPT = `Signifies an end result that has been achieved (past tense), generally linked to the organization's capabilities. It closely aligns with requirements, goals, and intentions. Outcomes should be distinguished from actions or goals. Examples could be "Customer Satisfaction Improved", "Market Share Increased", or "Operational Costs Reduced"`

const PRINCIPLE_DESCRIPTION = [
  "A Principle represents a statement of intent defining a general property that applies to any system in a certain context in the architecture.",
  "Principles define intended properties of systems. A Principle defines a general property that applies to any system in a certain context. A Principle is motivated by some goal or driver. Organizational values, best practices, and design knowledge may be reflected and made applicable in terms of principles.",
  "Principles are strongly related to goals and requirements. Similar to requirements, principles define intended properties of systems.",
]

//const PRINCIPLE_PROMPT = "a fundamental guideline, shaping architectural decisions and aligning technology strategies with organizational goals. It defines inherent properties of systems in a specific context, reflecting organizational values and industry best practices. "
const PRINCIPLE_PROMPT = `An assertion providing specific guidance around organizational values, best practices, and design knowledge. It sets general properties that apply to any system within a specific context. Principles are usually motivated by certain goals or drivers and are strongly related to goals and requirements. Examples could be "Data Security First", "User-Centered Design", or "Sustainability by Default".`

const REQUIREMENT_DESCRIPTION = [
  "A Requirement represents a statement of need defining a property that applies to a specific system as described by the architecture.",
  "Requirements model the properties of these elements that are needed to achieve the 'ends' that are modelled by the goals. In this respect, requirements represent the 'means' to realize goals.",
]

const REQUIREMENT_PROMPT = `a necessary attribute or capability for a specific system within the scope of the architecture. It defines properties that must be fulfilled by a system, acting as the 'means' to achieve the 'ends' set out by organizational goals. Examples of requirements could be "The system must ensure data security", "The user interface must be intuitive and user-friendly", or "The system must be sustainable and energy-efficient".`

const CONSTRAINT_DESCRIPTION = [
  "A Constraint represents a factor that limits the realization of goals.",
  "In contrast to a requirement, a constraint does not prescribe some intended functionality of the system to be realized but imposes a restriction on the way it operates or may be realized. This may be a restriction on the implementation of the system (e.g., specific technology that is to be used), or a restriction on the implementation process (e.g., time or budget constraints).",
]

const MEANING_DESCRIPTION = [
  "Meaning represents the knowledge or expertise present in, or the interpretation given to, a concept in a particular context.",
  "A Meaning represents the interpretation of an element of the architecture. It is a description that expresses the intent of that element; i.e., how it informs the external user.",
  "A Meaning can be associated with any core element. To denote that a Meaning is specific to a particular Stakeholder, this Stakeholder can also be associated to the Meaning.",
  "The name of a Meaning should preferably be a noun or noun phrase.",
]
const MEANING_PROMPT =
  "The interpretation of a concept within a context. It conveys an element's purpose, can be associated with any core element, and is named ideally using a noun or noun phrase."

const VALUE_DESCRIPTION = [
  "Value represents the relative worth, utility, or importance of a concept.",
  "Value may apply to what a party gets by selling or making available some product or service, or it may apply to what a party gets by buying or obtaining access to it.",
  "Value may be expressed in terms of money, but non-monetary value is also essential to business - for example, practical/functional value (including the right to use a service), and the value of information or knowledge. Though Value can hold internally for some system or organisational unit, it is most typically applied to external appreciation of goods, services, information, knowledge, or money, normally as part of some sort of customer-provider relationship.",
  "A Value can be associated with any concept. To model the stakeholder for whom this Value applies, this stakeholder can also be associated with that Value.",
  "It is recommended to try and express the name of a Value as an action or state that can be performed or reached as a result of the corresponding service being available.",
]

const VALUE_PROMPT = `the relative worth or importance of a concept. This could relate to benefits gained by selling or providing a service, or what's obtained by purchasing or accessing it. Value can be monetary or non-monetary, like the practical value of a service or the value of information. While Value can apply internally, it's often linked to external appreciation of goods or services. Value can be associated with any concept, and stakeholders can be linked to specific values. The name of a Value should ideally express an achievable action or state due to the availability of a service.`

const WORK_PACKAGE_DESCRIPTION = [
  "A Work Package represents a series of actions identified and designed to achieve specific results within specified time and resource constraints.",
  "The central behavioural element is a Work Package. A Work Package is a behaviour element that has a clearly defined start and end date, and realizes a well-defined set of Goals or Deliverables. The Work Package element can be used to model sub-projects or tasks within a project, complete projects, programs, or project portfolios.",
]

const WORK_PACKAGE_PROMPT = `a set of actions designed to accomplish specific results within defined time and resource limits. It's a behavior element with clear start and end dates, realizing a specific set of goals or deliverables. This element can be used to model tasks, projects, programs, or project portfolios.`

const DELIVERABLE_DESCRIPTION = [
  "A Deliverable represents a precisely-defined outcome of a Work Package.",
  "Work Packages produce Deliverables. These may be results of any kind; e.g., reports, papers, services, software, physical products, etc., or intangible results such as organizational change. A Deliverable may also be the implementation of (a part of) an architecture.",
]

const DELIVERABLE_PROMPT = `A clearly defined output of a Work Package. It can be tangible, such as reports, software, physical products, or intangible, like organizational change. A Deliverable might also constitute the implementation of a part or whole of an architecture.`

const IMPLEMENTATION_EVENT_DESCRIPTION = [
  "An Implementation Event is a behaviour element that denotes a state change related to implementation or migration.",
  "Work Packages may be triggered or interrupted by an Implementation Event. Also, work packages may raise events that trigger other behaviour. Unlike a Work Package, an event is instantaneous: it does not have duration.",
  "An Implementation Event may have a time attribute that denotes the moment or moments at which the event happens.",
  "Implementation Events access Deliverables to fulfil project objectives.",
  "An Implementation Event may trigger or be triggered (raised) by a Work Package or a Plateau. An Implementation Event may access a Deliverable and may be composed of other Implementation Events.",
  "An Implementation Event may be associated with any core element; e.g., to indicate a lifecycle state change.",
  "The name of an Implementation Event should preferably be a verb in the perfect tense.",
]

const IMPLEMENTATION_EVENT_PROMPT = `A state change relating to implementation or migration in the architecture. Unlike a Work Package, it's instantaneous and does not have a duration. These events can trigger or be triggered by a Work Package or a Plateau, and can access a Deliverable. They might also be composed of other Implementation Events and can indicate a lifecycle state change. The name of an Implementation Event should preferably be a verb in the perfect tense.`

const PLATEAU_DESCRIPTION = [
  "A Plateau represents a relatively stable state of the architecture that exists during a limited period of time",
]

const GAP_DESCRIPTION = [
  "A Gap represents a statement of difference between two Plateaus",
  "The Gap element is associated with two Plateaus (e.g., Baseline and Target Architectures, or two subsequent Transition Architectures), and represents the differences between these Plateaus.",
]

const GAP_PROMPT = `signifies the difference between two Plateaus, often representing different states of an architecture. These could be the Baseline and Target Architectures, or sequential Transition Architectures. Essentially, a Gap serves to highlight and define the differences between these two architectural states.`

const JUNCTION_DESCRIPTION = [
  "A Junction is used to connect relationships of the same type. A Junction may have multiple incoming relationships and one outgoing relationship, one incoming relationship and multiple outgoing relationships, or multiple incoming and outgoing relationships.",
  "The relationships that can be used in combination with a Junction are all the dynamic relationships, as well as Assignment, Realization, and Association. A Junction is used to explicitly express that several elements together participate in the relationship (and Junction) or that one of the elements participates in the relationship (or Junction).",
  "Ensure that only relationships of the same type are used to connect elements and Junctions.",
  "There are two types - And and Or.",
]

const GROUPING_DESCRIPTION = [
  "The Grouping element aggregates or composes concepts that belong together based on some common characteristic.",
  "The Grouping element is used to aggregate or compose an arbitrary group of concepts, which can be elements and/or relationships of the same or of different types. An Aggregation or Composition relationship is used to link the Grouping element to the grouped concepts.",
]

const NATURE_PASSIVE_STRUCTURE = {
  type: "passive structure",
}

const NATURE_ACTIVE_STRUCTURE = {
  type: "active structure",
}

const NATURE_BEHAVIOURAL = {
  type: "behavioural",
}

const NATURE_COMPOSITE = {
  type: "composite",
}

const NATURE_MOTIVATION = {
  type: "motivation",
}

const NATURE_IMPLEMENTATION_AND_MIGRATION = {
  type: "implementation and migration",
}

// Do not modify the 'index' values in this, as they are stored into Firestore
// as part of the 'model_index' collection
const ELEMENT_INDEX = [
  {
    name: LOCATION,
    index: 1,
    layer: LAYER_OTHER,
    description: LOCATION_DESCRIPTION,
    prompt: LOCATION_PROMPT,
    nature: NATURE_PASSIVE_STRUCTURE,
    label: `"Location" denotes a physical or virtual space housing an enterprise object. It can be a geographic site like an office or a virtual cloud area. Ensure its name clearly and unmistakably identifies its nature and position. Examples: North Campus Data Center, Europe Sales Office, Asia-Pacific Region, Cloud Region US-East, Virtual Workspace 002.`,
  },
  {
    name: RESOURCE,
    index: 2,
    layer: LAYER_STRATEGY,
    description: RESOURCE_DESCRIPTION,
    prompt: RESOURCE_PROMPT,
    nature: NATURE_PASSIVE_STRUCTURE,
    label: `A "Resource" is an asset owned or controlled by an individual or organization. It can encompass a wide range of items including financial assets, intellectual property, infrastructure, or personnel. Resources are fundamental for the execution of processes, functions, interactions, and more. Use succinct, descriptive terms that clearly indicate the type, nature, or primary purpose of the resource. The name should directly communicate the main asset or value the resource represents or provides. e.g. IT Infrastructure, Research & Development Fund, Employee Training Module, Database Server Cluster, Licensing Agreement, Technical Support Team. The name should encapsulate the main asset, value, or function that the resource represents or supports.`,
  },
  {
    name: CAPABILITY,
    index: 3,
    layer: LAYER_STRATEGY,
    description: CAPABILITY_DESCRIPTION,
    prompt: CAPABILITY_PROMPT,
    nature: NATURE_BEHAVIOURAL,
    label: `Represents an abstract ability that an organization, operating model, or functional area must possess to achieve specific outcomes. It typically describes 'what' the distinct function or skill set is to deliver value, implement strategy, or achieve goals. When naming a "Capability", the intent is to encapsulate the essence of the competence or ability that it represents. Use descriptors that highlight the primary skill, function, or ability that the capability imparts. The name should concisely encapsulate the main competence or functional ability that the capability represents. e.g. Strategic Planning, Sales and Marketing, Supply Chain Management, Financial Management, Human Resources Management, Customer Relationship Management, Product Development and Innovation, Operations Management, IT and Technology Management, Regulatory Compliance and Risk Management. Ensure clarity and simplicity so stakeholders can quickly grasp the nature and scope of the capability. Do not use the words 'Capability', 'System', 'Services', or 'Management' in the name. The name should directly convey the core ability or competency that the "Capability" embodies within the enterprise. It should specify the 'what', and not the 'how'. It should not contain any adjectives.`,
  },
  {
    name: VALUE_STREAM,
    index: 4,
    layer: LAYER_STRATEGY,
    description: VALUE_STREAM_DESCRIPTION,
    prompt: VALUE_STREAM_PROMPT,
    nature: NATURE_BEHAVIOURAL,
    label: `Conveys a sequence of activities that collectively create an overall result for a customer, stakeholder, or end user. Value streams are designed to illustrate how value flows through the different parts of an organization, from the initial trigger to the realization of value. Employ clear, descriptive terms that concisely depict the primary flow of value or the end result it delivers. The name should transparently represent the chief benefit or outcome derived from the sequence of activities. e.g. Order-to-Delivery, Inquiry-to-Resolution, Concept-to-Market, Lead-to-Customer Conversion, Issue-to-Solution, Request-to-Deployment. The name should be in noun verb form, and encapsulate the primary flow, outcome, or benefit that the value stream represents.`,
  },
  {
    name: COURSE_OF_ACTION,
    index: 5,
    layer: LAYER_STRATEGY,
    description: COURSE_OF_ACTION_DESCRIPTION,
    prompt: COURSE_OF_ACTION_PROMPT,
    nature: NATURE_BEHAVIOURAL,
    label: `Represents an approach or plan chosen to handle a situation or realize an objective. It reflects strategic decisions and plans made by the organization to achieve specific outcomes. When naming a Course of Action, the focus should be on clearly expressing its strategic intention or the primary objective it aims to achieve. Use clear, descriptive phrases that effectively capture the strategic intent or primary objective of the course of action. The name should unambiguously convey the key outcome or strategic direction it signifies. e.g. Digital Transformation Initiative, Sustainability Focus, Customer-Centric Approach, Adopt Agile Methodology, Implement Zero-Trust Security, Pursue Market Diversification. The name should encapsulate the primary strategy, direction, or outcome that the course of action embodies.`,
  },
  {
    name: BUSINESS_ACTOR,
    index: 6,
    layer: LAYER_BUSINESS,
    description: BUSINESS_ACTOR_DESCRIPTION,
    prompt: BUSINESS_ACTOR_PROMPT,
    nature: NATURE_ACTIVE_STRUCTURE,
    label: `an organizational entity that is capable of performing behavior. It could be a human role (like "Manager" or "Customer") or an organizational unit. Use clear and descriptive terms that effectively portray the role, function, or identity of the actor. The name should typically reflect the main responsibility, role, or organizational position that the business actor holds. e.g. Manager, Sales Representative, Customers, Billing Department, Executive Board, Stakeholders.`,
  },
  {
    name: BUSINESS_ROLE,
    index: 7,
    layer: LAYER_BUSINESS,
    description: BUSINESS_ROLE_DESCRIPTION,
    prompt: BUSINESS_ROLE_PROMPT,
    nature: NATURE_ACTIVE_STRUCTURE,
    label: `Represents an organizational entity capable of performing behavior, such as a person, organization, or system. It could be an individual, a team, a department, or even an external partner or stakeholder.Utilize clear, descriptive terms that effectively portray the entity's role, function, or relation within or to the organization. The name should distinctly represent the primary function, role, or position of the actor within the enterprise. e.g. Sales Representative, Finance Team, Supply Chain Partner, CFO, External Auditor, Marketing Department. The name should encapsulate the main function, role, or position that the business actor represents within the organizational structure or process.`,
  },
  {
    name: BUSINESS_COLLABORATION,
    index: 8,
    layer: LAYER_BUSINESS,
    description: BUSINESS_COLLABORATION_DESCRIPTION,
    prompt: BUSINESS_COLLABORATION_PROMPT,
    nature: NATURE_ACTIVE_STRUCTURE,
    label: `A collection of two or more business roles that work together to perform collective behavior. It represents a temporary configuration of various business roles (or even collaborations) to achieve some shared goals or outcomes. Examples might include a cross-functional team, a partnership between organizations, or a temporary task force. When naming a Business Collaboration, the focus should be on reflecting its purpose, its participants, or the joint goal it seeks to achieve. Use clear, descriptive terms that encapsulate the collective purpose, the main participants, or the shared goal of the collaboration. The name should directly depict the primary cooperative activity or joint objective the collaboration seeks to fulfill. e.g. Product Development Collaboration, Strategic Partnership Collaboration, Supply Chain Integration Team, Inter-Departmental Task Force. The name should encapsulate the key joint activity, objective, or participants that the business collaboration represents.`,
  },
  {
    name: BUSINESS_INTERFACE,
    index: 9,
    layer: LAYER_BUSINESS,
    description: BUSINESS_INTERFACE_DESCRIPTION,
    prompt: BUSINESS_INTERFACE_PROMPT,
    nature: NATURE_ACTIVE_STRUCTURE,
    label: `A point of access where a business service is made available to the environment. It can represent, for instance, a service desk offering IT support to employees or a customer-facing interface like a helpdesk. Employ clear, descriptive terms that effectively convey the primary function or the main service offered through the interface. The name should be a clear indicator of the specific point of interaction or the type of service accessibility it provides. e.g. Customer Support Interface, Order Processing Interface, Billing Portal, Employee Self-Service Desk.`,
  },
  {
    name: BUSINESS_PROCESS,
    index: 10,
    layer: LAYER_BUSINESS,
    description: BUSINESS_PROCESS_DESCRIPTION,
    prompt: BUSINESS_PROCESS_PROMPT,
    nature: NATURE_BEHAVIOURAL,
    label: `A sequence of business behaviors that achieves a specific outcome such as a set product, a meaningful business event, or a requested value. It outlines the organized steps or activities needed to achieve a particular business goal. Use clear, descriptive terms that succinctly capture the primary action or outcome of the process. The name should accurately represent the chief task, function, or result it embodies. e.g. Order Fulfillment Process, Employee Onboarding Process, Invoice Generation, Customer Feedback Collection. The name should encapsulate the main activity, function, or outcome that the business process represents.`,
  },
  {
    name: BUSINESS_FUNCTION,
    index: 11,
    layer: LAYER_BUSINESS,
    description: BUSINESS_FUNCTION_DESCRIPTION,
    prompt: BUSINESS_FUNCTION_PROMPT,
    nature: NATURE_BEHAVIOURAL,
    label: `A defined organizational capability representing a specific business role or responsibility. It encapsulates the primary activities or tasks performed by a particular segment of the organization but, unlike processes, does not have a specific sequence or flow. Functions might include areas like "Sales," "Human Resources," or "Supply Chain Management. When naming a Business Function, the focus should be on clearly describing its primary role, responsibility, or organizational capability.  Use clear, descriptive terms that effectively portray the key responsibility, capability, or role of the function. The name should directly represent the primary organizational capability or responsibility it embodies. e.g. Product Development, Customer Relations, Inventory Management, Strategic Planning, Talent Acquisition, Brand Management. The name should encapsulate the main responsibility, capability, or role that the business function represents within the enterprise.`,
  },
  {
    name: BUSINESS_INTERACTION,
    index: 12,
    layer: LAYER_BUSINESS,
    description: BUSINESS_INTERACTION_DESCRIPTION,
    prompt: BUSINESS_INTERACTION_PROMPT,
    nature: NATURE_BEHAVIOURAL,
    label: `Describes a unit of collective business behavior performed by (a collaboration of) two or more business roles. It's more specific than a business process and focuses on interactions between roles. For instance, a negotiation between a buyer and seller could be a business interaction. When naming a Business Interaction, it's vital to capture the essence of the collective behavior, the participants, or the outcome of the interaction. Use terms that convey the collective activity, the roles involved, or the outcome of the interaction. The name should encapsulate the primary cooperative or collaborative behavior it signifies. e.g. Negotiation Interaction, Collaborative Design Interaction, Supplier-Customer Dialogue, Team Brainstorming Session. Reflect the primary collaborative behavior, participants, or outcome in the interaction's name.`,
  },
  {
    name: BUSINESS_EVENT,
    index: 13,
    layer: LAYER_BUSINESS,
    description: BUSINESS_EVENT_DESCRIPTION,
    prompt: BUSINESS_EVENT_PROMPT,
    nature: NATURE_BEHAVIOURAL,
    label: `An organizational state change that has significance for organizational units or stakeholders. It could be external or internal. Examples might include a customer order, a regulatory change, or a system alert. When naming a Business Event, the focus should be on concisely conveying the nature or cause of the event and its impact or relevance to the organization. Use clear, descriptive terms that highlight the specific occurrence or change leading to the event and, if possible, its organizational significance. The name should directly represent the specific trigger, condition, or change it signifies. e.g., Order Submission Event, Regulatory Update Event, System Outage Alert, Contract Renewal Reminder. Aim for clarity so that stakeholders and team members can quickly identify the nature and significance of the event. The name should encapsulate the primary trigger, condition, or change that the business event represents, as well as its significance or impact if feasible.`,
  },
  {
    name: BUSINESS_SERVICE,
    index: 14,
    layer: LAYER_BUSINESS,
    description: BUSINESS_SERVICE_DESCRIPTION,
    prompt: BUSINESS_SERVICE_PROMPT,
    nature: NATURE_BEHAVIOURAL,
    label: `Use verbs or verb-noun combinations that describe the action provided or the benefit offered. The name should capture the core value proposition or operation of the service. e.g. Process Invoice, Manage Customer Account, Provide IT Support, 24/7 Customer Assistance, Real-time Order Tracking.`,
  },
  {
    name: BUSINESS_OBJECT,
    index: 15,
    layer: LAYER_BUSINESS,
    description: BUSINESS_OBJECT_DESCRIPTION,
    prompt: BUSINESS_OBJECT_PROMPT,
    nature: NATURE_PASSIVE_STRUCTURE,
    label: `Use singular nouns or noun phrases that depict the core concept or entity. The name (noun) should immediately convey the nature of the data or information being represented. e.g. Product, Employee, Invoice, Active Customer Profile, Pending Order, Customer Service Ticket. Never use names that would represent a specific record entry. Never use names that refer to IT systems, functions, processes, or anything which is a verb, that is Business Objects MUST be a noun.`,
  },
  {
    name: CONTRACT,
    index: 16,
    layer: LAYER_BUSINESS,
    description: CONTRACT_DESCRIPTION,
    prompt: CONTRACT_PROMPT,
    nature: NATURE_PASSIVE_STRUCTURE,
    label: `Use nouns or noun phrases that depict the core agreement or obligation. The name should convey the primary intent or scope of the contract. e.g. Service Level Agreement, Non-Disclosure Agreement, Vendor-Client Supply Contract, Employee Confidentiality Pact.`,
  },
  {
    name: REPRESENTATION,
    index: 17,
    layer: LAYER_BUSINESS,
    description: REPRESENTATION_DESCRIPTION,
    prompt: REPRESENTATION_PROMPT,
    nature: NATURE_PASSIVE_STRUCTURE,
    label: `Use nouns or noun phrases that indicate the form or content of the representation. The name should capture either the primary medium/format, the content type, or the purpose of the representation. e.g. PDF Invoice, Network Diagram, Project Status Dashboard, Detailed Budget Spreadsheet, Quarterly Sales Report.`,
  },
  {
    name: PRODUCT,
    index: 18,
    layer: LAYER_BUSINESS,
    description: PRODUCT_DESCRIPTION,
    prompt: PRODUCT_PROMPT,
    nature: NATURE_COMPOSITE,
    label: `Represents a coherent collection of services and, if applicable, physical products, offered as a whole to (internal or external) customers. It defines a specific offering or package for which a customer will recognize and pay. Examples might be a software suite, a mobile plan, or a business service package. When naming a Product, the focus should be on encapsulating its primary value proposition, target audience, or core features. Utilize terms that convey the primary value proposition, the target demographic, or the chief features of the product. The name should immediately hint at what the product offers or its chief benefits. e.g. Enterprise Security Suite Product, Unlimited Mobile Plan Product, Small Business Service Bundle, Advanced Analytics Toolkit. Embody the primary value, features, or target audience in the product's name.`,
  },
  {
    name: APPLICATION_COMPONENT,
    index: 19,
    layer: LAYER_APPLICATION,
    description: APPLICATION_COMPONENT_DESCRIPTION,
    prompt: APPLICATION_COMPONENT_PROMPT,
    nature: NATURE_ACTIVE_STRUCTURE,
    label: `Represents a modular, deployable, and replaceable part of a software system that encapsulates its behavior and data and exposes these through a set of interfaces. Essentially, it's a high-level architectural piece of the software system, whether that's an entire application or a module within a larger application. When naming an Application Component, the aim should be to capture its primary function, feature set, or system role. Use terms that describe the primary function, the feature set, or the system role of the component. The name should offer a quick insight into what the component does or its significance in the system architecture. e.g. User Authentication Component, Data Processing Component, Billing Module, Inventory Management System. Aim for clarity to ensure that developers, architects, and other stakeholders can instantly grasp the component's role or purpose. Reflect the primary function, role, or feature set in the component's name.`,
  },
  {
    name: APPLICATION_COLLABORATION,
    index: 20,
    layer: LAYER_APPLICATION,
    description: APPLICATION_COLLABORATION_DESCRIPTION,
    prompt: APPLICATION_COLLABORATION_PROMPT,
    nature: NATURE_ACTIVE_STRUCTURE,
    label: `Represents an aggregation of two or more application components that work together to perform collective application behavior. It emphasizes the cooperative aspect of these components and how they interact to achieve a specific functionality or service. When naming an Application Collaboration, the focus should be on capturing the joint behavior or the outcome of the collaboration. Opt for terms that describe the joint behavior, the combined functions, or the outcome of the collaboration. The name should provide insight into the cooperative nature of the involved components and the functionality they collectively deliver. e.g., Order Processing Collaboration, Data Synchronization Collaboration, Payment & Billing Integration, Inventory & Shipping Coordination. Capture the collective behavior, functions, or outcomes in the collaboration's name.`,
  },
  {
    name: APPLICATION_INTERFACE,
    index: 21,
    layer: LAYER_APPLICATION,
    description: APPLICATION_INTERFACE_DESCRIPTION,
    prompt: APPLICATION_INTERFACE_PROMPT,
    nature: NATURE_ACTIVE_STRUCTURE,
    label: `Specifies a point where an application service can be accessed by other application components, collaborations, or even external systems. It is crucial for understanding the ways in which application services are exposed and can be consumed. When naming an Application Interface, the focus should be on capturing the service it exposes, its intended users or systems, or the kind of interaction it supports. Utilize terms that describe the exposed service, its intended users or systems, or the type of interaction it facilitates. The name should offer a clear understanding of the service's accessibility or the interface's interaction type. e.g. Payment Processing, Data Export API, Customer Account Portal, Third-Party Integration API. Reflect the primary service, interaction type, or intended users in the interface's name.`,
  },
  {
    name: APPLICATION_FUNCTION,
    index: 22,
    layer: LAYER_APPLICATION,
    description: APPLICATION_FUNCTION_DESCRIPTION,
    prompt: APPLICATION_FUNCTION_PROMPT,
    nature: NATURE_BEHAVIOURAL,
    label: `Represents automated behavior that can be performed by an application component. This behavior is internal to the component and can be described as a series of actions or steps to accomplish a specific purpose within the system. Essentially, it's a functional ability that an application component provides. When naming an Application Function, the aim should be to describe its primary purpose or the automated behavior it encapsulates. Adopt terms that capture the primary purpose, operation, or automated behavior of the function. The name should instantly convey the internal behavior or operation it represents. e.g. Data Validation, Transaction Logging, User AuthenticationFile Upload Handling. The name should encapsulate the primary operation, task, or behavior that the application function embodies.`,
  },
  {
    name: APPLICATION_INTERACTION,
    index: 23,
    layer: LAYER_APPLICATION,
    description: APPLICATION_INTERACTON_DESCRIPTION,
    prompt: APPLICATION_INTERACTION_PROMPT,
    nature: NATURE_BEHAVIOURAL,
    label: `Represents a unit of collective application behavior, realized by one or more application components, which together produce a specific outcome. It is more about how different application functions or components work together to produce a result. When naming an Application Interaction, the emphasis should be on capturing the collective behavior, the components involved, or the outcome of the interaction. Use terms that convey the combined behavior, the participating components, or the outcome of the interaction. The name should provide insight into the interaction's nature and the resultant outcome or functionality. e.g., Order Processing, Data Synchronization, Authentication & Authorization Coordination, Upload & Processing. Capture the joint behavior, functions, or outcomes in the interaction's name.`,
  },
  {
    name: APPLICATION_PROCESS,
    index: 24,
    layer: LAYER_APPLICATION,
    description: APPLICATION_PROCESS_DESCRIPTION,
    prompt: APPLICATION_PROCESS_PROMPT,
    nature: NATURE_BEHAVIOURAL,
    label: `Represents a sequence of application behaviors that produce a specific outcome, adhering to certain rules or conditions. It provides an orchestrated flow of activities related to the application layer and focuses on how software components handle specific tasks. When naming an Application Process, the objective should be to convey its sequence, outcome, or the specific rules it follows. Use terms that reflect the sequence, desired outcome, or the specific guidelines the process follows. The name should offer insight into the process's flow and end result. e.g. Order-to-Cash, User Registration, Invoice Generation, Data Backup. Reflect the primary sequence, activities, or outcomes in the process's name.`,
  },
  {
    name: APPLICATION_EVENT,
    index: 25,
    layer: LAYER_APPLICATION,
    description: APPLICATION_EVENT_DESCRIPTION,
    prompt: APPLICATION_EVENT_PROMPT,
    nature: NATURE_BEHAVIOURAL,
    label: `Signifies an occurrence or change of state within the application layer that triggers or impacts behavior. Events can be generated by the environment or other applications and usually cause some form of reaction or process to take place within the application domain. When naming an Application Event, it's crucial to encapsulate the essence or the cause of the event. The name should provide a clear hint about what happened or what state change took place. Use terms that pinpoint the specific occurrence or state change within the application domain. The name should provide immediate clarity about the nature or cause of the event. e.g. Login Failure, Data Upload Completion, On Password Reset, On Order Confirmation. The name should encapsulate the primary occurrence, state change, or trigger that the event represents.`,
  },
  {
    name: APPLICATION_SERVICE,
    index: 26,
    layer: LAYER_APPLICATION,
    description: APPLICATION_SERVICE_DESCRIPTION,
    prompt: APPLICATION_SERVICE_PROMPT,
    nature: NATURE_BEHAVIOURAL,
    label: `Represents a well-defined exposed application behavior or functionality that provides value to users, other application components, or external systems. It describes the way an application component offers its functionality to its environment. When naming an Application Service, it's crucial to represent the specific functionality or value being provided. The name should directly relate to what the service does or the benefit it offers. Use terms that delineate the specific functionality or value the service provides. The name should provide a direct insight into the service's purpose or the benefit it delivers. e.g. Authentication, Invoice Generation, Data Backup, Order Processing, Data Retrieval, Payment Processing. The name should precisely encapsulate the main function, benefit, or value that the application service provides.`,
  },
  {
    name: DATA_OBJECT,
    index: 27,
    layer: LAYER_APPLICATION,
    description: DATA_OBJECT_DESCRIPTION,
    prompt: DATA_OBJECT_PROMPT,
    nature: NATURE_PASSIVE_STRUCTURE,
    label: `Represents data structures within the application layer. It's a passive entity that holds data that can be accessed or manipulated by application services, functions, or interactions. These data objects can be seen as a representation of databases, tables, files, and other data constructs. When naming a Data Object, the emphasis should be on representing the nature, type, or the primary data content of the object. The name should provide a direct insight into the kind of data the object holds or its purpose. Use terms that depict the primary data content, type, or purpose of the object. The name should directly reflect the type of data held or the primary intent of the data object. e.g. Customer Record, Invoice Details, Transaction Log, User Authentication Data, Product Catalog Data. The name should encapsulate the primary data type, content, or purpose that the data object represents within the application.`,
  },
  {
    name: NODE,
    index: 28,
    layer: LAYER_TECHNOLOGY,
    description: NODE_DESCRIPTION,
    prompt: NODE_PROMPT,
    nature: NATURE_ACTIVE_STRUCTURE,
    label: `Represents a computational or physical device that can execute one or more software artifacts or can host artifacts that represent a computational resource. It's an active entity within the technology layer. Nodes can represent servers, devices, computational environments, and other technology-based entities. When naming a Node, it's pivotal to describe its role, function, or the primary technology it embodies. The name should offer clarity on the Node's main purpose or the technology role it plays within the environment. Use terms that portray the main technology, role, or function of the node. The name should be indicative of the technology realm, function, or role the node plays within the environment. e.g. Web Server, Database Cluster, Load Balancer, Data Processing EnvironmentEdge Computing Device. The name should encapsulate the main technology, function, or role that the node represents within the technological landscape.`,
  },
  {
    name: DEVICE,
    index: 29,
    layer: LAYER_TECHNOLOGY,
    description: DEVICE_DESCRIPTION,
    prompt: DEVICE_PROMPT,
    nature: NATURE_ACTIVE_STRUCTURE,
    label: `A specialization of a node and represents physical computational hardware that executes specific software. Devices can embody elements like computers, servers, routers, switches, firewalls, and other pieces of tangible hardware.When naming a Device, it's crucial to capture its primary function, type, or the specific technology it encapsulates. The name should convey a clear understanding of the Device's main role or the technological function it serves. Use terms that showcase the main technology, function, or type of the device. The name should be indicative of the hardware's primary function, type, or the technological realm it belongs to. e.g. Web Server, Database Cluster, Router, Firewall Device, Load Balancer, Data Storage Device. The name should precisely encapsulate the primary technological function, type, or role the device plays within the organization's infrastructure.`,
  },
  {
    name: SYSTEM_SOFTWARE,
    index: 30,
    layer: LAYER_TECHNOLOGY,
    description: SYSTEM_SOFTWARE_DESCRIPTION,
    prompt: SYSTEM_SOFTWARE_PROMPT,
    nature: NATURE_ACTIVE_STRUCTURE,
    label: `Represents the software environment for specific types of hardware, often encompassing operating systems, database management systems, middleware, and other foundational software that provides general services for other software. These underpin the functionality and environment for applications and technology. When naming System Software, it's essential to describe its primary function, the type of services it provides, or its general category. The name should offer clarity on the System Software's core purpose, features, or the technological realm it operates in. Use terms that depict the main service, function, or category of the system software. The name should directly reflect the software's primary features, purpose, or its overarching category. e.g. Windows OS, Oracle DBMS, Apache Web Server, Security Middleware, Virtualization Layer. The name should encapsulate the primary function, features, or category that the system software represents within the technological layer of the organization.`,
  },
  {
    name: TECHNOLOGY_COLLABORATION,
    index: 31,
    layer: LAYER_TECHNOLOGY,
    description: TECHNOLOGY_COLLABORATION_DESCRIPTION,
    prompt: TECHNOLOGY_COLLABORATION_PROMPT,
    nature: NATURE_ACTIVE_STRUCTURE,
    label: `Refers to an aggregation of two or more technology behavior elements that work jointly to produce collective technology behavior. This is most often utilized to represent how different software and hardware components work together to deliver a specific technological capability or functionality. When naming a Technology Collaboration, it's crucial to capture the essence of the collective behavior or the primary technological purpose it serves. The name should provide a concise understanding of the combined role or functionality achieved through the collaboration. Utilize terms that encapsulate the collective behavior or the main technological outcome of the collaboration. The name should embody the purpose or the technological results achieved through the joint operation of the components. e.g. Network Load Balancing, Security Protocol Integration, Collaboration for Multi-Platform Integration, Collaboration for Real-time Data Sync. The name should faithfully represent the collective behavior, purpose, or technological outcome achieved through the collaboration of the components.`,
  },
  {
    name: TECHNOLOGY_INTERFACE,
    index: 32,
    layer: LAYER_TECHNOLOGY,
    description: TECHNOLOGY_INTERFACE_DESCRIPTION,
    prompt: TECHNOLOGY_INTERFACE_PROMPT,
    nature: NATURE_ACTIVE_STRUCTURE,
    label: `Refers to a point of access where technology services offered by a node can be accessed by other nodes and application components. This may encompass API endpoints, communication protocols, and other interfaces that enable interactions between different technological entities. When naming a Technology Interface, the focus should be on capturing the type of service or function it provides, the technology it interacts with, or the protocol/method it uses. The name should offer clarity regarding the specific access point, method, or the technological service it facilitates. Use descriptors that highlight the main technological service, the access method, or the interface type. The name should directly reflect the interface's main function, the technology or protocol it caters to, or its method of interaction. e.g. Data Sync Interface, Authentication API Interface, REST API Endpoint, FTP Access Point. The name should precisely encapsulate the main function, method, or technological service that the interface offers within the organization's technological landscape.`,
  },
  {
    name: PATH,
    index: 33,
    layer: LAYER_TECHNOLOGY,
    description: PATH_DESCRIPTION,
    prompt: PATH_PROMPT,
    nature: NATURE_ACTIVE_STRUCTURE,
    label: `Represents a communication network, or a specific type of link between two or more devices, through which these devices can exchange data. A Path can be physical (like a wired network) or logical (like a VPN). It effectively captures the medium and mode of data transport between technology entities. When naming a Path, the objective should be to concisely depict the mode, medium, or purpose of data transport it represents. The name should clearly convey the type or function of the path, ensuring quick recognition by IT professionals, architects, and other stakeholders. Opt for descriptors that emphasize the primary mode, medium, or primary function of the data transport. The name should reflect the path's main type, its technological characteristics, or its main function in data exchange. e.g. Ethernet, VPN Tunnel, High-speed Data Transfer, Secure Communication. The name should faithfully capture the primary mode, medium, or function that the path plays within the technology environment.`,
  },
  {
    name: COMMUNICATION_NETWORK,
    index: 34,
    layer: LAYER_TECHNOLOGY,
    description: COMMUNICATION_NETWORK_DESCRIPTION,
    prompt: COMMUNICATION_NETWORK_PROMPT,
    nature: NATURE_ACTIVE_STRUCTURE,
    label: `Represents a set of interconnected nodes that enable data exchange and communication between various technological entities. Communication Networks can be broad in scale, encompassing everything from an organization's internal LAN to vast global WANs or even cloud-based communication infrastructures. When naming a Communication Network, the aim should be to delineate the scope, purpose, or primary characteristic of the network. The name should succinctly communicate the type or primary function of the network to IT professionals, architects, and other stakeholders. Use terms that highlight the network's primary scope, function, or distinguishing feature. The name should embody the network's main type, its range (local, global, etc.), or its primary operational purpose. e.g. Local Area Network, Global WAN, Cloud Communication Network, Secure Transaction Network, Video Conferencing Network. The name should accurately depict the main scope, characteristic, or function that the communication network serves within the organization's tech ecosystem.`,
  },
  {
    name: TECHNOLOGY_FUNCTION,
    index: 35,
    layer: LAYER_TECHNOLOGY,
    description: TECHNOLOGY_FUNCTION_DESCRIPTION,
    prompt: TECHNOLOGY_FUNCTION_PROMPT,
    nature: NATURE_BEHAVIOURAL,
    label: `Represents a collection of technology behavior that can be performed by a node in the technology layer. This behavior can be seen as automated functions provided by technology components such as scripts, software, devices, etc. When compared to the Business Function in the business layer, the Technology Function is more about technological capabilities and automated tasks. When naming a Technology Function, the goal should be to succinctly depict its primary role, operational purpose, or main technological characteristic. The name should give a clear picture of the technological capability or behavior it represents. Choose descriptors that spotlight the technology function's main role, operational purpose, or primary technological characteristic. The name should encapsulate the function's main type of technological behavior, operation, or the capability it provides. e.g. Automated Backup Traffic Load Balancing, Intrusion Detection, Data Compression, Virtualization. The name should offer a direct reflection of the primary technological behavior, role, or capability that the function embodies in the organization's technology landscape.`,
  },
  {
    name: TECHNOLOGY_PROCESS,
    index: 36,
    layer: LAYER_TECHNOLOGY,
    description: TECHNOLOGY_PROCESS_DESCRIPTION,
    prompt: TECHNOLOGY_PROCESS_PROMPT,
    nature: NATURE_BEHAVIOURAL,
    label: `Refers to a sequence of technology behaviors (activities, actions, or functions) that produce a specific outcome or service in the technology layer. It can be compared to a Business Process but is focused on technological aspects and is often more granular, detailing how technology components work together to achieve a particular goal. When naming a Technology Process, the intention should be to clearly communicate its sequence, technological focus, or the desired outcome. Opt for descriptors that distinctly express the technology process's sequence, operational objective, or its main technological focus. The name should reflect the process's primary outcome or the sequence of technological activities it encompasses. e.g. Data Sync, Network Optimization, Technology Deployment and Update. The name should be a direct reflection of the main technological sequence, objective, or outcome the process aims to achieve.`,
  },
  {
    name: TECHNOLOGY_INTERACTION,
    index: 37,
    layer: LAYER_TECHNOLOGY,
    description: TECHNOLOGY_INTERACTION_DESCRIPTION,
    prompt: TECHNOLOGY_INTERACTION_PROMPT,
    nature: NATURE_BEHAVIOURAL,
    label: `Represents a unit of collective technology behavior performed by (or through) collaboration of two or more technology entities, where the emphasis is on coordination and interaction. Unlike "Technology Function" which is more focused on the capabilities, "Technology Interaction" is about the interplay between different technological entities or components. When naming a Technology Interaction, the goal should be to reflect the collective behavior and the coordinated interplay between different technological entities. Choose terms that shed light on the coordinated interplay, collaborative behavior, or the joint technological outcome of the interaction. The name should mirror the main collective technological behavior or the coordination between different technology entities. e.g. Data Exchange, Network Handshake, Server-Client Coordination, Device-Sensor Sync. The name should embody the primary collective technological behavior or the collaborative interplay it signifies in the technology landscape.`,
  },
  {
    name: TECHNOLOGY_EVENT,
    index: 38,
    layer: LAYER_TECHNOLOGY,
    description: TECHNOLOGY_EVENT_DESCRIPTION,
    prompt: TECHNOLOGY_EVENT_PROMPT,
    nature: NATURE_BEHAVIOURAL,
    label: `Represents an event that happens within the technology layer, triggering or influencing the behavior of other technological components. It could be, for instance, a system failure, a security breach, or a capacity threshold being reached. When naming a Technology Event, it's essential to clearly convey its nature, significance, or the specific technological condition or change it represents. Select descriptors that precisely pinpoint the specific technological change, condition, or significance of the event. The name should encapsulate the primary technological circumstance or change the event signifies or brings about. e.g. System Failure Event, Capacity Threshold Reached Event, Security Breach Event, Database Corruption Event. The name should directly reflect the primary technological change, condition, or significance the event signifies or results in.`,
  },
  {
    name: TECHNOLOGY_SERVICE,
    index: 39,
    layer: LAYER_TECHNOLOGY,
    description: TECHNOLOGY_SERVICE_DESCRIPTION,
    prompt: TECHNOLOGY_SERVICE_PROMPT,
    nature: NATURE_BEHAVIOURAL,
    label: `Represents an explicitly defined exposed technology behavior, which is available to its environment (like business or application layers) and is performed by a technology interface or node. This is analogous to the notion of a service in Service-Oriented Architecture (SOA) and could include things like API services, infrastructure-as-a-service, platform-as-a-service, etc. When naming a Technology Service, the primary objective is to distinctly communicate its functionality, scope, or the specific technology behavior it provides. Use terms that lucidly express the specific technology behavior, function, or scope of the service. The name should encapsulate the primary technological capability or functionality the service offers. e.g. Authentication Service, Database Hosting Service, Network Management Service, Cloud Storage Service. The name should be a direct reflection of the core technological behavior, functionality, or domain the service pertains to.`,
  },
  {
    name: ARTIFACT,
    index: 40,
    layer: LAYER_TECHNOLOGY,
    description: ARTIFACT_DESCRIPTION,
    prompt: ARTIFACT_PROMPT,
    nature: NATURE_PASSIVE_STRUCTURE,
    label: `Represents a physical piece of information that is used or produced in a software development process, or by system deployment and operation. This can be anything from a script, a compiled binary, a configuration file, a data set, to a detailed software documentation. When naming an Artifact, the objective is to precisely define its nature, usage, or the specific type of information it represents. Opt for descriptors that accurately represent the specific type of information, its purpose, or its role in the development or deployment process. The name should encapsulate the primary type, use, or content of the artifact. e.g. Configuration File, Compiled Binary, Deployment Script, User Manual. The name should directly reflect the intrinsic type, usage, or content of the artifact.`,
  },
  {
    name: EQUIPMENT,
    index: 41,
    layer: LAYER_PHYSICAL,
    description: EQUIPMENT_DESCRIPTION,
    prompt: EQUIPMENT_PROMPT,
    nature: NATURE_ACTIVE_STRUCTURE,
    label: `Represents physical devices or tools that are employed to perform specific tasks or functions. It can encompass anything from hardware components, machinery, tools, and other tangible assets that facilitate technological operations. When naming Equipment, it's essential to distinctly denote its nature, function, or the specific technological role it performs. Choose descriptors that precisely illustrate the specific type, function, or technological role of the equipment. The name should embody the primary nature, function, or technological role of the equipment. e.g. Server Rack, Cooling System, Backup Generator, Network Switch. The name should be an accurate representation of the core nature, functionality, or technological role the equipment serves.`,
  },
  {
    name: FACILITY,
    index: 42,
    layer: LAYER_PHYSICAL,
    description: FACILITY_DESCRIPTION,
    prompt: FACILITY_PROMPT,
    nature: NATURE_ACTIVE_STRUCTURE,
    label: `Refers to a physical structure or environment, such as a data center, office building, warehouse, or other types of physical locations that house and facilitate specific business or technological operations. When naming a Facility, the goal is to succinctly indicate its primary purpose, function, or the specific kind of activities it facilitates. Choose names that clearly express the main purpose, function, or type of activities facilitated by the physical location. The name should encapsulate the primary use, purpose, or nature of the facility. e.g. Data Center, Headquarters, Research and Development, Training Center, Logistics Hub. The name should be a direct reflection of the core purpose, function, or type of activities the facility is designed to support.`,
  },
  {
    name: DISTRIBUTION_NETWORK,
    index: 43,
    layer: LAYER_PHYSICAL,
    description: DISTRIBUTION_NETWORK_DESCRIPTION,
    prompt: DISTRIBUTION_NETWORK_PROMPT,
    nature: NATURE_ACTIVE_STRUCTURE,
    label: `Represents the physical infrastructure and logistics mechanisms used to move goods, services, or information from producers to consumers. This can encompass transportation routes, distribution centers, logistics systems, and other related components that facilitate the flow and delivery of goods and services. When naming a Distribution Network, the objective is to clearly denote its primary purpose, the type of goods or services it facilitates, or its geographical scope. Opt for descriptors that lucidly express the main purpose, the type of goods/services facilitated, or the geographical scope of the network. The name should capture the principal function, type of distribution, or the specific region the network covers. e.g., Pharmaceutical Distribution Network, Digital Content Distribution Network, Regional Distribution Network, Global Logistics Network, Last-Mile Delivery Network, Wholesale Distribution Network. By heeding this guidance, Distribution Network names will be in line with best practices in Enterprise Architecture, clearly reflecting their primary functions, types, or geographical scopes.`,
  },
  {
    name: MATERIAL,
    index: 44,
    layer: LAYER_PHYSICAL,
    description: MATERIAL_DESCRIPTION,
    // Use same value for description and prompt, since description is brief and ok
    prompt: MATERIAL_DESCRIPTION,
    nature: NATURE_PASSIVE_STRUCTURE,
    label: `Pertains to tangible physical items or substances that are used, consumed, or transformed in a business or technological process. This could include raw materials, finished goods, spare parts, and other physical items. When naming "Material", the focus should be on indicating its primary composition, use, or the specific context in which it is relevant. Opt for names that clearly define the composition, use, or the specific context of the material. The name should accurately depict the nature or primary characteristics of the material. e.g. Steel, Plastic Granules, Ceramic, Insulating Material, Construction Material, Electronic Component Material, Grade A Aluminum, High-Density Polyethylene. The name should reflect the primary composition, characteristics, or application context of the material.`,
  },
  {
    name: STAKEHOLDER,
    index: 45,
    layer: LAYER_MOTIVATION,
    description: STAKEHOLDER_DESCRIPTION,
    prompt: STAKEHOLDER_PROMPT,
    nature: NATURE_MOTIVATION,
    label: `Refers to any person, group, or organization that has an interest in, can affect, or can be affected by an organization's actions, objectives, or policies. This could include customers, employees, shareholders, suppliers, and other parties. When naming a "Stakeholder", the emphasis should be on denoting their primary role, function, or relationship with the organization or initiative. Choose descriptors that clearly express the primary role, function, or relationship of the stakeholder to the organization or initiative. The name should encapsulate the main interest, influence, or relationship that the stakeholder holds in relation to the organization. e.g. Customers, Employees, Regulators, Investor Stakeholders, Community Stakeholders, Supplier Stakeholders, Youth Advocacy Groups, Environmental Activists, Institutional Investors. The name should directly signify the main role, relationship, or interest that the stakeholder represents in relation to the organization or initiative.`,
  },
  {
    name: DRIVER,
    index: 46,
    layer: LAYER_MOTIVATION,
    description: DRIVER_DESCRIPTION,
    // Description is ok to use for prompt
    prompt: DRIVER_DESCRIPTION,
    // Optional more detailed prompt when generating descriptions for an element, rather than the element name
    prompt_for_description:
      "A driver is a declarative statement that identifies a particular business problem. It can be related to risk, cost, complexity or any range of challenges that the business may face. When describing a Driver only the problem or concern area is stated, and not the solution or goals to address the driver. Also do not use the word driver in the description -- just state what the driver is in passive voice and in past tense",
    nature: NATURE_MOTIVATION,
    label: `Signifies an external or internal condition that motivates an organization to achieve specific outcomes. This could encompass market demands, societal trends, technological innovations, laws and regulations, internal corporate goals, and more. When naming a "Driver", the emphasis should be on portraying the primary motivational or influential force that the driver represents. Opt for descriptors that lucidly convey the primary motivational or influential force the driver epitomizes. The name should encapsulate the primary catalyst, concern, or incentive that the driver presents. e.g. Technological Advancements, Market Competition, Regulatory Compliance, Growing Demand for Eco-Friendly Products, Shift to Remote Work, Need for Digital Transformation, Strategic Growth Goals, Emerging Market Opportunities, Evolving Customer Preferences. The name should reflect the primary motivational or influential force that the driver embodies.`,
  },
  {
    name: ASSESSMENT,
    index: 47,
    layer: LAYER_MOTIVATION,
    description: ASSESSMENT_DESCRIPTION,
    prompt: ASSESSMENT_PROMPT,
    nature: NATURE_MOTIVATION,
    label: `Pertains to the outcome of some analysis concerning drivers. An Assessment might highlight a gap between the current state of affairs and a desired (or mandated) future state. It often provides insights that underpin decision-making in an organization. When naming an "Assessment", the focus should be on conveying the primary subject or outcome of the evaluation. The name should encapsulate the key focus, concern, or result of the assessment. e.g., Cybersecurity Vulnerability, Operational Efficiency , Market Position, Regulatory Compliance, Technological Readiness, Organizational Culture, Digital Capabilities Gap, Customer Satisfaction Levels, Review of Supply Chain Robustness. The name should directly indicate the main focus, subject, or outcome of the assessment, and be past-tense as if the assessment had occurred. In the description, provide specific, factual information relevant to the given context and this element. Ensure the information is based on real data or findings and cite sources where applicable. For example, include market statistics, industry benchmarks, survey results, regulatory changes, or technological advancements. Avoid general descriptions and focus on delivering actionable insights backed by evidence.`,
    element_description_prompt: `List some common findings or outcomes of an assessment. For example, "Cybersecurity Vulnerability" might find "Critical vulnerabilities in the network infrastructure" or "Lack of employee awareness training on phishing scams".`,
  },
  {
    name: GOAL,
    index: 48,
    layer: LAYER_MOTIVATION,
    description: GOAL_DESCRIPTION,
    // Description is ok to use for prompt
    prompt: GOAL_DESCRIPTION,
    nature: NATURE_MOTIVATION,
    label: `Denotes a high-level state or situation that an organization aspires to achieve within a specified timeframe. Goals are often strategic and help guide decision-making by setting clear priorities. When naming a "Goal", it's vital to articulate the desired end state or outcome that the organization aims to accomplish. Opt for descriptors that vividly convey the intended outcome or desired end state. The name should encapsulate the primary aim, objective, or aspiration of the goal. e.g. Achieve Market Leadership, Achieve Operational Excellence, Achieve Carbon Neutrality by 2030, Increase Customer Loyalty, Reduce Operational Costs, Expand Global Presence, Full Regulatory Compliance, Excellence in Customer Service. The name should mirror the overarching aspiration or desired outcome that the goal seeks to achieve. By adhering to this guidance, Goal names will be in line with standard practices in Enterprise Architecture, offering stakeholders a clear vision of what the organization aims to achieve in the stipulated period.`,
  },
  {
    name: OUTCOME,
    index: 49,
    layer: LAYER_MOTIVATION,
    description: OUTCOME_DESCRIPTION,
    prompt: OUTCOME_PROMPT,
    nature: NATURE_MOTIVATION,
    label: `A measurable result stemming from actions, behaviors, and strategies applied in an organization or system. Outcomes can be the realization of objectives or the consequences of certain activities, and they provide tangible evidence of whether goals or objectives are being achieved. For naming an "Outcome", the emphasis should be on the tangible or measurable result or end-state. Choose descriptors that vividly communicate the tangible result, change, or effect. The name should capture the primary result or measurable state arising from certain actions or strategies. e.g. Increase in Sales Revenue, Reduction in Customer Complaints, Improvement in Process Efficiency, Achieved 95% Customer Satisfaction, Achieved Zero Downtime, Achieved Full Regulatory Compliance, 15% Growth in Market Share, Successful Product Launch in Asian Markets, Enhanced Employee Retention Rate. The name should directly indicate the measurable change, result, or state represented by the outcome.`,
  },
  {
    name: PRINCIPLE,
    index: 50,
    layer: LAYER_MOTIVATION,
    description: PRINCIPLE_DESCRIPTION,
    prompt: PRINCIPLE_PROMPT,
    nature: NATURE_MOTIVATION,
    label: `A fundamental tenet or guideline that guides decision-making, behavior, and operations within an organization. Principles assert the essential, enduring ethos of the organization and often derive from organizational values or foundational beliefs. When naming a "Principle", it's essential to articulate the core guiding belief or value in a manner that is unequivocal and actionable. Opt for descriptors that clearly articulate the core belief, value, or guideline. The name should encapsulate the foundational ethos or guideline that the principle intends to convey. e.g. Customer First, Safety First, Quality First, Protect User Privacy, Foster Open Communication, Ensure Data Integrity, Commitment to Sustainability, Embrace Continuous Learning, Operate with Transparency. The name should mirror the foundational guideline or belief that the principle aims to enshrine.`,
  },
  {
    name: REQUIREMENT,
    index: 51,
    layer: LAYER_MOTIVATION,
    description: REQUIREMENT_DESCRIPTION,
    prompt: REQUIREMENT_PROMPT,
    nature: NATURE_MOTIVATION,
    label: `Specifies a condition or capability that must be met or possessed by a system, product, service, or result. Requirements serve as stipulations for design or implementation and can originate from stakeholders, regulations, standards, or the system's inherent nature. When naming a "Requirement", it's essential to provide a concise descriptor that indicates the specific need or condition. Choose descriptors that clearly state the particular need, condition, or capability. The name should encapsulate the specific stipulation or need that the requirement is addressing. e.g. Data Encryption Requirement, Two-Factor Authentication Requirement, Accessibility Compliance Requirement, Need for Real-Time Reporting, Need for User Customization, Need for Multi-Language Support, Maintain 99.99% Uptime, Support Multiple Browsers, Ensure GDPR Compliance. The name should directly reflect the specific stipulation, need, or capability encapsulated by the requirement. By following this guidance, Requirement names will align with standard practices in Enterprise Architecture, providing stakeholders with a clear representation of specific stipulations, needs, or capabilities. Do not use the word 'requirement' in the name.`,
  },
  {
    name: CONSTRAINT,
    index: 52,
    layer: LAYER_MOTIVATION,
    description: CONSTRAINT_DESCRIPTION,
    // Description is ok to use for prompt
    prompt: CONSTRAINT_DESCRIPTION,
    nature: NATURE_MOTIVATION,
    label: `A restrictive condition, limitation, or regulation that affects the design, implementation, or operation of an entity. Constraints can arise from various sources, including regulations, standards, technology limitations, or business policies. When naming a "Constraint", the goal is to succinctly express the specific restriction or limitation being imposed. Select descriptors that explicitly express the particular restriction, limitation, or regulation. The name should encapsulate the specific limitation or restriction addressed by the constraint. e.g. Data Storage Limit, Bandwidth Usage, Operational Hours, Limit API Calls to 1000/hr, Restrict Access to Authorized Users, Maintain Compliance with ISO 27001, Maximum 10 Concurrent Users, No Data Transfer Outside EU, Operate Only in Designated Zones. The name should reflect the specific restriction, limitation, or regulation represented by the constraint.`,
  },
  {
    name: MEANING,
    index: 53,
    layer: LAYER_MOTIVATION,
    description: MEANING_DESCRIPTION,
    prompt: MEANING_PROMPT,
    nature: NATURE_MOTIVATION,
    label: `Refers to the knowledge or expertise present in, or the interpretation given to, a piece of information in a particular context. Essentially, it is about the semantics of information. models, "Meaning" can be used to add more depth or specificity about what a particular piece of information represents in a given context. When naming a "Meaning", the goal is to succinctly capture the essence or interpretation of the information in its context. Opt for descriptors that clearly convey the core essence or interpretation of the information in its given context. The name should encapsulate the specific interpretation or essence of the piece of information. Customer Satisfaction Score, Operational Risk Level, Product Lifecycle Stage, Product's Market Position, Customer Feedback Sentiment, Financial Health Indicator. The name should directly reflect the specific interpretation or essence represented by the "Meaning".`,
  },
  {
    name: VALUE,
    index: 54,
    layer: LAYER_MOTIVATION,
    description: VALUE_DESCRIPTION,
    prompt: VALUE_PROMPT,
    nature: NATURE_MOTIVATION,
    label: `Refers to the relative worth, utility, or importance of a particular service, product, process, or other entity. A "Value" could denote something tangible, like monetary gains, or something intangible, such as customer satisfaction or brand recognition. When naming a "Value", the goal is to capture the essence of the specific worth, utility, or importance that the entity brings. Choose descriptors that specifically convey the worth, utility, or importance of the entity in context. The name should clearly encapsulate the specific benefit or worth brought about by the entity. e.g., Cost Savings, Operational Efficiency, Brand Loyalty Boost, Reduction in Operational Downtime, Increase in Customer Retention Rate, Enhanced Market Reach. The name should directly reflect the specific worth, utility, or importance represented by the "Value".`,
  },
  {
    name: WORK_PACKAGE,
    index: 55,
    layer: LAYER_IMPLEMENTATION,
    description: WORK_PACKAGE_DESCRIPTION,
    prompt: WORK_PACKAGE_PROMPT,
    nature: NATURE_IMPLEMENTATION_AND_MIGRATION,
    label: `Represents a series of actions identified and designed to achieve specific objectives within a set time frame. A Work Package can represent projects, project phases, or other types of initiatives. When you're naming a "Work Package", the aim is to depict the essence of the specific objectives and actions planned. Opt for descriptors that provide a clear picture of the objectives and the scope of the planned actions. The name should concisely encapsulate the nature and purpose of the tasks within the Work Package. e.g. Website Redesign Project, Supply Chain Optimization Phase, Customer Data Migration Initiative, Network Infrastructure Upgrade, Post-merger Integration Tasks, CRM System Implementation Phase 1. The name should directly mirror the specific objectives and nature of the activities enclosed in the "Work Package".`,
  },
  {
    name: DELIVERABLE,
    index: 56,
    layer: LAYER_IMPLEMENTATION,
    description: DELIVERABLE_DESCRIPTION,
    prompt: DELIVERABLE_PROMPT,
    nature: NATURE_IMPLEMENTATION_AND_MIGRATION,
    label: `Represents a concrete, tangible outcome resulting from the completion of a work package or course of action. It could be a report, a piece of software, documentation, or any other tangible item that results from some effort or project. When naming a "Deliverable", the objective is to encapsulate the essence of the tangible outcome or product that has been, or will be, delivered. Use descriptors that offer a clear representation of the tangible outcome or product. The name should succinctly portray the nature and purpose of the deliverable. e.g. Database Migration Report, User Interface Design Document, Sales Training Module, Finalized Marketing Strategy, Upgraded Software Release, Post-project Evaluation Summary. The name should directly capture the specific tangible outcome or product represented by the "Deliverable".`,
  },
  {
    name: IMPLEMENTATION_EVENT,
    index: 57,
    layer: LAYER_IMPLEMENTATION,
    description: IMPLEMENTATION_EVENT_DESCRIPTION,
    prompt: IMPLEMENTATION_EVENT_PROMPT,
    nature: NATURE_IMPLEMENTATION_AND_MIGRATION,
    label: `Refers to a state change or occurrence during the realization of deliverables, work packages, or plateaus. These events can signify milestones, triggers, outcomes, or significant shifts during the implementation or transition phases. Use clear, descriptive phrases that pinpoint the significance, trigger, or change associated with the event. The name should instantly convey the main occurrence or shift the event signifies in the implementation phase. e.g. Database Migration Completion, Stakeholder Approval Milestone, Beta Testing Kickoff, Transition to Deployment Phase, Transition to Post-Launch Support.`,
  },
  {
    name: PLATEAU,
    index: 58,
    layer: LAYER_IMPLEMENTATION,
    description: PLATEAU_DESCRIPTION,
    // Use same value for description and prompt, since description is brief and ok
    prompt: PLATEAU_DESCRIPTION,
    nature: NATURE_IMPLEMENTATION_AND_MIGRATION,
    label: `is employed in the context of gap analysis and migration planning. It represents a state of the architecture at a particular point in time or over a period. Typically, the term "Plateau" is used to describe a stable state in the evolution of the architecture (like the current state, target state, or intermediary states). When naming a "Plateau", the intent is to capture the essence of the architecture's state or phase during its evolution or transformation. Choose descriptors that clearly indicate the state or phase of the architecture during its transformation journey. The name should concisely encapsulate the timeframe or characteristic of the architecture's state. e.g., "Initial State, Mid-Term Target, Final Desired State, Post-Merger Unified Architecture, Cloud-First State, Legacy System Phase-out Stage. If using temporal or sequential indicators, maintain consistency in terminology across plateaus to prevent confusion. The name should directly reflect the specific state or phase of the architecture that the "Plateau" represents.`,
  },
  {
    name: GAP,
    index: 59,
    layer: LAYER_IMPLEMENTATION,
    description: GAP_DESCRIPTION,
    prompt: GAP_PROMPT,
    nature: NATURE_IMPLEMENTATION_AND_MIGRATION,
    label: `is used to represent the difference between two plateaus (like the current state and the target state). It provides insights into what is missing, redundant, or needs to change to migrate from the present architecture to the desired one. When naming a "Gap", the goal is to succinctly describe the disparity between the two states or the nature of the change that needs to be addressed. Use descriptors that convey the primary difference or discrepancy between the two plateaus or the primary change necessary. The name should briefly characterize the main area of change or the primary issue to be addressed. e.g., Gap in Security Measures, Gap in Data Integration, Legacy to Cloud Migration Gap, On-premise to Hybrid Network Gap. The name should directly represent the primary disparity or the major change that the "Gap" signifies in the architectural transition.`,
  },
  {
    name: JUNCTION,
    index: 60,
    layer: LAYER_OTHER,
    description: JUNCTION_DESCRIPTION,
    // Use same value for description and prompt, since description is brief and ok
    prompt: JUNCTION_DESCRIPTION,
    nature: NATURE_COMPOSITE,
  },
  {
    name: GROUPING,
    index: 61,
    layer: LAYER_OTHER,
    description: GROUPING_DESCRIPTION,
    // Use same value for description and prompt, since description is brief and ok
    prompt: GROUPING_DESCRIPTION,
    nature: NATURE_COMPOSITE,
  },
]

const getElementTypeSummaries = () => {
  return `    Application Collaboration: Cooperative action between software elements. E.g., "Data Sharing Interface".
    Application Component: Software component. E.g., "Billing Module".
    Application Event: Signal within software that prompts action or change. E.g., "Login Attempt".
    Application Interaction: Software processes collaboration. E.g., "Data Synchronization Process".
    Application Interface: Point of access for IT services. E.g., "API Endpoint".
    Application Service: IT function offered to users. E.g., "Authentication Service".
    Artifact: Tangible technology outcome. E.g., "Compiled Binary", "Data File".
    Assessment: Judgment of state or decision. E.g., "SWOT Analysis".
    Business Actor: Organizational role or entity. Typically a human position or department. E.g., "Manager", "Billing Department".
    Business Collaboration: Joint business activities. E.g., "Supplier Partnership".
    Business Event: Business trigger or disruptor. E.g., "Sales Promotion".
    Business Function: Organizational capability without a set sequence. E.g., "Sales", "Supply Chain Management".
    Business Interaction: Collaborative set of activities. E.g., "Customer Negotiation".
    Business Interface: Way a business element connects or interacts, not necessarily tangible. E.g., "Customer Support Portal".
    Business Object: Data concept that represents a business aspect. E.g., "Customer Profile".
    Business Process: Sequence of organizational activities with a clear start and end. E.g., "Order Processing".
    Business Role: Responsibility pattern. E.g., "Approver", "Buyer".
    Business Service: Business functionality offered. E.g., "Consultation Service".
    Capability: Ability of an organization. E.g., "Strategic Planning".
    Communication Network: Infrastructure facilitating communication between elements. E.g., "Intranet".
    Constraint: Limitation or restriction. E.g., "Budget Limit".
    Contract: Formalized agreement. E.g., "Service Level Agreement".
    Course of Action: Strategy or plan. E.g., "Market Expansion Strategy".
    Data Object: Organized data used in software operations. E.g., "User Profile".
    Deliverable: Tangible outcome from work. E.g., "Software Release".
    Device: Physical IT equipment. E.g., "Router", "Firewall".
    Distribution Network: How physical items move. E.g., "Supply Chain", "Mail Delivery Network".
    Driver: Organizational influencer. E.g., "Market Demand", "Regulation".
    Equipment: Major tangible resource, not IT-specific. E.g., "Generator", "Conference Room Projector".
    Facility: Physical structure. E.g., "Data Center", "Office Building".
    Gap: Difference between two plateau states. E.g., "Skillset Gap".
    Goal: Desired end-state. E.g., "Increase Market Share".
    Location: Geographical or physical point. E.g., "European Branch", "Warehouse B".
    Material: Physical matter, tangible. E.g., "Raw Materials", "Manufactured Parts".
    Meaning: Data's semantic interpretation. E.g., "Contract Terms Explanation".
    Node: Physical or virtual IT component, like a computer or cloud instance. E.g., "Server Cluster".
    Outcome: Actual end-state result. E.g., "Increased Customer Loyalty".
    Path: Physical or virtual IT component, like a computer or cloud instance. Not a walkway. E.g., "Data Transfer Route".
    Plateau: Snapshot or phase of an evolving architecture. E.g., "Current IT Infrastructure".
    Principle: Guiding rule or statement. E.g., "Data Privacy".
    Product: Collection of services perceived as a unit. E.g., "Premium Subscription".
    Representation: Snapshot or phase of an evolving architecture. E.g., "Digital Invoice".
    Requirement: Explicit need or condition. E.g., "Two-Factor Authentication".
    Resource: Assets for operations, general. E.g., "Financial Reserve", "Human Resources".
    Stakeholder: Entity with interest in outcome. E.g., "Investor", "Customer".
    System Software: Environment or software layer. E.g., "Operating System", "Database Management System".
    Technology Collaboration: Interaction between hardware or system software. E.g., "Load Balancing Setup".
    Technology Event: Technology trigger or disruptor. E.g., "System Failure Alert".
    Technology Function: Specific tech task or utility. E.g.,"Data Encryption".
    Technology Interface: Point of access in technology. E.g., "Network Port", "API Endpoint".
    Technology Interaction: Collaborative technology activities. E.g., "Automated System Update".
    Technology Process: Sequence of technology activities. E.g., "Backup Procedure".
    Technology Service: IT service offered externally. E.g., "Cloud Storage Service".
    Value: Business worth or importance. E.g., "Customer Satisfaction".
    Value Stream: High level strategic steps or processes that together produce value. E.g., "Customer Support Journey".
    Work Package: Grouped work efforts. E.g., "Product Launch Plan".`
}

const getIndex = (name) => {
  const element = ELEMENT_INDEX.find(
    (element) => element.name.toLocaleLowerCase() === name.toLocaleLowerCase()
  )
  return element ? element.index : -1
}

const getElementNameByIndex = (index) => {
  const element = ELEMENT_INDEX.find((element) => element.index === index)
  return element ? element.name : null
}

const getElementTypeByIndex = (index) => {
  const element = ELEMENT_INDEX.find((element) => element.index === index)
  return element ? element : null
}

const getElementType = (elementType) => {
  // Replace all spaces with empty string
  const strippedName = elementType.replace(/\s/g, "")
  return ELEMENT_INDEX.find((element) => element.name === strippedName)
}

const getElementTypeNames = () => {
  return ELEMENT_INDEX.map((element) => element.name)
}

const getElementLayerColor = (elementType) => {
  return getElementType(elementType).layer.color
}

const getElements = () => {
  return ELEMENT_INDEX
}

const getElementTypes = () => {
  const result = _.groupBy(
    ELEMENT_INDEX.map((element) => ({
      ...element,
      layer: LAYERS.find((l) => l.id === element.layer.id),
    })),
    "layer.id"
  )

  return result
}

const getElementTypeIds = (elementTypeNames) => {
  return elementTypeNames.map((name) => getIndex(name))
}

const symbols = {
  // Elements

  [LOCATION]: Location,
  [GROUPING]: Grouping,
  [GROUP]: Group,
  [JUNCTION]: Junction,
  [NOTE]: Note,

  [RESOURCE]: Resource,
  [CAPABILITY]: Capability,
  [VALUE_STREAM]: ValueStream,
  [COURSE_OF_ACTION]: CourseOfAction,

  [BUSINESS_ACTOR]: BusinessActor,
  [BUSINESS_ROLE]: BusinessRole,
  [BUSINESS_COLLABORATION]: BusinessCollaboration,
  [BUSINESS_INTERFACE]: BusinessInterface,
  [BUSINESS_PROCESS]: BusinessProcess,
  [BUSINESS_FUNCTION]: BusinessFunction,
  [BUSINESS_INTERACTION]: BusinessInteraction,
  [BUSINESS_EVENT]: BusinessEvent,
  [BUSINESS_SERVICE]: BusinessService,
  [BUSINESS_OBJECT]: BusinessObject,
  [CONTRACT]: Contract,
  [REPRESENTATION]: Representation,
  [PRODUCT]: Product,

  [APPLICATION_COMPONENT]: ApplicationComponent,
  [APPLICATION_COLLABORATION]: ApplicationCollaboration,
  [APPLICATION_INTERFACE]: ApplicationInterface,
  [APPLICATION_FUNCTION]: ApplicationFunction,
  [APPLICATION_INTERACTION]: ApplicationInteraction,
  [APPLICATION_PROCESS]: ApplicationProcess,
  [APPLICATION_EVENT]: ApplicationEvent,
  [APPLICATION_SERVICE]: ApplicationService,
  [DATA_OBJECT]: DataObject,

  [NODE]: Node,
  [DEVICE]: Device,
  [SYSTEM_SOFTWARE]: SystemSoftware,
  [TECHNOLOGY_COLLABORATION]: TechnologyCollaboration,
  [TECHNOLOGY_INTERFACE]: TechnologyInterface,
  [PATH]: Path,
  [COMMUNICATION_NETWORK]: CommunicationNetwork,
  [TECHNOLOGY_FUNCTION]: TechnologyFunction,
  [TECHNOLOGY_PROCESS]: TechnologyProcess,
  [TECHNOLOGY_INTERACTION]: TechnologyInteraction,
  [TECHNOLOGY_EVENT]: TechnologyEvent,
  [TECHNOLOGY_SERVICE]: TechnologyService,

  [ARTIFACT]: Artifact,
  [EQUIPMENT]: Equipment,
  [FACILITY]: Facility,
  [DISTRIBUTION_NETWORK]: DistributionNetwork,
  [MATERIAL]: Material,

  [STAKEHOLDER]: Stakeholder,
  [DRIVER]: Driver,
  [ASSESSMENT]: Assessment,
  [GOAL]: Goal,
  [OUTCOME]: Outcome,
  [PRINCIPLE]: Principle,
  [REQUIREMENT]: Requirement,
  [CONSTRAINT]: Constraint,
  [MEANING]: Meaning,
  [VALUE]: Value,

  [WORK_PACKAGE]: WorkPackage,
  [DELIVERABLE]: Deliverable,
  [IMPLEMENTATION_EVENT]: ImplementationEvent,
  [PLATEAU]: Plateau,
  [GAP]: Gap,

  // Connectors
  CompositionRelationship: Composition,
  AggregationRelationship: Aggregation,
  AssignmentRelationship: Assignment,
  [REALIZATION_RELATIONSHIP]: Realization,
  ServingRelationship: Serving,
  AccessRelationship: Access,
  InfluenceRelationship: Influence,
  [TRIGGERING_RELATIONSHIP]: Triggering,
  FlowRelationship: Flow,
  SpecializationRelationship: Specialization,
  AssociationRelationship: Association,
  Connection: Connection,

  // Link
  [DIAGRAM_MODEL_REFERENCE]: DiagramModelReference,
}

const formatLabel = (label) => {
  return label.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")
}

// elements that only appear on views, and aren't ArchiMate elements
const viewOnlySymbols = [
  `archimate:${NOTE}`,
  `archimate:${GROUP}`,
  `archimate:${DIAGRAM_MODEL_REFERENCE}`,
]

export {
  // Constants
  LOCATION,
  GROUPING,
  GROUP,
  JUNCTION,
  RESOURCE,
  CAPABILITY,
  VALUE_STREAM,
  COURSE_OF_ACTION,
  BUSINESS_ACTOR,
  BUSINESS_ROLE,
  BUSINESS_COLLABORATION,
  BUSINESS_INTERFACE,
  BUSINESS_PROCESS,
  BUSINESS_FUNCTION,
  BUSINESS_INTERACTION,
  BUSINESS_EVENT,
  BUSINESS_SERVICE,
  BUSINESS_OBJECT,
  CONTRACT,
  REPRESENTATION,
  PRODUCT,
  APPLICATION_COMPONENT,
  APPLICATION_COLLABORATION,
  APPLICATION_INTERFACE,
  APPLICATION_FUNCTION,
  APPLICATION_INTERACTION,
  APPLICATION_PROCESS,
  APPLICATION_EVENT,
  APPLICATION_SERVICE,
  DATA_OBJECT,
  NODE,
  DEVICE,
  SYSTEM_SOFTWARE,
  TECHNOLOGY_COLLABORATION,
  TECHNOLOGY_INTERFACE,
  PATH,
  COMMUNICATION_NETWORK,
  TECHNOLOGY_FUNCTION,
  TECHNOLOGY_PROCESS,
  TECHNOLOGY_INTERACTION,
  TECHNOLOGY_EVENT,
  TECHNOLOGY_SERVICE,
  ARTIFACT,
  EQUIPMENT,
  FACILITY,
  DISTRIBUTION_NETWORK,
  MATERIAL,
  STAKEHOLDER,
  DRIVER,
  ASSESSMENT,
  GOAL,
  OUTCOME,
  PRINCIPLE,
  REQUIREMENT,
  CONSTRAINT,
  MEANING,
  VALUE,
  WORK_PACKAGE,
  DELIVERABLE,
  IMPLEMENTATION_EVENT,
  PLATEAU,
  GAP,
  NOTE,
  DIAGRAM_MODEL_REFERENCE,
  // Relationships
  COMPOSITION_RELATIONSHIP,
  AGGREGATION_RELATIONSHIP,
  ASSIGNMENT_RELATIONSHIP,
  REALIZATION_RELATIONSHIP,
  SERVING_RELATIONSHIP,
  ACCESS_RELATIONSHIP,
  INFLUENCE_RELATIONSHIP,
  TRIGGERING_RELATIONSHIP,
  FLOW_RELATIONSHIP,
  SPECIALIZATION_RELATIONSHIP,
  ASSOCIATION_RELATIONSHIP,
  CONNECTION,
  // Visual
  Grouping,
  Group,
  Note,
  Junction,
  // Strategy
  ValueStream,
  Capability,
  Resource,
  CourseOfAction,
  // Motivation
  Stakeholder,
  Requirement,
  Goal,
  Outcome,
  Constraint,
  Value,
  Driver,
  Assessment,
  Meaning,
  Principle,
  // Symbols
  Location,
  // Business
  BusinessProcess,
  BusinessFunction,
  BusinessService,
  BusinessObject,
  BusinessRole,
  BusinessInterface,
  BusinessInteraction,
  BusinessEvent,
  BusinessCollaboration,
  BusinessActor,
  Contract,
  Product,
  Representation,
  // Application
  ApplicationComponent,
  ApplicationService,
  ApplicationProcess,
  ApplicationFunction,
  ApplicationInterface,
  ApplicationCollaboration,
  ApplicationInteraction,
  ApplicationEvent,
  DataObject,
  // Technology & Physical
  TechnologyProcess,
  TechnologyService,
  TechnologyFunction,
  TechnologyInterface,
  TechnologyCollaboration,
  TechnologyInteraction,
  TechnologyEvent,
  SystemSoftware,
  Artifact,
  Node,
  Device,
  CommunicationNetwork,
  Path,
  Equipment,
  Facility,
  DistributionNetwork,
  Material,
  // Implementation and Migration
  WorkPackage,
  ImplementationEvent,
  Deliverable,
  Plateau,
  Gap,
  // Connectors
  Triggering,
  Composition,
  Aggregation,
  Association,
  Assignment,
  Flow,
  Access,
  Specialization,
  Realization,
  Influence,
  Serving,
  // Between notes
  Connection,
  // Link
  DiagramModelReference,
  // Placeholder synbol and line until all the ArchiMate symbols/lines are created
  GenericSymbol,
  GenericLine,
  symbols,
  ELEMENT_INDEX,
  LAYERS,
  LAYER_NAME_MOTIVATION,
  LAYER_NAME_STRATEGY,
  LAYER_NAME_BUSINESS,
  LAYER_NAME_APPLICATION,
  LAYER_NAME_TECHNOLOGY,
  LAYER_NAME_PHYSICAL,
  LAYER_NAME_IMPLEMENTATION,
  LAYER_NAME_OTHER,
  getIndex,
  getElements,
  getElementTypes,
  getElementNameByIndex,
  getElementTypeByIndex,
  getElementTypeNames,
  getElementTypeSummaries,
  getElementType,
  getElementTypeIds,
  getElementLayerColor,
  getConnectorTypes,
  formatLabel,
  viewOnlySymbols,
}
