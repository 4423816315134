import * as actions from "../actionTypes"

const initialState = {
    value: {
        data: [],
        //sortProjects: false,
    },
}

/**
 * Store state for retrieving projects by tag from BigQuery for use in ChartJS
 * @param {*} state
 * @param {*} action
 * @returns
 */
export default function chartProjectsByTag(state = initialState, action) {
    switch (action.type) {
        case actions.SET_CHART_PROJECTS_BY_TAG: {
            console.log("[r] Setting chart projects by tag", action.payload.value)
            return {
                ...state,
                value: action.payload.value,
            }
        }

        default:
            return state
    }
}
