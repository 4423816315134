import TextInput from "./TextInput"
import RadioGroup from "./RadioGroup"
import Select from "./Select"
import Checkbox from "./Checkbox"
import DatePicker from "./DatePicker"
import Button from "./Button"
import Readonly from "./Readonly"
import ComboBox from "./ComboBox"
import AddMultiple from "./AddMultiple"
import CreatedDate from "./CreatedDate"
import ProviderCombobox from "./ProviderCombobox"

const Controls = {
    TextInput,
    RadioGroup,
    Select,
    Checkbox,
    DatePicker,
    Button,
    Readonly,
    ComboBox,
    AddMultiple,
    CreatedDate,
    ProviderCombobox,
}

export default Controls
