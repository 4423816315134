const features = [
    {
        name: "Model Viewing",
        description:
            "View and navigate through models. Make it easy to understand where a model element has been used. Allow multiple model files to be uploaded for Projects and Components, which might represent different aspects of a Project/Component, or different versions",
        status: "Done",
        category: "Model viewing",
        completed: "3-Apr-2022",
    },
    {
        name: "Version Comparison",
        description:
            "Be able to compare 2 versions of the same view to understand what's changed. For a given view, show other versions of that view with either the same name or GUID and allow switching between different versions of the same view",
        status: "Idea",
        category: "Model viewing",
    },
    {
        name: "Explorer",
        description:
            "Be able to search, group, and filter a portfolio of projects or components to help drive model quality and completeness, and identify and gaps or overlaps in designs",
        status: "Done",
        notes: "The Explorer view is availabe from the menu, via the Project Explorer or Component Explorer menu items",
        category: "Portfolio management",
        completed: "12-May-2022",
    },
    {
        name: "Model Rules",
        description:
            "Configure and apply validation rules across models, depending on the development stage of the model",
        notes: "v1 of this is done in that there is a flexible rule type that can handle many scenarios. Further usage is required to determine what extensions or other rule types are required",
        status: "Done",
        category: "Model quality",
        completed: "2-Apr-2022",
    },
    {
        name: "Business Scenario Overlay",
        description:
            "Select functions and flows which combine to form a business scenario. Be able to highlight/colour-code the scenario incl. functions and interconnecting flows, and export for AIM simulations. Be able to generate skeleton scripts to run the op model simulation (Node based)",
        status: "WIP",
        notes: "This can be partially achieved by creating a Story within a Project, and tagging functions within a business architecture view, e.g. operating model or process view, and then tagging the story as type: Business Scenario",
        category: "Business Architecture",
    },
    {
        name: "Work Packages Walkthrough",
        description:
            "Create a page for walking through the work packages and deliverables for a project, work packages and deliverables on the left, and can display the relevant view(s) to which the work packages/deliverables are associated and also highlight which aspects of the model are affected -- even if the work package/deliverable elements are not shown on that view. Impacted elements can he highlighted with different color codes, e.g. green for add, red for remove, etc",
        status: "Idea",
        notes: "There is an initial view of Work Packages as a tab in the Project Edit page. This summarises all of the Work Packages in the selected model, from which you can navigate to the views that include those Work Packages",
        category: "Scope definition",
    },
    {
        name: "Project and Component report",
        description:
            "Be able to produce a report of what projects and components are stored, incl. status of each, e.g. Concept, WIP, etc, and breakdown of apps, etc, used.",
        status: "Idea",
        category: "Portfolio management",
    },
    {
        name: "Prefab import",
        description:
            "Import 1 or more prefabs into a model, and then download that model as a file to be edited, so that we can maximise the reuse of prefabs across designs",
        status: "Done",
        category: "Component reuse",
        completed: "05-May-2022",
    },
    {
        name: "Design Decisions",
        description:
            "Be able to capture design decisions, the options, and eventually morph it into defining the design decision with reference to the view(s) that show what the outcome is. This could be at various architecture layers. Initially, these start as a set of design questions, i.e. known unknowns, that undergo discussion and resolution of options, etc. These can be fast moving questions that need to be evolved quickly towards completing the design, and as such the project stakeholders and other observers will want to be notified of any changes to these design questions/decisions, and be able to understand the outcome, e.g. via various highlighting of 1 or more views",
        status: "WIP",
        category: "Model governance",
        notes: "Create a Project or Component story that explains the key decision and tag it with something like type='Design Decision'",
    },
    {
        name: "Auto Legend",
        description:
            "Automatically add a legend beneath each diagram with whatever elements have been used",
        status: "Done",
        category: "Model viewing",
        completed: "17-Apr-2022",
    },
    {
        name: "Heatmapping",
        description: "Allow heatmapping of views",
        status: "Done",
        category: "Stories",
        completed: "10-Oct-2022",
        notes: "Can be done in Story Edit page",
    },
    {
        name: "Story highlighting",
        description:
            "Highlight both elements and connectors in stories, and allow for different colors used in the same highlighting at once",
        status: "Idea",
        category: "Stories",
    },
    {
        name: "Element Search",
        description:
            "After selecting an element, show all views across all (indexed) projects and components where that element is used",
        status: "Done",
        category: "Portfolio management",
        completed: "10-Feb-2022",
        notes: "Achieved in Project Explorer and Component Explorer. Can then click on elements to see the specific views that they are used in",
    },
    {
        name: "Stories grid",
        description:
            "Create a new page which is a list of stories across project and components, filterable by tag/label",
        status: "Done",
        category: "Stories",
        completed: "10-Oct-2022",
        notes: "Provided via new Stories page",
    },
    {
        name: "Publishable components",
        description:
            "Let people publish components to be viewable by others. Make it very clear that a component has been shared. Allow people to download it and see who the author is",
        status: "Idea",
        category: "Components",
    },
    {
        name: "Show View and Element Properties",
        description: "Show properties of a view and element",
        status: "Idea",
        category: "Model viewing",
    },
    {
        name: "View similarity v1",
        description:
            "Be able to compare views, and highlight similar views in the same model and across models",
        status: "Done",
        category: "Model viewing",
        notes: "Works currently for any views that you have opened. Does not find any views that have not been opened/in-cache",
        completed: "8-May-2022",
    },
    {
        name: "View similarity v2",
        description:
            "Be able to compare views, and highlight similar views in the same model and across models -- across all models, not just as per the v1 implementation which only works if you have previously opened the model and it is in cache",
        status: "Idea",
        category: "Model viewing",
    },
    {
        name: "Blog",
        description:
            "Allow blogs to be created for projects and components so that people can follow along and common on the progress of project or component development",
        status: "Idea",
        category: "Collaboration",
    },
    {
        name: "Linkage to external document repository",
        description:
            "capture base url in archi model that you could link to an external document repository",
        status: "Idea",
        category: "Model governance",
    },
    {
        name: "Component Technical Debt",
        description:
            "Be able to track component technical debt through creating stories and tagging the story with 'Technical Debt'",
        status: "Done",
        category: "Stories",
        notes: "Needs the /Stories page to be created and ability to find all technical debt stories across all projects and components",
        completed: "2-Jul-2022",
    },
    {
        name: "Data samples",
        description:
            "Allow data snippets to be uploaded for business objects and data objects, to enable understanding the top level structure of data-at-rest and data-in-transit",
        status: "Idea",
        category: "Information definitions",
    },
    {
        name: "Master Data and Lifecycle",
        description:
            "Be able to query what data is created, consumed, and exchanged across the IT platform across multiple components",
        status: "Idea",
        category: "Information definitions",
    },
    {
        name: "Model review and comments",
        description:
            "Enter and review comments entered against views and individual model elements. Have the comments linked to the model element UID, so that comments remain linked even across incremental updates to models, e.g. file v0.1, file v0.2. Allow for a range of comments to be entered and track their closure status",
        status: "Idea",
        category: "Collaboration",
    },
    {
        name: "Auto View Identification",
        description:
            "Automatically label the view type depending on the composition of elements. Identify views that are lacking, e.g. missing Goals and Drivers, missing Information Architecture",
        status: "Idea",
    },
    {
        name: "Assurance question input",
        description:
            "Allow for all assurance groups to collaborate into defining questions, and then going through assurance, e.g. infosec, regulatory, legal. Enable each question to be defined as mandatory, optional, etc, and be able to close each question as it is covered, so you know what's left to assure. This is intended to help reduce duplication across assurance teams",
        status: "Idea",
    },
    {
        name: "Assurance socialization",
        description:
            "Streamline assurance. Make information available to the various assurance groups in a way that means the team isn't spending all their time doing assurance, e.g. InfoSec, privacy, infrastructure, architecture governance, etc",
        status: "Idea",
    },
    {
        name: "Platform",
        description:
            "Make the aim web app a platform/ecosystem that various stakeholders can plug into via APIs",
        status: "Idea",
        category: "Platform",
    },
    {
        name: "Agile",
        description:
            "Improve how ArchiMate Work Packages and Deliverables can be aligned to SAFe, e.g. some common id to link the Work Package to a SAFe ticket.",
        status: "Idea",
    },
    {
        name: "Icons overlaid on diagrams",
        description:
            "Be able to overlay icons on top of diagrams to convey more info, e.g. email icon",
        status: "Idea",
        category: "Model viewing",
    },
    {
        name: "Architecture Endorsement",
        description:
            "Capturing endorsement of the architecture at each gate by governance decision makers. Treat this as a process/conversation",
        status: "Idea",
        catgegory: "Assurance",
    },
    {
        name: "Project Shaping",
        description:
            "Capture an initial set of feedback for an initiative to help with project shaping. This would be aligned to the organisation's value chain, mapping to operational core vs digital components, and which parts of the solution are plug-n-play/substitutable",
        status: "Idea",
        category: "Projects",
    },
    {
        name: "Prefab criteria",
        description:
            "Provide selection criteria on when a prefab should be used, so that the Architect can better determine when to import it into their project design",
        status: "Idea",
        category: "Components",
    },
    {
        name: "Dynamic filters",
        description:
            "Provide ability to filter/shade/overlay diagrams based on various rules, incl. validation rules, and against key/value pairs of elements or the whole view",
        status: "Idea",
        category: "Model viewing",
    },
    {
        name: "Auto Label",
        description:
            "Automatically label flows and elements, e.g. 'write' or 'read' on an access connector or label each connector with its type, e.g. Triggering, etc. This is useful for those that don't have a strong knowledge of the ArchiMate language",
        status: "Idea",
        category: "Model viewing",
    },
    {
        name: "Vendor Components",
        description:
            "Obtain designs for vendor components that organisation can import, e.g. SAP, Oracle, Service Now, Mulesoft, etc.",
        status: "Idea",
        category: "Platform",
    },
    {
        name: "Standup Status",
        description:
            "Provide weekly status updates for projects on the main activities that are occurring from an Architecture development perspective",
        status: "Idea",
        category: "Projects",
    },
    {
        name: "Online Project Architecture documentation",
        description:
            "Be able to write up an online Architecture at any level via markdown with reference to the project architecture files/views and any referenced components/prefabs, so that you can interweave links to views and other renderings, e.g. work packages / work breakdown structure completely online, without having to extract into a word doc. Enable these templates to be saved so multiple projects can be rendered with the same template",
        status: "Idea",
        category: "Projects",
    },
    {
        name: "View Tagging",
        description:
            "Have user-definable view tags, #app, #WBS, etc, and allow the user to tag views. Be able to search and filter by these view tags",
        status: "WIP",
        category: "Search",
        notes: "Can currently tag views with tags, but not searchable in Project Explorer and Component Explorer",
    },
    {
        name: "Design Completion",
        description:
            "Provide various pages that each help complete an aspect of the design, e.g. op model, WBS, list of information flow interfaces (extracted from [INT-nn] tags in flow connectors, app arch, etc. Provide an over arching completion status % and score based on the lifecycle stage of the project, e.g. tech layer doesn't need to be complete if we've just started the project. Be able to associate different rules and checks to a view type, in order to help get each type of view completed, e.g. op model view, conceptual info model view, app arch view, WBS view, etc",
        status: "Idea",
        category: "Design Completion",
    },
    {
        name: "Estimation",
        description:
            "Be able to estimate against work packages and deliverables in terms of effort and elapsed. This can then be extracted to go into a full project plan that adds indirect activities",
        status: "Idea",
        category: "Project Estimation",
    },
    {
        name: "Info Samples",
        description: "Be able to enter JSON data representations of information",
        status: "Idea",
        category: "Information Design",
    },
    {
        name: "Story Colours",
        description:
            "Be able to use different highlight palettes for stories, e.g. RAG colours, etc",
        status: "Done",
        category: "Stories",
        completed: "10-Oct-2022",
        notes: "Limited palettes provided in Story Edit",
    },
    {
        name: "Identifying extraneous model elements",
        description:
            "Be able to tag what views are authoritative, so that any other links or elements on other views can be identified, and either deleted, e.g. if you have view 1 and view 2, and view 1 is authoritative you want to know what additional items are on view 2",
        status: "Idea",
        category: "Model quality",
    },
    {
        name: "Cyber Review",
        description:
            "Highlight specific elements for review by Cyber, incl. data-in-transit, data-at-rest, information security classifications",
        status: "Idea",
        category: "Security",
    },
    {
        name: "Properties",
        description:
            "Read in and display element properties, incl. links to other systems. Allow URLs to be attached to views and elements",
        status: "Idea",
        category: "Model viewing",
    },
    {
        name: "Acceptance Criteria",
        description:
            "Add acceptance criteria to the model and model element, at bus, app, tech layers, incl. security",
        status: "Idea",
        category: "Assurance",
    },
    {
        name: "Time and Cost estimation",
        description:
            "Be able to add work packages and deliverables against a project, and draw in time/cost estimates from linked components. Have a pre-defined set of categories, e.g. design, build, integrate, etc",
        status: "Idea",
        category: "Project Estimation",
    },
    {
        name: "Product Mapping",
        description:
            "Map products to 1 or more models, so that you can see how a product is realized. Be able to cater to changes in the underlying models",
        status: "Idea",
        category: "Products",
    },
    {
        name: "View Questions / Social",
        description:
            "Be able to define questions against a view, so that people can search against these, e.g. 'How does invoicing work', 'What is our CRM?'. Also allow searching against #tags, that can be tagged against views and elements",
        status: "Idea",
        category: "Stories",
        notes: "Stories are implemented for Projects and Components, but needs more work on Searching",
    },
    {
        name: "Choose your own adventure",
        description: "Allow linkages to other stories from within a story",
        status: "Idea",
        category: "Stories",
    },
    {
        name: "Color coding scenarios",
        description:
            "Be able to color code bus and app layer diagrams interfaces based on the business scenario that they belong to, can be based on properties in the model, e.g. ordering = blue, payments = green",
        status: "Idea",
        category: "Model viewing",
    },
    {
        name: "Custom View Filters",
        description:
            "Allow for custom filters to be defined for each view, and then applied to the view",
        status: "Idea",
        category: "Model Stories",
    },
    {
        name: "Auto-view tagging",
        description:
            "Be able to automatically suggest tags for views based on existing view tags, e.g. Domain, Level of Detail, Architecture Layer, Type -- or whatever tags the user has used",
        status: "Idea",
        notes: "ML use-case",
    },
    {
        name: "View validation",
        description:
            "Be able to indicate which views might be incomplete based on missing elements, or missing connectors",
        status: "Idea",
        category: "Model quality",
        notes: "ML use-case",
    },
    {
        name: "Motivation summary",
        description:
            "Be able to summarize the motivation of a view, e.g. explain it like i'm 5, explain it as a black comedy, explain it as a story, etc",
        status: "Idea",
        notes: "ML use-case",
    },
    {
        name: "Operating Model Validation",
        description:
            "Validate the flow of information through an op model diagram, i.e. functions have inputs and outputs",
        status: "Idea",
        category: "Model quality",
    },
    {
        name: "Model node and edge recommendations",
        description: "Validate that a node or edge is the correct type based on other models",
        status: "Done",
        category: "Model quality",
        completed: "16-Apr-2022",
    },
    {
        name: "'Its not Visio' rule",
        description:
            "Check connectors between layers, i.e. check if someone puts flows between app => tech layer",
        status: "Done",
        category: "Model quality",
        completed: "16-Apr-2022",
        notes: "This isn't a specific feature, but can be achieved by creating a series of Rules. For example, in seeking to avoid connections that go from the Business layer to the Application layer you would create a rule with Source = all Business layer elements, and Target = all Application layer elements. You would similarly create another rule from Application layer to Technology layer. The Connectors selection for each rule would include all rules except perhaps Association",
    },
    {
        name: "Application recommendation",
        description:
            "Identify the key applications and technologies to use based on the business layer",
        status: "Idea",
        category: "Projects",
    },
    {
        name: "Story portability across model versions",
        description:
            "Ensure that stories created in v1 of a model file work when the architect uploads v2 of the model",
        status: "Idea",
        category: "Stories",
    },
    {
        name: "DOCX extractor",
        description:
            "Be able to extract DOCX fragments of things like application components, interfaces, etc. that can be copy/pasted into a Solution Architecture doc",
        status: "Idea",
        category: "Model documentation",
    },
    {
        name: "Project/Component export",
        description:
            "Ability to download/upload zip files that contain the model file and AIM JSON data as a package, ala package manager",
        status: "Idea",
        category: "Model management",
    },
    {
        name: "Pin models",
        description:
            "Be able to select models that always appear at the top of the project/component grid, or explorer view",
        status: "Idea",
        category: "Search",
    },
    {
        name: "LOD validation",
        description:
            "Be able to validate that a model aligns to LOD requirements, perhaps via the current rules engine",
        status: "Idea",
        category: "Model quality",
    },
    {
        name: "LOD integration",
        description:
            "auto set LOD tag, and add LOD as an attribute to ML training and edge/node prediction",
        status: "Idea",
        category: "Model quality",
    },
    {
        name: "Decision Support",
        description:
            "Provide a way to enter questions and the email address of those responsible to help answer, and also have an open/closed status so you can track the many in-flight questions. This should also be a thread that people can join. The purpose is to avoid dropping the ball on clarifying questions",
        status: "Idea",
        category: "Assurance",
    },
    {
        name: "Tech Debt per Component",
        description:
            "Be able to tag components with a tech debt value, and then have a view that shows the top components based on the tech debt value",
        status: "Idea",
        category: "Technical Debt",
        completed: "10-Oct-2022",
        notes: "Can be achieved by creating a Story and adding a tag where type = 'Tech Debt' and value = 'High', 'Medium', 'Low'",
    },
    {
        name: "Diff models (for comparison)",
        description: "Use red, amber, green to indicate differences between models",
        status: "Idea",
        category: "Model comparison",
    },
    {
        name: "Show what changed",
        description:
            "Make it easy to see what changed, incl. projects, components, stories, (and their scores), etc, so that people can keep up to date and offer feedback",
        status: "Idea",
        category: "Learning loop",
    },
    {
        name: "Fragment rules",
        description:
            "Be able to upload Architecture fragment(s) to a rule, and require that a model matches the fragment(s)",
        status: "Idea",
        category: "Model quality",
        notes: "This might be an easier and more graphical way for people to specify how model should be preferably constructed",
    },
    {
        name: "Model Scoring",
        description:
            "Be able to score models based on a set of rules. Don't reward larger models. Reward alignment to rules",
        status: "Idea",
        category: "Learning loop",
    },
    {
        name: "Story layout",
        description:
            "Optional (via switch) overlay the current story item text as a semi-transparent text atop the model near the selected element(s) so that its easier to follow the story",
        status: "Idea",
        category: "Stories",
    },
    {
        name: "Design Decision Progress",
        description:
            "Be able to track WIP design decisions, where they're up to, who has the ball, and the history of how it has evolved, and whether it seems to be taking too long to resolve. Capture when the design decision was resolved, and what the resolution was. Allow the design decision to be linked to 1 or more stories, so that the resolution can ultimately be captured as a Story",
        status: "Idea",
        category: "Learning loop",
    },
    {
        name: "BPMN import",
        description: "Be able to import BPMN files and convert to ArchiMate equivalent",
        status: "Idea",
        category: "Model management",
        notes: "BPMN is a 1-trick pony, and does a terrible job of depicting information lifecycles, and so it's better that 'process flows' are also in ArchiMate notation.",
    },
    {
        name: "Links and nested components",
        description:
            "Be able to link elements components. This can be related components, or enable nested components. Be able to label the link",
        status: "Idea",
        category: "Model navigation",
    },
    {
        name: "Element description updates",
        description:
            "Provide a way for changes to element labels, descriptions, and properties to be modified in AIM, and then batch-updated into a new version of the model file",
        status: "Idea",
        category: "Model management",
        notes: "The idea is to enable broad review and update of model information, without the actual model structure/layout being changed",
    },
    {
        name: "Since you've been gone",
        description: "List new features added since each user last visited",
        status: "Idea",
        category: "Learning loop",
    },
    {
        name: "Enterprise Model Consistency",
        description:
            "Be able to tag models as authoritative, to use as a filter in Explorer to identfy where other models were not consistent with naming, i.e. show other model elements in green or red depending on if they align to the authoritative models",
        status: "Idea",
        category: "Model quality",
    },
    {
        name: "Review element name consistency",
        description:
            "Add a section at the end of the Explorer page (Project + Component) to show selected elements and the models that they are used in, so that you can identify inconsistent naming",
        status: "Done",
        completed: "10-Jul-2022",
    },
    {
        name: "Extend machine learning to do node prediction",
        description:
            "ArchiBot currently provides edge (connector) predictions, but can also predict node (component) predictions",
        status: "Done",
        completed: "10-Jul-2022",
    },
    {
        name: "Grey out non-selected view elements in stories for higher contrast",
        description:
            "Be able to grey out all non-selected story elements in a view, so its easier to see what elements are highlighted",
        status: "Done",
        completed: "5-Nov-2022",
        notes: "Can be achieved in Story Edit page",
    },
    {
        name: "Architecture Overview",
        description:
            "Be able to assemble a custom view of the Architecture, containing elements, stories, and other custom elements to simplify socializataion. Have some template/builders that can automatically put together some versions of these pages",
        status: "Idea",
        category: "Socialsation",
    },
    {
        name: "Slide format",
        description:
            "Format the project, component, and story page like a slide when not editing. Show fields in read only mode unless editing",
        status: "Done",
        category: "UI",
        completed: "15-Nov-2022",
        notes: "Added an Edit button to the top right of the page to enable editing",
    },
    {
        name: "Story property shader",
        description:
            "Provide a property driven shader and auto detect if a view has properties - show the user so they can select the property type and then also show the available values to assign a colour",
        status: "Idea",
        category: "Stories",
    },
    {
        name: "Story numbering",
        description:
            "For stories be able to overlay numbers in circles on the selected elements so that you can see what text applies to what elements all at once",
        status: "Done",
        category: "Stories",
        completed: "15-Nov-2022",
        notes: "Added a Story Numbering button display option that can be toggled on/off",
    },
    {
        name: "Duplication detection",
        description: "Detect when 2 projects might have overlap",
        status: "Idea",
        category: "Assurance",
    },
    {
        name: "Community based model feedback",
        description:
            "Allow cross-account secured sharing of selected models, to obtain community feedback",
        status: "Idea",
        category: "Community",
    },
    {
        name: "Architect scoring",
        description:
            "Be able to score an Architect's models based on a set of rules. Allow Architects to opt-in to sharing their high score",
        status: "Idea",
        category: "Learning loop",
        notes: "Scoring can be for projects, components, no. of stories, etc",
    },
    {
        name: "Missing component references",
        description:
            "Find project elements that do exist in components but where the component is not linked into the project",
        status: "Idea",
        category: "Assurance",
    },
    {
        name: "Missing components",
        description:
            "Compare project vs component indexes for project elements that do not appear in components",
        status: "Idea",
        category: "Components",
    },
    {
        name: "Compare application components names between projects and components",
        description: "Make sure app names are consistent by comparing project vs component indexes",
        status: "Idea",
        category: "Assurance",
    },
]

export { features }
