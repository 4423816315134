import React, { useState, useEffect, useRef } from "react"
import { Switch, Route, HashRouter } from "react-router-dom"
import "fontsource-roboto"
import "./index.css"
import SignIn from "./pages/SignIn"
import SignOut from "./pages/SignOut"
import SignUp from "./pages/SignUp"
import Dashboard from "./pages/Dashboard"
import ProfilePage from "./pages/ProfilePage"
import Invites from "./pages/Invites"
import AccountEdit from "./pages/AccountEdit"
import UserFind from "./pages/UserFind"
import UserEdit from "./pages/UserEdit"
import ComponentFind from "./pages/ComponentFind"
import ComponentEdit from "./pages/ComponentEdit"
import firebase from "firebase/compat/app"
import ProtectedRoute from "./ProtectedRoute"
import Billing from "./pages/Billing"
import ProjectEdit from "./pages/ProjectEdit"
import Print from "./pages/Print"
import ViewEdit from "./pages/ViewEdit"
import Projects from "./pages/Projects"
import ViewSets from "./pages/ViewSets"
import ElementView from "./pages/ElementView"
import ElementListView from "./pages/ElementListView"
import JobsToBeDone from "./pages/JobsToBeDone"
import Accounts from "./pages/Accounts"
import ProjectExplorer from "./pages/ProjectExplorer"
import ComponentExplorer from "./pages/ComponentExplorer"
import UserGuide from "./pages/UserGuide"
import StoryEdit from "./pages/StoryEdit"
import RuleEdit from "./pages/RuleEdit"
import RuleFind from "./pages/RuleFind"
import ML from "./pages/ML"
import QrTest from "./pages/QrTest"
import Stories from "./pages/Stories"
import Prompts from "./pages/Prompts"
import * as userServices from "./pages/services/userServices"
import ModelEdit from "./pages/ModelEdit"
import PromptEdit from "./pages/PromptEdit"
import StoryPrompts from "./pages/StoryPrompts"
import StoryPromptEdit from "./pages/StoryPromptEdit"
import db from "./Firestore"
import { obtainCustomClaims } from "./pages/services/cloudFunctions"
import { useSelector } from "react-redux"
import { selectElementDefinitions } from "./redux/selectors"
import { useDispatch } from "react-redux"
import { setElementDefinitions } from "./redux/actions"
import _ from "lodash"
import ElementPrompts from "./pages/ElementPrompts"
import ElementPromptEdit from "./pages/ElementPromptEdit"
import Templates from "./pages/Templates"
import TemplateEdit from "./pages/TemplateEdit"
import VectorStoreEdit from "./pages/VectorStoreEdit"
import VectorStores from "./pages/VectorStores"
import {
  collection,
  deleteDoc,
  getCountFromServer,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore"

const AppMenu = (props) => {
  const [isSystem, setSystem] = useState(false)

  const [roles, setRoles] = useState([])

  const [uid, setUid] = useState()

  const [accountId, setAccountId] = useState()

  const elementTypes = useSelector(selectElementDefinitions)

  const prevElementTypesRef = useRef()

  const dispatch = useDispatch()

  useEffect(() => {
    const unsub = firebase.auth().onAuthStateChanged((user) => {
      if (user !== null) {
        user.getIdTokenResult(true).then((token) => {
          setUid(token.claims.user_id)
          setSystem(token.claims.system_role === true)

          console.log("%cuid", "color:orange", { token, user })

          if (token.claims.roles) {
            setRoles(token.claims.roles)
          }

          if (token.claims.account_id) {
            setAccountId(token.claims.account_id)
          }

          const uid = user.uid
          userServices.logUserAccess(user.email, token.claims.account_id, uid)
        })
      }
    })

    return unsub
  }, [])

  const initElementPrompts = async (accountId) => {
    const countElements = query(
      collection(db, "elements"),
      where("account_id", "==", accountId)
    )
    console.log("query", query, accountId)
    getCountFromServer(countElements).then(async (result) => {
      const count = result.data().count
      console.log("count", count)
      // assume most people haven't got more than 10 prompts
      //if (count < 10) {
      console.log("element prompts not initialised")
      // Take copy of all 'REF' elements and create new elements with account_id === accountId
      // but do not override any prompts they have created based on the element 'type'

      const refElementPromptsQuery = getElementPromptsQuery("REF")
      const accountElementPromptsQuery = getElementPromptsQuery(accountId)

      // Retrieve all 'REF' element prompts

      const refElementPrompts = await getDocs(refElementPromptsQuery).then(
        (querySnapshot) => {
          const elements = []
          querySnapshot.forEach((doc) => {
            elements.push({ id: doc.id, ...doc.data() })
          })
          return elements
        }
      )

      // Retrieve all account element prompts

      const accountElementPrompts = await getDocs(
        accountElementPromptsQuery
      ).then((querySnapshot) => {
        const elements = []
        querySnapshot.forEach((doc) => {
          elements.push({ id: doc.id, ...doc.data() })
        })
        return elements
      })

      console.log("REF prompts vs account prompts", {
        refElementPrompts,
        accountElementPrompts,
      })

      // Create a new element prompt for each 'REF' element prompt that does not already exist in the account

      refElementPrompts.forEach((refElementPrompt) => {
        const exists = accountElementPrompts.some(
          (accountElementPrompt) =>
            accountElementPrompt.type === refElementPrompt.type
        )

        if (!exists) {
          const { id, ...rest } = refElementPrompt
          const newElementPrompt = {
            ...rest,
            account_id: accountId,
            created: serverTimestamp(),
            modified: serverTimestamp(),
          }

          console.log("new element prompt", newElementPrompt)

          db.collection("elements").add(newElementPrompt)
        }
      })
      //}
    })
  }

  // Check if element promots are initialised and if not initialise them
  // by copying from the element definitions where account_id === 'REF'

  useEffect(() => {
    if (accountId) {
      initElementPrompts(accountId)

      // DEBUG - delete all element prompts with my account id
      // const elementsCollection = collection(db, "elements")
      // const elementsQuery = query(
      //   elementsCollection,
      //   where("account_id", "==", accountId)
      // )

      // getDocs(elementsQuery).then((querySnapshot) => {
      //   querySnapshot.forEach((doc) => {
      //     deleteDoc(doc.ref)
      //   })
      // })
    }
  }, [accountId])

  const getElementPromptsQuery = (accountId) => {
    const elementsCollection = collection(db, "elements")
    const elementsQuery = query(
      elementsCollection,
      where("account_id", "==", accountId)
    )
    return elementsQuery
  }

  // Listen for changes to elements
  // Do NOT include elementTypes in the useEffect variable list since
  // this is not required, and sends the useEffect into an infinite loop

  useEffect(() => {
    if (!accountId) return

    // Create a query against the collection.
    const elementsQuery = getElementPromptsQuery(accountId)

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(elementsQuery, (querySnapshot) => {
      const newElementTypes = []

      querySnapshot.docChanges().forEach((change) => {
        const changedItem = { id: change.doc.id, ...change.doc.data() }

        console.log(
          "%cchange",
          "color:yellow",
          change.doc.id,
          change.type,
          changedItem
        )

        // Find the index of the changed item in the newElementTypes array
        const index = newElementTypes.findIndex(
          (element) => element.id === changedItem.id
        )

        // If the item exists, remove it
        if (index !== -1) {
          newElementTypes.splice(index, 1)
        }

        // Add or modify the item based on the change type
        if (change.type === "added" || change.type === "modified") {
          newElementTypes.push(changedItem)
        }
      })

      // Prepare the "before" state by removing 'created' and 'modified' fields
      const before = (
        prevElementTypesRef.current?.map(
          ({ created, modified, ...rest }) => rest
        ) || []
      ).sort((a, b) => a.id.localeCompare(b.id))

      // Prepare the "after" state similarly
      const after = newElementTypes
        .map(({ created, modified, ...rest }) => rest)
        .sort((a, b) => a.id.localeCompare(b.id))

      // Compare the before and after states using lodash's isEqual
      if (!_.isEqual(before, after)) {
        dispatch(setElementDefinitions(newElementTypes))
        prevElementTypesRef.current = newElementTypes
      } else {
        console.log("%cno change", "color:lightblue")
      }
    })

    // Cleanup subscription on unmount or when accountId changes
    return () => unsubscribe()
  }, [accountId, dispatch])

  useEffect(() => {
    if (!uid) {
      return
    }

    const unsub = db
      .collection("users")
      .doc(uid)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data()
          console.log("%cuser changed, update claims", "color:pink")
          obtainCustomClaims()
        }
      })

    return unsub
  }, [uid])

  const isSystemRole = () => isSystem

  const isRole = (roleName) => {
    //console.log("is role?", roleName, roles)
    return roles.includes(roleName)
  }

  const isComponentRole = () => isRole("comp_user") || isRole("comp_admin")

  const isProjectRole = () => isRole("project_admin") || isRole("project_user")

  const isEARole = () => isRole("ea_admin") || isRole("ea_user")

  const isAdminRole = () => isRole("admin")

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={SignIn} allowAccess={true} />

        <Route path="/SignIn" component={SignIn} allowAccess={true} />

        <Route path="/QrTest" component={QrTest} allowAccess={true} />

        <Route path="/SignOut" component={SignOut} allowAccess={true} />

        <Route path="/SignUp" component={SignUp} allowAccess={true} />

        <Route path="/UserGuide" component={UserGuide} allowAccess={true} />

        <ProtectedRoute
          path="/dashboard"
          component={Dashboard}
          allowAccess={true}
        />

        <ProtectedRoute
          path="/billing/"
          component={Billing}
          allowAccess={() => isAdminRole()}
        />

        <ProtectedRoute
          path="/billing/:id"
          component={Billing}
          allowAccess={() => isAdminRole()}
        />

        <ProtectedRoute
          path="/profile"
          component={ProfilePage}
          allowAccess={true}
        />

        <ProtectedRoute
          path="/print/:type/:parentId"
          component={Print}
          allowAccess={isProjectRole()}
        />

        <ProtectedRoute
          path="/project/:id"
          component={ProjectEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/project"
          component={ProjectEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/projects"
          component={Projects}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/viewsets"
          component={ViewSets}
          allowAccess={() => isEARole()}
        />

        <ProtectedRoute
          path="/modeledit/:id"
          component={ModelEdit}
          allowAccess={() => isEARole()}
        />

        <ProtectedRoute
          path="/story/:id"
          component={StoryEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/story"
          component={StoryEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/stories"
          component={Stories}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/prompt/:id"
          component={PromptEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/prompt"
          component={PromptEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/prompts"
          component={Prompts}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/storyprompt/:id"
          component={StoryPromptEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/storyprompt"
          component={StoryPromptEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/storyprompts"
          component={StoryPrompts}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/elementprompt/:id"
          component={ElementPromptEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/elementprompt"
          component={ElementPromptEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/elementprompts"
          component={ElementPrompts}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/template/:id"
          component={TemplateEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/template"
          component={TemplateEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/templates"
          component={Templates}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/vectorstore/:id"
          component={VectorStoreEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/vectorstore"
          component={VectorStoreEdit}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/vectorstores"
          component={VectorStores}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/ProjectExplorer"
          component={ProjectExplorer}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/ComponentExplorer"
          component={ComponentExplorer}
          allowAccess={() => isProjectRole()}
        />

        <Route
          path="/JobsToBeDone"
          component={JobsToBeDone}
          allowAccess={true}
        />

        {/* <ProtectedRoute
                    path="/component/:id"
                    component={ComponentEdit}
                    allowAccess={() => isComponentRole()}
                />

                <ProtectedRoute
                    path="/component"
                    component={ComponentEdit}
                    allowAccess={() => isComponentRole()}
                /> */}

        <ProtectedRoute
          path="/ml"
          component={ML}
          allowAccess={() => isProjectRole()}
        />

        <ProtectedRoute
          path="/viewedit/:parentId/view/:viewId/fileName/:fileName"
          component={ViewEdit}
          allowAccess={true}
        />

        {/* Could be viewing an element from either a Component or Project */}
        <ProtectedRoute
          path="/elementview/:parentId/fileName/:fileName/elementId/:elementId"
          component={ElementView}
          allowAccess={() => isComponentRole() || isProjectRole()}
        />

        <ProtectedRoute
          path="/elementlist/:parentId/fileName/:fileName/type/:type"
          component={ElementListView}
          allowAccess={() => isComponentRole() || isProjectRole()}
        />

        {/* <ProtectedRoute
                    path="/projects"
                    component={ComponentFind}
                    allowAccess={() => isComponentRole()}
                /> */}

        {/* Edit a user */}
        <ProtectedRoute
          path="/useredit/:id"
          component={UserEdit}
          allowAccess={() => isAdminRole()}
        />

        <ProtectedRoute
          path="/users"
          component={UserFind}
          allowAccess={() => isAdminRole()}
        />

        {/* Edit a rule */}
        <ProtectedRoute
          path="/ruleedit/:id"
          component={RuleEdit}
          allowAccess={() => isAdminRole()}
        />

        {/* Create a new rule */}
        <ProtectedRoute
          path="/ruleedit/"
          component={RuleEdit}
          allowAccess={() => isAdminRole()}
        />

        <ProtectedRoute
          path="/rules"
          component={RuleFind}
          allowAccess={() => isAdminRole()}
        />

        <ProtectedRoute
          path="/invites"
          component={Invites}
          allowAccess={() => isAdminRole()}
        />

        <ProtectedRoute
          path="/accountedit/:id"
          component={AccountEdit}
          allowAccess={() => isSystemRole()}
        />

        <ProtectedRoute
          path="/accountedit"
          component={AccountEdit}
          allowAccess={() => isSystemRole()}
        />

        <ProtectedRoute
          path="/myaccount"
          component={AccountEdit}
          allowAccess={true}
        />

        <ProtectedRoute
          path="/accounts"
          component={Accounts}
          allowAccess={() => isSystemRole()}
        />

        {/* Edit a component */}
        <ProtectedRoute
          path="/component/:id"
          component={ComponentEdit}
          allowAccess={() => isComponentRole()}
        />

        {/* Create a new component */}
        <ProtectedRoute
          path="/component"
          component={ComponentEdit}
          allowAccess={() => isComponentRole()}
        />

        <ProtectedRoute
          path="/components"
          component={ComponentFind}
          allowAccess={() => isComponentRole()}
        />
      </Switch>
    </HashRouter>
  )
}

export default AppMenu
