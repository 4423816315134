import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  listAll,
  deleteObject,
} from "firebase/storage"

// Convert base64 into JavaScript file, so we can save it into firebase storage
const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

const getUrl = async (path, fileName) => {
  const fullPath = `${path}/${fileName}`
  const storageRef = ref(getStorage(), fullPath)
  try {
    const url = await getDownloadURL(storageRef)
    return url
  } catch (error) {
    console.error("%cError getting url", "color:red", { error, fullPath })
    return undefined
  }
}

const imageMimeTypes = {
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  png: "image/png",
  gif: "image/gif",
  bmp: "image/bmp",
  tiff: "image/tiff",
  tif: "image/tiff",
  svg: "image/svg+xml",
  ico: "image/x-icon",
  webp: "image/webp",
}

const getMimeType = (fileName) => {
  const fileExtension = fileName.split(".").pop().toLowerCase()
  return imageMimeTypes[fileExtension] || "application/octet-stream"
}

const deleteFile = async (path) => {
  console.log("%cdelete file", "color:orange", { path })
  const storageRef = ref(getStorage(), path)
  await deleteObject(storageRef)
}

const uploadFiles = async (files, filePath) => {
  for (const file of files) {
    const storageRef = ref(getStorage(), `${filePath}/${file.name}`)
    await uploadBytes(storageRef, file)
  }
}

export { dataURLtoFile, uploadFiles, deleteFile, getUrl, getMimeType }
