import React, { useState } from "react"
import Header from "../components/Header"
import ElementPromptEditForm from "../components/ElementPromptEditForm"

const ElementPromptEdit = (props) => {
  const [title, setTitle] = useState("Create Element Prompt")

  return (
    <Header title={title}>
      <ElementPromptEditForm {...props} setTitle={setTitle} />
    </Header>
  )
}

export default ElementPromptEdit
