import { Box, Slider, Typography } from "@mui/material"
import { useMemo } from "react"
import { format } from "date-fns"
import * as colors from "@mui/material/colors"
import {
    addProjectChartAttributes,
    getElapsedMonths,
    getShaderColor,
    START_DATE_LOWER,
    END_DATE_LOWER,
} from "../pages/services/modelEditServices"

const ProjectPortfolio = (props) => {
    const { projects, elements, setElements, currentShader, portfolioDateRange } = props

    const workPackages = useMemo(
        () => addProjectChartAttributes(projects, portfolioDateRange),
        [projects, portfolioDateRange]
    )

    const handleSetStartAndEndDate = (elementId, values) => {
        const element = elements.find((x) => x.id === elementId)

        const hasStartDate = element.props.find((p) => p.name.toLowerCase() === START_DATE_LOWER)
        const hasEndDate = element.props.find((p) => p.name.toLowerCase() === END_DATE_LOWER)

        const indexes = []
        if (hasStartDate) {
            indexes.push("START")
        }
        if (hasEndDate) {
            indexes.push("END")
        }

        const startIndex = indexes.findIndex((item) => item === "START")
        const endIndex = indexes.findIndex((item) => item === "END")

        let startDateStr
        let endDateStr
        let startDateIndex
        let endDateIndex

        if (hasStartDate) {
            const startDate = new Date(values[startIndex])
            startDateStr = format(startDate, "dd-MMM-yyyy")
            startDateIndex = element.props.findIndex(
                (x) => x.name.toLowerCase() === START_DATE_LOWER
            )
        }

        if (hasEndDate) {
            const endDate = new Date(values[endIndex])
            endDateStr = format(endDate, "dd-MMM-yyyy")
            endDateIndex = element.props.findIndex((x) => x.name.toLowerCase() === END_DATE_LOWER)
        }

        const newProps = element.props.map((prop, index) => {
            if (index === startDateIndex) {
                return { ...prop, value: startDateStr }
            } else if (index === endDateIndex) {
                return { ...prop, value: endDateStr }
            } else {
                return prop
            }
        })

        const newElements = elements.map((x) => {
            if (x.id === elementId) {
                return { ...x, props: newProps }
            } else {
                return x
            }
        })

        setElements(newElements)
    }

    return (
        <Box>
            <Box sx={{ marginTop: "18px" }}>
                {workPackages.map((wp) => (
                    <Box key={wp.id} sx={{ display: "flex", flexDirection: "row", height: "60px" }}>
                        <Box
                            sx={{
                                width: "12px",
                                height: "20px",
                                marginRight: "6px",
                                marginTop: "2px",
                                backgroundColor: getShaderColor(wp, currentShader),
                            }}
                        />
                        <Box
                            sx={{
                                width: "300px",
                                marginRight: "30px",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                {wp.name}
                            </Typography>
                            <Typography variant="caption" sx={{ color: colors.grey[500] }}>
                                {getElapsedMonths(wp.startDate, wp.endDate)} months
                            </Typography>
                        </Box>

                        <Slider
                            min={portfolioDateRange.startDate}
                            max={portfolioDateRange.endDate}
                            onChange={(e, v) => {
                                handleSetStartAndEndDate(wp.id, v)
                            }}
                            value={wp.values}
                            marks={wp.marks}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(v) => {
                                return format(v, "dd-MMM-yyyy")
                            }}
                        />
                        <Box sx={{ marginRight: "40px" }} />
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

export default ProjectPortfolio
