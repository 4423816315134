import React from "react"
import * as common from "./common"

const BaseShapeOctagon = (props) => {
    const { x, y, height, width, fill } = props

    // We need to subtract 2 * r from both the height and width because both height and width each
    // have a radius (r) curve at each end, effectively adding 2 * r to each of the height and width.

    // corner length
    const c = 10

    return (
        <path
            d={`M${x + 1 + c},${y + 1} h${width - c * 2 - 1} l ${c} ${c} v ${
                height - c * 2
            } l ${-c} ${c} h -${width - c * 2 - 1} l ${-c} ${-c} v -${height - c * 2} z`}
            fill={fill}
            stroke={common.outlineColor}
        />
    )
}

export default BaseShapeOctagon
