import React, { useState } from "react"
import Header from "../components/Header"
import { Box } from "@mui/material"
import ElementViewForm from "../components/ElementViewForm"
import { spacing } from "../pages/services/styleServices"

const styles = {
    pageContent: {
        margin: spacing(1),
        padding: spacing(1),
    },
}

const ElementView = (props) => {
    const [title, setTitle] = useState("View Element")

    return (
        <Header title={title}>
            <Box sx={styles.pageContent}>
                <ElementViewForm {...props} setTitle={setTitle} />
            </Box>
        </Header>
    )
}

export default ElementView
