import React, { useState } from "react"
import {
  Box,
  Typography,
  CardHeader,
  Card,
  CardContent,
  Button,
} from "@mui/material"
import CreditCardIcon from "@mui/icons-material/CreditCard"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import {
  createStripeSetupIntent,
  setStripeCardAsDefault,
  createStripeCustomer,
} from "../pages/services/stripeServices"
import Controls from "./controls/Controls"
import { useSnackbar } from "notistack"
import { spacing } from "../pages/services/styleServices"

const styles = {
  pageContent: {
    marginTop: spacing(1),
    marginLeft: spacing(1),
    marginRight: spacing(2),
    padding: spacing(1),
    maxWidth: "400px",
  },
}

const StripeAddCard = (props) => {
  const { stripeCustId, accountId, setShowProgress, reload } = props

  const elements = useElements()

  const stripe = useStripe()

  const { enqueueSnackbar } = useSnackbar()

  const [nameOnCard, setNameOnCard] = useState("")

  const handleAddCard = async (event) => {
    event.preventDefault()

    if (nameOnCard === undefined || nameOnCard.trim() === "") {
      enqueueSnackbar("Please enter a name on the card", { variant: "error" })
      return
    }

    setShowProgress(true)

    console.log("handleAddCard", { stripeCustId, accountId })

    // Check if customer exists in Stripe. If not, create

    let stripeCustIdNewOrExisting = stripeCustId

    if (stripeCustId === "") {
      console.log("creating stripe customer")
      const createCustResult = await createStripeCustomer(accountId)
      console.log("created stripe customer", createCustResult)
      stripeCustIdNewOrExisting = createCustResult.stripeCustId
    }

    const cardElement = elements.getElement(CardElement)

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    })

    if (error) {
      console.log("[error]", error)

      enqueueSnackbar(error.message, { variant: "error" })
    } else {
      const setupIntentData = {
        customerId: stripeCustIdNewOrExisting,
        paymentMethodId: paymentMethod.id,
      }

      const setupIntent = await createStripeSetupIntent(setupIntentData)

      const cardSetupPayload = {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: nameOnCard,
          },
        },
      }

      const result = await stripe.confirmCardSetup(
        setupIntent.data.client_secret,
        cardSetupPayload
      )

      console.log("confirmed card setup", result)

      await setStripeCardAsDefault(
        result.setupIntent.payment_method,
        stripeCustIdNewOrExisting
      )

      enqueueSnackbar("Payment method added", { variant: "success" })
    }

    // Clear Stripe card element UI
    cardElement.clear()
    setNameOnCard("")

    await reload()
    setShowProgress(false)
  }

  return (
    <Card sx={styles.pageContent}>
      <CardHeader
        avatar={<CreditCardIcon />}
        disableTypography={true}
        title={
          <Typography variant="body2" component={"span"}>
            Add Payment Method
          </Typography>
        }
      />

      <CardContent>
        <form onSubmit={handleAddCard}>
          <CardElement
            id="stripe-card-element"
            options={{ hidePostalCode: true }}
          />

          <Box style={{ paddingTop: "15px" }}>
            <Controls.TextInput
              label="Name on card"
              name="name"
              value={nameOnCard}
              onChange={(event) => setNameOnCard(event.target.value)}
            />
          </Box>

          <Box sx={{ pt: "15px" }}>
            <Button variant="outlined" type="submit" disabled={!stripe}>
              Save
            </Button>
          </Box>
        </form>
      </CardContent>
    </Card>
  )
}

export default StripeAddCard
