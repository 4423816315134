import {
  addMessages,
  runAssistant,
  retrieveRunForStatus,
} from "./chatGenerationServices"
import { createChatPrompt } from "./chatPromptServices"
import { createThread, deleteThread, listMessages } from "./cloudFunctions"
import { createChatPromptData } from "./modelEditServices"
import * as palette from "../../components/symbols/palette"

const fillHeading = async ({
  templateInstance,
  viewSet,
  views,
  selectedHeading,
  selectedFileIds,
  selectedViewIds,
  assistants,
  contentGuidance,
}) => {
  // console.log("fill heading", {
  //   templateInstance,
  //   viewSet,
  //   views,
  //   selectedHeading,
  //   selectedFileIds,
  //   selectedViewIds,
  //   assistants,
  //   contentGuidance,
  // })

  const templateFillerAssistant = assistants.find(
    (assistant) => assistant.name === "AIM Content Filler"
  )

  // console.log("%cfiles/views", "color:orange", {
  //   selectedFileIds,
  //   selectedViewIds,
  // })

  let headingContent = null

  const threadResult = await createThread({
    messages: [],
  })

  const threadId = threadResult.data?.response?.id
  //console.log("threadId", { threadId })

  // Get input content organised, whether that is a file, views, or Archi models

  if (selectedViewIds.length > 0 || selectedFileIds.length > 0) {
    const msgs = [
      {
        type: "text",
        text: `We want to fill the '${selectedHeading.heading}' heading of the '${templateInstance.name}' template using selected content in the attached view(s) and/or files(s)`,
      },
      {
        type: "text",
        text:
          "Purpose of the heading you will populate is: " +
          selectedHeading.rationale,
      },
      {
        type: "text",
        text: "Please review the provided content and unless there is further guidance on the style, length, or any other aspects of how the response should be authored, write a high quality piece of text appropriate for the section you are filling. The text should be well structured and coherent. It should be written in a formal style and be free from spelling and grammatical errors.",
      },
      {
        type: "text",
        text: "Please stay within the bounds of the heading scope. There are other headings in the overall document, and this is just 1 section of the document.",
      },
      {
        type: "text",
        text: "RULE: YOU MUST NOT MAKE UP CONTENT IF IT DOES NOT EXIST IN INFORMATION PROVIDED TO YOU. IF YOU DO NOT HAVE ENOUGH INFORMATION TO WRITE A SECTION, YOU MUST SAY SO.",
      },
    ]

    // if (contentGuidance) {
    //   msgs.push({
    //     type: "text",
    //     text: `You must pay particular attention to this guidance, which should superceded any previous advice on the style, formatting, length, or any other aspect of the answer. The following instructions must be adhered to when preparing your response: ${contentGuidance}.`,
    //   })
    //   console.log("%cadded content guidance", "color:lightgreen", {
    //     contentGuidance,
    //   })
    // }

    msgs.push({
      type: "text",
      text: 'Just provide the answer without any supplementary information such as "The answer to this question is..." or "The question asks us to..." or "If you need any further info please ask". I just want the answer, not any other information.',
    })

    msgs.push({
      type: "text",
      text: "Also do not provide any information at the end of your response which explains what you just provied. Just provide the answer and nothing else.",
    })

    await addMessages({
      threadId,
      content: msgs,
    })
  }

  if (selectedViewIds.length > 0) {
    const selectedViews = views.filter((view) =>
      selectedViewIds.find((selectedView) => selectedView.id === view.id)
    )

    // console.log("%cselected views", "color:orange", {
    //   selectedViews,
    //   views,
    //   selectedViewIds,
    // })

    const messages = [
      {
        type: "text",
        text: `The views selected to fill the section are as follows, where each view begins with an ID attribute:`,
      },
      ...selectedViews.flatMap((view) => {
        const chatPromptData = createChatPromptData({
          currentView: view,
          includeDoc: true,
        })

        //console.log("%cchatPromptData", "color:orange", { chatPromptData })

        const prompts = createChatPrompt({
          promptData: chatPromptData,
          // All layers
          promptLayers: palette.LAYERS.map((layer) => layer.name),
          includeProperties: true,
          includeDoco: false,
          includeIds: false,
        })

        //console.log("%cprompts", "color:orange", { prompts })

        const viewResult = [
          { type: "text", text: `ID: ${view.id}` },
          { type: "text", text: `View: ${view.name}` },
          { type: "text", text: `Description: ${view.description}` },
          {
            type: "text",
            text: `Importance: ${
              view.importance || "Importance not defined for this view"
            }`,
          },
          {
            type: "text",
            text: `Question: ${view.question || "No question was provided"}`,
          },
          {
            type: "text",
            text: `Summary of view as follows:`,
          },
          ...prompts.map((prompt) => ({
            type: "text",
            text: prompt,
          })),
        ]

        if (view.text_response) {
          viewResult.push({
            type: "text",
            text: `The extra text information accompanying this view that you can use is: ${view.text_response}`,
          })
        }

        return viewResult
      }),
      {
        type: "text",
        text: "That is the end of the list of views selected to fill the section.",
      },
    ]

    //console.log("%cmessage", "color:orange", { messages })

    await addMessages({
      threadId,
      content: messages,
    })
  }

  if (selectedFileIds.length > 0) {
    await addMessages({
      threadId,
      content: [
        {
          type: "text",
          text: `We want to fill the '${selectedHeading.heading}' heading of the '${templateInstance.name}' template using selected content in the attached file(s)`,
        },
      ],
      attachments: selectedFileIds.map((file) => ({
        file_id: file.id,
        tools: [{ type: "file_search" }],
      })),
    })
  }

  const fillTemplateResult = await runAssistant({
    threadId,
    assistantId: templateFillerAssistant.id,
    additionalInstructions: contentGuidance || "",
    usage: "fill template section",
    expectedStatus: "completed",
    tools: [{ type: "file_search" }],
    toolChoice: { type: "file_search" },
  })

  // console.log("%cread attachments result", "color:orange", {
  //   readAttachmentsResult: fillTemplateResult,
  // })

  const getHeadingMessages = await listMessages({
    threadId,
    runId: fillTemplateResult.result.data.response.id,
  })

  // console.log("%cread attachments messages", "color:orange", {
  //   getHeadingMessages,
  // })

  headingContent =
    getHeadingMessages.data.response.body.data[0].content[0].text.value
  console.log("%csection content", "color:lightgreen", {
    sectionContent: headingContent,
  })

  const deleteThreadResult = await deleteThread({ threadId: threadId })
  console.log("delete thread result", { deleteThreadResult })

  return headingContent
}

const selectBestViewsToFillSection = async ({
  views,
  viewSet,
  templateInstance,
  selectedHeading,
  assistants,
  contentGuidance,
}) => {
  console.log("%cparams", "color:orange", {
    views,
    viewSet,
    templateInstance,
    selectedHeading,
    assistants,
    contentGuidance,
  })

  const assistant = assistants.find(
    (assistant) => assistant.name === "AIM Template Content Selector"
  )

  const threadResult = await createThread({
    messages: [],
  })

  const threadId = threadResult.data?.response?.id
  console.log("threadId", { threadId })

  const messages = [
    {
      type: "text",
      text: `We want to choose which views provide the best and relevant content to fill the '${selectedHeading.name}' heading of the '${templateInstance.name}' template using selected content in the attached views`,
    },
    {
      type: "text",
      text: `Heading purpose for which you will select only relevant content to fill is: ${selectedHeading.rationale}.`,
    },
    {
      type: "text",
      text: `The overall top level context for the entire document is ${viewSet.purpose}. You should use this as high level guidance on what inputs might be relevant, however use the above heading purpose as primary guidance on what content to select.`,
    },
    {
      type: "text",
      text: `The view content available is as follows and for each view the following fields are provided: id, name, description, importance, question (the question that the view answers) (optional)`,
    },
    {
      type: "text",
      text: `Each view begins with an ID attribute`,
    },
    ...views.flatMap((view) => [
      { type: "text", text: `ID: ${view.id}` },
      { type: "text", text: `View: ${view.name}` },
      { type: "text", text: `Description: ${view.description}` },
      {
        type: "text",
        text: `Importance: ${
          view.importance || "Importance not defined for this view"
        }`,
      },
      {
        type: "text",
        text: `Question: ${view.question || "No question was provided"}`,
      },
    ]),
    {
      type: "text",
      text: "That is the end of the list of views available to fill the section.",
    },
    {
      type: "text",
      text: "We are seeking to identify the minimum number of relevant views that very closely align to the specified heading purpose.",
    },
  ]

  if (contentGuidance) {
    messages.push({
      type: "text",
      text: `As further guidance to help choose only relevant inputs, use the following guidance: ${contentGuidance}`,
    })
  }

  console.log("%cmessage", "color:orange", { messagges: messages })

  await addMessages({
    threadId,
    content: messages,
  })

  const selectViewsFunction = assistant.tools.find(
    (t) => t.type === "function" && t.function.name === "select_content"
  )

  const selectViewsResult = await runAssistant({
    threadId,
    assistantId: assistant.id,
    usage: "select best views to fill section",
    expectedStatus: "requires_action",
    functionToUse: selectViewsFunction,
  })

  const runId = selectViewsResult.result.data.response.id

  console.log("select views result", { selectViewsResult })

  console.log("%ccreated run", "color:lightgreen", { selectViewsResult, runId })

  const result = await retrieveRunForStatus({
    threadId: threadId,
    runId: runId,
    expectedStatus: "requires_action",
  })

  console.log("select views result", { result })

  let selectViewsOutput

  if (result.success) {
    const rawJson =
      result.result.data.response.required_action.submit_tool_outputs
        .tool_calls[0].function.arguments

    console.log("raw json", { rawJson })
    const selectViewsJSON = JSON.parse(rawJson)

    selectViewsOutput = selectViewsJSON
  }

  const deleteThreadResult = await deleteThread({ threadId: threadId })

  console.log("delete thread result", { deleteThreadResult })

  return selectViewsOutput
}

const selectQuestionsToFillSection = async ({
  views,
  viewSet,
  templateInstance,
  selectedHeading,
  assistants,
  contentGuidance,
}) => {
  console.log("%cparams", "color:orange", {
    views,
    viewSet,
    templateInstance,
    selectedHeading,
    assistants,
    contentGuidance,
  })

  const assistant = assistants.find(
    (assistant) => assistant.name === "AIM Template Content Selector"
  )

  const threadResult = await createThread({
    messages: [],
  })

  const threadId = threadResult.data?.response?.id
  console.log("threadId", { threadId })

  const answeredQuestionIds = views
    .map((view) => view.question_id)
    .filter((id) => id)

  console.log("answeredQuestionIds", { answeredQuestionIds, viewSet })

  const answeredQuestions = views.filter((view) =>
    answeredQuestionIds.includes(view.question_id)
  )
  const unansweredQuestions = viewSet.view_questions.filter(
    (question) => !answeredQuestionIds.includes(question.question_id)
  )

  console.log("answeredQuestions", { answeredQuestions, unansweredQuestions })

  const messages = [
    {
      type: "text",
      text: `We want to choose which questions would be good to answer to fill the '${selectedHeading.name}' heading of the '${templateInstance.name}' template`,
    },
    {
      type: "text",
      text: `Heading purpose for which you will select useful questions that should be answered to create content to fill this heading is: ${selectedHeading.rationale}.`,
    },
    {
      type: "text",
      text: `The overall top level context for the entire document is ${viewSet.purpose}. You should use this as high level guidance on what inputs might be relevant, however use the above heading purpose as primary guidance on what content to select.`,
    },
    {
      type: "text",
      text:
        answeredQuestions.length > 0
          ? `The questions that have already been answered are:`
          : `No questions have been answered yet.`,
    },
    ...answeredQuestions.map((question) => ({
      type: "text",
      text: `ID [${question.question_id}]: ${question.question}`,
    })),
    {
      type: "text",
      text: `The questions that have not been answered and from which you should select to identify any questions that would likely provide good information to fill the ${selectedHeading.name} heading:`,
    },
    ...unansweredQuestions.map((question) => ({
      type: "text",
      text: `ID [${question.question_id}]: ${question.question}`,
    })),
    {
      type: "text",
      text: "We are seeking to identify the minimum number of relevant views that very closely align to the specified heading purpose.",
    },
    {
      type: "text",
      text: "Please select the questions that you think would be most useful to answer to fill the heading, and in the rationale explain why the question would be useful to help fill the given heading.",
    },
  ]

  console.log("%cmessage", "color:orange", { messagges: messages })

  await addMessages({
    threadId,
    content: messages,
  })

  const selectViewsFunction = assistant.tools.find(
    (t) => t.type === "function" && t.function.name === "select_questions"
  )

  const selectQuestionsResult = await runAssistant({
    threadId,
    assistantId: assistant.id,
    usage: "select questions to fill section",
    expectedStatus: "requires_action",
    functionToUse: selectViewsFunction,
  })

  const runId = selectQuestionsResult.result.data.response.id

  console.log("select questions result", { selectQuestionsResult })

  console.log("%ccreated run", "color:lightgreen", {
    selectQuestionsResult,
    runId,
  })

  const result = await retrieveRunForStatus({
    threadId: threadId,
    runId: runId,
    expectedStatus: "requires_action",
  })

  console.log("select questions result", { result })

  let selectQuestionsOutput

  if (result.success) {
    const rawJson =
      result.result.data.response.required_action.submit_tool_outputs
        .tool_calls[0].function.arguments

    console.log("raw json", { rawJson })
    const selectQuestionsJSON = JSON.parse(rawJson)

    selectQuestionsOutput = selectQuestionsJSON
  }

  const deleteThreadResult = await deleteThread({ threadId: threadId })

  console.log("delete thread result", { deleteThreadResult })

  return selectQuestionsOutput
}

export {
  fillHeading,
  selectBestViewsToFillSection,
  selectQuestionsToFillSection,
}
