import { IconButton, Tooltip } from "@mui/material"
import LaunchIcon from "@mui/icons-material/Launch"

const HelpLink = ({ url, pageName, tooltip }) => {
    return (
        <Tooltip title={tooltip}>
            <IconButton
                size="small"
                onClick={(e) => {
                    window.open(url, pageName)
                }}
            >
                <LaunchIcon />
            </IconButton>
        </Tooltip>
    )
}

export default HelpLink
