import React, { useRef } from "react"
import { Box } from "@mui/material"
import Controls from "./controls/Controls"
import AssignmentIcon from "@mui/icons-material/Assignment"
import DownloadIcon from "@mui/icons-material/Download"
import NotesIcon from "@mui/icons-material/Notes"
import LabelIcon from "@mui/icons-material/Label"
import EditIcon from "@mui/icons-material/Edit"
import ChatIcon from "@mui/icons-material/Chat"
import DrawIcon from "@mui/icons-material/Draw"
import {
  getBlobFromImageElement,
  copyBlobToClipboard,
} from "copy-image-clipboard"

const DiagramButtons = (props) => {
  const {
    handleCreateStory,
    svgRef,
    showCreateStoryButton,
    showCopyButton,
    handleCreateChatPrompt,
    handleDrawRough,
    handleShowChat,
    showCreateRefButton,
    handleCreateAIDesignerRef,
    handleDownloadSVG,
  } = props

  const canvasRef = useRef()
  const imgRef = useRef()

  const svgToCanvas = () => {
    var xml = new XMLSerializer().serializeToString(svgRef.current)
    //console.log("%cSVG", "color:orange", xml)

    // var svg64 = btoa(xml)
    //const buffer = Buffer.from(xml, "binary")
    //const svg64 = buffer.toString("base64")

    const utf8Array = new TextEncoder().encode(xml)

    // Convert the Uint8Array to a binary string
    const binaryString = utf8Array.reduce(
      (acc, byte) => acc + String.fromCharCode(byte),
      ""
    )

    var svg64 = btoa(binaryString)

    var b64Start = "data:image/svg+xml;base64,"

    // prepend a "header"
    var image64 = b64Start + svg64

    // set it as the source of the img element
    imgRef.current.src = image64

    // draw the image onto the canvas
    canvasRef.current.getContext("2d").drawImage(imgRef.current, 0, 0)

    console.log("svgToCanvas() called")
  }

  return (
    <>
      <img
        style={{ display: "none" }}
        ref={imgRef}
        alt="diagram"
        onLoad={() => {
          // The image is loaded above, but takes some time so we need
          // to drive the copy to clipboard command of this image loaded event.
          // This is done by calling the svgToCanvas function above.

          getBlobFromImageElement(imgRef.current)
            .then((blob) => {
              console.log("%cblob", "color:orange", blob)
              return copyBlobToClipboard(blob)
            })
            .then(() => {
              console.log("Blob Copied")
            })
            .catch((e) => {
              console.log("Error: ", e.message)
            })
        }}
      />

      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>

      <Box
        style={{
          display: "flex",
          justifyContent: "left",
          paddingBottom: "10px",
          marginTop: "10px",
          gap: "5px",
        }}
      >
        {showCreateStoryButton && (
          <Controls.Button
            endIcon={<EditIcon />}
            onClick={handleCreateStory}
            text="Story"
            tooltip="Create a story from this diagram"
          />
        )}

        {showCopyButton && (
          <Controls.Button
            endIcon={<AssignmentIcon />}
            onClick={svgToCanvas}
            text="Copy"
            tooltip="Copy image to clipboard"
          />
        )}

        {/* {handleDrawRough && (
                    <Controls.Button
                        variant="outlined"
                        endIcon={<DrawIcon />}
                        onClick={handleDrawRough}
                        text="Drawing"
                        tooltip="Create line drawing version of this view"
                    />
                )} */}

        {showCopyButton && (
          <Controls.Button
            endIcon={<DownloadIcon />}
            onClick={() => handleDownloadSVG(svgRef)}
            text="SVG"
            tooltip="Download image as SVG"
          />
        )}

        {handleCreateChatPrompt && (
          <Controls.Button
            endIcon={<NotesIcon />}
            onClick={handleCreateChatPrompt}
            text="Prompt"
            tooltip="Create ChatGPT prompt from diagram"
          />
        )}

        {/* {handleShowChat && (
                    <Controls.Button
                        variant="outlined"
                        endIcon={<ChatIcon />}
                        onClick={handleShowChat}
                        text="Chat"
                        tooltip="Chat"
                    />
                )} */}

        {showCreateRefButton && (
          <Controls.Button
            endIcon={<LabelIcon />}
            onClick={handleCreateAIDesignerRef}
            text="Ref"
            tooltip="Copy AI Designer reference to clipboard"
          />
        )}
      </Box>
    </>
  )
}

export default DiagramButtons
