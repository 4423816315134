import * as actions from '../actionTypes'

const initialState = { 
    value: false
}

export default function showRuleDetail(state = initialState, action) {

    switch(action.type) {

        case actions.SET_SHOW_RULE_DETAIL: {

            //console.log('[redux] Setting show rule detail
            return {
                ...state,
                value: action.payload.value
            }
        }

        default:
            return state
    }
}